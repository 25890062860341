// import { useTranslation } from "react-i18next";
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getPosList } from '../../store/Sales/Pos/PosSlice';
import { useTranslation } from 'react-i18next';

const useGetPosList = () => {
  const dispatch = useAppDispatch();

  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (PosList?.length === 0) {
      dispatch(getPosList());
    }
  }, []);

  const { PosList, loading, error } = useAppSelector((state) => state.Pos);

  const optionsPos = PosList.map((m) => ({
    value: m.id,
    label: i18n.language === 'en' ? m.name : m.name2,
  }));

  const optionsPosWithAll = [
    {
      label: t('جميع نقاط البيـع'),
      value: 'All',
    },
    ...optionsPos,
  ];

  return { PosList, optionsPos,optionsPosWithAll ,  loading, error };
};

export default useGetPosList;
