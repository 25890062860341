import { useFormik } from 'formik';
// import React, { useContext, useEffect } from "react";
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useGetPersons from '../../../../hooks/HR/use-get-persons';
import useGetOrganizations from '../../../../hooks/HR/use-get-organizations';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch } from '../../../../store/hooks';
import useGetPeoplesList from '../../../../hooks/NTX/useGetPeoplesList';
import useLookupInventoryTransactions from '../../../../hooks/Lookups/use-Lookup-InventoryTransactions';
// import TransactionsInvLines from '../TransactionsInvLines/TransactionsInvLines';
import InvLines from '../TransactionsLines/InvLines';
import { addMoveTransactions } from '../../../../store/INV/Transactions/InvMove/invMoveSlice';
import { useNavigate } from 'react-router-dom';
import useGetTransactionTypesMoveList from '../../../../hooks/INV/InvMove/useGetTransactionTypesMoveList';

const Add = () => {

  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();
  const { dataEmployees, loading } = useGetPersons();
  const { BranchsOptions, InventoryOptions, loading: loading3 } = useGetOrganizations();
  const { OptionCustomers, loading: loading2 } = useGetPeoplesList({ LookupType: '' });
  const { INVENTORY_TRANSACTIONS } = useLookupInventoryTransactions();

  const { OptionsTransactionTypes } = useGetTransactionTypesMoveList();

  //#region Formik
  const formik = useFormik({
    initialValues: {
      branchTo: '',

      inventoryTo: '',

      subInventoryTo: '',

      code: '',
      transactionDate: '',
      description: '',
      branchId: '',
      // transactionTypeId: '',
      // transactionTypeName: '',
      transactionAmount: 0,
      referenceType: '',
      referenceId: '',
      // referenceCode: '',
      // referenceDate: '',
      supplierId: '',
      customerId: '',
      // branchTo: '',
      personId: '',
      // autoManual: true,
      // keeperId: '',
      // driverId: '',
      // fleetEquipmentsId: '',
      inventoryId: '',
      // inventoryTo: '',
      // directTransfer: 0,
      address: '',
      note: '',

      materialTransactionsLineList: [] as any[],
    },
    enableReinitialize: true,
    // validationSchema: AddGlJeHeaderSchema(t),
    onSubmit: (values) => {
      dispatch(
        addMoveTransactions({
          branchTo: values.branchTo,
          inventoryTo: values.inventoryTo,
          subInventoryTo: values.subInventoryTo || null,
          code: values.code,
          transactionDate: values.transactionDate,
          description: values.description,
          branchId: values.branchId,
          transactionAmount: 0,
          referenceType: values.referenceType,
          referenceId: values.referenceId || null,
          supplierId: values.supplierId || null,
          customerId: values.customerId || null,
          personId: values.personId || null,
          inventoryId: values.inventoryId,
          address: values.address,
          note: values.note,

          materialTransactionsLineList: formik.values.materialTransactionsLineList.map((item) => ({
            transactionItemId: item.itemId,
            transactionUom: item.uom,
            unitPrice: 0, // item.price
            transactionQuantityIn: item.qty,
            transactionQuantityOut: 0,
            inventoryId: null,
            discount: 0,
            codeCombinationId: null,
            currencyCode: '',
            currencyConversionRate: 0,
            referenceIdMaster: null,
            referenceIdLine: null,
            transactionTypeId: null,
            equivalent: 0,
            expensesAmount: 0,
            batchId: null,
          })),
        })
      )
        .unwrap()
        .then((res: any) => {
          // debugger;
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  const { values, handleChange: formikhandleChange, errors, touched } = formik;
  //#endregion



  
  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'InvMove',
      link: '',
    },
    {
      name: 'Add InvMove',
      link: null,
    },
  ];

  // console.log(values);

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="Add InvMove" />

      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('تاريخ التحويل')}</label>
                  <Form.Control
                    className="form-control  text-center"
                    type="date"
                    name="transactionDate"
                    onChange={formikhandleChange}
                    value={values.transactionDate || ''}
                    isInvalid={!!(touched.transactionDate && errors.transactionDate)}
                    isValid={formik.touched.transactionDate && !formik.errors.transactionDate}
                  />
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('نوع الحركة')}</label>
                  <Select
                    classNamePrefix="react-select"
                    // isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={OptionsTransactionTypes}
                    onChange={(option: any) => {
                      formik.setFieldValue('code', option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>

                {/* <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('نوع المرجع')}</label>
                    <Form.Select
                      className="form-select"
                      name="referenceType"
                      onChange={formik.handleChange}
                      value={formik.values.referenceType}
                      // isInvalid={formik.touched.referenceType && formik.errors.referenceType}
                    >
                      <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {INVENTORY_TRANSACTIONS &&
                        INVENTORY_TRANSACTIONS.map((item: any, idx: number) =>
                          item.list.map((list: any) => (
                            <option key={++idx} value={list.lookupCode}>
                              {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                            </option>
                          ))
                        )}
                    </Form.Select>
                  </div>
                </div> */}

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('الفرع المحول منه')}</label>
                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={BranchsOptions}
                    onChange={(option: any) => {
                      formik.setFieldValue('branchId', option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('الفرع المحول اليه')}</label>
                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={BranchsOptions}
                    onChange={(option: any) => {
                      formik.setFieldValue('branchTo', option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('المخزن المحول منه')}</label>
                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={InventoryOptions}
                    onChange={(option: any) => {
                      formik.setFieldValue('inventoryId', option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('المخزن المحول اليه')}</label>
                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={InventoryOptions}
                    onChange={(option: any) => {
                      formik.setFieldValue('inventoryTo', option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>

              {/* <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('طريقة التحويل')}</label>
                  <Form.Select
                    className="form-select"
                    name="referenceType"
                    onChange={formik.handleChange}
                    value={formik.values.referenceType}
                   >
                    <option key={''}>{i18n.language === 'ar' ? 'مباشر' : 'Choose'}</option>
                    <option key={''}>{i18n.language === 'ar' ? 'غير مباشر' : 'Choose'}</option>
                    
                  </Form.Select>
                </div>
              </div> */}

              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Note')}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control"
                    as="textarea"
                    rows={1}
                    name="description"
                    onChange={formik.handleChange}
                    value={values.description}
                    isInvalid={!!(touched.description && errors.description)}
                    isValid={touched.description && !errors.description}
                  />
                  <div></div>
                </div>
              </div>
            </div>

            <div className="row m-0 p-0">
              <div className="bg-inverse bg-opacity-5 p-2">
                {/* <TransactionsInvLines formik={formik} /> */}
                <InvLines formik={formik} />
              </div>
            </div>

            <div className="text-center">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn btn-lg me-1 btn-theme mb-1">
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                )}
                {t('Add')}
              </button>
              <button
                type="button"
                className="btn btn-lg  me-1 btn-default mb-1"
                onClick={() => Navigate(-1)}>
                <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
              </button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default Add;
