import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { GetDashboardDetails } from '../../store/NTX/Reports/Dashboard/dashboardSlice';

const useGetDashboardDetails = (dashboardId: string | undefined) => {
  const dispatch = useAppDispatch();

  const { DashboardDetailsById, loading, error } = useAppSelector((state) => state.DashBoard);

  // const { DashboardDetailsById, loading } = useAppSelector(({ DashBoard }) => DashBoard);

  const memoizedDispatch = useCallback(
    (id: string) => dispatch(GetDashboardDetails(id)),
    [dispatch]
  );

  useEffect(() => {
    if (dashboardId) {
      memoizedDispatch(dashboardId);
    }
  }, [dashboardId, memoizedDispatch]);

  // useEffect(() => {
  //   if (DashboardId) {
  //     dispatch(GetDashboardDetails(DashboardId));
  //   }
  // }, [DashboardId, dispatch]);

  return { DashboardDetailsById, loading, error };
};

export default useGetDashboardDetails;
