import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import * as Yup from "yup";

import { useLocation, useNavigate } from "react-router-dom";
import Logo from "./Logo";
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { variables, ntxheader } from "../../API/variables";
// import ToastAlert from "../../components/Shared/Alert/ToastAlert";
import PasswordChecklist from "react-password-checklist";
import { useDispatch } from "react-redux";
import { setAuthUser } from "../../store/Login/loginSlice";
import ToastSwal from "../../components/Shared/Alert/ToastSwal";

function ConfirmPassword() {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const stateIsConfirm = location.state;
  const user =
    stateIsConfirm && stateIsConfirm?.res?.token
      ? jwt_decode(stateIsConfirm?.res?.token)
      : null;

  const navigate = useNavigate();

  useEffect(() => {
    if (!stateIsConfirm || !stateIsConfirm.Token) {
      localStorage.clear();
      navigate("/login");
      return; // Add this to exit the useEffect early if stateIsConfirm is null
    }

    localStorage.setItem("language", "ar");
    localStorage.setItem("appMode", "dark");
    localStorage.setItem("appTheme", "theme-info");
    document.documentElement.setAttribute("data-bs-theme", "dark");
  }, [stateIsConfirm]);

  //_____________________________________________________________________________________
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [shownewPassword, setNewshowPassword] = useState(false);
  const toggleNewPasswordVisibility = () => {
    setNewshowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [showconfirmnewPassword, setConfirmnewshowPassword] = useState(false);
  const toggleConfirmNewPasswordVisibility = () => {
    setConfirmnewshowPassword((prevShowPassword) => !prevShowPassword);
  };
  //_____________________________________________________________________________________

  const formik = useFormik({
    initialValues: {
      usersName: user?.UserName,
      currentPassword: "",
      newPassword: "",
      comfirmdNewPassword: "",
    },

    validationSchema: Yup.object({
      usersName: Yup.string().trim().required("Username is required"),
      currentPassword: Yup.string().trim().required("Password is required"),

      newPassword: Yup.string()
        .min(8, "كلمة المرور يجب أن تحتوي على أكثر من 8 أحرف")
        .matches(
          /(?=.*[A-Z])/,
          "يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل"
        )
        .matches(/(?=.*\d)/, "يجب أن تحتوي كلمة المرور على رقم واحد على الأقل")
        .matches(/(?=.*[!@#$%^&*])/, "يجب أن تحتوي كلمة المرور على أحرف خاصة")
        .required(),

      comfirmdNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Please confirm your new password"),
    }),

    onSubmit: async (values) => {
      var item = {
        usersName: values.usersName,
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
        comfirmdNewPassword: values.comfirmdNewPassword,
      };

      try {
        const { data } = await axios.post(
          `${variables.API_URL}Security/ChangePassword`,
          item,
          {
            headers: {
              ...ntxheader,
              Authorization: `Bearer ${stateIsConfirm?.res?.token}`, // Use stateIsConfirm?.Token here
              "Content-Type": "application/json",
            },
          }
        );

        if (data?.succeeded === true) {
          ToastSwal({
            position: "top-start",
            title: "تم تغيير كلمة المرور بنجاح",
          });
          dispatch(setAuthUser(stateIsConfirm?.res));
          localStorage.setItem("CurrentSystem", "NTX");
          window.location.replace("/dashboard");
          // navigate("/dashboard", { replace: true });
        } else {
          ToastSwal({
            icon: "warning",
            position: "top-start",
            title: "تم تغيير كلمة المرور بنجاح",
          });
        }
        return data;
      } catch (error) {
        // console.error(error);
        // return rejectWithValue(error.message);
      }
    },
  });

  return (
    <>
      <div className="login p-0">
        <div className="login-content">
          <div style={{ marginBottom: "20px" }}>
            <Logo themeClass={localStorage.appTheme ?? "theme-info"} />
          </div>
          <hr />
          <Form onSubmit={formik.handleSubmit}>
            <h5 className="text-center">{t("ChangePassword")}</h5>
            <div className="text-white text-opacity-50 text-center mb-3"></div>

            <div className="mb-3">
              <label className="form-label">
                {t("UserName")} <span className="text-danger">*</span>
              </label>
              {/* <input type="text" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="" /> */}
              <Form.Control
                disabled
                style={{ textAlign: "center" }}
                className="form-control form-control-lg bg-white bg-opacity-5"
                type="text"
                name="usersName"
                onChange={formik.handleChange}
                value={formik.values.usersName}
                isInvalid={formik.touched.usersName && formik.errors.usersName}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.usersName}
              </Form.Control.Feedback>
            </div>

            <div className="mb-3">
              <div className="d-flex">
                <label className="form-label">
                  {t("CurrentPassword")} <span className="text-danger">*</span>
                </label>
              </div>
              <div className="input-group">
                <div
                  className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                  style={{ zIndex: 1020, right: "10px" }}
                >
                  <i
                    className={`fs-4 opacity-10 bi bi-eye${
                      showPassword ? "-slash" : ""
                    }`}
                    onClick={togglePasswordVisibility}
                    style={{ cursor: "pointer", marginLeft: "-30px" }}
                  />
                </div>

                <Form.Control
                  style={{ textAlign: "center" }}
                  className="form-control form-control-lg bg-white bg-opacity-5"
                  // type="password"
                  type={showPassword ? "text" : "password"}
                  name="currentPassword"
                  onChange={formik.handleChange}
                  value={formik.values.currentPassword}
                  isInvalid={
                    formik.touched.currentPassword &&
                    formik.errors.currentPassword
                  }
                  autoComplete="off"
                />
              </div>

              <Form.Control.Feedback type="invalid">
                {formik.errors.currentPassword}
              </Form.Control.Feedback>
            </div>

            <div className="mb-3">
              <div className="d-flex">
                <label className="form-label">
                  {t("NewPassword")} <span className="text-danger">*</span>
                </label>
              </div>

              <div className="input-group">
                <div
                  className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                  style={{ zIndex: 1020, right: "10px" }}
                >
                  <i
                    className={`fs-4 opacity-10 bi bi-eye${
                      shownewPassword ? "-slash" : ""
                    }`}
                    onClick={toggleNewPasswordVisibility}
                    style={{ cursor: "pointer", marginLeft: "-30px" }}
                  />
                </div>

                <Form.Control
                  style={{ textAlign: "center" }}
                  className="form-control form-control-lg bg-white bg-opacity-5 mb-1"
                  // type="password"
                  type={shownewPassword ? "text" : "password"}
                  name="newPassword"
                  onChange={formik.handleChange}
                  value={formik.values.newPassword}
                  isInvalid={
                    formik.touched.newPassword && formik.errors.newPassword
                  }
                />
              </div>
              <div className=" bg-inverse bg-opacity-5 rounded-1 p-1">
                <PasswordChecklist
                  rules={["minLength", "specialChar", "number", "capital"]}
                  minLength={8}
                  value={formik.values.newPassword}
                  messages={{
                    minLength: t("ContainsMoreThan_8_Characters"),
                    specialChar: `${t(
                      "ContainsSpecialCharacters"
                    )} [! @ #  $ % ^ & *] `,
                    number: t("ContainAtLeastOneNumber"),
                    capital: t("ContainAtLeastOneCapitalLetter"),
                  }}
                />
              </div>
            </div>

            <div className="mb-3">
              <div className="d-flex">
                <label className="form-label">
                  {t("ConfirmNewPassword")}
                  <span className="text-danger">*</span>
                </label>
              </div>

              <div className="input-group">
                <div
                  className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                  style={{ zIndex: 1020, right: "10px" }}
                >
                  <i
                    className={`fs-4 opacity-10 bi bi-eye${
                      showconfirmnewPassword ? "-slash" : ""
                    }`}
                    onClick={toggleConfirmNewPasswordVisibility}
                    style={{ cursor: "pointer", marginLeft: "-30px" }}
                  />
                </div>

                <Form.Control
                  style={{ textAlign: "center" }}
                  className="form-control form-control-lg bg-white bg-opacity-5 mb-1"
                  // type="password"
                  type={showconfirmnewPassword ? "text" : "password"}
                  name="comfirmdNewPassword"
                  onChange={formik.handleChange}
                  value={formik.values.comfirmdNewPassword}
                  isInvalid={
                    formik.touched.comfirmdNewPassword &&
                    formik.errors.comfirmdNewPassword
                  }
                />
              </div>
              <div className=" bg-inverse bg-opacity-5 rounded-1 p-1">
                <PasswordChecklist
                  rules={["match"]} //,
                  minLength={8}
                  value={formik.values.comfirmdNewPassword}
                  valueAgain={formik.values.newPassword}
                  messages={{
                    match: t("MatchThePassword"),
                  }}
                />
              </div>
            </div>

            {/* 
            <div className="mb-3">
              <div className="d-flex">
                <label className="form-label">
                {t('CurrentPassword')} <span className="text-danger">*</span>
                </label>
              </div>

              <Form.Control
                style={{ textAlign: "center" }}
                className="form-control form-control-lg bg-white bg-opacity-5"
                type="password"
                name="currentPassword"
                onChange={formik.handleChange}
                value={formik.values.currentPassword}
                isInvalid={
                  formik.touched.currentPassword &&
                  formik.errors.currentPassword
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.currentPassword}
              </Form.Control.Feedback>
            </div>
            <div className="mb-3">
              <div className="d-flex">
                <label className="form-label">
                {t('NewPassword')} <span className="text-danger">*</span>
                </label>
              </div>

              <Form.Control
                style={{ textAlign: "center" }}
                className="form-control form-control-lg bg-white bg-opacity-5"
                type="password"
                name="newPassword"
                onChange={formik.handleChange}
                value={formik.values.newPassword}
                isInvalid={
                  formik.touched.newPassword && formik.errors.newPassword
                }
              />
              <div className=" bg-inverse bg-opacity-5 rounded-1 p-1">
                <PasswordChecklist
                  rules={["minLength", "specialChar", "number", "capital"]}
                  minLength={8}
                  value={formik.values.newPassword}
                  messages={{
                    minLength: t('ContainsMoreThan_8_Characters'),
                    specialChar:t('ContainsSpecialCharacters'), //.[!   @   # , $   %   ^   &   * s]
                    number: t('ContainAtLeastOneNumber'),
                    capital: t('ContainAtLeastOneCapitalLetter') ,
                    // match: "تتطابق كلمات المرور.",
                  }}
                />
              </div>
            </div>
            <div className="mb-3">
              <div className="d-flex">
                <label className="form-label">
                {t('ConfirmNewPassword')}
                  <span className="text-danger">*</span>
                </label>
              </div>

              <Form.Control
                style={{ textAlign: "center" }}
                className="form-control form-control-lg bg-white bg-opacity-5"
                type="password"
                name="comfirmdNewPassword"
                onChange={formik.handleChange}
                value={formik.values.comfirmdNewPassword}
                isInvalid={formik.errors.comfirmdNewPassword}
              />
              <div className=" bg-inverse bg-opacity-5 rounded-1 p-1">
                <PasswordChecklist
                  rules={["match"]} //,
                  minLength={8}
                  value={formik.values.comfirmdNewPassword}
                  valueAgain={formik.values.newPassword}
                  messages={{
                    match: t('MatchThePassword'),
                  }}
                />
              </div>
            </div>
 */}

            <div className="mb-3"></div>
            <button
              type="submit"
              className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? (
                <div className="spinner-border text-primary spinner-border-sm "></div>
              ) : (
                t("Save")
              )}
            </button>
          </Form>
        </div>
      </div>
    </>
  );
}

export default ConfirmPassword;
