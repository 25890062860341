import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../store/hooks";
import { useFormik } from "formik";

import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";

import { Form } from "react-bootstrap";
import {
  GetEquipmentsModelList,
  InsertEquipmentsModel,
} from "../../../../store/FLEET/EquipmentsModel/equipmentsmodelSlice";
import { AddEquipmentsModelFLeetSchema } from "../../../ValidationForm/validationSchema";

const AddManufacturer = () => {
  const {  t } = useTranslation();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      name: "",
      name2: "",
      description: "",
      parentId: null,
      manufYear: "",
      barcode: "",
      catagory: "",
      manufCountry: "",
      seatsNo: "",
      doorsNo: "",
      fuelType: "",
      motionVector: "",
      horsepower: "",
      horsepowerTax: "",
      Img: "",
      IsAddIMG: false,
    },
    validationSchema: AddEquipmentsModelFLeetSchema(t),
    onSubmit: (values) => {
      

      const formData = new FormData();

      formData.append("Name", values.name);
      formData.append("Name2", values.name2);
      formData.append("Description", values.description);
      formData.append("ParentId", "");
      // formData.append("ManufYear", values.manufYear);
      // formData.append("Barcode", values.barcode);
      // formData.append("Catagory", values.catagory);
      // formData.append("ManufCountry", values.manufCountry);
      formData.append("SeatsNo", values.seatsNo);
      formData.append("DoorsNo", values.doorsNo);
      // formData.append("FuelType", values.fuelType);
      // formData.append("MotionVector", values.motionVector);
      formData.append("Horsepower", values.horsepower);
      formData.append("HorsepowerTax", values.horsepowerTax);
      formData.append("Img", values.Img);
      formData.append("IsAddIMG", values.IsAddIMG.toString());

      dispatch(InsertEquipmentsModel(formData))
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: "Add" });
            document.getElementById("closeModalForm")?.click();
            formik.resetForm();
            dispatch(GetEquipmentsModelList());
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  const handleImageUpload = (event: any) => {
    formik.setFieldValue("Img", event.currentTarget.files[0]);
    formik.setFieldValue("IsAddIMG", true);
  };

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t("LocalName")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t("GlobalName")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name2}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t("Image")}</label>
              <Form.Control
                className="form-control"
                type="file"
                name="Img"
                placeholder="أختر صورة"
                accept="image/*"
                onChange={(e) => handleImageUpload(e)}
                isInvalid={!!(formik.touched.Img && formik.errors.Img)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Img}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t("Note")}</label>
              <Form.Control
                className="form-control"
                as="textarea"
                rows={1}
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
              />
              <div></div>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-outline-theme mb-1"
          >
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t("Add")}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-outline-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm"
          >
            <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default AddManufacturer;
