import { createRef, useLayoutEffect, useRef } from 'react';
import "gridstack/dist/gridstack.min.css";
import { GridStack } from 'gridstack';
import Item from './Item';

const ControlledStack = ({ items, addItem, changeItems, removeItem }) => {
  const refs = useRef({});
  const gridRef = useRef();
  const gridContainerRef = useRef(null);
  refs.current = {};

  if (Object.keys(refs.current)?.length !== items?.length) {
    items?.forEach(({ id }) => {
      refs.current[id] = refs.current[id] || createRef();
    });
  }
 

 

  useLayoutEffect(() => {
    if (!gridRef.current) {
      const grid = (gridRef.current = GridStack.init(
        {
          // float: true,
          // acceptWidgets: true,
          // auto:false
          // column:20
        },
        gridContainerRef.current
      )

        .on('added', (ev, gsItems) => {
          if (grid._ignoreCB) return;
          gsItems.forEach((n) => {
            grid.removeWidget(n.el, true, false);
            addItem({ id: n.id, x: n.x, y: n.y, w: n.w, h: n.h });
          });
        })
        .on('removed change', (ev, gsItems) => {
          const newItems = grid.save(false);
          changeItems(newItems);
        }));
    } else {
      const grid = gridRef.current;
      const layout = items?.map(
        (a) =>
          refs.current[a.id].current.gridstackNode || {
            ...a,
            el: refs.current[a.id].current,
          }
      );
      grid._ignoreCB = true;
      grid.load(layout);
      delete grid._ignoreCB;
    }
  }, [addItem, changeItems, items]);

  //___________________________
  const handleRemoveItem = (itemId) => {
    const isConfirmed = window.confirm('Are you sure you want to remove this item?');

    if (isConfirmed) {
      // Call the removeItem function if the user confirms
      removeItem(itemId);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <div className="grid-stack" ref={gridContainerRef}>
        {items?.map((item, i) => {
          return (
            <div
              ref={refs.current[item.id]}
              key={item.id}
              className="grid-stack-item bg-inverse bg-opacity-5"
              gs-id={item.id}
              gs-w={item.w}
              gs-h={item.h}
              gs-x={item.x}
              gs-y={item.y}>
              <div
                className="grid-stack-item-content bg-inverse bg-opacity-15 text-center border border-3 border-theme rounded-3"
                // onDoubleClick={() => removeItem(item.id)}
                onDoubleClick={() => handleRemoveItem(item.id)}>
                <>
                  <Item {...item} />
                </>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ControlledStack;

// {/* <code>
//   <pre>{JSON.stringify(items, null, 2)}</pre>
// </code> */}
