import React, { useState } from 'react';
import confirmAction from '../../../../components/Shared/Alert/confirmAction';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { ntxAPI } from '../../../../API/axiosNeatex';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../store/hooks';
import { getEmployees } from '../../../../store/HR/Person/employeeSlice';

const translation = {
  en: {
    rollbackConfirmation: 'Are you sure you want to roll back the end of service?',
    rollbackSuccess: 'The rollback of the end of service was successful!',
    rollbackFailure: 'The rollback of the end of service failed. Please try again.',
    rollbackButton: 'Roll Back End of Service',
  },
  ar: {
    rollbackConfirmation: 'هل أنت متأكد أنك تريد التراجع عن نهاية الخدمة؟',
    rollbackSuccess: 'تم التراجع عن نهاية الخدمة بنجاح!',
    rollbackFailure: 'فشل التراجع عن نهاية الخدمة. حاول مرة أخرى.',
    rollbackButton: 'تراجع عن نهاية الخدمة',
  },
};


interface Props {
  EndOfServiceId : string;
}


const RollBack = ({EndOfServiceId}:Props) => {

  const dispatch = useAppDispatch();
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleRollBackEndOfServices = async () => {
    let isConfirmation = await confirmAction(
      i18n.language === 'en'
        ? translation.en.rollbackConfirmation
        : translation.ar.rollbackConfirmation
    );

    if (isConfirmation) {
      setLoading(true);
      try {
        const { data } = await ntxAPI.post('Persons/DeletePeriodOfService', {
          id: EndOfServiceId,
        });

        if (data?.succeeded) {
          CustomAlert({
            action: 'Add',
            msg:
              i18n.language === 'en'
                ? translation.en.rollbackSuccess
                : translation.ar.rollbackSuccess,
          });
          dispatch(getEmployees());  
          Navigate(-1)
        } else {
          CustomAlert({
            action: 'Error',
            msg:
              i18n.language === 'en'
                ? translation.en.rollbackFailure
                : translation.ar.rollbackFailure,
          });
        }
      } catch (error: any) {
        CustomAlert({ action: 'Error', msg: `An error occurred: ${error?.message}` });
      } finally {
        setLoading(false);
      }
    } else {
      // CustomAlert({ action: 'info', msg: 'Rollback of the end of service was cancelled.' });
    }
  };
  return (
    <div>
      <button
        disabled={loading}
        type="button"
        className="btn btn-lg me-1 btn-warning"
        onClick={handleRollBackEndOfServices}>
        <i className="fas fa-undo fa-fw me-1"></i>
        {i18n.language === 'en' ? translation.en.rollbackButton : translation.ar.rollbackButton}
      </button>
    </div>
  );
};

export default RollBack;
