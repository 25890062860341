import { useRef } from "react";




const Marker = ({ lat, lng, draggable, onDragEnd }) => {
    const markerRef = useRef(null);
  
    const handleDragEnd = (e) => {
      if (onDragEnd) {
        const newPos = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        };
        onDragEnd(newPos);
      }
    };
  
    return (
      <div style={{ position: "absolute", transform: "translate(-50%, -50%)" }}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 0C10.27 0 6 4.27 6 10C6 14.05 8.44 17.75 12.32 20.65L16 31.6L19.68 20.65C23.56 17.75 26 14.05 26 10C26 4.27 21.73 0 16 0ZM16 14C13.79 14 12 12.21 12 10C12 7.79 13.79 6 16 6C18.21 6 20 7.79 20 10C20 12.21 18.21 14 16 14Z"
            fill="#F00"
          />
        </svg>
        {draggable && (
          <div
            style={{
              position: "absolute",
              top: "-32px",
              left: "-16px",
              width: "32px",
              height: "32px",
              cursor: "move",
            }}
            ref={markerRef}
            draggable="true"
            onDragEnd={handleDragEnd}
          />
        )}
      </div>
    );
  };
  export default Marker;