import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { getData, postData } from "../../Axios/axiosData";
import { LookupByIdModel, LookupModel } from "./lookupModels";
import { ntxAPI } from "../../../API/axiosNeatex";



export const GetLookupManagList = createAsyncThunk(
    "LookupData/GetLookupManagList",
    async ( _ , thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      
      try {
        // const data = await getData('Lookup/GetLookupManagList');   
        const {data} = await ntxAPI.get( 'Lookup/GetLookupManagList');   
        return data.data;
      } catch (error : any) {
        console.error(error);
        return rejectWithValue(error.message);
      }
    }
  );

  export const AddNtxLookup = createAsyncThunk(
    "LookupData/AddNtxLookup",
    async (item : any, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;

      try {
        // const data = await postData("Lookup/AddLookup" , item);
        const {data} = await ntxAPI.post("Lookup/AddLookup" , item)
        return data;
      } catch (error:any) {
        console.error(error);
        return rejectWithValue(error.message);
      }
    }
  );


  export const GetLookupById = createAsyncThunk(
    "LookupData/GetLookupById",
    async (id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        // const data = await getData(`Lookup/GetLookupById?Id=${id}`);
        const {data} = await ntxAPI.get( `Lookup/GetLookupById?Id=${id}`);
        return data.data;
      } catch (error:any) {
        console.error(error);
        return rejectWithValue(error.message);
      }
    }
  );

  export const UpdateNtxLookup = createAsyncThunk(
    "LookupData/UpdateNtxLookup",
    async (item : any, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;

      try {
        // const data = await postData("Lookup/UpdateLookup" , item);
        const {data} = await ntxAPI.post("Lookup/UpdateLookup" , item)
        return data;
      } catch (error:any) {
        console.error(error);
        return rejectWithValue(error.message);
      }
    }
  );
  

  export const GetPaymentTermsList = createAsyncThunk(
    "LookupData/GetPaymentTermsList",
    async ( _ , thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      
      try {
        const {data} = await ntxAPI.get( 'NtxSetting/GetPaymentTermsList');   
        return data.data;
      } catch (error : any) {
        console.error(error);
        return rejectWithValue(error.message);
      }
    }
  );




const initialState: {
    LookupData : LookupModel[]
    LookupById : LookupByIdModel | null
    PaymentTermsList : []
    loading: boolean;
    loadingUpdate: boolean;
    error: any | null;
  } = {
    LookupData:[],
    LookupById : null ,
    PaymentTermsList : [],
    loading: false,
    loadingUpdate: false,
    error: null,
  };



  const LookupDataSlice = createSlice({
    name: "LookupData",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder

      .addCase(GetLookupManagList.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear any previous errors when creating a post
      })
      .addCase(GetLookupManagList.fulfilled, (state, action:PayloadAction<LookupModel[]>) => {
        state.loading = false;
        state.LookupData = action.payload
      })
      .addCase(GetLookupManagList.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


      ////GetLookupById
      .addCase(GetLookupById.pending, (state) => {
        state.loadingUpdate = true;
        state.error = null; // Clear any previous errors when creating a post
      })
      .addCase(GetLookupById.fulfilled, (state, action:PayloadAction<LookupByIdModel>) => {
        state.loadingUpdate = false;
        state.LookupById = action.payload
      })
      .addCase(GetLookupById.rejected, (state, action:PayloadAction<any>) => {
        state.loadingUpdate = false;
        state.error = action.payload;
      })







      .addCase(GetPaymentTermsList.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear any previous errors when creating a post
      })
      .addCase(GetPaymentTermsList.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.PaymentTermsList = action.payload
      })
      .addCase(GetPaymentTermsList.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })



      

      
    },
  });
  
  export default LookupDataSlice.reducer;
  