import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../store/hooks';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { Form } from 'react-bootstrap';

import {
  GetGlDailyRatesList,
  UpdatGlDailyRates,
} from '../../../../store/Finance/DailyRates/DailyRatesSlice';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import useGetGlDailyRatesList from '../../../../hooks/Finance/useGetGlDailyRatesList';
import { DailyRatesModels } from '../../../../store/Finance/DailyRates/DailyRatesModels';

interface props {
  ID?: string;
}

const EditDailyRates: React.FC<props> = ({ ID }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const { Currency } = useLookupCurrency();
  const { DailyRatesList } = useGetGlDailyRatesList();
  const [dailyRateById, setDailyRateById] = useState<DailyRatesModels>();

  useEffect(() => {
    var ById = DailyRatesList.find((f) => f.id === ID);
    setDailyRateById(ById);
  }, [DailyRatesList, ID]);

  console.log(dailyRateById);

  const formik = useFormik({
    initialValues: {
      id: dailyRateById ? dailyRateById.id : '',
      fromCurrencyCode: dailyRateById ? dailyRateById.fromCurrencyCode : '',
      toCurrencyCode: dailyRateById ? dailyRateById.toCurrencyCode : '',
      conversionStartDate: dailyRateById ? dailyRateById.conversionStartDate : '',
      conversionEndDate: dailyRateById ? dailyRateById.conversionEndDate : '',
      conversionRate: dailyRateById ? dailyRateById.conversionRate : '',
    },
    enableReinitialize: true,
    //   validationSchema: AddEquipmentsModelFLeetSchema(t),
    onSubmit: (values) => {
      dispatch(
        UpdatGlDailyRates({
          id: values.id,
          fromCurrencyCode: values.fromCurrencyCode,
          toCurrencyCode: values.toCurrencyCode,
          conversionStartDate: values.conversionStartDate,
          conversionEndDate: values.conversionEndDate,
          conversionRate: values.conversionRate,
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Edit' });
            document.getElementById('closeModalForm')?.click();
            formik.resetForm();
            dispatch(GetGlDailyRatesList());
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-5">
            <div className="form-group mb-3">
              <label className="form-label"> fromCurrencyCode </label>
              <Form.Select
                className="form-select"
                name="fromCurrencyCode"
                onChange={formik.handleChange}
                value={formik.values.fromCurrencyCode || ''}
                isInvalid={!!(formik.touched.fromCurrencyCode && formik.errors.fromCurrencyCode)}>
                <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {Currency &&
                  Currency?.map((item: any, idx: number) => (
                    <option key={++idx} value={item.currencyCode}>
                      {item.description}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.fromCurrencyCode}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-2">
            <div className="form-group mb-3">
              <label className="form-label">conversionRate</label>
              <Form.Control
                className="form-control text-center"
                type="text"
                name="conversionRate"
                onChange={formik.handleChange}
                value={formik.values.conversionRate}
                isInvalid={!!(formik.touched.conversionRate && formik.errors.conversionRate)}
              />
            </div>
          </div>

          <div className="col-xl-5">
            <div className="form-group mb-3">
              <label className="form-label"> toCurrencyCode </label>
              <Form.Select
                className="form-select"
                name="toCurrencyCode"
                onChange={formik.handleChange}
                value={formik.values.toCurrencyCode || ''}
                isInvalid={!!(formik.touched.toCurrencyCode && formik.errors.toCurrencyCode)}>
                <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {Currency &&
                  Currency?.map((item: any, idx: number) => (
                    <option key={++idx} value={item.currencyCode}>
                      {item.description}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.toCurrencyCode}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('StartDate')}</label>
              <Form.Control
                className="form-control text-center"
                type="date"
                name="conversionStartDate"
                onChange={formik.handleChange}
                value={formik.values.conversionStartDate}
                isInvalid={
                  !!(formik.touched.conversionStartDate && formik.errors.conversionStartDate)
                }
              />
            </div>
          </div>

          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('endDate')}</label>
              <Form.Control
                className="form-control text-center"
                type="date"
                name="conversionEndDate"
                onChange={formik.handleChange}
                value={formik.values.conversionEndDate}
                isInvalid={!!(formik.touched.conversionEndDate && formik.errors.conversionEndDate)}
              />
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-outline-theme mb-1">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fa fa-save fa-fw me-1"></i>
            )}
            {t('Save')}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-outline-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default EditDailyRates;
