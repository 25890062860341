import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
import { GetRealEstateList } from "../../store/SU/realEstateSlice";

const useGetRealEstateList = ()=>{
    const dispatch = useDispatch();

    useEffect(()=>{
      if(RealEstateList?.length === 0 ){
        dispatch(GetRealEstateList())
      }
      },[dispatch])

    const { RealEstateList ,loading } = useSelector((state) => state.RealState); 

    return { RealEstateList , loading  } ;
};

export default useGetRealEstateList ;