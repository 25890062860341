import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  GetReportChartById,
  GetReportChartByReportId,
  UpdatNtxReportChart,
} from "../../../../../store/NTX/Reports/ReportShape/reportShapeSlice";
import { useFormik } from "formik";
import CustomAlert from "../../../../../components/Shared/Alert/CustomAlert";
import Loader from "../../../../../components/Shared/Loader/Loader";

interface props {
  ID: string;
}

const UpdateReportShape: React.FC<props> = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [datatype, setDatatype] = useState<any>();
  const { id } = useParams<{ id: string }>();

  const { ListReportChartById, ReportColumns, ChartShapesList, loadingUpdate } =
    useAppSelector((state) => state.ReportShape);

  useEffect(() => {
    if (props.ID) {
      dispatch(GetReportChartById(props.ID));
    }
  }, [props.ID]);

  const ChartShapesListForSelect = ChartShapesList.map((item) => ({
    value: item.id,
    label: (
      <>
        <i className={item.icon}></i>
        {i18n.language === "ar" ? item.name2 : item.name}
      </>
    ),
    maxKpi: item.maxKpi,
  }));

  ////_______________________________________________________________________
  useEffect(() => {
    setDatatype(
      ReportColumns?.reportColumnsList?.filter(
        (f) => f.name === ListReportChartById?.groupBy
      )[0]?.dataType
    );
  }, [ListReportChartById, ReportColumns]);

  const formik = useFormik({
    initialValues: {
      id: ListReportChartById ? ListReportChartById.id : "",
      reportId: id,
      shapeId: ListReportChartById ? ListReportChartById.shapeId : "",
      name: ListReportChartById ? ListReportChartById.name : "",
      name2: ListReportChartById ? ListReportChartById.name2 : "",
      groupBy: ListReportChartById ? ListReportChartById.groupBy : "",
      dateFormat: ListReportChartById ? ListReportChartById.dateFormat : "",

      maxKpi:
        ListReportChartById &&
        ChartShapesListForSelect?.filter(
          (f) => f.value === ListReportChartById.shapeId
        )[0].maxKpi,
      reportChartKpiList:
        ListReportChartById?.reportChartKpiList.map((item: any, index) => {
          return {
            index: ++index, // not for post
            groupByValue: "", // not for post

            id: item.id,
            reportChartId: item.reportChartId,
            columnName: item.columnName,
            kpiName: item.kpiName,
            dbFunction: item.dbFunction,
            groupBy: item.groupBy,
            dataType: ReportColumns?.reportColumnsList?.filter(
              (f) => f.name === item.columnName
            )[0]?.dataType,
          };
        }) || [],
    },
    enableReinitialize: true,
    // validationSchema: ValidationSchemaAddChart(t),
    onSubmit: (values) => {
      dispatch(
        UpdatNtxReportChart({
          id: values.id,
          reportId: id || null,
          shapeId: values.shapeId,
          name: values.name,
          name2: values.name2,
          groupBy: values.groupBy,
          dateFormat: datatype === "date" ? values.dateFormat : "",
          reportChartKpiList: values.reportChartKpiList.map((item) => ({
            id: item.id || null,
            reportChartId: id || null,
            kpiName: item.kpiName,
            columnName: item.columnName,
            dbFunction: item.dbFunction,
            groupBy: item.groupBy,
          })),
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: "Edit" });
            document.getElementById("closeModalUpdateReportShape")?.click();
            dispatch(GetReportChartByReportId(id || ""));
            formik.setSubmitting(false);
          } else {
            CustomAlert({ action: "Error" });
            formik.setSubmitting(false);
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  ////_______________________________________________________________________

  const handleAddField = () => {
    const newField = {
      index:
        formik.values.reportChartKpiList &&
        formik.values.reportChartKpiList?.length + 1,
      columnName: "",
      kpiName: "",
      dbFunction: "",
      groupBy: "",
      dataType: "",
      groupByValue: "",
    };

    if (formik.values.reportChartKpiList) {
      formik.setFieldValue("reportChartKpiList", [
        ...formik.values.reportChartKpiList,
        newField,
      ]);
    }

    // setFormFields([...formFields, newField]);
  };

  const handleRemoveField = (index: number) => {
    if (formik.values.reportChartKpiList) {
      const updatedFields = formik.values.reportChartKpiList?.filter(
        (field) => field.index !== index
      );

      formik.setFieldValue("reportChartKpiList", updatedFields);
    }
  };

  useEffect(() => {
    if (formik.values.maxKpi === 1) {
      const newFormFields = [formik.values.reportChartKpiList[0]];
      formik.setFieldValue("reportChartKpiList", newFormFields);
    }
  }, [formik.values.maxKpi]);

  //____________________________________________

  return (
    <>
      <Loader loading={loadingUpdate} />
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.submitForm();
        }}
      >
        <div className="row">
          <div className="col-xl-2">
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="exampleFormControlSelect1">
                {t("LocalName")}
              </label>
              <Form.Control
                className="form-control"
                type="text"
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2 || ""}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name2}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-2">
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="exampleFormControlSelect1">
                {t("GlobalName")}
              </label>
              <Form.Control
                className="form-control"
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name || ""}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t("Shape")}</label>

              <Select
                classNamePrefix="react-select"
                isClearable
                isSearchable={true}
                options={ChartShapesListForSelect}
                onChange={(option: any) => {
                  formik.setFieldValue(
                    "shapeId",
                    option === null ? "" : option.value
                  );
                  formik.setFieldValue(
                    "maxKpi",
                    option === null ? "" : option.maxKpi
                  );
                }}
                placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                value={ChartShapesListForSelect?.filter(
                  (f) => f.value === formik.values.shapeId
                )}
                // defaultValue={ChartShapesListForSelect?.filter( f => f.value === formik.values.shapeId)}
              />

              {formik.errors.shapeId && formik.touched.shapeId && (
                <div style={{ color: "red" }}>{formik.errors.shapeId}</div>
              )}
            </div>
          </div>

          <div className={datatype !== "date" ? "col-xl-4" : "col-xl-2"}>
            <div className="form-group mb-3">
              <label className="form-label">Axis X</label>
              <Form.Select
                className="form-select"
                name="groupBy"
                value={formik.values.groupBy || ""}
                // onChange={formik.handleChange}
                onChange={(e) => {
                  formik.handleChange(e);
                  setDatatype(e.target.selectedOptions[0].dataset.dataType);
                }}
                isInvalid={!!(formik.touched.groupBy && formik.errors.groupBy)}
              >
                <option value="">
                  {i18n.language === "ar" ? "إختر" : "Choose"}
                </option>
                {ReportColumns &&
                  ReportColumns.reportColumnsList.map((item: any, idx) => (
                    <option
                      key={item.id}
                      value={item.name}
                      data-data-type={item.dataType}
                    >
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.groupBy}
              </Form.Control.Feedback>
            </div>
          </div>

          {datatype === "date" && (
            <div className="col-xl-2">
              <div className="form-group mb-3">
                <label className="form-label">{t("Format_Date")}</label>
                <select
                  className="form-select"
                  name="dateFormat"
                  value={formik.values.dateFormat || ""}
                  onChange={formik.handleChange}
                >
                  <option value="">
                    {i18n.language === "ar" ? "إختر" : "Choose"}
                  </option>

                  <option value="date" title="2020-02-20">
                    date
                  </option>
                  <option value="year" title="2020">
                    year
                  </option>
                  <option value="month_number" title="02">
                    month number
                  </option>
                  <option value="year_month_number" title="2020-02">
                    year month number
                  </option>
                  <option value="year_month_short" title="2020-Feb">
                    year month short
                  </option>
                  <option value="month_name_short" title="Feb">
                    month name short
                  </option>
                  <option value="month_name_long" title="February">
                    month name long
                  </option>
                  <option value="day_of_week_number" title="5">
                    day of week number
                  </option>
                  <option value="day_of_week" title="Thursday">
                    day of week
                  </option>
                  <option value="day_of_week_short" title="Thu">
                    day of week short
                  </option>
                  <option value="quarter" title="Q1">
                    quarter
                  </option>
                  <option value="year_quarter" title="2020-Q1">
                    year quarter
                  </option>
                  <option value="week_number" title="8">
                    week number
                  </option>
                </select>
              </div>
            </div>
          )}

          <div style={{ paddingTop: "28px" }}>
            {formik.values.reportChartKpiList &&
              formik?.values?.reportChartKpiList?.map((field, idx) => (
                <div className="row" key={field.index}>
                  <div className="col-xl-4">
                    <div className="input-group c">
                      <span className="input-group-text">{t("Kpi_Name")} </span>
                      <Form.Control
                        className="form-control"
                        type="text"
                        name={`reportChartKpiList[${field.index - 1}].kpiName`}
                        value={field.kpiName || ""}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>

                  {/* 
                    توقف حتى اشعار الخيار
                    <div className="col-xl-3">
                      <div className="input-group c">
                        <span className="input-group-text">Group By</span>
                        <select
                          className="form-select"
                          // name="columnName"
                          name={`reportChartKpiList[${
                            field.index - 1
                          }].groupBy`}
                          value={field.groupBy}
                          // onChange={formik.handleChange}
                          onChange={(e) => {
                            formik.handleChange(e);
                            // setGroupByValue(e.target.value);
                            formik.setFieldValue(
                              `reportChartKpiList[${field.index - 1}].groupByValue`,
                              e.target.value
                            );
                          }}
                        >
                          <option value="">
                            {i18n.language === "ar" ? "إختر" : "Choose"}
                          </option>
                          {ReportColumnsList &&
                            ReportColumnsList.map((item: any, idx) => (
                              <option key={item.id} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

 */}

                  <div className="col-xl-4">
                    <div className="input-group c">
                      <span className="input-group-text">{t("Column")}</span>
                      <select
                        className="form-select"
                        name={`reportChartKpiList[${
                          field.index - 1
                        }].columnName`}
                        value={field.columnName || ""}
                        // onChange={formik.handleChange}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue(
                            `reportChartKpiList[${field.index - 1}].dataType`,
                            e.target.selectedOptions[0].dataset.dataType
                          );
                        }}
                      >
                        <option value="">
                          {i18n.language === "ar" ? "إختر" : "Choose"}
                        </option>
                        {ReportColumns &&
                          ReportColumns.reportColumnsList
                            ?.filter(
                              (f: any) =>
                                f.name !==
                                formik.values.reportChartKpiList[
                                  field.index - 1
                                ]?.groupByValue
                            )
                            .map((item: any, idx) => (
                              <option
                                key={item.id}
                                value={item.name || ""}
                                data-data-type={item.dataType}
                              >
                                {i18n.language === "ar"
                                  ? item.name2
                                  : item.name}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>

                  <div
                    className={
                      formik.values.maxKpi === 5 ? `col-xl-3` : `col-xl-4`
                    }
                  >
                    <div className="input-group c">
                      <span className="input-group-text">{t("Function")}</span>

                      <select
                        className="form-select"
                        name={`reportChartKpiList[${
                          field.index - 1
                        }].dbFunction`}
                        value={field.dbFunction || ""}
                        onChange={formik.handleChange}
                        // onChange={(e) => handleInputChange(field.index, e)}
                      >
                        <option value="">
                          {i18n.language === "ar" ? "إختر" : "Choose"}
                        </option>
                        <option value="count">Count</option>

                        {field.dataType === "number" && (
                          <>
                            <option value="max">Max</option>

                            <option value="min">Min</option>
                          </>
                        )}
                        {field.dataType === "number" && (
                          <>
                            <option value="avg">Avg</option>

                            <option value="sum">Sum</option>
                          </>
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-1" style={{ paddingBottom: "28px" }}>
                    {formik.values.maxKpi === 5 && (
                      <button
                        disabled={
                          formik.values.reportChartKpiList?.length === 1
                        }
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleRemoveField(field.index)}
                      >
                        {t("Button.Delete")}
                      </button>
                    )}
                  </div>
                </div>
              ))}

            {formik.values.maxKpi === 5 && (
              <div className="text-right">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAddField}
                >
                  {t("Add")}
                </button>
              </div>
            )}
          </div>
        </div>

        <hr className="mb-4" />

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
          >
            <i className="fa fa-save fa-fw me-1"></i> {t("Save")}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalUpdateReportShape"
          >
            <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          </button>
        </div>
      </Form>
    </>
  );
};

export default UpdateReportShape;
