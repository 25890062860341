import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// import { getData, postData } from "../../Axios/axiosData";
import { ntxAPI } from '../../../API/axiosNeatex';

const initialState = {
  Organizations: [],
  OrganizationWithPermissionList: [],
  loading: false,
  error: null,
  Organization: [],
  loadingById: false,
};

export const insertOrganization = createAsyncThunk(
  'Organizations/insertOrganization',
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("Organization/AddOrganization" , item);
      const { data } = await ntxAPI.post('Organization/AddOrganization', item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getOrganizations = createAsyncThunk(
  'Organizations/getOrganizations',
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;

    //   const cachedOrg = getState().Organizations.Organizations
    // if (cachedOrg.length > 0) {
    //   return cachedOrg;
    // }

    try {
      const { data } = await ntxAPI.get('Organization/GetOrganizationList');
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getOrganization = createAsyncThunk(
  'Organizations/getOrganization',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.get(`Organization/GetOrganizationById?guid=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const EditOrganization = createAsyncThunk(
  'Organizations/EditOrganization',
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("Organization/UpdateOrganization" , item);
      const { data } = await ntxAPI.post('Organization/UpdateOrganization', item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getOrganizationWithPermissionByType = createAsyncThunk(
  'Organizations/getOrganizationWithPermissionByType',
  async (type, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;

    try {
      const { data } = await ntxAPI.get(
        `Organization/GetOrganizationWithPermissionByType?Type=${type}`
      );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

const OrganizationSlice = createSlice({
  name: 'Organizations',
  initialState,
  reducers: {
    cleanOrganization: (state) => {
      state.Organization = [];
    },
  },
  extraReducers(builder) {
    builder
      /////// get all Organizations
      .addCase(getOrganizations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOrganizations.fulfilled, (state, action) => {
        state.loading = false;
        state.Organizations = action.payload;
      })
      .addCase(getOrganizations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///////  get Organization With Permission ByType
      .addCase(getOrganizationWithPermissionByType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOrganizationWithPermissionByType.fulfilled, (state, action) => {
        state.loading = false;
        state.OrganizationWithPermissionList = action.payload;
      })
      .addCase(getOrganizationWithPermissionByType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // get one Org by id
      .addCase(getOrganization.pending, (state) => {
        state.loadingById = true;
        state.error = null;
      })
      .addCase(getOrganization.fulfilled, (state, action) => {
        state.loadingById = false;
        state.Organization = action.payload;
      })
      .addCase(getOrganization.rejected, (state, action) => {
        state.loadingById = false;
        state.error = action.payload;
      })

      ///////insert
      .addCase(insertOrganization.pending, (state) => {
        // state.loading = true;
        state.error = null;
      })
      .addCase(insertOrganization.fulfilled, (state, action) => {
        // state.loading = false;
        state.Organizations.push(action.payload);
      })
      .addCase(insertOrganization.rejected, (state, action) => {
        // state.loading = false;
        state.error = action.payload;
      })

      ///////Edit
      .addCase(EditOrganization.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(EditOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.Organization = action.payload;
      })
      .addCase(EditOrganization.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { cleanOrganization } = OrganizationSlice.actions;
export default OrganizationSlice.reducer;
