import { useFormik } from 'formik';
import React, { memo } from 'react';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';
import { AddDynamicForm, UodateDynamicForm } from '../../../../store/NTX/FlexValue/Form/formSlice';
// import {
//   DynamicFormSchema,
//   formJob,
// } from "../../ValidationForm/validationSchema";
import { useTranslation } from 'react-i18next';

import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { DataIcons } from '../../../../assets/icons';
import useGetStaticFormList from '../../../../hooks/Forms/use-get-StaticForm';
import useLookupModule from '../../../../hooks/Lookups/use-Lookup-Module';
import { DynamicFormAmeSchema } from '../../../ValidationForm/validationSchema';

const AddForm = ({ Idmodul }) => {
  const { StaticFormList } = useGetStaticFormList();
  const { Module } = useLookupModule();

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      IdStaticForm: '',
      isStatic: false,
      name2: '',
      name: '',
      icon: '',
      moduleId: '',
      dynamicFormTypesId: 1, // 1 Self Service  نماذج الخدمة الذاتية
      tableId: null,
    },
    validationSchema: DynamicFormAmeSchema(t),
    onSubmit: (values) => {
      dispatch(
        values.isStatic
          ? UodateDynamicForm({
              id: values.IdStaticForm,
              name: values.name,
              moduleId: values.moduleId,
              name2: values.name2,
              icon: values.icon,
              isStatic: values.isStatic,
              // tableId : values.tableId,
              dynamicFormTypesId: values.dynamicFormTypesId,
            })
          : AddDynamicForm({
              name2: values.name2,
              name: values.name,
              moduleId: values.moduleId,
              icon: values.icon,
              isStatic: values.isStatic,
              dynamicFormTypesId: values.dynamicFormTypesId,
              // tableId : values.tableId
            })
      )
        .unwrap()
        .then(() => {
          document.getElementById('closeModalForm').click();
          formik.resetForm();
          // dispatch(GetDynamicForm());
          CustomAlert({ action: 'Add' });
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('LocalName')}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name2"
              onChange={formik.handleChange}
              value={formik.values.name2}
              isInvalid={formik.touched.name2 && formik.errors.name2}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('GlobalName')}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isInvalid={formik.touched.name && formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.name2}</Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('Application')}</label>
            <Form.Select
              className="form-select"
              name="moduleId"
              value={formik.values.moduleId}
              onChange={formik.handleChange}
              isInvalid={formik.touched.moduleId && formik.errors.moduleId}>
              <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
              {Module &&
                Module.map((item, idx) => (
                  <option key={++idx} value={item.id}>
                    {i18n.language === 'ar' ? item.name2 : item.name}
                  </option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">{formik.errors.moduleId}</Form.Control.Feedback>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('Icon')}</label>

            <Select
              classNamePrefix="react-select"
              // classNamePrefix="select"
              isSearchable={true}
              options={DataIcons}
              onChange={(option) => formik.setFieldValue('icon', option.value)}
              placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
            />
          </div>
        </div>

        <div className="col-xl-1"></div>

        <div className="col-xl-1">
          <div className="form-group mb-3 text-nowrap">
            <label className="form-label">{t('IsStatic')}</label>
            <Form.Check
              style={{ margin: '10px 20px' }}
              type="checkbox"
              label=""
              name="isStatic"
              value={formik.values.isStatic}
              onChange={formik.handleChange}
            />
          </div>
        </div>

        {formik.values.isStatic && (
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('StaticForms')}</label>
              <Form.Select
                className="form-select"
                name="IdStaticForm"
                onChange={formik.handleChange}
                value={formik.values.IdStaticForm}
                isInvalid={!!formik.errors.IdStaticForm}>
                <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {StaticFormList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {i18n.language === 'ar' ? item.name2 : item.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.IdStaticForm}
              </Form.Control.Feedback>
            </div>
          </div>
        )}
      </div>

      <div className="text-center">
        <button
          disabled={formik.isSubmitting}
          type="submit"
          className="btn me-1 btn-outline-theme mb-1">
          {formik.isSubmitting ? (
            <div className="spinner-border spinner-border-sm me-2"></div>
          ) : (
            <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
          )}
          {t('Add')}
        </button>

        <button
          type="button"
          className=" btn me-1 btn-outline-default mb-1"
          data-bs-dismiss="modal"
          id="closeModalForm">
          <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
        </button>
      </div>
    </Form>
  );
};

export default memo(AddForm);
