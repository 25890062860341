import React, { useState } from 'react';
import i18next from 'i18next';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const NtxLanguage = () => {
  const { t } = useTranslation();

  const [language, setLanguage] = useState(() => {
    const storedLanguage = localStorage && localStorage.getItem('language');
    return storedLanguage !== null ? storedLanguage : 'ar';
  });

  const SaveLanguage = () => {
    localStorage.setItem('language', language);
    i18next.changeLanguage(language);
    setLanguage(language);
    window.location.reload();
  };

  return (
    <>
      {/* <div className="radio-with-Icon text-center">

        <p className="radioOption-Item mx-2 ">
          <input
            type="radio"
            name="BannerTypes"
            id="BannerType1"
            value="true"
            className="ng-valid ng-dirty ng-touched ng-empty"
          />
          <label htmlFor="BannerType1">
          <i className="fa fa-language"></i>
          
             العربية
          </label>
        </p>

        <p className="radioOption-Item mx-2 ">
          <input
            type="radio"
            name="BannerTypes"
            id="BannerType2"
            value="false"
            className="ng-valid ng-dirty ng-touched ng-empty"
          />
          <label htmlFor="BannerType2">
          <i className="fa fa-language"></i>
           English
          </label>
        </p>
      </div> */}
      <div className="col-xl-12">
        <div className="form-group mb-3">
          <label className="form-label">{t('ChooseLanguage')}</label>
          <Form.Select
            className="form-select"
            name="Language"
            value={language}
            onChange={(e) => setLanguage(e.target.value)}>
            <option value="en">English</option>
            <option value="ar">العربية</option>
          </Form.Select>
        </div>

        <div className="text-center mt-5">
          <button
            // disabled={isSubmitting}
            type="button"
            className="btn me-1 btn-theme mb-1"
            onClick={() => SaveLanguage()}>
            {false ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fa fa-save fa-fw me-1"></i>
            )}
            {t('Save')}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </div>
    </>
  );
};

export default NtxLanguage;
