import React, { useContext, useEffect, useState } from "react";
import { AppSettings } from "../config/app-settings"

 

const useGetSysName = () => {

  const context = useContext(AppSettings);

  var sysName = context.sysName

  // useEffect(() => {

  // }, []);

  return sysName ;
};

export default useGetSysName;
