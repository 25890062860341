import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { GetNtxPeoplesList } from '../../../store/NTX/Peoples/peoplesSlice';
import { useTranslation } from 'react-i18next';

const useGetSuppliersList = () => {
  const { i18n } = useTranslation();

  const dispatch = useAppDispatch();
  const { PeoplesList, loading, error } = useAppSelector((state) => state.Peoples);
  useEffect(() => {
    if (SuppliersList?.length === 0) {
      dispatch(GetNtxPeoplesList('SUPPLIERS'));
    }
  }, []);

  var SuppliersList = PeoplesList;
  var OptionSuppliers = SuppliersList.map((item) => ({
    value: item.id,
    label: i18n.language === 'en' ? item.name : item.name2,
    id: item.id,
    parentId: item.parentId,
  }));

  const convertToTreeData = () => {
    const map = new Map();
    const treeData: { title: string; value: string; children: never[] }[] = [];

    OptionSuppliers?.forEach((item) => {
      const node = {
        title: item.label,
        value: item.id,
        children: [],
      };

      map.set(item.id, node);

      if (item.parentId === null) {
        treeData.push(node);
      } else {
        const parent = map.get(item.parentId);
        if (parent) {
          parent.children.push(node);
        }
      }
    });

    return treeData;
  };

  const treeSuppliersData = convertToTreeData();

  return {
    SuppliersList: SuppliersList,
    OptionSuppliers: OptionSuppliers,
    treeSuppliersData: treeSuppliersData,
    loading,
    error,
  };
};

export default useGetSuppliersList;
