import { useState, useEffect } from 'react';
// import { DecryptString } from '../Encrypt/NtxEncrypt';
import { useAppSelector } from '../../store/hooks';

export const usePermissions = () => {
  const [permissions, setPermissions] = useState<string[]>([]);

  const Permission = useAppSelector((s) => s.userLogin?.AuthUser?.permission);

  useEffect(() => {
    // const decryptedPermissions = DecryptString(localStorage.Permission || "");
    const permissionsArray = Permission.split(',');
    setPermissions(permissionsArray);
  }, []);

  const HasPermission = (permission: string) => {
    return permissions.includes(permission);
  };

  return { HasPermission };
};
