import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getTransactionTypesMoveList } from '../../../store/INV/Transactions/InvMove/invMoveSlice';
import { useTranslation } from 'react-i18next';

const useGetTransactionTypesMoveList = () => {
  const dispatch = useAppDispatch();
  const {i18n ,t} = useTranslation();

  useEffect(() => {
    if (TransactionTypes?.length === 0) {
      dispatch(getTransactionTypesMoveList());
    }
  }, []);
  const {
    TransactionTypesMoveList: TransactionTypes,
    loading,
    error,
  } = useAppSelector((state) => state.InvMove);

  const OptionsTransactionTypes = TransactionTypes?.map(item => ({
    value : item.code,
    label : i18n.language === 'en'? item.name : item.name2,
  }))

  return { TransactionTypes, OptionsTransactionTypes,  loading, error };
};

export default useGetTransactionTypesMoveList;
