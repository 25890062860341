import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { NotificationRequestModels } from './notificationRequestModels';

export const addNotificationRequest = createAsyncThunk(
  'NotificationRequest/addNotificationRequest',
  async (item: any, { rejectWithValue }) => {

    
    try {
      const { data } = await ntxAPI.post('AmeSetting/AddNotificationRequest', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateNotificationRequest = createAsyncThunk(
  'NotificationRequest/updateNotificationRequest',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('AmeSetting/UpdateNotificationRequest', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getNotificationRequstByFormId = createAsyncThunk(
  'addNotificationRequest/getNotificationRequstByFormId',
  async (DynamicFormId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(
        `AmeSetting/GetNotificationRequstByFormId?DynamicFormId=${DynamicFormId}`
      );
      if (data?.succeeded === true) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  NotificationRequestById: NotificationRequestModels;
  loading: boolean;
  loadingById: boolean;
  error: any | null;
} = {
  NotificationRequestById: {} as NotificationRequestModels,
  loading: false,
  loadingById: false,
  error: null,
};

const notificationRequestSlice = createSlice({
  name: 'NotificationRequest',
  initialState,
  reducers: {
    cleanNotificationRequestById: (state) => {
      state.NotificationRequestById = {} as NotificationRequestModels;
    },
  },
  extraReducers(builder) {
    builder

      .addCase(addNotificationRequest.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear any previous errors when creating a post
      })
      .addCase(addNotificationRequest.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        // state.NotificationsList.push(action.payload);
      })
      .addCase(addNotificationRequest.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getNotificationRequstByFormId.pending, (state, action) => {
        state.loadingById = true;
      })
      .addCase(
        getNotificationRequstByFormId.fulfilled,
        (state, action: PayloadAction<NotificationRequestModels>) => {
          state.loadingById = false;
          state.NotificationRequestById = action.payload;
        }
      )
      .addCase(getNotificationRequstByFormId.rejected, (state, action: PayloadAction<any>) => {
        state.loadingById = false;
        state.error = action.payload;
      });
  },
});

export const {cleanNotificationRequestById} = notificationRequestSlice.actions;
export default notificationRequestSlice.reducer;
