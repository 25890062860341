import React, { useState } from 'react';
import MedModalComponent from '../../../../components/Modal/MedModalComponent';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import Loader from '../../../../components/Shared/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { Link, useNavigation } from 'react-router-dom';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import AddLedgers from './AddLedgers';
import useGetGlLedgersList from '../../../../hooks/Finance/useGetGlLedgersList';
import UpdateLedgers from './UpdateLedgers';
import { LedgersModels } from '../../../../store/Finance/Ledgers/LedgersModels';
import Loading from '../../../../components/Shared/Loader/Loading';

const Index = () => {
  const [Show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigation();
  const { LedgersList, loading, error } = useGetGlLedgersList();

  const [dataById, setDataById] = useState<LedgersModels>(); // for sent as props to Update component PeriodsModels

  const filteredSearch = LedgersList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'الأستاذ العام',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="الأستاذ العام" />

        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-outline-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAddLedgers"
            onClick={() => setShow(true)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            اضافة استاذ عام
          </Link>
        </div>
      </div>
      <Card>
        <Loader loading={loading} />
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="tab-content p-1">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-2">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder={t('Search') + ' ...'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table table-hover text-nowrap">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t('Name')}</th>
                      <th scope="col">{t('العملة')}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle">
                          {' '}
                          {i18n.language === 'en' ? item.name : item.name2}{' '}
                        </td>
                        <td className="align-middle"> {item.currencyCode} </td>
                        {/* <td> { item.startDate.toString()} </td>
                        <td> { item.endDate.toString()} </td> */}
                        <td className="align-middle">
                          <button
                            type="button"
                            className="btn btn-outline-theme"
                            data-bs-toggle="modal"
                            data-bs-target="#modalUpdateLedgers"
                            onClick={() => setDataById(item)}>
                            <i className="far fa-lg fa-fw mx-2 fa-edit"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </CardBody>
        </Loading>
      </Card>

      <MedModalComponent title="اضافة استاذ عام" id="modalAddLedgers">
        {Show && <AddLedgers />}
      </MedModalComponent>

      <MedModalComponent title="تعديل الأستاذ العام" id="modalUpdateLedgers">
        {dataById && <UpdateLedgers DataById={dataById} />}
      </MedModalComponent>
    </div>
  );
};

export default Index;
