import { t } from 'i18next';

export const Translations = {
  uploadStep: {
    title: t('UploadFile'),
    manifestTitle: t('Data_that_we_expect') + ':',
    manifestDescription: t('You_will_have_a_chance_to_rename_or_remove_columns_in_next_steps'),
    dropzone: {
      // title: "string",
      // errorToastDescription: "string",
      // activeDropzoneTitle: "string",
      // buttonTitle: t('SelectFile'),
      buttonTitle: (
        <>
          <i className="fa-lg fa-fw me-2 bi bi-arrow-bar-down"></i>
          {t('SelectFile')}
        </>
      ),
      // loadingTitle: "string",
    },
  },
  selectHeaderStep: {
    title: t('Select_header_row'),
    nextButtonTitle: (
      <>
        {/* <i className="fas fa-arrow-right"></i> */}
        {t('Next')}
      </>
    ),
    backButtonTitle: 'back',
  },
  matchColumnsStep: {
    title: t('Match_Columns'),
    nextButtonTitle: t('Next'),
    // backButtonTitle: "",
    userTableTitle: t('Your_table'),
    templateTitle: t('Will_become'),
    // selectPlaceholder: "1"
    // ignoredColumnText: "2"
    // subSelectPlaceholder: "اختر"
    matchDropdownTitle: t('Match'),
    unmatched: t('UnMatched'),
    // duplicateColumnWarningTitle: "33" ,
    duplicateColumnWarningDescription: t('Columns_cannot_duplicate'),
  },
  validationStep: {
    title: t('Validate_data'),
    nextButtonTitle: t('Confirm'),
    // backButtonTitle: string;
    // noRowsMessage: "dd"
    // noRowsMessageWhenFiltered: "dd"
    discardButtonTitle: t('Discord_selected_rows'),
    filterSwitchTitle: t('Show_only_rows_with_errors'),
  },
  alerts: {
    confirmClose: {
      headerTitle: t('Exit_import_flow'),
      bodyText: t('Are_you_sure_Your_current_information_will_not_be_saved'),
      cancelButtonTitle: t('Cancel'),
      exitButtonTitle: t('Exit'),
    },
    submitIncomplete: {
      headerTitle: 'Errors detected',
      bodyText:
        'There are still some rows that contain errors. Rows with errors will be ignored when submitting.',
      bodyTextSubmitForbidden: 'There are still some rows containing errors.',
      cancelButtonTitle: 'Cancel',
      finishButtonTitle: 'Submit',
    },
    submitError: {
      title: 'Error',
      defaultMessage: 'An error occurred while submitting data',
    },
    unmatchedRequiredFields: {
      headerTitle: t('Not_all_columns_matched'),
      bodyText: `${t('There_are_required_columns_that_are_not_matched_or_ignored_Do_you_want_to_continue')}?`,
      listTitle: `${t('Columns_not_matched')}:`,
      cancelButtonTitle: t('Cancel'),
      continueButtonTitle: t('Continue'),
    },
    toast: {
      error: 'Error',
    },
  },
};
