import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../../../../components/Shared/Pagination/Pagination";

const RsponseData = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { i18n } = useTranslation();

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = data?.slice(indexOfFirstData, indexOfLastData);

  return (
    <div className="mt-2">
      <table className="table  table-sm">
        <thead>
          <tr>
            <th scope="col">.</th>
          </tr>
        </thead>
        <tbody>
          {currentData?.map((item , idx) => (
            <tr key={idx}>
              <td>{i18n.language === "en" ? item.name : item.name2}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        filteredData={data}
        dataPerPage={dataPerPage}
        handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
        currentPage={currentPage}
      />
    </div>
  );
};

export default RsponseData;
