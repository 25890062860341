import React, { useRef, useState } from "react";
import ViewFile from "../../../Attachments/ViewFile.js";
import { Link } from "react-router-dom";

const AttachmentList = ({ attachments, setAttachments }) => {
  // const [attachments, setAttachments] = useState([]);
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles(selectedFiles);
  };

  const handleAddButtonClick = () => {
    setAttachments([...attachments, ...files]);
    setFiles([]); // Clear the files state
    fileInputRef.current.value = null; // Reset the file input
  };

  const onDeleteFile = (index) => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);
    setAttachments(updatedAttachments);
  };

  return (
    <div>
      <div className="row">
        <div className="col-xl-10">
          <div className="form-group mb-3">
            <label className="form-label"></label>
            <input
              className="form-control"
              type="file"
              multiple
              onChange={(e) => handleFileChange(e)}
              ref={fileInputRef}
            />
          </div>
        </div>
        <div className="col-xl-2 pt-4">
          <button onClick={handleAddButtonClick} className="btn btn-primary">
            إضافة
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-9">
          {attachments &&
            attachments.map((attachment, index) => (
              <div className="row align-items-center" key={index}>
                <div className="col-xl-11">
                  <ViewFile Attachment={attachment} />
                </div>
                
                <div className="col-xl-1">
                  <Link
                  to=""
                    style={{ color: "red" , zIndex:"1000" }}
                    className="position-relative"
                    onClick={() => onDeleteFile(index)}
                  >
                    <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
                  </Link>
                </div>
                <hr className="mt-1" />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AttachmentList;

// <div key={index} className="file-item">
//   <i className="fas fa-file fa-lg"></i>
//   <span>{file.name}</span>
// <Link style={{ color: "red" }} onClick={() => onDeleteFile(index)}>
//   <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
// </Link>
// </div>
