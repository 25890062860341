import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { PurchaseRequestsModels } from './purchaseRequestsModels';

export const addPurchaseRequest = createAsyncThunk(
  'PurchaseRequest/addPurchaseRequest',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('PurchaseRequest/Add', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updatePurchaseRequest = createAsyncThunk(
  'PurchaseRequest/updatePurchaseRequest',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('PurchaseRequest/Update', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPurchaseRequestList = createAsyncThunk(
  'PurchaseRequest/getPurchaseRequestList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('PurchaseRequest/GetList');
      if (data?.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPurchaseRequestById = createAsyncThunk(
  'PurchaseRequest/getPurchaseRequestById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`PurchaseRequest/GetById?Id=${id}`);
      if (data?.succeeded) {
        // ===
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  PurchaseRequestsList: PurchaseRequestsModels[];
  PurchaseRequestsById: PurchaseRequestsModels;
  loading: boolean;
  error: any | null;
} = {
  PurchaseRequestsList: [],
  PurchaseRequestsById: {} as PurchaseRequestsModels,
  loading: false,
  error: null,
};

const PurchaseRequestsSlice = createSlice({
  name: 'PurchaseRequest',
  initialState,
  reducers: {
    cleanUpPurchaseRequestById: (state) => {
      state.PurchaseRequestsById = {} as PurchaseRequestsModels;
    },
  },
  extraReducers(builder) {
    builder

      .addCase(getPurchaseRequestList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getPurchaseRequestList.fulfilled,
        (state, action: PayloadAction<PurchaseRequestsModels[]>) => {
          state.loading = false;
          state.PurchaseRequestsList = action.payload;
        }
      )
      .addCase(getPurchaseRequestList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getPurchaseRequestById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getPurchaseRequestById.fulfilled,
        (state, action: PayloadAction<PurchaseRequestsModels>) => {
          state.loading = false;
          if (action.payload && typeof action.payload === 'object') {
            state.PurchaseRequestsById = action.payload;
          } else if (action.payload === null) {
            state.PurchaseRequestsById = {} as PurchaseRequestsModels;
          }
          // state.PurchaseRequestsById = action.payload;
        }
      )
      .addCase(getPurchaseRequestById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { cleanUpPurchaseRequestById } = PurchaseRequestsSlice.actions;
export default PurchaseRequestsSlice.reducer;
