import React from 'react'
import NtxPeoples from '../../../NTX/Peoples/NtxPeoples'
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';



const EditSuppliers : React.FC  = () => {

 
  
//________________________________
var BcrumbList = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Suppliers",
    link: '/FLEETSettings/Suppliers',
  },
  {
    name: "EditSuppliers",
    link: null,
  },
];

var fieldList = [
  "typeCode",
  "customerNumber",
  "personId",
  "billToLocationId",
  "shipToLocationId",
  // "termsId", // الشروط للعملاء . لها جدول محدد
  // "setOfBooksId", // جدول الاستاذ العام
  "creditLimit", // سقف الدين
  "paymentPriority", // أولوية الدفع لووكآب  موجود في المورد وليس في العميل
  "invoiceCurrencyCode", //
  "paymentCurrencyCode", //
  "invoiceLimit", // موجود في المورد وليس في العميل ونوعه رقم 
  "bankAccountName", // 
  "bankAccountNum", // 
  "vatCode", //  الرقم الضريبي للعميل
  "tradingName", // الاسم التجاري للعميل
  "workReference", // اسم الشخص
  "companyRegistrationNumber", // السجل التجاري للعميل
  "peopleCategory"

];


  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="EditSuppliers" />

      <Card>
        <CardBody>

        <NtxPeoples typeCode='SUPPLIERS' fieldList={fieldList} isUpdate={true} />

        </CardBody>
      </Card>
    </div>
  )
}

export default EditSuppliers
