import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteAmeRule, GetAmeRuleListByDynamicFormId } from '../../../../store/Ame/ameRuleSlice';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { usePermissions } from '../../../../Helper/Permisstions/usePermissions';
import showConfirmation from '../../../../components/Shared/Alert/Confirmation';

function AmeRuleList({ data }) {
  const { HasPermission } = usePermissions();

  const { id } = useParams();

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  // Handele Delete AmeCondition
  const handleDeleteAmeRule = (item) => {
    showConfirmation(t('MsgConfirm'), () => {
      dispatch(DeleteAmeRule(item.id))
        .unwrap()
        .then(() => {
          dispatch(GetAmeRuleListByDynamicFormId(id));
          CustomAlert({ action: 'Delete' });
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: 'Error' });
        });
    });
  };

  return (
    <>
      <div className="table-responsive rounded-2 text-center border border-theme">
        <table className="table table-hover text-nowrap mb-0">
          <thead className="bg-theme bg-opacity-60">
            <tr>
              {/* <th scope="col">الرقم</th> */}
              <th scope="col">{t('Name')}</th>
              <th scope="col"></th>
            </tr>
          </thead>

          <tbody>
            {data.map((item, idx) => (
              <tr key={item.id}>
                {/* <td className="align-middle">{++idx}</td> */}
                <td className="align-middle">{i18n.language === 'ar' ? item.name2 : item.name}</td>
                <td className="align-middle text-end">
       
                  <div className="btn-group">
                    {HasPermission('AmeRuleConditions') && (
                      <button
                        onClick={() => Navigate(`${item.id}/AddAmeConditionUsage`)}
                        type="button"
                        className="btn btn-outline-theme">
                        <i className="fas fa-lg fa-fw me-2 fa-question"></i>
                        {t('RuleConditions')}
                      </button>
                    )}

                    {HasPermission('AmeSetRuleApprovals') && (
                      <button
                        onClick={() => Navigate(`${item.id}/AddAmeAction`)}
                        type="button"
                        className="btn btn-outline-lime">
                        <i className="fas fa-lg fa-fw me-2 fa-list-ol"></i>
                        {t('ApprovalGroup')}
                      </button>
                    )}

                  </div>
                  {HasPermission('AmeDeleteRule') && (
                      <>
                        <button
                          type="button"
                          className="btn btn-outline-danger mx-1 "
                          onClick={() => handleDeleteAmeRule(item)}>
                          <i className="fas fa-trash-alt fa-fw fa-lg"></i>
                          {/* {t('Button.Delete')} */}
                        </button>
                      </>
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default AmeRuleList;
