import React, { useEffect, useState } from 'react';
import MedModalComponent from '../../../components/Modal/MedModalComponent';
// import Pagination from "../../../components/Shared/Pagination/Pagination";
import { Card, CardBody } from '../../../components/card/card';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import { useTranslation } from 'react-i18next';
// import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Link, useNavigate } from 'react-router-dom';
// import { GetGlChartOfAccountsList } from "../../../store/Finance/ChartAccounts/ChartAccountsSlice";
import AddChartAccounts from './AddChartAccounts';
import useGetChartAccountsDetailsList from '../../../hooks/Finance/useGetChartAccountsDetailsList';
import useGetChartAccountsList from '../../../hooks/Finance/useGetChartAccountsList';
import Loading from '../../../components/Shared/Loader/Loading';

const Index = () => {
  const [Show, setShow] = useState(false);
  const [selectedacount, setSelectedaccount] = useState<any>('');
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const {
    ChartAccountsDetailsList,
    loading: loading2,
    error: error2,
  } = useGetChartAccountsDetailsList();
  const { ChartAccountsList, loading, error } = useGetChartAccountsList();

  const filteredSearch = ChartAccountsDetailsList?.slice()
    ?.filter(
      (f) =>
        f.accountParant === null &&
        f.chartOfAccountsId?.toLowerCase() === selectedacount?.toLowerCase() &&
        f.id !== "f289092c-0842-4676-af1a-736df6388f1e" //"Future"
    )
    .sort((a, b) => a.sequenceNum - b.sequenceNum);

  useEffect(() => {
    setSelectedaccount(ChartAccountsList[0]?.id);
  }, [ChartAccountsList]);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'الدليل المحاسبي',
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="الدليل المحاسبي" />
      <hr />

      <Loading loading={loading || loading2} error={error || error2}>
        <CardBody>
          <div className="p-2">
            <div className="row mb-3">
              <div className="col-7 col-md-6 d-flex justify-content-start">
                <div className="input-group mb-2">
                  <div className="flex-fill position-relative">
                    <div className="input-group ">
                      <select
                        className="text-theme fw-bold bg-inverse bg-opacity-20 btn btn-lg btn-outline-default dropdown-toggle p-2 mb-1 w-75 "
                        value={selectedacount}
                        onChange={(e) => setSelectedaccount(e.target.value)}>
                        {ChartAccountsList &&
                          ChartAccountsList?.map((item) => (
                            <option value={item.id} key={item.id} className="dropdown-item ">
                              {i18n.language === 'en' ? item.name : item.name2}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {filteredSearch.map((item, index) => (
                <div className="col-xl-4 col-md-6 m-auto" key={item.id}>
                  <div className="mailbox-detail-content">
                    <div className="mailbox-attachment1 w-100" style={{ margin: '10px' }}>
                      <Link
                        onClick={() => {
                          if (item.accountType === 'Companies' && item.accountParant === null) {
                            Navigate(`${item.id}/CompaniesAndBranches`);
                          } else if (
                            item.accountType === 'CostCenters' &&
                            item.accountParant === null
                          ) {
                            Navigate(`${item.id}/CostCenters`);
                          } else if (
                            item.accountType === 'Accounts' &&
                            item.accountParant === null
                          ) {
                            Navigate(`${item.id}/ChartAccountsDetails`);
                          }
                        }}
                        to="">
                        <div className="document-file1 h-100px">
                          <i
                            className={
                              item.accountType === 'Companies' && item.accountParant === null
                                ? 'fas fa-building' // رمز يُعبر عن الشركات
                                : item.accountType === 'CostCenters' && item.accountParant === null
                                ? 'fas fa-coins' // رمز يُعبر عن مراكز التكلفة
                                : item.accountType === 'Accounts' && item.accountParant === null
                                ? 'fas fa-calculator' // رمز يُعبر عن الحسابات
                                : 'bi bi-menu-up pt-2'
                            }></i>
                        </div>
                        <div className="document-name">
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </CardBody>
      </Loading>

      <MedModalComponent title="ChartAccounts" id="modalAddChartAccounts">
        {Show && <AddChartAccounts />}
      </MedModalComponent>
    </div>
  );
};

export default Index;

// <div className="col-md-3  col-sm-6  mb-4 ">
// <div className="d-flex flex-column align-items-center text-center">
//   <div
//     className="w-70px h-70px d-flex align-items-center justify-content-center text-white rounded-3 ms-n1"
//     style={{ cursor: 'pointer', background: 'linear-gradient(to bottom right, var(--bs-theme), rgba(0, 0, 0, 0.5))' }}>
//     <i className="fab fa-lg fa-fw bi bi-person-fill-exclamation"></i>
//   </div>
//   <div className="fw-bold fs-5 m-2">{t('HumanResources')}</div>
// </div>
// </div>
