import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody } from '../../../../components/card/card.jsx';
import { getPayrolls } from '../../../../store/HR/Payroll/PayrollSlice.js';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination.jsx';
import { usePermissions } from '../../../../Helper/Permisstions/usePermissions.ts';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import Loading from '../../../../components/Shared/Loader/Loading';
// import NeatixModal from '../../../../components/Modal/NeatixModal.jsx';
import AddPayrollRun from './AddPayrollRun.js';
import MedModalComponent from '../../../../components/Modal/MedModalComponent.jsx';

const PayrollRun = () => {
  const { HasPermission } = usePermissions();
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  // const [show, setShow] = useState(false);
  const [payroll, setPayroll] = useState();

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const { Payrolls, loading, error } = useSelector((state) => state.Payrolls);

  useEffect(() => {
    if (Payrolls?.length === 0) {
      dispatch(getPayrolls());
    }
  }, [dispatch]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = Payrolls?.filter(
    (item) =>
      item.name.toLowerCase().includes(search.toLowerCase()) ||
      item.name2.toLowerCase().includes(search.toLowerCase())
  )?.slice(indexOfFirstData, indexOfLastData);




  const titleModal = () => {

    return (
      <div className="">
        {t('CreateRunPayrolls')}
        {'  '}
        <span className="text-theme ms-2">
          {`${i18n.language === 'en' ? payroll?.name : payroll?.name2}`}
        </span>
      </div>
    );
  };


  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('RunPayrolls'),
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('RunPayrolls')} />

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="tab-content p-2">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-2">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={handleSearch}
                          placeholder={t('Search') + ' ...'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table table-hover text-nowrap">
                  <thead className="table-dark ">
                    <tr>
                      <th scope="col">{t('Name')}</th>
                      <th scope="col">{t('TypeOfDuration')}</th>

                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle">
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </td>
                        <td className="align-middle">
                          {item.periodType === 'M' ? t('Monthly') : t('Weekly')}
                        </td>
                        <td className="align-middle text-end">
                          <div className="btn-group">
                            <button
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#CreateRunPayrolls"
                              // onClick={() => Navigate(`${item.id}/AddPayrollRun`)}
                              onClick={() => {
                                // setShow(true);
                                setPayroll(item);
                              }}
                              className="btn btn-outline-theme">
                              <i className="fas fa-lg fa-fw me-2 fa-money-bill-alt"></i>
                              {t('CreateRunPayrolls')}
                            </button>

                            {HasPermission('PayViewPayrollRun') && (
                              <button
                                type="button"
                                onClick={() =>
                                  Navigate(`${item.id}/ActionList`, {
                                    state: {
                                      ReferenceName:
                                        i18n.language === 'en' ? item.name : item.name2,
                                    },
                                  })
                                }
                                className="btn btn-outline-theme">
                                <i className="fab fa-lg fa-fw me-2 fa-windows"></i>
                                {t('ShowAllRuns')}
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={Payrolls}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />

              {/* </CardBody> */}
            </div>
          </CardBody>
        </Loading>
      </Card>

      {/* <NeatixModal
        setShow={setShow}
        show={show}
        bodyClassName="p-0"
        size="md"
        // title={t('CreateRunPayrolls')}
        showHeader={false}
        >
        <Card>{payrollId && <AddPayrollRun payrollId={payrollId} />}</Card>
      </NeatixModal> */}
      <MedModalComponent id="CreateRunPayrolls" title={titleModal()}>
        {payroll && <AddPayrollRun payrollId={payroll.id} />}
      </MedModalComponent>
    </>
  );
};

export default PayrollRun;
