import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
// import useGetTablesForm from "../../../hooks/Forms/use-get-tables-form";
// import useGetDataByTableId from "../../../hooks/NTX/useGetDataByTableId";
import { ntxAPI } from "../../../../API/axiosNeatex";
import SkeletonLoader from "../../../../components/Shared/SkeletonLoader/SkeletonLine";


const TypeT = ({ item, handleSelectChange }) => {


  const { t, i18n } = useTranslation();
    const [TableDataList , setTableDataList] = useState([])

    const [isLoading, setIsLoading] = useState(true); // New state for loading indicator

    //NtxSetting/GetDataByTabelId?TabelId=6121dd5c-aa4c-4832-a561-3fdfd0e15ba4&LookupType=150
    useEffect(() => {
      async function GetTableData() {
        try {
          const { data } = await ntxAPI.get(
            `NtxSetting/GetDataByTabelId?TabelId=${item?.tableId}&LookupType=${item?.lookupType}`
          );
  
          var DataList = data.map((item) => ({
            value: item.id,
            label: i18n.language === "ar" ? item.name2 : item.name,
          }));
  
          setTableDataList(DataList);
          setIsLoading(false); // Data has been loaded, set loading indicator to false
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false); // In case of an error, set loading indicator to false
        }
      }
  
      GetTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.tableId]);
 
  return (
    <>
      <div className="form-group mb-3">
        <label className="form-label">
          {i18n.language === "ar" ? item.lable2 : item.lable}
        </label>
        <div className="row" >
                  {isLoading ? ( 
          <SkeletonLoader/>
        ) : (
          TableDataList?.length > 0 && (
            <Select
              classNamePrefix="react-select"
              isSearchable={true}
              options={TableDataList}
              name={item.id}
              required={item.requiredFlag}
              onChange={handleSelectChange}
              placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
            />
          )
        )}
        </div>

      </div>
    </>
  );
};

export default TypeT;