import React from 'react';
import { useTranslation } from 'react-i18next';
import useGetDynamicForm from '../../../../hooks/Forms/useGetDynamicForm';
import { Card, CardBody } from '../../../../components/card/card';
import { useNavigate } from 'react-router-dom';

const Conditions = () => {
  const { t } = useTranslation();
  const { DynamicFormAme, loading, error } = useGetDynamicForm();
  const Navigate = useNavigate();

  return (
    <div>
      {/* <Card> */}
        {/* <CardBody> */}
          <div className=" p-4">
            <div className="row">
              {DynamicFormAme.map((item) => (
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 ">
                  <div className="card-conditions">
                    <div className="details-conditions">
                      <h6 className="text-center">{item.name}</h6>

                      <button
                        type="button"
                        onClick={() => Navigate(`${item.id}/AddAmeCondition`)}
                        className=" btn btn-outline-theme fw-bold">
                        <i className="fas fa-lg fa-fw me-2 fa-question"></i>
                        {t('Conditions')}
                      </button>
                    </div>
                    <div className="image">
                      <i
                        className={
                          item.icon
                            ? item.icon.replace('fa-lg fa-fw me-2', '') + ' pt-2 '
                            : 'bi bi-menu-up pt-2'
                        }></i>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        {/* </CardBody> */}
      {/* </Card> */}
    </div>
  );
};

export default Conditions;
