import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody } from '../../../../components/card/card';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { GetPersonlListWithPayrol } from '../../../../store/HR/Payroll/runPayrollSlice';
import { usePermissions } from '../../../../Helper/Permisstions/usePermissions';
import Loading from '../../../../components/Shared/Loader/Loading';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';

const PayrollElementEntry = () => {
  const { HasPermission } = usePermissions();

  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState('');
  // const [sortBy, setSortBy] = useState("");
  // const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const { PersonlListWithPayrol, loading, error } = useSelector((state) => state.runPayroll);

  useEffect(() => {
    if (PersonlListWithPayrol?.length === 0) {
      dispatch(GetPersonlListWithPayrol());
    }
  }, [dispatch]);

  const filteredPerson = PersonlListWithPayrol?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.personName?.toLowerCase().includes(searchLowerCase) ||
      item.personName2?.toLowerCase().toString().includes(searchLowerCase) ||
      item.employeeNumber?.toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredPerson?.slice(indexOfFirstData, indexOfLastData);

  //_____________________

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },

    {
      name: t('PayrollElementEntry'),
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('PayrollElementEntry')} />

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="tab-content p-2">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-2">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder={t('Search') + ' ...'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table table-hover text-nowrap">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t('EmployeeName')}</th>
                      <th scope="col">{t('EmployeeNumber')}</th>
                      <th scope="col">{t('Payroll')}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((item, index) => (
                      <tr key={++index}>
                        <td className="align-middle">
                          {i18n.language === 'ar' ? item.personName2 : item.personName}
                        </td>
                        <td className="align-middle">{item.employeeNumber}</td>
                        <td className="align-middle">
                          {i18n.language === 'ar' ? item.payrollName2 : item.payrollName}
                        </td>
                        <td className="text-end">
                          {HasPermission('PayAssignmentBasicSalary') && (
                            <button
                              type="button"
                              onClick={() =>
                                Navigate(`${item.assignmentId}/AddPayProposal`, {
                                  state: {
                                    ReferenceName:
                                      i18n.language === 'en' ? item.personName : item.personName2,
                                  },
                                })
                              }
                              className="btn btn-outline-theme mx-1">
                              <i className=" fs-4 bi-cash fa-lg me-2"></i>
                              {t('Button.Basic_salary')}
                            </button>
                          )}

                          {HasPermission('PayAssignmentPayrollElementEntries') && (
                            <button
                              type="button"
                              className="btn btn-outline-theme mx-1 "
                              // onClick={() => handleNavigate2(item)}
                              onClick={() =>
                                Navigate(`${item.assignmentId}/AddPayrollElementEntry`, {
                                  state: {
                                    ReferenceName:
                                      i18n.language === 'en' ? item.personName : item.personName2,
                                  },
                                })
                              }>
                              <i className="bi-journal-text fa-lg me-1"></i>
                              {t('PayrollElementEntry')}
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={PersonlListWithPayrol}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </CardBody>
        </Loading>
      </Card>
    </>
  );
};

export default PayrollElementEntry;
