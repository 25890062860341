import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { GetMaterialTransactionsList } from '../../store/INV/MaterialTransactions/MaterialTransactionsSlice';

const useGetMaterialTransactionsList = () => {

    const dispatch = useAppDispatch();
  
    useEffect(() => {
      if(MaterialTransactionsList?.length ===0){
        dispatch(GetMaterialTransactionsList());
      }
    }, []);
    const { MaterialTransactionsList , loading} = useAppSelector(
      (state) => state.MaterialTransactions
    );

   

  return { MaterialTransactionsList, loading }
}

export default useGetMaterialTransactionsList
