import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../API/axiosNeatex";

export const AddUpdateExtraInformation = createAsyncThunk(
  "ExtraInformation/AddUpdateExtraInformation",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.post("ExtraInformation/AddUpdateExtraInformation" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetExtraInformationTabs = createAsyncThunk(
  "ExtraInformation/GetExtraInformationTabs",
  async ({ DynamicFormId, ReferenceId }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const {data} = await ntxAPI.get(`ExtraInformation/GetExtraInformationTabs?DynamicFormId=${DynamicFormId}&ReferenceId=${ReferenceId}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetExtraInformationWithValueById = createAsyncThunk(
  "ExtraInformation/GetExtraInformationWithValueById",
  async (ExtraInformationId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.get(`ExtraInformation/GetExtraInformationWithValueById?ExtraInformationId=${ExtraInformationId}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);



export const GetFormRecordWithValue = createAsyncThunk(
  "ExtraInformation/GetFormRecordWithValue",
  async ({ TableId, ReferenceId }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const {data} = await ntxAPI.get(`ExtraInformation/GetFormRecordWithValue?ReferenceId=${ReferenceId}&TableId=${TableId}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);




const initialState = {
  ExtraInformationList: [],
  ExtraInformationTabList: [], //  FormRecordWithValue هذه ماعد استخدمتها بدلت بدالة اللي تحته
  FormRecordWithValueList: [],
  ExtraInformationWithValueById: [],
  loading: false,
  loadingRecordWithValueList: false,
  error: null,
};

const ExtraInformationSlice = createSlice({
  name: "ExtraInformation",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // GetExtraInformationTabs
      .addCase(GetExtraInformationTabs.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.ExtraInformationTabList = [];
      })
      .addCase(GetExtraInformationTabs.fulfilled, (state, action) => {
        state.loading = false;
        state.ExtraInformationTabList = action.payload;
      })
      .addCase(GetExtraInformationTabs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // GetFormRecordWithValue
      .addCase(GetFormRecordWithValue.pending, (state) => {
        state.loadingRecordWithValueList = true;
        state.error = null;
      })
      .addCase(GetFormRecordWithValue.fulfilled, (state, action) => {
        state.loadingRecordWithValueList = false;
        state.FormRecordWithValueList = action.payload;
      })
      .addCase(GetFormRecordWithValue.rejected, (state, action) => {
        state.loadingRecordWithValueList = false;
        state.error = action.payload;
      })
  
      // GetExtraInformationWithValueById
      .addCase(GetExtraInformationWithValueById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.ExtraInformationWithValueById = [];
      })
      .addCase(GetExtraInformationWithValueById.fulfilled, (state, action) => {
        state.loading = false;
        state.ExtraInformationWithValueById = action.payload;
      })
      .addCase(GetExtraInformationWithValueById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // AddUpdateExtraInformation
      .addCase(AddUpdateExtraInformation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddUpdateExtraInformation.fulfilled, (state, action) => {
        state.loading = false;
        state.ExtraInformationList.push(action.payload);
      })
      .addCase(AddUpdateExtraInformation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default ExtraInformationSlice.reducer;
