

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { EquipmentsOdometer } from "./equipmentsOdometerModels";




export const  GetEquipmentsOdometerList = createAsyncThunk(
    "EquipmentsOdometer/GetEquipmentsOdometerList",
    async (_, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get('Fleet/GetEquipmentsOdometerList');
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );

export const  GetEquipmentsOdometerById = createAsyncThunk(
    "EquipmentsOdometer/GetEquipmentsOdometerById",
    async (id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get(`Fleet/GetEquipmentsOdometerById?Id=${id}`);
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );


  export const InsertEquipmentsOdometer = createAsyncThunk('EquipmentsOdometer/InsertEquipmentsOdometer',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Fleet/AddEquipmentsOdometer" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });


  export const UpdateEquipmentsOdometer = createAsyncThunk('EquipmentsOdometer/UpdateEquipmentsOdometer',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Fleet/UpdatEquipmentsOdometer" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });


  export const  GetEquipmentsOdometerListByFleetEquipmentsId = createAsyncThunk(
    "EquipmentsOdometer/GetEquipmentsOdometerListByFleetEquipmentsId",
    async (id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {

        const {data} = await ntxAPI.get(`Fleet/GetEquipmentsOdometerByFleetEquipmentsId?FleetEquipmentsId=${id}`);
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );

  


const initialState: {
  OdometerListByFleetEquipmentsId : EquipmentsOdometer[]
    EquipmentsOdometerList : EquipmentsOdometer[]
    EquipmentsOdometerById? : EquipmentsOdometer
    loading: boolean;
    error: any | null;
  } = {
    EquipmentsOdometerList: [],
    OdometerListByFleetEquipmentsId: [],
    loading: false,
    error: null,

  };


  

const equipmentsOdometerSlice = createSlice({
    name: "EquipmentsOdometer",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder

      .addCase(InsertEquipmentsOdometer.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(InsertEquipmentsOdometer.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.EquipmentsOdometerList.push(action.payload.data); 
      })
      .addCase(InsertEquipmentsOdometer.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


    .addCase(GetEquipmentsOdometerList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetEquipmentsOdometerList.fulfilled, (state, action: PayloadAction<any> ) => {
        state.loading = false;
        state.EquipmentsOdometerList = action.payload;
      })
      .addCase(GetEquipmentsOdometerList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })




    .addCase(GetEquipmentsOdometerListByFleetEquipmentsId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetEquipmentsOdometerListByFleetEquipmentsId.fulfilled, (state, action: PayloadAction<any> ) => {
        state.loading = false;
        state.OdometerListByFleetEquipmentsId = action.payload;
      })
      .addCase(GetEquipmentsOdometerListByFleetEquipmentsId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })



    .addCase(GetEquipmentsOdometerById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetEquipmentsOdometerById.fulfilled, (state, action: PayloadAction<EquipmentsOdometer> ) => {
        state.loading = false;
        state.EquipmentsOdometerById = action.payload;
      })
      .addCase(GetEquipmentsOdometerById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      
    },
  });
  
  export default equipmentsOdometerSlice.reducer;