/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  GetEquipmentsWorkOrderById,
  UpdatEquipmentsWorkOrder,
} from "../../../store/FLEET/EquipmentsWorkOrder/equipmentsworkorderSlice";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Shared/Breadcrumb";
import { Card, CardBody } from "../../../components/card/card";
import CustomAlert from "../../../components/Shared/Alert/CustomAlert";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/Shared/Loader/Loader";
import { UpdateWorkOrderSchema } from "../../ValidationForm/validationSchema";

const CloseOrder = () => {
  const { Id } = useParams();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  useEffect(() => {
    if (Id) {
      dispatch(GetEquipmentsWorkOrderById(Id));
    }
  }, []);
  const { EquipmentsWorkOrderById: data , loading } = useAppSelector(
    (state) => state.EquipmentsWorkOrder
  );

  const formik = useFormik({
    initialValues: {
      id: Id,
      fleetEquipmentsWorkRequestsId :data ? data.fleetEquipmentsWorkRequestsId : "",  
      fleetEquipmentsId: data ? data.fleetEquipmentsId : "",
      orderCode: data ? data.orderCode : "",
      validationCode: data ? data.validationCode : "",
      closePersonId: data ? data.closePersonId : "",
      dateCompletion: data ? data.dateCompletion : "",
      isInternal: data ? data.isInternal : "", // boolean
      startCompletionSugg: data ? data.startCompletionSugg : "",
      endCompletionSugg: data ? data.endCompletionSugg : "",
      totalItems: data ? data.totalItems : "",
      totalAsset: data ? data.totalAsset : "",
      otherAmounts: data ? data.otherAmounts : "",
      totalBudget: data ? data.totalBudget : "",
      supplierId: data ? data.supplierId : "",
      workshopId: data ? data.workshopId : "",
      startDateExecution: data ? data.startDateExecution : "",
      endDateExecution: data ? data.endDateExecution : "",
      noteCompletion: data ? data.noteCompletion : "",
      totalItemsActual: data ? data.totalItemsActual : "",
      totalAssetActual: data ? data.totalAssetActual : "",
      otherAmountsActual: data ? data.otherAmountsActual : "",
      totalBudgetActual: data ? data.totalBudgetActual : "",
    },
    enableReinitialize: true,
    validationSchema: UpdateWorkOrderSchema(t),
    onSubmit: (values) => {
 
      dispatch(
        UpdatEquipmentsWorkOrder({
            id: data?.id,
          fleetEquipmentsWorkRequestsId: values.fleetEquipmentsWorkRequestsId,
          fleetEquipmentsId: values.fleetEquipmentsId,
          orderCode: values.orderCode,
          validationCode: values.validationCode,
          closePersonId: localStorage.personId ? localStorage.personId : null,
          dateCompletion: values.dateCompletion,
          isInternal: values.isInternal ,
          startCompletionSugg: values.startCompletionSugg,
          endCompletionSugg: values.endCompletionSugg,
          totalItems: values.totalItems,
          totalAsset: values.totalAsset,
          otherAmounts: values.otherAmounts,
          totalBudget: values.totalBudget,
          supplierId:  values.supplierId,
          workshopId:  values.workshopId,
          startDateExecution: values.startDateExecution,
          endDateExecution: values.endDateExecution,
          noteCompletion: values.noteCompletion,
          totalItemsActual: values.totalItemsActual,
          totalAssetActual: values.totalAssetActual,
          otherAmountsActual: values.otherAmountsActual,
          totalBudgetActual: values.totalBudgetActual,
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            formik.resetForm();
            CustomAlert({ action: "Edit" });
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  /// Calculate Total
  const calculateTotalBudget = () => {
    const { totalAssetActual, otherAmountsActual, totalItemsActual } =
      formik.values;

    // Explicitly convert values to numbers using the unary plus operator
    const totalAsset = +totalAssetActual;
    const otherAmounts = +otherAmountsActual;
    const totalItems = +totalItemsActual;

    // Perform the addition
    return totalItems + totalAsset + otherAmounts;
  };

  useEffect(() => {
    formik.setFieldValue("totalBudgetActual", calculateTotalBudget());
  }, [
    formik.values.totalItemsActual,
    formik.values.totalAssetActual,
    formik.values.otherAmountsActual,
  ]);
  //_________________________________

  //_______________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "MaintenanceOrders",
      link: "/FLEET/Maintenance/MaintenanceOrder",
    },
    {
      name: "Close_Maintenance_Order",
      link: null,
    },
  ]
  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="Close_Maintenance_Order" />

      <Card>
        <Loader loading={loading}/>
        <CardBody>
          <div className="table-responsive text-center rounded-top-2">
            <table className="table table-bordered bg-inverse bg-opacity-10 ">
              <thead className="">
                <tr className="bg-inverse bg-opacity-15">
                  <th>{t('OrderNumber')}</th>
                  <th>{t('Equipment')}</th>
                  <th>{data?.workshopId !== null ? t("Workshop"):t("Supplier") }</th>
                  <th>{t('Expected_total')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle">{data?.orderCode}</td>
                  <td className="align-middle">{ i18n.language === 'ar' ? data?.fleetEquipmentsName : data?.fleetEquipmentsName2}</td>
                  <td className="align-middle">{ data?.workshopId !== null ? data?.workshopName : data.supplierName}</td>
                  <td className="align-middle">{data?.totalBudget}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <Form onSubmit={formik.handleSubmit}>
            <div className="row mt-3">
              <div className="col-xl-3">
                <div className="input-group">
                  <span className="input-group-text">{t('Material_price')}</span>
                  <Form.Control
                    className="form-control"
                    type="number"
                    name="totalItemsActual"
                    onChange={formik.handleChange}
                    value={formik.values.totalItemsActual}
                    isInvalid={
                      !!(
                        formik.touched.totalItemsActual &&
                        formik.errors.totalItemsActual
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.totalItemsActual}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="input-group">
                  <span className="input-group-text">{t('Remuneration_Workers')}</span>
                  <Form.Control
                    className="form-control"
                    type="number"
                    name="totalAssetActual"
                    onChange={formik.handleChange}
                    value={formik.values.totalAssetActual}
                    isInvalid={
                      !!(
                        formik.touched.totalAssetActual &&
                        formik.errors.totalAssetActual
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.totalAssetActual}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="input-group">
                  <span className="input-group-text">{t('Other_amounts')}</span>
                  <Form.Control
                    className="form-control"
                    type="number"
                    name="otherAmountsActual"
                    onChange={formik.handleChange}
                    value={formik.values.otherAmountsActual}
                    isInvalid={
                      !!(
                        formik.touched.otherAmountsActual &&
                        formik.errors.otherAmountsActual
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.otherAmounts}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3 ">
                <div className="input-group bg-inverse bg-opacity-20">
                  <span className="input-group-text">{t('Total')}</span>
                  <Form.Control
                    className="form-control"
                    type="number"
                    name="totalBudgetActual"
                    onChange={formik.handleChange}
                    readOnly
                    value={formik.values.totalBudgetActual}
                    isInvalid={
                      !!(
                        formik.touched.totalBudgetActual &&
                        formik.errors.totalBudgetActual
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.totalBudgetActual}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t('DateCompletion')}</label>
                  <Form.Control 
                    className="form-control"
                    type="datetime-local"
                    name="dateCompletion"
                    onChange={formik.handleChange}
                    value={formik.values.dateCompletion}
                    isInvalid={
                      !!(
                        formik.touched.dateCompletion &&
                        formik.errors.dateCompletion
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.dateCompletion}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t('actual_implementation_start_date')}</label>
                  <Form.Control
                    className="form-control"
                    type="datetime-local"
                    name="startDateExecution"
                    onChange={formik.handleChange}
                    value={formik.values.startDateExecution}
                    isInvalid={
                      !!(
                        formik.touched.startDateExecution &&
                        formik.errors.startDateExecution
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.startDateExecution}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label"> {t('actual_implementation_end_date')} </label>
                  <Form.Control
                    className="form-control"
                    type="datetime-local"
                    name="endDateExecution"
                    onChange={formik.handleChange}
                    value={formik.values.endDateExecution}
                    isInvalid={
                      !!(
                        formik.touched.endDateExecution &&
                        formik.errors.endDateExecution
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.endDateExecution}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-12">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Note")}</label>
                  <Form.Control
                    className="form-control"
                    as="textarea"
                    rows={1}
                    name="noteCompletion"
                    onChange={formik.handleChange}
                    value={formik.values.noteCompletion}
                  />
                </div>
              </div>
            </div>

            <div className="text-center mt-5">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-outline-theme mb-1"
          >
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas bi bi-check2-circle fa-lg fa-fw me-2"></i>
            )}
          {t("Close_Maintenance_Order")}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-outline-default mb-1"
            onClick={() => Navigate("/FLEET/Maintenance/MaintenanceOrder", { replace: true })}
          >
            <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          </button>
        </div>
          </Form>

        </CardBody>
      </Card>
    </div>
  );
};

export default CloseOrder;
