import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getTransactionsMoveList } from '../../../store/INV/Transactions/InvMove/invMoveSlice';

const useGetInvMoveList = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (InvMoveList?.length === 0) {
      dispatch(getTransactionsMoveList());
    }
  }, []);
  const { InvMoveList, loading , error } = useAppSelector((state) => state.InvMove);

  return { InvMoveList, loading , error };
};

export default useGetInvMoveList;
