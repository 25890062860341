import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
 
import { getMarStatus, getTITLE } from "../../store/Lookup/lookupSlice";
import { useTranslation } from "react-i18next";

const useLookupMarStatus = ()=>{

    const dispatch = useDispatch();
    const {i18n} = useTranslation();
    
    useEffect(()=>{
        dispatch(getMarStatus())
      },[])

    const { MAR_STATUS , loading , error} = useSelector((state) => state.Lookup); 


    const OptionsMarStatus =
    MAR_STATUS &&
      MAR_STATUS.map((item, idx) =>
        item.list.map((list) => (
          {
             value: list.lookupCode,
              label: i18n.language === "en" ? list.meaning : list.meaning2
          }
        ))
      ).flat()



    return { MAR_STATUS , OptionsMarStatus ,loading , error } ;
};

export default useLookupMarStatus ;