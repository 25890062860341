import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useLookupModule from "../../../../hooks/Lookups/use-Lookup-Module";
import { useAppDispatch } from "../../../../store/hooks";
import { AddAuthRule, GetRoleList } from "../../../../store/NTX/Security/Rules/RulesSlice";
import { useFormik } from "formik";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import { formAuthRulesSchema } from "../../../ValidationForm/validationSchema";

const AddRule = () => {
  const { t, i18n } = useTranslation();
  const { Module } = useLookupModule();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      name: "",
      name2: "",
      startDate: "",
      endDate: "",
      description: "",
      moduleId: "",
      // //
      // isExpiry : false
    },

    validationSchema: formAuthRulesSchema(t),
    //
    onSubmit: (values) => {
      dispatch(
        AddAuthRule({
          name: values.name,
          name2: values.name2,
          startDate: values.startDate,
          endDate: values.endDate || null,
          description: values.description,
          moduleId: values.moduleId,
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res.succeeded === true) {
            CustomAlert({ action: "Add" });
            document.getElementById("closeModal")?.click();
            dispatch(GetRoleList());
            formik.resetForm();
          } else {
            CustomAlert({ action: "Error" });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t("LocalName")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name2}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t("GlobalName")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t("Application")}</label>
              <Form.Select
                className="form-select"
                name="moduleId"
                value={formik.values.moduleId}
                onChange={formik.handleChange}
                isInvalid={
                  !!(formik.touched.moduleId && formik.errors.moduleId)
                }
              >
                <option value="">
                  {i18n.language === "ar" ? "إختر" : "Choose"}
                </option>
                {Module &&
                  Module.map((item: any, idx: any) => (
                    <option key={++idx} value={item.id}>
                      {i18n.language === "ar" ? item.name2 : item.name}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.moduleId}
              </Form.Control.Feedback>
            </div>
          </div>
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Start Date")}</label>
            <Form.Control
              // disabled={!formik.values.isExpiry}
              className="form-control"
              type="date"
              name="startDate"
              onChange={formik.handleChange}
              value={formik.values.startDate}
              isInvalid={
                !!(formik.touched.startDate && formik.errors.startDate)
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.startDate}
            </Form.Control.Feedback>
          </div>
        </div>
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t("Date_TO")}</label>
              <Form.Control
                className="form-control"
                type="date"
                name="endDate"
                onChange={formik.handleChange}
                value={formik.values.endDate}
                isInvalid={!!(formik.touched.endDate && formik.errors.endDate)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.endDate}
              </Form.Control.Feedback>
            </div>
          </div>

        </div>

        <div className="col-xl-12">
          <div className="form-group mb-3">
            <label className="form-label">ملاحظات</label>
            <Form.Control
              className="form-control"
              as="textarea"
              rows={3}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
            />
          </div>
        </div>
        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
          >
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t("Add")}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalResponsibility"
          >
            <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default AddRule;
