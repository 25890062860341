import { configureStore } from "@reduxjs/toolkit";

import DashBoard from "./NTX/Reports/Dashboard/dashboardSlice";
import NtxSettings from "./NTX/Settings/settingsSlice";
import NtxProfile from "./NTX/Profile/profileSlice";
import Employees from "./HR/Person/employeeSlice";
import Absence from "./HR/Person/absenceSlice";
import EndOfService from "./HR/Person/EndOfService/endofserviceSlice";
import Lookup from "./Lookup/lookupSlice";
import Grades from "./HR/HRSetting/jobgradeSlice";
import Jobs from "./HR/HRSetting/jobSlice";
import Positions from "./HR/HRSetting/positionSlice";
import Organizations from "./HR/Organizations/organizationSlice";
import OrganizationStructure from "./HR/Organizations/OrganizationStructureSice";

import Locations from "./HR/HRSetting/locationSlice";
import Payrolls from "./HR/Payroll/PayrollSlice";
import runPayroll from "./HR/Payroll/runPayrollSlice";
import PyrollElement from "./HR/Payroll/payrollElementSlice";
import PayProposal from "./HR/Payroll/payProposalSlice";
import PayElementEntry from "./HR/Payroll/payrollElementEntrySlice";
import Groups from "./HR/HRSetting/groupEmpSlice";
import Assignments from "./HR/Person/assignmentSlice";
import Forms from "./NTX/FlexValue/Form/formSlice";
import FormColumns from "./NTX/FlexValue/Form/FormColumnSlice"
import FlexValueSet from "./NTX/FlexValue/flexValueSetSlice"
import FlexValue from "./NTX/FlexValue/FlixValueSlice"
import Ame from "./Ame/ameSlice"
import AmeDelegate from "./Ame/ameDelegateSlice"
import ApprovalGroup from "./Ame/approvalGroupSlice"
import ApprovalMember from "./Ame/AprovalMemberSlice"
import AmeRule from "./Ame/ameRuleSlice"
import AmeConditionUsage from "./Ame/ameConditionUsageSlice"
import AmeActionRule from "./Ame/ameActionRuleSlice"
import AmeRequest from "./Ame/ameRequestSlice"
import NotificationRequest from "./Ame/NotificationRequest/notificationRequestSlice"

import Users from "./NTX/Security/usersSlice"
import Responsibility from "./NTX/Security/responsibilitySlice"
import ResponsibilityUser from "./NTX/Security/responsibilityUserSlice"
import ResponsibilityFunction from "./NTX/Security/responsibilityFunctionSlice"

import Permission from "./NTX/Security/Permission/PermissionSlice"
import Rules from "./NTX/Security/Rules/RulesSlice"

//
// import { moduleApi } from "./moduleApi";

import userLogin from "./Login/loginSlice"
import Raqeeb from "./HR/Raqeeb/raqeebSlice";

import ExtraInformation from "./ExtraInformation/extraInformationSlice"

import RealState from "./SU/realEstateSlice"
import Contracts from "./SU/contractLeaseSlice"
import DashboardSU from "./SU/dashboardSUSlice"
import InstallmentPayment from "./SU/installmentPaymentSlice"
import Notifications from "./NTX/Notifications/notificationSlice"
import Reports from "./NTX/Reports/reportSlice"
import PrintTemplate from "./NTX/PrintTemplate/PrintTemplateSlice"
import ReportData from "./NTX/Reports/ReportData/reportdataSlice"
import ReportShape from "./NTX/Reports/ReportShape/reportShapeSlice"
import Peoples  from "./NTX/Peoples/peoplesSlice";
import PeoplesContacts  from "./NTX/Peoples/PeoplesContactSlice";
import LookupData from "./NTX/Lookup/lookupsSlice"

import Banks from "./NTX/Banks/banksSlice"
import Currency from "./NTX/Currency/currencySlice"


import WorkShop  from "./FLEET/Workshop/workshopSlice";
import EquipmentsModel  from "./FLEET/EquipmentsModel/equipmentsmodelSlice";
import Equipments  from "./FLEET/Equipments/equipmentsSlice";
import EquipmentsAssignment  from "./FLEET/EquipmentsAssignment/equipmentsAssignmentSlice";
import EquipmentsOdometer  from "./FLEET/EquipmentsOdometer/equipmentsOdometerSlice";
import EquipmentsWorkRequest  from "./FLEET/EquipmentsWorkRequests/equipmentsWorkRequestSlice";
import  EquipmentsRoutes from "./FLEET/EquipmentsRoutes/equipmentsRoutesSlice";
import  EquipmentsWorkOrder from "./FLEET/EquipmentsWorkOrder/equipmentsworkorderSlice";
import  AssignmentRoutes from "./FLEET/AssignmentRoutes/assignmentroutesSlice";
import  FleetScan from "./FLEET/FleetScan/fleetScanSlice";
import  EquipmentInspection from "./FLEET/EquipmentInspection/equipmentInspectionSlice";


//_Finance
import  ConsolidationSet from "./Finance/ConsolidationSet/consolidationSetSlice";
import  TaxManager from "./Finance/TaxManagers/TaxManagerSlice";
import  Periods from "./Finance/Periods/PeriodsSlice";
import  Ledgers from "./Finance/Ledgers/LedgersSlice";
import  DailyRates from "./Finance/DailyRates/DailyRatesSlice";
import  ChartAccounts from "./Finance/ChartAccounts/ChartAccountsSlice";
import  ChartAccountsDetails from "./Finance/ChartAccounts/ChartAccountsDetailsSlice";
import  JeCategory from "./Finance/JeCategory/categorySlice";
import  JeSource from "./Finance/JeSource/sourceSlice";
import  Journals from "./Finance/Journals/JournalsSlice";
import  PostingJournals from "./Finance/Journals/postingJournalsSlice";

import  ApInvoices from "./Finance/AP/ApInvoices/ApInvoicesSlice";
import  ApBankAccounts from "./Finance/AP/ApBankAccounts/ApBankAccountsSlice";

//_ INV
import  SubInventory from "./INV/SubInventory/SubInventorySlice";
import  MasterItemsINV from "./INV/MasterItems/MasterItemsSlice";
import  CategoriesINV from "./INV/Categories/CategoriesSlice";
import  ItemConversion from "./INV/MasterItems/ItemConversionSlice";
import  PriceList from "./INV/PriceList/PriceListSlice";
import  TransactionTypes_Inv from "./INV/TransactionTypes/TransactionTypesSlice";
import  UnitsOfMeasure from "./INV/UnitsOfMeasure/UnitsOfMeasureSlice";
import  MaterialTransactions from "./INV/MaterialTransactions/MaterialTransactionsSlice";
//
import  InvIssue from "./INV/Transactions/InvIssue/invIssueSlice";
import  InvReceive from "./INV/Transactions/InvReceive/invReceiveSlice";
import  InvMove from "./INV/Transactions/InvMove/invMoveSlice";
import  InventoryCount from "./INV/InventoryCount/inventoryCountSlice";


//_ Sales
import  SalesInvoices from "./Sales/SalesInvoices/salesInvoicesSlice";
import  SalesQuotation from "./Sales/SalesQuotation/salesQuotationSlice";
import  SalesOrder from "./Sales/SalesOrder/SalesOrderSlice";
import  DiscountVouchers from "./Sales/DiscountVouchers/DiscountVouchersSlice";
import  SalesReturns from "./Sales/SalesReturns/salesReturnsSlice";
import  Pos from "./Sales/Pos/PosSlice";
import  PosCashier from "./Sales/Pos/PosCashierSlice";
import  PosPaymentMethod from "./Sales/Pos/PosPaymentMethodSlice";
import  PosSession from "./Sales/Pos/PosSessionSlice";


//_ Purchases
import  PurchaseQuotation from "./purchases/PurchaseQuotation/purchaseQuotationSlice";
import  PurchaseRequest from "./purchases/PurchaseRequests/purchaseRequestsSlice";
import  PurchaseOrder from "./purchases/PurchaseOrder/purchaseOrderSlice";
import  PurchaseBill from "./purchases/PurchaseBill/purchaseBillSlice";
import  PurchaseReturn from "./purchases/PurchaseReturn/purchasesReturnsSlice";



export const store = configureStore({
  reducer: {
    DashBoard,
    NtxProfile,
    NtxSettings,
    Employees,
    Assignments,
    Absence,
    EndOfService,
    Lookup,
    LookupData,
    Grades,
    Jobs,
    Positions,
    Organizations,
    OrganizationStructure,
    Locations,
    Payrolls,
    runPayroll,
    PyrollElement,
    PayProposal,
    PayElementEntry,
    Groups,
    //////// 
    ExtraInformation,
    Forms,
    FlexValueSet,
    FlexValue,
    FormColumns,
    Ame,
    AmeDelegate,
    ApprovalGroup,
    ApprovalMember,
    AmeRule,
    AmeConditionUsage,
    AmeActionRule,
    AmeRequest,
    NotificationRequest,
    Users,
    Responsibility,
    ResponsibilityUser,
    ResponsibilityFunction,
    Permission,
    Rules,
    //
    userLogin,
    //
    Raqeeb,

    // SU
    RealState,
    Contracts,
    InstallmentPayment,
    DashboardSU,
    // Reports
    Reports,
    PrintTemplate,
    Notifications,
    ReportData,
    ReportShape,
    Peoples,
    PeoplesContacts,
    Banks,
    Currency,

    // Fleet //
    WorkShop,
    EquipmentsModel,
    Equipments,
    EquipmentsAssignment,
    EquipmentsOdometer,
    EquipmentsWorkRequest,
    EquipmentsRoutes,
    EquipmentsWorkOrder,
    AssignmentRoutes,
    FleetScan,
    EquipmentInspection,


    ////_Finance
    ConsolidationSet,
    TaxManager,
    Ledgers,
    Periods,
    DailyRates,
    ChartAccounts,
    ChartAccountsDetails,
    JeCategory,
    JeSource,
    Journals,
    PostingJournals,

    ApInvoices,
    ApBankAccounts,

    //_ INV
    SubInventory,
    CategoriesINV,
    MasterItemsINV,
    ItemConversion,
    PriceList,
    TransactionTypes_Inv,
    UnitsOfMeasure,
    MaterialTransactions,
    //
    InvIssue,
    InvReceive,
    InvMove,
    InventoryCount,


    //_ Sales
    SalesInvoices,
    SalesQuotation,
    SalesOrder,
    SalesReturns,
    DiscountVouchers,
    Pos,
    PosCashier,
    PosPaymentMethod,
    PosSession,

    //_ Purchases
    PurchaseQuotation,
    PurchaseRequest,
    PurchaseOrder,
    PurchaseBill,
    PurchaseReturn,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    })
});


// const persistor = persistStore(store);
// export { store, persistor };

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch