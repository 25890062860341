import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import EndOfServiceForm from './EndOfServiceForm';
import { useAppSelector } from '../../../../store/hooks';

const EndOfService = () => {
  const { personId } = useParams();
  const { t, i18n } = useTranslation();

  var BreadcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Employees',
      link: '/HR/Employees',
    },
    {
      name: 'EndOfService',
      link: null,
    },
  ];

  const { PeriodOfServiceByPersonId: data } = useAppSelector((state) => state.EndOfService);

  const PersonName = i18n.language === 'en' ? data.name ?? '' : data.name2 ?? '';

  return (
    <>
      <Breadcrumb
        BreadcrumbList={BreadcrumbList}
        PageName="EndOfService"
        subtitle={PersonName}
      />

      <Card>
        <CardBody>
          <EndOfServiceForm personId={personId || ''} />
        </CardBody>
      </Card>
    </>
  );
};

export default EndOfService;
