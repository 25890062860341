import React, { useState } from 'react';
import MedModalComponent from '../../../../components/Modal/MedModalComponent';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import Loader from '../../../../components/Shared/Loader/Loader';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import AddAccountingPeriod from './AddAccountingPeriod';
import useGetGlPeriodsList from '../../../../hooks/Finance/useGetGlPeriodsList';
import { Link } from 'react-router-dom';
import { PeriodsModels } from '../../../../store/Finance/Periods/PeriodsModels';
import UpdateAccountingPeriod from './UpdateAccountingPeriod';
import Loading from '../../../../components/Shared/Loader/Loading';

const Index = () => {
  const [Show, setShow] = useState(false);
  const [year, setYear] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const [dataById, setDataById] = useState<PeriodsModels>(); // for sent as props to Update component PeriodsModels

  const { PeriodsList, loading, error } = useGetGlPeriodsList();

  const filteredSearch = PeriodsList?.slice()
    .filter((f) => (year === '' ? true : f.periodYear === year))
    .filter((f) => f.periodYear !== null);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'الفترات المحاسبية',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="الفترات المحاسبية" />

        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-outline-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAddAccountingPeriod"
            onClick={() => setShow(true)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            اضافة فترة محاسبية
          </Link>
        </div>
      </div>

      <Card>
        <Loader loading={loading} />
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="tab-content p-1">
              <div className="row">
                <div className="col-lg-4">
                  <div className="input-group mb-2">
                    <select
                      className="text-theme fw-bold bg-inverse bg-opacity-20 btn btn-default dropdown-toggle w-50 "
                      value={year}
                      onChange={(e) => setYear(e.target.value)}>
                      <option className="dropdown-item" value={''}>
                        {t('All')}
                      </option>
                      {PeriodsList &&
                        PeriodsList.filter((f) => f.periodYear === null)?.map((item) => (
                          <option value={item.id} key={item.id} className="dropdown-item">
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="col-lg-8 text-end"></div>
              </div>
              {/* //  START TABLE   */}
              {filteredSearch.length === 0 ? (
                <></>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover text-nowrap">
                      <thead className="table-dark">
                        <tr>
                          <th scope="col">{t('Name')}</th>
                          <th scope="col">{t('السنة المالية')}</th>
                          <th scope="col">{t('من')}</th>
                          <th scope="col">{t('to')}</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentData.map((item, index) => (
                          <tr key={item.id}>
                            <td className="align-middle">
                              {i18n.language === 'ar' ? item.name2 : item.name}
                            </td>
                            <td className="align-middle">
                              {' '}
                              {PeriodsList?.find((f) => f.id === item.periodYear)?.name}
                            </td>
                            <td className="align-middle"> {item.startDate.toString()} </td>
                            <td className="align-middle"> {item.endDate.toString()} </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-outline-theme"
                                data-bs-toggle="modal"
                                data-bs-target="#modalEditAccountingPeriod"
                                onClick={() => setDataById(item)}>
                                <i className="far fa-lg fa-fw mx-2 fa-edit"></i>
                                {/* {t('Button.Edit')} */}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    filteredData={filteredSearch}
                    dataPerPage={dataPerPage}
                    handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
                    currentPage={currentPage}
                  />
                </>
              )}
            </div>
          </CardBody>
        </Loading>
      </Card>

      <MedModalComponent title="اضافة فترة محاسبية" id="modalAddAccountingPeriod">
        {Show && <AddAccountingPeriod />}
      </MedModalComponent>

      <MedModalComponent title="تعديل فترة محاسبية" id="modalEditAccountingPeriod">
        {dataById && <UpdateAccountingPeriod DataById={dataById} />}
      </MedModalComponent>
    </div>
  );
};

export default Index;
