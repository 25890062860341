import React from 'react';
import ViewAbsence from '../../../HR/Employees/Absence/ViewAbsence/ViewAbsence';
import ViewWorkRequest from '../../../FLEET/WorkRequests/ViewWorkRequest/ViewWorkRequest';
// import ViewAbsence from '../../HR/Employees/Absence

const ViewStaticForm = ({ id, ReferenceId }) => {
  let Component = null;

  switch (id) {
    case '19b2d5d2-8864-4e5d-b07e-4c1713d07a3c':
      Component = <ViewAbsence ReferenceId={ReferenceId} isAme={true} />;
      break;
    case 'd9e918b3-b706-4281-a6e6-5101be1bcd63':
      Component = <ViewWorkRequest ReferenceId={ReferenceId} isAme={true} />;
      break;
    default:
      <div> {Component} </div>;
  }

  return <div>{Component}</div>;
};

export default ViewStaticForm;
