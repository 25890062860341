// import { useTranslation } from "react-i18next";
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { GetPosCashersByPosId } from '../../store/Sales/Pos/PosCashierSlice';

type Props = {
  posId: string;
};
const useGetCashierListByPosId = ({ posId }: Props) => {
  const dispatch = useAppDispatch();

  // const { i18n } = useTranslation();

  useEffect(() => {
    dispatch(GetPosCashersByPosId(posId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posId]);

  const {
    PosCashierListByPosId: PosCashierList,
    loading,
    error,
  } = useAppSelector((a) => a.PosCashier);

  return { PosCashierList, loading, error };
};

export default useGetCashierListByPosId;
