
export const DataIcons = [
  { value:'bi bi-fingerprint me-2 fa-lg ', label: <><i className='bi bi-fingerprint fa-lg  me-2 text-theme fs-4 text-theme fs-4'></i>fingerprint </>},
  { value:'fas fa-lg fa-fw me-2 fa-address-book', label: <><i className='fas fa-lg fa-fw me-2 fa-address-book text-theme fs-4'></i>address-book</>},
  { value:'fas fa-lg fa-fw me-2 fa-address-card', label: <><i className='fas fa-lg fa-fw me-2 fa-address-card text-theme fs-4'></i>address-card</>},
  { value:'fas fa-lg fa-fw me-2 fa-adjust', label: <><i className='fas fa-lg fa-fw me-2 fa-adjust text-theme fs-4'></i>adjust</>},
  { value:'fas fa-lg fa-fw me-2 fa-align-center', label: <><i className='fas fa-lg fa-fw me-2 fa-align-center text-theme fs-4'></i>align-center</>},
  { value:'fas fa-lg fa-fw me-2 fa-align-justify', label: <><i className='fas fa-lg fa-fw me-2 fa-align-justify text-theme fs-4'></i>align-justify</>},
  { value:'fas fa-lg fa-fw me-2 fa-align-left', label: <><i className='fas fa-lg fa-fw me-2 fa-align-left text-theme fs-4'></i>align-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-align-right', label: <><i className='fas fa-lg fa-fw me-2 fa-align-right text-theme fs-4'></i>align-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-ambulance', label: <><i className='fas fa-lg fa-fw me-2 fa-ambulance text-theme fs-4'></i>ambulance</>},
  { value:'fas fa-lg fa-fw me-2 fa-american-sign-language-interpreting', label: <><i className='fas fa-lg fa-fw me-2 fa-american-sign-language-interpreting text-theme fs-4'></i>american-sign-language-interpreting</>},
  { value:'fas fa-lg fa-fw me-2 fa-anchor', label: <><i className='fas fa-lg fa-fw me-2 fa-anchor text-theme fs-4'></i>anchor</>},
  { value:'fas fa-lg fa-fw me-2 fa-angle-double-down', label: <><i className='fas fa-lg fa-fw me-2 fa-angle-double-down text-theme fs-4'></i>angle-double-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-angle-double-left', label: <><i className='fas fa-lg fa-fw me-2 fa-angle-double-left text-theme fs-4'></i>angle-double-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-angle-double-right', label: <><i className='fas fa-lg fa-fw me-2 fa-angle-double-right text-theme fs-4'></i>angle-double-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-angle-double-up', label: <><i className='fas fa-lg fa-fw me-2 fa-angle-double-up text-theme fs-4'></i>angle-double-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-angle-down', label: <><i className='fas fa-lg fa-fw me-2 fa-angle-down text-theme fs-4'></i>angle-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-angle-left', label: <><i className='fas fa-lg fa-fw me-2 fa-angle-left text-theme fs-4'></i>angle-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-angle-right', label: <><i className='fas fa-lg fa-fw me-2 fa-angle-right text-theme fs-4'></i>angle-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-angle-up', label: <><i className='fas fa-lg fa-fw me-2 fa-angle-up text-theme fs-4'></i>angle-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-archive', label: <><i className='fas fa-lg fa-fw me-2 fa-archive text-theme fs-4'></i>archive</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-alt-circle-down', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-alt-circle-down text-theme fs-4'></i>arrow-alt-circle-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-alt-circle-left', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-alt-circle-left text-theme fs-4'></i>arrow-alt-circle-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-alt-circle-right', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-alt-circle-right text-theme fs-4'></i>arrow-alt-circle-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-alt-circle-up', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-alt-circle-up text-theme fs-4'></i>arrow-alt-circle-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-circle-down', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-circle-down text-theme fs-4'></i>arrow-circle-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-circle-left', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-circle-left text-theme fs-4'></i>arrow-circle-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-circle-right', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-circle-right text-theme fs-4'></i>arrow-circle-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-circle-up', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-circle-up text-theme fs-4'></i>arrow-circle-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-down', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-down text-theme fs-4'></i>arrow-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-left', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-left text-theme fs-4'></i>arrow-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-right', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-right text-theme fs-4'></i>arrow-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-up', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-up text-theme fs-4'></i>arrow-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrows-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-arrows-alt text-theme fs-4'></i>arrows-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrows-alt-h', label: <><i className='fas fa-lg fa-fw me-2 fa-arrows-alt-h text-theme fs-4'></i>arrows-alt-h</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrows-alt-v', label: <><i className='fas fa-lg fa-fw me-2 fa-arrows-alt-v text-theme fs-4'></i>arrows-alt-v</>},
  { value:'fas fa-lg fa-fw me-2 fa-assistive-listening-systems', label: <><i className='fas fa-lg fa-fw me-2 fa-assistive-listening-systems text-theme fs-4'></i>assistive-listening-systems</>},
  { value:'fas fa-lg fa-fw me-2 fa-asterisk', label: <><i className='fas fa-lg fa-fw me-2 fa-asterisk text-theme fs-4'></i>asterisk</>},
  { value:'fas fa-lg fa-fw me-2 fa-at', label: <><i className='fas fa-lg fa-fw me-2 fa-at text-theme fs-4'></i>at</>},
  { value:'fas fa-lg fa-fw me-2 fa-audio-description', label: <><i className='fas fa-lg fa-fw me-2 fa-audio-description text-theme fs-4'></i>audio-description</>},
  { value:'fas fa-lg fa-fw me-2 fa-backward', label: <><i className='fas fa-lg fa-fw me-2 fa-backward text-theme fs-4'></i>backward</>},
  { value:'fas fa-lg fa-fw me-2 fa-balance-scale', label: <><i className='fas fa-lg fa-fw me-2 fa-balance-scale text-theme fs-4'></i>balance-scale</>},
  { value:'fas fa-lg fa-fw me-2 fa-ban', label: <><i className='fas fa-lg fa-fw me-2 fa-ban text-theme fs-4'></i>ban</>},
  { value:'fas fa-lg fa-fw me-2 fa-barcode', label: <><i className='fas fa-lg fa-fw me-2 fa-barcode text-theme fs-4'></i>barcode</>},
  { value:'fas fa-lg fa-fw me-2 fa-bars', label: <><i className='fas fa-lg fa-fw me-2 fa-bars text-theme fs-4'></i>bars</>},
  { value:'fas fa-lg fa-fw me-2 fa-bath', label: <><i className='fas fa-lg fa-fw me-2 fa-bath text-theme fs-4'></i>bath</>},
  { value:'fas fa-lg fa-fw me-2 fa-battery-empty', label: <><i className='fas fa-lg fa-fw me-2 fa-battery-empty text-theme fs-4'></i>battery-empty</>},
  { value:'fas fa-lg fa-fw me-2 fa-battery-full', label: <><i className='fas fa-lg fa-fw me-2 fa-battery-full text-theme fs-4'></i>battery-full</>},
  { value:'fas fa-lg fa-fw me-2 fa-battery-half', label: <><i className='fas fa-lg fa-fw me-2 fa-battery-half text-theme fs-4'></i>battery-half</>},
  { value:'fas fa-lg fa-fw me-2 fa-battery-quarter', label: <><i className='fas fa-lg fa-fw me-2 fa-battery-quarter text-theme fs-4'></i>battery-quarter</>},
  { value:'fas fa-lg fa-fw me-2 fa-battery-three-quarters', label: <><i className='fas fa-lg fa-fw me-2 fa-battery-three-quarters text-theme fs-4'></i>battery-three-quarters</>},
  { value:'fas fa-lg fa-fw me-2 fa-bed', label: <><i className='fas fa-lg fa-fw me-2 fa-bed text-theme fs-4'></i>bed</>},
  { value:'fas fa-lg fa-fw me-2 fa-beer', label: <><i className='fas fa-lg fa-fw me-2 fa-beer text-theme fs-4'></i>beer</>},
  { value:'fas fa-lg fa-fw me-2 fa-bell', label: <><i className='fas fa-lg fa-fw me-2 fa-bell text-theme fs-4'></i>bell</>},
  { value:'fas fa-lg fa-fw me-2 fa-bell-slash', label: <><i className='fas fa-lg fa-fw me-2 fa-bell-slash text-theme fs-4'></i>bell-slash</>},
  { value:'fas fa-lg fa-fw me-2 fa-bicycle', label: <><i className='fas fa-lg fa-fw me-2 fa-bicycle text-theme fs-4'></i>bicycle</>},
  { value:'fas fa-lg fa-fw me-2 fa-binoculars', label: <><i className='fas fa-lg fa-fw me-2 fa-binoculars text-theme fs-4'></i>binoculars</>},
  { value:'fas fa-lg fa-fw me-2 fa-birthday-cake', label: <><i className='fas fa-lg fa-fw me-2 fa-birthday-cake text-theme fs-4'></i>birthday-cake</>},
  { value:'fas fa-lg fa-fw me-2 fa-blind', label: <><i className='fas fa-lg fa-fw me-2 fa-blind text-theme fs-4'></i>blind</>},
  { value:'fas fa-lg fa-fw me-2 fa-bold', label: <><i className='fas fa-lg fa-fw me-2 fa-bold text-theme fs-4'></i>bold</>},
  { value:'fas fa-lg fa-fw me-2 fa-bolt', label: <><i className='fas fa-lg fa-fw me-2 fa-bolt text-theme fs-4'></i>bolt</>},
  { value:'fas fa-lg fa-fw me-2 fa-bomb', label: <><i className='fas fa-lg fa-fw me-2 fa-bomb text-theme fs-4'></i>bomb</>},
  { value:'fas fa-lg fa-fw me-2 fa-book', label: <><i className='fas fa-lg fa-fw me-2 fa-book text-theme fs-4'></i>book</>},
  { value:'fas fa-lg fa-fw me-2 fa-bookmark', label: <><i className='fas fa-lg fa-fw me-2 fa-bookmark text-theme fs-4'></i>bookmark</>},
  { value:'fas fa-lg fa-fw me-2 fa-braille', label: <><i className='fas fa-lg fa-fw me-2 fa-braille text-theme fs-4'></i>braille</>},
  { value:'fas fa-lg fa-fw me-2 fa-briefcase', label: <><i className='fas fa-lg fa-fw me-2 fa-briefcase text-theme fs-4'></i>briefcase</>},
  { value:'fas fa-lg fa-fw me-2 fa-bug', label: <><i className='fas fa-lg fa-fw me-2 fa-bug text-theme fs-4'></i>bug</>},
  { value:'fas fa-lg fa-fw me-2 fa-building', label: <><i className='fas fa-lg fa-fw me-2 fa-building text-theme fs-4'></i>building</>},
  { value:'fas fa-lg fa-fw me-2 fa-bullhorn', label: <><i className='fas fa-lg fa-fw me-2 fa-bullhorn text-theme fs-4'></i>bullhorn</>},
  { value:'fas fa-lg fa-fw me-2 fa-bullseye', label: <><i className='fas fa-lg fa-fw me-2 fa-bullseye text-theme fs-4'></i>bullseye</>},
  { value:'fas fa-lg fa-fw me-2 fa-bus', label: <><i className='fas fa-lg fa-fw me-2 fa-bus text-theme fs-4'></i>bus</>},
  { value:'fas fa-lg fa-fw me-2 fa-calculator', label: <><i className='fas fa-lg fa-fw me-2 fa-calculator text-theme fs-4'></i>calculator</>},
  { value:'fas fa-lg fa-fw me-2 fa-calendar', label: <><i className='fas fa-lg fa-fw me-2 fa-calendar text-theme fs-4'></i>calendar</>},
  { value:'fas fa-lg fa-fw me-2 fa-calendar-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-calendar-alt text-theme fs-4'></i>calendar-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-calendar-check', label: <><i className='fas fa-lg fa-fw me-2 fa-calendar-check text-theme fs-4'></i>calendar-check</>},
  { value:'fas fa-lg fa-fw me-2 fa-calendar-minus', label: <><i className='fas fa-lg fa-fw me-2 fa-calendar-minus text-theme fs-4'></i>calendar-minus</>},
  { value:'fas fa-lg fa-fw me-2 fa-calendar-plus', label: <><i className='fas fa-lg fa-fw me-2 fa-calendar-plus text-theme fs-4'></i>calendar-plus</>},
  { value:'fas fa-lg fa-fw me-2 fa-calendar-times', label: <><i className='fas fa-lg fa-fw me-2 fa-calendar-times text-theme fs-4'></i>calendar-times</>},
  { value:'fas fa-lg fa-fw me-2 fa-camera', label: <><i className='fas fa-lg fa-fw me-2 fa-camera text-theme fs-4'></i>camera</>},
  { value:'fas fa-lg fa-fw me-2 fa-camera-retro', label: <><i className='fas fa-lg fa-fw me-2 fa-camera-retro text-theme fs-4'></i>camera-retro</>},
  { value:'fas fa-lg fa-fw me-2 fa-car', label: <><i className='fas fa-lg fa-fw me-2 fa-car text-theme fs-4'></i>car</>},
  { value:'fas fa-lg fa-fw me-2 fa-caret-down', label: <><i className='fas fa-lg fa-fw me-2 fa-caret-down text-theme fs-4'></i>caret-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-caret-left', label: <><i className='fas fa-lg fa-fw me-2 fa-caret-left text-theme fs-4'></i>caret-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-caret-right', label: <><i className='fas fa-lg fa-fw me-2 fa-caret-right text-theme fs-4'></i>caret-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-caret-square-down', label: <><i className='fas fa-lg fa-fw me-2 fa-caret-square-down text-theme fs-4'></i>caret-square-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-caret-square-left', label: <><i className='fas fa-lg fa-fw me-2 fa-caret-square-left text-theme fs-4'></i>caret-square-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-caret-square-right', label: <><i className='fas fa-lg fa-fw me-2 fa-caret-square-right text-theme fs-4'></i>caret-square-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-caret-square-up', label: <><i className='fas fa-lg fa-fw me-2 fa-caret-square-up text-theme fs-4'></i>caret-square-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-caret-up', label: <><i className='fas fa-lg fa-fw me-2 fa-caret-up text-theme fs-4'></i>caret-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-cart-arrow-down', label: <><i className='fas fa-lg fa-fw me-2 fa-cart-arrow-down text-theme fs-4'></i>cart-arrow-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-cart-plus', label: <><i className='fas fa-lg fa-fw me-2 fa-cart-plus text-theme fs-4'></i>cart-plus</>},
  { value:'fas fa-lg fa-fw me-2 fa-certificate', label: <><i className='fas fa-lg fa-fw me-2 fa-certificate text-theme fs-4'></i>certificate</>},
  { value:'fas fa-lg fa-fw me-2 fa-chart-area', label: <><i className='fas fa-lg fa-fw me-2 fa-chart-area text-theme fs-4'></i>chart-area</>},
  { value:'fas fa-lg fa-fw me-2 fa-chart-bar', label: <><i className='fas fa-lg fa-fw me-2 fa-chart-bar text-theme fs-4'></i>chart-bar</>},
  { value:'fas fa-lg fa-fw me-2 fa-chart-line', label: <><i className='fas fa-lg fa-fw me-2 fa-chart-line text-theme fs-4'></i>chart-line</>},
  { value:'fas fa-lg fa-fw me-2 fa-chart-pie', label: <><i className='fas fa-lg fa-fw me-2 fa-chart-pie text-theme fs-4'></i>chart-pie</>},
  { value:'fas fa-lg fa-fw me-2 fa-check', label: <><i className='fas fa-lg fa-fw me-2 fa-check text-theme fs-4'></i>check</>},
  { value:'fas fa-lg fa-fw me-2 fa-check-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-check-circle text-theme fs-4'></i>check-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-check-square', label: <><i className='fas fa-lg fa-fw me-2 fa-check-square text-theme fs-4'></i>check-square</>},
  { value:'fas fa-lg fa-fw me-2 fa-chevron-circle-down', label: <><i className='fas fa-lg fa-fw me-2 fa-chevron-circle-down text-theme fs-4'></i>chevron-circle-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-chevron-circle-left', label: <><i className='fas fa-lg fa-fw me-2 fa-chevron-circle-left text-theme fs-4'></i>chevron-circle-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-chevron-circle-right', label: <><i className='fas fa-lg fa-fw me-2 fa-chevron-circle-right text-theme fs-4'></i>chevron-circle-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-chevron-circle-up', label: <><i className='fas fa-lg fa-fw me-2 fa-chevron-circle-up text-theme fs-4'></i>chevron-circle-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-chevron-down', label: <><i className='fas fa-lg fa-fw me-2 fa-chevron-down text-theme fs-4'></i>chevron-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-chevron-left', label: <><i className='fas fa-lg fa-fw me-2 fa-chevron-left text-theme fs-4'></i>chevron-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-chevron-right', label: <><i className='fas fa-lg fa-fw me-2 fa-chevron-right text-theme fs-4'></i>chevron-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-chevron-up', label: <><i className='fas fa-lg fa-fw me-2 fa-chevron-up text-theme fs-4'></i>chevron-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-child', label: <><i className='fas fa-lg fa-fw me-2 fa-child text-theme fs-4'></i>child</>},
  { value:'fas fa-lg fa-fw me-2 fa-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-circle text-theme fs-4'></i>circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-circle-notch', label: <><i className='fas fa-lg fa-fw me-2 fa-circle-notch text-theme fs-4'></i>circle-notch</>},
  { value:'fas fa-lg fa-fw me-2 fa-clipboard', label: <><i className='fas fa-lg fa-fw me-2 fa-clipboard text-theme fs-4'></i>clipboard</>},
  { value:'fas fa-lg fa-fw me-2 fa-clock', label: <><i className='fas fa-lg fa-fw me-2 fa-clock text-theme fs-4'></i>clock</>},
  { value:'fas fa-lg fa-fw me-2 fa-clone', label: <><i className='fas fa-lg fa-fw me-2 fa-clone text-theme fs-4'></i>clone</>},
  { value:'fas fa-lg fa-fw me-2 fa-closed-captioning', label: <><i className='fas fa-lg fa-fw me-2 fa-closed-captioning text-theme fs-4'></i>closed-captioning</>},
  { value:'fas fa-lg fa-fw me-2 fa-cloud', label: <><i className='fas fa-lg fa-fw me-2 fa-cloud text-theme fs-4'></i>cloud</>},
  { value:'fas fa-lg fa-fw me-2 fa-code', label: <><i className='fas fa-lg fa-fw me-2 fa-code text-theme fs-4'></i>code</>},
  { value:'fas fa-lg fa-fw me-2 fa-code-branch', label: <><i className='fas fa-lg fa-fw me-2 fa-code-branch text-theme fs-4'></i>code-branch</>},
  { value:'fas fa-lg fa-fw me-2 fa-coffee', label: <><i className='fas fa-lg fa-fw me-2 fa-coffee text-theme fs-4'></i>coffee</>},
  { value:'fas fa-lg fa-fw me-2 fa-cog', label: <><i className='fas fa-lg fa-fw me-2 fa-cog text-theme fs-4'></i>cog</>},
  { value:'fas fa-lg fa-fw me-2 fa-cogs', label: <><i className='fas fa-lg fa-fw me-2 fa-cogs text-theme fs-4'></i>cogs</>},
  { value:'fas fa-lg fa-fw me-2 fa-columns', label: <><i className='fas fa-lg fa-fw me-2 fa-columns text-theme fs-4'></i>columns</>},
  { value:'fas fa-lg fa-fw me-2 fa-comment', label: <><i className='fas fa-lg fa-fw me-2 fa-comment text-theme fs-4'></i>comment</>},
  { value:'fas fa-lg fa-fw me-2 fa-comment-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-comment-alt text-theme fs-4'></i>comment-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-comments', label: <><i className='fas fa-lg fa-fw me-2 fa-comments text-theme fs-4'></i>comments</>},
  { value:'fas fa-lg fa-fw me-2 fa-compass', label: <><i className='fas fa-lg fa-fw me-2 fa-compass text-theme fs-4'></i>compass</>},
  { value:'fas fa-lg fa-fw me-2 fa-compress', label: <><i className='fas fa-lg fa-fw me-2 fa-compress text-theme fs-4'></i>compress</>},
  { value:'fas fa-lg fa-fw me-2 fa-copy', label: <><i className='fas fa-lg fa-fw me-2 fa-copy text-theme fs-4'></i>copy</>},
  { value:'fas fa-lg fa-fw me-2 fa-copyright', label: <><i className='fas fa-lg fa-fw me-2 fa-copyright text-theme fs-4'></i>copyright</>},
  { value:'fas fa-lg fa-fw me-2 fa-credit-card', label: <><i className='fas fa-lg fa-fw me-2 fa-credit-card text-theme fs-4'></i>credit-card</>},
  { value:'fas fa-lg fa-fw me-2 fa-crop', label: <><i className='fas fa-lg fa-fw me-2 fa-crop text-theme fs-4'></i>crop</>},
  { value:'fas fa-lg fa-fw me-2 fa-crosshairs', label: <><i className='fas fa-lg fa-fw me-2 fa-crosshairs text-theme fs-4'></i>crosshairs</>},
  { value:'fas fa-lg fa-fw me-2 fa-cube', label: <><i className='fas fa-lg fa-fw me-2 fa-cube text-theme fs-4'></i>cube</>},
  { value:'fas fa-lg fa-fw me-2 fa-cubes', label: <><i className='fas fa-lg fa-fw me-2 fa-cubes text-theme fs-4'></i>cubes</>},
  { value:'fas fa-lg fa-fw me-2 fa-cut', label: <><i className='fas fa-lg fa-fw me-2 fa-cut text-theme fs-4'></i>cut</>},
  { value:'fas fa-lg fa-fw me-2 fa-database', label: <><i className='fas fa-lg fa-fw me-2 fa-database text-theme fs-4'></i>database</>},
  { value:'fas fa-lg fa-fw me-2 fa-deaf', label: <><i className='fas fa-lg fa-fw me-2 fa-deaf text-theme fs-4'></i>deaf</>},
  { value:'fas fa-lg fa-fw me-2 fa-dollar-sign', label: <><i className='fas fa-lg fa-fw me-2 fa-dollar-sign text-theme fs-4'></i>dollar-sign</>},
  { value:'fas fa-lg fa-fw me-2 fa-dot-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-dot-circle text-theme fs-4'></i>dot-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-download', label: <><i className='fas fa-lg fa-fw me-2 fa-download text-theme fs-4'></i>download</>},
  { value:'fas fa-lg fa-fw me-2 fa-edit', label: <><i className='fas fa-lg fa-fw me-2 fa-edit text-theme fs-4'></i>edit</>},
  { value:'fas fa-lg fa-fw me-2 fa-eject', label: <><i className='fas fa-lg fa-fw me-2 fa-eject text-theme fs-4'></i>eject</>},
  { value:'fas fa-lg fa-fw me-2 fa-ellipsis-h', label: <><i className='fas fa-lg fa-fw me-2 fa-ellipsis-h text-theme fs-4'></i>ellipsis-h</>},
  { value:'fas fa-lg fa-fw me-2 fa-ellipsis-v', label: <><i className='fas fa-lg fa-fw me-2 fa-ellipsis-v text-theme fs-4'></i>ellipsis-v</>},
  { value:'fas fa-lg fa-fw me-2 fa-envelope', label: <><i className='fas fa-lg fa-fw me-2 fa-envelope text-theme fs-4'></i>envelope</>},
  { value:'fas fa-lg fa-fw me-2 fa-envelope-open', label: <><i className='fas fa-lg fa-fw me-2 fa-envelope-open text-theme fs-4'></i>envelope-open</>},
  { value:'fas fa-lg fa-fw me-2 fa-envelope-square', label: <><i className='fas fa-lg fa-fw me-2 fa-envelope-square text-theme fs-4'></i>envelope-square</>},
  { value:'fas fa-lg fa-fw me-2 fa-eraser', label: <><i className='fas fa-lg fa-fw me-2 fa-eraser text-theme fs-4'></i>eraser</>},
  { value:'fas fa-lg fa-fw me-2 fa-euro-sign', label: <><i className='fas fa-lg fa-fw me-2 fa-euro-sign text-theme fs-4'></i>euro-sign</>},
  { value:'fas fa-lg fa-fw me-2 fa-exchange-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-exchange-alt text-theme fs-4'></i>exchange-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-exclamation', label: <><i className='fas fa-lg fa-fw me-2 fa-exclamation text-theme fs-4'></i>exclamation</>},
  { value:'fas fa-lg fa-fw me-2 fa-exclamation-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-exclamation-circle text-theme fs-4'></i>exclamation-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-exclamation-triangle', label: <><i className='fas fa-lg fa-fw me-2 fa-exclamation-triangle text-theme fs-4'></i>exclamation-triangle</>},
  { value:'fas fa-lg fa-fw me-2 fa-expand', label: <><i className='fas fa-lg fa-fw me-2 fa-expand text-theme fs-4'></i>expand</>},
  { value:'fas fa-lg fa-fw me-2 fa-expand-arrows-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-expand-arrows-alt text-theme fs-4'></i>expand-arrows-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-external-link-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-external-link-alt text-theme fs-4'></i>external-link-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-external-link-square-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-external-link-square-alt text-theme fs-4'></i>external-link-square-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-eye', label: <><i className='fas fa-lg fa-fw me-2 fa-eye text-theme fs-4'></i>eye</>},
  { value:'fas fa-lg fa-fw me-2 fa-eye-dropper', label: <><i className='fas fa-lg fa-fw me-2 fa-eye-dropper text-theme fs-4'></i>eye-dropper</>},
  { value:'fas fa-lg fa-fw me-2 fa-eye-slash', label: <><i className='fas fa-lg fa-fw me-2 fa-eye-slash text-theme fs-4'></i>eye-slash</>},
  { value:'fas fa-lg fa-fw me-2 fa-fast-backward', label: <><i className='fas fa-lg fa-fw me-2 fa-fast-backward text-theme fs-4'></i>fast-backward</>},
  { value:'fas fa-lg fa-fw me-2 fa-fast-forward', label: <><i className='fas fa-lg fa-fw me-2 fa-fast-forward text-theme fs-4'></i>fast-forward</>},
  { value:'fas fa-lg fa-fw me-2 fa-fax', label: <><i className='fas fa-lg fa-fw me-2 fa-fax text-theme fs-4'></i>fax</>},
  { value:'fas fa-lg fa-fw me-2 fa-female', label: <><i className='fas fa-lg fa-fw me-2 fa-female text-theme fs-4'></i>female</>},
  { value:'fas fa-lg fa-fw me-2 fa-fighter-jet', label: <><i className='fas fa-lg fa-fw me-2 fa-fighter-jet text-theme fs-4'></i>fighter-jet</>},
  { value:'fas fa-lg fa-fw me-2 fa-file', label: <><i className='fas fa-lg fa-fw me-2 fa-file text-theme fs-4'></i>file</>},
  { value:'fas fa-lg fa-fw me-2 fa-file-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-file-alt text-theme fs-4'></i>file-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-file-archive', label: <><i className='fas fa-lg fa-fw me-2 fa-file-archive text-theme fs-4'></i>file-archive</>},
  { value:'fas fa-lg fa-fw me-2 fa-file-audio', label: <><i className='fas fa-lg fa-fw me-2 fa-file-audio text-theme fs-4'></i>file-audio</>},
  { value:'fas fa-lg fa-fw me-2 fa-file-code', label: <><i className='fas fa-lg fa-fw me-2 fa-file-code text-theme fs-4'></i>file-code</>},
  { value:'fas fa-lg fa-fw me-2 fa-file-excel', label: <><i className='fas fa-lg fa-fw me-2 fa-file-excel text-theme fs-4'></i>file-excel</>},
  { value:'fas fa-lg fa-fw me-2 fa-file-image', label: <><i className='fas fa-lg fa-fw me-2 fa-file-image text-theme fs-4'></i>file-image</>},
  { value:'fas fa-lg fa-fw me-2 fa-file-pdf', label: <><i className='fas fa-lg fa-fw me-2 fa-file-pdf text-theme fs-4'></i>file-pdf</>},
  { value:'fas fa-lg fa-fw me-2 fa-file-powerpoint', label: <><i className='fas fa-lg fa-fw me-2 fa-file-powerpoint text-theme fs-4'></i>file-powerpoint</>},
  { value:'fas fa-lg fa-fw me-2 fa-file-video', label: <><i className='fas fa-lg fa-fw me-2 fa-file-video text-theme fs-4'></i>file-video</>},
  { value:'fas fa-lg fa-fw me-2 fa-file-word', label: <><i className='fas fa-lg fa-fw me-2 fa-file-word text-theme fs-4'></i>file-word</>},
  { value:'fas fa-lg fa-fw me-2 fa-film', label: <><i className='fas fa-lg fa-fw me-2 fa-film text-theme fs-4'></i>film</>},
  { value:'fas fa-lg fa-fw me-2 fa-filter', label: <><i className='fas fa-lg fa-fw me-2 fa-filter text-theme fs-4'></i>filter</>},
  { value:'fas fa-lg fa-fw me-2 fa-fire', label: <><i className='fas fa-lg fa-fw me-2 fa-fire text-theme fs-4'></i>fire</>},
  { value:'fas fa-lg fa-fw me-2 fa-fire-extinguisher', label: <><i className='fas fa-lg fa-fw me-2 fa-fire-extinguisher text-theme fs-4'></i>fire-extinguisher</>},
  { value:'fas fa-lg fa-fw me-2 fa-flag', label: <><i className='fas fa-lg fa-fw me-2 fa-flag text-theme fs-4'></i>flag</>},
  { value:'fas fa-lg fa-fw me-2 fa-flag-checkered', label: <><i className='fas fa-lg fa-fw me-2 fa-flag-checkered text-theme fs-4'></i>flag-checkered</>},
  { value:'fas fa-lg fa-fw me-2 fa-flask', label: <><i className='fas fa-lg fa-fw me-2 fa-flask text-theme fs-4'></i>flask</>},
  { value:'fas fa-lg fa-fw me-2 fa-folder', label: <><i className='fas fa-lg fa-fw me-2 fa-folder text-theme fs-4'></i>folder</>},
  { value:'fas fa-lg fa-fw me-2 fa-folder-open', label: <><i className='fas fa-lg fa-fw me-2 fa-folder-open text-theme fs-4'></i>folder-open</>},
  { value:'fas fa-lg fa-fw me-2 fa-font', label: <><i className='fas fa-lg fa-fw me-2 fa-font text-theme fs-4'></i>font</>},
  { value:'fas fa-lg fa-fw me-2 fa-forward', label: <><i className='fas fa-lg fa-fw me-2 fa-forward text-theme fs-4'></i>forward</>},
  { value:'fas fa-lg fa-fw me-2 fa-frown', label: <><i className='fas fa-lg fa-fw me-2 fa-frown text-theme fs-4'></i>frown</>},
  { value:'fas fa-lg fa-fw me-2 fa-futbol', label: <><i className='fas fa-lg fa-fw me-2 fa-futbol text-theme fs-4'></i>futbol</>},
  { value:'fas fa-lg fa-fw me-2 fa-gamepad', label: <><i className='fas fa-lg fa-fw me-2 fa-gamepad text-theme fs-4'></i>gamepad</>},
  { value:'fas fa-lg fa-fw me-2 fa-gavel', label: <><i className='fas fa-lg fa-fw me-2 fa-gavel text-theme fs-4'></i>gavel</>},
  { value:'fas fa-lg fa-fw me-2 fa-gem', label: <><i className='fas fa-lg fa-fw me-2 fa-gem text-theme fs-4'></i>gem</>},
  { value:'fas fa-lg fa-fw me-2 fa-genderless', label: <><i className='fas fa-lg fa-fw me-2 fa-genderless text-theme fs-4'></i>genderless</>},
  { value:'fas fa-lg fa-fw me-2 fa-gift', label: <><i className='fas fa-lg fa-fw me-2 fa-gift text-theme fs-4'></i>gift</>},
  { value:'fas fa-lg fa-fw me-2 fa-glass-martini', label: <><i className='fas fa-lg fa-fw me-2 fa-glass-martini text-theme fs-4'></i>glass-martini</>},
  { value:'fas fa-lg fa-fw me-2 fa-globe', label: <><i className='fas fa-lg fa-fw me-2 fa-globe text-theme fs-4'></i>globe</>},
  { value:'fas fa-lg fa-fw me-2 fa-graduation-cap', label: <><i className='fas fa-lg fa-fw me-2 fa-graduation-cap text-theme fs-4'></i>graduation-cap</>},
  { value:'fas fa-lg fa-fw me-2 fa-h-square', label: <><i className='fas fa-lg fa-fw me-2 fa-h-square text-theme fs-4'></i>h-square</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-lizard', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-lizard text-theme fs-4'></i>hand-lizard</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-paper', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-paper text-theme fs-4'></i>hand-paper</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-peace', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-peace text-theme fs-4'></i>hand-peace</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-point-down', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-point-down text-theme fs-4'></i>hand-point-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-point-left', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-point-left text-theme fs-4'></i>hand-point-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-point-right', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-point-right text-theme fs-4'></i>hand-point-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-point-up', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-point-up text-theme fs-4'></i>hand-point-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-pointer', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-pointer text-theme fs-4'></i>hand-pointer</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-rock', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-rock text-theme fs-4'></i>hand-rock</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-scissors', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-scissors text-theme fs-4'></i>hand-scissors</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-spock', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-spock text-theme fs-4'></i>hand-spock</>},
  { value:'fas fa-lg fa-fw me-2 fa-handshake', label: <><i className='fas fa-lg fa-fw me-2 fa-handshake text-theme fs-4'></i>handshake</>},
  { value:'fas fa-lg fa-fw me-2 fa-hashtag', label: <><i className='fas fa-lg fa-fw me-2 fa-hashtag text-theme fs-4'></i>hashtag</>},
  { value:'fas fa-lg fa-fw me-2 fa-hdd', label: <><i className='fas fa-lg fa-fw me-2 fa-hdd text-theme fs-4'></i>hdd</>},
  { value:'fas fa-lg fa-fw me-2 fa-heading', label: <><i className='fas fa-lg fa-fw me-2 fa-heading text-theme fs-4'></i>heading</>},
  { value:'fas fa-lg fa-fw me-2 fa-headphones', label: <><i className='fas fa-lg fa-fw me-2 fa-headphones text-theme fs-4'></i>headphones</>},
  { value:'fas fa-lg fa-fw me-2 fa-heart', label: <><i className='fas fa-lg fa-fw me-2 fa-heart text-theme fs-4'></i>heart</>},
  { value:'fas fa-lg fa-fw me-2 fa-heartbeat', label: <><i className='fas fa-lg fa-fw me-2 fa-heartbeat text-theme fs-4'></i>heartbeat</>},
  { value:'fas fa-lg fa-fw me-2 fa-history', label: <><i className='fas fa-lg fa-fw me-2 fa-history text-theme fs-4'></i>history</>},
  { value:'fas fa-lg fa-fw me-2 fa-home', label: <><i className='fas fa-lg fa-fw me-2 fa-home text-theme fs-4'></i>home</>},
  { value:'fas fa-lg fa-fw me-2 fa-hospital', label: <><i className='fas fa-lg fa-fw me-2 fa-hospital text-theme fs-4'></i>hospital</>},
  { value:'fas fa-lg fa-fw me-2 fa-hourglass', label: <><i className='fas fa-lg fa-fw me-2 fa-hourglass text-theme fs-4'></i>hourglass</>},
  { value:'fas fa-lg fa-fw me-2 fa-hourglass-end', label: <><i className='fas fa-lg fa-fw me-2 fa-hourglass-end text-theme fs-4'></i>hourglass-end</>},
  { value:'fas fa-lg fa-fw me-2 fa-hourglass-half', label: <><i className='fas fa-lg fa-fw me-2 fa-hourglass-half text-theme fs-4'></i>hourglass-half</>},
  { value:'fas fa-lg fa-fw me-2 fa-hourglass-start', label: <><i className='fas fa-lg fa-fw me-2 fa-hourglass-start text-theme fs-4'></i>hourglass-start</>},
  { value:'fas fa-lg fa-fw me-2 fa-i-cursor', label: <><i className='fas fa-lg fa-fw me-2 fa-i-cursor text-theme fs-4'></i>i-cursor</>},
  { value:'fas fa-lg fa-fw me-2 fa-id-badge', label: <><i className='fas fa-lg fa-fw me-2 fa-id-badge text-theme fs-4'></i>id-badge</>},
  { value:'fas fa-lg fa-fw me-2 fa-id-card', label: <><i className='fas fa-lg fa-fw me-2 fa-id-card text-theme fs-4'></i>id-card</>},
  { value:'fas fa-lg fa-fw me-2 fa-image', label: <><i className='fas fa-lg fa-fw me-2 fa-image text-theme fs-4'></i>image</>},
  { value:'fas fa-lg fa-fw me-2 fa-images', label: <><i className='fas fa-lg fa-fw me-2 fa-images text-theme fs-4'></i>images</>},
  { value:'fas fa-lg fa-fw me-2 fa-inbox', label: <><i className='fas fa-lg fa-fw me-2 fa-inbox text-theme fs-4'></i>inbox</>},
  { value:'fas fa-lg fa-fw me-2 fa-indent', label: <><i className='fas fa-lg fa-fw me-2 fa-indent text-theme fs-4'></i>indent</>},
  { value:'fas fa-lg fa-fw me-2 fa-industry', label: <><i className='fas fa-lg fa-fw me-2 fa-industry text-theme fs-4'></i>industry</>},
  { value:'fas fa-lg fa-fw me-2 fa-info', label: <><i className='fas fa-lg fa-fw me-2 fa-info text-theme fs-4'></i>info</>},
  { value:'fas fa-lg fa-fw me-2 fa-info-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-info-circle text-theme fs-4'></i>info-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-italic', label: <><i className='fas fa-lg fa-fw me-2 fa-italic text-theme fs-4'></i>italic</>},
  { value:'fas fa-lg fa-fw me-2 fa-key', label: <><i className='fas fa-lg fa-fw me-2 fa-key text-theme fs-4'></i>key</>},
  { value:'fas fa-lg fa-fw me-2 fa-keyboard', label: <><i className='fas fa-lg fa-fw me-2 fa-keyboard text-theme fs-4'></i>keyboard</>},
  { value:'fas fa-lg fa-fw me-2 fa-language', label: <><i className='fas fa-lg fa-fw me-2 fa-language text-theme fs-4'></i>language</>},
  { value:'fas fa-lg fa-fw me-2 fa-laptop', label: <><i className='fas fa-lg fa-fw me-2 fa-laptop text-theme fs-4'></i>laptop</>},
  { value:'fas fa-lg fa-fw me-2 fa-leaf', label: <><i className='fas fa-lg fa-fw me-2 fa-leaf text-theme fs-4'></i>leaf</>},
  { value:'fas fa-lg fa-fw me-2 fa-lemon', label: <><i className='fas fa-lg fa-fw me-2 fa-lemon text-theme fs-4'></i>lemon</>},
  { value:'fas fa-lg fa-fw me-2 fa-level-down-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-level-down-alt text-theme fs-4'></i>level-down-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-level-up-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-level-up-alt text-theme fs-4'></i>level-up-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-life-ring', label: <><i className='fas fa-lg fa-fw me-2 fa-life-ring text-theme fs-4'></i>life-ring</>},
  { value:'fas fa-lg fa-fw me-2 fa-lightbulb', label: <><i className='fas fa-lg fa-fw me-2 fa-lightbulb text-theme fs-4'></i>lightbulb</>},
  { value:'fas fa-lg fa-fw me-2 fa-link', label: <><i className='fas fa-lg fa-fw me-2 fa-link text-theme fs-4'></i>link</>},
  { value:'fas fa-lg fa-fw me-2 fa-lira-sign', label: <><i className='fas fa-lg fa-fw me-2 fa-lira-sign text-theme fs-4'></i>lira-sign</>},
  { value:'fas fa-lg fa-fw me-2 fa-list', label: <><i className='fas fa-lg fa-fw me-2 fa-list text-theme fs-4'></i>list</>},
  { value:'fas fa-lg fa-fw me-2 fa-list-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-list-alt text-theme fs-4'></i>list-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-list-ol', label: <><i className='fas fa-lg fa-fw me-2 fa-list-ol text-theme fs-4'></i>list-ol</>},
  { value:'fas fa-lg fa-fw me-2 fa-list-ul', label: <><i className='fas fa-lg fa-fw me-2 fa-list-ul text-theme fs-4'></i>list-ul</>},
  { value:'fas fa-lg fa-fw me-2 fa-location-arrow', label: <><i className='fas fa-lg fa-fw me-2 fa-location-arrow text-theme fs-4'></i>location-arrow</>},
  { value:'fas fa-lg fa-fw me-2 fa-lock', label: <><i className='fas fa-lg fa-fw me-2 fa-lock text-theme fs-4'></i>lock</>},
  { value:'fas fa-lg fa-fw me-2 fa-lock-open', label: <><i className='fas fa-lg fa-fw me-2 fa-lock-open text-theme fs-4'></i>lock-open</>},
  { value:'fas fa-lg fa-fw me-2 fa-long-arrow-alt-down', label: <><i className='fas fa-lg fa-fw me-2 fa-long-arrow-alt-down text-theme fs-4'></i>long-arrow-alt-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-long-arrow-alt-left', label: <><i className='fas fa-lg fa-fw me-2 fa-long-arrow-alt-left text-theme fs-4'></i>long-arrow-alt-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-long-arrow-alt-right', label: <><i className='fas fa-lg fa-fw me-2 fa-long-arrow-alt-right text-theme fs-4'></i>long-arrow-alt-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-long-arrow-alt-up', label: <><i className='fas fa-lg fa-fw me-2 fa-long-arrow-alt-up text-theme fs-4'></i>long-arrow-alt-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-low-vision', label: <><i className='fas fa-lg fa-fw me-2 fa-low-vision text-theme fs-4'></i>low-vision</>},
  { value:'fas fa-lg fa-fw me-2 fa-magic', label: <><i className='fas fa-lg fa-fw me-2 fa-magic text-theme fs-4'></i>magic</>},
  { value:'fas fa-lg fa-fw me-2 fa-magnet', label: <><i className='fas fa-lg fa-fw me-2 fa-magnet text-theme fs-4'></i>magnet</>},
  { value:'fas fa-lg fa-fw me-2 fa-male', label: <><i className='fas fa-lg fa-fw me-2 fa-male text-theme fs-4'></i>male</>},
  { value:'fas fa-lg fa-fw me-2 fa-map', label: <><i className='fas fa-lg fa-fw me-2 fa-map text-theme fs-4'></i>map</>},
  { value:'fas fa-lg fa-fw me-2 fa-map-marker', label: <><i className='fas fa-lg fa-fw me-2 fa-map-marker text-theme fs-4'></i>map-marker</>},
  { value:'fas fa-lg fa-fw me-2 fa-map-marker-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-map-marker-alt text-theme fs-4'></i>map-marker-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-map-pin', label: <><i className='fas fa-lg fa-fw me-2 fa-map-pin text-theme fs-4'></i>map-pin</>},
  { value:'fas fa-lg fa-fw me-2 fa-map-signs', label: <><i className='fas fa-lg fa-fw me-2 fa-map-signs text-theme fs-4'></i>map-signs</>},
  { value:'fas fa-lg fa-fw me-2 fa-mars', label: <><i className='fas fa-lg fa-fw me-2 fa-mars text-theme fs-4'></i>mars</>},
  { value:'fas fa-lg fa-fw me-2 fa-mars-double', label: <><i className='fas fa-lg fa-fw me-2 fa-mars-double text-theme fs-4'></i>mars-double</>},
  { value:'fas fa-lg fa-fw me-2 fa-mars-stroke', label: <><i className='fas fa-lg fa-fw me-2 fa-mars-stroke text-theme fs-4'></i>mars-stroke</>},
  { value:'fas fa-lg fa-fw me-2 fa-mars-stroke-h', label: <><i className='fas fa-lg fa-fw me-2 fa-mars-stroke-h text-theme fs-4'></i>mars-stroke-h</>},
  { value:'fas fa-lg fa-fw me-2 fa-mars-stroke-v', label: <><i className='fas fa-lg fa-fw me-2 fa-mars-stroke-v text-theme fs-4'></i>mars-stroke-v</>},
  { value:'fas fa-lg fa-fw me-2 fa-medkit', label: <><i className='fas fa-lg fa-fw me-2 fa-medkit text-theme fs-4'></i>medkit</>},
  { value:'fas fa-lg fa-fw me-2 fa-meh', label: <><i className='fas fa-lg fa-fw me-2 fa-meh text-theme fs-4'></i>meh</>},
  { value:'fas fa-lg fa-fw me-2 fa-mercury', label: <><i className='fas fa-lg fa-fw me-2 fa-mercury text-theme fs-4'></i>mercury</>},
  { value:'fas fa-lg fa-fw me-2 fa-microchip', label: <><i className='fas fa-lg fa-fw me-2 fa-microchip text-theme fs-4'></i>microchip</>},
  { value:'fas fa-lg fa-fw me-2 fa-microphone', label: <><i className='fas fa-lg fa-fw me-2 fa-microphone text-theme fs-4'></i>microphone</>},
  { value:'fas fa-lg fa-fw me-2 fa-microphone-slash', label: <><i className='fas fa-lg fa-fw me-2 fa-microphone-slash text-theme fs-4'></i>microphone-slash</>},
  { value:'fas fa-lg fa-fw me-2 fa-minus', label: <><i className='fas fa-lg fa-fw me-2 fa-minus text-theme fs-4'></i>minus</>},
  { value:'fas fa-lg fa-fw me-2 fa-minus-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-minus-circle text-theme fs-4'></i>minus-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-minus-square', label: <><i className='fas fa-lg fa-fw me-2 fa-minus-square text-theme fs-4'></i>minus-square</>},
  { value:'fas fa-lg fa-fw me-2 fa-mobile-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-mobile-alt text-theme fs-4'></i>mobile-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-money-bill-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-money-bill-alt text-theme fs-4'></i>money-bill-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-moon', label: <><i className='fas fa-lg fa-fw me-2 fa-moon text-theme fs-4'></i>moon</>},
  { value:'fas fa-lg fa-fw me-2 fa-motorcycle', label: <><i className='fas fa-lg fa-fw me-2 fa-motorcycle text-theme fs-4'></i>motorcycle</>},
  { value:'fas fa-lg fa-fw me-2 fa-mouse-pointer', label: <><i className='fas fa-lg fa-fw me-2 fa-mouse-pointer text-theme fs-4'></i>mouse-pointer</>},
  { value:'fas fa-lg fa-fw me-2 fa-music', label: <><i className='fas fa-lg fa-fw me-2 fa-music text-theme fs-4'></i>music</>},
  { value:'fas fa-lg fa-fw me-2 fa-neuter', label: <><i className='fas fa-lg fa-fw me-2 fa-neuter text-theme fs-4'></i>neuter</>},
  { value:'fas fa-lg fa-fw me-2 fa-newspaper', label: <><i className='fas fa-lg fa-fw me-2 fa-newspaper text-theme fs-4'></i>newspaper</>},
  { value:'fas fa-lg fa-fw me-2 fa-object-group', label: <><i className='fas fa-lg fa-fw me-2 fa-object-group text-theme fs-4'></i>object-group</>},
  { value:'fas fa-lg fa-fw me-2 fa-object-ungroup', label: <><i className='fas fa-lg fa-fw me-2 fa-object-ungroup text-theme fs-4'></i>object-ungroup</>},
  { value:'fas fa-lg fa-fw me-2 fa-outdent', label: <><i className='fas fa-lg fa-fw me-2 fa-outdent text-theme fs-4'></i>outdent</>},
  { value:'fas fa-lg fa-fw me-2 fa-paint-brush', label: <><i className='fas fa-lg fa-fw me-2 fa-paint-brush text-theme fs-4'></i>paint-brush</>},
  { value:'fas fa-lg fa-fw me-2 fa-paper-plane', label: <><i className='fas fa-lg fa-fw me-2 fa-paper-plane text-theme fs-4'></i>paper-plane</>},
  { value:'fas fa-lg fa-fw me-2 fa-paperclip', label: <><i className='fas fa-lg fa-fw me-2 fa-paperclip text-theme fs-4'></i>paperclip</>},
  { value:'fas fa-lg fa-fw me-2 fa-paragraph', label: <><i className='fas fa-lg fa-fw me-2 fa-paragraph text-theme fs-4'></i>paragraph</>},
  { value:'fas fa-lg fa-fw me-2 fa-paste', label: <><i className='fas fa-lg fa-fw me-2 fa-paste text-theme fs-4'></i>paste</>},
  { value:'fas fa-lg fa-fw me-2 fa-pause', label: <><i className='fas fa-lg fa-fw me-2 fa-pause text-theme fs-4'></i>pause</>},
  { value:'fas fa-lg fa-fw me-2 fa-pause-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-pause-circle text-theme fs-4'></i>pause-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-paw', label: <><i className='fas fa-lg fa-fw me-2 fa-paw text-theme fs-4'></i>paw</>},
  { value:'fas fa-lg fa-fw me-2 fa-pen-square', label: <><i className='fas fa-lg fa-fw me-2 fa-pen-square text-theme fs-4'></i>pen-square</>},
  { value:'fas fa-lg fa-fw me-2 fa-pencil-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-pencil-alt text-theme fs-4'></i>pencil-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-percent', label: <><i className='fas fa-lg fa-fw me-2 fa-percent text-theme fs-4'></i>percent</>},
  { value:'fas fa-lg fa-fw me-2 fa-phone', label: <><i className='fas fa-lg fa-fw me-2 fa-phone text-theme fs-4'></i>phone</>},
  { value:'fas fa-lg fa-fw me-2 fa-phone-square', label: <><i className='fas fa-lg fa-fw me-2 fa-phone-square text-theme fs-4'></i>phone-square</>},
  { value:'fas fa-lg fa-fw me-2 fa-phone-volume', label: <><i className='fas fa-lg fa-fw me-2 fa-phone-volume text-theme fs-4'></i>phone-volume</>},
  { value:'fas fa-lg fa-fw me-2 fa-plane', label: <><i className='fas fa-lg fa-fw me-2 fa-plane text-theme fs-4'></i>plane</>},
  { value:'fas fa-lg fa-fw me-2 fa-play', label: <><i className='fas fa-lg fa-fw me-2 fa-play text-theme fs-4'></i>play</>},
  { value:'fas fa-lg fa-fw me-2 fa-play-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-play-circle text-theme fs-4'></i>play-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-plug', label: <><i className='fas fa-lg fa-fw me-2 fa-plug text-theme fs-4'></i>plug</>},
  { value:'fas fa-lg fa-fw me-2 fa-plus', label: <><i className='fas fa-lg fa-fw me-2 fa-plus text-theme fs-4'></i>plus</>},
  { value:'fas fa-lg fa-fw me-2 fa-plus-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-plus-circle text-theme fs-4'></i>plus-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-plus-square', label: <><i className='fas fa-lg fa-fw me-2 fa-plus-square text-theme fs-4'></i>plus-square</>},
  { value:'fas fa-lg fa-fw me-2 fa-podcast', label: <><i className='fas fa-lg fa-fw me-2 fa-podcast text-theme fs-4'></i>podcast</>},
  { value:'fas fa-lg fa-fw me-2 fa-pound-sign', label: <><i className='fas fa-lg fa-fw me-2 fa-pound-sign text-theme fs-4'></i>pound-sign</>},
  { value:'fas fa-lg fa-fw me-2 fa-power-off', label: <><i className='fas fa-lg fa-fw me-2 fa-power-off text-theme fs-4'></i>power-off</>},
  { value:'fas fa-lg fa-fw me-2 fa-print', label: <><i className='fas fa-lg fa-fw me-2 fa-print text-theme fs-4'></i>print</>},
  { value:'fas fa-lg fa-fw me-2 fa-puzzle-piece', label: <><i className='fas fa-lg fa-fw me-2 fa-puzzle-piece text-theme fs-4'></i>puzzle-piece</>},
  { value:'fas fa-lg fa-fw me-2 fa-qrcode', label: <><i className='fas fa-lg fa-fw me-2 fa-qrcode text-theme fs-4'></i>qrcode</>},
  { value:'fas fa-lg fa-fw me-2 fa-question', label: <><i className='fas fa-lg fa-fw me-2 fa-question text-theme fs-4'></i>question</>},
  { value:'fas fa-lg fa-fw me-2 fa-question-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-question-circle text-theme fs-4'></i>question-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-quote-left', label: <><i className='fas fa-lg fa-fw me-2 fa-quote-left text-theme fs-4'></i>quote-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-quote-right', label: <><i className='fas fa-lg fa-fw me-2 fa-quote-right text-theme fs-4'></i>quote-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-random', label: <><i className='fas fa-lg fa-fw me-2 fa-random text-theme fs-4'></i>random</>},
  { value:'fas fa-lg fa-fw me-2 fa-recycle', label: <><i className='fas fa-lg fa-fw me-2 fa-recycle text-theme fs-4'></i>recycle</>},
  { value:'fas fa-lg fa-fw me-2 fa-redo', label: <><i className='fas fa-lg fa-fw me-2 fa-redo text-theme fs-4'></i>redo</>},
  { value:'fas fa-lg fa-fw me-2 fa-redo-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-redo-alt text-theme fs-4'></i>redo-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-registered', label: <><i className='fas fa-lg fa-fw me-2 fa-registered text-theme fs-4'></i>registered</>},
  { value:'fas fa-lg fa-fw me-2 fa-reply', label: <><i className='fas fa-lg fa-fw me-2 fa-reply text-theme fs-4'></i>reply</>},
  { value:'fas fa-lg fa-fw me-2 fa-reply-all', label: <><i className='fas fa-lg fa-fw me-2 fa-reply-all text-theme fs-4'></i>reply-all</>},
  { value:'fas fa-lg fa-fw me-2 fa-retweet', label: <><i className='fas fa-lg fa-fw me-2 fa-retweet text-theme fs-4'></i>retweet</>},
  { value:'fas fa-lg fa-fw me-2 fa-road', label: <><i className='fas fa-lg fa-fw me-2 fa-road text-theme fs-4'></i>road</>},
  { value:'fas fa-lg fa-fw me-2 fa-rocket', label: <><i className='fas fa-lg fa-fw me-2 fa-rocket text-theme fs-4'></i>rocket</>},
  { value:'fas fa-lg fa-fw me-2 fa-rss', label: <><i className='fas fa-lg fa-fw me-2 fa-rss text-theme fs-4'></i>rss</>},
  { value:'fas fa-lg fa-fw me-2 fa-rss-square', label: <><i className='fas fa-lg fa-fw me-2 fa-rss-square text-theme fs-4'></i>rss-square</>},
  { value:'fas fa-lg fa-fw me-2 fa-ruble-sign', label: <><i className='fas fa-lg fa-fw me-2 fa-ruble-sign text-theme fs-4'></i>ruble-sign</>},
  { value:'fas fa-lg fa-fw me-2 fa-rupee-sign', label: <><i className='fas fa-lg fa-fw me-2 fa-rupee-sign text-theme fs-4'></i>rupee-sign</>},
  { value:'fas fa-lg fa-fw me-2 fa-save', label: <><i className='fas fa-lg fa-fw me-2 fa-save text-theme fs-4'></i>save</>},
  { value:'fas fa-lg fa-fw me-2 fa-search', label: <><i className='fas fa-lg fa-fw me-2 fa-search text-theme fs-4'></i>search</>},
  { value:'fas fa-lg fa-fw me-2 fa-search-minus', label: <><i className='fas fa-lg fa-fw me-2 fa-search-minus text-theme fs-4'></i>search-minus</>},
  { value:'fas fa-lg fa-fw me-2 fa-search-plus', label: <><i className='fas fa-lg fa-fw me-2 fa-search-plus text-theme fs-4'></i>search-plus</>},
  { value:'fas fa-lg fa-fw me-2 fa-server', label: <><i className='fas fa-lg fa-fw me-2 fa-server text-theme fs-4'></i>server</>},
  { value:'fas fa-lg fa-fw me-2 fa-share', label: <><i className='fas fa-lg fa-fw me-2 fa-share text-theme fs-4'></i>share</>},
  { value:'fas fa-lg fa-fw me-2 fa-share-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-share-alt text-theme fs-4'></i>share-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-share-alt-square', label: <><i className='fas fa-lg fa-fw me-2 fa-share-alt-square text-theme fs-4'></i>share-alt-square</>},
  { value:'fas fa-lg fa-fw me-2 fa-share-square', label: <><i className='fas fa-lg fa-fw me-2 fa-share-square text-theme fs-4'></i>share-square</>},
  { value:'fas fa-lg fa-fw me-2 fa-shekel-sign', label: <><i className='fas fa-lg fa-fw me-2 fa-shekel-sign text-theme fs-4'></i>shekel-sign</>},
  { value:'fas fa-lg fa-fw me-2 fa-shield-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-shield-alt text-theme fs-4'></i>shield-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-ship', label: <><i className='fas fa-lg fa-fw me-2 fa-ship text-theme fs-4'></i>ship</>},
  { value:'fas fa-lg fa-fw me-2 fa-shopping-bag', label: <><i className='fas fa-lg fa-fw me-2 fa-shopping-bag text-theme fs-4'></i>shopping-bag</>},
  { value:'fas fa-lg fa-fw me-2 fa-shopping-basket', label: <><i className='fas fa-lg fa-fw me-2 fa-shopping-basket text-theme fs-4'></i>shopping-basket</>},
  { value:'fas fa-lg fa-fw me-2 fa-shopping-cart', label: <><i className='fas fa-lg fa-fw me-2 fa-shopping-cart text-theme fs-4'></i>shopping-cart</>},
  { value:'fas fa-lg fa-fw me-2 fa-shower', label: <><i className='fas fa-lg fa-fw me-2 fa-shower text-theme fs-4'></i>shower</>},
  { value:'fas fa-lg fa-fw me-2 fa-sign-in-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-sign-in-alt text-theme fs-4'></i>sign-in-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-sign-language', label: <><i className='fas fa-lg fa-fw me-2 fa-sign-language text-theme fs-4'></i>sign-language</>},
  { value:'fas fa-lg fa-fw me-2 fa-sign-out-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-sign-out-alt text-theme fs-4'></i>sign-out-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-signal', label: <><i className='fas fa-lg fa-fw me-2 fa-signal text-theme fs-4'></i>signal</>},
  { value:'fas fa-lg fa-fw me-2 fa-sitemap', label: <><i className='fas fa-lg fa-fw me-2 fa-sitemap text-theme fs-4'></i>sitemap</>},
  { value:'fas fa-lg fa-fw me-2 fa-sliders-h', label: <><i className='fas fa-lg fa-fw me-2 fa-sliders-h text-theme fs-4'></i>sliders-h</>},
  { value:'fas fa-lg fa-fw me-2 fa-smile', label: <><i className='fas fa-lg fa-fw me-2 fa-smile text-theme fs-4'></i>smile</>},
  { value:'fas fa-lg fa-fw me-2 fa-snowflake', label: <><i className='fas fa-lg fa-fw me-2 fa-snowflake text-theme fs-4'></i>snowflake</>},
  { value:'fas fa-lg fa-fw me-2 fa-sort', label: <><i className='fas fa-lg fa-fw me-2 fa-sort text-theme fs-4'></i>sort</>},
  { value:'fas fa-lg fa-fw me-2 fa-sort-alpha-down', label: <><i className='fas fa-lg fa-fw me-2 fa-sort-alpha-down text-theme fs-4'></i>sort-alpha-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-sort-alpha-up', label: <><i className='fas fa-lg fa-fw me-2 fa-sort-alpha-up text-theme fs-4'></i>sort-alpha-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-sort-amount-down', label: <><i className='fas fa-lg fa-fw me-2 fa-sort-amount-down text-theme fs-4'></i>sort-amount-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-sort-amount-up', label: <><i className='fas fa-lg fa-fw me-2 fa-sort-amount-up text-theme fs-4'></i>sort-amount-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-sort-down', label: <><i className='fas fa-lg fa-fw me-2 fa-sort-down text-theme fs-4'></i>sort-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-sort-numeric-down', label: <><i className='fas fa-lg fa-fw me-2 fa-sort-numeric-down text-theme fs-4'></i>sort-numeric-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-sort-numeric-up', label: <><i className='fas fa-lg fa-fw me-2 fa-sort-numeric-up text-theme fs-4'></i>sort-numeric-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-sort-up', label: <><i className='fas fa-lg fa-fw me-2 fa-sort-up text-theme fs-4'></i>sort-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-space-shuttle', label: <><i className='fas fa-lg fa-fw me-2 fa-space-shuttle text-theme fs-4'></i>space-shuttle</>},
  { value:'fas fa-lg fa-fw me-2 fa-spinner', label: <><i className='fas fa-lg fa-fw me-2 fa-spinner text-theme fs-4'></i>spinner</>},
  { value:'fas fa-lg fa-fw me-2 fa-square', label: <><i className='fas fa-lg fa-fw me-2 fa-square text-theme fs-4'></i>square</>},
  { value:'fas fa-lg fa-fw me-2 fa-star', label: <><i className='fas fa-lg fa-fw me-2 fa-star text-theme fs-4'></i>star</>},
  { value:'fas fa-lg fa-fw me-2 fa-star-half', label: <><i className='fas fa-lg fa-fw me-2 fa-star-half text-theme fs-4'></i>star-half</>},
  { value:'fas fa-lg fa-fw me-2 fa-step-backward', label: <><i className='fas fa-lg fa-fw me-2 fa-step-backward text-theme fs-4'></i>step-backward</>},
  { value:'fas fa-lg fa-fw me-2 fa-step-forward', label: <><i className='fas fa-lg fa-fw me-2 fa-step-forward text-theme fs-4'></i>step-forward</>},
  { value:'fas fa-lg fa-fw me-2 fa-stethoscope', label: <><i className='fas fa-lg fa-fw me-2 fa-stethoscope text-theme fs-4'></i>stethoscope</>},
  { value:'fas fa-lg fa-fw me-2 fa-sticky-note', label: <><i className='fas fa-lg fa-fw me-2 fa-sticky-note text-theme fs-4'></i>sticky-note</>},
  { value:'fas fa-lg fa-fw me-2 fa-stop', label: <><i className='fas fa-lg fa-fw me-2 fa-stop text-theme fs-4'></i>stop</>},
  { value:'fas fa-lg fa-fw me-2 fa-stop-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-stop-circle text-theme fs-4'></i>stop-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-stopwatch', label: <><i className='fas fa-lg fa-fw me-2 fa-stopwatch text-theme fs-4'></i>stopwatch</>},
  { value:'fas fa-lg fa-fw me-2 fa-street-view', label: <><i className='fas fa-lg fa-fw me-2 fa-street-view text-theme fs-4'></i>street-view</>},
  { value:'fas fa-lg fa-fw me-2 fa-strikethrough', label: <><i className='fas fa-lg fa-fw me-2 fa-strikethrough text-theme fs-4'></i>strikethrough</>},
  { value:'fas fa-lg fa-fw me-2 fa-subscript', label: <><i className='fas fa-lg fa-fw me-2 fa-subscript text-theme fs-4'></i>subscript</>},
  { value:'fas fa-lg fa-fw me-2 fa-subway', label: <><i className='fas fa-lg fa-fw me-2 fa-subway text-theme fs-4'></i>subway</>},
  { value:'fas fa-lg fa-fw me-2 fa-suitcase', label: <><i className='fas fa-lg fa-fw me-2 fa-suitcase text-theme fs-4'></i>suitcase</>},
  { value:'fas fa-lg fa-fw me-2 fa-sun', label: <><i className='fas fa-lg fa-fw me-2 fa-sun text-theme fs-4'></i>sun</>},
  { value:'fas fa-lg fa-fw me-2 fa-superscript', label: <><i className='fas fa-lg fa-fw me-2 fa-superscript text-theme fs-4'></i>superscript</>},
  { value:'fas fa-lg fa-fw me-2 fa-sync', label: <><i className='fas fa-lg fa-fw me-2 fa-sync text-theme fs-4'></i>sync</>},
  { value:'fas fa-lg fa-fw me-2 fa-sync-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-sync-alt text-theme fs-4'></i>sync-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-table', label: <><i className='fas fa-lg fa-fw me-2 fa-table text-theme fs-4'></i>table</>},
  { value:'fas fa-lg fa-fw me-2 fa-tablet-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-tablet-alt text-theme fs-4'></i>tablet-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-tag', label: <><i className='fas fa-lg fa-fw me-2 fa-tag text-theme fs-4'></i>tag</>},
  { value:'fas fa-lg fa-fw me-2 fa-tags', label: <><i className='fas fa-lg fa-fw me-2 fa-tags text-theme fs-4'></i>tags</>},
  { value:'fas fa-lg fa-fw me-2 fa-tasks', label: <><i className='fas fa-lg fa-fw me-2 fa-tasks text-theme fs-4'></i>tasks</>},
  { value:'fas fa-lg fa-fw me-2 fa-taxi', label: <><i className='fas fa-lg fa-fw me-2 fa-taxi text-theme fs-4'></i>taxi</>},
  { value:'fas fa-lg fa-fw me-2 fa-terminal', label: <><i className='fas fa-lg fa-fw me-2 fa-terminal text-theme fs-4'></i>terminal</>},
  { value:'fas fa-lg fa-fw me-2 fa-text-height', label: <><i className='fas fa-lg fa-fw me-2 fa-text-height text-theme fs-4'></i>text-height</>},
  { value:'fas fa-lg fa-fw me-2 fa-text-width', label: <><i className='fas fa-lg fa-fw me-2 fa-text-width text-theme fs-4'></i>text-width</>},
  { value:'fas fa-lg fa-fw me-2 fa-th', label: <><i className='fas fa-lg fa-fw me-2 fa-th text-theme fs-4'></i>th</>},
  { value:'fas fa-lg fa-fw me-2 fa-th-large', label: <><i className='fas fa-lg fa-fw me-2 fa-th-large text-theme fs-4'></i>th-large</>},
  { value:'fas fa-lg fa-fw me-2 fa-th-list', label: <><i className='fas fa-lg fa-fw me-2 fa-th-list text-theme fs-4'></i>th-list</>},
  { value:'fas fa-lg fa-fw me-2 fa-thermometer-empty', label: <><i className='fas fa-lg fa-fw me-2 fa-thermometer-empty text-theme fs-4'></i>thermometer-empty</>},
  { value:'fas fa-lg fa-fw me-2 fa-thermometer-full', label: <><i className='fas fa-lg fa-fw me-2 fa-thermometer-full text-theme fs-4'></i>thermometer-full</>},
  { value:'fas fa-lg fa-fw me-2 fa-thermometer-half', label: <><i className='fas fa-lg fa-fw me-2 fa-thermometer-half text-theme fs-4'></i>thermometer-half</>},
  { value:'fas fa-lg fa-fw me-2 fa-thermometer-quarter', label: <><i className='fas fa-lg fa-fw me-2 fa-thermometer-quarter text-theme fs-4'></i>thermometer-quarter</>},
  { value:'fas fa-lg fa-fw me-2 fa-thermometer-three-quarters', label: <><i className='fas fa-lg fa-fw me-2 fa-thermometer-three-quarters text-theme fs-4'></i>thermometer-three-quarters</>},
  { value:'fas fa-lg fa-fw me-2 fa-thumbs-down', label: <><i className='fas fa-lg fa-fw me-2 fa-thumbs-down text-theme fs-4'></i>thumbs-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-thumbs-up', label: <><i className='fas fa-lg fa-fw me-2 fa-thumbs-up text-theme fs-4'></i>thumbs-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-thumbtack', label: <><i className='fas fa-lg fa-fw me-2 fa-thumbtack text-theme fs-4'></i>thumbtack</>},
  { value:'fas fa-lg fa-fw me-2 fa-ticket-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-ticket-alt text-theme fs-4'></i>ticket-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-times', label: <><i className='fas fa-lg fa-fw me-2 fa-times text-theme fs-4'></i>times</>},
  { value:'fas fa-lg fa-fw me-2 fa-times-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-times-circle text-theme fs-4'></i>times-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-tint', label: <><i className='fas fa-lg fa-fw me-2 fa-tint text-theme fs-4'></i>tint</>},
  { value:'fas fa-lg fa-fw me-2 fa-toggle-off', label: <><i className='fas fa-lg fa-fw me-2 fa-toggle-off text-theme fs-4'></i>toggle-off</>},
  { value:'fas fa-lg fa-fw me-2 fa-toggle-on', label: <><i className='fas fa-lg fa-fw me-2 fa-toggle-on text-theme fs-4'></i>toggle-on</>},
  { value:'fas fa-lg fa-fw me-2 fa-trademark', label: <><i className='fas fa-lg fa-fw me-2 fa-trademark text-theme fs-4'></i>trademark</>},
  { value:'fas fa-lg fa-fw me-2 fa-train', label: <><i className='fas fa-lg fa-fw me-2 fa-train text-theme fs-4'></i>train</>},
  { value:'fas fa-lg fa-fw me-2 fa-transgender', label: <><i className='fas fa-lg fa-fw me-2 fa-transgender text-theme fs-4'></i>transgender</>},
  { value:'fas fa-lg fa-fw me-2 fa-transgender-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-transgender-alt text-theme fs-4'></i>transgender-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-trash', label: <><i className='fas fa-lg fa-fw me-2 fa-trash text-theme fs-4'></i>trash</>},
  { value:'fas fa-lg fa-fw me-2 fa-trash-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-trash-alt text-theme fs-4'></i>trash-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-tree', label: <><i className='fas fa-lg fa-fw me-2 fa-tree text-theme fs-4'></i>tree</>},
  { value:'fas fa-lg fa-fw me-2 fa-trophy', label: <><i className='fas fa-lg fa-fw me-2 fa-trophy text-theme fs-4'></i>trophy</>},
  { value:'fas fa-lg fa-fw me-2 fa-truck', label: <><i className='fas fa-lg fa-fw me-2 fa-truck text-theme fs-4'></i>truck</>},
  { value:'fas fa-lg fa-fw me-2 fa-tty', label: <><i className='fas fa-lg fa-fw me-2 fa-tty text-theme fs-4'></i>tty</>},
  { value:'fas fa-lg fa-fw me-2 fa-tv', label: <><i className='fas fa-lg fa-fw me-2 fa-tv text-theme fs-4'></i>tv</>},
  { value:'fas fa-lg fa-fw me-2 fa-umbrella', label: <><i className='fas fa-lg fa-fw me-2 fa-umbrella text-theme fs-4'></i>umbrella</>},
  { value:'fas fa-lg fa-fw me-2 fa-underline', label: <><i className='fas fa-lg fa-fw me-2 fa-underline text-theme fs-4'></i>underline</>},
  { value:'fas fa-lg fa-fw me-2 fa-undo', label: <><i className='fas fa-lg fa-fw me-2 fa-undo text-theme fs-4'></i>undo</>},
  { value:'fas fa-lg fa-fw me-2 fa-undo-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-undo-alt text-theme fs-4'></i>undo-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-universal-access', label: <><i className='fas fa-lg fa-fw me-2 fa-universal-access text-theme fs-4'></i>universal-access</>},
  { value:'fas fa-lg fa-fw me-2 fa-university', label: <><i className='fas fa-lg fa-fw me-2 fa-university text-theme fs-4'></i>university</>},
  { value:'fas fa-lg fa-fw me-2 fa-unlink', label: <><i className='fas fa-lg fa-fw me-2 fa-unlink text-theme fs-4'></i>unlink</>},
  { value:'fas fa-lg fa-fw me-2 fa-unlock', label: <><i className='fas fa-lg fa-fw me-2 fa-unlock text-theme fs-4'></i>unlock</>},
  { value:'fas fa-lg fa-fw me-2 fa-unlock-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-unlock-alt text-theme fs-4'></i>unlock-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-upload', label: <><i className='fas fa-lg fa-fw me-2 fa-upload text-theme fs-4'></i>upload</>},
  { value:'fas fa-lg fa-fw me-2 fa-user', label: <><i className='fas fa-lg fa-fw me-2 fa-user text-theme fs-4'></i>user</>},
  { value:'fas fa-lg fa-fw me-2 fa-user-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-user-circle text-theme fs-4'></i>user-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-user-md', label: <><i className='fas fa-lg fa-fw me-2 fa-user-md text-theme fs-4'></i>user-md</>},
  { value:'fas fa-lg fa-fw me-2 fa-user-plus', label: <><i className='fas fa-lg fa-fw me-2 fa-user-plus text-theme fs-4'></i>user-plus</>},
  { value:'fas fa-lg fa-fw me-2 fa-user-secret', label: <><i className='fas fa-lg fa-fw me-2 fa-user-secret text-theme fs-4'></i>user-secret</>},
  { value:'fas fa-lg fa-fw me-2 fa-user-times', label: <><i className='fas fa-lg fa-fw me-2 fa-user-times text-theme fs-4'></i>user-times</>},
  { value:'fas fa-lg fa-fw me-2 fa-users', label: <><i className='fas fa-lg fa-fw me-2 fa-users text-theme fs-4'></i>users</>},
  { value:'fas fa-lg fa-fw me-2 fa-utensil-spoon', label: <><i className='fas fa-lg fa-fw me-2 fa-utensil-spoon text-theme fs-4'></i>utensil-spoon</>},
  { value:'fas fa-lg fa-fw me-2 fa-utensils', label: <><i className='fas fa-lg fa-fw me-2 fa-utensils text-theme fs-4'></i>utensils</>},
  { value:'fas fa-lg fa-fw me-2 fa-venus', label: <><i className='fas fa-lg fa-fw me-2 fa-venus text-theme fs-4'></i>venus</>},
  { value:'fas fa-lg fa-fw me-2 fa-venus-double', label: <><i className='fas fa-lg fa-fw me-2 fa-venus-double text-theme fs-4'></i>venus-double</>},
  { value:'fas fa-lg fa-fw me-2 fa-venus-mars', label: <><i className='fas fa-lg fa-fw me-2 fa-venus-mars text-theme fs-4'></i>venus-mars</>},
  { value:'fas fa-lg fa-fw me-2 fa-video', label: <><i className='fas fa-lg fa-fw me-2 fa-video text-theme fs-4'></i>video</>},
  { value:'fas fa-lg fa-fw me-2 fa-volume-down', label: <><i className='fas fa-lg fa-fw me-2 fa-volume-down text-theme fs-4'></i>volume-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-volume-off', label: <><i className='fas fa-lg fa-fw me-2 fa-volume-off text-theme fs-4'></i>volume-off</>},
  { value:'fas fa-lg fa-fw me-2 fa-volume-up', label: <><i className='fas fa-lg fa-fw me-2 fa-volume-up text-theme fs-4'></i>volume-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-wheelchair', label: <><i className='fas fa-lg fa-fw me-2 fa-wheelchair text-theme fs-4'></i>wheelchair</>},
  { value:'fas fa-lg fa-fw me-2 fa-wifi', label: <><i className='fas fa-lg fa-fw me-2 fa-wifi text-theme fs-4'></i>wifi</>},
  { value:'fas fa-lg fa-fw me-2 fa-window-close', label: <><i className='fas fa-lg fa-fw me-2 fa-window-close text-theme fs-4'></i>window-close</>},
  { value:'fas fa-lg fa-fw me-2 fa-window-maximize', label: <><i className='fas fa-lg fa-fw me-2 fa-window-maximize text-theme fs-4'></i>window-maximize</>},
  { value:'fas fa-lg fa-fw me-2 fa-window-minimize', label: <><i className='fas fa-lg fa-fw me-2 fa-window-minimize text-theme fs-4'></i>window-minimize</>},
  { value:'fas fa-lg fa-fw me-2 fa-window-restore', label: <><i className='fas fa-lg fa-fw me-2 fa-window-restore text-theme fs-4'></i>window-restore</>},
  { value:'fas fa-lg fa-fw me-2 fa-won-sign', label: <><i className='fas fa-lg fa-fw me-2 fa-won-sign text-theme fs-4'></i>won-sign</>},
  { value:'fas fa-lg fa-fw me-2 fa-wrench', label: <><i className='fas fa-lg fa-fw me-2 fa-wrench text-theme fs-4'></i>wrench</>},
  { value:'fas fa-lg fa-fw me-2 fa-yen-sign', label: <><i className='fas fa-lg fa-fw me-2 fa-yen-sign text-theme fs-4'></i>yen-sign</>},
  { value:'far fa-lg fa-fw me-2 fa-address-book', label: <><i className='far fa-lg fa-fw me-2 fa-address-book text-theme fs-4'></i>address-book</>},
  { value:'far fa-lg fa-fw me-2 fa-address-card', label: <><i className='far fa-lg fa-fw me-2 fa-address-card text-theme fs-4'></i>address-card</>},
  { value:'far fa-lg fa-fw me-2 fa-arrow-alt-circle-down', label: <><i className='far fa-lg fa-fw me-2 fa-arrow-alt-circle-down text-theme fs-4'></i>arrow-alt-circle-down</>},
  { value:'far fa-lg fa-fw me-2 fa-arrow-alt-circle-left', label: <><i className='far fa-lg fa-fw me-2 fa-arrow-alt-circle-left text-theme fs-4'></i>arrow-alt-circle-left</>},
  { value:'far fa-lg fa-fw me-2 fa-arrow-alt-circle-right', label: <><i className='far fa-lg fa-fw me-2 fa-arrow-alt-circle-right text-theme fs-4'></i>arrow-alt-circle-right</>},
  { value:'far fa-lg fa-fw me-2 fa-arrow-alt-circle-up', label: <><i className='far fa-lg fa-fw me-2 fa-arrow-alt-circle-up text-theme fs-4'></i>arrow-alt-circle-up</>},
  { value:'far fa-lg fa-fw me-2 fa-bell', label: <><i className='far fa-lg fa-fw me-2 fa-bell text-theme fs-4'></i>bell</>},
  { value:'far fa-lg fa-fw me-2 fa-bell-slash', label: <><i className='far fa-lg fa-fw me-2 fa-bell-slash text-theme fs-4'></i>bell-slash</>},
  { value:'far fa-lg fa-fw me-2 fa-bookmark', label: <><i className='far fa-lg fa-fw me-2 fa-bookmark text-theme fs-4'></i>bookmark</>},
  { value:'far fa-lg fa-fw me-2 fa-building', label: <><i className='far fa-lg fa-fw me-2 fa-building text-theme fs-4'></i>building</>},
  { value:'far fa-lg fa-fw me-2 fa-calendar', label: <><i className='far fa-lg fa-fw me-2 fa-calendar text-theme fs-4'></i>calendar</>},
  { value:'far fa-lg fa-fw me-2 fa-calendar-alt', label: <><i className='far fa-lg fa-fw me-2 fa-calendar-alt text-theme fs-4'></i>calendar-alt</>},
  { value:'far fa-lg fa-fw me-2 fa-calendar-check', label: <><i className='far fa-lg fa-fw me-2 fa-calendar-check text-theme fs-4'></i>calendar-check</>},
  { value:'far fa-lg fa-fw me-2 fa-calendar-minus', label: <><i className='far fa-lg fa-fw me-2 fa-calendar-minus text-theme fs-4'></i>calendar-minus</>},
  { value:'far fa-lg fa-fw me-2 fa-calendar-plus', label: <><i className='far fa-lg fa-fw me-2 fa-calendar-plus text-theme fs-4'></i>calendar-plus</>},
  { value:'far fa-lg fa-fw me-2 fa-calendar-times', label: <><i className='far fa-lg fa-fw me-2 fa-calendar-times text-theme fs-4'></i>calendar-times</>},
  { value:'far fa-lg fa-fw me-2 fa-caret-square-down', label: <><i className='far fa-lg fa-fw me-2 fa-caret-square-down text-theme fs-4'></i>caret-square-down</>},
  { value:'far fa-lg fa-fw me-2 fa-caret-square-left', label: <><i className='far fa-lg fa-fw me-2 fa-caret-square-left text-theme fs-4'></i>caret-square-left</>},
  { value:'far fa-lg fa-fw me-2 fa-caret-square-right', label: <><i className='far fa-lg fa-fw me-2 fa-caret-square-right text-theme fs-4'></i>caret-square-right</>},
  { value:'far fa-lg fa-fw me-2 fa-caret-square-up', label: <><i className='far fa-lg fa-fw me-2 fa-caret-square-up text-theme fs-4'></i>caret-square-up</>},
  { value:'far fa-lg fa-fw me-2 fa-chart-bar', label: <><i className='far fa-lg fa-fw me-2 fa-chart-bar text-theme fs-4'></i>chart-bar</>},
  { value:'far fa-lg fa-fw me-2 fa-check-circle', label: <><i className='far fa-lg fa-fw me-2 fa-check-circle text-theme fs-4'></i>check-circle</>},
  { value:'far fa-lg fa-fw me-2 fa-check-square', label: <><i className='far fa-lg fa-fw me-2 fa-check-square text-theme fs-4'></i>check-square</>},
  { value:'far fa-lg fa-fw me-2 fa-circle', label: <><i className='far fa-lg fa-fw me-2 fa-circle text-theme fs-4'></i>circle</>},
  { value:'far fa-lg fa-fw me-2 fa-clipboard', label: <><i className='far fa-lg fa-fw me-2 fa-clipboard text-theme fs-4'></i>clipboard</>},
  { value:'far fa-lg fa-fw me-2 fa-clock', label: <><i className='far fa-lg fa-fw me-2 fa-clock text-theme fs-4'></i>clock</>},
  { value:'far fa-lg fa-fw me-2 fa-clone', label: <><i className='far fa-lg fa-fw me-2 fa-clone text-theme fs-4'></i>clone</>},
  { value:'far fa-lg fa-fw me-2 fa-closed-captioning', label: <><i className='far fa-lg fa-fw me-2 fa-closed-captioning text-theme fs-4'></i>closed-captioning</>},
  { value:'far fa-lg fa-fw me-2 fa-comment', label: <><i className='far fa-lg fa-fw me-2 fa-comment text-theme fs-4'></i>comment</>},
  { value:'far fa-lg fa-fw me-2 fa-comment-alt', label: <><i className='far fa-lg fa-fw me-2 fa-comment-alt text-theme fs-4'></i>comment-alt</>},
  { value:'far fa-lg fa-fw me-2 fa-comments', label: <><i className='far fa-lg fa-fw me-2 fa-comments text-theme fs-4'></i>comments</>},
  { value:'far fa-lg fa-fw me-2 fa-compass', label: <><i className='far fa-lg fa-fw me-2 fa-compass text-theme fs-4'></i>compass</>},
  { value:'far fa-lg fa-fw me-2 fa-copy', label: <><i className='far fa-lg fa-fw me-2 fa-copy text-theme fs-4'></i>copy</>},
  { value:'far fa-lg fa-fw me-2 fa-copyright', label: <><i className='far fa-lg fa-fw me-2 fa-copyright text-theme fs-4'></i>copyright</>},
  { value:'far fa-lg fa-fw me-2 fa-credit-card', label: <><i className='far fa-lg fa-fw me-2 fa-credit-card text-theme fs-4'></i>credit-card</>},
  { value:'far fa-lg fa-fw me-2 fa-dot-circle', label: <><i className='far fa-lg fa-fw me-2 fa-dot-circle text-theme fs-4'></i>dot-circle</>},
  { value:'far fa-lg fa-fw me-2 fa-edit', label: <><i className='far fa-lg fa-fw me-2 fa-edit text-theme fs-4'></i>edit</>},
  { value:'far fa-lg fa-fw me-2 fa-envelope', label: <><i className='far fa-lg fa-fw me-2 fa-envelope text-theme fs-4'></i>envelope</>},
  { value:'far fa-lg fa-fw me-2 fa-envelope-open', label: <><i className='far fa-lg fa-fw me-2 fa-envelope-open text-theme fs-4'></i>envelope-open</>},
  { value:'far fa-lg fa-fw me-2 fa-eye-slash', label: <><i className='far fa-lg fa-fw me-2 fa-eye-slash text-theme fs-4'></i>eye-slash</>},
  { value:'far fa-lg fa-fw me-2 fa-file', label: <><i className='far fa-lg fa-fw me-2 fa-file text-theme fs-4'></i>file</>},
  { value:'far fa-lg fa-fw me-2 fa-file-alt', label: <><i className='far fa-lg fa-fw me-2 fa-file-alt text-theme fs-4'></i>file-alt</>},
  { value:'far fa-lg fa-fw me-2 fa-file-archive', label: <><i className='far fa-lg fa-fw me-2 fa-file-archive text-theme fs-4'></i>file-archive</>},
  { value:'far fa-lg fa-fw me-2 fa-file-audio', label: <><i className='far fa-lg fa-fw me-2 fa-file-audio text-theme fs-4'></i>file-audio</>},
  { value:'far fa-lg fa-fw me-2 fa-file-code', label: <><i className='far fa-lg fa-fw me-2 fa-file-code text-theme fs-4'></i>file-code</>},
  { value:'far fa-lg fa-fw me-2 fa-file-excel', label: <><i className='far fa-lg fa-fw me-2 fa-file-excel text-theme fs-4'></i>file-excel</>},
  { value:'far fa-lg fa-fw me-2 fa-file-image', label: <><i className='far fa-lg fa-fw me-2 fa-file-image text-theme fs-4'></i>file-image</>},
  { value:'far fa-lg fa-fw me-2 fa-file-pdf', label: <><i className='far fa-lg fa-fw me-2 fa-file-pdf text-theme fs-4'></i>file-pdf</>},
  { value:'far fa-lg fa-fw me-2 fa-file-powerpoint', label: <><i className='far fa-lg fa-fw me-2 fa-file-powerpoint text-theme fs-4'></i>file-powerpoint</>},
  { value:'far fa-lg fa-fw me-2 fa-file-video', label: <><i className='far fa-lg fa-fw me-2 fa-file-video text-theme fs-4'></i>file-video</>},
  { value:'far fa-lg fa-fw me-2 fa-file-word', label: <><i className='far fa-lg fa-fw me-2 fa-file-word text-theme fs-4'></i>file-word</>},
  { value:'far fa-lg fa-fw me-2 fa-flag', label: <><i className='far fa-lg fa-fw me-2 fa-flag text-theme fs-4'></i>flag</>},
  { value:'far fa-lg fa-fw me-2 fa-folder', label: <><i className='far fa-lg fa-fw me-2 fa-folder text-theme fs-4'></i>folder</>},
  { value:'far fa-lg fa-fw me-2 fa-folder-open', label: <><i className='far fa-lg fa-fw me-2 fa-folder-open text-theme fs-4'></i>folder-open</>},
  { value:'far fa-lg fa-fw me-2 fa-frown', label: <><i className='far fa-lg fa-fw me-2 fa-frown text-theme fs-4'></i>frown</>},
  { value:'far fa-lg fa-fw me-2 fa-futbol', label: <><i className='far fa-lg fa-fw me-2 fa-futbol text-theme fs-4'></i>futbol</>},
  { value:'far fa-lg fa-fw me-2 fa-gem', label: <><i className='far fa-lg fa-fw me-2 fa-gem text-theme fs-4'></i>gem</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-lizard', label: <><i className='far fa-lg fa-fw me-2 fa-hand-lizard text-theme fs-4'></i>hand-lizard</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-paper', label: <><i className='far fa-lg fa-fw me-2 fa-hand-paper text-theme fs-4'></i>hand-paper</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-peace', label: <><i className='far fa-lg fa-fw me-2 fa-hand-peace text-theme fs-4'></i>hand-peace</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-point-down', label: <><i className='far fa-lg fa-fw me-2 fa-hand-point-down text-theme fs-4'></i>hand-point-down</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-point-left', label: <><i className='far fa-lg fa-fw me-2 fa-hand-point-left text-theme fs-4'></i>hand-point-left</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-point-right', label: <><i className='far fa-lg fa-fw me-2 fa-hand-point-right text-theme fs-4'></i>hand-point-right</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-point-up', label: <><i className='far fa-lg fa-fw me-2 fa-hand-point-up text-theme fs-4'></i>hand-point-up</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-pointer', label: <><i className='far fa-lg fa-fw me-2 fa-hand-pointer text-theme fs-4'></i>hand-pointer</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-rock', label: <><i className='far fa-lg fa-fw me-2 fa-hand-rock text-theme fs-4'></i>hand-rock</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-scissors', label: <><i className='far fa-lg fa-fw me-2 fa-hand-scissors text-theme fs-4'></i>hand-scissors</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-spock', label: <><i className='far fa-lg fa-fw me-2 fa-hand-spock text-theme fs-4'></i>hand-spock</>},
  { value:'far fa-lg fa-fw me-2 fa-handshake', label: <><i className='far fa-lg fa-fw me-2 fa-handshake text-theme fs-4'></i>handshake</>},
  { value:'far fa-lg fa-fw me-2 fa-hdd', label: <><i className='far fa-lg fa-fw me-2 fa-hdd text-theme fs-4'></i>hdd</>},
  { value:'far fa-lg fa-fw me-2 fa-heart', label: <><i className='far fa-lg fa-fw me-2 fa-heart text-theme fs-4'></i>heart</>},
  { value:'far fa-lg fa-fw me-2 fa-hospital', label: <><i className='far fa-lg fa-fw me-2 fa-hospital text-theme fs-4'></i>hospital</>},
  { value:'far fa-lg fa-fw me-2 fa-hourglass', label: <><i className='far fa-lg fa-fw me-2 fa-hourglass text-theme fs-4'></i>hourglass</>},
  { value:'far fa-lg fa-fw me-2 fa-id-badge', label: <><i className='far fa-lg fa-fw me-2 fa-id-badge text-theme fs-4'></i>id-badge</>},
  { value:'far fa-lg fa-fw me-2 fa-id-card', label: <><i className='far fa-lg fa-fw me-2 fa-id-card text-theme fs-4'></i>id-card</>},
  { value:'far fa-lg fa-fw me-2 fa-image', label: <><i className='far fa-lg fa-fw me-2 fa-image text-theme fs-4'></i>image</>},
  { value:'far fa-lg fa-fw me-2 fa-images', label: <><i className='far fa-lg fa-fw me-2 fa-images text-theme fs-4'></i>images</>},
  { value:'far fa-lg fa-fw me-2 fa-keyboard', label: <><i className='far fa-lg fa-fw me-2 fa-keyboard text-theme fs-4'></i>keyboard</>},
  { value:'far fa-lg fa-fw me-2 fa-lemon', label: <><i className='far fa-lg fa-fw me-2 fa-lemon text-theme fs-4'></i>lemon</>},
  { value:'far fa-lg fa-fw me-2 fa-life-ring', label: <><i className='far fa-lg fa-fw me-2 fa-life-ring text-theme fs-4'></i>life-ring</>},
  { value:'far fa-lg fa-fw me-2 fa-lightbulb', label: <><i className='far fa-lg fa-fw me-2 fa-lightbulb text-theme fs-4'></i>lightbulb</>},
  { value:'far fa-lg fa-fw me-2 fa-list-alt', label: <><i className='far fa-lg fa-fw me-2 fa-list-alt text-theme fs-4'></i>list-alt</>},
  { value:'far fa-lg fa-fw me-2 fa-map', label: <><i className='far fa-lg fa-fw me-2 fa-map text-theme fs-4'></i>map</>},
  { value:'far fa-lg fa-fw me-2 fa-meh', label: <><i className='far fa-lg fa-fw me-2 fa-meh text-theme fs-4'></i>meh</>},
  { value:'far fa-lg fa-fw me-2 fa-minus-square', label: <><i className='far fa-lg fa-fw me-2 fa-minus-square text-theme fs-4'></i>minus-square</>},
  { value:'far fa-lg fa-fw me-2 fa-money-bill-alt', label: <><i className='far fa-lg fa-fw me-2 fa-money-bill-alt text-theme fs-4'></i>money-bill-alt</>},
  { value:'far fa-lg fa-fw me-2 fa-moon', label: <><i className='far fa-lg fa-fw me-2 fa-moon text-theme fs-4'></i>moon</>},
  { value:'far fa-lg fa-fw me-2 fa-newspaper', label: <><i className='far fa-lg fa-fw me-2 fa-newspaper text-theme fs-4'></i>newspaper</>},
  { value:'far fa-lg fa-fw me-2 fa-object-group', label: <><i className='far fa-lg fa-fw me-2 fa-object-group text-theme fs-4'></i>object-group</>},
  { value:'far fa-lg fa-fw me-2 fa-object-ungroup', label: <><i className='far fa-lg fa-fw me-2 fa-object-ungroup text-theme fs-4'></i>object-ungroup</>},
  { value:'far fa-lg fa-fw me-2 fa-paper-plane', label: <><i className='far fa-lg fa-fw me-2 fa-paper-plane text-theme fs-4'></i>paper-plane</>},
  { value:'far fa-lg fa-fw me-2 fa-pause-circle', label: <><i className='far fa-lg fa-fw me-2 fa-pause-circle text-theme fs-4'></i>pause-circle</>},
  { value:'far fa-lg fa-fw me-2 fa-play-circle', label: <><i className='far fa-lg fa-fw me-2 fa-play-circle text-theme fs-4'></i>play-circle</>},
  { value:'far fa-lg fa-fw me-2 fa-plus-square', label: <><i className='far fa-lg fa-fw me-2 fa-plus-square text-theme fs-4'></i>plus-square</>},
  { value:'far fa-lg fa-fw me-2 fa-question-circle', label: <><i className='far fa-lg fa-fw me-2 fa-question-circle text-theme fs-4'></i>question-circle</>},
  { value:'far fa-lg fa-fw me-2 fa-registered', label: <><i className='far fa-lg fa-fw me-2 fa-registered text-theme fs-4'></i>registered</>},
  { value:'far fa-lg fa-fw me-2 fa-save', label: <><i className='far fa-lg fa-fw me-2 fa-save text-theme fs-4'></i>save</>},
  { value:'far fa-lg fa-fw me-2 fa-share-square', label: <><i className='far fa-lg fa-fw me-2 fa-share-square text-theme fs-4'></i>share-square</>},
  { value:'far fa-lg fa-fw me-2 fa-smile', label: <><i className='far fa-lg fa-fw me-2 fa-smile text-theme fs-4'></i>smile</>},
  { value:'far fa-lg fa-fw me-2 fa-snowflake', label: <><i className='far fa-lg fa-fw me-2 fa-snowflake text-theme fs-4'></i>snowflake</>},
  { value:'far fa-lg fa-fw me-2 fa-square', label: <><i className='far fa-lg fa-fw me-2 fa-square text-theme fs-4'></i>square</>},
  { value:'far fa-lg fa-fw me-2 fa-star', label: <><i className='far fa-lg fa-fw me-2 fa-star text-theme fs-4'></i>star</>},
  { value:'far fa-lg fa-fw me-2 fa-star-half', label: <><i className='far fa-lg fa-fw me-2 fa-star-half text-theme fs-4'></i>star-half</>},
  { value:'far fa-lg fa-fw me-2 fa-sticky-note', label: <><i className='far fa-lg fa-fw me-2 fa-sticky-note text-theme fs-4'></i>sticky-note</>},
  { value:'far fa-lg fa-fw me-2 fa-stop-circle', label: <><i className='far fa-lg fa-fw me-2 fa-stop-circle text-theme fs-4'></i>stop-circle</>},
  { value:'far fa-lg fa-fw me-2 fa-sun', label: <><i className='far fa-lg fa-fw me-2 fa-sun text-theme fs-4'></i>sun</>},
  { value:'far fa-lg fa-fw me-2 fa-thumbs-down', label: <><i className='far fa-lg fa-fw me-2 fa-thumbs-down text-theme fs-4'></i>thumbs-down</>},
  { value:'far fa-lg fa-fw me-2 fa-thumbs-up', label: <><i className='far fa-lg fa-fw me-2 fa-thumbs-up text-theme fs-4'></i>thumbs-up</>},
  { value:'far fa-lg fa-fw me-2 fa-times-circle', label: <><i className='far fa-lg fa-fw me-2 fa-times-circle text-theme fs-4'></i>times-circle</>},
  { value:'far fa-lg fa-fw me-2 fa-trash-alt', label: <><i className='far fa-lg fa-fw me-2 fa-trash-alt text-theme fs-4'></i>trash-alt</>},
  { value:'far fa-lg fa-fw me-2 fa-user', label: <><i className='far fa-lg fa-fw me-2 fa-user text-theme fs-4'></i>user</>},
  { value:'far fa-lg fa-fw me-2 fa-user-circle', label: <><i className='far fa-lg fa-fw me-2 fa-user-circle text-theme fs-4'></i>user-circle</>},
  { value:'far fa-lg fa-fw me-2 fa-window-close', label: <><i className='far fa-lg fa-fw me-2 fa-window-close text-theme fs-4'></i>window-close</>},
  { value:'far fa-lg fa-fw me-2 fa-window-maximize', label: <><i className='far fa-lg fa-fw me-2 fa-window-maximize text-theme fs-4'></i>window-maximize</>},
  { value:'far fa-lg fa-fw me-2 fa-window-minimize', label: <><i className='far fa-lg fa-fw me-2 fa-window-minimize text-theme fs-4'></i>window-minimize</>},
  { value:'far fa-lg fa-fw me-2 fa-window-restore', label: <><i className='far fa-lg fa-fw me-2 fa-window-restore text-theme fs-4'></i>window-restore</>},
  { value:'fab fa-lg fa-fw me-2 fa-500px', label: <><i className='fab fa-lg fa-fw me-2 fa-500px text-theme fs-4'></i>500px</>},
  { value:'fab fa-lg fa-fw me-2 fa-accessible-icon', label: <><i className='fab fa-lg fa-fw me-2 fa-accessible-icon text-theme fs-4'></i>accessible-icon</>},
  { value:'fab fa-lg fa-fw me-2 fa-accusoft', label: <><i className='fab fa-lg fa-fw me-2 fa-accusoft text-theme fs-4'></i>accusoft</>},
  { value:'fab fa-lg fa-fw me-2 fa-adn', label: <><i className='fab fa-lg fa-fw me-2 fa-adn text-theme fs-4'></i>adn</>},
  { value:'fab fa-lg fa-fw me-2 fa-adversal', label: <><i className='fab fa-lg fa-fw me-2 fa-adversal text-theme fs-4'></i>adversal</>},
  { value:'fab fa-lg fa-fw me-2 fa-affiliatetheme', label: <><i className='fab fa-lg fa-fw me-2 fa-affiliatetheme text-theme fs-4'></i>affiliatetheme</>},
  { value:'fab fa-lg fa-fw me-2 fa-algolia', label: <><i className='fab fa-lg fa-fw me-2 fa-algolia text-theme fs-4'></i>algolia</>},
  { value:'fab fa-lg fa-fw me-2 fa-amazon', label: <><i className='fab fa-lg fa-fw me-2 fa-amazon text-theme fs-4'></i>amazon</>},
  { value:'fab fa-lg fa-fw me-2 fa-amazon-pay', label: <><i className='fab fa-lg fa-fw me-2 fa-amazon-pay text-theme fs-4'></i>amazon-pay</>},
  { value:'fab fa-lg fa-fw me-2 fa-amilia', label: <><i className='fab fa-lg fa-fw me-2 fa-amilia text-theme fs-4'></i>amilia</>},
  { value:'fab fa-lg fa-fw me-2 fa-android', label: <><i className='fab fa-lg fa-fw me-2 fa-android text-theme fs-4'></i>android</>},
  { value:'fab fa-lg fa-fw me-2 fa-angellist', label: <><i className='fab fa-lg fa-fw me-2 fa-angellist text-theme fs-4'></i>angellist</>},
  { value:'fab fa-lg fa-fw me-2 fa-angrycreative', label: <><i className='fab fa-lg fa-fw me-2 fa-angrycreative text-theme fs-4'></i>angrycreative</>},
  { value:'fab fa-lg fa-fw me-2 fa-angular', label: <><i className='fab fa-lg fa-fw me-2 fa-angular text-theme fs-4'></i>angular</>},
  { value:'fab fa-lg fa-fw me-2 fa-app-store', label: <><i className='fab fa-lg fa-fw me-2 fa-app-store text-theme fs-4'></i>app-store</>},
  { value:'fab fa-lg fa-fw me-2 fa-app-store-ios', label: <><i className='fab fa-lg fa-fw me-2 fa-app-store-ios text-theme fs-4'></i>app-store-ios</>},
  { value:'fab fa-lg fa-fw me-2 fa-apper', label: <><i className='fab fa-lg fa-fw me-2 fa-apper text-theme fs-4'></i>apper</>},
  { value:'fab fa-lg fa-fw me-2 fa-apple', label: <><i className='fab fa-lg fa-fw me-2 fa-apple text-theme fs-4'></i>apple</>},
  { value:'fab fa-lg fa-fw me-2 fa-apple-pay', label: <><i className='fab fa-lg fa-fw me-2 fa-apple-pay text-theme fs-4'></i>apple-pay</>},
  { value:'fab fa-lg fa-fw me-2 fa-asymmetrik', label: <><i className='fab fa-lg fa-fw me-2 fa-asymmetrik text-theme fs-4'></i>asymmetrik</>},
  { value:'fab fa-lg fa-fw me-2 fa-audible', label: <><i className='fab fa-lg fa-fw me-2 fa-audible text-theme fs-4'></i>audible</>},
  { value:'fab fa-lg fa-fw me-2 fa-autoprefixer', label: <><i className='fab fa-lg fa-fw me-2 fa-autoprefixer text-theme fs-4'></i>autoprefixer</>},
  { value:'fab fa-lg fa-fw me-2 fa-avianex', label: <><i className='fab fa-lg fa-fw me-2 fa-avianex text-theme fs-4'></i>avianex</>},
  { value:'fab fa-lg fa-fw me-2 fa-aviato', label: <><i className='fab fa-lg fa-fw me-2 fa-aviato text-theme fs-4'></i>aviato</>},
  { value:'fab fa-lg fa-fw me-2 fa-aws', label: <><i className='fab fa-lg fa-fw me-2 fa-aws text-theme fs-4'></i>aws</>},
  { value:'fab fa-lg fa-fw me-2 fa-bandcamp', label: <><i className='fab fa-lg fa-fw me-2 fa-bandcamp text-theme fs-4'></i>bandcamp</>},
  { value:'fab fa-lg fa-fw me-2 fa-behance', label: <><i className='fab fa-lg fa-fw me-2 fa-behance text-theme fs-4'></i>behance</>},
  { value:'fab fa-lg fa-fw me-2 fa-behance-square', label: <><i className='fab fa-lg fa-fw me-2 fa-behance-square text-theme fs-4'></i>behance-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-bimobject', label: <><i className='fab fa-lg fa-fw me-2 fa-bimobject text-theme fs-4'></i>bimobject</>},
  { value:'fab fa-lg fa-fw me-2 fa-bitbucket', label: <><i className='fab fa-lg fa-fw me-2 fa-bitbucket text-theme fs-4'></i>bitbucket</>},
  { value:'fab fa-lg fa-fw me-2 fa-bitcoin', label: <><i className='fab fa-lg fa-fw me-2 fa-bitcoin text-theme fs-4'></i>bitcoin</>},
  { value:'fab fa-lg fa-fw me-2 fa-bity', label: <><i className='fab fa-lg fa-fw me-2 fa-bity text-theme fs-4'></i>bity</>},
  { value:'fab fa-lg fa-fw me-2 fa-black-tie', label: <><i className='fab fa-lg fa-fw me-2 fa-black-tie text-theme fs-4'></i>black-tie</>},
  { value:'fab fa-lg fa-fw me-2 fa-blackberry', label: <><i className='fab fa-lg fa-fw me-2 fa-blackberry text-theme fs-4'></i>blackberry</>},
  { value:'fab fa-lg fa-fw me-2 fa-blogger', label: <><i className='fab fa-lg fa-fw me-2 fa-blogger text-theme fs-4'></i>blogger</>},
  { value:'fab fa-lg fa-fw me-2 fa-blogger-b', label: <><i className='fab fa-lg fa-fw me-2 fa-blogger-b text-theme fs-4'></i>blogger-b</>},
  { value:'fab fa-lg fa-fw me-2 fa-bluetooth', label: <><i className='fab fa-lg fa-fw me-2 fa-bluetooth text-theme fs-4'></i>bluetooth</>},
  { value:'fab fa-lg fa-fw me-2 fa-bluetooth-b', label: <><i className='fab fa-lg fa-fw me-2 fa-bluetooth-b text-theme fs-4'></i>bluetooth-b</>},
  { value:'fab fa-lg fa-fw me-2 fa-btc', label: <><i className='fab fa-lg fa-fw me-2 fa-btc text-theme fs-4'></i>btc</>},
  { value:'fab fa-lg fa-fw me-2 fa-buromobelexperte', label: <><i className='fab fa-lg fa-fw me-2 fa-buromobelexperte text-theme fs-4'></i>buromobelexperte</>},
  { value:'fab fa-lg fa-fw me-2 fa-buysellads', label: <><i className='fab fa-lg fa-fw me-2 fa-buysellads text-theme fs-4'></i>buysellads</>},
  { value:'fab fa-lg fa-fw me-2 fa-cc-amazon-pay', label: <><i className='fab fa-lg fa-fw me-2 fa-cc-amazon-pay text-theme fs-4'></i>cc-amazon-pay</>},
  { value:'fab fa-lg fa-fw me-2 fa-cc-amex', label: <><i className='fab fa-lg fa-fw me-2 fa-cc-amex text-theme fs-4'></i>cc-amex</>},
  { value:'fab fa-lg fa-fw me-2 fa-cc-apple-pay', label: <><i className='fab fa-lg fa-fw me-2 fa-cc-apple-pay text-theme fs-4'></i>cc-apple-pay</>},
  { value:'fab fa-lg fa-fw me-2 fa-cc-diners-club', label: <><i className='fab fa-lg fa-fw me-2 fa-cc-diners-club text-theme fs-4'></i>cc-diners-club</>},
  { value:'fab fa-lg fa-fw me-2 fa-cc-discover', label: <><i className='fab fa-lg fa-fw me-2 fa-cc-discover text-theme fs-4'></i>cc-discover</>},
  { value:'fab fa-lg fa-fw me-2 fa-cc-jcb', label: <><i className='fab fa-lg fa-fw me-2 fa-cc-jcb text-theme fs-4'></i>cc-jcb</>},
  { value:'fab fa-lg fa-fw me-2 fa-cc-mastercard', label: <><i className='fab fa-lg fa-fw me-2 fa-cc-mastercard text-theme fs-4'></i>cc-mastercard</>},
  { value:'fab fa-lg fa-fw me-2 fa-cc-paypal', label: <><i className='fab fa-lg fa-fw me-2 fa-cc-paypal text-theme fs-4'></i>cc-paypal</>},
  { value:'fab fa-lg fa-fw me-2 fa-cc-stripe', label: <><i className='fab fa-lg fa-fw me-2 fa-cc-stripe text-theme fs-4'></i>cc-stripe</>},
  { value:'fab fa-lg fa-fw me-2 fa-cc-visa', label: <><i className='fab fa-lg fa-fw me-2 fa-cc-visa text-theme fs-4'></i>cc-visa</>},
  { value:'fab fa-lg fa-fw me-2 fa-centercode', label: <><i className='fab fa-lg fa-fw me-2 fa-centercode text-theme fs-4'></i>centercode</>},
  { value:'fab fa-lg fa-fw me-2 fa-chrome', label: <><i className='fab fa-lg fa-fw me-2 fa-chrome text-theme fs-4'></i>chrome</>},
  { value:'fab fa-lg fa-fw me-2 fa-cloudscale', label: <><i className='fab fa-lg fa-fw me-2 fa-cloudscale text-theme fs-4'></i>cloudscale</>},
  { value:'fab fa-lg fa-fw me-2 fa-cloudsmith', label: <><i className='fab fa-lg fa-fw me-2 fa-cloudsmith text-theme fs-4'></i>cloudsmith</>},
  { value:'fab fa-lg fa-fw me-2 fa-cloudversify', label: <><i className='fab fa-lg fa-fw me-2 fa-cloudversify text-theme fs-4'></i>cloudversify</>},
  { value:'fab fa-lg fa-fw me-2 fa-codepen', label: <><i className='fab fa-lg fa-fw me-2 fa-codepen text-theme fs-4'></i>codepen</>},
  { value:'fab fa-lg fa-fw me-2 fa-codiepie', label: <><i className='fab fa-lg fa-fw me-2 fa-codiepie text-theme fs-4'></i>codiepie</>},
  { value:'fab fa-lg fa-fw me-2 fa-connectdevelop', label: <><i className='fab fa-lg fa-fw me-2 fa-connectdevelop text-theme fs-4'></i>connectdevelop</>},
  { value:'fab fa-lg fa-fw me-2 fa-contao', label: <><i className='fab fa-lg fa-fw me-2 fa-contao text-theme fs-4'></i>contao</>},
  { value:'fab fa-lg fa-fw me-2 fa-cpanel', label: <><i className='fab fa-lg fa-fw me-2 fa-cpanel text-theme fs-4'></i>cpanel</>},
  { value:'fab fa-lg fa-fw me-2 fa-creative-commons', label: <><i className='fab fa-lg fa-fw me-2 fa-creative-commons text-theme fs-4'></i>creative-commons</>},
  { value:'fab fa-lg fa-fw me-2 fa-css3', label: <><i className='fab fa-lg fa-fw me-2 fa-css3 text-theme fs-4'></i>css3</>},
  { value:'fab fa-lg fa-fw me-2 fa-css3-alt', label: <><i className='fab fa-lg fa-fw me-2 fa-css3-alt text-theme fs-4'></i>css3-alt</>},
  { value:'fab fa-lg fa-fw me-2 fa-cuttlefish', label: <><i className='fab fa-lg fa-fw me-2 fa-cuttlefish text-theme fs-4'></i>cuttlefish</>},
  { value:'fab fa-lg fa-fw me-2 fa-d-and-d', label: <><i className='fab fa-lg fa-fw me-2 fa-d-and-d text-theme fs-4'></i>d-and-d</>},
  { value:'fab fa-lg fa-fw me-2 fa-dashcube', label: <><i className='fab fa-lg fa-fw me-2 fa-dashcube text-theme fs-4'></i>dashcube</>},
  { value:'fab fa-lg fa-fw me-2 fa-delicious', label: <><i className='fab fa-lg fa-fw me-2 fa-delicious text-theme fs-4'></i>delicious</>},
  { value:'fab fa-lg fa-fw me-2 fa-deploydog', label: <><i className='fab fa-lg fa-fw me-2 fa-deploydog text-theme fs-4'></i>deploydog</>},
  { value:'fab fa-lg fa-fw me-2 fa-deskpro', label: <><i className='fab fa-lg fa-fw me-2 fa-deskpro text-theme fs-4'></i>deskpro</>},
  { value:'fab fa-lg fa-fw me-2 fa-deviantart', label: <><i className='fab fa-lg fa-fw me-2 fa-deviantart text-theme fs-4'></i>deviantart</>},
  { value:'fab fa-lg fa-fw me-2 fa-digg', label: <><i className='fab fa-lg fa-fw me-2 fa-digg text-theme fs-4'></i>digg</>},
  { value:'fab fa-lg fa-fw me-2 fa-digital-ocean', label: <><i className='fab fa-lg fa-fw me-2 fa-digital-ocean text-theme fs-4'></i>digital-ocean</>},
  { value:'fab fa-lg fa-fw me-2 fa-discord', label: <><i className='fab fa-lg fa-fw me-2 fa-discord text-theme fs-4'></i>discord</>},
  { value:'fab fa-lg fa-fw me-2 fa-discourse', label: <><i className='fab fa-lg fa-fw me-2 fa-discourse text-theme fs-4'></i>discourse</>},
  { value:'fab fa-lg fa-fw me-2 fa-dochub', label: <><i className='fab fa-lg fa-fw me-2 fa-dochub text-theme fs-4'></i>dochub</>},
  { value:'fab fa-lg fa-fw me-2 fa-docker', label: <><i className='fab fa-lg fa-fw me-2 fa-docker text-theme fs-4'></i>docker</>},
  { value:'fab fa-lg fa-fw me-2 fa-draft2digital', label: <><i className='fab fa-lg fa-fw me-2 fa-draft2digital text-theme fs-4'></i>draft2digital</>},
  { value:'fab fa-lg fa-fw me-2 fa-dribbble', label: <><i className='fab fa-lg fa-fw me-2 fa-dribbble text-theme fs-4'></i>dribbble</>},
  { value:'fab fa-lg fa-fw me-2 fa-dribbble-square', label: <><i className='fab fa-lg fa-fw me-2 fa-dribbble-square text-theme fs-4'></i>dribbble-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-dropbox', label: <><i className='fab fa-lg fa-fw me-2 fa-dropbox text-theme fs-4'></i>dropbox</>},
  { value:'fab fa-lg fa-fw me-2 fa-drupal', label: <><i className='fab fa-lg fa-fw me-2 fa-drupal text-theme fs-4'></i>drupal</>},
  { value:'fab fa-lg fa-fw me-2 fa-dyalog', label: <><i className='fab fa-lg fa-fw me-2 fa-dyalog text-theme fs-4'></i>dyalog</>},
  { value:'fab fa-lg fa-fw me-2 fa-earlybirds', label: <><i className='fab fa-lg fa-fw me-2 fa-earlybirds text-theme fs-4'></i>earlybirds</>},
  { value:'fab fa-lg fa-fw me-2 fa-edge', label: <><i className='fab fa-lg fa-fw me-2 fa-edge text-theme fs-4'></i>edge</>},
  { value:'fab fa-lg fa-fw me-2 fa-elementor', label: <><i className='fab fa-lg fa-fw me-2 fa-elementor text-theme fs-4'></i>elementor</>},
  { value:'fab fa-lg fa-fw me-2 fa-ember', label: <><i className='fab fa-lg fa-fw me-2 fa-ember text-theme fs-4'></i>ember</>},
  { value:'fab fa-lg fa-fw me-2 fa-empire', label: <><i className='fab fa-lg fa-fw me-2 fa-empire text-theme fs-4'></i>empire</>},
  { value:'fab fa-lg fa-fw me-2 fa-envira', label: <><i className='fab fa-lg fa-fw me-2 fa-envira text-theme fs-4'></i>envira</>},
  { value:'fab fa-lg fa-fw me-2 fa-erlang', label: <><i className='fab fa-lg fa-fw me-2 fa-erlang text-theme fs-4'></i>erlang</>},
  { value:'fab fa-lg fa-fw me-2 fa-ethereum', label: <><i className='fab fa-lg fa-fw me-2 fa-ethereum text-theme fs-4'></i>ethereum</>},
  { value:'fab fa-lg fa-fw me-2 fa-etsy', label: <><i className='fab fa-lg fa-fw me-2 fa-etsy text-theme fs-4'></i>etsy</>},
  { value:'fab fa-lg fa-fw me-2 fa-expeditedssl', label: <><i className='fab fa-lg fa-fw me-2 fa-expeditedssl text-theme fs-4'></i>expeditedssl</>},
  { value:'fab fa-lg fa-fw me-2 fa-facebook', label: <><i className='fab fa-lg fa-fw me-2 fa-facebook text-theme fs-4'></i>facebook</>},
  { value:'fab fa-lg fa-fw me-2 fa-facebook-f', label: <><i className='fab fa-lg fa-fw me-2 fa-facebook-f text-theme fs-4'></i>facebook-f</>},
  { value:'fab fa-lg fa-fw me-2 fa-facebook-messenger', label: <><i className='fab fa-lg fa-fw me-2 fa-facebook-messenger text-theme fs-4'></i>facebook-messenger</>},
  { value:'fab fa-lg fa-fw me-2 fa-facebook-square', label: <><i className='fab fa-lg fa-fw me-2 fa-facebook-square text-theme fs-4'></i>facebook-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-firefox', label: <><i className='fab fa-lg fa-fw me-2 fa-firefox text-theme fs-4'></i>firefox</>},
  { value:'fab fa-lg fa-fw me-2 fa-first-order', label: <><i className='fab fa-lg fa-fw me-2 fa-first-order text-theme fs-4'></i>first-order</>},
  { value:'fab fa-lg fa-fw me-2 fa-firstdraft', label: <><i className='fab fa-lg fa-fw me-2 fa-firstdraft text-theme fs-4'></i>firstdraft</>},
  { value:'fab fa-lg fa-fw me-2 fa-flickr', label: <><i className='fab fa-lg fa-fw me-2 fa-flickr text-theme fs-4'></i>flickr</>},
  { value:'fab fa-lg fa-fw me-2 fa-fly', label: <><i className='fab fa-lg fa-fw me-2 fa-fly text-theme fs-4'></i>fly</>},
  { value:'fab fa-lg fa-fw me-2 fa-font-awesome', label: <><i className='fab fa-lg fa-fw me-2 fa-font-awesome text-theme fs-4'></i>font-awesome</>},
  { value:'fab fa-lg fa-fw me-2 fa-font-awesome-alt', label: <><i className='fab fa-lg fa-fw me-2 fa-font-awesome-alt text-theme fs-4'></i>font-awesome-alt</>},
  { value:'fab fa-lg fa-fw me-2 fa-font-awesome-flag', label: <><i className='fab fa-lg fa-fw me-2 fa-font-awesome-flag text-theme fs-4'></i>font-awesome-flag</>},
  { value:'fab fa-lg fa-fw me-2 fa-fonticons', label: <><i className='fab fa-lg fa-fw me-2 fa-fonticons text-theme fs-4'></i>fonticons</>},
  { value:'fab fa-lg fa-fw me-2 fa-fonticons-fi', label: <><i className='fab fa-lg fa-fw me-2 fa-fonticons-fi text-theme fs-4'></i>fonticons-fi</>},
  { value:'fab fa-lg fa-fw me-2 fa-fort-awesome', label: <><i className='fab fa-lg fa-fw me-2 fa-fort-awesome text-theme fs-4'></i>fort-awesome</>},
  { value:'fab fa-lg fa-fw me-2 fa-fort-awesome-alt', label: <><i className='fab fa-lg fa-fw me-2 fa-fort-awesome-alt text-theme fs-4'></i>fort-awesome-alt</>},
  { value:'fab fa-lg fa-fw me-2 fa-forumbee', label: <><i className='fab fa-lg fa-fw me-2 fa-forumbee text-theme fs-4'></i>forumbee</>},
  { value:'fab fa-lg fa-fw me-2 fa-foursquare', label: <><i className='fab fa-lg fa-fw me-2 fa-foursquare text-theme fs-4'></i>foursquare</>},
  { value:'fab fa-lg fa-fw me-2 fa-free-code-camp', label: <><i className='fab fa-lg fa-fw me-2 fa-free-code-camp text-theme fs-4'></i>free-code-camp</>},
  { value:'fab fa-lg fa-fw me-2 fa-freebsd', label: <><i className='fab fa-lg fa-fw me-2 fa-freebsd text-theme fs-4'></i>freebsd</>},
  { value:'fab fa-lg fa-fw me-2 fa-get-pocket', label: <><i className='fab fa-lg fa-fw me-2 fa-get-pocket text-theme fs-4'></i>get-pocket</>},
  { value:'fab fa-lg fa-fw me-2 fa-gg', label: <><i className='fab fa-lg fa-fw me-2 fa-gg text-theme fs-4'></i>gg</>},
  { value:'fab fa-lg fa-fw me-2 fa-gg-circle', label: <><i className='fab fa-lg fa-fw me-2 fa-gg-circle text-theme fs-4'></i>gg-circle</>},
  { value:'fab fa-lg fa-fw me-2 fa-git', label: <><i className='fab fa-lg fa-fw me-2 fa-git text-theme fs-4'></i>git</>},
  { value:'fab fa-lg fa-fw me-2 fa-git-square', label: <><i className='fab fa-lg fa-fw me-2 fa-git-square text-theme fs-4'></i>git-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-github', label: <><i className='fab fa-lg fa-fw me-2 fa-github text-theme fs-4'></i>github</>},
  { value:'fab fa-lg fa-fw me-2 fa-github-alt', label: <><i className='fab fa-lg fa-fw me-2 fa-github-alt text-theme fs-4'></i>github-alt</>},
  { value:'fab fa-lg fa-fw me-2 fa-github-square', label: <><i className='fab fa-lg fa-fw me-2 fa-github-square text-theme fs-4'></i>github-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-gitkraken', label: <><i className='fab fa-lg fa-fw me-2 fa-gitkraken text-theme fs-4'></i>gitkraken</>},
  { value:'fab fa-lg fa-fw me-2 fa-gitlab', label: <><i className='fab fa-lg fa-fw me-2 fa-gitlab text-theme fs-4'></i>gitlab</>},
  { value:'fab fa-lg fa-fw me-2 fa-gitter', label: <><i className='fab fa-lg fa-fw me-2 fa-gitter text-theme fs-4'></i>gitter</>},
  { value:'fab fa-lg fa-fw me-2 fa-glide', label: <><i className='fab fa-lg fa-fw me-2 fa-glide text-theme fs-4'></i>glide</>},
  { value:'fab fa-lg fa-fw me-2 fa-glide-g', label: <><i className='fab fa-lg fa-fw me-2 fa-glide-g text-theme fs-4'></i>glide-g</>},
  { value:'fab fa-lg fa-fw me-2 fa-gofore', label: <><i className='fab fa-lg fa-fw me-2 fa-gofore text-theme fs-4'></i>gofore</>},
  { value:'fab fa-lg fa-fw me-2 fa-goodreads', label: <><i className='fab fa-lg fa-fw me-2 fa-goodreads text-theme fs-4'></i>goodreads</>},
  { value:'fab fa-lg fa-fw me-2 fa-goodreads-g', label: <><i className='fab fa-lg fa-fw me-2 fa-goodreads-g text-theme fs-4'></i>goodreads-g</>},
  { value:'fab fa-lg fa-fw me-2 fa-google', label: <><i className='fab fa-lg fa-fw me-2 fa-google text-theme fs-4'></i>google</>},
  { value:'fab fa-lg fa-fw me-2 fa-google-drive', label: <><i className='fab fa-lg fa-fw me-2 fa-google-drive text-theme fs-4'></i>google-drive</>},
  { value:'fab fa-lg fa-fw me-2 fa-google-play', label: <><i className='fab fa-lg fa-fw me-2 fa-google-play text-theme fs-4'></i>google-play</>},
  { value:'fab fa-lg fa-fw me-2 fa-google-plus', label: <><i className='fab fa-lg fa-fw me-2 fa-google-plus text-theme fs-4'></i>google-plus</>},
  { value:'fab fa-lg fa-fw me-2 fa-google-plus-g', label: <><i className='fab fa-lg fa-fw me-2 fa-google-plus-g text-theme fs-4'></i>google-plus-g</>},
  { value:'fab fa-lg fa-fw me-2 fa-google-plus-square', label: <><i className='fab fa-lg fa-fw me-2 fa-google-plus-square text-theme fs-4'></i>google-plus-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-google-wallet', label: <><i className='fab fa-lg fa-fw me-2 fa-google-wallet text-theme fs-4'></i>google-wallet</>},
  { value:'fab fa-lg fa-fw me-2 fa-gratipay', label: <><i className='fab fa-lg fa-fw me-2 fa-gratipay text-theme fs-4'></i>gratipay</>},
  { value:'fab fa-lg fa-fw me-2 fa-grav', label: <><i className='fab fa-lg fa-fw me-2 fa-grav text-theme fs-4'></i>grav</>},
  { value:'fab fa-lg fa-fw me-2 fa-gripfire', label: <><i className='fab fa-lg fa-fw me-2 fa-gripfire text-theme fs-4'></i>gripfire</>},
  { value:'fab fa-lg fa-fw me-2 fa-grunt', label: <><i className='fab fa-lg fa-fw me-2 fa-grunt text-theme fs-4'></i>grunt</>},
  { value:'fab fa-lg fa-fw me-2 fa-gulp', label: <><i className='fab fa-lg fa-fw me-2 fa-gulp text-theme fs-4'></i>gulp</>},
  { value:'fab fa-lg fa-fw me-2 fa-hacker-news', label: <><i className='fab fa-lg fa-fw me-2 fa-hacker-news text-theme fs-4'></i>hacker-news</>},
  { value:'fab fa-lg fa-fw me-2 fa-hacker-news-square', label: <><i className='fab fa-lg fa-fw me-2 fa-hacker-news-square text-theme fs-4'></i>hacker-news-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-hire-a-helper', label: <><i className='fab fa-lg fa-fw me-2 fa-hire-a-helper text-theme fs-4'></i>hire-a-helper</>},
  { value:'fab fa-lg fa-fw me-2 fa-hooli', label: <><i className='fab fa-lg fa-fw me-2 fa-hooli text-theme fs-4'></i>hooli</>},
  { value:'fab fa-lg fa-fw me-2 fa-hotjar', label: <><i className='fab fa-lg fa-fw me-2 fa-hotjar text-theme fs-4'></i>hotjar</>},
  { value:'fab fa-lg fa-fw me-2 fa-houzz', label: <><i className='fab fa-lg fa-fw me-2 fa-houzz text-theme fs-4'></i>houzz</>},
  { value:'fab fa-lg fa-fw me-2 fa-html5', label: <><i className='fab fa-lg fa-fw me-2 fa-html5 text-theme fs-4'></i>html5</>},
  { value:'fab fa-lg fa-fw me-2 fa-hubspot', label: <><i className='fab fa-lg fa-fw me-2 fa-hubspot text-theme fs-4'></i>hubspot</>},
  { value:'fab fa-lg fa-fw me-2 fa-imdb', label: <><i className='fab fa-lg fa-fw me-2 fa-imdb text-theme fs-4'></i>imdb</>},
  { value:'fab fa-lg fa-fw me-2 fa-instagram', label: <><i className='fab fa-lg fa-fw me-2 fa-instagram text-theme fs-4'></i>instagram</>},
  { value:'fab fa-lg fa-fw me-2 fa-internet-explorer', label: <><i className='fab fa-lg fa-fw me-2 fa-internet-explorer text-theme fs-4'></i>internet-explorer</>},
  { value:'fab fa-lg fa-fw me-2 fa-ioxhost', label: <><i className='fab fa-lg fa-fw me-2 fa-ioxhost text-theme fs-4'></i>ioxhost</>},
  { value:'fab fa-lg fa-fw me-2 fa-itunes', label: <><i className='fab fa-lg fa-fw me-2 fa-itunes text-theme fs-4'></i>itunes</>},
  { value:'fab fa-lg fa-fw me-2 fa-itunes-note', label: <><i className='fab fa-lg fa-fw me-2 fa-itunes-note text-theme fs-4'></i>itunes-note</>},
  { value:'fab fa-lg fa-fw me-2 fa-jenkins', label: <><i className='fab fa-lg fa-fw me-2 fa-jenkins text-theme fs-4'></i>jenkins</>},
  { value:'fab fa-lg fa-fw me-2 fa-joget', label: <><i className='fab fa-lg fa-fw me-2 fa-joget text-theme fs-4'></i>joget</>},
  { value:'fab fa-lg fa-fw me-2 fa-joomla', label: <><i className='fab fa-lg fa-fw me-2 fa-joomla text-theme fs-4'></i>joomla</>},
  { value:'fab fa-lg fa-fw me-2 fa-js', label: <><i className='fab fa-lg fa-fw me-2 fa-js text-theme fs-4'></i>js</>},
  { value:'fab fa-lg fa-fw me-2 fa-js-square', label: <><i className='fab fa-lg fa-fw me-2 fa-js-square text-theme fs-4'></i>js-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-jsfiddle', label: <><i className='fab fa-lg fa-fw me-2 fa-jsfiddle text-theme fs-4'></i>jsfiddle</>},
  { value:'fab fa-lg fa-fw me-2 fa-keycdn', label: <><i className='fab fa-lg fa-fw me-2 fa-keycdn text-theme fs-4'></i>keycdn</>},
  { value:'fab fa-lg fa-fw me-2 fa-kickstarter', label: <><i className='fab fa-lg fa-fw me-2 fa-kickstarter text-theme fs-4'></i>kickstarter</>},
  { value:'fab fa-lg fa-fw me-2 fa-kickstarter-k', label: <><i className='fab fa-lg fa-fw me-2 fa-kickstarter-k text-theme fs-4'></i>kickstarter-k</>},
  { value:'fab fa-lg fa-fw me-2 fa-korvue', label: <><i className='fab fa-lg fa-fw me-2 fa-korvue text-theme fs-4'></i>korvue</>},
  { value:'fab fa-lg fa-fw me-2 fa-laravel', label: <><i className='fab fa-lg fa-fw me-2 fa-laravel text-theme fs-4'></i>laravel</>},
  { value:'fab fa-lg fa-fw me-2 fa-lastfm', label: <><i className='fab fa-lg fa-fw me-2 fa-lastfm text-theme fs-4'></i>lastfm</>},
  { value:'fab fa-lg fa-fw me-2 fa-lastfm-square', label: <><i className='fab fa-lg fa-fw me-2 fa-lastfm-square text-theme fs-4'></i>lastfm-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-leanpub', label: <><i className='fab fa-lg fa-fw me-2 fa-leanpub text-theme fs-4'></i>leanpub</>},
  { value:'fab fa-lg fa-fw me-2 fa-less', label: <><i className='fab fa-lg fa-fw me-2 fa-less text-theme fs-4'></i>less</>},
  { value:'fab fa-lg fa-fw me-2 fa-line', label: <><i className='fab fa-lg fa-fw me-2 fa-line text-theme fs-4'></i>line</>},
  { value:'fab fa-lg fa-fw me-2 fa-linkedin', label: <><i className='fab fa-lg fa-fw me-2 fa-linkedin text-theme fs-4'></i>linkedin</>},
  { value:'fab fa-lg fa-fw me-2 fa-linkedin-in', label: <><i className='fab fa-lg fa-fw me-2 fa-linkedin-in text-theme fs-4'></i>linkedin-in</>},
  { value:'fab fa-lg fa-fw me-2 fa-linode', label: <><i className='fab fa-lg fa-fw me-2 fa-linode text-theme fs-4'></i>linode</>},
  { value:'fab fa-lg fa-fw me-2 fa-linux', label: <><i className='fab fa-lg fa-fw me-2 fa-linux text-theme fs-4'></i>linux</>},
  { value:'fab fa-lg fa-fw me-2 fa-lyft', label: <><i className='fab fa-lg fa-fw me-2 fa-lyft text-theme fs-4'></i>lyft</>},
  { value:'fab fa-lg fa-fw me-2 fa-magento', label: <><i className='fab fa-lg fa-fw me-2 fa-magento text-theme fs-4'></i>magento</>},
  { value:'fab fa-lg fa-fw me-2 fa-maxcdn', label: <><i className='fab fa-lg fa-fw me-2 fa-maxcdn text-theme fs-4'></i>maxcdn</>},
  { value:'fab fa-lg fa-fw me-2 fa-medapps', label: <><i className='fab fa-lg fa-fw me-2 fa-medapps text-theme fs-4'></i>medapps</>},
  { value:'fab fa-lg fa-fw me-2 fa-medium', label: <><i className='fab fa-lg fa-fw me-2 fa-medium text-theme fs-4'></i>medium</>},
  { value:'fab fa-lg fa-fw me-2 fa-medium-m', label: <><i className='fab fa-lg fa-fw me-2 fa-medium-m text-theme fs-4'></i>medium-m</>},
  { value:'fab fa-lg fa-fw me-2 fa-medrt', label: <><i className='fab fa-lg fa-fw me-2 fa-medrt text-theme fs-4'></i>medrt</>},
  { value:'fab fa-lg fa-fw me-2 fa-meetup', label: <><i className='fab fa-lg fa-fw me-2 fa-meetup text-theme fs-4'></i>meetup</>},
  { value:'fab fa-lg fa-fw me-2 fa-microsoft', label: <><i className='fab fa-lg fa-fw me-2 fa-microsoft text-theme fs-4'></i>microsoft</>},
  { value:'fab fa-lg fa-fw me-2 fa-mix', label: <><i className='fab fa-lg fa-fw me-2 fa-mix text-theme fs-4'></i>mix</>},
  { value:'fab fa-lg fa-fw me-2 fa-mixcloud', label: <><i className='fab fa-lg fa-fw me-2 fa-mixcloud text-theme fs-4'></i>mixcloud</>},
  { value:'fab fa-lg fa-fw me-2 fa-mizuni', label: <><i className='fab fa-lg fa-fw me-2 fa-mizuni text-theme fs-4'></i>mizuni</>},
  { value:'fab fa-lg fa-fw me-2 fa-modx', label: <><i className='fab fa-lg fa-fw me-2 fa-modx text-theme fs-4'></i>modx</>},
  { value:'fab fa-lg fa-fw me-2 fa-monero', label: <><i className='fab fa-lg fa-fw me-2 fa-monero text-theme fs-4'></i>monero</>},
  { value:'fab fa-lg fa-fw me-2 fa-napster', label: <><i className='fab fa-lg fa-fw me-2 fa-napster text-theme fs-4'></i>napster</>},
  { value:'fab fa-lg fa-fw me-2 fa-node', label: <><i className='fab fa-lg fa-fw me-2 fa-node text-theme fs-4'></i>node</>},
  { value:'fab fa-lg fa-fw me-2 fa-node-js', label: <><i className='fab fa-lg fa-fw me-2 fa-node-js text-theme fs-4'></i>node-js</>},
  { value:'fab fa-lg fa-fw me-2 fa-npm', label: <><i className='fab fa-lg fa-fw me-2 fa-npm text-theme fs-4'></i>npm</>},
  { value:'fab fa-lg fa-fw me-2 fa-ns8', label: <><i className='fab fa-lg fa-fw me-2 fa-ns8 text-theme fs-4'></i>ns8</>},
  { value:'fab fa-lg fa-fw me-2 fa-nutritionix', label: <><i className='fab fa-lg fa-fw me-2 fa-nutritionix text-theme fs-4'></i>nutritionix</>},
  { value:'fab fa-lg fa-fw me-2 fa-odnoklassniki', label: <><i className='fab fa-lg fa-fw me-2 fa-odnoklassniki text-theme fs-4'></i>odnoklassniki</>},
  { value:'fab fa-lg fa-fw me-2 fa-odnoklassniki-square', label: <><i className='fab fa-lg fa-fw me-2 fa-odnoklassniki-square text-theme fs-4'></i>odnoklassniki-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-opencart', label: <><i className='fab fa-lg fa-fw me-2 fa-opencart text-theme fs-4'></i>opencart</>},
  { value:'fab fa-lg fa-fw me-2 fa-openid', label: <><i className='fab fa-lg fa-fw me-2 fa-openid text-theme fs-4'></i>openid</>},
  { value:'fab fa-lg fa-fw me-2 fa-opera', label: <><i className='fab fa-lg fa-fw me-2 fa-opera text-theme fs-4'></i>opera</>},
  { value:'fab fa-lg fa-fw me-2 fa-optin-monster', label: <><i className='fab fa-lg fa-fw me-2 fa-optin-monster text-theme fs-4'></i>optin-monster</>},
  { value:'fab fa-lg fa-fw me-2 fa-osi', label: <><i className='fab fa-lg fa-fw me-2 fa-osi text-theme fs-4'></i>osi</>},
  { value:'fab fa-lg fa-fw me-2 fa-page4', label: <><i className='fab fa-lg fa-fw me-2 fa-page4 text-theme fs-4'></i>page4</>},
  { value:'fab fa-lg fa-fw me-2 fa-pagelines', label: <><i className='fab fa-lg fa-fw me-2 fa-pagelines text-theme fs-4'></i>pagelines</>},
  { value:'fab fa-lg fa-fw me-2 fa-palfed', label: <><i className='fab fa-lg fa-fw me-2 fa-palfed text-theme fs-4'></i>palfed</>},
  { value:'fab fa-lg fa-fw me-2 fa-patreon', label: <><i className='fab fa-lg fa-fw me-2 fa-patreon text-theme fs-4'></i>patreon</>},
  { value:'fab fa-lg fa-fw me-2 fa-paypal', label: <><i className='fab fa-lg fa-fw me-2 fa-paypal text-theme fs-4'></i>paypal</>},
  { value:'fab fa-lg fa-fw me-2 fa-periscope', label: <><i className='fab fa-lg fa-fw me-2 fa-periscope text-theme fs-4'></i>periscope</>},
  { value:'fab fa-lg fa-fw me-2 fa-phabricator', label: <><i className='fab fa-lg fa-fw me-2 fa-phabricator text-theme fs-4'></i>phabricator</>},
  { value:'fab fa-lg fa-fw me-2 fa-phoenix-framework', label: <><i className='fab fa-lg fa-fw me-2 fa-phoenix-framework text-theme fs-4'></i>phoenix-framework</>},
  { value:'fab fa-lg fa-fw me-2 fa-pied-piper', label: <><i className='fab fa-lg fa-fw me-2 fa-pied-piper text-theme fs-4'></i>pied-piper</>},
  { value:'fab fa-lg fa-fw me-2 fa-pied-piper-alt', label: <><i className='fab fa-lg fa-fw me-2 fa-pied-piper-alt text-theme fs-4'></i>pied-piper-alt</>},
  { value:'fab fa-lg fa-fw me-2 fa-pied-piper-pp', label: <><i className='fab fa-lg fa-fw me-2 fa-pied-piper-pp text-theme fs-4'></i>pied-piper-pp</>},
  { value:'fab fa-lg fa-fw me-2 fa-pinterest', label: <><i className='fab fa-lg fa-fw me-2 fa-pinterest text-theme fs-4'></i>pinterest</>},
  { value:'fab fa-lg fa-fw me-2 fa-pinterest-p', label: <><i className='fab fa-lg fa-fw me-2 fa-pinterest-p text-theme fs-4'></i>pinterest-p</>},
  { value:'fab fa-lg fa-fw me-2 fa-pinterest-square', label: <><i className='fab fa-lg fa-fw me-2 fa-pinterest-square text-theme fs-4'></i>pinterest-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-playstation', label: <><i className='fab fa-lg fa-fw me-2 fa-playstation text-theme fs-4'></i>playstation</>},
  { value:'fab fa-lg fa-fw me-2 fa-product-hunt', label: <><i className='fab fa-lg fa-fw me-2 fa-product-hunt text-theme fs-4'></i>product-hunt</>},
  { value:'fab fa-lg fa-fw me-2 fa-pushed', label: <><i className='fab fa-lg fa-fw me-2 fa-pushed text-theme fs-4'></i>pushed</>},
  { value:'fab fa-lg fa-fw me-2 fa-python', label: <><i className='fab fa-lg fa-fw me-2 fa-python text-theme fs-4'></i>python</>},
  { value:'fab fa-lg fa-fw me-2 fa-qq', label: <><i className='fab fa-lg fa-fw me-2 fa-qq text-theme fs-4'></i>qq</>},
  { value:'fab fa-lg fa-fw me-2 fa-quora', label: <><i className='fab fa-lg fa-fw me-2 fa-quora text-theme fs-4'></i>quora</>},
  { value:'fab fa-lg fa-fw me-2 fa-ravelry', label: <><i className='fab fa-lg fa-fw me-2 fa-ravelry text-theme fs-4'></i>ravelry</>},
  { value:'fab fa-lg fa-fw me-2 fa-react', label: <><i className='fab fa-lg fa-fw me-2 fa-react text-theme fs-4'></i>react</>},
  { value:'fab fa-lg fa-fw me-2 fa-rebel', label: <><i className='fab fa-lg fa-fw me-2 fa-rebel text-theme fs-4'></i>rebel</>},
  { value:'fab fa-lg fa-fw me-2 fa-red-river', label: <><i className='fab fa-lg fa-fw me-2 fa-red-river text-theme fs-4'></i>red-river</>},
  { value:'fab fa-lg fa-fw me-2 fa-reddit', label: <><i className='fab fa-lg fa-fw me-2 fa-reddit text-theme fs-4'></i>reddit</>},
  { value:'fab fa-lg fa-fw me-2 fa-reddit-alien', label: <><i className='fab fa-lg fa-fw me-2 fa-reddit-alien text-theme fs-4'></i>reddit-alien</>},
  { value:'fab fa-lg fa-fw me-2 fa-reddit-square', label: <><i className='fab fa-lg fa-fw me-2 fa-reddit-square text-theme fs-4'></i>reddit-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-renren', label: <><i className='fab fa-lg fa-fw me-2 fa-renren text-theme fs-4'></i>renren</>},
  { value:'fab fa-lg fa-fw me-2 fa-replyd', label: <><i className='fab fa-lg fa-fw me-2 fa-replyd text-theme fs-4'></i>replyd</>},
  { value:'fab fa-lg fa-fw me-2 fa-resolving', label: <><i className='fab fa-lg fa-fw me-2 fa-resolving text-theme fs-4'></i>resolving</>},
  { value:'fab fa-lg fa-fw me-2 fa-rocketchat', label: <><i className='fab fa-lg fa-fw me-2 fa-rocketchat text-theme fs-4'></i>rocketchat</>},
  { value:'fab fa-lg fa-fw me-2 fa-rockrms', label: <><i className='fab fa-lg fa-fw me-2 fa-rockrms text-theme fs-4'></i>rockrms</>},
  { value:'fab fa-lg fa-fw me-2 fa-safari', label: <><i className='fab fa-lg fa-fw me-2 fa-safari text-theme fs-4'></i>safari</>},
  { value:'fab fa-lg fa-fw me-2 fa-sass', label: <><i className='fab fa-lg fa-fw me-2 fa-sass text-theme fs-4'></i>sass</>},
  { value:'fab fa-lg fa-fw me-2 fa-schlix', label: <><i className='fab fa-lg fa-fw me-2 fa-schlix text-theme fs-4'></i>schlix</>},
  { value:'fab fa-lg fa-fw me-2 fa-scribd', label: <><i className='fab fa-lg fa-fw me-2 fa-scribd text-theme fs-4'></i>scribd</>},
  { value:'fab fa-lg fa-fw me-2 fa-searchengin', label: <><i className='fab fa-lg fa-fw me-2 fa-searchengin text-theme fs-4'></i>searchengin</>},
  { value:'fab fa-lg fa-fw me-2 fa-sellcast', label: <><i className='fab fa-lg fa-fw me-2 fa-sellcast text-theme fs-4'></i>sellcast</>},
  { value:'fab fa-lg fa-fw me-2 fa-sellsy', label: <><i className='fab fa-lg fa-fw me-2 fa-sellsy text-theme fs-4'></i>sellsy</>},
  { value:'fab fa-lg fa-fw me-2 fa-servicestack', label: <><i className='fab fa-lg fa-fw me-2 fa-servicestack text-theme fs-4'></i>servicestack</>},
  { value:'fab fa-lg fa-fw me-2 fa-shirtsinbulk', label: <><i className='fab fa-lg fa-fw me-2 fa-shirtsinbulk text-theme fs-4'></i>shirtsinbulk</>},
  { value:'fab fa-lg fa-fw me-2 fa-simplybuilt', label: <><i className='fab fa-lg fa-fw me-2 fa-simplybuilt text-theme fs-4'></i>simplybuilt</>},
  { value:'fab fa-lg fa-fw me-2 fa-sistrix', label: <><i className='fab fa-lg fa-fw me-2 fa-sistrix text-theme fs-4'></i>sistrix</>},
  { value:'fab fa-lg fa-fw me-2 fa-skyatlas', label: <><i className='fab fa-lg fa-fw me-2 fa-skyatlas text-theme fs-4'></i>skyatlas</>},
  { value:'fab fa-lg fa-fw me-2 fa-skype', label: <><i className='fab fa-lg fa-fw me-2 fa-skype text-theme fs-4'></i>skype</>},
  { value:'fab fa-lg fa-fw me-2 fa-slack', label: <><i className='fab fa-lg fa-fw me-2 fa-slack text-theme fs-4'></i>slack</>},
  { value:'fab fa-lg fa-fw me-2 fa-slack-hash', label: <><i className='fab fa-lg fa-fw me-2 fa-slack-hash text-theme fs-4'></i>slack-hash</>},
  { value:'fab fa-lg fa-fw me-2 fa-slideshare', label: <><i className='fab fa-lg fa-fw me-2 fa-slideshare text-theme fs-4'></i>slideshare</>},
  { value:'fab fa-lg fa-fw me-2 fa-snapchat', label: <><i className='fab fa-lg fa-fw me-2 fa-snapchat text-theme fs-4'></i>snapchat</>},
  { value:'fab fa-lg fa-fw me-2 fa-snapchat-ghost', label: <><i className='fab fa-lg fa-fw me-2 fa-snapchat-ghost text-theme fs-4'></i>snapchat-ghost</>},
  { value:'fab fa-lg fa-fw me-2 fa-snapchat-square', label: <><i className='fab fa-lg fa-fw me-2 fa-snapchat-square text-theme fs-4'></i>snapchat-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-soundcloud', label: <><i className='fab fa-lg fa-fw me-2 fa-soundcloud text-theme fs-4'></i>soundcloud</>},
  { value:'fab fa-lg fa-fw me-2 fa-speakap', label: <><i className='fab fa-lg fa-fw me-2 fa-speakap text-theme fs-4'></i>speakap</>},
  { value:'fab fa-lg fa-fw me-2 fa-spotify', label: <><i className='fab fa-lg fa-fw me-2 fa-spotify text-theme fs-4'></i>spotify</>},
  { value:'fab fa-lg fa-fw me-2 fa-stack-exchange', label: <><i className='fab fa-lg fa-fw me-2 fa-stack-exchange text-theme fs-4'></i>stack-exchange</>},
  { value:'fab fa-lg fa-fw me-2 fa-stack-overflow', label: <><i className='fab fa-lg fa-fw me-2 fa-stack-overflow text-theme fs-4'></i>stack-overflow</>},
  { value:'fab fa-lg fa-fw me-2 fa-staylinked', label: <><i className='fab fa-lg fa-fw me-2 fa-staylinked text-theme fs-4'></i>staylinked</>},
  { value:'fab fa-lg fa-fw me-2 fa-steam', label: <><i className='fab fa-lg fa-fw me-2 fa-steam text-theme fs-4'></i>steam</>},
  { value:'fab fa-lg fa-fw me-2 fa-steam-square', label: <><i className='fab fa-lg fa-fw me-2 fa-steam-square text-theme fs-4'></i>steam-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-steam-symbol', label: <><i className='fab fa-lg fa-fw me-2 fa-steam-symbol text-theme fs-4'></i>steam-symbol</>},
  { value:'fab fa-lg fa-fw me-2 fa-sticker-mule', label: <><i className='fab fa-lg fa-fw me-2 fa-sticker-mule text-theme fs-4'></i>sticker-mule</>},
  { value:'fab fa-lg fa-fw me-2 fa-strava', label: <><i className='fab fa-lg fa-fw me-2 fa-strava text-theme fs-4'></i>strava</>},
  { value:'fab fa-lg fa-fw me-2 fa-stripe', label: <><i className='fab fa-lg fa-fw me-2 fa-stripe text-theme fs-4'></i>stripe</>},
  { value:'fab fa-lg fa-fw me-2 fa-stripe-s', label: <><i className='fab fa-lg fa-fw me-2 fa-stripe-s text-theme fs-4'></i>stripe-s</>},
  { value:'fab fa-lg fa-fw me-2 fa-studiovinari', label: <><i className='fab fa-lg fa-fw me-2 fa-studiovinari text-theme fs-4'></i>studiovinari</>},
  { value:'fab fa-lg fa-fw me-2 fa-stumbleupon', label: <><i className='fab fa-lg fa-fw me-2 fa-stumbleupon text-theme fs-4'></i>stumbleupon</>},
  { value:'fab fa-lg fa-fw me-2 fa-stumbleupon-circle', label: <><i className='fab fa-lg fa-fw me-2 fa-stumbleupon-circle text-theme fs-4'></i>stumbleupon-circle</>},
  { value:'fab fa-lg fa-fw me-2 fa-superpowers', label: <><i className='fab fa-lg fa-fw me-2 fa-superpowers text-theme fs-4'></i>superpowers</>},
  { value:'fab fa-lg fa-fw me-2 fa-supple', label: <><i className='fab fa-lg fa-fw me-2 fa-supple text-theme fs-4'></i>supple</>},
  { value:'fab fa-lg fa-fw me-2 fa-telegram', label: <><i className='fab fa-lg fa-fw me-2 fa-telegram text-theme fs-4'></i>telegram</>},
  { value:'fab fa-lg fa-fw me-2 fa-telegram-plane', label: <><i className='fab fa-lg fa-fw me-2 fa-telegram-plane text-theme fs-4'></i>telegram-plane</>},
  { value:'fab fa-lg fa-fw me-2 fa-tencent-weibo', label: <><i className='fab fa-lg fa-fw me-2 fa-tencent-weibo text-theme fs-4'></i>tencent-weibo</>},
  { value:'fab fa-lg fa-fw me-2 fa-themeisle', label: <><i className='fab fa-lg fa-fw me-2 fa-themeisle text-theme fs-4'></i>themeisle</>},
  { value:'fab fa-lg fa-fw me-2 fa-trello', label: <><i className='fab fa-lg fa-fw me-2 fa-trello text-theme fs-4'></i>trello</>},
  { value:'fab fa-lg fa-fw me-2 fa-tumblr', label: <><i className='fab fa-lg fa-fw me-2 fa-tumblr text-theme fs-4'></i>tumblr</>},
  { value:'fab fa-lg fa-fw me-2 fa-tumblr-square', label: <><i className='fab fa-lg fa-fw me-2 fa-tumblr-square text-theme fs-4'></i>tumblr-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-twitch', label: <><i className='fab fa-lg fa-fw me-2 fa-twitch text-theme fs-4'></i>twitch</>},
  { value:'fab fa-lg fa-fw me-2 fa-twitter', label: <><i className='fab fa-lg fa-fw me-2 fa-twitter text-theme fs-4'></i>twitter</>},
  { value:'fab fa-lg fa-fw me-2 fa-twitter-square', label: <><i className='fab fa-lg fa-fw me-2 fa-twitter-square text-theme fs-4'></i>twitter-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-typo3', label: <><i className='fab fa-lg fa-fw me-2 fa-typo3 text-theme fs-4'></i>typo3</>},
  { value:'fab fa-lg fa-fw me-2 fa-uber', label: <><i className='fab fa-lg fa-fw me-2 fa-uber text-theme fs-4'></i>uber</>},
  { value:'fab fa-lg fa-fw me-2 fa-uikit', label: <><i className='fab fa-lg fa-fw me-2 fa-uikit text-theme fs-4'></i>uikit</>},
  { value:'fab fa-lg fa-fw me-2 fa-uniregistry', label: <><i className='fab fa-lg fa-fw me-2 fa-uniregistry text-theme fs-4'></i>uniregistry</>},
  { value:'fab fa-lg fa-fw me-2 fa-untappd', label: <><i className='fab fa-lg fa-fw me-2 fa-untappd text-theme fs-4'></i>untappd</>},
  { value:'fab fa-lg fa-fw me-2 fa-usb', label: <><i className='fab fa-lg fa-fw me-2 fa-usb text-theme fs-4'></i>usb</>},
  { value:'fab fa-lg fa-fw me-2 fa-ussunnah', label: <><i className='fab fa-lg fa-fw me-2 fa-ussunnah text-theme fs-4'></i>ussunnah</>},
  { value:'fab fa-lg fa-fw me-2 fa-vaadin', label: <><i className='fab fa-lg fa-fw me-2 fa-vaadin text-theme fs-4'></i>vaadin</>},
  { value:'fab fa-lg fa-fw me-2 fa-viacoin', label: <><i className='fab fa-lg fa-fw me-2 fa-viacoin text-theme fs-4'></i>viacoin</>},
  { value:'fab fa-lg fa-fw me-2 fa-viadeo', label: <><i className='fab fa-lg fa-fw me-2 fa-viadeo text-theme fs-4'></i>viadeo</>},
  { value:'fab fa-lg fa-fw me-2 fa-viadeo-square', label: <><i className='fab fa-lg fa-fw me-2 fa-viadeo-square text-theme fs-4'></i>viadeo-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-viber', label: <><i className='fab fa-lg fa-fw me-2 fa-viber text-theme fs-4'></i>viber</>},
  { value:'fab fa-lg fa-fw me-2 fa-vimeo', label: <><i className='fab fa-lg fa-fw me-2 fa-vimeo text-theme fs-4'></i>vimeo</>},
  { value:'fab fa-lg fa-fw me-2 fa-vimeo-square', label: <><i className='fab fa-lg fa-fw me-2 fa-vimeo-square text-theme fs-4'></i>vimeo-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-vimeo-v', label: <><i className='fab fa-lg fa-fw me-2 fa-vimeo-v text-theme fs-4'></i>vimeo-v</>},
  { value:'fab fa-lg fa-fw me-2 fa-vine', label: <><i className='fab fa-lg fa-fw me-2 fa-vine text-theme fs-4'></i>vine</>},
  { value:'fab fa-lg fa-fw me-2 fa-vk', label: <><i className='fab fa-lg fa-fw me-2 fa-vk text-theme fs-4'></i>vk</>},
  { value:'fab fa-lg fa-fw me-2 fa-vnv', label: <><i className='fab fa-lg fa-fw me-2 fa-vnv text-theme fs-4'></i>vnv</>},
  { value:'fab fa-lg fa-fw me-2 fa-vuejs', label: <><i className='fab fa-lg fa-fw me-2 fa-vuejs text-theme fs-4'></i>vuejs</>},
  { value:'fab fa-lg fa-fw me-2 fa-weibo', label: <><i className='fab fa-lg fa-fw me-2 fa-weibo text-theme fs-4'></i>weibo</>},
  { value:'fab fa-lg fa-fw me-2 fa-weixin', label: <><i className='fab fa-lg fa-fw me-2 fa-weixin text-theme fs-4'></i>weixin</>},
  { value:'fab fa-lg fa-fw me-2 fa-whatsapp', label: <><i className='fab fa-lg fa-fw me-2 fa-whatsapp text-theme fs-4'></i>whatsapp</>},
  { value:'fab fa-lg fa-fw me-2 fa-whatsapp-square', label: <><i className='fab fa-lg fa-fw me-2 fa-whatsapp-square text-theme fs-4'></i>whatsapp-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-whmcs', label: <><i className='fab fa-lg fa-fw me-2 fa-whmcs text-theme fs-4'></i>whmcs</>},
  { value:'fab fa-lg fa-fw me-2 fa-wikipedia-w', label: <><i className='fab fa-lg fa-fw me-2 fa-wikipedia-w text-theme fs-4'></i>wikipedia-w</>},
  { value:'fab fa-lg fa-fw me-2 fa-windows', label: <><i className='fab fa-lg fa-fw me-2 fa-windows text-theme fs-4'></i>windows</>},
  { value:'fab fa-lg fa-fw me-2 fa-wordpress', label: <><i className='fab fa-lg fa-fw me-2 fa-wordpress text-theme fs-4'></i>wordpress</>},
  { value:'fab fa-lg fa-fw me-2 fa-wordpress-simple', label: <><i className='fab fa-lg fa-fw me-2 fa-wordpress-simple text-theme fs-4'></i>wordpress-simple</>},
  { value:'fab fa-lg fa-fw me-2 fa-wpbeginner', label: <><i className='fab fa-lg fa-fw me-2 fa-wpbeginner text-theme fs-4'></i>wpbeginner</>},
  { value:'fab fa-lg fa-fw me-2 fa-wpexplorer', label: <><i className='fab fa-lg fa-fw me-2 fa-wpexplorer text-theme fs-4'></i>wpexplorer</>},
  { value:'fab fa-lg fa-fw me-2 fa-wpforms', label: <><i className='fab fa-lg fa-fw me-2 fa-wpforms text-theme fs-4'></i>wpforms</>},
  { value:'fab fa-lg fa-fw me-2 fa-xbox', label: <><i className='fab fa-lg fa-fw me-2 fa-xbox text-theme fs-4'></i>xbox</>},
  { value:'fab fa-lg fa-fw me-2 fa-xing', label: <><i className='fab fa-lg fa-fw me-2 fa-xing text-theme fs-4'></i>xing</>},
  { value:'fab fa-lg fa-fw me-2 fa-xing-square', label: <><i className='fab fa-lg fa-fw me-2 fa-xing-square text-theme fs-4'></i>xing-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-y-combinator', label: <><i className='fab fa-lg fa-fw me-2 fa-y-combinator text-theme fs-4'></i>y-combinator</>},
  { value:'fab fa-lg fa-fw me-2 fa-yahoo', label: <><i className='fab fa-lg fa-fw me-2 fa-yahoo text-theme fs-4'></i>yahoo</>},
  { value:'fab fa-lg fa-fw me-2 fa-yandex', label: <><i className='fab fa-lg fa-fw me-2 fa-yandex text-theme fs-4'></i>yandex</>},
  { value:'fab fa-lg fa-fw me-2 fa-yandex-international', label: <><i className='fab fa-lg fa-fw me-2 fa-yandex-international text-theme fs-4'></i>yandex-international</>},
  { value:'fab fa-lg fa-fw me-2 fa-yelp', label: <><i className='fab fa-lg fa-fw me-2 fa-yelp text-theme fs-4'></i>yelp</>},
  { value:'fab fa-lg fa-fw me-2 fa-yoast', label: <><i className='fab fa-lg fa-fw me-2 fa-yoast text-theme fs-4'></i>yoast</>},
  { value:'fab fa-lg fa-fw me-2 fa-youtube', label: <><i className='fab fa-lg fa-fw me-2 fa-youtube text-theme fs-4'></i>youtube</>},
  
    
  ];