import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ntxAPI } from "../../API/axiosNeatex";

export const AddRequest = createAsyncThunk(
  "AmeRequest/AddRequest",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.post("AmeRequest/AddRequest", item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const GetRequestList = createAsyncThunk(
  "AmeRequest/GetRequestList",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      // const data = await getData("AmeRequest/GetRequestList" );
      const { data } = await ntxAPI.get("AmeRequest/GetRequestList");
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const GetTransactionHistoryWithValueById = createAsyncThunk(
  "AmeRequest/GetTransactionHistoryWithValueById",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.get(
        `AmeRequest/GetTransactionHistoryWithValueById?TransactionHistoryId=${id}`
      );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const ActionRequest = createAsyncThunk(
  "AmeRequest/ActionRequest",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    console.log(JSON.stringify(item));
    debugger
    try {
      const { data } = await ntxAPI.post("AmeRequest/ActionRequest", item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const GetMyRequest = createAsyncThunk(
  "AmeRequest/GetMyRequest",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      // const data = await getData("AmeRequest/GetMyRequests" );
      const { data } = await ntxAPI.get("AmeRequest/GetMyRequests");
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const GetRequestListByFilter = createAsyncThunk(
  "AmeRequest/GetRequestListByFilter",
  async (bodyFilter, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.post(
        "AmeRequest/GetRequestListByFeltr",
        bodyFilter
      );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// نماذج الطلبات مع الصلاحيات
export const GetDynamicFormAme = createAsyncThunk(
  "AmeRequest/GetDynamicFormAme",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.get("AmeRequest/GetDynamicFormList");
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  RequestList: [],
  MyRequestList: [],
  TransactionHistoryWithValueList: [],
  DynamicFormAme: [],
  loadingViewRequest: false,
  loading: false,
  error: null,
};

const AmeRequestSlice = createSlice({
  name: "AmeRequest",
  initialState,
  reducers: {
    cleanTransactionHistoryList: (state) => {
      state.TransactionHistoryWithValueList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Request list
      .addCase(GetRequestList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetRequestList.fulfilled, (state, action) => {
        state.loading = false;
        state.RequestList = action.payload;
      })
      .addCase(GetRequestList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // Get MyRequest list
      .addCase(GetMyRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetMyRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.MyRequestList = action.payload;
      })
      .addCase(GetMyRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // Get Transaction History With Value List
      .addCase(GetTransactionHistoryWithValueById.pending, (state) => {
        state.loadingViewRequest = true;
        state.error = null;
        state.TransactionHistoryWithValueList = [];
      })
      .addCase(GetTransactionHistoryWithValueById.fulfilled, (state, action) => {
        state.loadingViewRequest = false;
        state.TransactionHistoryWithValueList = action.payload;
      })
      .addCase(GetTransactionHistoryWithValueById.rejected, (state, action) => {
        state.loadingViewRequest = false;
        state.error = action.payload;
      })
  
      // Add Request
      .addCase(AddRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.RequestList.push(action.payload);
      })
      .addCase(AddRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // Get Dynamic Form Ame
      .addCase(GetDynamicFormAme.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.DynamicFormAme = [];
      })
      .addCase(GetDynamicFormAme.fulfilled, (state, action) => {
        state.loading = false;
        state.DynamicFormAme = action.payload;
      })
      .addCase(GetDynamicFormAme.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { cleanTransactionHistoryList } = AmeRequestSlice.actions;

export default AmeRequestSlice.reducer;
