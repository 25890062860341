import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
 
import { GetUom_Class } from "../../store/Lookup/lookupSlice";

const useLookupUomClass = ()=>{

    const dispatch = useDispatch();
    
    useEffect(()=>{
      if(UOM_CLASS?.length === 0){
        dispatch(GetUom_Class())
      }
      },[])

    const { UOM_CLASS } = useSelector((state) => state.Lookup); 

    return { UOM_CLASS } ;
};

export default useLookupUomClass ;