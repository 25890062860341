import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SwalAlert from '../../../components/Shared/Alert/SwalAlert';
import { Form } from 'react-bootstrap';
import useLookupGetCustomerType from '../../../hooks/Lookups/use-Lookup-CustomerType';
import { FormikProps } from 'formik';
import Select from 'react-select';

interface listModels {
  id: string;
}

interface Props {
  formik: FormikProps<any>;
}

const DiscountVouchersPeopleCategoryList = ({ formik }: Props) => {
  const { OptionsCustomerType, loading } = useLookupGetCustomerType();

  const { t, i18n } = useTranslation();

  const handleChange = (selectedOptions: any) => {
    const selectedItems = selectedOptions
      ? selectedOptions.map((option: any) => ({ peopleCategory: option.value }))
      : [];

    formik.setFieldValue('discountVouchersPeopleCategoryList', selectedItems);
  };

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{t('تصنيف العملاء')}</Form.Label>
        <Select
          classNamePrefix="react-select"
          // className="w-100 border border-theme p-0 text-center"
          className={`${
            formik.values.AllPeoplesAllPeopleCategory !== 'allPeopleCategory' &&
            'bg-inverse bg-opacity-10'
          } `}
          isDisabled={formik.values.AllPeoplesAllPeopleCategory !== 'allPeopleCategory'}

          isLoading={loading}
          isMulti={formik.values.voucherTypeId !== '1a0eb9fb-b164-4a60-a6d0-16008fcb43f8'}
          isSearchable={true}
          isClearable
          options={OptionsCustomerType}
          onChange={handleChange}
          value={formik.values.discountVouchersPeopleCategoryList.map((item: any) =>
            OptionsCustomerType?.find((option: any) => option.value === item.peopleCategory)
          )}
          placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
        />
      </Form.Group>
    </>
  );
};

export default memo(DiscountVouchersPeopleCategoryList);

//     <div className="mt-1">
//           <table className="table table-sm  table-hover text-nowrap ">
//             <tbody>
//               {list.map((item, index) => (
//                 <tr key={index}>
//                   <td>{getCustomerTypeNameById(item.id)}</td>
//                   <td className="ps-4" style={{ width: "5%" }}>
//                     <OverlayTrigger
//                       placement="left-end"
//                       overlay={
//                         <Tooltip id="button-tooltip-2">Delete</Tooltip>
//                       }
//                     >
//                       <Link
//                         to=""
//                         className="text-danger"
//                         onClick={() => handleDelete(item.id)}
//                       >
//                         <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
//                       </Link>
//                     </OverlayTrigger>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//           {/* <Pagination
//   filteredData={data}
//   dataPerPage={dataPerPage}
//   handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
//   currentPage={currentPage}
// /> */}
//         </div>
