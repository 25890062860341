import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";

// import { getData, postData } from "../../Axios/axiosData";

const initialState = {
  Payrolls: [],
  loading: false,
  error: null,
  Payroll: [],
  ElementLinkDeatail: [],
  PaymentMethodList:[],
};

export const addPayroll = createAsyncThunk(
  "Pyrolls/AddPayroll",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
 
    try {
      const {data} = await ntxAPI.post("Payroll/AddPayroll" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);


export const getPayrolls = createAsyncThunk(
  "Pyrolls/getPayrolls",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData("Payroll/GetPayrollList");
      const {data} = await ntxAPI.get("Payroll/GetPayrollList" );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetElementLinkDeatail = createAsyncThunk(
  "Pyrolls/GetElementLinkDeatail",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;


    try {
      // const data = await getData(`Payroll/GetElementLinkDetail?PayrollId=${id}`);
      const {data} = await ntxAPI.get(`Payroll/GetElementLinkDetail?PayrollId=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const AddElementLink = createAsyncThunk(
  "Pyrolls/AddElementLink",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
 

    try {
      // const data = await postData( "Payroll/AddElementLink", item);
      const {data} = await ntxAPI.post("Payroll/AddElementLink" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);



export const DeleteElementLink = createAsyncThunk(
  "Pyrolls/GetElementLinkDeatail",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData(`Payroll/DeleteElementLink?Id=${id}`);
      const {data} = await ntxAPI.post(`Payroll/DeleteElementLink?Id=${id}`)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);





export const GetPaymentMethod = createAsyncThunk(
  "Pyrolls/GetPaymentMethod",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData("Payroll/GetPaymentMethodList");
      const {data} = await ntxAPI.get("Payroll/GetPaymentMethodList" );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);



const PayrollSlice = createSlice({
  name: "Pyrolls",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /////// get all Payrolls
      .addCase(getPayrolls.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPayrolls.fulfilled, (state, action) => {
        state.loading = false;
        state.Payrolls = action.payload;
      })
      .addCase(getPayrolls.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      /////// get ElementLinkDetail
      .addCase(GetElementLinkDeatail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetElementLinkDeatail.fulfilled, (state, action) => {
        state.loading = false;
        state.ElementLinkDeatail = action.payload;
      })
      .addCase(GetElementLinkDeatail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      ////// AddElementLink
      .addCase(AddElementLink.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddElementLink.fulfilled, (state, action) => {
        state.loading = false;
        state.ElementLinkDeatail = action.payload;
      })
      .addCase(AddElementLink.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      /////// get PaymentMethodList
      .addCase(GetPaymentMethod.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetPaymentMethod.fulfilled, (state, action) => {
        state.loading = false;
        state.PaymentMethodList = action.payload;
      })
      .addCase(GetPaymentMethod.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      /////// Add all Payrolls
      .addCase(addPayroll.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addPayroll.fulfilled, (state, action) => {
        state.loading = false;
        state.Payrolls.push(action.payload);
      })
      .addCase(addPayroll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default PayrollSlice.reducer;
