import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../API/axiosNeatex";


export const AddContractLease = createAsyncThunk(
    "ContractLease/AddContractLease",
    async (item, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;

      try {
        // const data = await postData("RealState/AddContractLease" , item);
        const {data} = await ntxAPI.post("RealState/AddContractLease" , item)
        return data;
      } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
      }
    }
  );

  
export const GetContractLease = createAsyncThunk(
    "ContractLease/GetContractLease",
    async (_,thunkAPI) => {
      const { rejectWithValue } = thunkAPI;

      try {
        const {data} = await ntxAPI.get("RealState/GetContractLeaseList" );
        return data;
      } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
      }
    }
  );

export const GetContractLeaseById = createAsyncThunk(
    "ContractLease/GetContractLeaseById",
    async (id,thunkAPI) => {
      const { rejectWithValue } = thunkAPI;

      try {
        // const data = await getData(`RealState/GetContractLaseById?Id=${id}`);
        const {data} = await ntxAPI.get( `RealState/GetContractLaseById?Id=${id}`);
        return data;
      } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
      }
    }
  );


export const UpdateContractLease = createAsyncThunk(
    "ContractLease/UpdateContractLease",
    async (item, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;

      try {
        const {data} = await ntxAPI.post("RealState/UpdateContractLease" , item)
        return data;
      } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
      }
    }
  );
  




  const initialState = { ContractLeaseList: [] , ListContractLeaseById: [] ,loading: false, error: null };


const contractLeaseSlice = createSlice({
  name: "ContractLease",
  initialState,
  reducers: {  },
  extraReducers: (builder) => {
    builder
      // Get List
      .addCase(GetContractLease.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetContractLease.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.succeeded === true && payload?.data) {
          state.ContractLeaseList = payload.data;
        }
      })
      .addCase(GetContractLease.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // Get List By Id
      .addCase(GetContractLeaseById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetContractLeaseById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.ListContractLeaseById = payload.data;
      })
      .addCase(GetContractLeaseById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default contractLeaseSlice.reducer;
