import React from 'react'
import NtxPeoples from '../../../NTX/Peoples/NtxPeoples'
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';



const EditDrivers : React.FC  = () => {
//________________________________
var BcrumbList = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Drivers",
    link: '/FLEETSettings/Drivers',
  },
  {
    name: "EditDrivers",
    link: null,
  },
];
  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="EditDrivers" />

      <Card>
        <CardBody>

        <NtxPeoples typeCode='DRIVERS' isUpdate={true}/>

        </CardBody>
      </Card>
    </div>
  )
}

export default EditDrivers
