import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  GetDiscountVoucherList,
} from '../../../store/Sales/DiscountVouchers/DiscountVouchersSlice';

const useGetVoucherList = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (DiscountVouchersList?.length === 0) {
      dispatch(GetDiscountVoucherList());
    }
  }, []);
  const { DiscountVouchersList, loading , error} = useAppSelector((state) => state.DiscountVouchers);

  return { DiscountVouchersList, loading , error };
};

export default useGetVoucherList;
