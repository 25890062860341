import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
 
import { useParams } from "react-router-dom";
import { getOrganization , cleanOrganization } from "../../store/HR/Organizations/organizationSlice";

const useOrganizationDetails = ()=>{
    const { id } =  useParams();
   

    const dispatch = useDispatch();
    const { loading , error , Organization} = useSelector((state) => state.Organizations); 

    useEffect(() => {
      dispatch(cleanOrganization());
            dispatch(getOrganization(id));
        }, [dispatch , id]);

    return { loading , error , Organization } ;
};

export default useOrganizationDetails ;