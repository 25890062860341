import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getOrganizationWithPermissionByType } from '../../../store/HR/Organizations/organizationSlice';

const useGetOrganizationWithPermissionByType = (type) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrganizationWithPermissionByType(type));
  }, [dispatch, type]);
  const {
    OrganizationWithPermissionList: data,
    loading,
    error,
  } = useSelector((state) => state.Organizations);

  const DataOptions =
    data &&
    data?.map((item) => ({
      value: item.id,
      label: i18n.language === 'en' ? item.name : item.name2,
    }));

  return { data, DataOptions, loading, error };
};

export default useGetOrganizationWithPermissionByType;
