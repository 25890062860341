import React, { memo } from 'react';
import { Card, CardHeader, CardBody } from '../../../../../components/card/card.jsx';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../../../../Helper/Permisstions/usePermissions.ts';

const DataEmployees = ({ Employees }) => {
  const { HasPermission } = usePermissions();

  const [t, i18n] = useTranslation();

  return (
    <>
      <div className="row">
        {Employees &&
          Employees.map((item) => {
            const isTerminated = item.endOfServiceDate !== null;
            var orgName = i18n.language === 'ar' ? item.organizatioN_NAME2 : item.organizatioN_NAME;
            return (
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12   pb-3" key={item.id}>
                <Card className="m-0" key={item.id}>
                  <CardHeader className="fw-bold small bg-theme bg-opacity-25">
                    {i18n.language === 'ar' ? item.name2 : item.name} - {item.employeeNumber}
                  </CardHeader>
                  <CardBody>
                    <div className="flex-fill">
                      <div className="d-flex align-items-center">
                        <h6>
                          <i className="fas fa-lg fa-fw me-2 fa-id-card"></i>
                          {item.nationalIdentifier}
                        </h6>
                        <div className="dropdown dropdown-icon ms-auto text-center">
                          <Link
                            href="#/"
                            className="text-inverse text-opacity-50"
                            data-bs-toggle="dropdown">
                            <i className="fa fa-ellipsis-h"></i>
                          </Link>
                          <div className="dropdown-menu dropdown-menu-end">
                            {HasPermission('DetailsHrPerson') && (
                              <Link to={`${item.id}/Edit`} className="dropdown-item text-start">
                                  <i className="fa fa-user fa-fw me-2 text-theme"></i>
                                {t('EmployeeDetails')}
                              </Link>
                            )}

                            {HasPermission('ViewdetailsHrPerAssignment') && (
                              <a
                                href={`/HR/Employees/${item.id}/Assignments`}
                                className="dropdown-item text-start">
                                  <i className="fa fa-clipboard-list fa-fw me-2 text-theme"></i>
                                {t('Assign')}
                              </a>
                            )}

                            {HasPermission('ViewlistHrPerAbsence') && (
                              <Link to={`absence/${item.id}`} className="dropdown-item text-start">
                                <i className="fa fa-calendar-times fa-fw me-2 text-theme"></i>
                                {t('Button.Absence')}
                              </Link>
                            )}

                            {HasPermission('ExtraInformationHrPerson') && (
                              <Link
                                to={`${item.id}/ExtraRecordFormPerson`}
                                className="dropdown-item text-start"
                                state={{
                                  ReferenceName: i18n.language === 'en' ? item.name : item.name2,
                                }}>
                                  <i className="fa fa-info-circle fa-fw me-2 text-theme"></i>
                                {t('ExtraInformation')}
                              </Link>
                            )}

                            {HasPermission('AddPeriodOfService') && (
                              <Link
                                to={`EndOfService/${item.id}`}
                                className="dropdown-item text-start"
                                state={{
                                  ReferenceName: i18n.language === 'en' ? item.name : item.name2,
                                }}>
                                <i className="fa fa-user-slash  fa-fw me-2 text-theme "></i>
                                {t('EndOfService')}
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-center bg-dark-transparent-5 mb-2">
                      {item?.personImage === null ? (
                        <img
                          src={
                            item.gender === 'M'
                              ? '../../../assets/img/user/manEmp.png'
                              : '../../../assets/img/user/WomanEmp.png'
                          }
                          alt=""
                          width={120}
                          height={120}
                          className="rounded-circle"
                          loading="lazy"
                        />
                      ) : (
                        <img
                          src={item?.personImage?.filePath}
                          alt=""
                          width={120}
                          height={120}
                          className="rounded-circle"
                          loading="lazy"
                        />
                      )}
                    </div>

                    <div className="d-flex justify-content-between">
                      {item.organizatioN_ID !== null ? (
                        <h6>{orgName}</h6>
                      ) : (
                        <span className="badge bg-warning pt-1">{t('NotAssigned')}</span>
                      )}

                      {isTerminated && (
                        <span className="badge bg-danger pt-2">{t('Terminated')}</span>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default memo(DataEmployees);




// <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 pb-3" key={item.id}>
// <Card className="m-0" key={item.id}>
//   <CardHeader className="fw-bold small bg-theme bg-opacity-25">
//     {i18n.language === 'ar' ? item.name2 : item.name} - {item.employeeNumber}
//   </CardHeader>
//   <CardBody>
//     <div className="flex-fill">
//       <div className="d-flex align-items-center">
//         <h6>
//           <i className="fas fa-lg fa-fw me-2 fa-id-card"></i>
//           {item.nationalIdentifier}
//         </h6>
//         <div className="dropdown dropdown-icon ms-auto text-center">
//           <Link
//             href="#/"
//             className="text-inverse text-opacity-50"
//             data-bs-toggle="dropdown">
//             <i className="fa fa-ellipsis-h"></i>
//           </Link>
//           <div className="dropdown-menu dropdown-menu-end">
//             {HasPermission('DetailsHrPerson') && (
//               <Link to={`${item.id}/Edit`} className="dropdown-item text-start">
//                   <i className="fa fa-user fa-fw me-2 text-theme"></i>
//                 {t('EmployeeDetails')}
//               </Link>
//             )}

//             {HasPermission('ViewdetailsHrPerAssignment') && (
//               <a
//                 href={`/HR/Employees/${item.id}/Assignments`}
//                 className="dropdown-item text-start">
//                   <i className="fa fa-clipboard-list fa-fw me-2 text-theme"></i>
//                 {t('Assign')}
//               </a>
//             )}

//             {HasPermission('ViewlistHrPerAbsence') && (
//               <Link to={`absence/${item.id}`} className="dropdown-item text-start">
//                 <i className="fa fa-calendar-times fa-fw me-2 text-theme"></i>
//                 {t('Button.Absence')}
//               </Link>
//             )}

//             {HasPermission('ExtraInformationHrPerson') && (
//               <Link
//                 to={`${item.id}/ExtraRecordFormPerson`}
//                 className="dropdown-item text-start"
//                 state={{
//                   ReferenceName: i18n.language === 'en' ? item.name : item.name2,
//                 }}>
//                   <i className="fa fa-info-circle fa-fw me-2 text-theme"></i>
//                 {t('ExtraInformation')}
//               </Link>
//             )}

//             {HasPermission('AddPeriodOfService') && (
//               <Link
//                 to={`EndOfService/${item.id}`}
//                 className="dropdown-item text-start"
//                 state={{
//                   ReferenceName: i18n.language === 'en' ? item.name : item.name2,
//                 }}>
//                 <i className="fa fa-user-slash  fa-fw me-2 text-theme "></i>
//                 {t('EndOfService')}
//               </Link>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>

//     <div className="text-center bg-dark-transparent-5 mb-2">
//       {item?.personImage === null ? (
//         <img
//           src={
//             item.gender === 'M'
//               ? '../../../assets/img/user/manEmp.png'
//               : '../../../assets/img/user/WomanEmp.png'
//           }
//           alt=""
//           width={120}
//           height={120}
//           className="rounded-circle"
//           loading="lazy"
//         />
//       ) : (
//         <img
//           src={item?.personImage?.filePath}
//           alt=""
//           width={120}
//           height={120}
//           className="rounded-circle"
//           loading="lazy"
//         />
//       )}
//     </div>

//     <div className="d-flex justify-content-between">
//       {item.organizatioN_ID !== null ? (
//         <h6>{orgName}</h6>
//       ) : (
//         <span className="badge bg-warning pt-1">{t('NotAssigned')}</span>
//       )}

//       {isTerminated && (
//         <span className="badge bg-danger pt-2">{t('Terminated')}</span>
//       )}
//     </div>
//   </CardBody>
// </Card>
// </div>
