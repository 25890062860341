import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPosition } from "../../store/HR/HRSetting/positionSlice";

const usePositionDetails = ()=>{
    const { id } =  useParams();

    const dispatch = useDispatch();
    const { loading , error , Position} = useSelector((state) => state.Positions); 

    useEffect(() => {
          if(!Position?.length){
            dispatch(getPosition(id));
          } 
        }, [dispatch , id]);

    return { loading , error , Position } ;
};

export default  usePositionDetails ;