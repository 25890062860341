import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import {
  DiscountVouchersModels,
  VoucherTypesModels,
} from "./DiscountVouchersModels";

export const AddDiscountVouchers = createAsyncThunk(
  "DiscountVouchers/AddDiscountVouchers",
  async (item: any, { rejectWithValue }) => {
    console.log(JSON.stringify(item));
    
    try {
      const { data } = await ntxAPI.post("Sals/AddDiscountVouchers", item);
       console.log(JSON.stringify(data));
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetDiscountVouchersById = createAsyncThunk(
  "DiscountVouchers/GetDiscountVouchersById",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(
        `Sals/GetDiscountVouchersById?Id=56D3B644-8341-4CEF-84F2-313A6EFC33F9`
      );
      if (data?.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data?.message || "هناك خطأ");
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetDiscountVoucherTypesList = createAsyncThunk(
  "DiscountVouchers/GetDiscountVoucherTypesList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get("Sals/GetDiscountVoucherTypesList");
      if (data?.succeeded) {
        //===
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const GetDiscountVoucherList = createAsyncThunk(
  "DiscountVouchers/GetDiscountVoucherList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get("Sals/GetDiscountVouchersList");
      if (data?.succeeded) {
        //===
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);



const initialState: {
  DiscountVouchersList: DiscountVouchersModels[];
  DiscountVouchersById: DiscountVouchersModels;
  DiscountVoucherTypesList: VoucherTypesModels[];
  loading: boolean;
  error: any | null;
} = {
  DiscountVouchersList: [],
  DiscountVouchersById: {} as DiscountVouchersModels,
  DiscountVoucherTypesList: [],
  loading: false,
  error: null,
};

const DiscountVouchersSlice = createSlice({
  name: "DiscountVouchers",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder


      .addCase(GetDiscountVoucherTypesList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetDiscountVoucherTypesList.fulfilled,
        (state, action: PayloadAction<VoucherTypesModels[]>) => {
          state.loading = false;
          state.DiscountVoucherTypesList = action.payload;
        }
      )
      .addCase(
        GetDiscountVoucherTypesList.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )


      .addCase(GetDiscountVoucherList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetDiscountVoucherList.fulfilled,
        (state, action: PayloadAction<DiscountVouchersModels[]>) => {
          state.loading = false;
          state.DiscountVouchersList = action.payload;
        }
      )
      .addCase(
        GetDiscountVoucherList.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )

      //________________________________________________________________
      .addCase(GetDiscountVouchersById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetDiscountVouchersById.fulfilled,
        (state, action: PayloadAction<DiscountVouchersModels>) => {
          state.loading = false;
          state.DiscountVouchersById = action.payload;
        }
      )
      .addCase(
        GetDiscountVouchersById.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default DiscountVouchersSlice.reducer;
