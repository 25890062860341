import React from 'react';

import DashboardByModule from '../../NTX/DashboardManagament/DashboardListByModule/DashboardByModule';
import useGetDashboardWithPermission from '../../../hooks/NTX/Dashboard/useGetDashboardWithPermission';

//________________ Start Components
const DashboardHR: React.FC = () => {
  const { DashboardWithPermission, loading, error } = useGetDashboardWithPermission();

  const dashboardListByModule = DashboardWithPermission?.filter(
    (f) => f?.moduleId?.toLowerCase() === '0a6c6b89-1d52-4eaa-8d2c-010c74b1f6e0'
    // (f) => f?.moduleId === null
  );

  return (
    <div>
      <DashboardByModule dashboardList={dashboardListByModule} />
    </div>
  );
};

export default DashboardHR;
