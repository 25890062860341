import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  DeleteAmeActionRule,
  GetAmeActionRule,
} from "../../../../store/Ame/ameActionRuleSlice";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import showConfirmation from "../../../../components/Shared/Alert/Confirmation";

const ActionRuleList = ({ data }) => {
  const { ruleId } = useParams();

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  // Handele Delete Action Rule
  const handleDeleteActionRule = (item) => {
    showConfirmation(t('MsgConfirm'), () => {
      dispatch(DeleteAmeActionRule(item.id))
        .unwrap()
        .then(() => {
          dispatch(GetAmeActionRule(ruleId));
          CustomAlert({ action: "Add" });
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: "Error" });
        });
    });
  };

  return (
    <>
    {
      data.length > 0 &&
    
      <div className="table-responsive rounded-2 text-center border border-theme">
        <table className="table table-hover mb-0">
          <thead className="bg-theme bg-opacity-60">
            <tr>
              <th scope="col"> {t("Name")}</th>
              <th scope="col"> {t("Order")}</th>
              <th scope="col"></th>
            </tr>
          </thead>

          <tbody>
            {data.map((item, idx) => (
              <tr key={item.id}>
                <td className="align-middle">
                  {i18n.language === "ar" ? item.name2 : item.name}
                 
                </td>
                <td className="align-middle">{item.order}</td>
                <td className="align-middle">
  

                  <button
                    type="button"
                    className="btn btn-outline-danger btn-lg m-0"
                    onClick={() => handleDeleteActionRule(item)}
                  >
                    <i className="fas fa-trash-alt fa-fw fa-lg"></i>
                  </button>
 
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      }
    </>
  );
};

export default ActionRuleList;
