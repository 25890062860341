import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";

import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { Link } from "react-router-dom";
import useGetItemsList from "../../../../hooks/INV/MasterItems/useGetItemsList";
// import { TransactionsLinesList } from "../../../../store/Sales/Transactions/TransactionsMolels";
import useGetUnitsOfMeasureList from "../../../../hooks/INV/useGetUnitsOfMeasureList";
// import { TransactionsLinesList } from "../../../../store/Sales/SalesInvoices/SalesInvoicesMolels";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { GetItemConversionList } from "../../../../store/INV/MasterItems/ItemConversionSlice";
import SwalAlert from "../../../../components/Shared/Alert/SwalAlert";
import useGetItemConversionList from "../../../../hooks/INV/ItemConversion/useGetItemConversionList";
// import { MaterialTransactionsLineList } from "../../../../store/INV/MaterialTransactions/MaterialTransactionsModels";

interface LinesProps {
  formik?: FormikProps<any>;
  isUpdate?: boolean;
}

interface LinsState {
  itemId: any;
  transactionUom: string;
  price: number;
  Quantity: number;
  total?: number;
}

const TransactionsInvLines: React.FC<LinesProps> = ({ formik }) => {
  const [Line, setLine] = useState<LinsState>({
    itemId: "",
    price: 0,
    Quantity: 1,
    transactionUom: "",
  });
  const { i18n, t } = useTranslation();
  const { ItemsOptions, MasterItemsList } = useGetItemsList();
  const { UnitsOfMeasureList } = useGetUnitsOfMeasureList();

  
 
  const { ItemConversionList, loading: loading2, error: error2 } = useGetItemConversionList();

  //

  var UnitsByItemId = ItemConversionList.filter(
    (f) =>
      f.itemId === Line?.itemId ||
      f.uom ===
        MasterItemsList.find((a) => a?.id === Line?.itemId)?.unitOfMeasure
  );

  console.log(formik?.values);

  //______________________________________________________

  //#region Calculate the total debit and credit amounts

  //#endregion

  //#region handleLine Change Add Delete

  const handleAddLine = () => {
    if (
      !(
        Line &&
        Line?.itemId &&
        Line?.transactionUom &&
        Line?.price &&
        Line?.Quantity
      )
    ) {
      SwalAlert({ text: "Please fill all fields before adding" });
      return;
    }

    formik?.setFieldValue("materialTransactionsLineList", [
      ...formik.values.materialTransactionsLineList,
      { ...Line },
    ]);

    // clear Line after adding line
    setLine({
      itemId: null,
      transactionUom: "",
      price: 0,
      Quantity: 1,
      // total: 0,
    });
  };

  const handleDeleteLine = async (id: any) => {
    const updatedLines = formik?.values?.materialTransactionsLineList?.filter(
      (f: any) => f.itemId !== id
    );

    await formik?.setFieldValue("materialTransactionsLineList", updatedLines);
  };

  //#endregion

  // function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
  //   const allowedChars = /^[0-9.]+$/;
  //   const target = event.target as HTMLInputElement;

  //   if (
  //     !allowedChars.test(event.key) ||
  //     (event.key === "." && target.value.includes("."))
  //   ) {
  //     event.preventDefault();
  //   }
  // }

  const handlechange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name === "Quantity" && Number(value) <= 0) {
      return;
    }

    if (name === "price" ||  name === "Quantity" ) {
      setLine((prevLine) => ({
        ...prevLine,
        [name]: Number(value),
      }));
    } else {
      setLine((prevLine) => ({
        ...prevLine,
        [name]: value,
      }));
    }
  };

  const sumPrice = () => {
    let sum = 0;
    formik?.values?.materialTransactionsLineList?.forEach((item: any) => {
      sum += Number(item.price);
    });
    return sum;
  };

  return (
    <div className="mb-4">
      <div className="row  ps-1">
        <div className="row">
          <div className="col-lg-5">
            <div className="input-group flex-nowrap">
              <span className="input-group-text">{t("أسم الصنف")}</span>
              <Select
                classNamePrefix="react-select"
                className="w-100"
                // isLoading={loading}
                isSearchable={true}
                isClearable
                options={ItemsOptions}
                value={
                  Line?.itemId === null
                    ? null
                    : ItemsOptions?.find(
                        (option) => option.value === Line?.itemId
                      )
                }
                onChange={(option) => {
                  setLine({
                    ...Line,
                    itemId: option === null ? null : option.value,
                  });
                }}
                placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
              />
            </div>
          </div>

          <div className="col-lg-3">
            <div className="input-group flex-nowrap">
              <span className="input-group-text">{t("الوحدة")}</span>
              <Form.Select
                className="form-select"
                name="transactionUom"
                value={Line?.transactionUom || ""}
                onChange={(e) =>
                  setLine({ ...Line, transactionUom: e.target.value })
                }
              >
                <option value="">
                  {i18n.language === "ar" ? "إختر" : "Choose"}
                </option>
                {UnitsByItemId &&
                  UnitsByItemId?.map((item, idx) => (
                    <option key={++idx} value={item.uom}>
                      {i18n.language === "en" ? item.uomName : item.uomName2}
                    </option>
                  ))}
              </Form.Select>
            </div>
          </div>

          <div className="col-lg-2">
            <div className="input-group flex-nowrap">
              <span className="input-group-text">{t("الكمية")}</span>
              <Form.Control
                className="form-control text-center"
                type="number"
                name="Quantity"
                value={Line?.Quantity}
                onChange={(e) => handlechange(e)}
              />
            </div>
          </div>

          <div className="col-lg-2">
            <div className="input-group flex-nowrap">
              <span className="input-group-text">{t("سعر الوحدة")} </span>
              <Form.Control
                autoComplete="off"
                className="form-control  text-center"
                type="text"
                name="price"
                value={Line?.price || ""}
                onChange={(e) => handlechange(e)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row my-4">
        <div className="col-lg-12 text-end pe-4">
          <button
            type="button"
            className="btn btn-lg btn-theme "
            onClick={() => handleAddLine()}
          >
            <i className="bi bi-plus-square-dotted fa-lg"></i>
          </button>
        </div>
      </div>

      {formik?.values?.materialTransactionsLineList?.length > 0 && (
        <Fragment>
          <div className="bg-inverse bg-opacity-10 rounded-3 ">
            <div className="table-responsive rounded-2 text-center border border-theme">
              <table className="table  table-bordered mb-1px text-nowrap">
                <thead className="bg-theme bg-opacity-60">
                  <tr>
                    {/* <th>#</th> */}
                    <th className="w-auto">{t("رقم الصنف")}</th>
                    <th>{t("اسم الصنف")}</th>
                    <th>{t("الوحدة")}</th>
                    <th>{t("الكمية")}</th>
                    <th>{t("السعر")}</th>

                    <th style={{ width: "5%" }}></th>
                  </tr>
                </thead>
                <tbody className="fw-bold">
                  {formik?.values.materialTransactionsLineList.map(
                    (item: any, idx: number) => (
                      <tr key={++idx}>
                        {/* <th scope="row" className="bg-theme bg-opacity-25">
                          {++idx}
                        </th> */}

                        <td className="w-auto">
                          {
                            ItemsOptions.find((f) => f.value === item.itemId)
                              ?.itemNo
                          }
                        </td>
                        <td>
                          {
                            ItemsOptions.find((f) => f.value === item.itemId)
                              ?.itemName
                          }
                        </td>
                        <td>
                          {
                            UnitsOfMeasureList.find(
                              (f) => f.id === item.transactionUom
                            )?.name2
                          }
                        </td>
                        <td>{item.Quantity}</td>
                        <td>{item.price}</td>

                        <td style={{ width: "5%" }}>
                          <Link
                            to=""
                            className="text-center text-danger"
                            onClick={() => handleDeleteLine(item.itemId)}
                          >
                            <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
                          </Link>
                        </td>
                      </tr>
                    )
                  )}
                  <tr>
                    <td colSpan={4}></td>
                    {/* <td>Total:</td> */}
                    <td>{sumPrice()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default TransactionsInvLines;
