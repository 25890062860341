import React, { useState } from 'react';
import MedModalComponent from '../../../../components/Modal/MedModalComponent';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
 import { useTranslation } from 'react-i18next';
 import { Link } from 'react-router-dom';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import AddDailyRates from './AddDailyRates';
// import useLookupCurrency from '../../../hooks/Lookups/use-Lookup-Currency';
import useGetGlDailyRatesList from '../../../../hooks/Finance/useGetGlDailyRatesList';
import EditDailyRates from './EditDailyRates';
import Loading from '../../../../components/Shared/Loader/Loading';

const Index = () => {
  const [ID, setID] = useState<string>('');

  const [Show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t } = useTranslation();
  const { DailyRatesList, loading, error } = useGetGlDailyRatesList();

  const filteredSearch = DailyRatesList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return item.fromCurrencyCode?.toLowerCase().includes(searchLowerCase);
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'المعدل اليومي للعملات',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="المعدل اليومي للعملات" />

        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-outline-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAddDailyRates"
            onClick={() => setShow(true)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            اضافة
          </Link>
        </div>
      </div>

      <Card>
         <Loading loading={loading} error={error}>
          <CardBody>
            <div className="tab-content p-1">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-2">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder={t('Search') + ' ...'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-hover text-nowrap ">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t('من')}</th>
                      <th scope="col">{t('to')}</th>
                      <th scope="col">{t('المعدل')}</th>
                      <th scope="col">{t('Currency')}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle"> {item.conversionStartDate} </td>
                        <td className="align-middle"> {item.conversionEndDate} </td>
                        <td className="align-middle"> {item.conversionRate} </td>
                        <td className="align-middle">
                          {item.toCurrencyCode}{' '}
                          <i className="bi bi-arrow-left fa-lg text-theme"></i>{' '}
                          {item.fromCurrencyCode}
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-outline-theme"
                            data-bs-toggle="modal"
                            data-bs-target="#modalEditDailyRates"
                            onClick={() => setID(item.id)}>
                            <i className="far fa-lg fa-fw fa-edit"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </CardBody>
        </Loading>
      </Card>

      <MedModalComponent title="اضافة" id="modalAddDailyRates">
        <AddDailyRates />
      </MedModalComponent>

      <MedModalComponent title="تعديل" id="modalEditDailyRates">
        {ID && <EditDailyRates ID={ID} />}
      </MedModalComponent>
    </div>
  );
};

export default Index;
