import { useEffect } from "react";
import { useAppDispatch , useAppSelector } from "../../../store/hooks";
import { GetDiscountVoucherTypesList } from "../../../store/Sales/DiscountVouchers/DiscountVouchersSlice";
 

const useGetDiscountVoucherTypesList = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (DiscountVoucherTypesList?.length === 0) {
      dispatch(GetDiscountVoucherTypesList());
    }
  }, []);
  const { DiscountVoucherTypesList, loading } = useAppSelector(
    (state) => state.DiscountVouchers
  );


  return { DiscountVoucherTypesList  , loading };
};

export default useGetDiscountVoucherTypesList;
