import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getPeriodOfServiceDate } from '../../../../store/HR/Person/EndOfService/endofserviceSlice';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type Props = {
  personId: string;
  formik: any;
  isTerminated: boolean;
};

const CalculationOfServicePeriod = ({ personId, isTerminated, formik }: Props) => {
  const dispatch = useAppDispatch();
 const {t , i18n} = useTranslation()
  const { stardDate } = useAppSelector((state) => state.EndOfService);

  const { PeriodOfServiceDate: Period, loading2 } = useAppSelector((state) => state.EndOfService);

  const handleDateChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const endDate = event.target.value;

    formik.setFieldValue('actualTerminationDate', event.target.value);
    try {
      await dispatch(getPeriodOfServiceDate({ personId, endDate })).unwrap();
    } catch (error) {
      console.error('Failed to calculate period of service:', error);
    }
  };

  return (
    <>
      <div className="col-xl-8 m-auto">
        <div className="table-responsive rounded-2 text-center border border-theme ">
          <table className="table m-0  table-hover  table-bordered ">
            <thead>
              <tr>
                <th className="align-middle" style={{ fontSize: '12px' }} colSpan={1}>
                  <span>{t('ServiceStartDate')}</span>
                </th>
                <th style={{ fontSize: '12px' }} colSpan={2}>
                  <span className="text-theme fs-5">{stardDate}</span>
                </th>
              </tr>
            </thead>

            <thead>
              <tr>
                <th className="align-middle" style={{ fontSize: '12px' }} colSpan={1}>
                  <span>{t('ServiceEndDate')}</span>
                </th>
                <th style={{ fontSize: '12px' }} colSpan={2}>
                  <Form.Control
                    type="date"
                    className={
                      !isTerminated
                        ? 'form-control text-theme text-center rounded-5 fs-5 '
                        : 'form-control text-theme text-center rounded-5 fs-5 bg-red bg-opacity-15'
                    }
                    onChange={handleDateChange}
                    name="actualTerminationDate"
                    value={formik.values.actualTerminationDate}
                    disabled={isTerminated}
                    // onChange={formik.handleChange}
                    isInvalid={
                      !!(
                        formik.touched.actualTerminationDate &&
                        formik.errors.actualTerminationDate
                      )
                    }
                  />
                  <p className='small text-start ps-4 pt-1 m-0   '>{formik.errors.actualTerminationDate}</p>
                </th>
              </tr>
            </thead>

            <tbody className="text-center">
              <tr className={!isTerminated ? 'bg-inverse bg-opacity-10' : 'bg-red bg-opacity-15'}>
                <td className="align-middle">
                  {loading2 ? (
                    <div className="spinner-grow spinner-grow-sm"></div>
                  ) : (
                    <h3 className="  m-0">{Period.totalYears}</h3>
                  )}
                </td>
                <td className="align-middle">
                  {loading2 ? (
                    <div className="spinner-grow spinner-grow-sm"></div>
                  ) : (
                    <h3 className="  m-0">{Period.totalMonths}</h3>
                  )}
                </td>
                <td className="align-middle">
                  {loading2 ? (
                    <div className="spinner-grow spinner-grow-sm"></div>
                  ) : (
                    <h3 className="  m-0">{Period.totalDays}</h3>
                  )}
                </td>
              </tr>
            </tbody>

            <tfoot className="text-center">
              <tr className=" ">
                <th style={{ fontSize: '12px' }}>{t('TotalYears')}</th>
                <th style={{ fontSize: '12px' }}>{t('TotalMonths')}</th>
                <th style={{ fontSize: '12px' }}>{t('TotalDays')}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
};

export default CalculationOfServicePeriod;
