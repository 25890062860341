import React from "react";
import NtxPeoples from "../../../../NTX/Peoples/NtxPeoples";
import { useParams } from "react-router-dom";

const AddBranchesCustomer = () => {
  var fieldList = [
    "typeCode",
    "customerNumber",
    "personId",
    "billToLocationId",
    "shipToLocationId",
    "termsId", // الشروط للعملاء . لها جدول محدد
    "setOfBooksId", // جدول الاستاذ العام
    "creditLimit", // سقف الدين
    "paymentPriority", // أولوية الدفع لووكآب
    "invoiceCurrencyCode", //
    "paymentCurrencyCode", //
    "invoiceAmountLimit", // حساب العملاء من الحسابات
    "bankAccountName", // 
    "bankAccountNum", // 
    "vatCode", //  الرقم الضريبي للعميل
    "tradingName", // الاسم التجاري للعميل
    "workReference", // اسم الشخص
    "companyRegistrationNumber", // السجل التجاري للعميل
    'accountId',

  ];
  const {id} = useParams();

 
  

  return (
    <div>
      <NtxPeoples typeCode="SUPPLIERS" isUpdate={false} fieldList={fieldList} parentId={id}  />
    </div>
  );
};

export default AddBranchesCustomer;
