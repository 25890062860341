import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
 



//_________________________________________
export const getEmployees = createAsyncThunk(
  "Employees/getEmployees",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.get("Persons/GetPersonList");
      if(data?.succeeded === true) {
        return data?.data;
      } else {
        return []
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);


export const GetPersonLookup = createAsyncThunk(
  "Employees/GetPersonLookup",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.get("Persons/GetPersonLookup");
      if(data?.succeeded === true) {
        return data?.data;
      } else {
        return []
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getEmployee = createAsyncThunk(
  "Employees/getEmployee",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.get(`Persons/GetPerson?guid=${id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const insertEmployee = createAsyncThunk(
  "Employees/insertEmployee",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.post("Persons/AddPerson", item);
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const editEmployee = createAsyncThunk(
  "Employees/editEmployee",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.post(`Persons/UpdatePerson`, item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);



// export const getStardDate = createSelector(
//   (state) => state.Employees.Employees,
//   (state, PersonId) => PersonId,
//   (Employees, PersonId) => {
//     console.log(PersonId);
//     const employee = Employees.find(emp => emp.PersonId === PersonId);
//     return employee ? employee.stardDate : null;
//   }
// );

const initialState = {
  Employees: [],
  PersonList: [],
  loading: false,
  error: null,
  Employee: {},

};

// const persistConfig = {
//   key: "root", // The key under which your state will be saved in local storage
//   storage, // The storage engine (localStorage by default)
//   // whitelist: ["Employees"], // An array of reducer names to persist
// };

const employeeSlice = createSlice({
  name: "Employees",
  initialState,
  reducers: {
    cleanPersonById: (state) => {
      state.Employee = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // Get all employees
      .addCase(getEmployees.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEmployees.fulfilled, (state, action) => {
        state.loading = false;
        state.Employees = action.payload;
      })
      .addCase(getEmployees.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // Get one employee by ID
      .addCase(getEmployee.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.Employee = {};
      })
      .addCase(getEmployee.fulfilled, (state, action) => {
        state.loading = false;
        state.Employee = action.payload.data;
      })
      .addCase(getEmployee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // Insert employee
      .addCase(insertEmployee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(insertEmployee.fulfilled, (state, action) => {
        state.loading = false;
        state.Employees.push(action.payload.data);
      })
      .addCase(insertEmployee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // Edit employee
      .addCase(editEmployee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editEmployee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // Get person lookup
      .addCase(GetPersonLookup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetPersonLookup.fulfilled, (state, action) => {
        state.loading = false;
        state.PersonList = action.payload;
      })
      .addCase(GetPersonLookup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { cleanPersonById } = employeeSlice.actions;
export default employeeSlice.reducer;
  

