import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
 
import { getPurchaseQuotationById , cleanUpPurchaseQuotationById } from '../../../store/purchases/PurchaseQuotation/purchaseQuotationSlice';

interface Props {
  id: string | undefined;
}

const useGetPurchaseQuotationById = ({ id }: Props) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getPurchaseQuotationById(id));
    }
    return () => {
      dispatch(cleanUpPurchaseQuotationById());
    };
  }, [dispatch, id]);
  const { PurchaseQuotationById, loading, error } = useAppSelector((state) => state.PurchaseQuotation);

  return { PurchaseQuotationById, loading, error };
};

export default useGetPurchaseQuotationById;
