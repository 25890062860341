import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DashboardDetails, DashboardModels } from './dashboardModels';
import { ntxAPI } from '../../../../API/axiosNeatex';

export const AddDashboard = createAsyncThunk(
  'DashBoard/AddDashboard',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Reports/AddDashboard', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdateDashboard = createAsyncThunk(
  'DashBoard/UpdateDashboard',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Reports/UpdateDashboard', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdateDashboardChartPosition = createAsyncThunk(
  'DashBoard/UpdateDashboardChartPosition',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Reports/UpdateDashboardChartPosition', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetDashboardsList = createAsyncThunk(
  'DashBoard/GetDashboardsList',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.get('Reports/GetDashboardsList');
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetDashboardDetails = createAsyncThunk(
  'DashBoard/GetDashboardDetail',
  async (id: string, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.get(`Reports/GetDashboardDetail?DashboardId=${id}`);
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetDashboardWithPermission = createAsyncThunk(
  'DashBoard/GetDashboardWithPermission',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.get('Reports/GetDashboardWithPermission');
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  DashBoardList: DashboardModels[];
  DashboardWithPermission: DashboardModels[];
  DashboardDetailsById: DashboardDetails;
  loading: boolean;
  error: any | null;
} = {
  DashBoardList: [],
  DashboardWithPermission: [],
  DashboardDetailsById: {} as DashboardDetails,
  loading: false,
  error: null,
};

const dashboardSlice = createSlice({
  name: 'DashBoard',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(AddDashboard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddDashboard.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.DashBoardList.push(action.payload.data);
      })
      .addCase(AddDashboard.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(GetDashboardsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetDashboardsList.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.DashBoardList = action.payload;
      })
      .addCase(GetDashboardsList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


      .addCase(GetDashboardWithPermission.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetDashboardWithPermission.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.DashboardWithPermission = action.payload;
      })
      .addCase(GetDashboardWithPermission.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })



      .addCase(GetDashboardDetails.pending, (state, action: PayloadAction<any>) => {
        state.loading = true;
        state.DashboardDetailsById = state.DashboardDetailsById = {} as DashboardDetails;
      })
      .addCase(GetDashboardDetails.fulfilled, (state, action: PayloadAction<DashboardDetails>) => {
        state.loading = false;
        state.DashboardDetailsById = action.payload;
      })
      .addCase(GetDashboardDetails.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default dashboardSlice.reducer;
