/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card } from '../../../../../components/card/card';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { AppSettings } from '../../../../../config/app-settings';
import Loading from '../../../../../components/Shared/Loader/Loading';

interface Props {
  ReportsList: any[];
  loading?: boolean;
}

const Index: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const context = useContext(AppSettings);

  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('Reports'),
      link: null,
    },
  ];

  useEffect(() => {
    context.setAppContentFullHeight(true);
    return function cleanUp() {
      context.setAppContentFullHeight(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('Reports')} />

      <Loading loading={props.loading || false}>
        <div className="row px-3">
          {props.ReportsList?.map((item) => (
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={item.id}>
              <div className="card-conditions d-flex flex-column justify-content-between">
                {/* <div className=''> */}
                <h6 className="text-center m-0">{item.name}</h6>
                {/* </div> */}

                <div className="d-flex  justify-content-between align-items-center">
                  <button
                    type="button"
                    onClick={() => Navigate(`${item.id}/ReportsBuilder`)}
                    className=" btn btn-outline-theme fw-bold">
                    {t('عرض التقرير')}
                  </button>
                  <i
                    className={
                      item.icon
                        ? item.icon.replace('fs-1 fa-lg fa-fw me-2', '')  
                        : 'fas fs-1 fa-lg fa-fw me-2 bi bi-file-earmark-bar-graph-fill '
                    }></i>
                </div>

                {/* <div className="details-conditions">
                  <h6 className="text-center">{item.name}</h6>

                  <button
                    type="button"
                    onClick={() => Navigate(`${item.id}/ReportsBuilder`)}
                    className=" btn btn-outline-theme fw-bold">
                    {t('عرض التقرير')}
                  </button>
                  <i
                    className={
                      item.icon
                        ? item.icon.replace('fa-lg fa-fw me-2', '') + ' pt-2 '
                        : 'bi bi-menu-up pt-2'
                    }></i>
                </div>
                <div className="image">
                  <i
                    className={
                      item.icon
                        ? item.icon.replace('fa-lg fa-fw me-2', '') + ' pt-2 '
                        : 'bi bi-menu-up pt-2'
                    }></i>
                </div> */}
              </div>
            </div>
          ))}
        </div>
      </Loading>
    </>
  );
};

export default Index;
