import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useTranslation } from "react-i18next";
import { GetAssignmentByFleetEquipmentsId } from "../../../store/FLEET/AssignmentRoutes/assignmentroutesSlice";
import Pagination from "../../../components/Shared/Pagination/Pagination";
import { useParams } from "react-router-dom";


const AssignmentRoutesList = () => {
  const { Id, equipmentRoutesID } = useParams();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if(Id){
      dispatch(GetAssignmentByFleetEquipmentsId(Id))
    }
  }, [Id]);

  const { AssignmentByFleetEquipmentsId : data } = useAppSelector(
    (state) => state.AssignmentRoutes
  );

  const filteredSearch = data?.persons?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
        item.personName?.toLowerCase().includes(searchLowerCase) ||
      item.personName2?.toLowerCase().toString().includes(searchLowerCase)
    );
  })?.filter(f => f.fleetEquipmentsRouteId === equipmentRoutesID)

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  return (
    <div>
      <div className="table-responsive">
        <table className="table table-sm ">
          <thead className="table-active  ">
            <tr>
              <th scope="col">#</th>
              <th scope="col">اسم الموظف</th>
              {/* <th scope="col"></th> */}
            </tr>
          </thead>
          <tbody>
            {currentData?.map((item, index) => (
              <tr key={item.id}>
                <td className="align-middle">{++index}</td>
                <td className="align-middle">
                  {i18n.language === "en" ? item.personName : item.personName2}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        filteredData={filteredSearch}
        dataPerPage={dataPerPage}
        handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
        currentPage={currentPage}
      />
    </div>
  );
};

export default AssignmentRoutesList;
