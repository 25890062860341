import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useFormik } from "formik";
import Select from "react-select";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import {
  GetFleetCategory,
  GetFleetFuelType,
  GetFleetMotionVector,
} from "../../../../store/Lookup/lookupSlice";
import useLookupNationality from "../../../../hooks/Lookups/use-Lookup-Nationality";
import { Form } from "react-bootstrap";
import {
  GetEquipmentsModelList,
  InsertEquipmentsModel,
} from "../../../../store/FLEET/EquipmentsModel/equipmentsmodelSlice";
import { EquipmentsModelSchema } from "../../../ValidationForm/validationSchema";

const AddModel = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GetFleetCategory());
    dispatch(GetFleetFuelType());
    dispatch(GetFleetMotionVector());
    // dispatch(GetEquipmentsModelList());
  }, []);

  const { EquipmentsModelList } = useAppSelector(
    (state) => state.EquipmentsModel
  );

  const { FleetCategory, FleetFuelType, FleetMotionVector } = useAppSelector(
    (state) => state.Lookup
  );
  const { dataNATIONALITY } = useLookupNationality();

  const formik = useFormik({
    initialValues: {
      name: "",
      name2: "",
      description: "",
      parentId: "",
      manufYear: "",
      barcode: "",
      catagory: "",
      manufCountry: "",
      seatsNo: "",
      doorsNo: "",
      fuelType: "",
      motionVector: "",
      horsepower: "",
      horsepowerTax: "",
      Img: "",
      IsAddIMG: false,
    },
    validationSchema: EquipmentsModelSchema(t),
    onSubmit: (values) => {
   
      const formData = new FormData();

      formData.append("Name", values.name);
      formData.append("Name2", values.name2);
      formData.append("Description", values.description);
      formData.append("ParentId", values.parentId);
      formData.append("ManufYear", values.manufYear);
      formData.append("Barcode", values.barcode);
      formData.append("Catagory", values.catagory);
      formData.append("ManufCountry", values.manufCountry);
      formData.append("SeatsNo", values.seatsNo);
      formData.append("DoorsNo", values.doorsNo);
      formData.append("FuelType", values.fuelType);
      formData.append("MotionVector", values.motionVector);
      formData.append("Horsepower", values.horsepower);
      formData.append("HorsepowerTax", values.horsepowerTax);
      formData.append("Img", values.Img);
      formData.append("IsAddIMG", values.IsAddIMG.toString());

      dispatch(InsertEquipmentsModel(formData))
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            document.getElementById("closeModalForm")?.click();
            CustomAlert({ action: "Add" });
            formik.resetForm();
            dispatch(GetEquipmentsModelList());
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  const handleImageUpload = (event: any) => {
    formik.setFieldValue("Img", event.currentTarget.files[0]);
    formik.setFieldValue("IsAddIMG", true);
  };
  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t("LocalName")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name2}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t("GlobalName")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t('Manufacturer')}</label>
              <Form.Select
                className="form-select"
                name="parentId"
                onChange={formik.handleChange}
                value={formik.values.parentId || ""}
                isInvalid={
                  !!(formik.touched.parentId && formik.errors.parentId)
                }
              >
                <option value="">
                  {i18n.language === "ar" ? "إختر" : "Choose"}
                </option>
                {EquipmentsModelList &&
                  EquipmentsModelList?.filter((f) => f.parentId === null).map(
                    (item, idx) => (
                      <option key={++idx} value={item.id}>
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </option>
                    )
                  )}
              </Form.Select>
              {formik.errors.parentId && formik.touched.parentId && (
                <div>{formik.errors.parentId}</div>
              )}
            </div>
          </div>

          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t("Catagory")}</label>
              <Form.Select
                className="form-select pt-"
                name="catagory"
                onChange={formik.handleChange}
                value={formik.values.catagory}
                isInvalid={
                  !!(formik.touched.catagory && formik.errors.catagory)
                }
              >
                <option key={null}>
                  {i18n.language === "ar" ? "إختر" : "Choose"}
                </option>
                {FleetCategory &&
                  FleetCategory.map((item: any, idx) =>
                    item.list.map((list: any) => (
                      <option key={++idx} value={list.lookupCode}>
                        {i18n.language === "ar" ? list.meaning2 : list.meaning}
                      </option>
                    ))
                  )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.catagory}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t('Transmission')}</label>
              <Form.Select
                className="form-select"
                name="motionVector"
                onChange={formik.handleChange}
                value={formik.values.motionVector}
                isInvalid={
                  !!(formik.touched.motionVector && formik.errors.motionVector)
                }
              >
                <option key={null}>
                  {i18n.language === "ar" ? "إختر" : "Choose"}
                </option>
                {FleetMotionVector &&
                  FleetMotionVector.map((item: any, idx) =>
                    item.list.map((list: any) => (
                      <option key={++idx} value={list.lookupCode}>
                        {i18n.language === "ar" ? list.meaning2 : list.meaning}
                      </option>
                    ))
                  )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.motionVector}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t('Year_of_Manufacture')}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="manufYear"
                onChange={formik.handleChange}
                value={formik.values.manufYear}
                isInvalid={
                  !!(formik.touched.manufYear && formik.errors.manufYear)
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.manufYear}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t('State_of_Manufacture')}</label>
              <Select
                classNamePrefix="react-select"
                isRtl
                isSearchable={true}
                options={dataNATIONALITY}
                onChange={(option: any) =>
                  formik.setFieldValue("manufCountry", option.value)
                }
                placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
              />
              {formik.errors.manufCountry && formik.touched.manufCountry && (
                <div style={{ color: "red" }}>{formik.errors.manufCountry}</div>
              )}
            </div>
          </div>

          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t("Type_of_Fuel")}</label>
              <Form.Select
                className="form-select"
                name="fuelType"
                onChange={formik.handleChange}
                value={formik.values.fuelType}
                isInvalid={
                  !!(formik.touched.fuelType && formik.errors.fuelType)
                }
              >
                <option key={null}>
                  {i18n.language === "ar" ? "إختر" : "Choose"}
                </option>
                {FleetFuelType &&
                  FleetFuelType.map((item: any, idx) =>
                    item.list.map((list: any) => (
                      <option key={++idx} value={list.lookupCode}>
                        {i18n.language === "ar" ? list.meaning2 : list.meaning}
                      </option>
                    ))
                  )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.fuelType}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t('Barcodes')}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="barcode"
                onChange={formik.handleChange}
                value={formik.values.barcode}
                isInvalid={!!(formik.touched.barcode && formik.errors.barcode)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.barcode}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-2">
            <div className="form-group mb-3">
              <label className="form-label">{t('Number_of_Seats')}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="seatsNo"
                onChange={formik.handleChange}
                value={formik.values.seatsNo}
                isInvalid={!!(formik.touched.seatsNo && formik.errors.seatsNo)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.seatsNo}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-2">
            <div className="form-group mb-3">
              <label className="form-label">{t('Number_of_Doors')}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="doorsNo"
                onChange={formik.handleChange}
                value={formik.values.doorsNo}
                isInvalid={!!(formik.touched.doorsNo && formik.errors.doorsNo)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.doorsNo}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-2">
            <div className="form-group mb-3">
              <label className="form-label">{t('Horsepower')}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="horsepower"
                onChange={formik.handleChange}
                value={formik.values.horsepower}
                isInvalid={
                  !!(formik.touched.horsepower && formik.errors.horsepower)
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.horsepower}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t('Horsepower_Tax')}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="horsepowerTax"
                onChange={formik.handleChange}
                value={formik.values.horsepowerTax}
                isInvalid={
                  !!(
                    formik.touched.horsepowerTax && formik.errors.horsepowerTax
                  )
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.horsepowerTax}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('Image')}</label>
              <Form.Control
                className="form-control"
                type="file"
                name="Img"
                placeholder=" أختر صورة"
                accept="image/*"
                onChange={(e) => handleImageUpload(e)}
                isInvalid={!!(formik.touched.Img && formik.errors.Img)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Img}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t("Note")}</label>
              <Form.Control
                className="form-control"
                as="textarea"
                rows={1}
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
              />
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-outline-theme mb-1"
            id="SaveSubmit"
          >
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t("Add")}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-outline-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm"
          >
            <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default AddModel;
