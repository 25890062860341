import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../API/axiosNeatex";


// GetAmeConditionListBy DynamicFormId
export const GetAmeCondition = createAsyncThunk("Ame/GetAmeConditionList", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.get(`AmeCondition/GetAmeConditionListByDynamicFormId?DynamicFormId=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

  export const insertAmeCondition = createAsyncThunk("Ame/insertAmeCondition", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    console.log(JSON.stringify(item));
    debugger
    try {
      const {data} = await ntxAPI.post("AmeCondition/AddAmeCondition" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

  export const DeleteAmeCondition = createAsyncThunk("Ame/DeleteAmeCondition", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {

      const {data} = await ntxAPI.post( `AmeCondition/DeleteAmeCondition?Id=${id}`)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });






  const initialState = { AmeConditionList: [], loading: false, error: null };

  const AmeSlice = createSlice({
    name: "Ame",
    initialState,
    reducers: {
    },
    extraReducers : (builder => {
      builder
        .addCase(GetAmeCondition.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(GetAmeCondition.fulfilled, (state, action) => {
          state.loading = false;
          state.AmeConditionList = action.payload;
        })
        .addCase(GetAmeCondition.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(insertAmeCondition.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(insertAmeCondition.fulfilled, (state, action) => {
          state.loading = false;
          state.AmeConditionList.push(action.payload);
        })
        .addCase(insertAmeCondition.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(DeleteAmeCondition.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(DeleteAmeCondition.fulfilled, (state, action) => {
          state.loading = false;
          state.AmeConditionList = state.AmeConditionList?.filter((el) => el.id !== action.payload);
        })
        .addCase(DeleteAmeCondition.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    })
  });
  
  export default AmeSlice.reducer;