import { useFormik } from "formik";
import React, {   useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {   useNavigate } from "react-router-dom";
 
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import { ChangePass } from "../../../../store/Login/loginSlice";
import PasswordChecklist from "react-password-checklist";
import {AppSettings} from "../../../../config/app-settings"
import { useSelector } from "react-redux";

function ChangePassword() {
  const { t } = useTranslation();
  const Token = useSelector((s) => s.userLogin?.AuthUser?.token);
  const user = jwt_decode(Token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //#region context Layout
  const context = useContext(AppSettings);
    useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentClass('p-0');
		
		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentClass('');
		};
		
		// eslint-disable-next-line
	}, []);
    //#endregion



  //#region ShowPassword 
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [shownewPassword, setNewshowPassword] = useState(false);
  const toggleNewPasswordVisibility = () => {
    setNewshowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [showconfirmnewPassword, setConfirmnewshowPassword] = useState(false);
  const toggleConfirmNewPasswordVisibility = () => {
    setConfirmnewshowPassword((prevShowPassword) => !prevShowPassword);
  };
    //#endregion
 

//#region formik
  const formik = useFormik({
    initialValues: {
      usersName: user?.UserName,
      currentPassword: "",
      newPassword: "",
      comfirmdNewPassword: "",
    },

    validationSchema: Yup.object({
      usersName: Yup.string().trim().required("Username is required"),
      currentPassword: Yup.string().trim().required("Password is required"),

      newPassword: Yup.string()
        .min(8, "كلمة المرور يجب أن تحتوي على أكثر من 8 أحرف")
        .matches(
          /(?=.*[A-Z])/,
          "يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل"
        )
        .matches(/(?=.*\d)/, "يجب أن تحتوي كلمة المرور على رقم واحد على الأقل")
        .matches(/(?=.*[!@#$%^&*])/, "يجب أن تحتوي كلمة المرور على أحرف خاصة")
        .required(),

      comfirmdNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Please confirm your new password"),
    }),

    onSubmit: (values, { setErrors }) => {
      dispatch(
        ChangePass({
          usersName: values.usersName,
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
          comfirmdNewPassword: values.comfirmdNewPassword,
        })
      )
        .unwrap()
        .then((res) => {
          // console.log(res);
          if (res?.succeeded === true) {
            CustomAlert({ action: "Add", msg: "تم تغيير كلمة المرور بنجاح" });
            formik.resetForm();
            formik.setSubmitting(false);
            navigate(-1, { replace: true });
          } else {
            CustomAlert({ action: "Error", msg: res?.message });
            formik.setSubmitting(false);
          }
        })
        .catch((error) => {
          console.log(error);
          formik.setSubmitting(false);
        });
    },
  });
//#endregion
 

return (
    <>
      <div className="login p-0">
        <div className="login-content">
          {/* <div style={{ marginBottom: "20px" }}>
            <Logo themeClass={localStorage.appTheme ?? "theme-info"} />
          </div> */}
          {/* <hr /> */}
          <Form onSubmit={formik.handleSubmit}>
          <div className=" bg-inverse bg-opacity-10 rounded-1 p-2 mb-3 border border-theme ">
            <h5 className="text-center p-0 m-0">{t("ChangePassword")}</h5>

          </div>
            {/* <div className="  mb-3"></div> */}

            <div className="mb-3">
              <label className="form-label">
                {t("UserName")} <span className="text-danger">*</span>
              </label>

              <Form.Control
                disabled
                style={{ textAlign: "center" }}
                className="form-control form-control-lg bg-white bg-opacity-5"
                type="text"
                name="usersName"
                onChange={formik.handleChange}
                value={formik.values.usersName}
                isInvalid={formik.touched.usersName && formik.errors.usersName}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.usersName}
              </Form.Control.Feedback>
            </div>




            <div className="mb-3">
              <div className="d-flex">
                <label className="form-label">
                  {t("CurrentPassword")} <span className="text-danger">*</span>
                </label>
              </div>
              <div className="input-group">
                <div
                  className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                  style={{ zIndex: 1020, right: "10px" }}
                >
                  <i
                    className={`text-theme fs-4 opacity-10 bi bi-eye${
                      showPassword ? "-slash" : ""
                    }`}
                    onClick={togglePasswordVisibility}
                    style={{ cursor: "pointer", marginLeft: "-30px" }}
                  />
                </div>

                <Form.Control
                  style={{ textAlign: "center" }}
                  className="form-control form-control-lg bg-white bg-opacity-5"
                  // type="password"
                  type={showPassword ? "text" : "password"}
                  name="currentPassword"
                  onChange={formik.handleChange}
                  value={formik.values.currentPassword}
                  isInvalid={
                    formik.touched.currentPassword &&
                    formik.errors.currentPassword
                  }
                  autoComplete="off"
                />
              </div>

              <Form.Control.Feedback type="invalid">
                {formik.errors.currentPassword}
              </Form.Control.Feedback>
            </div>

            <div style={{display:'none'}}>
              <input type="password" name="hidden-password" />
            </div>

            <div className="mb-3">
              <div className="d-flex">
                <label className="form-label">
                  {t("NewPassword")} <span className="text-danger">*</span>
                </label>
              </div>

              <div className="input-group">
                <div
                  className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                  style={{ zIndex: 1020, right: "10px" }}
                >
                  <i
                    className={`text-theme fs-4 opacity-10 bi bi-eye${
                      shownewPassword ? "-slash" : ""
                    }`}
                    onClick={toggleNewPasswordVisibility}
                    style={{ cursor: "pointer", marginLeft: "-30px" }}
                  />
                </div>
                
              <Form.Control
                style={{ textAlign: "center" }}
                className="form-control form-control-lg bg-white bg-opacity-5 mb-1"
                // type="password"
                type={shownewPassword ? "text" : "password"}
                name="newPassword"
                onChange={formik.handleChange}
                value={formik.values.newPassword}
                isInvalid={
                  formik.touched.newPassword && formik.errors.newPassword
                }
              /></div>
              <div className=" bg-inverse bg-opacity-5 rounded-1 p-1">
                <PasswordChecklist
                  rules={["minLength", "specialChar", "number", "capital"]}
                  minLength={8}
                  value={formik.values.newPassword}
                  messages={{
                    minLength: t("ContainsMoreThan_8_Characters"),
                    specialChar: `${t(
                      "ContainsSpecialCharacters"
                    )} [! @ #  $ % ^ & *] `,
                    number: t("ContainAtLeastOneNumber"),
                    capital: t("ContainAtLeastOneCapitalLetter"),
                  }}
                />
              </div>
            </div>

            <div className="mb-3">
              <div className="d-flex">
                <label className="form-label">
                  {t("ConfirmNewPassword")}
                  <span className="text-danger">*</span>
                </label>
              </div>

              <div className="input-group">
                <div
                  className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                  style={{ zIndex: 1020, right: "10px" }}
                >
                  <i
                    className={`text-theme fs-4 opacity-10 bi bi-eye${
                      showconfirmnewPassword ? "-slash" : ""
                    }`}
                    onClick={toggleConfirmNewPasswordVisibility}
                    style={{ cursor: "pointer", marginLeft: "-30px" }}
                  />
                </div>
                
              <Form.Control
                style={{ textAlign: "center" }}
                className="form-control form-control-lg bg-white bg-opacity-5 mb-1"
                // type="password"
                type={showconfirmnewPassword ? "text" : "password"}
                name="comfirmdNewPassword"
                onChange={formik.handleChange}
                value={formik.values.comfirmdNewPassword}
                isInvalid={
                  formik.touched.comfirmdNewPassword &&
                  formik.errors.comfirmdNewPassword
                }
              />
              </div>
              <div className=" bg-inverse bg-opacity-5 rounded-1 p-1">
                <PasswordChecklist
                  rules={["match"]} //,
                  minLength={8}
                  value={formik.values.comfirmdNewPassword}
                  valueAgain={formik.values.newPassword}
                  messages={{
                    match: t("MatchThePassword"),
                  }}
                />
              </div>
            </div>



            <div className="mb-3"></div>
            <div className=" d-flex">
            <button
              type="submit"
              className="btn btn-theme btn-lg d-block w-100 fw-500 m-1"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? (
                <div className="spinner-border text-primary spinner-border-sm "></div>
              ) : (
                t("Save")
              )}
            </button>
            
            <button
              type="button"
              className="btn btn-secondary btn-lg d-block w-100 fw-500 m-1"
              disabled={formik.isSubmitting}
              onClick={()=>navigate(-1 , {replace:true})}
            >
              {formik.isSubmitting ? (
                <div className="spinner-border text-secondary spinner-border-sm "></div>
              ) : (
                t("Back")
              )}
            </button>
                      </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
