import React, { useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import useGetItemsList from '../../../../hooks/INV/MasterItems/useGetItemsList';
import Loading from '../../../../components/Shared/Loader/Loading';
import { Form } from 'react-bootstrap';
import useGetUnitsOfMeasureList from '../../../../hooks/INV/useGetUnitsOfMeasureList';
import useGetItemConversionList from '../../../../hooks/INV/ItemConversion/useGetItemConversionList';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import CodeCombination from './CodeCombination';
import { Card, CardBody } from '../../../../components/card/card';
import PopoverCustom from '../../../../components/Shared/Popover/PopoverCustom';

import useGetCostCenters from '../../../../hooks/Finance/ChartAccountsDetails/useGetCostCenters';
import useGetCompanies from '../../../../hooks/Finance/ChartAccountsDetails/useGetCompanies';
import useGetBranches from '../../../../hooks/Finance/ChartAccountsDetails/useGetBranches';
import useGetAccounts from '../../../../hooks/Finance/ChartAccountsDetails/useGetAccounts';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';

interface LinesProps {
  formik: FormikProps<any>;
}

const ApInvoiceLines: React.FC<LinesProps> = ({ formik }) => {
  const { values } = formik;

  const { OptionsCostCenters } = useGetCostCenters();
  const { OptionsCompanies } = useGetCompanies();
  const { OptionsBranches } = useGetBranches();
  const { OptionsAccounts } = useGetAccounts();

  const [shawModalAcaount, setShawModalAcaount] = useState(false);
  const [selectedLineIndex, setSelectedLineIndex] = useState<number | null>(null); // Keep track of selected line for the modal

  const { i18n, t } = useTranslation();
  const { ItemsOptions, loading, error } = useGetItemsList();

  const handleLineChange = (index: number, field: string, value: any) => {
    if (field === 'invItemId') {
      const updatedLines = [...values.apInvoiceLinesList];

      let price = ItemsOptions.find((f) => f.value === value)?.salesPrice;
      let total = Number(updatedLines[index].qty) * Number(price);
      updatedLines[index] = {
        ...updatedLines[index],
        invItemId: value,
        price: Number(price),
        total,
      };

      formik?.setFieldValue('apInvoiceLinesList', updatedLines);

      return;
    }

    const updatedLines = [...values.apInvoiceLinesList];
    updatedLines[index] = {
      ...updatedLines[index],
      [field]: value,
    };

    // Recalculate total and VAT if needed
    if (field === 'qty' || field === 'price' || field === 'vatRate') {
      const total = Number(updatedLines[index].qty) * Number(updatedLines[index].price);
      const vatAmount = (total * Number(updatedLines[index].vatRate)) / 100;
      updatedLines[index] = {
        ...updatedLines[index],
        total,
        vatAmount,
      };
    }

    // setLines(updatedLines);
    formik?.setFieldValue('apInvoiceLinesList', updatedLines);
  };

  const handleAddLine = () => {
    const newLine = {
      lineType: 'Item',
      id: null,
      lineNumber: values.apInvoiceLinesList.length + 1,
      invItemId: null,
      uom: '',
      price: 0,
      qty: 0,
      discountRate: 0,
      discountAmount: 0,
      vatRate: 15,
      total: 0,
      vatAmount: 0,
      description: '',
      currencyCode: '',
      currencyConversionRate: 1,
      purHeaderId: null,
      purLineId: null,
      accountId: null,
      costCenterId: null,
      //
      companyId: '', // غير موجود في البوست
      branchId: '', // غير موجود في البوست
      codeCombination: '', // غير موجود في البوست
    };
    formik.setFieldValue('apInvoiceLinesList', [...formik.values.apInvoiceLinesList, newLine]);
  };

  const handleDeleteLine = (index: number) => {
    const updatedLines = values.apInvoiceLinesList.filter((_: any, i: number) => i !== index);
    formik?.setFieldValue('apInvoiceLinesList', updatedLines);
  };

  const calculateTotalSum = () => {
    let total = values.apInvoiceLinesList?.reduce((sum: any, line: any) => sum + line.total, 0);
    const formattedTotal = new Intl.NumberFormat('en-US').format(total);
    return formattedTotal;
  };

  const [accountDetails, setAccountDetails] = useState({
    companyId: '',
    branchId: '',
    costCenterId: '',
    accountId: '',
  });

  const handleAccountClick = (index: number) => {
    if (!values.organizationId) {
      SwalAlert({ text: 'حدد الفرع' });
      return;
    }

    const selectedLine = formik.values.apInvoiceLinesList[index];
    // إعداد القيم لتمريرها إلى المكون
    const companyId = selectedLine.companyId || '';
    const branchId = selectedLine.branchId || '';
    const accountId = selectedLine.accountId || '';
    const costCenterId = selectedLine.costCenterId || '';

    // تمرير القيم إلى المكون وفتح الـ Modal
    setSelectedLineIndex(index);
    setShawModalAcaount(true);
    setAccountDetails({ accountId, costCenterId, companyId, branchId });
  };

  const handleAccountSelect = (account: any) => {
    // console.log( values.apInvoiceLinesList[0]);
    // console.log(account);
    // console.log(selectedLineIndex);

    if (selectedLineIndex !== null) {
      const updatedLines = [...values.apInvoiceLinesList];

      updatedLines[selectedLineIndex].companyId = account?.company?.id || '';
      updatedLines[selectedLineIndex].branchId = account?.branch?.id || '';
      updatedLines[selectedLineIndex].costCenterId = account?.costCenter?.id || '';
      updatedLines[selectedLineIndex].accountId = account?.account?.id || '';

      var codeCombination = `${account?.company?.accountNumber} | ${account?.branch?.accountNumber} |${account?.costCenter?.accountNumber} |${account?.account?.accountNumber}`;
      updatedLines[selectedLineIndex].codeCombination = codeCombination || '';
      formik.setFieldValue('apInvoiceLinesList', updatedLines);
    }
    setShawModalAcaount(false); // Close modal after selection
  };

  const handleShowAccountDetails = (index: number) => {
    const selectedLine = values.apInvoiceLinesList[index];
    const { codeCombination, companyId, branchId, accountId, costCenterId } = selectedLine;

    return (
      <div className="text-start">
        <p className="m-0">{OptionsCompanies?.find((f) => f.value === companyId)?.accountShow}</p>
        <p className="m-0">{OptionsBranches?.find((f) => f.value === branchId)?.accountShow}</p>
        <p className="m-0">{OptionsAccounts?.find((f) => f.value === accountId)?.accountShow}</p>
        <p className="m-0">
          {OptionsCostCenters?.find((f) => f.value === costCenterId)?.accountShow}
        </p>
      </div>
    );
  };

  return (
    <>
      <Loading loading={loading} error={error} Type="Dots">
        <>
          <div className="row">
            <div className="col-12 col-md-3 mb-2">
              <div className="rounded-2 p-2 bg-inverse bg-opacity-10">
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">{t('Total')}:</div>
                  <div className="text-theme fs-5 fw-bold">{calculateTotalSum()}</div>
                </div>
              </div>
            </div>

            <div className="col-lg-3"></div>
            <div className="col-lg-6 text-end ">
              <div className="text-end mb-2">
                <button type="button" className="btn btn-outline-theme  " onClick={handleAddLine}>
                  {/* <i className="bi bi-plus-square-dotted fa-lg me-2"></i> */}
                  {t('إضافة صنف')}
                </button>
              </div>
            </div>
          </div>

          {values.apInvoiceLinesList?.length > 0 && (
            <div className="">
              <div
                className={`table-responsive rounded-2 text-center ${
                  formik.errors.apInvoiceLinesList ? 'border border-2 border-danger' : ''
                } `}>
                <table className="table table-borderless  text-nowrap  table-hover mb-0">
                  <thead className="bg-theme bg-opacity-30">
                    <tr>
                      <th className="align-middle  small" style={{ width: '2%' }}>
                        #
                      </th>
                      <th className="align-middle  small" style={{ width: '5%' }}>
                        النوع
                      </th>

                      <th className="align-middle small" style={{ width: '5%' }}>
                        {t('القيمة')}
                      </th>
                      <th className="align-middle small" style={{ width: '29%' }}>
                        {t('الوصف')}
                      </th>
                      <th className="align-middle small" style={{ width: '12%' }}>
                        {t('الحســـاب')}
                      </th>
                      <th className="align-middle text-end" style={{ width: '2%' }}></th>
                    </tr>
                  </thead>

                  <tbody className="fw-bold">
                    {values.apInvoiceLinesList
                      ?.slice()

                      .map((line: any, index: any) => {
                        const Index = index;

                        return (
                          <tr key={index}>
                            <td className="align-middle   small">{index + 1}</td>
                            <td className="align-middle small">
                              <Form.Select
                                className="form-select"
                                name="lineType"
                                value={line.lineType || ''}
                                onChange={(e) =>
                                  handleLineChange(Index, 'lineType', e.target.value)
                                }>
                                <option value="Item">
                                  {i18n.language === 'en' ? 'Item' : 'Item'}
                                </option>
                                <option value="Tax">
                                  {i18n.language === 'en' ? 'Tax Memo' : 'Tax'}
                                </option>
                                <option value="Freight">
                                  {i18n.language === 'en' ? 'Freight' : 'Freight'}
                                </option>
                              </Form.Select>
                            </td>

                            <td className="align-middle small">
                              <input
                                type="text"
                                className="form-control text-center px-0"
                                value={line.total || 0}
                                onChange={(e) =>
                                  handleLineChange(Index, 'total', Number(e.target.value))
                                }
                                onInput={(e) => {
                                  const input = e.target as HTMLInputElement;
                                  input.value = input.value.replace(/[^0-9]/g, '');
                                }}
                              />
                            </td>

                            <td className="align-middle small">
                              <Form.Control
                                className="form-control"
                                as="textarea"
                                rows={1}
                                value={line.description || ''}
                                onChange={(e) => {
                                  handleLineChange(Index, 'description', e.target.value);
                                }}
                              />
                            </td>

                            <td className="align-middle small  ">
                              {line.codeCombination ? (
                                <PopoverCustom
                                  childern={
                                    <Form.Control
                                      readOnly
                                      className="form-control text-center"
                                      type="text"
                                      onClick={() => handleAccountClick(index)}
                                      value={line.codeCombination || ''}
                                    />
                                  }
                                  Header="تفاصيل الحـــساب"
                                  Body={handleShowAccountDetails(index)}
                                />
                              ) : (
                                <Form.Control
                                  readOnly
                                  className="form-control text-center"
                                  type="text"
                                  onClick={() => handleAccountClick(index)}
                                  value={line.codeCombination || ''}
                                />
                              )}
                            </td>

                            <td className="align-middle small">
                              <button
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={() => handleDeleteLine(Index)}>
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      </Loading>

      <NeatixModal
        show={shawModalAcaount}
        setShow={setShawModalAcaount}
        size="md"
        title="الحســــاب"
        headerClassName="bg-theme bg-opacity-25 rounded-top-3 p-2"
        bodyClassName="p-0">
        <div className="p-2">
          <CodeCombination
            onAccountSelect={handleAccountSelect}
            accountDetails={accountDetails}
            organizationId={values?.organizationId} // الفرع
          />
        </div>
      </NeatixModal>
    </>
  );
};

export default ApInvoiceLines;

// {/* <th className="align-middle  small" style={{ width: '30%' }}>
//   {t('اسم الصنف')}
// </th>
// <th className="align-middle  small" style={{ width: '15%' }}>
//   {t('الوحدة')}
// </th>
// <th className="align-middle  small" style={{ width: '5%' }}>
//   {t('الكمية')}
// </th>
// <th className="align-middle  small" style={{ width: '5%' }}>
//   {t('السعر')}
// </th>
// <th className="align-middle  small" style={{ width: '5%' }}>
//   {t('نسبة الخصم')}
// </th>
// <th className="align-middle  small" style={{ width: '5%' }}>
//   {t('قيمة الخصم')}
// </th>
// <th className="align-middle  small" style={{ width: '5%' }}>
//   {t('VAT %')}
// </th>
// <th className="align-middle  small" style={{ width: '5%' }}>
//   {t('VAT')}
// </th> */}

// <td className="align-middle small">
// <Select
//   classNamePrefix="react-select"
//   className="w-100"
//   isSearchable
//   isClearable
//   options={ItemsOptions.filter(
//     (f) =>
//       !values.apInvoiceLinesList.some(
//         (line: any) => line.invItemId === f.value
//       )
//   )}
//   onChange={(option) =>
//     handleLineChange(
//       Index,
//       'invItemId',
//       option === null ? null : option.value
//     )
//   }
// value={
//   line.invItemId === null
//     ? null
//     : ItemsOptions.find((option) => option.value === line.invItemId)
// }
//   placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
//   menuPortalTarget={document.body}
//   styles={{
//     menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
//   }}
// />
// </td>
// <td className="align-middle small">
// <select
//   className="form-select text-center"
//   value={line.uom || ''}
//   onChange={(e) => handleLineChange(Index, 'uom', e.target.value)}>
//   <option value="">
//     {i18n.language === 'ar' ? 'إختر' : 'Choose'}
//   </option>
//   {/* {ItemConversionList.filter((f) => f.itemId === line.itemId).map(
//     (item, idx) => (
//       <option key={++idx} value={item.uom}>
//         {i18n.language === 'en' ? item.uomName : item.uomName2}
//       </option>
//     )
//   )} */}
//   {GetUom(line).map((item, idx) => (
//     <option key={++idx} value={item.uom}>
//       {i18n.language === 'en' ? item.uomName : item.uomName2}
//     </option>
//   ))}
// </select>
// </td>
// <td className="align-middle small">
// <input
//   type="text"
//   className="form-control text-center px-0"
//   value={line.qty}
//   onChange={(e) =>
//     handleLineChange(Index, 'qty', Number(e.target.value))
//   }
//   onInput={(e) => {
//     // إلغاء أي إدخال غير رقمي يدويًا
//     const input = e.target as HTMLInputElement;
//     input.value = input.value.replace(/[^0-9]/g, '');
//   }}
// />
// </td>
// <td className="align-middle small">
// <input
//   type="text"
//   className="form-control text-center px-0"
//   value={line.price}
//   onChange={(e) =>
//     handleLineChange(Index, 'price', Number(e.target.value))
//   }
//   onInput={(e) => {
//     // إلغاء أي إدخال غير رقمي يدويًا
//     const input = e.target as HTMLInputElement;
//     input.value = input.value.replace(/[^0-9]/g, '');
//   }}
// />
// </td>
// <td className="align-middle small">
// <input
//   type="text"
//   className="form-control text-center px-0"
//   value={line.discountRate}
//   onChange={(e) =>
//     handleLineChange(Index, 'discountRate', Number(e.target.value))
//   }
//   onInput={(e) => {
//     // إلغاء أي إدخال غير رقمي يدويًا
//     const input = e.target as HTMLInputElement;
//     input.value = input.value.replace(/[^0-9]/g, '');
//   }}
// />
// </td>
// <td className="align-middle small">{line.discountAmount}</td>
// <td className="align-middle small">
// <input
//   type="text"
//   className="form-control text-center px-0"
//   value={line.vatRate}
//   onChange={(e) =>
//     handleLineChange(Index, 'vatRate', Number(e.target.value))
//   }
//   onInput={(e) => {
//     // إلغاء أي إدخال غير رقمي يدويًا
//     const input = e.target as HTMLInputElement;
//     input.value = input.value.replace(/[^0-9]/g, '');
//   }}
// />
// </td>
// <td className="align-middle small">{line.vatAmount}</td>

// const GetUom = (line: any) => {
//   const uomIdForItem = ItemsOptions.find((f) => f.value === line?.invItemId)?.uom;

//   // تصفية العناصر من قائمة التحويلات بناءً على itemId
//   const filteredList = ItemConversionList.filter((f) => f.itemId === line.invItemId);
//   // العثور على الوحدة المقابلة من قائمة UomList بناءً على uomId
//   const filteredList2 = UomList.find((f) => f.id === uomIdForItem);

//   // دمج القائمتين في قائمة ثالثة
//   const combinedList = [...filteredList];

//   // إذا كانت filteredList2 موجودة وغير موجودة في filteredList، يتم إضافتها إلى القائمة
//   if (filteredList2 && !filteredList.some((f) => f.uom === filteredList2.id)) {
//     combinedList.push({
//       id: filteredList2.id,
//       itemId: line.invItemId,
//       uom: filteredList2.id,
//       conversionValue: 1, // أو أي قيمة افتراضية مناسبة للتحويل
//       barcode: '',
//       uomName: filteredList2.name,
//       uomName2: filteredList2.name2,
//     });
//   }

//   return combinedList;
// };

// const autoResize = (e: any) => {
//   e.target.style.height = 'auto'; // Reset height
//   e.target.style.height = `${e.target.scrollHeight}px`; // Set height to scrollHeight
// };
