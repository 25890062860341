import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  cleanLocation,
  getLocation,
} from "../../store/HR/HRSetting/locationSlice";

const useLocationDetails = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { loading, error, Location } = useSelector((state) => state.Locations);

  useEffect(() => {
    // dispatch(cleanLocation());
    dispatch(getLocation(id));
  }, [dispatch, id]);

  return { loading, error, Location };
};

export default useLocationDetails;
