import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import useLookupNationality from "../../../../hooks/Lookups/use-Lookup-Nationality";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { getLocations, insertLocation } from "../../../../store/HR/HRSetting/locationSlice";
import { formLocation } from "../../../ValidationForm/validationSchema";
import useGetOrganizations from "../../../../hooks/HR/use-get-organizations";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import useGetLocations from "../../../../hooks/HRSetting/use-get-locations";
import { Card, CardBody } from "../../../../components/card/card";
import Map from "../../../../components/Shared/Map/Map";

const AddLocation = () => {
  //////////////////////////// State  for Map
  const [center, setCenter] = useState({ lat: 24.7135517, lng: 46.6752957 });
  const [lat, setLat] = useState(center.lat);
  const [lng, setLng] = useState(center.lng);
  //////////////////////////// State  for Map

  const { Locations  } = useGetLocations();

  //// Get Organizations
  const { Organizations } = useGetOrganizations();
  // const dataOrg = Organizations.filter((f) => f.type === "130");
  ///////////////////
  const { NATIONALITY } = useLookupNationality();
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: "",
      name2: "",
      businessGroupId: "",
      description: "",
      shipToLocationId: 0,
      shipToSiteFlag: false,
      receivingSiteFlag: false,
      billToSiteFlag: false,
      country: "",
      addressLine: "",
      townOrCity: "",
      postalCode: "",
      region: "",
      telephoneNumber: "",
      latitude: lat,
      longitude: lng,
    },

    validationSchema: formLocation(t),

    onSubmit: (values, { resetForm }) => {
      values.latitude = lat.toString();
      values.longitude = lng.toString();

      dispatch(
        insertLocation({
          name: values.name,
          name2: values.name2,
          businessGroupId: values.businessGroupId,
          description: values.description,
          shipsToLocationId: null, /// نحتاجها في المبيعات
          shipToSiteFlag: values.shipToSiteFlag, // نحتاجها في المبيعات
          receivingSiteFlag: values.receivingSiteFlag, // نحتاجها في المبيعات
          billToSiteFlag: values.billToSiteFlag, // نحتاجها في المبيعات
          country: values.country,
          addressLine: values.addressLine,
          townOrCity: values.townOrCity,
          postalCode: values.postalCode,
          region: values.region,
          telephoneNumber: values.telephoneNumber,
          latitude: values.latitude,
          longitude: values.longitude,
        })
      )
        .unwrap()
        .then(() => {
          CustomAlert({ action: "Add" });
          dispatch(getLocations());
          document.getElementById("closeModalLocation").click();
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-xl-6">
          <div className="row">
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t("LocalName")}</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="name2"
                  onChange={formik.handleChange}
                  value={formik.values.name2}
                  isInvalid={formik.touched.name2 && formik.errors.name2}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name2}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t("GlobalName")}</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  isInvalid={formik.touched.name && formik.errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t("Latitude")}</label>

                <Form.Control
                  className="form-control"
                  type="text"
                  value={lat}
                  name="latitude"
                  onChange={(e) => setLat(parseFloat(e.target.value))}
                  readOnly
                  isInvalid={formik.errors.latitude}
                />
              </div>
            </div>

            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t("Longitude")}</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  value={lng}
                  onChange={(e) => setLng(parseFloat(e.target.value))}
                  name="longitude"
                  readOnly
                  isInvalid={!!formik.errors.longitude}
                />
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-xl-12">
              <div className="form-group mb-3">
                <label className="form-label">{t('Organization')}</label>
                <Form.Select
                  className="form-select"
                  name="businessGroupId"
                  onChange={formik.handleChange}
                  value={formik.values.businessGroupId}
                  isInvalid={
                    formik.touched.businessGroupId &&
                    formik.errors.businessGroupId
                  }
                >
                  <option key={null}>
                    {i18n.language === "ar" ? "إختر" : "Choose"}
                  </option>
                  {Organizations &&
                    Organizations.map((item, idx) => (
                      <option key={++idx} value={item.id}>
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.businessGroupId}
                </Form.Control.Feedback>
              </div>
            </div>

            {/* <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">ship To Location</label>
                <Form.Select
                  className="form-select"
                  name="shipToLocationId"
                  onChange={formik.handleChange}
                  value={formik.values.shipToLocationId}
                  isInvalid={
                    formik.touched.shipToLocationId &&
                    formik.errors.shipToLocationId
                  }
                >
                  <option key={null}>
                    {i18n.language === "ar" ? "إختر" : "Choose"}
                  </option>
                  {Locations &&
                    Locations.map((item, idx) => (
                      <option key={++idx} value={item.id}>
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </option>
                    ))}
                </Form.Select>
              </div>
            </div> */}
          </div>

          <div className="row" style={{marginTop : "24px"}}>
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("Country")}</label>
            <Form.Select
              className="form-select"
              name="country"
              onChange={formik.handleChange}
              value={formik.values.country}
              isInvalid={formik.touched.country && formik.errors.country}
            >
              <option key={null}>
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {NATIONALITY.map((item, idx) =>
                item.list.map((list) => (
                  <option key={++idx} value={list.lookupCode}>
                    {i18n.language === "ar" ? list.meaning : list.meaning2}
                  </option>
                ))
              )}
            </Form.Select>
  
          </div>
        </div>

        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("City")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="townOrCity"
              onChange={formik.handleChange}
              value={formik.values.townOrCity}
              isInvalid={formik.touched.townOrCity && formik.errors.townOrCity}
            />
  
          </div>
        </div>


          </div>

        </div>

        <div className="col-xl-6">
          <Card>
            <CardBody>
              <Map
                center={center}
                lat={lat}
                lng={lng}
                setCenter={setCenter}
                setLat={setLat}
                setLng={setLng}
              />
            </CardBody>
          </Card>
        </div>
      </div>


      <div className="row" style={{marginTop : "10px"}}>

        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("Address")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="addressLine"
              onChange={formik.handleChange}
              value={formik.values.addressLine}
              isInvalid={
                formik.touched.addressLine && formik.errors.addressLine
              }
            />
 
          </div>
        </div>


          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t("PostalCode")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="postalCode"
                onChange={formik.handleChange}
                value={formik.values.postalCode}
                isInvalid={
                  formik.touched.postalCode && formik.errors.postalCode
                }
              />
    
            </div>
          </div>

          <div className="col-xl-2 text-center">
              <div className="form-group mb-3">
              <label className="form-label">
               يُشحن لـه
                </label>
                <Form.Check
                style={{ margin: "10px 40px" }}
                  type="checkbox"
                  name="shipToSiteFlag"
                  onChange={formik.handleChange}
                  value={formik.values.shipToSiteFlag}
                />
              </div>
            </div>

            <div className="col-xl-2 text-center">
              <div className="form-group mb-3">
              <label className="form-label">
                 يُفوتر لـه
                </label>
                <Form.Check
                style={{ margin: "10px 40px" }}
                type="checkbox"
                  name="billToSiteFlag"
                  onChange={formik.handleChange}
                  value={formik.values.billToSiteFlag}
                />
              </div>
            </div>

            <div className="col-xl-2 text-center">
              <div className="form-group mb-3">
              <label className="form-label">
                يستقبل بضاعة
                </label>
                <Form.Check
                style={{ margin: "10px 40px" }}
                type="checkbox"
                  name="receivingSiteFlag"
                  onChange={formik.handleChange}
                  value={formik.values.receivingSiteFlag}
                />
              </div>
            </div>

      </div>
      
      <div className="row">



          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t("Region")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="region"
                onChange={formik.handleChange}
                value={formik.values.region}
                isInvalid={formik.touched.region && formik.errors.region}
              />
              {/* <Form.Control.Feedback type="invalid">
            {formik.errors.NATIONAL_IDENTIFIER}
          </Form.Control.Feedback> */}
            </div>
          </div>

          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t("MobileNumber")}</label>
              <Form.Control
                className="form-control"
                type="text"
                // placeholder="الهوية"
                name="telephoneNumber"
                onChange={formik.handleChange}
                value={formik.values.telephoneNumber}
                isInvalid={
                  formik.touched.telephoneNumber &&
                  formik.errors.telephoneNumber
                }
              />
              {/* <Form.Control.Feedback type="invalid">
            {formik.errors.NATIONAL_IDENTIFIER}
          </Form.Control.Feedback> */}
            </div>
          </div>
                  <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("Note")}</label>
            <Form.Control
              className="form-control"
              as="textarea"
              rows={1}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
            />

            <div></div>
          </div>
        </div>
        </div>

      <div className="text-center">
        <button
          disabled={formik.isSubmitting}
          type="submit"
          className="btn me-1 btn-theme mb-1"
          id="SaveSubmit"
        >
          <i className="fa fa-save fa-fw me-1"></i>{" "}
          {formik.isSubmitting ? "..." : t("Button.Save")}
        </button>
        <button
          type="button"
          className=" btn me-1 btn-default mb-1"
          data-bs-dismiss="modal"
          id="closeModalLocation"
        >
          <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
        </button>
        <button
          type="button"
          onClick={formik.resetForm}
          className="btn me-1 btn btn-danger mb-1"
        >
          <i className="fa fa-trash-alt fa-fw me-1"></i>
          {t("Clear")}
        </button>
      </div>
    </Form>
  );
};

export default AddLocation;
