/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import Pagination from "../../../../components/Shared/Pagination/Pagination";
import { Card, CardBody } from "../../../../components/card/card";
import { GetEquipmentsModelList } from "../../../../store/FLEET/EquipmentsModel/equipmentsmodelSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useTranslation } from "react-i18next";
import ModalComponent from "../../../../components/Modal/ModalComponent";
import AddModel from "./AddModel";
import Loader from "../../../../components/Shared/Loader/Loader";

const Index = () => {
  const [Show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();

  useEffect(() => {
    if(EquipmentsModelList?.length === 0){
      dispatch(GetEquipmentsModelList());
    }
  }, []);
  const { EquipmentsModelList , loading} = useAppSelector(
    (state) => state.EquipmentsModel
  );


  const filteredSearch = EquipmentsModelList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.filter((f) => f.parentId !== null);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Model",
      link: null,
    },
  ];


  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="Model" />
      <Card>
      <Loader loading={loading}/>
        <CardBody>
 
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: "10px" }}
                        >
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder={t("Search") + " ..."}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-5 col-md-6 text-end">
                  <a
                    href="#/"
                    className="btn me-1 btn-outline-theme"
                    data-bs-toggle="modal"
                    data-bs-target="#modalِAddModel"
                    onClick={() => setShow(true)}
                  >
                    <i className="fa fa-plus-circle fa-fw me-1"></i>
                    {t("AddModel")}
                  </a>
                </div>
              </div>
              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t("Model")}</th>
                      <th scope="col">{t("Manufacturers")}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle ">
                          <div className="d-flex align-items-center">
                            <div className="w-50px h-50px bg-white bg-opacity-25 d-flex align-items-center justify-content-center">
                              {item.image === null ? (
                                <img
                                  alt=""
                                  className="mw-100 mh-100"
                                  src="/assets/img/Image/NoImg2.ico"
                                />
                              ) : (
                                <img
                                  alt=""
                                  className="mw-100 mh-100"
                                  src={item?.image?.filePath}
                                />
                              )}
                            </div>
                            <div className="ms-3">
                              {i18n.language === "ar" ? item.name2 : item.name}
                            </div>
                          </div>
                        </td>
                        <td className="align-middle">
                          {
                            i18n.language === "ar" ? 
                            EquipmentsModelList?.find(f => f.id === item.parentId)?.name2 
                            :
                            EquipmentsModelList?.find(f => f.id === item.parentId)?.name
                          }
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-outline-lime"
                            onClick={() => Navigate(`${item.id}/Edit`)} 
                          >
                            <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                            {t("Button.Edit")}
                          </button>
                        </td> 
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: any) =>
                  setCurrentPage(pageNumber)
                }
                currentPage={currentPage}
              />

        </CardBody>
      </Card>

      <ModalComponent title={t("AddModel")} id="modalِAddModel">
        {Show && <AddModel />}
      </ModalComponent>
    </div>
  );
};

export default Index;
