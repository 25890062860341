import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";




export const GetResponsibilityUserByUserId = createAsyncThunk("ResponsibilityUser/GetResponsibilityUserByUserId", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`Security/GetAuthResponsibilityUserByUserIdList?UserId=${id}`);
      const {data} = await ntxAPI.get(`Security/GetAuthResponsibilityUserByUserIdList?UserId=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });


  export const AddResponsibilityUser = createAsyncThunk("ResponsibilityUser/AddResponsibilityUser", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("Security/AddAuthResponsibilityUser" , item);
      const {data} = await ntxAPI.post("Security/AddAuthResponsibilityUser" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });



export const DeleteAuthResponsibilityUser = createAsyncThunk("ResponsibilityUser/DeleteAuthResponsibilityUser", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
 
    try {
      // const data = await postData(`Security/DeleteAuthResponsibilityUser?Id=${id}`);
      const {data} = await ntxAPI.post(`Security/DeleteAuthResponsibilityUser?Id=${id}`)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });






  const initialState = { ResponsibilityUserList: [] ,  loading: false, error: null };

  const ResponsibilityUserSlice = createSlice({
    name: "ResponsibilityUser",
    initialState,
    reducers: { },
    extraReducers: (builder) => {
      // get Approval Member List
      builder
        .addCase(GetResponsibilityUserByUserId.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(GetResponsibilityUserByUserId.fulfilled, (state, action) => {
          state.loading = false;
          state.ResponsibilityUserList = action.payload;
        })
        .addCase(GetResponsibilityUserByUserId.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        // insert Approval Member
        .addCase(AddResponsibilityUser.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(AddResponsibilityUser.fulfilled, (state, action) => {
          state.loading = false;
          state.ResponsibilityUserList.table.push(action.payload);
        })
        .addCase(AddResponsibilityUser.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  
  export default ResponsibilityUserSlice.reducer;