import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { GetGlJeHeadersById, clearJournalsById } from '../../../store/Finance/Journals/JournalsSlice';

const useGetJournalsById= (id:string | undefined) => {

    const dispatch = useAppDispatch();
    useEffect(() => {
      if(id){
        dispatch(clearJournalsById())
          dispatch(GetGlJeHeadersById(id));
      }
    }, [ id]);
    const { JournalsById , loading} = useAppSelector(
      (state) => state.Journals
    );
   

  return {JournalsById , loading }
}

export default useGetJournalsById
