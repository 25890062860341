import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getTransactionsIssueList } from '../../../store/INV/Transactions/InvIssue/invIssueSlice';

const useGetInvIssueList = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (InvIssueList?.length === 0) {
      dispatch(getTransactionsIssueList());
    }
  }, []);
  const { InvIssueList, loading , error } = useAppSelector((state) => state.InvIssue);

  return { InvIssueList, loading , error };
};

export default useGetInvIssueList;
