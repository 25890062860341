import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { GetGlChartOfAccountsList } from '../../store/Finance/ChartAccounts/ChartAccountsSlice';

const useGetChartAccountsList = () => {

    const dispatch = useAppDispatch();
   
    useEffect(() => {
      if(ChartAccountsList?.length ===0){
        dispatch(GetGlChartOfAccountsList());
      }
    }, []);
    const { ChartAccountsList , loading , error} = useAppSelector(
      (state) => state.ChartAccounts
    );
  

   

  return { ChartAccountsList, loading , error}
}

export default useGetChartAccountsList
