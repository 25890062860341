import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getTransactionsQuotationList } from '../../store/Sales/SalesQuotation/salesQuotationSlice';

const useGetQuotationList = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (SalesQuotationList?.length === 0) {
      dispatch(getTransactionsQuotationList());
    }
  }, []);
  const { SalesQuotationList, loading, error } = useAppSelector((state) => state.SalesQuotation);

  return { SalesQuotationList, loading, error };
};

export default useGetQuotationList;
