import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody } from '../../../../components/card/card';
import {
  ActinRunPyroll,
  GetPeriodTimeByPayrollId,
} from '../../../../store/HR/Payroll/runPayrollSlice';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import * as Yup from 'yup';
import Loader from '../../../../components/Shared/Loader/Loader';
import showConfirmation from '../../../../components/Shared/Alert/Confirmation';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import Loading from '../../../../components/Shared/Loader/Loading';

const AddPayrollRun = ({ payrollId }) => {
  // const { payrollId } = useParams();
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  //________________________________________________________

  useEffect(() => {
    dispatch(GetPeriodTimeByPayrollId(payrollId));
  }, [dispatch, payrollId]);
  const { PeriodTimeList, loading, error } = useSelector((state) => state.runPayroll);

  const formik = useFormik({
    initialValues: {
      timePeriodId: '',
    },
    validationSchema: Yup.object().shape({
      timePeriodId: Yup.string().required(() => t('Required')),
    }),

    onSubmit: (values) => {
      showConfirmation('هل أنت متأكد من تسيير راتب الشهر المحدد', () => {
        setIsSubmitting(true);
        dispatch(
          ActinRunPyroll({
            payrollId: payrollId,
            assignmentId: null,
            actionType: 'Run',
            timePeriodId: values.timePeriodId,
          })
        )
          .unwrap()
          .then((res) => {
            if (res.status === true) {
              CustomAlert({ action: 'Add', msg: res.msg });
              // Navigate(-1, { replace: true });
              formik.resetForm();
              setIsSubmitting(false);
            } else if (res.status === false) {
              CustomAlert({ action: 'Error', msg: res.msg });
              setIsSubmitting(false);
            }
          })
          .catch((error) => {
            setIsSubmitting(false);
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          });
      });
    },
  });

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('RunPayrolls'),
      link: '/Pay/payrollsRun',
    },
    {
      name: t('CreateRunPayrolls'),
      link: null,
    },
  ];

  return (
    <>
      {/* <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('RunPayrolls')} /> */}

      {/* <Card> */}
      <Loading loading={loading} error={error}>
        <CardBody>
          <Loader loading={loading} />

          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Period-Month')} </label>
                  <Form.Select
                    className="form-select form-select-lg"
                    name="timePeriodId"
                    onChange={formik.handleChange}
                    value={formik.values.timePeriodId}
                    isInvalid={formik.touched.timePeriodId && formik.errors.timePeriodId}>
                    <option value={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {PeriodTimeList.map((item, idx) => (
                      <option key={++idx} value={item.id}>
                        {item.periodName}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.timePeriodId}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>
          </Form>

          <div className="text-end">
            <button
              disabled={isSubmitting}
              type="button"
              className="btn btn-theme btn-lg"
              onClick={formik.handleSubmit}>
              {isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="fa fa-plus-circle fa-fw me-2"></i>
              )}
              {t('CreateRun')}
            </button>

            <button
              type="button"
              className=" btn  btn-lg btn-default mx-1"
              data-bs-dismiss="modal"
              // onClick={() => Navigate(-1, { replace: true })}
            >
              <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
            </button>
          </div>
        </CardBody>
      </Loading>
      {/* </Card> */}
    </>
  );
};

export default AddPayrollRun;
