import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store/hooks';
import { Form, Col, Row } from 'react-bootstrap';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';
import DiscountVouchersCategoriesList from './DiscountVouchersCategoriesList';
import useLookupDiscountType from '../../../hooks/Lookups/use-Lookup-DiscountType';
import useGetDiscountVoucherTypesList from '../../../hooks/Sales/Vouchers/useGetDiscountVoucherTypesList';
import useGetPriceList from '../../../hooks/INV/useGetPriceList';
import DiscountVouchersItemsList from './DiscountVouchersItemsList';
import DiscountVouchersPeoplesList from './DiscountVouchersPeoplesList';
import DiscountVouchersPeopleCategoryList from './DiscountVouchersPeopleCategoryList';
import { AddDiscountVouchers } from '../../../store/Sales/DiscountVouchers/DiscountVouchersSlice';
import { Card, CardBody } from '../../../components/card/card';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import Loading from '../../../components/Shared/Loader/Loading';
import Coupons from './Coupons';
import NeatixModal from '../../../components/Modal/NeatixModal';
import Rewards from './Rewards';

const voucherCode = require('voucher-code-generator');

type DiscountVoucherRewardItem = {
  discountVouchersRewardsId: string;
  itemId: string;
  itemQty: number;
};

type DiscountVoucherReward = {
  discountVouchersId: string;
  rewardsType: string;
  discountPercentage: number;
  discountVouchersRewardsItemsList: DiscountVoucherRewardItem[];
};

interface FormValues {
  voucherTypeId: string;
  validationDateFrom: string;
  validationDateTo: string;
  name: string;
  name2: string;
  conditionalRulesType: string;
  conditionalRulesQty: string | number;
  conditionalRulesAmount: string | number;
  pricelIstId: string | null;
  allItems: boolean;
  allCategories: boolean;
  allPeoples: boolean;
  allPeopleCategory: boolean;
  allPos: boolean;
  allLocations: boolean;
  discountVouchersCategoriesList: { discountVouchersId: string | null; categoryId: string }[];
  discountVouchersCouponsList: any[];
  discountVouchersItemsList: { discountVouchersId: string | null; itemId: string }[];
  discountVouchersLocationsList: any[];
  discountVouchersPeopleCategoryList: {
    discountVouchersId: string | null;
    peopleCategory: string;
  }[];
  discountVouchersPeoplesList: {
    discountVouchersId: string | null;
    peopleId: string | undefined;
  }[];
  discountVouchersPosList: any[];
  discountVouchersRewardsList: DiscountVoucherReward;
  AllItemsAllCategories: string;
  AllPeoplesAllPeopleCategory: string;
}

const AddVoucher = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const [discountCode, setDiscountCode] = useState('');
  const { DISCOUNT_TYPE } = useLookupDiscountType();
  const { PriceList, loading } = useGetPriceList();
  const { DiscountVoucherTypesList, loading: loading2 } = useGetDiscountVoucherTypesList();

  //#region
  const formik = useFormik<FormValues>({
    initialValues: {
      voucherTypeId: '',
      validationDateFrom: '',
      validationDateTo: '',
      name: '',
      name2: '',
      conditionalRulesType: '',
      conditionalRulesQty: '',
      conditionalRulesAmount: '',
      pricelIstId: '',
      allItems: false,
      allCategories: false,
      allPeoples: false,
      allPeopleCategory: false,
      allPos: false,
      allLocations: false,
      discountVouchersCategoriesList: [],
      discountVouchersCouponsList: [],
      discountVouchersItemsList: [],
      discountVouchersLocationsList: [],
      discountVouchersPeopleCategoryList: [],
      discountVouchersPeoplesList: [],
      discountVouchersPosList: [],

      discountVouchersRewardsList: {
        discountVouchersId: '',
        rewardsType: '',
        discountPercentage: 0,
        discountVouchersRewardsItemsList: [],
      },

      //__ not for submit
      AllItemsAllCategories: '',
      AllPeoplesAllPeopleCategory: '',
    },

    // validationSchema: formOrganization(t),

    onSubmit: (values) => {
      dispatch(
        AddDiscountVouchers({
          voucherTypeId: values.voucherTypeId,
          validationDateFrom: values.validationDateFrom,
          validationDateTo: values.validationDateTo,
          name: values.name,
          name2: values.name2,

          conditionalRulesType: values.conditionalRulesType,

          conditionalRulesQty:
            values.conditionalRulesType === 'qty' ? values.conditionalRulesQty : 0,

          conditionalRulesAmount:
            values.conditionalRulesType === 'amount' ? values.conditionalRulesAmount : 0,

          pricelIstId: values.conditionalRulesType === 'fixed' ? values.pricelIstId : null,

          allItems: values.AllItemsAllCategories === 'allItems', // return boolean
          allCategories: values.AllItemsAllCategories === 'allCategories', // return boolean
          allPeoples: values.AllPeoplesAllPeopleCategory === 'allPeoples', // return boolean
          allPeopleCategory: values.AllPeoplesAllPeopleCategory === 'allPeopleCategory', // return boolean
          allPos: false,
          allLocations: false,

          discountVouchersCategoriesList: values.discountVouchersCategoriesList?.map(
            (item: any) => ({
              discountVouchersId: null,
              categoryId: item.categoryId,
            })
          ),

          discountVouchersCouponsList:
            values.voucherTypeId === '2ad37011-c6c3-4987-a92a-e99830edf2f1'
              ? values.discountVouchersCouponsList.map((c) => ({
                  discountVouchersId: null,
                  expiryDate: c.expiryDate,
                  couponNumber: c.NumberOfCoupons,
                  isValid: true,
                }))
              : [],

          discountVouchersItemsList: values.discountVouchersItemsList.map((item: any) => ({
            discountVouchersId: null,
            itemId: item.itemId,
          })),

          discountVouchersPeopleCategoryList: values.discountVouchersPeopleCategoryList.map(
            (item: any) => ({
              discountVouchersId: null,
              peopleCategory: item.peopleCategory,
            })
          ),

          discountVouchersPeoplesList: values.discountVouchersPeoplesList.map((item: any) => ({
            discountVouchersId: null,
            peopleId: item?.peopleId,
          })),

          discountVouchersRewardsList: [
            {
              discountVouchersId: null,
              rewardsType: values.discountVouchersRewardsList.rewardsType,
              discountPercentage:
                values.discountVouchersRewardsList.rewardsType === 'DISCOUNT'
                  ? values.discountVouchersRewardsList.discountPercentage
                  : 0,
              discountVouchersRewardsItemsList:
                values.discountVouchersRewardsList.rewardsType === 'FREE_ITEM'
                  ? values.discountVouchersRewardsList.discountVouchersRewardsItemsList.map(
                      (r) => ({
                        discountVouchersRewardsId: null,
                        itemId: r.itemId,
                        itemQty: r.itemQty,
                      })
                    )
                  : [],
            },
          ],

          discountVouchersPosList: [],
          discountVouchersLocationsList: [],
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded === true) {
            formik.resetForm();
            document.getElementById('AddPriceList')?.click();
            CustomAlert({ action: 'Add' });
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  //#endregion


 
  const HandleChangeVoucherType = (e: any) => {
    formik.handleChange(e);

    if (e.target.value.toLowerCase() === 'a6dc19de-e06f-44c4-b977-eceb457a7976') {
      formik.setFieldValue('AllItemsAllCategories', 'allItems');

      formik.setFieldValue('conditionalRulesType', 'qty');
    }
  };

  const HandleChangeRadio = (e: any) => {
    formik.handleChange(e);
    console.log(e.target.value);

    if (e.target.value === 'allItems') {
      formik.setFieldValue('discountVouchersCategoriesList', []);
    } else if (e.target.value === 'allCategories') {
      formik.setFieldValue('discountVouchersItemsList', []);
    } else if (e.target.value === 'allPeoples') {
      formik.setFieldValue('discountVouchersPeopleCategoryList', []);
    } else if (e.target.value === 'allPeopleCategory') {
      formik.setFieldValue('discountVouchersPeoplesList', []);
    }
  };

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Vouchers',
      link: '/SalesSettings/Vouchers',
    },
    {
      name: 'AddVouchers',
      link: null,
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('Vouchers')} />

        {/* <div className="ms-auto">
          <button
            className="btn me-1 btn-outline-theme"
            // onClick={() => setShow(true)}
          >
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            اضافة
          </button>
        </div> */}
      </div>

      <hr className="mt-0" />

      <Loading loading={loading || loading2}>
        <Form onSubmit={formik.handleSubmit}>
          <Row className="row d-flex align-items-center">
            <Col lg={4}>
              <div className="form-group mb-3">
                <Form.Label>{t('LocalName')}</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="name2"
                  onChange={formik.handleChange}
                  value={formik.values.name2}
                  isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                />
                <Form.Control.Feedback type="invalid">{formik.errors.name2}</Form.Control.Feedback>
              </div>
            </Col>

            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>{t('GlobalName')}</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  isInvalid={!!(formik.touched.name && formik.errors.name)}
                />
                <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={2}>
              <Form.Group className="mb-3">
                <Form.Label>{t('validationDateFrom')}</Form.Label>
                <Form.Control
                  className="form-control"
                  type="date"
                  name="validationDateFrom"
                  onChange={formik.handleChange}
                  value={formik.values.validationDateFrom}
                  isInvalid={
                    !!(formik.touched.validationDateFrom && formik.errors.validationDateFrom)
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.validationDateFrom}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={2}>
              <Form.Group className="mb-3">
                <Form.Label>{t('validationDateTo')}</Form.Label>
                <Form.Control
                  className="form-control"
                  type="date"
                  name="validationDateTo"
                  onChange={formik.handleChange}
                  value={formik.values.validationDateTo}
                  isInvalid={!!(formik.touched.validationDateTo && formik.errors.validationDateTo)}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.validationDateTo}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <div className="row d-flex align-items-center">
            <div className="col-lg-4">
              <div className="form-group mb-3">
                <label className="form-label"> {t('voucherTypeId')} </label>
                <Form.Select
                  className="form-select"
                  name="voucherTypeId"
                  onChange={(e) => HandleChangeVoucherType(e)}
                  value={formik.values.voucherTypeId}
                  isInvalid={!!(formik.touched.voucherTypeId && formik.errors.voucherTypeId)}>
                  <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                  {DiscountVoucherTypesList &&
                    DiscountVoucherTypesList?.map((item, idx) => (
                      <option key={++idx} value={item.id}>
                        {i18n.language === 'en' ? item.name : item.name2}
                      </option>
                    ))}
                </Form.Select>
              </div>
            </div>

            {[
              '55d69c47-c3b0-4831-a046-75a144fd68ce',
              '1a0eb9fb-b164-4a60-a6d0-16008fcb43f8',
            ].includes(formik.values.voucherTypeId.toLowerCase()) && (
              <div className="col-lg-4">
                <label className="form-label"> {t('كود الخصم')} </label>
                <div className="input-group mb-4">
                  <Form.Control
                    className="form-control text-center "
                    type="text"
                    name="discountCode"
                    // onChange={formik.handleChange}
                    value={discountCode}
                  />
                  <button
                    type="button"
                    className="btn me-1 btn-outline-warning"
                    onClick={() => {
                      const codes = voucherCode.generate({
                        length: 15,
                      });
                      setDiscountCode(codes);
                    }}>
                    <i className="fa-lg fw-bold bi bi-tag"></i>
                  </button>
                </div>
              </div>
            )}

            {['2ad37011-c6c3-4987-a92a-e99830edf2f1'].includes(
              formik.values.voucherTypeId.toLowerCase()
            ) && (
              <>
                <div className="col-lg-5"></div>
                <div className="col-lg-3 text-center bg-inverse bg-opacity-10 rounded-2 p-2">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="me-auto">
                      <button
                        type="button"
                        className="btn btn-sm me-1 btn-warning"
                        onClick={() => setShow(true)}>
                        توليد الكوبونات
                      </button>
                    </div>
                    <div>عدد الكوبونات : {formik.values.discountVouchersCouponsList.length}</div>
                  </div>
                </div>
              </>
            )}
          </div>

          <>
            <div className="mb-5">
              <h4>
                <i className="bi bi-pencil-square fa-lg text-theme"></i> الشروط
              </h4>
              <p> </p>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={8}>
                      <Row>
                        <Col lg={4}>
                          <Form.Group className="mb-3">
                            <Form.Label className="form-label">
                              {t('conditionalRulesType')}
                            </Form.Label>
                            <Form.Select
                              className="form-select"
                              name="conditionalRulesType"
                              onChange={formik.handleChange}
                              value={formik.values.conditionalRulesType}
                              isInvalid={
                                !!(
                                  formik.touched.conditionalRulesType &&
                                  formik.errors.conditionalRulesType
                                )
                              }
                              isValid={
                                formik.touched.conditionalRulesType &&
                                !formik.errors.conditionalRulesType
                              }>
                              <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                              {DISCOUNT_TYPE &&
                                DISCOUNT_TYPE.map((item: any, idx: any) =>
                                  item.list.map((list: any) => (
                                    <option key={++idx} value={list.lookupCode}>
                                      {i18n.language === 'en' ? list.meaning : list.meaning2}
                                    </option>
                                  ))
                                )}
                            </Form.Select>
                          </Form.Group>
                        </Col>

                        <Col md={4}>
                          <div className="form-group mb-3">
                            <label className="form-label">{t('pricelIstId')}</label>
                            <Form.Select
                              disabled={formik.values.conditionalRulesType !== 'fixed'}
                              // className="form-select"
                              className={`form-select ${
                                formik.values.conditionalRulesType !== 'fixed' &&
                                'bg-inverse bg-opacity-10'
                              } `}
                              name="pricelIstId"
                              onChange={formik.handleChange}
                              value={formik.values.pricelIstId || ''}
                              isInvalid={
                                !!(formik.touched.pricelIstId && formik.errors.pricelIstId)
                              }>
                              <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                              {PriceList &&
                                PriceList?.map((item, idx) => (
                                  <option key={++idx} value={item.id}>
                                    {i18n.language === 'en' ? item.name : item.name2}
                                  </option>
                                ))}
                            </Form.Select>
                          </div>
                        </Col>

                        <Col md={2}>
                          <div className="form-group mb-3">
                            <label className="form-label">{t('Qty')}</label>
                            <Form.Control
                              disabled={formik.values.conditionalRulesType !== 'qty'}
                              className={`form-control ${
                                formik.values.conditionalRulesType !== 'qty' &&
                                'bg-inverse bg-opacity-10'
                              } `}
                              type="text"
                              name="conditionalRulesQty"
                              onChange={formik.handleChange}
                              value={formik.values.conditionalRulesQty}
                              isInvalid={
                                !!(
                                  formik.touched.conditionalRulesQty &&
                                  formik.errors.conditionalRulesQty
                                )
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.conditionalRulesQty}
                            </Form.Control.Feedback>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="form-group mb-3">
                            <label className="form-label">{t('Amount')}</label>
                            <Form.Control
                              disabled={formik.values.conditionalRulesType !== 'amount'}
                              className={`form-control ${
                                formik.values.conditionalRulesType !== 'amount' &&
                                'bg-inverse bg-opacity-10'
                              } `}
                              type="text"
                              name="conditionalRulesAmount"
                              onChange={formik.handleChange}
                              value={formik.values.conditionalRulesAmount}
                              isInvalid={
                                !!(
                                  formik.touched.conditionalRulesAmount &&
                                  formik.errors.conditionalRulesAmount
                                )
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.conditionalRulesAmount}
                            </Form.Control.Feedback>
                          </div>
                        </Col>
                      </Row>
                      <DiscountVouchersItemsList formik={formik} />

                      <DiscountVouchersCategoriesList formik={formik} />

                      <Row>
                        <Col md={6}>
                          <DiscountVouchersPeoplesList formik={formik} />
                        </Col>
                        <Col md={6}>
                          <DiscountVouchersPeopleCategoryList formik={formik} />
                        </Col>
                      </Row>
                    </Col>

                    <Col lg={4} className="pt-4">
                      <div className="p-2 bg-inverse bg-opacity-10 ">
                        <div className="form-group mb-0">
                          <div className="shipping-container">
                            <div className="row align-items-center">
                              <div className="col-6 pt-1 pb-1">{t('allItems')}</div>

                              <div className="col-6 d-flex align-items-center">
                                <div className="form-check  form-switch ms-auto">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    id="defaultRadio1"
                                    name="AllItemsAllCategories"
                                    // onChange={formik.handleChange}
                                    onChange={HandleChangeRadio}
                                    checked={formik.values.AllItemsAllCategories === 'allItems'}
                                    value="allItems"
                                  />

                                  <label className="form-check-label" htmlFor="defaultRadio1">
                                    &nbsp;
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="my-2" />

                            <div className="row align-items-center">
                              <div className="col-6 pt-1 pb-1">{t('allCategories')}</div>

                              <div className="col-6 d-flex align-items-center">
                                <div className="form-check form-switch ms-auto">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    id="defaultRadio2"
                                    name="AllItemsAllCategories"
                                    // onChange={formik.handleChange}
                                    onChange={HandleChangeRadio}
                                    checked={
                                      formik.values.AllItemsAllCategories === 'allCategories'
                                    }
                                    value="allCategories"
                                  />
                                  <label className="form-check-label" htmlFor="defaultRadio2">
                                    &nbsp;
                                  </label>
                                </div>
                              </div>
                            </div>

                            <hr className="my-2" />

                            <div className="row align-items-center">
                              <div className="col-6 pt-1 pb-1">{t('allPeoples')}</div>

                              <div className="col-6 d-flex align-items-center">
                                <div className="form-check form-switch ms-auto">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    id="defaultRadio3"
                                    name="AllPeoplesAllPeopleCategory"
                                    // onChange={formik.handleChange}
                                    onChange={HandleChangeRadio}
                                    checked={
                                      formik.values.AllPeoplesAllPeopleCategory === 'allPeoples'
                                    }
                                    value="allPeoples"
                                  />
                                  <label className="form-check-label" htmlFor="defaultRadio3">
                                    &nbsp;
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="my-2" />

                            <div className="row align-items-center">
                              <div className="col-6 pt-1 pb-1">{t('allPeopleCategory')}</div>

                              <div className="col-6 d-flex align-items-center">
                                <div className="form-check form-switch ms-auto">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    id="defaultRadio4"
                                    name="AllPeoplesAllPeopleCategory"
                                    // onChange={formik.handleChange}
                                    onChange={HandleChangeRadio}
                                    value="allPeopleCategory"
                                    checked={
                                      formik.values.AllPeoplesAllPeopleCategory ===
                                      'allPeopleCategory'
                                    }
                                  />
                                  <label className="form-check-label" htmlFor="defaultRadio4">
                                    &nbsp;
                                  </label>
                                </div>
                              </div>
                            </div>

                            <hr className="my-2" />
                            <div className="row align-items-center">
                              <div className="col-6 pt-1 pb-1">{t('allPos')}</div>

                              <div className="col-6 d-flex align-items-center">
                                <div className="form-check form-switch ms-auto">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    id="defaultRadio5"
                                    name="allPos"
                                    onChange={formik.handleChange}
                                    // value={formik.values.allPos}
                                  />
                                  <label className="form-check-label" htmlFor="defaultRadio5">
                                    &nbsp;
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="my-2" />

                            <div className="row align-items-center">
                              <div className="col-6 pt-1 pb-1">{t('allLocations')}</div>

                              <div className="col-6 d-flex align-items-center">
                                <div className="form-check form-switch ms-auto">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    id="defaultRadio6"
                                    name="allLocations"
                                    onChange={formik.handleChange}
                                    // value={formik.values.allLocations}
                                  />
                                  <label className="form-check-label" htmlFor="defaultRadio6">
                                    &nbsp;
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </>

          <>
            <div className="mb-5">
              <h4>
                <i className="bi bi-award fa-lg text-theme"></i> المكافئات
              </h4>
              <p> </p>
              <Card>
                <CardBody>
                  <Rewards formik={formik} />
                </CardBody>
              </Card>
            </div>
          </>

          <div className="text-center">
            <button
              disabled={formik.isSubmitting}
              type="submit"
              className="btn me-1 btn-theme mb-1"
              id="SaveSubmit">
              {formik.isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
              )}
              {t('Add')}
            </button>
            <button type="button" className=" btn me-1 btn-default mb-1">
              <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
            </button>
          </div>
        </Form>
      </Loading>

      <NeatixModal size="md" title="الكوبونات" setShow={setShow} show={show}>
        <Coupons formik={formik} />
      </NeatixModal>
    </>
  );
};

export default AddVoucher;
