import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { GetGlLedgersList } from '../../store/Finance/Ledgers/LedgersSlice';
import { useTranslation } from 'react-i18next';

const useGetGlLedgersList = () => {

    const dispatch = useAppDispatch();

    const {i18n , t} = useTranslation();
    useEffect(() => {
      if(LedgersList?.length ===0){
        dispatch(GetGlLedgersList());
      }
    }, []);
    const { LedgersList , loading , error} = useAppSelector(
      (state) => state.Ledgers
    );
   
    const OptionsLedgers =
    LedgersList &&
    LedgersList.map((item) => ({
      value: item.id,
      label: i18n.language === 'ar' ? item.name2 : item.name,
    }));


  return { LedgersList, OptionsLedgers ,loading , error }
}

export default useGetGlLedgersList
