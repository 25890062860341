import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
// import { useTranslation } from 'react-i18next';
import { GetDashboardWithPermission } from '../../../store/NTX/Reports/Dashboard/dashboardSlice';

const useGetDashboardWithPermission = () => {
  // const { i18n, t } = useTranslation();

  const dispatch = useAppDispatch();
  const { DashboardWithPermission, loading, error } = useAppSelector((state) => state.DashBoard);
  useEffect(() => {
    if (DashboardWithPermission?.length === 0) {
      dispatch(GetDashboardWithPermission());
    }
  }, []);

  return {
    DashboardWithPermission,
    loading,
    error,
  };
};

export default useGetDashboardWithPermission;
