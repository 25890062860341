import React from 'react';

import useGetDynamicForm from '../../../hooks/Forms/useGetDynamicForm.js';
import { FormCard } from './FormCard.js';
import Loading from '../../../components/Shared/Loader/Loading';
import { CardBody } from '../../../components/card/card.jsx';

const AmeDahboard = () => {
  const { loading, DynamicFormAme, error } = useGetDynamicForm();

  var HrForm = DynamicFormAme.filter((f) => f.moduleId === '0a6c6b89-1d52-4eaa-8d2c-010c74b1f6e0');
  var FleetForm = DynamicFormAme.filter(
    (f) => f.moduleId === '0a6c6b89-1d52-4eaa-8d2c-040c74b1f6e0'
  );
  var SUForm = DynamicFormAme.filter((f) => f.moduleId === '0a6c6b89-1d52-4eaa-8d2c-030c74b1f6e0');
  var FinForm = DynamicFormAme.filter((f) => f.moduleId === '0a6c6b89-1d52-4eaa-8d2c-050c74b1f6e0');
  var InvForm = DynamicFormAme.filter((f) => f.moduleId === '0a6c6b89-1d52-4eaa-8d2c-070c74b1f6e0');
  var SalesForm = DynamicFormAme.filter(
    (f) => f.moduleId === '0a6c6b89-1d52-4eaa-8d2c-080c74b1f6e0'
  );
  var PurForm = DynamicFormAme.filter((f) => f.moduleId === '0a6c6b89-1d52-4eaa-8d2c-090c74b1f6e0');

  return (
    <div>
      <Loading loading={loading} error={error}>
        <CardBody>
          <div className="row">
            <div className="col-xl-4 col-lg-6">
              <FormCard
                title="الموارد البشرية"
                formList={HrForm}
                icon="bi-person-fill-exclamation"
              />
            </div>


            <div className="col-xl-4 col-lg-6">
              <FormCard title="إدارة الأسطول" formList={FleetForm} icon="bi-car-front-fill" />
            </div>
            <div className="col-xl-4 col-lg-6">
              <FormCard title="الخدمات المساندة" formList={SUForm} icon="bi-tools" />
            </div>
          </div>

          <div className="row">
            <div className="col-xl-3 col-lg-6">
              <FormCard title="المالية" formList={FinForm} icon="bi-cash-stack" />
            </div>
            <div className="col-xl-3 col-lg-6">
              <FormCard title="المخازن" formList={InvForm} icon="bi-box-seam" />
            </div>
            <div className="col-xl-3 col-lg-6">
              <FormCard title="المبيعات" formList={SalesForm} icon="bi-cart-fill" />
            </div>
            <div className="col-xl-3 col-lg-6">
              <FormCard title="المشتريات" formList={PurForm} icon="bi-basket-fill" />
            </div>
          </div>

          {/* <div className="row">

   

        <div className="col-xl-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <span className="flex-grow-1">جميع طلبات الأنظمة</span>
                <CardExpandToggler />
              </div>
              <RequestList />
            </CardBody>
          </Card>
        </div>
      </div> */}
        </CardBody>
      </Loading>
    </div>
  );
};

export default AmeDahboard;
