import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CardBody } from '../../../../components/card/card';
import { GetDynamicFormByID } from '../../../../store/NTX/FlexValue/Form/formSlice';
import { EditDynamicFormSchema } from '../../../ValidationForm/validationSchema';
import { UodateDynamicForm } from '../../../../store/NTX/FlexValue/Form/formSlice';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { DataIcons } from '../../../../assets/icons';
import Select from 'react-select';
import Loading from '../../../../components/Shared/Loader/Loading';

const EditForm = ({ id }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  useEffect(() => {
    dispatch(GetDynamicFormByID(id));
  }, [id]);
  const { DynamicFormListByID, loading2, error } = useSelector((state) => state.Forms);

  const formik = useFormik({
    initialValues: {
      name2: DynamicFormListByID ? DynamicFormListByID.name2 : '',
      name: DynamicFormListByID ? DynamicFormListByID.name : '',
      icon: DynamicFormListByID ? DynamicFormListByID.icon : '',
      isStatic: DynamicFormListByID ? DynamicFormListByID.isStatic : '',
      urlStaticForm: DynamicFormListByID ? DynamicFormListByID.urlStaticForm : '',
    },
    enableReinitialize: true,
    validationSchema: EditDynamicFormSchema(t),
    onSubmit: (values, { resetForm }) => {
      dispatch(
        UodateDynamicForm({
          id: DynamicFormListByID?.id,
          name: values.name,
          name2: values.name2,
          icon: values.icon,
          dynamicFormTypesId: 1,
          isStatic: values.isStatic,
          tableId: null,
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          CustomAlert({ action: 'Edit' });
          Navigate('/NTX/Forms', { replace: true });
          resetForm();
        })
        .catch((error) => {
          console.log(error);
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  return (
    <>
      {/* <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('EditForm')} /> */}

      {/* <Card> */}
      <Loading loading={loading2} error={error}>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t('LocalName')}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name2"
                    onChange={formik.handleChange}
                    value={formik.values.name2 || ''}
                    isInvalid={!!formik.errors.name2}
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t('GlobalName')}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name || ''}
                    isInvalid={!!formik.errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name2}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-6 m-auto">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Icon')}</label>

                  <Select
                    classNamePrefix="react-select"
                    isRtl
                    isSearchable={true}
                    options={DataIcons}
                    onChange={(option) => formik.setFieldValue('icon', option.value)}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    value={DataIcons.find((f) => f.value === formik.values.icon)}
                  />
                </div>
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn me-1 btn-theme mb-1">
                <i className="fa fa-save fa-fw me-1"></i> {t('Save')}
              </button>

              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                data-bs-dismiss="modal"
              
                // onClick={() => Navigate('/NTX/Forms', { replace: true })}
                >
                <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
              </button>
            </div>
          </Form>
        </CardBody>
      </Loading>
      {/* </Card> */}
    </>
  );
};

export default EditForm;
