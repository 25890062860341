import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
import { GetStaticForm } from "../../store/NTX/FlexValue/Form/formSlice";
 

const useGetStaticFormList = ()=>{

    const dispatch = useDispatch();

    const { StaticFormList, loading } = useSelector((state) => state.Forms);
    useEffect(() => {
      dispatch(GetStaticForm())
    }, [dispatch]);

    return { StaticFormList , loading} ;
};

export default useGetStaticFormList ;