import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  DeleteAmeConditionUsage,
  GetAmeConditionUsageListByRuleId,
} from '../../../../store/Ame/ameConditionUsageSlice';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import showConfirmation from '../../../../components/Shared/Alert/Confirmation';

function AmeConditionUsageList({ data }) {
  const { ruleId } = useParams();

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  // Handele Delete AmeCondition
  const handleDeleteConditionUsage = (item) => {
    showConfirmation(t('MsgConfirm'), () => {
      dispatch(DeleteAmeConditionUsage(item.id))
        .unwrap()
        .then(() => {
          dispatch(GetAmeConditionUsageListByRuleId(ruleId));
          CustomAlert({ action: 'Delete' });
        })
        .catch((error) => {
          CustomAlert({ action: 'Error' });
        });
    });
  };

  return (
    <div>
      {data.length > 0 && (
        <div className="table-responsive rounded-2 text-center border border-theme">
          <table className="table table-hover mb-0">
            <thead className="bg-theme bg-opacity-60">
              <tr>
                <th scope="col"> {t('Condition')}</th>

                <th scope="col"></th>
              </tr>
            </thead>

            <tbody>
              {data.map((item, idx) => (
                <tr key={item.id}>
                  <td className="align-middle">
                    {i18n.language === 'ar' ? item.name2 : item.name}
                  </td>

                  <td className="align-middle">
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-lg m-0"
                      onClick={() => handleDeleteConditionUsage(item)}>
                      <i className="fas fa-trash-alt fa-fw fa-lg"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default AmeConditionUsageList;
