import React, { useEffect } from "react";
import AddScan from "./AddScan";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { GetEquipmentsList } from "../../../../store/FLEET/Equipments/equipmentsSlice";
import { GetScanningElementList } from "../../../../store/FLEET/FleetScan/fleetScanSlice";
import { GetNtxPeoplesList } from "../../../../store/NTX/Peoples/peoplesSlice";
import { useFormik } from "formik";
import { GetPeriodicallyScanningById, UpdatePeriodicallyScanning } from "../../../../store/FLEET/EquipmentInspection/equipmentInspectionSlice";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import { Form } from "react-bootstrap";
import { Card, CardBody } from "../../../../components/card/card";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import { version } from "os";
import { AddFleetInspectionSchema } from "../../../ValidationForm/validationSchema";
import useGetEquipmentsList from "../../../../hooks/FLeet/useGetEquipmentsList";

const UpdateScan = () => {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { fleetEquipmentsId, id } = useParams();
  
  useEffect(() => {
    dispatch(GetScanningElementList());
    dispatch(GetNtxPeoplesList(""));
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(GetPeriodicallyScanningById(id));
    }
  }, [id]);

  const { PeoplesList } = useAppSelector((state) => state.Peoples);
  const { ScanElementList } = useAppSelector((state) => state.FleetScan);
  const { PeriodicallyScanningById } = useAppSelector(
    (state) => state.EquipmentInspection
  );
  const { EquipmentsList , loading} = useGetEquipmentsList()
  const EquipmentData = EquipmentsList?.find((f) => f.id === fleetEquipmentsId);

  const formik = useFormik({
    initialValues: {
      id: PeriodicallyScanningById
        ? PeriodicallyScanningById.id
        : "",
      fleetEquipmentsId: PeriodicallyScanningById
        ? PeriodicallyScanningById.fleetEquipmentsId
        : "",
      driverId: PeriodicallyScanningById
        ? PeriodicallyScanningById.driverId
        : "",
      scanningCode: PeriodicallyScanningById
        ? PeriodicallyScanningById.scanningCode
        : "",
      scanningDate: PeriodicallyScanningById
        ? PeriodicallyScanningById.scanningDate
        : "",
      description: PeriodicallyScanningById
        ? PeriodicallyScanningById.description
        : "",
      details:
        PeriodicallyScanningById &&
        PeriodicallyScanningById?.details?.map((item) => ({
          id: item.id,
          scanningMasterId: item.scanningMasterId,
          scanningElementId: item.scanningElementId,
          value: item.value ? "1":"0",
          description: item.description,
          elementName: item.elementName,
          elementName2: item.elementName2  ,         
          elementDescription: item.elementDescription  ,      
          elementDescription2: item.elementDescription2 ,    
          elementSort: item.elementSort            
        })),
    },
    enableReinitialize: true,
    validationSchema: AddFleetInspectionSchema(t),
    onSubmit: (values) => {

  
      dispatch(
        UpdatePeriodicallyScanning({
          id: id,
          fleetEquipmentsId: values.fleetEquipmentsId,
          driverId: values.driverId,
          scanningCode: values.scanningCode,
          scanningDate: values.scanningDate,
          description: values.description,
          details: values?.details?.map((m) => ({
            id: m.id ,
            scanningMasterId: m.scanningMasterId ,
            scanningElementId: m.scanningElementId,
            value: m.value === "1" ? true : false,
            description: m.description,
          })),
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            formik.resetForm();
            CustomAlert({ action: "Edit" });
            Navigate(-1)
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });


  //________________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "EquipmentInspection",
      link: "/FLEET/EquipmentInspection",
    },
    {
      name: "EditInspection",
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="EditInspection" />

      <Card>
        <CardBody className="p-4">
          <div className="table-responsive text-center rounded-top-2 pb-4">
            <table className="table table-bordered bg-inverse bg-opacity-10 ">
              <thead className="">
                <tr className="bg-inverse bg-opacity-15">
                  <th scope="col">{t("EquipmentName")}</th>
                  <th scope="col">{t("Plate_Number")}</th>
                  <th scope="col">{t("AssetNumber")}</th>
                  <th scope="col">{t("ServiceDate")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle">
                    {i18n.language === "ar"
                      ? EquipmentData?.name2
                      : EquipmentData?.name}
                  </td>
                  <td className="align-middle">{EquipmentData?.plateNo}</td>
                  <td className="align-middle">{EquipmentData?.code}</td>
                  <td className="align-middle">{EquipmentData?.serviceDate}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <Form onSubmit={formik.handleSubmit}>
            <div className="row mb-4">
              <div className="col-xl-4">
                <div className="input-group c">
                  <span className="input-group-text">{t("Driver")}</span>
                  <Form.Select
                    className="form-select"
                    name="driverId"
                    onChange={formik.handleChange}
                    value={formik.values.driverId || ""}
                    isInvalid={
                      !!(formik.touched.driverId && formik.errors.driverId)
                    }
                  >
                    <option value="">
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {PeoplesList &&
                      PeoplesList?.filter((f) => f.typeCode === "DRIVERS").map(
                        (item, idx) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === "ar" ? item.name2 : item.name}
                          </option>
                        )
                      )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.driverId}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="input-group c">
                  <span className="input-group-text">{t("InspectionDate")}</span>

                  <Form.Control
                    className="form-control"
                    type="datetime-local"
                    name="scanningDate"
                    onChange={formik.handleChange}
                    value={formik.values.scanningDate || ""}
                    isInvalid={
                      !!(
                        formik.touched.scanningDate &&
                        formik.errors.scanningDate
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.scanningDate}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="input-group c">
                  <span className="input-group-text">{t("Note")}</span>

                  <Form.Control
                    className="form-control"
                    type="text"
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description || ""}
                    isInvalid={
                      !!(
                        formik.touched.description && formik.errors.description
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.description}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>

            {PeriodicallyScanningById &&
              PeriodicallyScanningById?.details?.slice().sort((a,b) => a.elementSort -b.elementSort).map((item, index) => (
                <div
                  key={index}
                  className="row mb-2 bg-inverse bg-opacity-10 rounded-3 p-2"
                >
                  <div className="col-xl-2">
                    {i18n.language === "en" ? item.elementName : item.elementName2}
                  </div>
                  <div className="col-xl-5">
                    {i18n.language === "en"
                      ? item.elementDescription
                      : item.elementDescription2}
                  </div>

                  <div className="col-xl-2 bg-inverse bg-opacity-10 rounded-3 pt-3 text-center">
                    <div className="form-group mb-3">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          name={`details[${index}].value`}
                          type="radio"
                          value="1"
                          onChange={formik.handleChange}
                          checked={
                            (formik.values.details &&
                              formik.values.details[index] &&
                              formik.values.details[index].value === "1") ||
                            false
                          }
                        />
                        <label className="form-check-label">نعم</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          name={`details[${index}].value`}
                          type="radio"
                          value="0"
                          onChange={formik.handleChange}
                          checked={
                            (formik.values.details &&
                              formik.values.details[index] &&
                              formik.values.details[index].value === "0") ||
                            false
                          }
                        />
                        <label className="form-check-label">لا</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3">
                    <div className="input-group">
                      <Form.Control
                        className="form-control"
                        as="textarea"
                        rows={2}
                        name={`details[${index}].description`}
                        onChange={formik.handleChange}
                        placeholder="ملاحظات .."
                        value={
                          (formik.values.details &&
                            formik.values.details[index] &&
                            formik.values.details[index].description) ||
                          ""
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}

            <div className="text-danger">
              {formik.touched.details &&
                formik.errors.details?.length &&
                "* جميع البنود مطلوبة"}
            </div>
            <div className="text-center">
            <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
          >
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fa fa-save fa-fw me-1"></i>
            )}
            {t("Save")}
          </button>
              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                onClick={() => Navigate(-1)}
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default UpdateScan;
