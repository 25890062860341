import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { PurchaseQuotationModels, PurchaseQuotationByIdModels } from './purchaseQuotationModels';

export const addPurchaseQuotation = createAsyncThunk(
  'PurchaseQuotation/addPurchaseQuotation',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('PurchaseQuotation/Add', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updatePurchaseQuotation = createAsyncThunk(
  'PurchaseQuotation/updatePurchaseQuotation',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('PurchaseQuotation/Update', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPurchaseQuotationList = createAsyncThunk(
  'PurchaseQuotation/getPurchaseQuotationList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('PurchaseQuotation/GetList');
      if (data?.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPurchaseQuotationById = createAsyncThunk(
  'PurchaseQuotation/getPurchaseQuotationById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`PurchaseQuotation/GetById?Id=${id}`);
      if (data?.succeeded) {
        // ===
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  PurchaseQuotationList: PurchaseQuotationModels[];
  PurchaseQuotationById: PurchaseQuotationModels;
  loading: boolean;
  error: any | null;
} = {
  PurchaseQuotationList: [],
  PurchaseQuotationById: {} as PurchaseQuotationModels,
  loading: false,
  error: null,
};

const PurchaseQuotationSlice = createSlice({
  name: 'PurchaseQuotation',
  initialState,
  reducers: {
    cleanUpPurchaseQuotationById: (state) => {
      state.PurchaseQuotationById = {} as PurchaseQuotationModels;
    },
  },
  extraReducers(builder) {
    builder

      .addCase(getPurchaseQuotationList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getPurchaseQuotationList.fulfilled,
        (state, action: PayloadAction<PurchaseQuotationModels[]>) => {
          state.loading = false;
          state.PurchaseQuotationList = action.payload;
        }
      )
      .addCase(getPurchaseQuotationList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getPurchaseQuotationById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getPurchaseQuotationById.fulfilled,
        (state, action: PayloadAction<PurchaseQuotationModels>) => {
          state.loading = false;
          if (action.payload && typeof action.payload === 'object') {
            state.PurchaseQuotationById = action.payload;
          } else if (action.payload === null) {
            state.PurchaseQuotationById = {} as PurchaseQuotationModels;
          }
          // state.PurchaseQuotationById = action.payload;
        }
      )
      .addCase(getPurchaseQuotationById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { cleanUpPurchaseQuotationById } = PurchaseQuotationSlice.actions;
export default PurchaseQuotationSlice.reducer;
