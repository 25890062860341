import Swal from 'sweetalert2';
import i18n from 'i18next';

const confirmAction = (message: string): Promise<boolean> => {
  return Swal.fire({
    title: i18n.language === 'en' ? 'Confirmation' : 'تأكيد',
    text: message,
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText: i18n.language === 'en' ? 'Cancel' : 'إلغاء',
    confirmButtonText: i18n.language === 'en' ? 'Yes' : 'نعم',
    background: 'var(--bs-body-bg)',
    iconColor: 'var(--bs-theme)',
    color: 'var(--bs-heading-color)',
    customClass: {
      container: 'border',
      popup: 'border border-theme border-3 rounded-5',
    },
  }).then((result) => {
    return result.isConfirmed;
  });
};

export default confirmAction;