import React, { useState } from 'react';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../components/card/card';
import { Link } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import useGetQuotationList from '../../../../hooks/Sales/useGetQuotationList';

interface QuoteInvoiceListProps {
  RefrenceType: 'SellOrder' | 'SalesInvoices';
}

const QuoteInvoiceList = ({ RefrenceType }: QuoteInvoiceListProps) => {
  const { SalesQuotationList, loading } = useGetQuotationList();

  //   const [quoteInvoice, setQuoteInvoice] = useState<SaleTransactionsModels>();

  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  // const Navigate = useNavigate();

  const filteredSearch = SalesQuotationList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  return (
    <Card>
      <Loading loading={loading}>
        <div>
          <div className="table-responsive">
            <table className="table table-hover text-nowrap">
              <thead className="bg-theme bg-opacity-30 rounded-0 p-1">
                <tr>
                  <th scope="col">{t('رقم العرض ')}</th>
                  <th scope="col">{t('تاريخ العرض ')}</th>
                  <th scope="col">{t('العميـل ')}</th>
                  <th scope="col">{t('الفرع ')}</th>
                  <th scope="col">{t('قيمة العرض ')}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {currentData?.map((item, index) => (
                  <tr key={item.id}>
                    <td className="align-middle"> {item.code} </td>
                    <td className="align-middle"> {item.transactionDate} </td>
                    <td className="align-middle"> {item.peopleName2} </td>
                    <td className="align-middle"> {item.organizationName2} </td>
                    <td className="align-middle"> غير موجود</td>

                    <td>
                      <Link
                        className="btn btn-outline-theme"
                        to={
                          RefrenceType === 'SalesInvoices'
                            ? `/SalesTransactions/SalesInvoices/AddSalesInvoices/${item.id}`
                            : RefrenceType === 'SellOrder'
                            ? `/SalesTransactions/SellOrder/AddSellOrder/${item.id}`
                            : ''
                        }
                        //   state={{quoteInvoice : item}}
                      >
                        إنشــاء
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            filteredData={filteredSearch}
            dataPerPage={dataPerPage}
            handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
            currentPage={currentPage}
          />
        </div>
      </Loading>
    </Card>
  );
};

export default QuoteInvoiceList;
