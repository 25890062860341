import * as Yup from "yup";



 export const ValidationSchemaAddChart = (t : any) =>{
    return Yup.object().shape({
    reportId: Yup.string()
    .required(() => t("Required")),
    shapeId: Yup.string()
      .required(() => t("Required")),
    name: Yup.string()
      .required(() => t("Required")),
    name2: Yup.string()
      .required(() => t("Required")),
      groupBy: Yup.string()
      .required(() => t("Required")),
    reportChartKpiList: Yup.array().of(
      Yup.object().shape({
        columnName: Yup.string()
          .required(() => t("Required")),
        kpiName: Yup.string()
          .required(() => t("Required")),
          // groupBy: Yup.string()
          // .required(() => t("Required")),
        dbFunction: Yup.string()
          .required(() => t("Required")),
      })
    ),
  });
 }  

