import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";




const initialState = { FlexValueList: [], loading: false, error: null , TablesFormList:[]  };

  export const GetFlexValue = createAsyncThunk("FlexValue/GetFlexValue", async (id, thunkAPI) => {  
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`FlexValueSet/GetFlexValueList?FlexValueSetId=${id}`);
      const {data} = await ntxAPI.get(`FlexValueSet/GetFlexValueList?FlexValueSetId=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });



  export const insertFlexValue = createAsyncThunk("FlexValue/AddFlexValue", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("FlexValueSet/AddFlexValue" , item);
      const {data} = await ntxAPI.post( "FlexValueSet/AddFlexValue", item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });


  export const DeleteFlexValue = createAsyncThunk("FlexValue/DeleteFlexValue", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData(`FlexValueSet/DeleteFlexValue?FlexValueId=${id}`);
      const {data} = await ntxAPI.post(`FlexValueSet/DeleteFlexValue?FlexValueId=${id}`)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });







  const FlexValueSlice = createSlice({
    name: "FlexValue",
    initialState,
    reducers: { },
    extraReducers: (builder) => {
      // get FlexValueList
      builder
        .addCase(GetFlexValue.pending, (state) => {
          state.loading = true;
          state.error = null;
          state.FlexValueList = [];
        })
        .addCase(GetFlexValue.fulfilled, (state, action) => {
          state.loading = false;
          state.FlexValueList = action.payload;
        })
        .addCase(GetFlexValue.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        // Add FlexValueList
        .addCase(insertFlexValue.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(insertFlexValue.fulfilled, (state, action) => {
          state.loading = false;
          state.FlexValueList.push(action.payload);
        })
        .addCase(insertFlexValue.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        // Delete FlexValue
        .addCase(DeleteFlexValue.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(DeleteFlexValue.fulfilled, (state, action) => {
          state.loading = false;
          state.FlexValueList = state.FlexValueList?.filter((el) => el.id !== action.payload);
        })
        .addCase(DeleteFlexValue.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });

  export default FlexValueSlice.reducer;