import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { PurchasesReturnsModels } from './purchasesReturnModels';



export const addPurchasesReturns = createAsyncThunk(
  'PurchaseReturn/addPurchasesReturns',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('PurchasesReturns/Add', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updatePurchasesReturns= createAsyncThunk(
  'PurchaseReturn/updatePurchasesReturns',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('PurchasesReturns/Update', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPurchasesReturnsList = createAsyncThunk(
  'PurchaseReturn/getPurchasesReturnsList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('PurchasesReturns/GetList');
      if (data?.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPurchasesReturnsById = createAsyncThunk(
  'PurchaseReturn/getPurchasesReturnsById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`PurchasesReturns/GetById?Id=${id}`);
      if (data?.succeeded) {
        // ===
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  PurchaseReturnList: PurchasesReturnsModels[];
  PurchaseReturnById: PurchasesReturnsModels;
  loading: boolean;
  error: any | null;
} = {
  PurchaseReturnList: [],
  PurchaseReturnById: {} as PurchasesReturnsModels,
  loading: false,
  error: null,
};

const PurchaseReturnSlice = createSlice({
  name: 'PurchaseReturn',
  initialState,
  reducers: {
    cleanUpPurchasesReturnsById: (state) => {
      state.PurchaseReturnById = {} as PurchasesReturnsModels;
    },
  },
  extraReducers(builder) {
    builder

      .addCase(getPurchasesReturnsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getPurchasesReturnsList.fulfilled,
        (state, action: PayloadAction<PurchasesReturnsModels[]>) => {
          state.loading = false;
          state.PurchaseReturnList = action.payload;
        }
      )
      .addCase(getPurchasesReturnsList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })




      .addCase(getPurchasesReturnsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getPurchasesReturnsById.fulfilled,
        (state, action: PayloadAction<PurchasesReturnsModels>) => {
          state.loading = false;
          state.PurchaseReturnById = action.payload;
        }
      )
      .addCase(getPurchasesReturnsById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { cleanUpPurchasesReturnsById } = PurchaseReturnSlice.actions;
export default PurchaseReturnSlice.reducer;
