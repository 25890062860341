import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import useLookupCurrency from "../../../hooks/Lookups/use-Lookup-Currency";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { UpdatPriceList } from "../../../store/INV/PriceList/PriceListSlice";
import CustomAlert from "../../../components/Shared/Alert/CustomAlert";
import { PriceListModels } from "../../../store/INV/PriceList/PriceListModels";

type props = {
  Id: string;
};
const Update = ({ Id }: props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { Currency } = useLookupCurrency();
  const [data, setData] = useState<PriceListModels>();

  const { PriceList } = useAppSelector((state) => state.PriceList);

  useEffect(() => {
    const dataById = PriceList.find((f) => f.id === Id);

    setData(dataById);
  }, [Id, PriceList]);

  const formik = useFormik({
    initialValues: {
      id: data ? data.id : "",
      name: data ? data.name : "",
      name2: data ? data.name2 : "",
      description: data ? data.description : "",
      typeCode: data ? data.typeCode : "",
      currencyCode: data ? data.currencyCode : "",
      startDateActive: data ? data.startDateActive : "",
      endDateActive: data ? data.endDateActive : "",
    },

    // validationSchema: formOrganization(t),
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        UpdatPriceList({
          id: values.id,
          name: values.name,
          name2: values.name2,
          description: values.description,
          typeCode: values.typeCode,
          currencyCode: values.currencyCode,
          startDateActive: values.startDateActive,
          endDateActive: values.endDateActive,
          priceListItemsList: [],
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded === true) {
            formik.resetForm();
            document.getElementById("AddPriceList")?.click();
            CustomAlert({ action: "Edit" });
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error" });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
      formik.setSubmitting(false);
    },
  });
  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label className="form-label">{t("LocalName")}</label>
              <Form.Control
                className="form-control fw-bold"
                type="text"
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name2}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label className="form-label">{t("GlobalName")}</label>
              <Form.Control
                className="form-control fw-bold"
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group mb-3">
              <label className="form-label">{t("startDateActive")}</label>
              <Form.Control
                className="form-control fw-bold"
                type="date"
                name="startDateActive"
                onChange={formik.handleChange}
                value={formik.values.startDateActive}
                isInvalid={
                  !!(
                    formik.touched.startDateActive &&
                    formik.errors.startDateActive
                  )
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.startDateActive}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group mb-3">
              <label className="form-label">{t("endDateActive")}</label>
              <Form.Control
                className="form-control fw-bold"
                type="date"
                name="endDateActive"
                onChange={formik.handleChange}
                value={formik.values.endDateActive}
                isInvalid={
                  !!(
                    formik.touched.endDateActive && formik.errors.endDateActive
                  )
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.endDateActive}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group mb-3">
              <label className="form-label"> {t("Currency")} </label>
              <Form.Select
                className="form-select"
                name="currencyCode"
                onChange={formik.handleChange}
                value={formik.values.currencyCode}
                isInvalid={
                  !!(formik.touched.currencyCode && formik.errors.currencyCode)
                }
                isValid={
                  formik.touched.currencyCode && !formik.errors.currencyCode
                }
              >
                {Currency &&
                  Currency?.map((item: any, idx: number) => (
                    <option key={++idx} value={item.currencyCode}>
                      {item.description}
                    </option>
                  ))}
              </Form.Select>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
            id="SaveSubmit"
          >
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fa fa-save fa-fw me-2"></i>
            )}
            {t("Save")}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm"
          >
            <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default Update;
