import React  from "react";
import { Dispatch, SetStateAction } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TagsInput } from "react-tag-input-component";
import { Step4Modals } from "./Step4Modals";

interface Props {
  Columns: any[];
  formData: Step4Modals;
  setFormData: Dispatch<SetStateAction<Step4Modals>>;
}

const EmailTab: React.FC<Props> = ({ Columns, formData, setFormData }) => {
  const { t, i18n } = useTranslation();


 
 

 

  return (
    <div>
      <div className="row">
        <div className="col-xl-6 m-auto">
          <div className="form-group mb-3">
            <label className="form-label">العنوان</label>
            <Form.Control
              className="form-control"
              type="text"
              value={formData.emailSubject}
              onChange={(e) =>
                setFormData({ ...formData, emailSubject: e.target.value })
              }
            />
          </div>
        </div>

        <div className="col-xl-6 m-auto">
          <div className="form-group mb-3">
            <label className="form-label">To</label>

            <TagsInput
              value={formData.emailTo}
              onChange={(newTags) =>
                setFormData({ ...formData, emailTo: newTags })
              }
            />
          </div>
        </div>

        <div className="col-xl-6 m-auto">
          <div className="form-group mb-3">
            <label className="form-label">CC</label>
            <TagsInput
              value={formData.emailCC}
              onChange={(newTags) =>
                setFormData({ ...formData, emailCC: newTags })
              }
            />
          </div>
        </div>

        <div className="col-xl-6 m-auto">
          <div className="form-group mb-3">
            <label className="form-label">بريد تلقائي</label>
            <Form.Select
              className="form-select"
              name="Name"
              value={formData.emailColumn}
              onChange={(e) =>
                setFormData({ ...formData, emailColumn: e.target.value })
              }
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {Columns &&
                Columns.map((item, idx) => (
                  <option key={++idx} value={item.AliasName}>
                    {i18n.language === "en" ? item.name : item.name}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailTab;
