import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { PosPaymentMethodModel } from './PosModels';
import { APIResponse } from '../../Shared/shared';

export const addPosPaymentMethod = createAsyncThunk(
  'PosPaymentMethod/AddPosPaymentMethod',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Pos/AddPosPaymentMethod', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetPosPaymentMethodById = createAsyncThunk(
  'PosPaymentMethod/GetPosPaymentMethodById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`Pos/GetPosPaymentMethodById?Id=${id}`);
      if (data?.succeeded) {
        //===
        return data.data;
      } else {
        return rejectWithValue(data?.message || "هناك خطأ");
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetPosPaymentMethodByPosId = createAsyncThunk(
  'PosPaymentMethod/GetPosPaymentMethodByPosId',
  async (posId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`Pos/GetPosPaymentMethodByPosId?PosId=${posId}`);
      if (data?.succeeded) {
        //===
        return data.data;
      } else {
        return rejectWithValue(data.message);      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const deletePosPaymentMethod = createAsyncThunk(
  'PosPaymentMethod/deletePosPaymentMethod',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<boolean>>(`Pos/DeletePosPaymentMethod?Id=${id}`);
      if (data.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const initialState: {
  // PosCashierList: PosCashierModel[];
  PosPaymentMethodListByPosId: PosPaymentMethodModel[];
  PosPaymentMethodById: PosPaymentMethodModel;
  loading: boolean;
  error: any | null;
} = {
  PosPaymentMethodListByPosId: [],
  PosPaymentMethodById: {} as PosPaymentMethodModel,
  loading: false,
  error: null,
};

const PosPaymentMethodSlice = createSlice({
  name: 'PosPaymentMethod',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetPosPaymentMethodByPosId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetPosPaymentMethodByPosId.fulfilled,
        (state, action: PayloadAction<PosPaymentMethodModel[]>) => {
          state.loading = false;
          state.PosPaymentMethodListByPosId = action.payload;
        }
      )
      .addCase(GetPosPaymentMethodByPosId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      //

      .addCase(GetPosPaymentMethodById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetPosPaymentMethodById.fulfilled,
        (state, action: PayloadAction<PosPaymentMethodModel>) => {
          state.loading = false;
          state.PosPaymentMethodById = action.payload;
        }
      )
      .addCase(GetPosPaymentMethodById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
    //
  },
});

export default PosPaymentMethodSlice.reducer;
