import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../components/card/card';
import Loader from '../../../components/Shared/Loader/Loader';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../components/Shared/Pagination/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { GetPriceListList } from '../../../store/INV/PriceList/PriceListSlice';
import MedModalComponent from '../../../components/Modal/MedModalComponent';
import AddPriceList from './AddVoucher';
import Update from './Update';
import { GetDiscountVoucherList } from '../../../store/Sales/DiscountVouchers/DiscountVouchersSlice';
import useGetDiscountVoucherTypesList from '../../../hooks/Sales/Vouchers/useGetDiscountVoucherTypesList';

const Index = () => {
  const [Show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();

  const [id, setId] = useState('');

  // const { DiscountVoucherTypesList, loading: loading2 } = useGetDiscountVoucherTypesList();
  const { DiscountVoucherTypesList, loading: loading2 } = useGetDiscountVoucherTypesList();

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'القسـائم',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('القســـــــائم')} />

        {/* <div className="ms-auto">
          <Link
            to="AddVouchers"
            className="btn me-1 btn-outline-theme"
            onClick={() => setShow(true)}
          >
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            اضافة
          </Link>
        </div> */}
      </div>
      {/* <Card> */}
      {/* <Loader loading={loading} /> */}
      <CardBody>
        <div className="p-1">
          <div className="row">
            {/* <div className="col-12 col-md-12 d-flex justify-content-start">
                <div className="input-group mb-4">
                  <div className="flex-fill position-relative">
                    <div className="input-group">
                      <div
                        className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                        style={{ zIndex: 1020, right: '10px' }}>
                        <i className="fa fa-search opacity-5"></i>
                      </div>
                      <input
                        type="text"
                        className="form-control ps-35px"
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={t('Search') + ' ...'}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
            {/* <div className="col-5 col-md-6 text-end"></div> */}
          </div>
          {/* //  START TABLE   */}

          <div className="row">
            {DiscountVoucherTypesList?.map((item, index) => (
              <div className="col-xl-4 col-md-6" key={item.id}>
                <div className="mailbox-detail-content">
                  <div className="mailbox-attachment1 w-100">
                    <Link
                      // onClick={() => Navigate(`/SelfService/Requests/${item.id}/AddRequest`)}
                      onClick={() => Navigate(`${item.id}/VoucherType`)}
                      to="">
                      <div className="document-file1 h-100px">
                        <i className={'fas fa-gift pt-2'}></i>
                      </div>
                      <div className="document-name">
                        {i18n.language === 'ar' ? item.name2 : item.name}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </CardBody>
      {/* </Card> */}

      {/* <MedModalComponent title={'اضافة قائمة أسعار'} id="AddPriceList">
        {Show && <AddPriceList />}
      </MedModalComponent>

      <MedModalComponent title={'تعديل'} id="EditPriceList">
        {id && <Update Id={id} />}
      </MedModalComponent> */}
    </div>
  );
};

export default Index;
