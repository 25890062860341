import React from "react";
import Form from "react-bootstrap/Form";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { Card, CardBody } from "../../../../components/card/card";
import { useTranslation } from "react-i18next";

function DaysCard({ dayName, day, formik }) {
  const { values, handleChange } = formik;
  const dayValues = values[day];



  const { t, i18n } = useTranslation();
  return (
    <div>
      <Card className="m-2">
        <CardHeader className="fw-bold small bg-theme bg-opacity-25">
          {dayName}
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t("StatusOfPermanence")}</label>
                <Form.Select
                  className="form-select"
                  name={`${day}.countFingerprint`}
                  value={dayValues.countFingerprint || ""}
                  onChange={handleChange}
                >
                  <option value="0">
                    {i18n.language === "ar" ? "إجــازة" : "Off"}
                  </option>
                  <option value="1">
                    {i18n.language === "ar" ? "بصمة" : "One Fingerprint"}
                  </option>
                  <option value="2">
                    {i18n.language === "ar" ? "بصمتــان" : "Tow Fingerprint"}
                  </option>
                  <option value="3">
                    {i18n.language === "ar" ? "أربع بصمات" : "Four Fingerprint"}
                  </option>
                </Form.Select>
              </div>
            </div>

            {dayValues.countFingerprint !== "0" && (
              <>
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t("ExtraDay")}</label>
                    <Form.Check
                      style={{ margin: "10px 20px" }}
                      type="switch"
                      id="custom-switch"
                      label=""
                      name={`${day}.isExtraDay`}
                      value={dayValues.isExtraDay || ""}
                      checked={dayValues.isExtraDay || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t("Flexible")}</label>
                    <Form.Check
                      style={{ margin: "10px 20px" }}
                      type="switch"
                      id="custom-switch"
                      label=""
                      name={`${day}.isFlexible`}
                      value={dayValues.isFlexible || ""}
                      checked={dayValues.isFlexible || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="row">
            {(dayValues.countFingerprint === "1" ||
              dayValues.countFingerprint === "2" ||
              dayValues.countFingerprint === "3") && (
              <div className="col-xl-6">
                <div className="input-group mb-3 ">
                  <span className="input-group-text">
                    {t("OneFingerprint")}
                  </span>
                  <Form.Control
                    type="time"
                    className="form-control"
                    name={`${day}.fingerprintTime1`}
                    value={dayValues.fingerprintTime1 || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}

            {(dayValues.countFingerprint === "2" ||
              dayValues.countFingerprint === "3") && (
              <div className="col-xl-6">
                <div className="input-group mb-3 ">
                  <span className="input-group-text">
                    {t("TowFingerprint")}
                  </span>
                  <Form.Control
                    type="time"
                    className="form-control"
                    name={`${day}.fingerprintTime2`}
                    value={dayValues.fingerprintTime2 || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}
          </div>

          {dayValues.countFingerprint === "3" && (
            <div className="row">
              <div className="col-xl-6">
                <div className="input-group mb-3 ">
                  <span className="input-group-text">
                    {t("ThreeFingerprint")}
                  </span>
                  <Form.Control
                    type="time"
                    className="form-control"
                    name={`${day}.fingerprintTime3`}
                    value={dayValues.fingerprintTime3 || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="input-group mb-3 ">
                  <span className="input-group-text">
                    {t("FourFingerprint")}
                  </span>
                  <Form.Control
                    type="time"
                    className="form-control"
                    name={`${day}.fingerprintTime4`}
                    value={dayValues.fingerprintTime4 || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          )}

          {dayValues.countFingerprint !== "0" && (
            <div className="row">
              <div className="col-xl-6">
                <div className="input-group mb-3 ">
                  <span className="input-group-text">{t("AllowIn")}</span>
                  <Form.Control
                    type="time"
                    className="form-control"
                    name={`${day}.allowIn`}
                    value={dayValues.allowIn || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="input-group mb-3 ">
                  <span className="input-group-text">{t("AllowOut")}</span>
                  <Form.Control
                    type="time"
                    className="form-control"
                    name={`${day}.allowOut`}
                    value={dayValues.allowOut || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          )}

          {dayValues.isFlexible && (
            <div className="row">
              <div className="col-xl-6">
                <div className="input-group mb-3 ">
                  <span className="input-group-text">
                    {t("start Flexible")}
                  </span>
                  <Form.Control
                    type="time"
                    className="form-control"
                    name={`${day}.startFlexible`}
                    value={dayValues.startFlexible || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="input-group mb-3 ">
                  <span className="input-group-text">{t("end Flexible")}</span>
                  <Form.Control
                    type="time"
                    className="form-control"
                    name={`${day}.endFlexible`}
                    value={dayValues.endFlexible || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default DaysCard;
