import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import { editEmployee, getEmployees } from '../../../../../store/HR/Person/employeeSlice';
import { EditPersonSchema } from '../../../../ValidationForm/validationSchema';
import { useDispatch } from 'react-redux';
import ExtraInformationBuilderUpdate from '../../../../ExtraInformation/ExtraInformationBuilder/Update/ExtraInformationBuilderUpdate';
import EditEmployees from './EditEmployees';
import useEmpDetails from '../../../../../hooks/HR/use-employee-details.js';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody } from '../../../../../components/card/card.jsx';
import SkeletonForm from '../../../../../components/Shared/SkeletonLoader/SkeletonForm.js';
import useAddUpdateExtraInformation from '../../../../ExtraInformation/ExtraInformationBuilder/useAddUpdateExtraInformation.js';
import { GetDynamicFormColumnListByTableId } from '../../../../../store/NTX/FlexValue/Form/FormColumnSlice.js';
import { useSelector } from 'react-redux';
import ExtraInformationBuilderAdd from '../../../../ExtraInformation/ExtraInformationBuilder/Add/ExtraInformationBuilderAdd.js';
import { usePermissions } from '../../../../../Helper/Permisstions/usePermissions';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import Loading from '../../../../../components/Shared/Loader/Loading';
import i18n from '../../../../../i18n';

const UpdatePerson = () => {
  const { HasPermission } = usePermissions();

  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { t } = useTranslation();
  const { AddUpdateExtraInformation } = useAddUpdateExtraInformation();
  const [extraInfoFormData, setExtraInfoFormData] = useState({});
  const { loading: dataLoading, Employee, error } = useEmpDetails();

  //__________ جلب حقول المعلومات الإضافية ان وجدت __________
  const TableId = 'cd5aa419-0e0b-48fe-a66f-f004d97964a2'; // جدول الموظفين id
  useEffect(() => {
    if (Employee?.extraInformationId === null) {
      dispatch(GetDynamicFormColumnListByTableId(TableId));
    }
  }, [Employee, dispatch]);
  const { FormColumnByTableIdList } = useSelector((state) => state.FormColumns);
  //_________________________________________________________

  const formik = useFormik({
    initialValues: {
      id: Employee ? Employee.id : null,
      ExtraInformationId: Employee ? Employee.extraInformationId : null,
      employeeNumber: Employee ? Employee.employeeNumber : '',
      NAME2: Employee ? Employee.name2 : '',
      NAME: Employee ? Employee.name : '',
      NATIONAL_IDENTIFIER: Employee ? Employee?.nationalIdentifier : '',
      START_DATE: Employee ? Employee?.startDate : '',
      DATE_OF_BIRTH: Employee ? Employee?.dateOfBirth : '',
      MARITAL_STATUS: Employee ? Employee?.maritalStatus : '',
      GENDER: Employee ? Employee?.gender : '',
      NATIONALITY: Employee ? Employee?.nationality : '',
      TITLE: Employee ? Employee?.title : '',
      COUNTRY_OF_BIRTH: Employee ? Employee?.countryOfBirth : '',
      TOWN_OF_BIRTH: Employee ? Employee?.townOfBirth : '',

      BankId: Employee ? Employee?.bankId : '',
      // IBan: Employee ? Employee?.iBan : '',
      IBan: Employee ?? Employee.iBan === 'null' ? '' : Employee.iBan,

      Religion: Employee ? Employee?.religion : '',
      RotationPlanId: Employee ? Employee?.rotationPlanId : '',
      AnnualLeave: Employee ? Employee?.annualLeave : 0,
      Person_Img: Employee ? Employee?.personImage?.filePath : '',

      Is_Update_IMG: false,

      emailAddress: Employee ? Employee?.emailAddress : '',
      mobile: Employee ? Employee?.mobile : '',
    },
    enableReinitialize: true,
    validationSchema: EditPersonSchema(t),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append('Id', values.id);
      // formData.append("EmployeeNumber", values.employeeNumber);
      formData.append('Name2', values.NAME2);
      formData.append('Name', values.NAME);
      formData.append('NationalIdentifier', values.NATIONAL_IDENTIFIER);
      formData.append('StartDate', values.START_DATE);
      formData.append('DateOfBirth', values.DATE_OF_BIRTH);
      formData.append('MaritalStatus', values.MARITAL_STATUS);
      formData.append('Gender', values.GENDER);
      formData.append('Nationality', values.NATIONALITY);
      formData.append('Title', values.TITLE);
      formData.append('CountryOfBirth', values.COUNTRY_OF_BIRTH);
      formData.append('TownOfBirth', values.TOWN_OF_BIRTH);
      formData.append('PERSON_IMG', values.Person_Img);
      formData.append('IBan', values.IBan);
      formData.append('Religion', values.Religion);
      formData.append('AnnualLeave', values.AnnualLeave === null ? '' : values.AnnualLeave);
      formData.append('Religion', values.Religion);
      formData.append('BankId', values.BankId === null ? '' : values.BankId);

      formData.append('EmailAddress', values.emailAddress === null ? '' : values.emailAddress);
      formData.append('Mobile', values.mobile === null ? '' : values.mobile);
      formData.append(
        'RotationPlanId',
        values.RotationPlanId === null ? '' : values.RotationPlanId
      );
      formData.append('Is_Update_IMG', values.Is_Update_IMG);
      let respons;
      if (extraInfoFormData?.values?.length > 0) {
        respons = await AddUpdateExtraInformation(extraInfoFormData);
      }
      if (respons === null) {
        CustomAlert({ action: 'Error' });
        return;
      }
      // debugger
      formData.append('ExtraInformationId', respons || '');
      dispatch(editEmployee(formData))
        .unwrap()
        .then((res) => {
          Navigate('/HR/Employees', { replace: true });
          CustomAlert({ action: 'Edit' });
          dispatch(getEmployees());
          formik.resetForm();
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Employees',
      link: '/HR/Employees',
    },
    {
      name: t('EmployeeDetails'),
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName={t('EmployeeDetails')}
        subtitle={i18n.language === 'ar' ? Employee.name2 : Employee.name}
      />

      <Card>
        <CardBody>
          <Loading loading={dataLoading} error={error}>
            <Form onSubmit={formik.handleSubmit}>
              <EditEmployees formik={formik} />

              <div className="  rounded-4 p-3 mb-3 border border-theme">
                {Employee?.extraInformationId ? (
                  <ExtraInformationBuilderUpdate
                    extraInformationId={formik.values.ExtraInformationId}
                    referenceId={Employee?.id}
                    setExtraInfoFormData={setExtraInfoFormData}
                  />
                ) : (
                  <ExtraInformationBuilderAdd
                    FormColumnList={FormColumnByTableIdList}
                    setExtraInfoFormData={setExtraInfoFormData}
                    referenceId={Employee?.id}
                  />
                )}
              </div>

              <div className="text-center">
                {HasPermission('EditHrPerson') && (
                  <button
                    disabled={formik.isSubmitting}
                    type="submit"
                    className="btn me-1 btn-theme mb-1">
                    <i className="fa fa-save fa-fw me-1"></i>
                    {formik.isSubmitting ? '...' : t('Save')}
                  </button>
                )}

                <button
                  type="button"
                  className=" btn me-1 btn-default mb-1"
                  onClick={() => Navigate(-1, { replace: true })}>
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </Form>
          </Loading>
        </CardBody>
      </Card>
    </>
  );
};

export default UpdatePerson;
