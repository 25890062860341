import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { GetMasterItemsList } from "../../../store/INV/MasterItems/MasterItemsSlice";
import { useTranslation } from "react-i18next";

const useGetItemsList = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  useEffect(() => {
    if (MasterItemsList?.length === 0) {
      dispatch(GetMasterItemsList());
    }
  }, []);
  const { MasterItemsList, loading ,error } = useAppSelector(
    (state) => state.MasterItemsINV
  );

  const ItemsOptions = MasterItemsList?.map((item) => ({
    value: item.id,
    label: `${i18n.language === "en" ? item.name : item.name2 } | ${item.itemCode} `,
    itemName: `${i18n.language === "en" ? item.name : item.name2 }`,
    salesPrice : item.salesPrice,
    purchasePrice : item.purchasePrice,
    itemNo : item.itemCode ,
    uom : item.unitOfMeasure,
    categoryId : item.categoryId,
  }));

  return { MasterItemsList , ItemsOptions , loading , error};
};

export default useGetItemsList;
