import React, { useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import MedModalComponent from '../../../../components/Modal/MedModalComponent';
import AddPos from './AddPos';
import Loading from '../../../../components/Shared/Loader/Loading';
import PosCashier from '../PosCashier/PosCashier';
import useGetPosList from '../../../../hooks/POS/useGetPosList';

const Index = () => {
  const [Show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  const [PosId, setPosId] = useState('');


  const {PosList , error ,loading} = useGetPosList()

  

  const filteredSearch = PosList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'إعدادات نقاط البيع',
      link: null,
    },
  ];

  return (
    <>
      
        <div>
          <div className="d-flex align-items-center">
            <Breadcrumb
              BreadcrumbList={BcrumbList}
              PageName={t('إعدادات نقاط البيع')}
            />

            <div className="ms-auto">
              <Link
                to=""
                className="btn me-1 btn-outline-theme"
                data-bs-toggle="modal"
                data-bs-target="#AddPos"
                onClick={() => setShow(true)}
              >
                <i className="fa fa-plus-circle fa-fw me-1"></i>
                {t('نقطة بيع جديدة')}
              </Link>
            </div>
          </div>
          <Card>
            {/* <Loader loading={loading} /> */}
            <Loading loading={loading} error={error}>
            <CardBody>
              <div className="tab-content p-2">
                <div className="row">
                  <div className="col-7 col-md-6 d-flex justify-content-start">
                    <div className="input-group mb-4">
                      <div className="flex-fill position-relative">
                        <div className="input-group">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}
                          >
                            <i className="fa fa-search opacity-5"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder={t('Search') + ' ...'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-5 col-md-6 text-end"></div>
                </div>
                {/* //  START TABLE   */}
                <div className="table-responsive">
                  <table className="table table-hover text-nowrap" style={{tableLayout : 'fixed'}}>
                    <thead className="table-dark">
                      <tr>
                        <th scope="col">{t('Name')}</th>
                        <th scope="col">{t("Type")}</th>
                        <th scope="col">{t("Organization")}</th>
                        <th scope="col">{t("المخزن")}</th>
                        <th scope="col">{t("isActive")}</th>
                        <th scope="col" className=''>الإجراءات</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData?.map((item, index) => (
                        <tr key={item.id}>
                          <td className="align-middle">
                            {i18n.language === 'en' ? item.name : item.name2}
                          </td>
                          <td className="align-middle">
                            {item.posType === 'shop' ? 'محل': "مطعم"}
                          </td>
                          <td className="align-middle"> Organization </td>
                          <td className="align-middle"> المخزن  </td>

                          <td className="align-middle">
                      {/* {item.isActive ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">not Active</span>} */}
                    {item.isActive ? (
                        <span className="badge d-block rounded-0 pt-5px w-70px bg-success text-theme-900">
                          {t("isActive")}
                        </span>
                      ) : (
                        <span className="badge d-block rounded-0 pt-5px w-70px text-bg-warning bg-opacity-25">
                          {t("InActive")}
                        </span>
                      )}
                    </td>

                          <td>
                            <div className="btn-group">

                              <button
                                type="button"
                                className="btn btn-outline-theme"
                                onClick={() =>
                                  Navigate(`${item.id}/PosDetails`)
                                }
                              >
                                {/* <i className="far fa-lg me-2 bi bi-list-stars"></i> */}
                                التفاصيــــل
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  filteredData={filteredSearch}
                  dataPerPage={dataPerPage}
                  handlePagination={(pageNumber: any) =>
                    setCurrentPage(pageNumber)
                  }
                  currentPage={currentPage}
                />
              </div>
            </CardBody>

            </Loading>
          </Card>

          <MedModalComponent title={'نقطة بيع جديدة'} id="AddPos">
            {Show && <AddPos />}
          </MedModalComponent>

          <MedModalComponent title={'Pos Cashier'} id="ModalPosCashier">
            {PosId && <PosCashier posId={PosId}/>}
          </MedModalComponent>
        </div>
      
    </>
  );
};

export default Index;





// <button
// type="button"
// className="btn btn-outline-theme"
// data-bs-toggle="modal"
// data-bs-target="#ModalEditPos"
// // onClick={() => setId(item.id)}
// >
// <i className="far fa-lg fa-fw me-2 fa-edit"></i>
// {t('Button.Edit')}
// </button>
// <button
// type="button"
// className="btn btn-outline-theme"
// data-bs-toggle="modal"
// data-bs-target="#ModalPosCashier"
// onClick={() =>
//   setPosId(item.id||"")
// }
// >
// {/* <i className="far fa-lg me-2 bi bi-list-stars"></i> */}
// Pos Cashier
// </button>
