import { ntxAPI } from "../../../API/axiosNeatex";
import { ntxheader } from "../../../API/variables";

const useAddRequest = () => {
  const AddRequest = async (requestFormData) => {

    console.log(JSON.stringify(requestFormData));
    // debugger;
    const formData = new FormData();
    formData.append("SelectedPersonId", requestFormData.selectedPersonId);
    formData.append("IsPersonaly", requestFormData.isPersonaly);
    formData.append("DynamicFormId", requestFormData.dynamicFormId);
    formData.append("ReferenceId", requestFormData.referenceId);

    for (let i = 0; i < requestFormData?.values?.length; i++) {
      const value = requestFormData?.values[i];

      formData.append(
        `Values[${i}].dynamicFormColumnID`,
        value.dynamicFormColumnID 
      );
      formData.append(`Values[${i}].dataType`, value.dataType);
      formData.append(`Values[${i}].value`, value.value || "");
      formData.append(`Values[${i}].lableText`, value.lableText);
      formData.append(`Values[${i}].lableText2`, value.lableText2);
      formData.append(`Values[${i}].sequenceNum`, value.sequenceNum);

      for (let j = 0; j < value?.fileAttachment?.length; j++) {
        formData.append(`Values[${i}].fileAttachment`, value.fileAttachment[j]);
      }

      formData.append(`Values[${i}].isUpdateAttach`, value.isUpdateAttach);
    }

    
    try {
      const { data } = await ntxAPI.post("AmeRequest/AddRequest", formData,             {
        headers: {
          ...ntxheader,
          'Content-Type': 'multipart/form-data;'
        },
      }
      );
      return data;
    } catch (err) {
      return false;
    }
  };

  return { AddRequest };
};

export default useAddRequest;
