/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import TestComponent from "../../../ExtraInformation/ExtraRecordsDynamicForm/BuilderExtraRecord/Tabs/TestComponent";
import { GetFormRecordWithValue } from "../../../../store/ExtraInformation/extraInformationSlice";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";

const ExtraRecordRealEstate = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const { RealEstateId } = useParams(); // ReferenceId
  const dispatch = useDispatch();
  const [refreshData, setRefreshData] = useState(true);
  //__________________________________________________
  const tableId = "ffe70bb0-1f6a-475e-945f-94faa967f80e"; // جدول الوحدات العقارية

  useEffect(() => {
    if (refreshData) {
      dispatch(
        GetFormRecordWithValue({
          TableId: tableId,
          ReferenceId: RealEstateId,
        })
      );
      setRefreshData(false);
    }
  }, [refreshData]);
  const { FormRecordWithValueList } = useSelector(
    (state) => state.ExtraInformation
  );

  //#region
  const [referenceName, setReferenceName] = useState(null); // State to hold ReferenceName
  useEffect(() => {
    if (location.state && location.state.ReferenceName) {
      const { ReferenceName } = location.state;
      if (ReferenceName) {
        setReferenceName(ReferenceName); // Update ReferenceName state
      }
    }
  }, []);
  //#endregion
  //_____________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "RealEstate",
      link: "/SU/RealEstate",
    },
    {
      name: "السجلات الإضـافية",
      link: null,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb BreadcrumbList={BcrumbList} subtitle={referenceName} />
      {/* PageName="السجلات الإضافية" */}
      <TestComponent
        setRefreshData={setRefreshData}
        FormRecordWithValueList={FormRecordWithValueList}
        ReferenceId={RealEstateId}
      />
    </Fragment>
  );
};

export default ExtraRecordRealEstate;
