import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { useNavigate } from 'react-router-dom';
import { Card } from '../../../components/card/card.jsx';
import { useTranslation } from 'react-i18next';

// import Pagination from "../../../components/Pagination/Pagination.jsx";
import useLookupModule from '../../../hooks/Lookups/use-Lookup-Module.js';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import Pagination from '../../../components/Shared/Pagination/Pagination.jsx';
import useGetDynamicForm from '../../../hooks/Forms/useGetDynamicForm.js';
import { usePermissions } from '../../../Helper/Permisstions/usePermissions.ts';
import Loading from '../../../components/Shared/Loader/Loading';

const RequestSettings = () => {
  const { HasPermission } = usePermissions();

  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const [selectedValue, setSelectedValue] = useState(null);
  const Navigate = useNavigate();

  const { DynamicFormAme, loading, error } = useGetDynamicForm();
  const { OptionsModule, loading: loading2 } = useLookupModule();


  const filteredSearch = DynamicFormAme?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.filter((f) => (selectedValue === null ? true : f.moduleId === selectedValue));

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  ///////////////////////////////

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    // {
    //   name: t('ApprovalManagement'),
    //   link: null,
    // },
    {
      name: t('RequestsSettings'),
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('RequestsSettings')} />

      <Card>
        <Loading loading={loading} error={error}>
          <div className="tab-content p-3">
            <div className="row mb-2">
              <div className="col-lg-6">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">
                    {t('Search')}
                    {/* <i className="fa fa-search  text-theme ms-3"></i> */}
                  </span>
                  <div className="input-group">
                    <div className="flex-fill position-relative">
                      <div className="input-group border-0 ">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5 text-theme"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          // placeholder={t('Search')}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

                <div className="col-lg-6">
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text  fw-bold">{t('Application')}</span>
                    <Select
                      classNamePrefix="react-select"
                      className="w-100"
                      isLoading={loading2}
                      isSearchable={true}
                      isClearable
                      options={[...OptionsModule]}
                      onChange={(option) => {
                        setSelectedValue(option === null ? null : option.value);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                  </div>
                </div>
            </div>

            <div className="table-responsive">
              <table className="table table-hover text-nowrap">
                <thead className="table-dark">
                  <tr>
                    <th scope="col" style={{ width: '25%' }}>
                      {t('Name')}
                    </th>
                    <th scope="col" style={{ width: '25%' }}>
                      {t('Application')}
                    </th>
                    <th scope="col" style={{ width: '25%' }}>
                      {t('Icon')}
                    </th>
                    <th scope="col" style={{ width: '25%' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle">
                        {i18n.language === 'ar' ? item.name2 : item.name}
                      </td>

                      <td className="align-middle">
                        {i18n.language === 'ar' ? item.moduleName2 : item.moduleName}
                      </td>
                      <td className="align-middle">
                        <div className="text-theme fs-4">
                          <i className={item.icon}></i>
                        </div>
                      </td>
                      <td className="text-end">
                        <div className="">
                          {HasPermission('AmeViewListConditions') && (
                            <button
                              type="button"
                              onClick={() => Navigate(`${item.id}/AddAmeCondition`)}
                              className="btn btn-outline-theme me-2">
                              <i className="fas fa-lg fa-fw me-2 fa-question"></i>
                              {t('Conditions')}
                            </button>
                          )}

                          {HasPermission('AmeViewListRulesforOrdering') && (
                            <button
                              type="button"
                              className="btn btn-outline-theme"
                              onClick={() =>
                                Navigate(`${item.id}/AddAmeRule`, {
                                  state: {
                                    ReferenceName: i18n.language === 'en' ? item.name : item.name2,
                                  },
                                })
                              }>
                              <i className="fas fa-lg fa-fw me-2 fa-filter"></i>
                              {t('Rules')}
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />

            {/* </CardBody> */}
          </div>
        </Loading>
      </Card>
    </>
  );
};

export default RequestSettings;
