import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { CategoriesModels } from './CategoriesModels';

export const AddCategories = createAsyncThunk(
  'CategoriesINV/AddCategories',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/AddCategories', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdatCategories = createAsyncThunk(
  'CategoriesINV/UpdatCategories',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/UpdateCategories', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetCategoriesList = createAsyncThunk(
  'CategoriesINV/GetCategoriesList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('Inv/GetCategoriesList');
      if (data.succeeded === true) {
        return data.data;
      } else {
        return [];
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  CategoriesList: CategoriesModels[];
  loading: boolean;
  error: any | null;
} = {
  CategoriesList: [],
  loading: false,
  error: null,
};

const CategoriesSlice = createSlice({
  name: 'CategoriesINV',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(AddCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddCategories.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        if (action?.payload?.succeeded === true) {
          state.CategoriesList.push(action.payload.data);
        }
      })
      .addCase(AddCategories.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(GetCategoriesList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetCategoriesList.fulfilled, (state, action: PayloadAction<CategoriesModels[]>) => {
        state.loading = false;
        state.CategoriesList = action.payload;
      })
      .addCase(GetCategoriesList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default CategoriesSlice.reducer;
