import React, { ReactNode } from 'react';
// import SkeletonForm from './SkeletonLoader/SkeletonForm';
// import SkeletonTable from './SkeletonLoader/SkeletonTable';
// import SkeletonGrid from './SkeletonLoader/SkeletonGrid';
// import SkeletonForm2 from './SkeletonLoader/SkeletonForm2';
// import SkeletonForm3 from './SkeletonLoader/SkeletonForm3';
// import SkeletonSettings from './SkeletonLoader/SkeletonSettings';
import Loader from './Loader';
// import Placeholder from 'react-bootstrap/Placeholder';
// import Alert from 'react-bootstrap/Alert';
// import { Placeholder, Message } from 'rsuite'
// import loaderImg from "./loader.gif";
// import Test from '../pages/Test/Test';
import { Icon } from '@iconify/react';

interface LoadingProps {
  loading: boolean;
  error?: string | null;
  children: ReactNode;
  SkeletonType?: 'Form' | 'Table' | 'Grid' | 'Form2' | 'Form3' | 'Settings';
  Type?: 'Logo' | 'Dots';
}

const Loading: React.FC<LoadingProps> = ({ loading, error, children, SkeletonType, Type }) => {
  const renderHandler = () => {
    return (
      <>
        {loading ? (
          <>
            {Type === 'Dots' ? (
              <div className="text-center p-2 ">
                <div className="spinner-grow text-success"></div>
                <div className="spinner-grow text-danger"></div>
                <div className="spinner-grow text-warning"></div>
              </div>
            ) : (
              <div style={{ minHeight: '300px' }}>
                <Loader loading={true} />
              </div>
            )}
          </>
        ) : error ? (
          <div
            className="d-flex flex-column align-items-center justify-content-center text-center"
            style={{ minHeight: '300px' }}>
            <div className="">
              <i
                className="bi bi-exclamation-triangle display-1 text-theme "
                style={{ fontSize: '7em' }}></i>
              <div className="d-flex p-0 m-0 bg-theme bg-opacity-15 rounded-0 p-1 shadow">
                <h5 className="font-weight-bold m-0">Oops! Something went wrong </h5>
              </div>
            </div>
          </div>
        ) : (
          children
        )}
      </>
    );
  };

  return renderHandler();
};

export default Loading;

// 					<div className="col-xl-3 col-lg-4 col-sm-6 d-flex">
// 	<div className="w-50px h-50px bg-theme bg-opacity-15 text-theme fs-32px d-flex align-items-center justify-content-center">
// 		<Icon icon="solar:monitor-smartphone-line-duotone" />
// 	</div>
// 	<div className="flex-1 ps-3">
// 		<h4>Responsive Design</h4>
// 		<p className="mb-0">Optimized for all devices, ensuring a seamless and exceptional user experience everywhere.</p>
// 	</div>
// </div>
