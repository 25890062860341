import React, { useEffect, useState } from 'react';
import 'gridstack/dist/gridstack.css';
import { useNavigate, useParams } from 'react-router-dom';
import useGetDashboardDetails from '../../../../hooks/NTX/useGetDashboardDetails';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { ntxAPI } from '../../../../API/axiosNeatex';
import BuildGrid from './BuildGrid';
import Charts from '../../../Charts/Index';
import Loading from '../../../../components/Shared/Loader/Loading';
import { useDispatch } from 'react-redux';
import { GetDashboardDetails } from '../../../../store/NTX/Reports/Dashboard/dashboardSlice';

const BuilderDashboard = () => {
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState({ fromDate: '', toDate: '' });
  const [dashboardItems, setDashboardItems] = useState([]);
  const [itemsForUpdate, setItemsForUpdate] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t, i18n } = useTranslation();
  const { dashboardId } = useParams();
  const { DashboardDetailsById: data, loading, error } = useGetDashboardDetails(dashboardId);
  // const [rerender, setRerender] = useState(false);
  // const  navigate = useNavigate();

  useEffect(() => {
    if (data && Object?.keys(data)?.length > 0) {
      const newItems = data.dashboardChartList.map((m) => ({
        id: m.id,
        x: m.xPosition,
        y: m.yPosition,
        w: m.width,
        h: m.height,
        reportChartId: m.reportChartId,
        content: (
          <Charts
            chartId={m.reportChartId}
            chartDateFrom={dateRange.fromDate}
            chartDateTo={dateRange.toDate}
          />
        ),
      }));
      setDashboardItems(newItems);
      setItemsForUpdate(newItems);
    }
  }, [data, dateRange.fromDate, dateRange.toDate, dispatch]);

  const handleFilterButtonClick = async () => {
    if (Object.keys(data).length > 0) {
      setDashboardItems([]);
      await new Promise((resolve) => setTimeout(resolve, 150));
      const updatedItems = data.dashboardChartList.map((m) => ({
        id: m.id,
        x: m.xPosition,
        y: m.yPosition,
        w: m.width,
        h: m.height,
        reportChartId: m.reportChartId,
        content: (
          // <DashboardCharts
          //   chartId={m.reportChartId}
          //   chartDateFrom={dateRange.fromDate}
          //   chartDateTo={dateRange.toDate}
          // />

          <Charts
            chartId={m.reportChartId}
            chartDateFrom={dateRange.fromDate}
            chartDateTo={dateRange.toDate}
          />
        ),
      }));
      setDashboardItems(updatedItems);
    }
  };

  const handleUpdateDashboardChartPosition = async () => {
    let request = {
      chartList: itemsForUpdate.map((item) => ({
        id: item.id,
        xPosition: item.x,
        yPosition: item.y,
        width: item.w,
        height: item.h,
      })),
      dashboardId: dashboardId,
    };
    try {
      setIsSubmitting(true);
      const { data } = await ntxAPI.post('Reports/UpdateDashboardChartPosition', request);
      if (data?.succeeded === true) {
        dispatch(GetDashboardDetails(dashboardId));
        CustomAlert({ action: 'Edit' });
        setIsSubmitting(false);
      } else {
        CustomAlert({ action: 'Error' });
        setIsSubmitting(false);
      }
    } catch (err) {
      CustomAlert({ action: 'Error' });
      setIsSubmitting(false);
    }
  };

  return (
    <Loading loading={loading} error={error}>
      <div>
        <h1 className="page-header">
          {(i18n.language === 'en' ? data?.name : data?.name2) || ''}
          {/* <small>Test 3</small> */}
        </h1>

        <div className="row mb-2">
          <div className="col-md-3">
            <div className="input-group mb-3 mx-2">
              <span className="input-group-text">من</span>
              <input
                type="date"
                className="form-control text-center"
                name="DateFrom"
                value={dateRange.fromDate}
                onChange={(e) => setDateRange({ ...dateRange, fromDate: e.target.value })}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="input-group mb-3 mx-2">
              <span className="input-group-text">إلى</span>
              <input
                type="date"
                className="form-control text-center"
                name="DateTo"
                value={dateRange.toDate}
                onChange={(e) => setDateRange({ ...dateRange, toDate: e.target.value })}
              />
            </div>
          </div>

          <div className="col-md-4 ">
            <button
              type="button"
              className="btn btn-outline-theme"
              onClick={handleFilterButtonClick}>
              <i className="fas fa-lg fa-fw me-2 fa-filter"></i> تحديث البيانات
            </button>
          </div>

          <div className="col-md-2 text-end">
            <button
              disabled={isSubmitting}
              className="btn btn-theme"
              onClick={handleUpdateDashboardChartPosition}>
              {isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="fa fa-save fa-fw me-2"></i>
              )}
              {t('Save')}
            </button>
          </div>
        </div>

        {dashboardItems?.length > 0 && (
          <BuildGrid dashboardItems={dashboardItems} setItemsForUpdate={setItemsForUpdate} />
        )}
      </div>
    </Loading>
  );
};

export default BuilderDashboard;
