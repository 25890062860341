import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody } from '../../../../../components/card/card';
import Loader from '../../../../../components/Shared/Loader/Loader';
import Pagination from '../../../../../components/Shared/Pagination/Pagination';
import { GetAssignmentActionList } from '../../../../../store/HR/Payroll/runPayrollSlice';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import Loading from '../../../../../components/Shared/Loader/Loading';

const AssignmentAction = () => {
  const { payrollId } = useParams();
  const { PayrollActionId } = useParams();
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => state.runPayroll);
  const data = useSelector((state) => state.runPayroll.AssignmentActionList);

  useEffect(() => {
    dispatch(GetAssignmentActionList(PayrollActionId));
  }, []);

  //________________________________

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = data
    ?.filter((item) => item?.name?.toLowerCase().includes(search.toLowerCase()))
    ?.slice(indexOfFirstData, indexOfLastData);

  const handlePagination = (pageNumber) => setCurrentPage(pageNumber);

  //_______________________
  //#region useLocation
  const location = useLocation();
  const [referenceName, setReferenceName] = useState(null); // State to hold ReferenceName
  useEffect(() => {
    if (location.state && location.state.ReferenceName) {
      const { ReferenceName } = location.state;
      if (ReferenceName) {
        setReferenceName(ReferenceName); // Update ReferenceName state
      }
    }
  }, []);
  //#endregion
  //_______________________

  const handleOpenTab = (item) => {
    localStorage.setItem('personName2', item.name2);
    localStorage.setItem('personName', item.name);
    const newWindow = window.open(
      `/Report/${item.id}/PayrollForPerson`,
      '_blank',
      'width=1000,height=800'
    );
    newWindow.focus();
  };

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('RunPayrolls'),
      link: '/Pay/payrollsRun',
    },
    {
      name: t('ShowAllRuns'),
      link: `/Pay/payrollsRun/${payrollId}/ActionList`,
      //
    },

    {
      name: t('SalaryStatement'),
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName={t('SalaryStatement')}
        subtitle={referenceName}
      />

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="tab-content p-2">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-2">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={handleSearch}
                          placeholder={t('Search') + ' ...'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-5 col-md-6 text-end">
                  <div className="btn-group">
                    <button
                      onClick={() => Navigate(-1, { replace: true })}
                      type="button"
                      className=" btn me-1 btn-default">
                      <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        const newWindow = window.open(
                          `/Report/${PayrollActionId}/PayrollForAll`,
                          '_blank',
                          'width=1300,height=700'
                        );
                        newWindow.focus();
                      }}
                      className="btn btn-theme">
                      <i className="fas fa-lg fa-fw me-2 fa-print"></i>
                      {t('PrintAll')}
                    </button>
                  </div>
                </div>
              </div>

              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table table-hover text-nowrap">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col"> {t('EmployeeName')}</th>
                      <th scope="col">{t('Job')}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      currentData.map((item) => (
                        <tr key={item.id}>
                          <td className="align-middle">
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </td>
                          <td className="align-middle">
                            {i18n.language === 'ar' ? item.jobName2 : item.jobName}
                          </td>
                          <td className="align-middle text-end">
                            <div className="btn-group">
                              <button
                                type="button"
                                // onClick={() => Navigate(`${item.id}/PayrollData`)}
                                // onClick={() => openInNewTab(`/Report/${item.id}/PayrollData`)}
                                // onClick={() => window.open( `/Report/${item.id}/PayrollForPerson`, '_blank')}
                                onClick={() => handleOpenTab(item)}
                                className="btn btn-outline-theme">
                                <i className="fas fa-lg fa-fw me-2 fa-print"></i>
                                {t('Print')}
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={data}
                // .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
                dataPerPage={dataPerPage}
                handlePagination={handlePagination}
                currentPage={currentPage}
              />
            </div>
          </CardBody>
        </Loading>
      </Card>
    </>
  );
};

export default AssignmentAction;
