import { useFormik } from 'formik';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { TransactionsLinesList } from '../../../../store/Sales/SalesInvoices/SalesInvoicesModels';
import { Card, CardFooter } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import useGetPersons from '../../../../hooks/HR/use-get-persons';
import useGetOrganizations from '../../../../hooks/HR/use-get-organizations';
import TransactionsLines2 from '../TransactionsLines/TransactionsLines2';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch } from '../../../../store/hooks';
import useGetPeoplesList from '../../../../hooks/NTX/useGetPeoplesList';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import { AddQuoteInvoiceSchema } from '../../../ValidationForm/validationSchema';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import { addQuotationTransactions } from '../../../../store/Sales/SalesQuotation/salesQuotationSlice';
import { useContext, useEffect } from 'react';
import { AppSettings } from '../../../../config/app-settings';
import useGetCustomersList from '../../../../hooks/NTX/Peoples/useGetCustomersList';
import { TreeSelect } from 'antd';

const Add = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { Currency, loading: loading4 } = useLookupCurrency();
  const { dataEmployees, loading } = useGetPersons();
  const { BranchsOptions, InventoryOptions, loading: loading3 } = useGetOrganizations();

  const {
    OptionCustomers,
    CustomerList,
    treeCustomerData,
    loading: loading2,
  } = useGetCustomersList();

 
  //#region Formik
  const formik = useFormik({
    initialValues: {
      no: 0, // رقم الفاتورة
      code: 'Qut-24-',
      name: 'Quotation',
      name2: 'عرض سعر',
      transTypeId: '42616040-A579-4A64-BEC2-79D70F41B13D', //  ثابت بناءة على النوع
      organizationId: '', // الفروع
      peopleId: '', //العميل
      recipientname: '', // اسم العميل وممكن يتعدل
      billToLocationId: null,
      shipToLocationId: null,
      referenceId: null,
      transactionDate: new Date().toISOString().split('T')[0], // تاريخ الفاتورة
      deliveryDate: '', //  تاريخ التوصبل
      expirationDate: '', //  تاريخ الإنتهاء
      dueDate: '', //  تاريخ الإستحقاق
      paymentTermsId: '', //  شروط الدفع .. الخاص بالعميل من جدول العميل تلقائيا
      description: '', //  وصف الفاتورة
      grossSale: '', //   سعر الصرف * الكمية
      discountRate: '', //   نسبة الخصم
      discountAmount: '', //   مبلغ الخصم
      vat: '', //   قيمة الضريبة
      netSale: '', //   الصافي مجموع اللاينس
      contractId: '', //   العقد
      ameStatus: '', //
      personId: '', //   المندوب
      paymentTerms: '', //
      deliveryTerm: '', //
      inventoryId: '', //
      waybill: '', //
      phone: '', //
      currencyCode: 'SR', //
      currencyConversionRate: '', //
      batchId: '', //
      transactionsLinesList: [
        {
          description: '',
          discountAmount: 0,
          discountRate: 0,
          itemId: '',
          lineNumber: 0,
          price: 0,
          qty: 1,
          total: 0,
          uom: '',
          vatAmount: 0,
          vatRate: 0,
        },
      ] as TransactionsLinesList[],
    },

    enableReinitialize: true,
    validationSchema: AddQuoteInvoiceSchema(t),
    onSubmit: (values) => {
      const { transactionsLinesList, ...restValues } = values;

      const grossSale = values.transactionsLinesList.reduce(
        (s, a) => Number(s) + Number(a.total),
        0
      );

      if (values.transactionsLinesList.length === 0) {
        SwalAlert({ text: 'أدخل أصناف' });
        formik.setSubmitting(false);

        return;
      }

      dispatch(
        addQuotationTransactions({
          // no: values.no,
          // code: values.code + values.no, // Qut-24-1233
          // name: values.name,
          // name2: values.name2,
          // transTypeId: values.transTypeId,
          organizationId: values.organizationId,
          peopleId: values.peopleId,
          recipientname: values.recipientname,
          billToLocationId: billToLocationId,
          shipToLocationId: shipToLocationId,
          referenceId: values.referenceId,
          transactionDate: values.transactionDate,
          deliveryDate: values.deliveryDate || null,
          expirationDate: values.expirationDate || null,
          dueDate: values.dueDate || null,
          paymentTermsId: paymentTermsId,
          paymentMethodId: null,
          description: values.description,
          grossSale: grossSale,
          discountRate: values.discountRate || 0,
          discountAmount: values.discountAmount || 0,
          vat: values.vat || 0,
          netSale: values.netSale || 0,
          contractId: values.contractId || null,
          ameStatus: values.ameStatus || 0,
          personId: values.personId,
          paymentTerms: values.paymentTerms,
          deliveryTerm: values.deliveryTerm,
          inventoryId: values.inventoryId,
          waybill: values.waybill,
          phone: values.phone,
          currencyCode: values.currencyCode,
          currencyConversionRate: values.currencyConversionRate || 0,
          batchId: values.batchId || null,
          transactionsLinesList: formik.values.transactionsLinesList.map((item) => ({
            itemId: item.itemId,
            lineNumber: item.lineNumber,
            uom: item.uom,
            description: item.description,
            price: Number(item.price),
            qty: Number(item.qty),
            discountRate: Number(item.discountRate),
            discountAmount: Number(item.discountAmount),
            vatRate: Number(item.vatRate),
            total: Number(item.total),
            vatAmount: Number(item.vatAmount),
            // unitCost: Number(item.unitCost),
            codeCombinationId: '54F915DF-8A22-486D-B860-08DC2A376B50',
            // currencyCode: 'SR',
            // currencyConversionRate: 0,
            // lotId: null,
            // headerId: null,
            // lineId: null,
            inventoryId: values.inventoryId,
          })),
        })
      )
        .unwrap()
        .then((res: any) => {
          debugger;
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched } = formik;
  //#endregion

  const paymentTermsId = CustomerList.find((f) => f.id === values.peopleId)?.termsId;
  const shipToLocationId = CustomerList.find((f) => f.id === values.peopleId)?.shipToLocationId;
  const billToLocationId = CustomerList.find((f) => f.id === values.peopleId)?.billToLocationId;

  //#region BcrumbList
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'فواتيـر مبيعات',
      link: '/SalesTransactions/SalesInvoices',
    },
    {
      name: 'عرض سعـر جديد',
      link: null,
    },
  ];
  //#endregion

  const context = useContext(AppSettings);
  useEffect(() => {
    context.setAppTopNav(true);
    context.setAppSidebarNone(true);

    return function cleanUp() {
      context.setAppTopNav(false);
      context.setAppSidebarNone(false);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="عرض سعـر جديد" />

      <Card>
        <Loading loading={loading || loading2 || loading3 || loading4}>
          <CardBody>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                {/* <div className="col-lg-3">
                  <div className="form-group flex-nowrap mb-3">
                    <label className="form-label">{t('رقم عرض السعر')}</label>

                    <div className="input-group flex-nowrap">
                      <Form.Control
                        autoComplete="off"
                        className="form-control  text-center"
                        type="text"
                        name="no"
                        onChange={formikhandleChange}
                        value={values.no || ''}
                        isInvalid={!!(touched.no && errors.no)}
                      />
                      <span className="input-group-text" id="addon-wrapping">
                        -Qut
                      </span>
                    </div>
                    <div className="text-danger">{formik.errors.no}</div>
                  </div>
                </div> */}

                <div className="col-lg-2">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('التاريخ')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="date"
                      name="transactionDate"
                      onChange={formikhandleChange}
                      value={values.transactionDate || ''}
                      // isInvalid={!!(touched.transactionDate && errors.transactionDate)}
                    />

                    <div className="text-danger">{formik.errors.transactionDate}</div>
                  </div>
                </div>

                <div className="col-lg-2">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('تاريخ الإنتهاء')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="date"
                      name="expirationDate"
                      onChange={formikhandleChange}
                      value={values.expirationDate || ''}
                      // isInvalid={!!(touched.expirationDate && errors.expirationDate)}
                    />
                    <div className="text-danger">{formik.errors.expirationDate}</div>
                  </div>
                </div>

                <div className="col-lg-2">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('Currency')} </label>
                    <Form.Select
                      className="form-select"
                      name="currencyCode"
                      onChange={formikhandleChange}
                      value={values.currencyCode || ''}
                      // isInvalid={!!(touched.currencyCode && errors.currencyCode)}
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {Currency &&
                        Currency?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.currencyCode}>
                            {item.description}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger">{formik.errors.currencyCode}</div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('العميل')}</label>

            

                    <TreeSelect
                      showSearch
                      // value={value}
                      className="w-100"
                      treeLine
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      allowClear
                      // treeDefaultExpandAll
                      onChange={(option: any) => {  
                      
                        formik.setFieldValue('peopleId', option === null ? null : option);
                      }}
                      treeData={treeCustomerData}
                      treeNodeFilterProp='title'
                    />

                    <div className="text-danger">{formik.errors.peopleId}</div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('مندوب المبيعات')}</label>
                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading}
                      isSearchable={true}
                      isClearable
                      name="personId"
                      options={dataEmployees}
                      onChange={(option: any) => {
                        formik.setFieldValue('personId', option === null ? null : option.value);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger">{formik.errors.personId}</div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('المخزن')}</label>

                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading3}
                      isSearchable={true}
                      isClearable
                      options={InventoryOptions}
                      onChange={(option: any) => {
                        formik.setFieldValue('inventoryId', option === null ? null : option.value);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger">{formik.errors.inventoryId}</div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الفرع')}</label>

                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading3}
                      isSearchable={true}
                      isClearable
                      options={BranchsOptions}
                      onChange={(option: any) => {
                        formik.setFieldValue(
                          'organizationId',
                          option === null ? null : option.value
                        );
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger">{formik.errors.organizationId}</div>
                  </div>
                </div>

                {/* <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("اسم العميل")}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control text-center"
                    type="text"
                    name="recipientname"
                    onChange={formikhandleChange}
                    value={values.recipientname || ""}
                  />
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("تاريخ التوصيل")}</label>
                  <Form.Control
                    className="form-control  text-center"
                    type="date"
                    name="deliveryDate"
                    onChange={formikhandleChange}
                    value={values.deliveryDate || ""}
                    isInvalid={
                      !!(touched.deliveryDate && errors.deliveryDate)
                    }
                    // isValid={
                    //   formik.touched.deliveryDate &&
                    //   !formik.errors.deliveryDate
                    // }
                  />
                </div>
              </div> */}

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('مدة التوريد')}</label>
                    <Form.Control
                      className="form-control"
                      as="textarea"
                      rows={1}
                      name="deliveryTerm"
                      onChange={formik.handleChange}
                      value={values.deliveryTerm}
                      isInvalid={!!(touched.deliveryTerm && errors.deliveryTerm)}
                      // isValid={touched.deliveryTerm && !errors.deliveryTerm}
                    />
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الدفع')}</label>
                    <Form.Control
                      className="form-control"
                      as="textarea"
                      rows={1}
                      name="paymentTerms"
                      onChange={formik.handleChange}
                      value={values.paymentTerms}
                      isInvalid={!!(touched.paymentTerms && errors.paymentTerms)}
                      // isValid={touched.paymentTerms && !errors.paymentTerms}
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الشروط')}</label>
                    <Form.Control
                      className="form-control"
                      as="textarea"
                      rows={2}
                      name="description"
                      onChange={formik.handleChange}
                      value={values.description}
                      isInvalid={!!(touched.description && errors.description)}
                      // isValid={touched.description && !errors.description}
                    />
                  </div>
                </div>

                {/* <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Note')}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control"
                    as="textarea"
                    rows={1}
                    name="description"
                    onChange={formik.handleChange}
                    value={values.description}
                    isInvalid={!!(touched.description && errors.description)}
                    // isValid={touched.description && !errors.description}
                  />
                </div>
              </div> */}
              </div>

              <CardFooter>
                <div className="row  ">
                  <TransactionsLines2 formik={formik} />
                  {formik.errors.transactionsLinesList?.length && (
                    <div className="text-danger"> * هناك حقول مطلوبة </div>
                  )}
                </div>
              </CardFooter>

              <div className="text-center mt-2">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn btn-lg me-1 btn-theme mb-1">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                  )}
                  {t('Add')}
                </button>
                <button
                  type="button"
                  className=" btn btn-lg me-1 btn-default mb-1"
                  onClick={() => navigate('/SalesTransactions/Quotation', { replace: true })}>
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </Form>
          </CardBody>
        </Loading>
      </Card>
    </div>
  );
};

export default Add;
