import { t } from 'i18next';

// <option>
// {i18n.language === "ar" ? "إختر" : "Choose"}
// </option>
// <option value="ANSWER">
// {i18n.language === "ar" ? "إجــابة" : "ANSWER"}
// </option>
// </>
// ) : (
// <>
// <option>
// {i18n.language === "ar" ? "إختر" : "Choose"}
// </option>
// <option value="APPROVED">
// {i18n.language === "ar" ? "قبول" : }
// </option>
// <option value="REJECTED">
// {i18n.language === "ar" ? "رفض" : "REJECTED"}
// </option>
// <option value="TRANSFER">
// {i18n.language === "ar" ? "تحويـل" : "TRANSFER"}
// </option>
// <option value="QUESTION">
// {i18n.language === "ar" ? "سـؤال" : "QUESTION"}
// </option>

export const OptionAme = [
  {
    value: 'APPROVED',
    label: (
      <span className=" text-success fw-bold px-2 pt-5px pb-5px rounded fs-16px d-inline-flex align-items-center px-5">
        <i className="bi bi-check-square-fill fs-18px fa-fw me-5px"></i>
        {t('APPROVED')}
      </span>
    ),
  },

  {
    value: 'REJECTED',
    label: (
      <span className=" text-danger fw-bold  px-2 pt-5px pb-5px rounded fs-16px d-inline-flex align-items-center px-5">
        <i className="bi bi-x-circle-fill fs-18px fa-fw me-5px"></i>
        {t('REJECTED')}
      </span>
    ),
  },

  {
    value: 'TRANSFER',
    label: (
      <span className=" text-warning fw-bold px-2 pt-5px pb-5px rounded fs-16px d-inline-flex align-items-center px-5">
        <i className="bi bi-sign-turn-right-fill fs-18px fa-fw me-5px"></i>
        {t('TRANSFER')}
      </span>
    ),
  },

  {
    value: 'QUESTION',
    label: (
      <span className=" text-indigo fw-bold px-2 pt-5px pb-5px rounded fs-16px d-inline-flex align-items-center px-5">
        <i className="bi bi-question-circle-fill fs-18px fa-fw me-5px"></i>
        {t('QUESTION')}
      </span>
    ),
  },

  {
    value: 'ANSWER',
    label: (
      <span className=" text-theme fw-bold px-2 pt-5px pb-5px rounded fs-16px d-inline-flex align-items-center px-5">
        <i className="bi bi-chat-left-dots-fill fs-18px fa-fw me-5px"></i>
        {t('ANSWER')}
      </span>
    ),
  },

  {
    value: 'NOTIFIED',
    label: (
      <span className="text-info fw-bold px-2 pt-5px pb-5px rounded fs-16px d-inline-flex align-items-center px-5">
        <i className="bi bi-eye-fill fs-18px fa-fw me-5px"></i>
        {t('NOTIFIED')}
      </span>
    ),
  },
];
