import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../../../../components/Shared/Loader/Loader";
import { GetAbsenceAttendanceView } from "../../../../../store/HR/Person/absenceSlice";

const ViewAbsence = ({ isAme =false ,ReferenceId}) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const { Id } = useParams();

  var ID = isAme ? ReferenceId : Id

  useEffect(() => {
    dispatch(GetAbsenceAttendanceView(ID));
  }, []);
  const data = useSelector(
    (state) => state.Absence.AbsenceAttendanceViewList.data
  );
  const loading = useSelector((state) => state.Absence.loading);

  return (
    <>
      <Loader loading={loading} />
      <div className="row">
        <div className="col-xl-2"></div>

        <div className="col-xl-8">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th
                  className="table-active"
                  style={{ maxWidth: "50px", textAlign: "center" }}
                >
                  {t('Absence_Type')}
                </th>
                <td>
                  {i18n.language === "ar"
                    ? data?.absenceName2
                    : data?.absenceName}
                </td>
              </tr>

              <tr>
                <th
                  colSpan={1}
                  scope="row"
                  className="table-active"
                  style={{ maxWidth: "50px", textAlign: "center" }}
                >
                 {t("Start Date")}
                </th>
                <td colSpan={4}>{data?.dateStart}</td>
              </tr>

              <tr>
                <th
                  colSpan={1}
                  scope="row"
                  className="table-active"
                  style={{ maxWidth: "50px", textAlign: "center" }}
                >
                  {t("Date_TO")}
                </th>
                <td colSpan={4}>{data?.dateEnd}</td>
              </tr>

              <tr>
                <th
                  colSpan={1}
                  scope="row"
                  className="table-active"
                  style={{ maxWidth: "50px", textAlign: "center" }}
                >
                 {t("Days")}
                </th>
                <td colSpan={4}>{data?.absenceDays}</td>
              </tr>

              <tr>
                <th
                  colSpan={1}
                  scope="row"
                  className="table-active"
                  style={{ maxWidth: "50px", textAlign: "center" }}
                >
                  {t("Authorising_Person")}
                </th>
                <td colSpan={4}>
                  {i18n.language === "ar"
                    ? data?.authorisingName2
                    : data?.authorisingName}
                </td>
              </tr>

              <tr>
                <th
                  colSpan={1}
                  scope="row"
                  className="table-active"
                  style={{ maxWidth: "50px", textAlign: "center" }}
                >
                 {t("replacement")}
                </th>
                <td colSpan={4}>
                  {i18n.language === "ar"
                    ? data?.replacemenName2
                    : data?.replacemenName}
                </td>
              </tr>

              <tr>
                <th
                  colSpan={1}
                  scope="row"
                  className="table-active"
                  style={{ maxWidth: "50px", textAlign: "center" }}
                >
                  {t("Notes")}
                </th>
                <td colSpan={4}>{data?.comments}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="col-xl-2"></div>
      </div>

      <div />
    </>
  );
};

export default ViewAbsence;
