import React from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import useGetOrganizations from "../../../../hooks/HR/use-get-organizations";
import { useTranslation } from "react-i18next";
import {
  getPositions,
  insertPosition,
} from "../../../../store/HR/HRSetting/positionSlice";
import { formPosition } from "../../../ValidationForm/validationSchema";
import { useEffect } from "react";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import useGetLocations from "../../../../hooks/HRSetting/use-get-locations";
import { getJobs } from "../../../../store/HR/HRSetting/jobSlice";

const AddPosition = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const { Organizations } = useGetOrganizations();

  const { Jobs } = useSelector((state) => state.Jobs);
  useEffect(() => {
    dispatch(getJobs());
  }, [dispatch]);
  //
  const { Locations } = useGetLocations();

  ///
  const formik = useFormik({
    initialValues: {
      jobId: "",
      organizationId: "",
      locationId: "",
      name: "",
      name2: "",
      comments: "",
    },

    validationSchema: formPosition(t),

    onSubmit: (values) => {
      dispatch(
        insertPosition({
          jobId: values.jobId,
          organizationId: values.organizationId,
          locationId: values.locationId,
          name: values.name,
          name2: values.name2,
          comments: values.comments,
        })
      )
        .unwrap()
        .then(() => {
          CustomAlert({ action: "Add" });
          document.getElementById("closeModalPosition").click();
          dispatch(getPositions());
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("LocalName")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name2"
              onChange={formik.handleChange}
              value={formik.values.name2}
              isInvalid={!!formik.errors.name2}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name2}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("GlobalName")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isInvalid={!!formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("Department")}</label>
            <Form.Select
              className="form-select"
              name="organizationId"
              onChange={formik.handleChange}
              value={formik.values.organizationId}
              isInvalid={!!formik.errors.organizationId}
            >
              <option value={null}>
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {Organizations.map((item) => (
                <option key={item.id} value={item.id}>
                  {i18n.language === "ar" ? item.name2 : item.name}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.organizationId}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("Job")}</label>
            <Form.Select
              className="form-select"
              name="jobId"
              onChange={formik.handleChange}
              value={formik.values.jobId}
              isInvalid={!!formik.errors.jobId}
            >
              <option key={null}>
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {Jobs.map((item) => (
                <option key={item.id} value={item.id}>
                  {i18n.language === "ar" ? item.name2 : item.name}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.jobId}
            </Form.Control.Feedback>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("Location")}</label>
            <Form.Select
              className="form-select"
              name="locationId"
              onChange={formik.handleChange}
              value={formik.values.locationId}
              isInvalid={!!formik.errors.locationId}
            >
              <option key={null}>
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>

              {Locations.map((item) => (
                <option key={item.id} value={item.id}>
                  {i18n.language === "ar" ? item.name2 : item.name}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.locationId}
            </Form.Control.Feedback>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <div className="form-group mb-3">
            <label className="form-label">{t("Note")}</label>
            <Form.Control
              className="form-control"
              as="textarea"
              rows={3}
              name="comments"
              onChange={formik.handleChange}
              value={formik.values.comments}
              isInvalid={!!formik.errors.comments}
            />

            <div></div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <button
          disabled={formik.isSubmitting}
          type="submit"
          className="btn me-1 btn-theme mb-1"
          id="SaveSubmit"
        >
          <i className="fa fa-save fa-fw me-1"></i>{" "}
          {formik.isSubmitting ? "..." : "حفظ"}
        </button>
        <button
          type="button"
          className=" btn me-1 btn-default mb-1"
          data-bs-dismiss="modal"
          id="closeModalPosition"
        >
          <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
        </button>
        <button
          type="button"
          onClick={formik.resetForm}
          className="btn me-1 btn btn-danger mb-1"
        >
          <i className="fa fa-trash-alt fa-fw me-1"></i>
          {t("Clear")}
        </button>
      </div>
    </Form>
  );
};

export default AddPosition;
