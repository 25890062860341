import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { CurrencyModel } from "./currencyModels";



export const GetCurrenciesList = createAsyncThunk(
  "Currency/GetCurrenciesList",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.get("NtxSetting/GetCurrenciesList");
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetCurrenciesById = createAsyncThunk(
  "Currency/GetCurrenciesById",
  async (id: string, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.get(`NtxSetting/GetCurrenciesById?Id=${id}`);
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);



export const InsertCurrencies = createAsyncThunk(
  "Currency/InsertCurrencies",
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post("NtxSetting/AddCurrencies", item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdateCurrencies = createAsyncThunk(
  "Currency/UpdateCurrencies",
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post("NtxSetting/UpdatCurrencies ", item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  CurrencyList: CurrencyModel[];
  CurrencyById?: CurrencyModel;
  loading: boolean;
  error: any | null;
} = {
  CurrencyList: [],
  CurrencyById: {} as CurrencyModel,
  loading: false,
  error: null,
};

const currencySlice = createSlice({
  name: "Currency",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(InsertCurrencies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(InsertCurrencies.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.CurrencyList.push(action.payload?.data);
      })
      .addCase(InsertCurrencies.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(GetCurrenciesList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetCurrenciesList.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.CurrencyList = action.payload;
      })
      .addCase(GetCurrenciesList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(GetCurrenciesById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetCurrenciesById.fulfilled,
        (state, action: PayloadAction<CurrencyModel>) => {
          state.loading = false;
          state.CurrencyById = action.payload;
        }
      )
      .addCase(GetCurrenciesById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default currencySlice.reducer;
