/* eslint-disable no-duplicate-case */
/* eslint-disable default-case */

import React, { Fragment, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../../components/Shared/Loader/Loader';
import {
  cleanTransactionHistoryList,
  GetTransactionHistoryWithValueById,
} from '../../../store/Ame/ameRequestSlice';
import { Card, CardBody, CardHeader } from '../../../components/card/card';

import HistoryList from './HistoryList';

import ContentRequest from './ContentRequest/ContentRequest.jsx';
import HeaderRequest from './HeaderRequest/HeaderRequest.jsx';
import ActionRequestForm from './ActionRequest/ActionRequest.js';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/Shared/Loader/Loading';

const ViewRequests = ({ isAction = true }) => {
  const { TransactionHistoryId } = useParams();
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  //

  useEffect(() => {
    dispatch(cleanTransactionHistoryList());
    dispatch(GetTransactionHistoryWithValueById(TransactionHistoryId));
  }, []);
  const data = useSelector(
    (state) => state.AmeRequest.TransactionHistoryWithValueList
  );
  const { loadingViewRequest, error } = useSelector(
    (state) => state.AmeRequest
  );

  //________________________________
  var BcrumbList = [
    {
      name: t('Home'),
      link: '/',
    },
    {
      name: 'selfService',
      link: null,
    },
  ];

 
  return (
    <>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName={
          i18n.language === 'en' ? data?.requestName : data?.requestName2
        }
        subtitle={
          i18n.language === 'en'
            ? data?.selectPersonName
            : data?.selectPersonName2
        }
      />
      <hr />


      <Loading loading={loadingViewRequest} error={error}>
        <Fragment>
          <div className="row gx-4">
            <div className=" col-xl-8 ">
              <Card className="mb-4 h-100">
                <CardHeader className="d-flex align-items-center bg-theme bg-opacity-30 fw-500 fs-5">
                  {t('RequestDetails')}
                </CardHeader>
                <CardBody>
                  <HeaderRequest data={data} />
                  <ContentRequest data={data} />
                </CardBody>
              </Card>
            </div>  
            {isAction && (
              <div className=" col-xl-4 ">
                <Card className="mb-4 h-100">
                  <CardHeader className="d-flex align-items-center bg-theme bg-opacity-30 fw-500 fs-5">
                   {t('RequestAction')}
                  </CardHeader>
                  <CardBody>
                    <div className="row">
                      <ActionRequestForm data={data} />
                    </div>
                  </CardBody>
                </Card>
              </div>
            )}
          </div>

          <div className="row mt-3">
            <div className="col-lg-12">
              {data.history && <HistoryList List={data.history} />}
            </div>
          </div>
        </Fragment>
      </Loading>
    </>
  );
};

export default ViewRequests;
