import { useFormik } from 'formik';
import * as Yup from 'yup';
// import Select from 'react-select';
import { Col, Form, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  addPosSession,
  getPosSessionById,
  updatPosSession,
} from '../../../store/Sales/Pos/PosSessionSlice';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';
import { useTranslation } from 'react-i18next';
// import useGetCashierListByPosId from '../../../hooks/POS/useGetCashierListByPosId';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Loading from '../../../components/Shared/Loader/Loading';
import showConfirmation from '../../../components/Shared/Alert/Confirmation';
import confirmAction from '../../../components/Shared/Alert/confirmAction';

// type Props = {
//   posId: string;
// };{ posId }: Props

const CloseSession = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { sessionId } = useParams<string>();
  const [difference, setDifference] = useState(0);

  // const { PosCashierList, loading: loading2 } = useGetCashierListByPosId({ posId: posId });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (sessionId) {
      dispatch(getPosSessionById(sessionId));
    }
  }, [sessionId]);

  const { PosSessionById: data, loading, error } = useAppSelector((s) => s.PosSession);

  const formik = useFormik({
    initialValues: {
      id: data ? data.id : '',
      posId: data ? data.posId : '',
      CasherId: data ? data.casherId : '',
      StartDatetime: data ? data.startDatetime : '',
      OpeningBalance: data ? data.openingBalance : 0,
      OpeningNote: data ? data.openingNote : '',
      EndDatetime: '',
      ClosingBalance: '',
      ClosingNote: '',
    },

    validationSchema: Yup.object({
      ClosingBalance: Yup.number()
        .typeError('Must be a number')
        .required('Required')
        .min(0, 'Must be greater than or equal to 0'),
      ClosingNote: Yup.string(),
    }),

    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      try {
        const confirmed = await confirmAction('Are you sure you want to submit this form?');

        if (confirmed) {
          const currentDateTime = new Date().toISOString();
          const res = await dispatch(
            updatPosSession({
              id: values.id,
              posId: values.posId,
              casherId: values.CasherId,
              // startDatetime: values.StartDatetime,
              openingBalance: values.OpeningBalance,
              openingNote: values.OpeningNote,

              endDatetime: currentDateTime, // here i wamt get date time now
              closingBalance: Number(values.ClosingBalance),
              closingNote: values.ClosingNote,
            })
          ).unwrap();

          if (res.succeeded === true) {
            let sessionId = res.data;
            await new Promise((resolve) => setTimeout(resolve, 2000));
            document.getElementById('closeModal')?.click();
            navigate("/Pos/PosCard" , {replace:true});
            CustomAlert({ action: 'Add' });
          } else {
            CustomAlert({ action: 'Error' });
          }
        }
      } catch (error) {
        CustomAlert({ action: 'Error' });
      } finally {
        setSubmitting(false);
      }

      // showConfirmation('', async () => {
      //   const currentDateTime = new Date().toISOString();
      //   dispatch(
      //     updatPosSession({
      //       id: values.id,
      //       posId: values.posId,
      //       casherId: values.CasherId,
      //       // startDatetime: values.StartDatetime,
      //       openingBalance: values.OpeningBalance,
      //       openingNote: values.OpeningNote,

      //       endDatetime: currentDateTime, // here i wamt get date time now
      //       closingBalance: Number(values.ClosingBalance),
      //       closingNote: values.ClosingNote,
      //     })
      //   )
      //     .unwrap()
      //     .then(async (res) => {
      //       if (res.succeeded === true) {
      //         let sessionId = res.data;
      //         await new Promise((resolve) => setTimeout(resolve, 2000));
      //         document.getElementById('closeModal')?.click();
      //         // navigate(`/Pos/${posId}/${sessionId}/PointOfSale`);
      //         // CustomAlert({ action: 'Add' });
      //         formik.setSubmitting(false);
      //       } else {
      //         formik.setSubmitting(false);
      //         CustomAlert({ action: 'Error' });
      //       }
      //     })
      //     .catch((error) => {
      //       formik.setSubmitting(false);
      //       CustomAlert({ action: 'Error' });
      //     });
      // });
    },
  });

  //___________________________________

  useEffect(() => {
    const openingBalance = Number(formik.values.OpeningBalance);
    const closingBalance = Number(formik.values.ClosingBalance);
    setDifference(closingBalance - openingBalance);
  }, [formik.values.OpeningBalance, formik.values.ClosingBalance]);

  return (
    <div>
      <Loading loading={loading} error={error}>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={6}>
              <div className="bg-inverse bg-opacity-5 rounded-2 p-2 mb-3">
                <div className="widget-reminder ps-2 mb-1">
                  <div className="widget-reminder-item">
                    <div className="widget-reminder-divider bg-success"></div>
                    <div className="widget-reminder-content p-2">
                      <div className="row align-items-center">
                        <div className="col-6 pt-1 pb-1">Cash</div>
                        <div className="col-6 pt-1 pb-1 text-end fw-bold fs-5">
                          SR {data.openingBalance?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="widget-reminder ps-2">
                  <div className="widget-reminder-item">
                    <div className="widget-reminder-divider bg-theme"></div>
                    <div className="widget-reminder-content p-2">
                      <div className="row align-items-center">
                        <div className="col-6 pt-1 pb-1">الإفتتاح</div>
                        <div className="col-6 pt-1 pb-1 text-end fw-bold fs-5">
                          SR {data.openingBalance?.toFixed(2)}
                        </div>
                        <hr className="mb-1" />
                      </div>

                      <div className="row align-items-center">
                        <div className="col-6 pt-1 pb-1">Bank</div>
                        <div className="col-6 pt-1 pb-1 text-end fw-bold fs-5">SR 0.00</div>
                        <hr className="mb-1" />
                      </div>
                      <div className="row align-items-center">
                        <div className="col-6 pt-1 pb-1">Customer Account</div>
                        <div className="col-6 pt-1 pb-1 text-end fw-bold fs-5">SR 0.00</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={6}>
              <Row>
                <Col md={6}>
                  <div className="form-group ">
                    <label className="form-label mb-1">ClosingBalance</label>
                    <div className="input-group flex-nowrap">
                      <Form.Control
                        className="form-control form-control-lg "
                        type="text"
                        name="ClosingBalance"
                        onChange={formik.handleChange}
                        value={formik.values.ClosingBalance}
                        onBlur={formik.handleBlur}
                        // isInvalid={
                        //   !!(formik.touched.ClosingBalance && formik.errors.ClosingBalance)
                        // }
                      />
                      <span className="input-group-text">
                        <i className="bi bi-cash-stack fa-lg"></i>
                      </span>
                    </div>
                    <div className="text-danger">{formik.errors.ClosingBalance}</div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group flex-nowrap ">
                    <label className="form-label mb-1">الفرق</label>
                    <div className="input-group flex-nowrap">
                      <Form.Control
                        disabled
                        className={`form-control form-control-lg text-center ${
                          Number(formik.values.OpeningBalance) !==
                          Number(formik.values.ClosingBalance)
                            ? 'text-danger'
                            : ''
                        } `}
                        type="text"
                        value={'SR  ' + difference.toFixed(2)}
                        // value={formik.values.ClosingBalance}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            {formik.values.OpeningNote !== '' && (
              <Col md={6}>
                <div className="form-group mb-3">
                  <label className="form-label">ملاحظة الإفتتاح</label>
                  <Form.Control
                    className="form-control"
                    as="textarea"
                    rows={2}
                    name="OpeningNote"
                    onChange={formik.handleChange}
                    value={formik.values.OpeningNote}
                    isInvalid={!!formik.errors.OpeningNote}
                  />
                </div>
              </Col>
            )}

            <Col md={formik.values.OpeningNote === '' ? 12 : 6}>
              <div className="form-group mb-3">
                <label className="form-label">ملاحظة الإغلاق</label>
                <Form.Control
                  className="form-control"
                  as="textarea"
                  rows={2}
                  name="ClosingNote"
                  onChange={formik.handleChange}
                  value={formik.values.ClosingNote}
                  isInvalid={!!formik.errors.ClosingNote}
                />
              </div>
            </Col>
          </Row>

          <div className="text-center">
            <button
              disabled={formik.isSubmitting}
              type="submit"
              className="btn btn-lg me-1 btn-theme px-4">
              {/* {formik.isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
              )} */}
              {t('إغــلاق ')}
            </button>
            <button
              disabled={formik.isSubmitting}
              type="button"
              className=" btn me-1 btn-default btn-lg px-4">
              {t('Cancel')}
            </button>
          </div>
        </Form>
      </Loading>
    </div>
  );
};

export default CloseSession;
