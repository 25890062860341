import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { SalesQuotationModels , SalesQuotationByIdModels } from './salesQuotationModels';

export const addQuotationTransactions = createAsyncThunk(
  'SalesQuotation/addQuotationTransactions',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('SalesQuotation/AddQuotationTransactions', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const updateQuotationTransactions = createAsyncThunk(
  'SalesQuotation/updateQuotationTransactions',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('SalesQuotation/UpdatQuotationTransactions', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);



export const getTransactionsQuotationList = createAsyncThunk(
  'SalesQuotation/getTransactionsQuotationList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('SalesQuotation/GetTransactionsQuotationList');
      if (data?.succeeded) {
        //===
        return data.data;
      } else {
        return rejectWithValue(data.message);      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const getTransactionsQuotationById = createAsyncThunk(
  'SalesQuotation/getTransactionsQuotationById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`SalesQuotation/GetTransactionsQuotationById?Id=${id}`);
      if (data?.succeeded) {
        // ===
        return data.data;
      } else {
        return rejectWithValue(data?.message || "هناك خطأ");     }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  SalesQuotationList: SalesQuotationByIdModels[];
  TransactionsQuotationById: SalesQuotationByIdModels;
  loading: boolean;
  error: any | null;
} = {
  SalesQuotationList: [],
  TransactionsQuotationById: {} as SalesQuotationByIdModels,
  loading: false,
  error: null,
};





const SalesQuotationSlice = createSlice({
  name: 'SalesQuotation',
  initialState,
  reducers: {
    cleanUpTransactionsQuotationById: (state) => {
      state.TransactionsQuotationById = {} as SalesQuotationByIdModels;
    },
  },
  extraReducers(builder) {
    builder

      .addCase(getTransactionsQuotationList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getTransactionsQuotationList.fulfilled,
        (state, action: PayloadAction<SalesQuotationByIdModels[]>) => {
          state.loading = false;
          state.SalesQuotationList = action.payload;
        }
      )
      .addCase(getTransactionsQuotationList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })






      .addCase(getTransactionsQuotationById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getTransactionsQuotationById.fulfilled,
        (state, action: PayloadAction<SalesQuotationByIdModels>) => {
          state.loading = false;
          state.TransactionsQuotationById = action.payload;
        }
      )
      .addCase(getTransactionsQuotationById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});



export const {cleanUpTransactionsQuotationById} = SalesQuotationSlice.actions;
export default SalesQuotationSlice.reducer;
