
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";


export const GetResponsibilityFunction = createAsyncThunk("ResponsibilityFunction/GetResponsibilityFunction", async (id, thunkAPI) => {
  const { rejectWithValue   } = thunkAPI;


  try {
    // const data = await getData(`Security/GetResponsibilityFunctionListbyResponsibilityId?ResponsibilityId=${id}`);
    const {data} = await ntxAPI.get(`Security/GetResponsibilityFunctionListbyResponsibilityId?ResponsibilityId=${id}` );
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

export const AddResponsibilityFunction = createAsyncThunk("ResponsibilityFunction/AddResponsibilityFunction", async (item, thunkAPI) => {
  const { rejectWithValue   } = thunkAPI;

  try {
    // const data = await postData("Security/AddAuthResponsibilityFunction" , item);
    const {data} = await ntxAPI.post( "Security/AddAuthResponsibilityFunction", item)
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});








const initialState = { ResponsibilityFunctionList: [], loading: false, error: null  };

const ResponsibilityFunctionSlice = createSlice({
  name: "ResponsibilityFunction", 
  initialState,
  reducers: {
    cleanResponsibilityFunction: (state)=>{
        state.ResponsibilityFunctionList = [];
      }
  },
  extraReducers: (builder) => {
    // Get Responsibility Function by Responsibility Id
    builder
      .addCase(GetResponsibilityFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetResponsibilityFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.ResponsibilityFunctionList = action.payload;
      })
      .addCase(GetResponsibilityFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // insert
      .addCase(AddResponsibilityFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddResponsibilityFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.ResponsibilityFunctionList.push(action.payload);
      })
      .addCase(AddResponsibilityFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {cleanResponsibilityFunction} = ResponsibilityFunctionSlice.actions
export default ResponsibilityFunctionSlice.reducer;
