import { useTranslation } from 'react-i18next';
import useGetPersons from '../../../hooks/HR/use-get-persons';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  AddAmeDelegate,
  DeleteMyDelegation,
  getMyDelegation,
} from '../../../store/Ame/ameDelegateSlice';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';
import { AddAmeDelegateSchema } from '../../ValidationForm/validationSchema';
import { useEffect } from 'react';
import confirmAction from '../../../components/Shared/Alert/confirmAction';
import Loading from '../../../components/Shared/Loader/Loading';

const MyDelegate = () => {
  const { t, i18n } = useTranslation();
  const { dataEmployees, loading: loadingPersone } = useGetPersons();
  const dispatch = useAppDispatch();
  const { MyDelegationList, error, loading } = useAppSelector((s) => s.AmeDelegate);

  useEffect(() => {
    dispatch(getMyDelegation());
  }, []);

  //#region
  const formik = useFormik({
    initialValues: {
      creatorPersonId: '',
      selectedPersonId: '',
      startDate: '',
      endDate: '',
      reason: '',
    },

    validationSchema: AddAmeDelegateSchema(t),

    onSubmit: (values) => {
      dispatch(
        AddAmeDelegate({
          // creatorPersonId: values.creatorPersonId,
          creatorPersonId: null,
          selectedPersonId: values.selectedPersonId,
          startDate: values.startDate,
          endDate: values.endDate,
          reason: values.reason,
        })
      )
        .unwrap()
        .then(() => {
          CustomAlert({ action: 'Add' });
          dispatch(getMyDelegation());
          formik.resetForm();
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  //#endregion

  const handleDeleteDelegation = async (item: any) => {
    let isConfirmation = await confirmAction(
      ` ----------- هل أنت متأكد من إلغاء تفويض -----------  (${
        i18n.language === 'en' ? item.selectedPersonName : item.selectedPersonName2
      })`
    );

    if (isConfirmation) {
      try {
        var res = await dispatch(DeleteMyDelegation(item.id)).unwrap();
        if (res.succeeded) {
          CustomAlert({ action: 'Delete' });
          dispatch(getMyDelegation());
        } else {
          CustomAlert({ action: 'Error' });
        }

        debugger;
      } catch (error: any) {
        CustomAlert({ action: 'Error', msg: `An error occurred: ${error?.message}` });
      } finally {
      }
    } else {
      // CustomAlert({ action: 'info', msg: 'Rollback of the end of service was cancelled.' });
    }
  };

  return (
    <div className="p-3">
      <Loading loading={loading || loadingPersone} error={error}>
        <>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-sm-6 col-md-3 mb-1">
                <div className="input-group mb-3 ">
                  <span className="input-group-text">{t('From')}</span>

                  <Form.Control
                    type="date"
                    className="form-control text-center"
                    name="startDate"
                    value={formik.values.startDate}
                    onChange={formik.handleChange}
                    isInvalid={!!(formik.touched.startDate && formik.errors.startDate)}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-3 mb-1">
                <div className="input-group mb-3">
                  <span className="input-group-text">{t('To')}</span>
                  <Form.Control
                    type="date"
                    className="form-control text-center"
                    name="endDate"
                    value={formik.values.endDate}
                    onChange={formik.handleChange}
                    isInvalid={!!(formik.touched.endDate && formik.errors.endDate)}
                  />
                </div>
              </div>

              <div className="col-sm-12 col-md-6 mb-1">
                <div className="input-group flex-nowrap mb-3">
                  <span className="input-group-text">{t('EmployeeName')}</span>
                  <Select
                    classNamePrefix="react-select"
                    className={`w-100 ${
                      !!(formik.touched.selectedPersonId && formik.errors.selectedPersonId)
                        ? ' border border-danger'
                        : ''
                    }`}
                    isLoading={loadingPersone}
                    isSearchable={true}
                    isClearable
                    name="selectedPersonId"
                    options={dataEmployees}
                    onChange={(option: any) => {
                      formik.setFieldValue(
                        'selectedPersonId',
                        option === null ? null : option.value
                      );
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>

              <div className="col-md-10 mb-1">
                <div className="input-group mb-2">
                  <span className="input-group-text">{t('Reason')}</span>
                  <Form.Control
                    type="text"
                    className="form-control text-center"
                    name="reason"
                    value={formik.values.reason}
                    onChange={formik.handleChange}
                    isInvalid={!!(formik.touched.reason && formik.errors.reason)}
                  />
                </div>
              </div>

              <div className=" col-md-2 mb-1 text-end">
                <button className="btn me-1 btn-theme w-100">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fas fa-lg fa-fw fa-user-tag me-2"></i>
                  )}
                  {t('تفويض')}
                </button>
              </div>
            </div>
          </Form>
          <div className="table-responsive">
            <table className="table table-sm text-nowrap table-hover text-center">
              <thead className="bg-inverse bg-opacity-25">
                <tr>
                  <th scope="col" style={{ width: '15%' }}>
                    {t('From')}
                  </th>
                  <th scope="col" style={{ width: '15%' }}>
                    {t('To')}
                  </th>
                  <th scope="col" style={{ width: '30%' }}>
                    {t('Name')}
                  </th>
                  <th scope="col" style={{ width: '30%' }}>
                    {t('Reason')}
                  </th>
                  <th scope="col" style={{ width: '10%' }}></th>
                </tr>
              </thead>
              <tbody>
                {MyDelegationList?.map((item) => (
                  <tr key={item.id}>
                    <td className="align-middle"> {item.startDate} </td>
                    <td className="align-middle">{item.endDate}</td>

                    <td className="align-middle">
                      {i18n.language === 'en' ? item.selectedPersonName : item.selectedPersonName2}
                    </td>
                    <td className="align-middle">{item.reason}</td>

                    <td className="align-middle text-end">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-danger m-0"
                        onClick={() => handleDeleteDelegation(item)}>
                        <i className="fas fa-trash-alt fa-fw fa-lg"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      </Loading>
    </div>
  );
};

export default MyDelegate;
