import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
 
import { useParams } from "react-router-dom";
import { getJob } from "../../store/HR/HRSetting/jobSlice";

const useJobDetails = ()=>{
    const { id } =  useParams();

    const dispatch = useDispatch();
    const { loading , error , Job} = useSelector((state) => state.Jobs); 

    useEffect(() => {
          if(!Job?.length){
            dispatch(getJob(id));
          } 
        }, [dispatch , id]);

    return { loading , error , Job } ;
};

export default useJobDetails ;