import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getInventoryCountOpen } from '../../../store/INV/InventoryCount/inventoryCountSlice';

const useGetInventoryCountOpenList= () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (InventoryCountOpenList?.length === 0) {
      dispatch(getInventoryCountOpen());
    }
  }, []);
  const { InventoryCountOpenList, loading , error } = useAppSelector((state) => state.InventoryCount);

  return { InventoryCountOpenList, loading , error };
};

export default useGetInventoryCountOpenList;
