import React, { createRef, useLayoutEffect, useRef } from 'react';
import { GridStack } from 'gridstack';
import 'gridstack/dist/gridstack.css';
import { Card } from '../../../../components/card/card';

const BuildGrid = ({ dashboardItems, setItemsForUpdate }) => {
  const refs = useRef({});
  const gridRef = useRef();
  const gridContainerRef = useRef(null);
  refs.current = {};
  if (Object.keys(refs.current)?.length !== dashboardItems?.length) {
    dashboardItems?.forEach(({ id }) => {
      refs.current[id] = refs.current[id] || createRef();
    });
  }

  useLayoutEffect(() => {
    if (!gridRef.current) {
      const grid = (gridRef.current = GridStack.init(
        {
          // acceptWidgets: true,
          // auto:false
          // staticGrid:HasPermission("ViewlistHrPerson")
        },
        gridContainerRef.current
      ).on('change', (ev, gsItems) => {
        const newItems = grid.save(false);
        // setItems(newItems);
        setItemsForUpdate(newItems);
      }));
    } else {
      const grid = gridRef.current;
      const layout = dashboardItems?.map(
        (a) =>
          refs.current[a.id].current.gridstackNode || {
            ...a,
            el: refs.current[a.id].current,
          }
      );
      grid._ignoreCB = true;
      grid.load(layout);
      delete grid._ignoreCB;
    }
  }, [dashboardItems, setItemsForUpdate]);

  return (
    <div>
      <div className="grid-stack" ref={gridContainerRef}>
        {dashboardItems?.map((item, i) => {
          //  const isMobile = window.innerWidth < 768;
          return (
            <div
              ref={refs.current[item.id]}
              key={item.id}
              className="grid-stack-item"
              gs-id={item.id}
              gs-w={item.w}
              // gs-w={isMobile ? 12 : item.w}
              gs-h={item.h}
              // gs-h={isMobile ? "15" : item.w}
              gs-x={item.x}
              gs-y={item.y}>
              <Card
                className="m-0 p-0"
                style={{
                  height: 'calc(100% - 10px)',
                  width: 'calc(100% - 10px)',
                }}>
                <div className="grid-stack-item-content">{item.content}</div>
              </Card>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BuildGrid;
