import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody } from '../../../../../components/card/card';
import Loader from '../../../../../components/Shared/Loader/Loader';
import Printpdf from '../../../../../components/Reports/Printpdf'
import PrintpdfLandscape from '../../../../../components/Reports/PrintpdfLandscape';
import { GetAssignmentActionList } from '../../../../../store/HR/Payroll/runPayrollSlice';

const PayrollForAll = () => {
    // const { t, i18n } = useTranslation();
    const { PayrollActionId } = useParams();
  
    const Navigate = useNavigate();
    const dispatch = useDispatch();
  
    const { loading } = useSelector((state) => state.runPayroll);
  
    const data = useSelector((state) => state.runPayroll.AssignmentActionList);
    useEffect(() => {
      dispatch(GetAssignmentActionList(PayrollActionId));
    }, []);


    //________________________________
  

  return (
    <>
    <div style={{backgroundColor : 'GrayText'}}>

  
          <Card>
            <CardBody>
              <Loader loading={loading}/>
    <PrintpdfLandscape data={data}  />
    
            </CardBody>
          </Card>
 </div>
  </>
  )
}

export default PayrollForAll