import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, CardBody } from "../../../../../components/card/card";
import Loader from "../../../../../components/Shared/Loader/Loader";
import Printpdf from "../../../../../components/Reports/Printpdf";
import { GetRunResultList } from "../../../../../store/HR/Payroll/runPayrollSlice";

const PayrollForPerson = () => {
  const { AssignmentActionId } = useParams();

  const dispatch = useDispatch();

  const { RunResultList, loading } = useSelector((state) => state.runPayroll);

  useEffect(() => {
    dispatch(GetRunResultList(AssignmentActionId));
  }, []);
  //________________________________

  return (
    <>
      <div style={{ backgroundColor: "GrayText" }}>
        <Card>
          <CardBody>
            <Loader loading={loading} />
            <Printpdf Data={RunResultList} />
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default PayrollForPerson;
