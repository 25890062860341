import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GetRequestList } from '../../../store/Ame/ameRequestSlice.js';

import Pagination from '../../../components/Shared/Pagination/Pagination.jsx';

const RequestList = () => {
  const { t, i18n } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.length === 0) {
      dispatch(GetRequestList());
    }
  }, []);
  const { RequestList: data, loading } = useSelector((state) => state.AmeRequest);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = data?.slice(indexOfFirstData, indexOfLastData);

  return (
    <>
      {loading ? (
        <div className="text-center ">
          <div className="spinner-grow text-success"></div>
          <div className="spinner-grow text-danger"></div>
          <div className="spinner-grow text-warning"></div>
        </div>
      ) : (
        <>
          {data && data?.length === 0 ? (
            <div className="text-center bg-inverse bg-opacity-10 rounded-4 p-3">
              لا يوجد لديك طلبات
            </div>
          ) : (
            <>
              <div className="table-responsive rounded-2">
                <table className="table table-striped table-borderless mb-2px small text-nowrap rounded-2 text-center">
                  <thead className="bg-theme bg-opacity-60">
                    <tr>
                      <th scope="col" className=" fw-800 fs-6">
                        الطلب
                      </th>
                      <th scope="col" className=" fw-800 fs-6">
                        مقدم الطلب
                      </th>
                      <th scope="col" className=" fw-800 fs-6">
                        تاريخ الطلب
                      </th>
                      <th scope="col" className=" fw-800 fs-6">
                        حالة الطلب
                      </th>
                      <th scope="col" className=" fw-800 fs-6"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData &&
                      currentData?.map((item, index) => (
                        <tr key={item.transectionHistoryId}>
                          <td className="align-middle">
                            {i18n.language === 'ar' ? item.requestName2 : item.requestName}
                          </td>
                          <td className="align-middle">
                            {i18n.language === 'ar' ? item.personName2 : item.personName}
                          </td>
                          <td className="align-middle">{item.dateCreated}</td>
                          <td className="align-middle ">
                            {item.status === 'IN PROGRESS' ? (
                              <span className="badge text-theme fw-bold px-4 rounded fs-12px d-inline-flex align-items-center">
                                <i className="bi bi-clock-history fs-18px fa-fw me-5px"></i>
                              </span>
                            ) : item.status === 'QUESTION' ? (
                              <span className="badge border   border-indigo text-indigo fw-bold px-5  rounded fs-12px d-inline-flex align-items-center">
                                <i className="bi bi-question-circle-fill fs-18px fa-fw me-5px"></i>
                                {t('QUESTION')}
                              </span>
                            ) : (
                              <span className="badge d-block rounded-0 pt-5px w-70px bg-white bg-opacity-25">
                                ..
                              </span>
                            )}
                          </td>
                          <td>
                            <Link
                              className="text-decoration-none text-inverse"
                              onClick={() =>
                                Navigate(
                                  `/SelfService/Requests/${item.transectionHistoryId}/ViewRequests`
                                )
                              }>
                              <i className="bi bi-eye-fill fs-5 text-theme"></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={data}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default RequestList;
