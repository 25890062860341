import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { TaxManagerModels } from "./TaxManagerModels";




export const AddTaxManagers = createAsyncThunk(
  "TaxManagers/AddTaxManagers",
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post("Finance/AddTaxManagers", item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

 



export const GetTaxManagersList = createAsyncThunk(
  "TaxManagers/GetTaxManagersList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get("Finance/GetTaxManagersList");
      if(data.succeeded){
        return data.data
      }else{
        return []
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

 




const initialState: {
  TaxManagerList: TaxManagerModels[];
  loading: boolean;
  error: any | null;
} = {
  TaxManagerList: [],
  loading: false,
  error: null,
};

const TaxManagerSlice = createSlice({
  name: "TaxManagers",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(AddTaxManagers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        AddTaxManagers.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.TaxManagerList.push(action.payload.data);
        }
      )
      .addCase(
        AddTaxManagers.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )

      .addCase(GetTaxManagersList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetTaxManagersList.fulfilled,
        (state, action: PayloadAction<TaxManagerModels[]>) => {
          state.loading = false;
          state.TaxManagerList = action.payload;
        }
      )
      .addCase(
        GetTaxManagersList.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default TaxManagerSlice.reducer;
