import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
import { GetInstallmentPaymentById } from "../../store/SU/installmentPaymentSlice";
 

const useGetInstallmentPaymentById = (id)=>{

  // var id = 'ce82c450-bca8-4459-b597-ccc6382576bc' ;

    const dispatch = useDispatch();
    useEffect(()=>{
       dispatch(GetInstallmentPaymentById(id))
      },[dispatch , id])

    const { InstallmentPaymentById , loading2 } = useSelector((state) => state.InstallmentPayment); 

    return { InstallmentPaymentById  , loading2  } ;
};

export default useGetInstallmentPaymentById ;