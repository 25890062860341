import { useFormik } from 'formik';
import React from 'react';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody } from '../../../../components/card/card';
import { GetApprovalGroupList } from '../../../../store/Ame/approvalGroupSlice';
import { AddAmeActionRule, GetAmeActionRule } from '../../../../store/Ame/ameActionRuleSlice';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import ActionRuleList from './ActionRuleList';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import Loading from '../../../../components/Shared/Loader/Loading';

function AddAmeAction() {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { ruleId } = useParams();
  const dispatch = useDispatch();
  const { AmeRuleListByDynamicFormId: AmeRuleList } = useSelector((state) => state.AmeRule);

  useEffect(() => {
    dispatch(GetApprovalGroupList());
    dispatch(GetAmeActionRule(ruleId));
  }, []);
  const { ApprovalGroupList , loading : loading2 , error:error2} = useSelector((state) => state.ApprovalGroup);
  const { ActionRuleListByRuleId , loading , error } = useSelector((state) => state.AmeActionRule);

  const formik = useFormik({
    initialValues: {
      ApprovalType: '', // hide show Element  and validation Dynamic Only
      supperVisorLevel: '',
      approvalGroupId: '',
      order: '',
    },
    validationSchema: Yup.object().shape({
      ApprovalType: Yup.string()
        .required(() => t('Required'))
        .notOneOf(['إختر', 'Choose'], 'Required'),

      supperVisorLevel: Yup.string().when('ApprovalType', {
        is: '0',
        then: () =>
          Yup.string()
            .required(() => t('Required'))
            .notOneOf(['إختر', 'Choose'], 'Required'),
      }),

      approvalGroupId: Yup.string().when('ApprovalType', {
        is: '1',
        then: () =>
          Yup.string()
            .required(() => t('Required'))
            .notOneOf(['إختر', 'Choose'], 'Required'),
      }),

      order: Yup.string().when('ApprovalType', {
        is: '1',
        then: () => Yup.string().required(() => t('Required')),
      }),
    }),
    onSubmit: (values) => {
      dispatch(
        AddAmeActionRule({
          ruleId: ruleId,
          supperVisorLevel:
            formik.values.ApprovalType === '0' ? formik.values.supperVisorLevel : null,
          approvalGroupId:
            formik.values.ApprovalType === '1' ? formik.values.approvalGroupId : null,
          order: formik.values.ApprovalType === '1' ? formik.values.order : null,
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          CustomAlert({ action: 'Add' });
          dispatch(GetAmeActionRule(ruleId));
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  var isSupervisor = false;
  ActionRuleListByRuleId.map((item) => {
    if (item.supperVisorLevel !== null) {
      isSupervisor = true;
    }
  });

  //_____
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('RequestsSettings'),
      link: '/Ame/RequestSetting',
    },
    {
      name: t('RequestRules'),
      link: '',
    },
    {
      name: t('ApprovalGroup'),
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName={t('ApprovalGroup')}
        subtitle={
          i18n.language === 'en'
            ? AmeRuleList?.find((f) => f.id === ruleId)?.name
            : AmeRuleList?.find((f) => f.id === ruleId)?.name2
        }
      />

      <Card>
        <Loading loading={loading || loading2} error={error || error2}>

      
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-6 m-auto">
                <div className="form-group mb-3">
                  <label className="form-label">{t('ApprovalGroup')}</label>
                  <Form.Select
                    className="form-select"
                    name="ApprovalType"
                    onChange={formik.handleChange}
                    value={formik.values.ApprovalType}
                    isInvalid={formik.touched.ApprovalType && formik.errors.ApprovalType}>
                    <option value={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                    {!isSupervisor && (
                      <option value="0">
                        {i18n.language === 'ar' ? 'مدير مباشر ' : 'supervisor'}
                      </option>
                    )}

                    <option value="1">
                      {i18n.language === 'ar' ? 'قائمة موافقات' : 'list Approvals'}
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.ApprovalType}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>

            <div className="row">
              {formik.values.ApprovalType === '0' ? (
                <div className="col-6 m-auto">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('SuperVisorLevel')}</label>
                    <Form.Select
                      className="form-select"
                      name="supperVisorLevel"
                      onChange={formik.handleChange}
                      value={formik.values.supperVisorLevel}
                      isInvalid={formik.touched.supperVisorLevel && formik.errors.supperVisorLevel}>
                      <option value={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      <option value="1">{i18n.language === 'ar' ? ' الأول ' : 'the first'}</option>

                      <option value="2">
                        {i18n.language === 'ar' ? 'الثاني ' : 'the second '}
                      </option>

                      <option value="3">{i18n.language === 'ar' ? 'الثالث' : 'the third'}</option>

                      <option value="4">{i18n.language === 'ar' ? 'الرابع ' : 'the fourth'}</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.supperVisorLevel}
                    </Form.Control.Feedback>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            {formik.values.ApprovalType === '1' ? (
              <>
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col-4 m-auto">
                    <div className="form-group mb-3">
                      <label className="form-label">{t('ApprovalGroup')}</label>
                      <Form.Select
                        className="form-select"
                        name="approvalGroupId"
                        onChange={formik.handleChange}
                        value={formik.values.approvalGroupId}
                        isInvalid={formik.touched.approvalGroupId && formik.errors.approvalGroupId}>
                        <option value={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                        {ApprovalGroupList.map((item) => (
                          <option key={item.id} value={item.id}>
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.approvalGroupId}
                      </Form.Control.Feedback>
                    </div>
                  </div>

                  <div className="col-2 m-auto">
                    <div className="form-group mb-3">
                      <label className="form-label">{t('Order')}</label>
                      <Form.Control
                        className="form-control"
                        type="number"
                        name="order"
                        onChange={formik.handleChange}
                        value={formik.values.order}
                        isInvalid={formik.touched.order && formik.errors.order}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.order}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-3"></div>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="text-center">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-theme mb-1">
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                )}
                {t('Add')}
              </button>
              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                onClick={() => Navigate(-1, { replace: true })}>
                <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
              </button>
            </div>
          </Form>

          <div className="mb-2" />

          <div className="row">
            <div className="col-10 m-auto">
              <div className=" bg-inverse bg-opacity-5 rounded-4 pt-1">
                <ActionRuleList data={ActionRuleListByRuleId} />
              </div>
            </div>
          </div>
        </CardBody>
          </Loading>
      </Card>
    </>
  );
}

export default AddAmeAction;
