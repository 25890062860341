import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

// import { useAppDispatch } from '../../../store/hooks';
// import { LogOut } from '../../../store/Login/loginSlice';
// import ToastSwal from '../../Shared/Alert/ToastSwal';
// import { Link } from 'react-router-dom';
import RefreashTokenToastSwalAlert from '../../Shared/Alert/ReafreashTokenAlert/RefreashTokenToastSwalAlert';
import RefreashTokenFailureSwalAlert from '../../Shared/Alert/ReafreashTokenAlert/RefreashTokenFailureSwalAlert';
import Swal from 'sweetalert2';
import PopoverCustom from '../../Shared/Popover/PopoverCustom';

const TimerToken = () => {
  // const dispatch = useAppDispatch();
  const token = useSelector((state) => state.userLogin?.AuthUser?.token);
  const LoadingRefreashToken = useSelector((state) => state.userLogin?.LoadingRefreashToken);
  const ErrorRefreashToken = useSelector((state) => state.userLogin?.ErrorRefreashToken);

  // Memoize the expiration time calculation
  const expirationTime = useMemo(() => {
    if (token) {
      const decodedToken = jwt_decode(token);
      return decodedToken.exp * 1000; // Convert seconds to milliseconds
    }
    return 0;
  }, [token]);

  // State for time left
  const [timeLeft, setTimeLeft] = useState(expirationTime - Date.now());

  useEffect(() => {
    if (expirationTime > 0) {
      // Update time left every second
      const timer = setInterval(() => {
        const newTimeLeft = expirationTime - Date.now();
        setTimeLeft(newTimeLeft);

        // If time left is zero or less, clear the interval
        if (newTimeLeft <= 0) {
          clearInterval(timer);
          // Handle token expiration (e.g., log out the user, redirect, etc.)
        }
      }, 1000); // Update every second

      // Cleanup interval on component unmount
      return () => clearInterval(timer);
    }
  }, [expirationTime]);

  // Convert milliseconds to minutes and seconds
  const minutes = Math.floor(timeLeft / 1000 / 60);
  const seconds = Math.floor((timeLeft / 1000) % 60);

  //#region // تسجيل الخروج في حال فشل تحديث التوكن
  useEffect(() => {
    const handleTokenError = async () => {
      if (ErrorRefreashToken) {
        RefreashTokenFailureSwalAlert();
        await delay(10000);
        localStorage.clear();
        window.location.replace('/login');
      }
    };

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    handleTokenError();
  }, [ErrorRefreashToken]);
  //#endregion

  //#region //  تحديث التوكن
  useEffect(() => {
    const handleLoadingRefreashToken = async () => {
      if (LoadingRefreashToken) {
        RefreashTokenToastSwalAlert();
      } else if (!LoadingRefreashToken && !ErrorRefreashToken) {
        Swal.close();
      }
    };

    handleLoadingRefreashToken();

    return () => {
      Swal.close();
    };
  }, [LoadingRefreashToken]);
  //#endregion

  return (
    <>
      {/* <OverlayTrigger placement="bottom" overlay={popover} trigger={['hover', 'focus']}>

      </OverlayTrigger> */}

      <PopoverCustom
        childern={
          <div className="me-2">
            {LoadingRefreashToken ? (
              <i className="bi bi-circle-fill text-primary"></i>
            ) : ErrorRefreashToken ? (
              <i className="bi bi-circle-fill text-danger"></i>
            ) : timeLeft > 0 ? (
              <i className="bi bi-circle-fill text-success"></i>
            ) : timeLeft < 0 && !LoadingRefreashToken ? (
              <i className="bi bi-circle-fill text-light"></i>
            ) : null}
          </div>
        }
        Header="حالة صلاحية الجلسة"
        Body={
          <div className="text-center">
            {LoadingRefreashToken ? (
              <p className="text-center m-0 text-info">جاري تحديث البيانات...</p>
            ) : ErrorRefreashToken ? (
              <>
                <span className=" text-danger">حدث خطأ أثناء تحديث بيانات المستخدم.</span>
                <br />
                سيتم تسجيل خروجك لإعادة التحقق من أمان الحساب. يرجى تسجيـل الدخول مرة أخرى .
              </>
            ) : timeLeft > 0 ? (
              <p className="text-center m-0">
                <span>تنتهي صلاحية الجلسة خلال</span>
                <span className="fw-bold mx-2 text-theme fw-bold fs-6">
                  {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </span>
              </p>
            ) : timeLeft < 0 && !LoadingRefreashToken ? (
              <p className="text-center text-danger m-0 p-0 ">إنتهت الجلسة</p>
            ) : null}
          </div>
        }
        placement='bottom'
      />
    </>
  );
};

export default TimerToken;

// const popover = (
//   <Popover id="popover-basic" className="text-center">
//     <Popover.Header as="h3" className="bg-danger bg-opacity-25">
//       تنبيــــه
//     </Popover.Header>
//     <Popover.Body>
//       <span className="text-danger">حدث خطأ أثناء تحديث البيانات.</span>
//       <br />
//       سيتم تسجيل خروجك لإعادة التحقق من أمان الحساب. يرجى تسجيـل الدخول مرة أخرى .
//     </Popover.Body>
//   </Popover>
// );

//   <div className="mx-2">
//   {LoadingRefreashToken ? (
//     <div className="d-flex m-0 text-info">
//       <i className="bi bi-arrow-repeat"></i>
//     </div>
//   ) : ErrorRefreashToken ? (
//     <div className="d-flex m-0 text-danger">
//       <i className="bi bi-exclamation-triangle-fill"></i>
//     </div>
//   ) : timeLeft > 0 ? (
//     <div className="d-flex m-0 bg-theme bg-opacity-20 rounded-2 p-1">
//       <i className="bi bi-clock-fill"></i>
//       <span className="fw-bold mx-2">
//         {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
//       </span>
//     </div>
//   ) : (
//     <div className="d-flex m-0 text-danger">
//       <i className="bi bi-x-circle-fill"></i>
//     </div>
//   )}
// </div>

// <div className="mx-2">
//   {timeLeft > 0 ? (
//     <p className="d-flex m-0 bg-theme bg-opacity-20 rounded-2 p-1">
//       <span>تنتهي صلاحية الجلسة خلال</span>
//       <span className="fw-bold mx-2">
//         {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
//       </span>
//     </p>
//   ) : (
//     <p className="d-flex m-0 text-danger">إنتهت الجلسة</p>
//   )}
// </div>

// <OverlayTrigger placement="bottom" overlay={popover} show={ErrorRefreashToken !== null}>
// <div className="mx-2">
//   {LoadingRefreashToken ? (
//     // <p className="d-flex m-0 text-info">جاري تحديث البيانات...</p>
//     <span className="badge bg-info text-light">...</span>
//   ) : ErrorRefreashToken ? (
//     // <p className="d-flex m-0 text-danger">فشل تحديث البيانات</p>
//     <span className="badge bg-danger text-light">!</span>
//   ) : timeLeft > 0 ? (
//     // <p className="d-flex m-0 bg-theme bg-opacity-20 rounded-2 p-1">
//     //   <span>تنتهي صلاحية الجلسة خلال</span>
//     //   <span className="fw-bold mx-2">
//     //     {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
//     //   </span>
//     // </p>
//       <span className="badge bg-primary">Primary</span>
//   ) : timeLeft < 0 && !LoadingRefreashToken   ? (
//     // <p className="d-flex m-0 text-danger">إنتهت الجلسة</p>
//     <span className="badge bg-danger text-light">X</span>
//   ) : null}
// </div>
// </OverlayTrigger>
