import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader } from '../../../components/card/card';
import CountUp from 'react-countup';

type Props = {
  title?: string;
  icon?: string;
  List?: any[];
};

export const UsersCard = ({ title, icon, List }: Props) => {
  const { i18n } = useTranslation();

  return (
    <Card className="mb-3" style={{ maxHeight: '16rem', minHeight: '16rem' }}>
      <CardHeader className="fw-bold small p-1 px-2  bg-theme bg-opacity-70 ">
        <div className=" text-center">
          <div className="pt-2">عدد المستخدمين</div>
          {/* <i className={`bi ${icon} fa-2x text-theme`}></i> */}
        </div>
      </CardHeader>

      <CardBody className="px-2 py-0">
        <div className="row align-items-center">
          <div className="col-12 text-center">
            <h1 className="mb-0 text-theme" style={{ fontSize: '50px' }}>
              <CountUp end={List?.length || 0} duration={2.5} />
            </h1>
          </div>

          <div className="col-12 text-center">
            <div className="bg-theme bg-opacity-10 rounded-5 d-flex justify-content-center align-items-center ">
              <i className="bi bi-people fa-7x text-theme"></i>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
