import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
 
import { getTransactionsInvoiceById , cleanUpSalesInvoicesById } from "../../store/Sales/SalesInvoices/salesInvoicesSlice";

interface Props {
  id: string | undefined;
}

const useGetSalesInvoicesById = ({ id }: Props) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if(id){

        dispatch(getTransactionsInvoiceById(id));
    }

    return () => {
      dispatch(cleanUpSalesInvoicesById());
    };
  }, [dispatch, id]);
  const { SalesInvoicesById, loading , error } = useAppSelector((state) => state.SalesInvoices);

  return { SalesInvoicesById, loading , error };
};

export default useGetSalesInvoicesById;
