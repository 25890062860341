import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { GetGlChartOfAccountsDetailsList } from '../../../store/Finance/ChartAccounts/ChartAccountsDetailsSlice';
import { useTranslation } from 'react-i18next';

const useGetAccounts = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ChartAccountsDetailsList?.length === 0) {
      dispatch(GetGlChartOfAccountsDetailsList());
    }
  }, []);
  const { ChartAccountsDetailsList, loading, error } = useAppSelector(
    (state) => state.ChartAccountsDetails
  );

  var Accounts = ChartAccountsDetailsList.filter((f) => f.accountlevel === 4);

  //___ Options _____

  var OptionsAccounts = Accounts.map(({ id, name, name2, accountNumber, accountParant }) => ({
    value: id,
    label: `${i18n.language === 'en' ? name : name2} | ${accountNumber}`,
    accountParant: accountParant,
    accountNumber: accountNumber,
    accountShow: (
      <div className="d-flex justify-content-between">
        <div>{i18n.language === 'en' ? name : name2}</div>
        <div className='text-theme ms-2'>{accountNumber}</div>
      </div>
    ),
  }));

  //_______
  return {
    ChartAccountsDetailsList,
    Accounts,
    loading,
    error,
    OptionsAccounts,
  };
};

export default useGetAccounts;
