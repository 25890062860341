import Swal from "sweetalert2";


interface AlertProps {
  Title?: string;
  text?: string | any;
  icon?: 'error' | "warning" | "question" | "success" | "info";
  timer? : number,
}

export default function SwalAlert({ text , Title , icon , timer }: AlertProps) {


  Swal.fire({
    // title: ".",
    timer : timer,
    icon: icon ?? "info",
    text: text,
     background: "var(--bs-body-bg)",
      color:'var(--bs-heading-color)',
      customClass: {
        container: "border",
        popup: "border border-theme border-3 rounded-5"
      },
      showConfirmButton: false, // Removes the "OK" button


  });
  

  
}

// Swal.fire({
//   title: Title,
//   icon: "info",
//   text: "text",
//    background: "var(--bs-body-bg)",
//     color:'var(--bs-heading-color)',
//     customClass: {
//       container: "border",
//       popup: "border border-theme border-3 rounded-5"
//     },

// });

// }