import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { SettingsList } from "./settingsModels";

export const GetNtxSettingsList = createAsyncThunk(
  "NtxSettings/GetNtxSettingsList",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.get(
        "NtxSetting/GetOrganizationSettingList"
      );
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdateNtxSettingValue = createAsyncThunk(
  "NtxSettings/UpdateNtxSettingValue",
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post(
        "NtxSetting/UpdateOrganizationSettingValue",
        item
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  SettingsLiat: SettingsList[];

  loading: boolean;
  error: any | null;
} = {
  SettingsLiat: [] as SettingsList[],
  loading: false,
  error: null,
};

const NtxSettingsSlice = createSlice({
  name: "NtxSettings",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(GetNtxSettingsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetNtxSettingsList.fulfilled,
        (state, action: PayloadAction<SettingsList[]>) => {
          state.loading = false;
          state.SettingsLiat = action.payload;
        }
      )
      .addCase(
        GetNtxSettingsList.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default NtxSettingsSlice.reducer;
