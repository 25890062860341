import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useResolvedPath, useMatch, NavLink, useLocation, matchPath } from 'react-router-dom';
 import menus from "./../../config/app-menu.jsx";
import useGetSysName from '../../hooks/useGetSysName';
// import Appmenu from '../../config/appmenu.jsx';
 
function NavItem({ menu, ...props }) {  //LinkProps
	
	const { t } = useTranslation();
	let path = (menu.path) ? menu.path : '';
	let resolved = useResolvedPath(path);
  let match = useMatch({ path: resolved.pathname });
	let location = useLocation();
	
	if (menu?.is_header) {
		return (
			<div className="menu-header">{ t(menu?.title) }</div>
		);
	}
	
	if (menu.is_divider) {
		return (
			<div className="menu-divider"></div>
		);
	}
	

	let match2 = matchPath({path: path, end: false, },location.pathname);

	let icon = menu.icon && <div className="menu-icon"><i className={menu.icon}></i></div>;
	let img = menu?.img && <div className="menu-icon-img"><img src={menu?.img} alt="" /></div>;
	let caret = (menu.children && !menu.badge) && <div className="menu-caret"><b className="caret"></b></div>;
	let label = menu.label && <span className="menu-label ms-5px">{menu.label}</span>;
	let badge = menu.badge && <div className="menu-badge">{menu.badge}</div>;
	let highlight = menu.highlight && <i className="fa fa-star text-theme"></i>;
	// let title = menu.title && <div className="menu-text">{menu.title} {label} {highlight}</div>;
	let title = menu.title && <div className="menu-text">{t(menu.title)} {highlight} </div>;



	return (
		<div className={'menu-item' + ((match || match2) ? ' active' : '') + (menu.children ? ' has-sub' : '')}>
			<NavLink className="menu-link" to={menu?.path} {...props}>
				{ img } { icon } { title }{ caret } { badge }
			</NavLink>
		
			{menu?.children && (


				<div className="menu-submenu">
					{menu?.children?.map((submenu, i) => (

						(submenu.isActive === true )?//&& submenu.SubChildren.some(item => item.isIndex && item.isActive)) ?
						<NavItem key={i} menu={submenu} /> : null
					))}
				</div>
			)}
		</div>
	);
}




function SidebarNav() {

	const SysName = useGetSysName();

	// const SystemMenu = menus.find(item => item.isActive === true)
	// var CurrentSystem = localStorage.CurrentSystem || "NTX"
	
	// const {menu : menus} = Appmenu()
	const SystemMenu = menus?.find(item => item.System === SysName )


	// const filteredMenu = SystemMenu.menu?.filter(item => {
	// //إظهار الداشبورد والنفيجيشن دائما بدون شروط
	// 	if (item.title ==='Navigation'||item.title ==='HomePage'){
	// 		return true;
	// 	}
	// 	// ارجاع القوائم التي تحتوي على قائمة فرعية واحدة على الأقل 
	// 	// else if (item.children) {
	// 	//   return item.children.some(child => child.isMenu && child.isActive);
	// 	// }
	// 	else if (item.children) {
	// 		return item.children?.some(child =>child.isActive);
	// 	  }
	// 	  else if (item.isActive) {
	// 		return item.isActive;
	// 	  }
		  
	// 	return false;
	//   });


  const filteredMenu = useMemo(() => {
    return SystemMenu?.menu?.filter(
      (item) =>
        // item.title === "Navigation" ||
        item.title === "HomePage" ||
        item.children?.some((child) => child.isActive) ||
        item.isActive
    );
  }, [SystemMenu]);

	return (
		<div className="menu">
			{ filteredMenu?.map((menu, i) => (				
				<NavItem key={i} menu={menu} />
			))}
		</div>
	);
}

export default SidebarNav;