import React, { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { GetNtxPeoplesList } from '../../store/NTX/Peoples/peoplesSlice';
import { useTranslation } from 'react-i18next';
 
type Props = {
  LookupType : string;
}

const useGetPeoplesList = ({LookupType}:Props) => {
  const {i18n} = useTranslation()

    const dispatch = useAppDispatch();
       const { PeoplesList, loading , error} = useAppSelector((state) => state.Peoples);
    useEffect(() => {
      if (PeoplesList?.length === 0 ) {
        dispatch(GetNtxPeoplesList(LookupType));
      }
    }, []);

    var DriversList =  PeoplesList.filter((f)=> f.typeCode === "DRIVERS")
    var CustomerList =  PeoplesList.filter((f)=> f.typeCode === "CUSTOMERS")

    var OptionCustomers =  CustomerList.map(item => ({
      value : item.id,
      label : i18n.language === 'en' ? item.name : item.name2
    }))

    var OptionPeoplesList =  PeoplesList.map(item => ({
      value : item.id,
      label : i18n.language === 'en' ? item.name : item.name2
    }))


 
   

  return {PeoplesList , DriversList , CustomerList ,OptionCustomers ,OptionPeoplesList ,  loading , error }
}

export default useGetPeoplesList
