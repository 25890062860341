import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "../../../../components/card/card";
import * as Yup from "yup";

import {
  AddOrganizationStructure,
  GetOrganizationStructure,
  GetOrganizationWithoutStructure,
} from "../../../../store/HR/Organizations/OrganizationStructureSice";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import { usePermissions } from "../../../../Helper/Permisstions/usePermissions";

const ListForm = ({ orgData, data }) => {
  const { HasPermission } = usePermissions();

  var ListChildOrg =
    orgData &&
    data?.filter(
      (f) =>
        (f.organizationIdParent === orgData.organizationIdChild) &
        (f.organizationIdChild !== f.organizationIdParent)
    );

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { OrganizationListWithoutStructure } = useSelector(
    (state) => state.OrganizationStructure
  );

  useEffect(() => {
    dispatch(GetOrganizationWithoutStructure());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      //   businessGroupId: "",
      organizationIdParent: "",
      organizationIdChild: "",
    },
    validationSchema: Yup.object().shape({
      organizationIdChild: Yup.string().required(() => t("Required")),
    }),
    onSubmit: (values) => {
      dispatch(
        AddOrganizationStructure({
          // businessGroupId: null ,
          organizationIdParent: orgData.organizationIdChild,
          organizationIdChild: values.organizationIdChild,
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          CustomAlert({ action: "Add" });
          dispatch(GetOrganizationStructure());
          dispatch(GetOrganizationWithoutStructure());
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
          console.log(error);
        });
    },
  });

  return (
    // <Card>
      <CardBody>
        <Form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-xl-5">
              <div className="form-group mb-3">
                <label className="form-label"> {t("Parent")}</label>
                <Form.Control
                  className="form-control form-control-lg bg-inverse bg-opacity-10"
                  type="text"
                  value={ i18n.language === 'en'? orgData?.childName : orgData?.childName2  || ""}
                  disabled
                />
              </div>
            </div>

            <div className="col-xl-5">
              <div className="form-group mb-3">
                <label className="form-label">{t("child")}</label>
                <Form.Select
                  className="form-select form-select-lg"
                  name="organizationIdChild"
                  value={formik.values.organizationIdChild}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.organizationIdChild &&
                    formik.errors.organizationIdChild
                  }
                >
                  <option value="">
                    {i18n.language === "ar" ? "إختر" : "Choose"}
                  </option>

                  {OrganizationListWithoutStructure?.map((item, idx) => (
                    <option key={++idx} value={item.id}>
                      {i18n.language === "ar" ? item.name2 : item.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.organizationIdChild}
                </Form.Control.Feedback>
              </div>
            </div>
            <div className="col-xl-2">
              <div className="form-group mb-3">
                <label className="form-label"><p></p></label>
                {HasPermission("AddStructureHrDepartments") && (
                  <button
                    disabled={formik.isSubmitting}
                    type="submit"
                    className="btn me-1 btn-theme mb-1 form-control"
                  >
                    {formik.isSubmitting ? (
                      <div className="spinner-border spinner-border-sm me-2"></div>
                    ) : (
                      <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                    )}
                    {t("Add")}
                  </button>
                )}
              </div>
            </div>

            {/* <div className="col-xl-2">
              <div className="form-group mb-3">
                <label className="form-label">ddd </label>
         
                {HasPermission("AddStructureHrDepartments") && (
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn btn-outline-theme"
                >
                  <i className="fa fa-plus-circle fa-fw me-1"></i>
                  {formik.isSubmitting ? "Adding..." : "Add"}
                </button>
              )}
              </div>
            </div> */}
          </div>
        </Form>

        <div className="mb-3"></div>

        <div className="row">
          <div className="col-lg-12">
            {ListChildOrg?.length === 0 ? (
              <div className="rounded-2 text-center border border-theme p-3">
                لا يوجد فروع لهذه الإدارة
                 </div>
            ) : (
              <div className="table-responsive rounded-2 text-center border border-theme">
                <table className="table text-center m-0">
                  <thead className="bg-theme bg-opacity-60">
                    <tr>
                      <th scope="col">{t("Name")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ListChildOrg?.map((item, idx) => (
                      <tr key={item.id}>
                        <td>
                          {i18n.language === "ar"
                            ? item.childName2
                            : item.childName}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </CardBody>
    // {/* </Card> */}
  );
};

export default ListForm;
