import * as XLSX from 'xlsx';
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { store } from "../store";

export const  IsTokenValid = () => {
  const Token = store.getState()?.userLogin?.AuthUser?.token

  if (!Token) return false;

  const user: any = jwt_decode(Token);
  const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

  if (isExpired) {
    return false;
  } else {
    return true;
  }
};


//________________________________________________________________

export const exportToExcel = (data : any, fileName : any) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};