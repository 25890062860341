/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { GetReportSourceFeild } from '../../../../store/NTX/Reports/reportSlice';
import BuilderQuerys from './Step2/BuilderQuerys';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardFooter, CardHeader } from '../../../../components/card/card';
import Step3 from './Step3/Step3';
import Step2 from './Step2/Step2';
import Step1 from './Step1/Step1';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Loader from '../../../../components/Shared/Loader/Loader';
import { useSelector } from 'react-redux';
// import ToastAlert from "../../../../components/Shared/Alert/ToastAlert";
import Step4 from './Step4/Step4';
import {
  // AddNtxNotification,
  GetNtxNotificationById,
  UpdateNtxNotification,
} from '../../../../store/NTX/Notifications/notificationSlice';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import Step5 from './Step5/Step5';
// import SwalAlert from "../../../../components/Shared/Alert/SwalAlert";
import ToastSwal from '../../../../components/Shared/Alert/ToastSwal';

const UpdateNotifications = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [fieldsName, setFieldsName] = useState([]);
  const [params, setParams] = useState([]);

  //_____________________ Start States Check Validation  _____________________
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [checkValidationStep1, setCheckValidationStep1] = useState(false);
  const [checkValidationStep2, setCheckValidationStep2] = useState(false);
  const [checkValidationStep3, setCheckValidationStep3] = useState(false);
  const [checkValidationStep4, setCheckValidationStep4] = useState(false);
  const [checkValidationStep5, setCheckValidationStep5] = useState(false);
  //_____________________ End States Check Validation  _____________________

  //_____________________ Start States for Post _____________________
  const [DataStep1, setDataStep1] = useState({}); // for submit
  const [DataStep2, setDataStep2] = useState([]); // for submit
  const [DataStep3, setDataStep3] = useState([]); // for submit
  const [DataStep4, setDataStep4] = useState({}); // for submit
  const [DataStep5, setDataStep5] = useState({}); // for submit
  const [Query, setQuery] = useState(''); // for submit  from step2
  const [queryBuilderReact, setQueryBuilderReact] = useState(''); // for submit  from step2

  //_____________________ End States for Post _____________________

  useEffect(() => {
    dispatch(GetNtxNotificationById(id));
  }, []);
  const { NotificationsById: data, loading } = useSelector((a) => a?.Notifications);

  //#region Start Steps Wiserd___________
  const [currentStep, setCurrentStep] = useState(1);
  const handleNextStep = async () => {
    if (currentStep === 1) {
      if (checkValidationStep1) {
        await dispatch(
          GetReportSourceFeild({
            typeForm: DataStep1?.typeForm,
            viewName: DataStep1?.viewName,

            formTypeId: data?.dynamicFormType === '4' ? data.dynamicFormId : null,
            formRecordId: data?.dynamicFormType === '3' ? data.dynamicFormId : null,
            tableId: DataStep1?.tableId,
            hasExtraInformation: DataStep1?.hasExtraInformation === true, //boolean
            hasExtraInformationRecord: DataStep1?.hasExtraInformationRecord === true, //boolean
            hasExtraInformationType: DataStep1?.hasExtraInformationType === true, //boolean
          })
        )
          .unwrap()
          .then((res) => {
            // debugger
            if (res?.succeeded === true) {
              let x = res?.data?.map((item) => ({
                name: item.value,
                label: item.name,
                inputType: item.inputType,
                defaultValue: '',
              }));
              setFieldsName(x);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        setCurrentStep(2);
      } else {
        ToastSwal({
          position: 'top-start',
          title: 'يرجى التحقق من تعبئة جميع الحقول',
        });
        return;
      }
    } else if (currentStep === 2) {
      if (checkValidationStep2 || params.length === 0) {
        setCurrentStep(3);
      } else {
        ToastSwal({
          position: 'top-start',
          title: 'يرجى التحقق من تعبئة جميع الحقول',
        });

        return;
      }
    } else if (currentStep === 3) {
      if (checkValidationStep3) {
        setCurrentStep(4);
      } else {
        ToastSwal({
          position: 'top-start',
          title: 'يرجى تحديد عمود واحد على الأقل',
        });
      }
    } else if (currentStep === 4) {
      if (checkValidationStep4) {
        setCurrentStep(5);
      } else {
        ToastSwal({
          position: 'top-start',
          title: 'يرجى كتابة رسالة الإشعار',
        });
      }
    } else if (currentStep === 5) {
      if (checkValidationStep5) {
        setIsSubmitting(true);
        dispatch(
          UpdateNtxNotification({
            id: data.id,
            name: DataStep1.name,
            name2: DataStep1.name2,
            moduleId: DataStep1.moduleId,
            notificationSourceId: DataStep1.reportSourceId,
            dynamicFormType: DataStep1.typeForm,

            dynamicFormId: DataStep1.typeForm === '0' ? null : DataStep1.dynamicFormId,

            query: Query,
            description: 'description',

            messageTemplate: DataStep4?.messageTemplate,
            queryBuilderReact: queryBuilderReact,

            icon: DataStep1.icon,

            sendEmail: DataStep1.email,
            emailSubject: DataStep4?.emailSubject,
            emailColumn: DataStep4?.emailColumn,
            emailTo: DataStep4?.emailTo?.map((m) => `"${m}"`).join(','),
            emailCC: DataStep4?.emailCC?.map((m) => `"${m}"`).join(','),
            pushNotification: DataStep1.Notification,
            userColumn: DataStep4?.userColumn,
            userIds: DataStep4?.userIds?.map((m) => `"${m}"`).join(','),
            sendSMS: DataStep1.sms,
            sendWhatsApp: DataStep1.whatsapp,
            mobileColumn: DataStep4?.mobileColumn,
            mobiles: DataStep4?.mobiles?.map((m) => `"${m}"`).join(','),
            periodType: DataStep5?.PeriodType,
            periodValue: DataStep5?.PeriodValue,
            notificationColumnsList: DataStep3?.map((item) => ({
              id: item.id,
              sequenceNum: 0,
              name: item.name,
              name2: item.name,
              notificationId: data.id,
              sqlName: item.sqlName,
              dataType: item.inputType,
            })),
            notificationParametersList: DataStep2?.map((item) => ({
              id: item.id,
              notificationId: data.id,
              paramName: item.paramName,
              paramValue: item.paramValue,
              endWith: item.endWith,
              beginWith: item.beginWith,
              hasIn: false, // موقف حاليا
              currentDateWithValue: item.currentDateWithValue === 'CurrentDateWithValue',
            })),
          })
        )
          .unwrap()
          .then((res) => {
            if (res.succeeded === true) {
              Navigate('/NTF/Notifications', { replace: true });
              CustomAlert({ action: 'Edit' });
              setIsSubmitting(false);
            } else {
              CustomAlert({ action: 'Error' });
              setIsSubmitting(false);
            }
          })
          .catch((error) => {
            console.log(error);
            CustomAlert({ action: 'Error' });
            setIsSubmitting(false);
          });
      } else {
        ToastSwal({
          position: 'top-start',
          title: 'يرجى إختيار نوع الإشعار',
        });

        return;
      }
    }
  };

  //#region Previous Step
  const handlePreviousStep = () => {
    if (currentStep === 2) {
      setCurrentStep(1);
    } else if (currentStep === 3) {
      setCurrentStep(2);
    } else if (currentStep === 4) {
      setCurrentStep(3);
    } else if (currentStep === 5) {
      setCurrentStep(4);
    }
  };
  //#endregion
  //#endregion End Steps Wiserd___________

  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('إدارة الإشعارات'),
      link: '',
    },
    {
      name: t('تعديل إشعار'),
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName={t('تعديل إشعار')}
        subtitle={i18n.language === 'en' ? data.name : data.name2}
      />

      {/* //_________________________________________________ */}
      <Card>
        <Loader loading={loading} />
        <CardHeader>
          <div className="nav-wizards-container" dir="ltr">
            <nav className="nav nav-wizards-1 mb-2 ">
              <div className="nav-item col">
                <Link className={`nav-link ${currentStep === 5 ? 'active' : ''}`}>
                  <div className="nav-no">5</div>
                  <div className="nav-text">{t('إعدادات الوقت')}</div>
                </Link>
              </div>

              <div className="nav-item col">
                <Link
                  className={`nav-link ${currentStep === 5 || currentStep === 4 ? 'active' : ''}`}>
                  <div className="nav-no">4</div>
                  <div className="nav-text">{t('إعدادات الإرسال')}</div>
                </Link>
              </div>

              <div className="nav-item col">
                <Link
                  className={`nav-link ${
                    currentStep === 5 || currentStep === 3 || currentStep === 4 ? 'active' : ''
                  }`}>
                  <div className="nav-no">3</div>
                  <div className="nav-text">{t('الأعمدة')}</div>
                </Link>
              </div>

              <div className="nav-item col">
                <Link
                  className={`nav-link ${
                    currentStep === 5 || currentStep === 2 || currentStep === 3 || currentStep === 4
                      ? 'active'
                      : ''
                  }  `}>
                  <div className="nav-no">2</div>
                  <div className="nav-text">{t('متغيرات الإشعار')}</div>
                </Link>
              </div>

              <div className="nav-item col">
                <Link
                  className={`nav-link ${
                    currentStep === 5 ||
                    currentStep === 1 ||
                    currentStep === 2 ||
                    currentStep === 3 ||
                    currentStep === 4
                      ? 'active'
                      : ''
                  }`}>
                  <div className="nav-no">1</div>
                  <div className="nav-text">{t('معلومات الإشعار')}</div>
                </Link>
              </div>
            </nav>
          </div>
        </CardHeader>

        <CardBody style={{ minHeight: '20rem' }}>
          {/* ______________ Step 1 _______________ */}

          <div style={{ display: currentStep === 1 ? 'block' : 'none' }}>
            <Step1
              // setTableViewName={setTableViewName}
              setCheckValidationStep1={setCheckValidationStep1}
              setDataStep1={setDataStep1}
            />
            {/* <p>All fields filled: {checkValidationStep1 ? "true" : "false"}</p> */}
          </div>

          {/* ______________ Step 2 _______________ */}

          <div style={{ display: currentStep === 2 ? 'block' : 'none' }}>
            <div className="row">
              <div className="col-xl-12">
                {fieldsName && data && Object.keys(data)?.length > 0 && (
                  <BuilderQuerys
                    fields={fieldsName}
                    setParams={setParams}
                    setQuery={setQuery}
                    setQueryBuilderReact={setQueryBuilderReact}
                    queryBuilderReact={data?.queryBuilderReact}
                  />
                )}
              </div>
            </div>
            <div className="mb-3" />
            <div className="row">
              <div className="col-xl-12">
                {fieldsName.length > 0 && (
                  <Step2
                    params={params}
                    fields={fieldsName}
                    setCheckValidationStep2={setCheckValidationStep2}
                    setDataStep2={setDataStep2}
                    notificationParametersById={data?.notificationParametersList}
                  />
                )}
              </div>
            </div>
          </div>

          {/* ______________ Step 3 _______________ */}

          <div style={{ display: currentStep === 3 ? 'block' : 'none' }}>
            {fieldsName && Object.keys(data)?.length > 0 && (
              <Step3
                fields={fieldsName}
                setCheckValidationStep3={setCheckValidationStep3}
                setDataStep3={setDataStep3}
                DataById={data.notificationColumnsList}
              />
            )}
          </div>

          {/* ______________ Step 4 _______________ */}
          <div style={{ display: currentStep === 4 ? 'block' : 'none' }}>
            {fieldsName && Object.keys(data)?.length > 0 && (
              <Step4
                Columns={DataStep3}
                DataStep1={DataStep1}
                setDataStep4={setDataStep4}
                setCheckValidationStep4={setCheckValidationStep4}
              />
            )}
          </div>

          {/* ______________ Step 5 _______________ */}
          <div style={{ display: currentStep === 5 ? 'block' : 'none' }}>
            {fieldsName && Object.keys(data)?.length > 0 && (
              <Step5
                setDataStep5={setDataStep5}
                setCheckValidationStep5={setCheckValidationStep5}
              />
            )}
          </div>
        </CardBody>

        <CardFooter style={{ zIndex: '1' }}>
          <div className="text-center my-2">
            <div className="btn-group btn-group-lg">
              <button
                disabled={currentStep === 5 && isSubmitting}
                type="button"
                className="btn btn-secondary mb-1"
                onClick={handlePreviousStep}>
                <i className="fas fa-lg fa-fw me-2 fa-arrow-alt-circle-right"></i>
                {t('Previous')}
              </button>

              <Link
                disabled={currentStep === 5 && isSubmitting}
                to="/NTF/Notifications"
                replace
                className="btn btn-light mb-1">
                {t('Cancel')}
              </Link>

              <button
                disabled={currentStep === 5 && isSubmitting}
                type="submit"
                className="btn me-1 btn-theme mb-1"
                onClick={handleNextStep}>
                {currentStep === 5 ? t('Button.Save') : t('Next')}
                {currentStep !== 5 ? (
                  <i
                    className="fas fa-lg fa-fw me-0 fa-arrow-alt-circle-left"
                    style={{ padding: '0px 12px 0px 0px' }}></i>
                ) : (
                  <i
                    className="far fa-lg fa-fw me-2 fa-save"
                    style={{ padding: '0px 12px 0px 0px' }}></i>
                )}
              </button>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default UpdateNotifications;
