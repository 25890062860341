import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useTranslation } from 'react-i18next';
 import { getPositions } from '../../store/HR/HRSetting/positionSlice';

 

const useGetPositions = ( ) => {
  const {i18n} = useTranslation()

   

  const { Positions, loading, error } = useAppSelector((state) => state.Positions);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (Positions?.length === 0) {
      dispatch(getPositions());
    }
  }, []);


  const PositionsOptions =
  Positions &&
  Positions?.map((item : any) => ({
    value: item.id,
    label: i18n.language === "en" ? item.name : item.name2,
  }));

 

 
   

  return {Positions ,PositionsOptions ,  loading, error }
}

export default useGetPositions
