import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useGetChartShapesList from "../../../../hooks/NTX/useGetChartShapesList";

const Item = ({ id }) => {
  const { t, i18n } = useTranslation();
  const { ChartShapesList } = useGetChartShapesList();
  const { ReportChartList } = useSelector((state) => state.ReportShape);

  var ChartName =
    i18n.language === "en"
      ? ReportChartList.find((f) => f.id === id)?.name
      : ReportChartList.find((f) => f.id === id)?.name2;

  var IconChart = ChartShapesList.find(
    (f) => f.id === ReportChartList.find((m) => m.id === id)?.shapeId
  )?.icon;

  return (
   
    <div  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <div className="pt-2">

        <i className={IconChart + " mt-4 text-theme"} style={{fontSize:'4rem'}}></i>
        <h6 className="mt-3 me-2">{ChartName}</h6>
      </div>
    </div>
  );
};

export default Item;
