import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getPurchaseRequestList } from '../../../store/purchases/PurchaseRequests/purchaseRequestsSlice';


const useGetPurchaseRequestList = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (PurchaseRequestsList?.length === 0) {
      dispatch(getPurchaseRequestList());
    }
  }, []);
  const { PurchaseRequestsList, loading, error } = useAppSelector(
    (state) => state.PurchaseRequest
  );

  return { PurchaseRequestsList, loading, error };
};

export default useGetPurchaseRequestList;
