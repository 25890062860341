import React, { useEffect } from 'react';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import { theme as CustomTheme } from '../Shared/CustomTheme';
import { ntxAPI } from '../../../API/axiosNeatex';
import { Translations } from '../Shared/Translations';
import FetchPersonWithAssignmentFields from './FetchPersonWithAssignmentFields';
import ToastSwal from '../../../components/Shared/Alert/ToastSwal';
import Swal from 'sweetalert2';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';
import { AxiosError } from 'axios';
import { exportToExcel } from '../../../Helper/Helper';

type Props = {
  setModalPersonWithAssignment: React.Dispatch<React.SetStateAction<boolean>>;
};

const PersonWithAssignmentIntegration: React.FC<Props> = (props) => {
  async function onSubmit(data: any) {
    let Data = data?.validData;

    // console.log(data);

    const body = {
      personWithAssignment: Data.map((item: any) => ({
        title: item.title || 'MR.',
        name: item.name,
        name2: item.name2,
        employeeNumber: item.employeeNumber || '00',
        gender: item.gender || 'M',
        startDate: item.startDate,
        nationalIdentifier: item.nationalIdentifier || '00000',
        maritalStatus: item.maritalStatus || 'S',
        religion: item.Religion || 'M',
        dateOfBirth: item.dateOfBirth,
        nationality: item.nationality,
        townOfBirth: item.townOfBirth || "",
        regionOfBirth: '',
        countryOfBirth: item.countryOfBirth || item.nationality,
        annualLeave: item.annualLeave || 21,
        bankId: item.bankId || null,
        iBan: item.iBan || '00',
        //___________________________
        rotationPlanId: item.RotationPlanId || null,
        assignmentStartDate: item.assignmentStartDate,
        gradeId: item.gradeId || null,
        positionId: item.positionId || null,
        jobId: item.jobId || null,
        payrollId: item.payrollId || null,
        locationId: item.locationId || null,
        supervisorId: item.supervisorId || null,
        organizationId: item.organizationId || null,
        personGroupId: item.personGroupId || null,
        // assignmentStatusTypeId: 'c4e1843c-00ff-42c5-a1b2-441bc490a3ec', // تعيين نشـط
        assignmentStatusTypeId: item.assignmentStatusTypeId || null, // تعيين نشـط
        integrationField: '', //??  Person
      })),
    };
    // const body = {
    //   personWithAssignment: Data.map((item: any) => ({
    //     title: item.title,
    //     name: item.name,
    //     name2: item.name2,
    //     employeeNumber: item.employeeNumber,
    //     gender: item.gender,
    //     startDate: item.startDate,
    //     nationalIdentifier: item.nationalIdentifier ,
    //     maritalStatus: item.maritalStatus || '',
    //     religion: item.Religion || '',
    //     dateOfBirth: item.dateOfBirth,
    //     nationality: item.nationality,
    //     townOfBirth: item.townOfBirth,
    //     regionOfBirth: '',
    //     countryOfBirth: item.countryOfBirth || '',
    //     annualLeave: item.annualLeave || 0,
    //     bankId: item.bankId,
    //     iBan: item.iBan,
    //     //___________________________
    //     rotationPlanId: item.RotationPlanId || null,
    //     assignmentStartDate: item.assignmentStartDate,
    //     gradeId: item.gradeId || null,
    //     positionId: item.positionId || null,
    //     jobId: item.jobId || null,
    //     payrollId: item.payrollId || null,
    //     locationId: item.locationId || null,
    //     supervisorId: item.supervisorId || null,
    //     organizationId: item.organizationId || null,
    //     personGroupId: item.personGroupId || null,
    //     // assignmentStatusTypeId: 'c4e1843c-00ff-42c5-a1b2-441bc490a3ec', // تعيين نشـط
    //     assignmentStatusTypeId: item.assignmentStatusTypeId || null, // تعيين نشـط
    //     integrationField: '', //??  Person
    //   })),
    // };

    try {
      const { data } = await ntxAPI.post('Integration/AddListPersonWithAssignment', body);
      const { faildList, succeededList } = data?.data;

      if (data.succeeded) {
        if (succeededList?.length) {
          exportToExcel(succeededList, 'Succeeded-List');
        }
        if (faildList?.length) {
          exportToExcel(faildList, 'faild-List');
        }

        await new Promise((resolve) => setTimeout(resolve, 1000));

        CustomAlert({
          action: 'info',
          htmlContent: `
          <div className="text-center">
          <p>
            Successfully added <span className="text-success h3">${succeededList.length}</span> items
          </p>
          <p>
            Failed to add <span className="text-danger h3">${faildList?.length}</span> items
          </p>
        
        </div>
        `,
          Timer: 1000000,
        });
      } else if (faildList.length > 0) {
        CustomAlert({ action: 'Error' });
      }
      // return data;`
    } catch (err) {
      if (err instanceof AxiosError) {
        const errorMessage =
          err.response?.data?.message || err.message || 'An unknown error occurred';
        CustomAlert({ action: 'Error', msg: errorMessage });
      } else {
        CustomAlert({ action: 'Error', msg: 'An unexpected error occurred' });
      }
    }
  }

  //_____________________________________________________

  const {
    PersonWithAssignmentFields,
    fieldsLoading: Loading,
    fieldsError: error,
  } = FetchPersonWithAssignmentFields();

  useEffect(() => {
    if (Loading) {
      ToastSwal({
        // title: 'جاري تحميل البيانات',
        icon: 'info',
        timer: 2000000,
        position: 'top',
        htmlContent: `
      <div class="text-center d-flex justify-content-between">
      <div class="fs-6 w-75">جاري تجهيز البيانات لبدء خطوات التكامل</div>
      <div class="spinner-border text-theme"></div>
      </div>
     `,
      });
    }

    if (!Loading) {
      Swal.close();
    }
    if (error) {
      props.setModalPersonWithAssignment(false)
      CustomAlert({ action: 'Error' });

    }
  }, [Loading, error, props]);
 

  // useEffect(() => {
  //   if (error) {
  //     props.setModalPersonWithAssignment(false)
  //     CustomAlert({ action: 'Error' });
      
  //   }
  // }, [error]);

  return (  
    <div>
      {!Loading ? (
        <ReactSpreadsheetImport
          isOpen={true}
          onClose={() => props.setModalPersonWithAssignment(false)}
          onSubmit={onSubmit}
          fields={PersonWithAssignmentFields}
          customTheme={CustomTheme}
          rtl={localStorage.language === 'ar'}
          translations={Translations}
        />
      ) : null}
    </div>
  );
};

export default PersonWithAssignmentIntegration;
