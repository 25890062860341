import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useTranslation } from 'react-i18next';
import { getJobs } from '../../store/HR/HRSetting/jobSlice';

 

const useGetJobs = ( ) => {
  const {i18n} = useTranslation()

   

    const { Jobs, loading, error } = useAppSelector((state) => state.Jobs);
    const dispatch = useAppDispatch();
    useEffect(() => {
      if (Jobs?.length === 0) {
        dispatch(getJobs());
      }
    }, []);
 
    const JobsOptions =
    Jobs &&
    Jobs?.map((item : any) => ({
      value: item.id,
      label: i18n.language === "en" ? item.name : item.name2,
    }));


 
   

  return {Jobs , JobsOptions , loading , error }
}

export default useGetJobs
