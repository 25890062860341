// import PerfectScrollbar from 'react-perfect-scrollbar';

import { Card, CardBody, CardExpandToggler } from '../../../components/card/card.jsx';
// import ApexCharts from 'react-apexcharts';

import RequestList from '../../Ame/Requests/RequestList.js';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import { useEffect, useState } from 'react';
// import useGetOrganizations from '../../../hooks/HR/use-get-organizations.js';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getEmployees } from '../../../store/HR/Person/employeeSlice.js';
import Loading from '../../../components/Shared/Loader/Loading';
import BarChart from './BarChart.js';

const HrDashBoard = () => {
  const { t } = useTranslation();
  // const { Organizations, loading: loading2 } = useGetOrganizations();

  //#region Employees Counts
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      if (Employees?.length === 0) {
        await dispatch(getEmployees());
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const { Employees, loading, error } = useSelector((state) => state.Employees);
  var totalEmployees = Employees.length;
  var employeesCurrentlyWorking = Employees.filter((f) => f.endOfServiceDate === null).length;
  var employeesEndedService = Employees.filter((f) => f.endOfServiceDate !== null).length;
  //#endregion

  //#region  chartOptions
   
  function countEmployeesByOrganizationId(employees) {
    const organizationCount = {};

    employees?.forEach(employee => {
        const orgId = employee.organizatioN_ID;
        const orgName = employee.organizatioN_NAME;
        if (organizationCount[orgId]) {
            organizationCount[orgId].count++;
        } else {
            organizationCount[orgId] = {
                organizatioN_ID: orgId,
                orgName: orgName,
                count: 1
            };
        }
    });

    // تحويل النتيجة إلى مصفوفة من الكائنات
    const result = Object.values(organizationCount);

    return result;
}


  
  const [data, setData] = useState([]);
 

  useEffect(() => {
    const generatedData = countEmployeesByOrganizationId(Employees);
    setData(generatedData);
  }, [Employees]);



 
 


  // const chartData = {
  //   series: [
  //     {
  //       name: 'Count',
  //       data: data.map((item) => item.count),
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       type: 'bar',
  //       height: 350,
  //     },
  //     plotOptions: {
  //       bar: {
  //         horizontal: false,
  //         columnWidth: '55%',
  //         endingShape: 'rounded',
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     xaxis: {
  //       categories: data.map((item) => item.name),
  //     },
  //     fill: {
  //       opacity: 1,
  //     },
  //   },
  // };

  //#endregion

  return (
    <div>
      <Loading loading={loading} error={error}>
        <>
          <div className="row">
            <div className="  col-lg-3">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">إجمالي الموظفين</span>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-7">
                      <h1 className="mb-0 ps-4">
                        <CountUp end={totalEmployees} duration={2.5} />
                      </h1>
                    </div>
                    <div className="col-5 text-center">
                      <div className="mt-n2 ps-4">
                        <i className="bi bi-person-lines-fill fa-4x text-theme"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="  col-lg-3">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">الموظفين على رأس لعمل</span>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-7">
                      <h1 className="mb-0 ps-4">
                        <CountUp end={employeesCurrentlyWorking} duration={2.5} />
                      </h1>
                    </div>
                    <div className="col-5 text-center">
                      <div className="mt-n2 ps-4">
                        <i className="bi bi-person-fill fa-4x text-theme"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="  col-lg-3">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">الموظفين المنتهية خدمتهم</span>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-7">
                      <h1 className="mb-0 ps-4">
                        <CountUp end={employeesEndedService} duration={2.5} />
                      </h1>
                    </div>
                    <div className="col-5 text-center">
                      <div className="mt-n2 ps-4">
                        <i className="bi bi-person-x-fill  fa-4x text-theme"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="  col-lg-3">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">موظفين في إجازة</span>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-7">
                      <h1 className="mb-0 ps-4">0</h1>
                    </div>
                    <div className="col-5 text-center">
                      <div className="mt-n2 ps-4">
                        {/* <i className="bi bi-person-check-fill fa-4x text-theme"></i> */}
                        <i className="bi bi-calendar-check fa-4x text-theme"></i>
                        </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <div className="col-xl-12">
                <Card className="mb-3">
                  <CardBody>
                    <div className="d-flex fw-bold small mb-1 align-items-center">
                      <i className="bi bi-clock-history fa-2x me-2 text-warning"></i>
                      <span className="flex-grow-1  ">الطلبات تحت الإجراء</span>
                      <CardExpandToggler />
                    </div>

                    {/* <PerfectScrollbar> */}
                    <RequestList />
                    {/* </PerfectScrollbar> */}
                  </CardBody>
                </Card>
              </div>
            </div>

            <div className="col-xl-6">
              <Card className="mb-3" style={{ height: '100%' }}>
                <CardBody className="">
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">توزيع الموظفين حسب الإدارة
                    </span>
                    <CardExpandToggler />
                  </div>
                  <div className="ratio ratio-21x9 mb-3">
                    {data && data?.length > 0 && <BarChart data={data} />}
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </>
      </Loading>
    </div>
  );
};

export default HrDashBoard;
