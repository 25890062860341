import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ItemTree = ({ parentId, List, All, setOrgData }) => {
  const { i18n } = useTranslation();

  var hasNotChildren = List?.filter((f) => f.childCount === 0);
  var hasChildren = List?.filter((f) => f.childCount > 0);

  return (
    <Fragment>
      <div className="file-tree ">
        {hasNotChildren?.map((item) => (
          <div className="file-node " key={item.organizationIdChild}>
            <Link to="#/" className="file-link" onClick={() => setOrgData(item)}>
              <span className="file-arrow"></span>
              <span className="file-info">
                <span className="file-icon">
                  <i className="bi bi-square fa-lg text-body text-opacity-50"></i>
                </span>
                <span className="file-text fs-6">
                  {i18n.language === 'en' ? item.childName : item.childName2}
                </span>
              </span>
            </Link>
          </div>
        ))}

        {hasChildren.map((item) => (
          <div className="file-node has-sub " key={item.organizationIdChild}>
            <Link to="" className="file-link" onClick={() => setOrgData(item)}>
              <span className="file-arrow"></span>
              <span className="file-info">
                <span className="file-icon">
                  <i className="bi bi-layers-fill fa-lg text-warning"></i>
                </span>
                <span className="file-text fs-5">
                  {i18n.language === 'en' ? item.childName : item.childName2}
                </span>
              </span>
            </Link>

            <ItemTree
              All={All}
              parentId={item.organizationIdChild}
              List={All?.filter((f) => {
                return (
                  f.organizationIdParent === item.organizationIdChild &&
                  f.organizationIdChild !== f.organizationIdParent
                );
              })}
              setOrgData={setOrgData}
            />
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default ItemTree;
