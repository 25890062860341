import CryptoJS from "crypto-js";

export const EncryptString = (string: string) => {
  const encrypted = CryptoJS.AES.encrypt(string, "100NTX001").toString();
  return encrypted;
};

export const DecryptString = (string: string) => {
  const decrypted = CryptoJS.AES.decrypt(string, "100NTX001").toString(
    CryptoJS.enc.Utf8
  );
  return decrypted
};
