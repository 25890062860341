import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useTranslation } from 'react-i18next';
import { getGroupEmp } from '../../store/HR/HRSetting/groupEmpSlice';

 

const useGetGroupEmp = ( ) => {
  const {i18n} = useTranslation();

    const { GroupEmps, loading, error } = useAppSelector((state) => state.Groups);
    const dispatch = useAppDispatch();
    useEffect(() => {
      if (GroupEmps?.length === 0) {
        dispatch(getGroupEmp());
      }
    }, []);


    const GroupEmpsOptions =
    GroupEmps &&
    GroupEmps?.map((item : any) => ({
      value: item.id,
      label: i18n.language === "en" ? item.name : item.name2,
    }));

 


  return {GroupEmps , GroupEmpsOptions , loading , error }
}

export default useGetGroupEmp
