import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import useGetNtxProfile from '../../../hooks/NTX/useGetNtxProfile';

const QrCode = () => {
  const [qrData, setQrData] = useState();
  const { t, i18n } = useTranslation();
  const { UserProfile: Profile } = useGetNtxProfile();

  useEffect(() => {
    // Assuming you want to include multiple fields in the QR code
    const formattedData = `
          ${t('Name')}: ${i18n.language === 'ar' ? Profile?.name2 : Profile?.name}
          ${t('Employee Number')}: ${Profile?.employeeNumber}
          ${t('Identity')}: ${Profile?.nationalIdentifier}
          ${t('SuperVisor')}: ${
      i18n.language === 'ar' ? Profile?.supervisorName2 : Profile?.supervisorName
    }
          ${t('Department')}: ${
      i18n.language === 'ar' ? Profile?.organizationName2 : Profile?.organizationName
    }
        `;

    setQrData(formattedData.toUpperCase()); // Remove leading/trailing spaces
  }, [Profile]);
  return (
    <div>
      <div className="text-center p-1" style={{ background: 'white' }}>
        {qrData && <QRCode value={qrData} size={150} />}
      </div>
    </div>
  );
};

export default QrCode;
