import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Shared/Breadcrumb";
import { Card, CardBody } from "../../../components/card/card";
import Loader from "../../../components/Shared/Loader/Loader";
import { useTranslation } from "react-i18next";
import Pagination from "../../../components/Shared/Pagination/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { GetPriceListList } from "../../../store/INV/PriceList/PriceListSlice";
import MedModalComponent from "../../../components/Modal/MedModalComponent";
import AddPriceList from "./AddVoucher";
import Update from "./Update";
import { GetDiscountVoucherList } from "../../../store/Sales/DiscountVouchers/DiscountVouchersSlice";

const Index = () => {

  const [Show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();

  const [id , setId] = useState("");

  useEffect(() => {
    if (DiscountVouchersList?.length === 0) {
      dispatch(GetDiscountVoucherList());
    }
  }, []);
  const { DiscountVouchersList, loading } = useAppSelector((state) => state.DiscountVouchers);

  const filteredSearch = DiscountVouchersList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Vouchers",
      link: null,
    },
  ];



  return (
    <div>

      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t("Vouchers")} />

        <div className="ms-auto">
          <Link
            to="AddVouchers"
            className="btn me-1 btn-outline-theme"
            onClick={() => setShow(true)}
          >
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            اضافة
          </Link>
        </div>
      </div>
      <Card>
        <Loader loading={loading} />
        <CardBody>
          <div className="p-1">
            <div className="row">
              <div className="col-12 col-md-12 d-flex justify-content-start">
                <div className="input-group mb-4">
                  <div className="flex-fill position-relative">
                    <div className="input-group">
                      <div
                        className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                        style={{ zIndex: 1020, right: "10px" }}
                      >
                        <i className="fa fa-search opacity-5"></i>
                      </div>
                      <input
                        type="text"
                        className="form-control ps-35px"
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={t("Search") + " ..."}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-5 col-md-6 text-end"></div> */}
            </div>
            {/* //  START TABLE   */}
            <div className="table-responsive">
              <table className="table table-hover text-nowrap">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">{t("Name")}</th>
                    {/* <th scope="col">{t("from")}</th>
                    <th scope="col">{t("to")}</th> */}
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((item, index) => (
                    <tr key={item.id}>
                      <td>
                        {" "}
                        {i18n.language === "en" ? item.name : item.name2}
                      </td>
                      {/* <td> {item.startDateActive} </td>
                      <td> {item.endDateActive} </td> */}
                      <td>
                        <div className="btn-group">

                       
                        <button
                          type="button"
                          className="btn btn-outline-theme"
                          data-bs-toggle="modal"
                          data-bs-target="#EditPriceList"
                          // onClick={() => setId(item.id) }
                        >
                          <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                          {t("Button.Edit")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-theme"
                          onClick={() => Navigate(`${item.id}/DetailsPriceList`)}
                        >
                          <i className="far fa-lg me-2 bi bi-list-stars"></i>
                         قائمة الأسعار
                        </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </div>
        </CardBody>
      </Card>


      <MedModalComponent title={"اضافة قائمة أسعار"} id="AddPriceList">
      {Show && <AddPriceList/>}
      </MedModalComponent>

      <MedModalComponent title={"تعديل"} id="EditPriceList">
      {id && <Update Id={id}/>}
      </MedModalComponent>

      
    </div>
  );
};

export default Index;
