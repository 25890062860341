import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
 
import { GetItemType } from "../../store/Lookup/lookupSlice";

const useLookupGetItemType = ()=>{

    const dispatch = useDispatch();
    
    useEffect(()=>{
        dispatch(GetItemType())
      },[])

    const {  ITEM_TYPE} = useSelector((state) => state.Lookup); 

    return { ITEM_TYPE } ;
};

export default useLookupGetItemType ;