/* eslint-disable react-hooks/exhaustive-deps */
// import { identity } from '@fullcalendar/react';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/Shared/Loader/Loader';
import { GetFlexValueSetList } from '../../../../store/NTX/FlexValue/flexValueSetSlice';
import {
  GetFormColumn,
  GetFormColumnById,
  UpdateFormColumn,
} from '../../../../store/NTX/FlexValue/Form/FormColumnSlice';
import { AddFormColumnSchema } from '../../../ValidationForm/validationSchema';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';

const EditFormColumn = ({ ID }) => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { FlexValueSetList, loading } = useSelector((state) => state.FlexValueSet);
  useEffect(() => {
    dispatch(GetFlexValueSetList());
  }, []);

  const { FormColumnByIdList } = useSelector((state) => state.FormColumns);
  useEffect(() => {
    dispatch(GetFormColumnById(ID));
  }, [ID, dispatch]);
  //

  const formik = useFormik({
    initialValues: {
      lable: FormColumnByIdList ? FormColumnByIdList.lable : '',
      lable2: FormColumnByIdList ? FormColumnByIdList.lable2 : '',
      sequenceNum: FormColumnByIdList ? FormColumnByIdList.sequenceNum : '',
      enabledFlag: FormColumnByIdList ? FormColumnByIdList.enabledFlag : false,
      requiredFlag: FormColumnByIdList ? FormColumnByIdList.requiredFlag : false,
      flexValueSetId: FormColumnByIdList ? FormColumnByIdList.flexValueSetId : '',
      defaultValue: FormColumnByIdList ? FormColumnByIdList.defaultValue : '',
      tableId: FormColumnByIdList ? FormColumnByIdList.tableId : '',

      //______________________ not for post
      validationType: '',
      formatType: 'S',
      FlexValueSetId: '',
    },
    enableReinitialize: true,
    validationSchema: AddFormColumnSchema(t),
    onSubmit: (values) => {
      dispatch(
        UpdateFormColumn({
          id: ID,
          lable: values.lable,
          lable2: values.lable2,
          sequenceNum: values.sequenceNum,
          enabledFlag: values.enabledFlag,
          requiredFlag: values.requiredFlag,
          flexValueSetId: values.flexValueSetId,
          tableId: values.tableId,
          defaultValue: values.defaultValue,
        })
      )
        .unwrap()
        .then(() => {
          CustomAlert({ action: 'Edit' });
          formik.resetForm();
          document.getElementById('closeModalForm')?.click();
          dispatch(GetFormColumn({ id, withStatic: false }));
          dispatch(GetFormColumnById(ID));
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  function handleSelectChange(e) {
    const selectedOption = e.target.selectedOptions[0];

    const tableId = selectedOption.dataset.tableId;
    const validationType = selectedOption.dataset.validationType;
    const formatType = selectedOption.dataset.formatType;
    const FlexValueSetId = selectedOption.dataset.id;

    formik.setFieldValue('flexValueSetId', e.target.value);
    formik.setFieldValue('tableId', tableId || null);
    formik.setFieldValue('validationType', validationType || null);
    formik.setFieldValue('formatType', formatType || null);

    formik.setFieldValue('defaultValue', '');
    formik.setFieldValue('FlexValueSetId', FlexValueSetId || null);
  }

  return (
    <>
      <Loader loading={loading} />

      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-5">
            <div className="form-group mb-3">
              <label className="form-label">{t('LocalName')}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="lable2"
                onChange={formik.handleChange}
                value={formik.values.lable2 || ''}
                isInvalid={formik.touched.lable2 && formik.errors.lable2}
              />
            </div>
          </div>
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('GlobalName')}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="lable"
                onChange={formik.handleChange}
                value={formik.values.lable || ''}
                isInvalid={formik.touched.lable && formik.errors.lable}
              />
            </div>
          </div>
          <div className="col-xl-1">
            <div className="form-group mb-3">
              <label className="form-label">{t('Order')}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="sequenceNum"
                onChange={formik.handleChange}
                value={formik.values.sequenceNum || ''}
                isInvalid={!!formik.errors.sequenceNum}
                onInput={(e) => {
                  const input = e.target;
                  input.value = input.value.replace(/[^0-9]/g, '');
                }}
              />
            </div>
          </div>
          <div className="col-xl-1">
            <div className="form-group mb-3">
              <label className="form-label">{t('Required')}</label>
              <Form.Check
                style={{ margin: '10px 20px' }}
                type="checkbox"
                label=""
                name="requiredFlag"
                checked={formik.values.requiredFlag || false}
                onChange={formik.handleChange}
                // value={formik.values.requiredFlag}
                isInvalid={!!formik.errors.requiredFlag}
              />
            </div>
          </div>
          <div className="col-xl-1">
            <div className="form-group mb-3">
              <label className="form-label">{t('Active')}</label>
              <Form.Check
                style={{ margin: '10px 20px' }}
                type="checkbox"
                label=""
                name="enabledFlag"
                checked={formik.values.enabledFlag || false}
                onChange={formik.handleChange}
                // value={formik.values.enabledFlag  }
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('FieldType')}</label>
              <Form.Select
                className="form-select"
                name="flexValueSetId"
                // onChange={formik.handleChange}
                onChange={handleSelectChange}
                value={formik.values.flexValueSetId || ''}
                isInvalid={!!formik.errors.flexValueSetId}>
                <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {FlexValueSetList.map((item, idx) => (
                  <option
                    key={++idx}
                    value={item.id}
                    data-table-id={item.tableId}
                    data-validation-type={item.validationType}
                    data-format-type={item.formatType}
                    data-id={item.id}>
                    {i18n.language === 'ar' ? item.name2 : item.name}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('DefaultValue')}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="defaultValue"
                onChange={formik.handleChange}
                // value={formik.values.defaultValue || ""}
                // isInvalid={!!formik.errors.defaultValue}
              />
            </div>
          </div>
        </div>

        <div className="text-center">
          <button disabled={formik.isSubmitting} type="submit" className="btn me-1 btn-theme mb-1">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fa fa-save fa-fw me-1"></i>
            )}
            {t('Save')}
          </button>

          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </>
  );
};

export default EditFormColumn;
