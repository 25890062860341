import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import { useFormik } from 'formik';
import SwalAlert from '../../../../../components/Shared/Alert/SwalAlert';
import { useAppDispatch } from '../../../../../store/hooks';
import { addReturnsTransactions } from '../../../../../store/Sales/SalesReturns/salesReturnsSlice';
import { useTranslation } from 'react-i18next';
// import { Card } from '../../../../components/card/card';
import UpdateReturnLines from './UpdateReturnLines';
import { AddSaleReturnsSchema } from '../../../../ValidationForm/validationSchema';

type ReturnFormProps = {
  data: any;
};

const UpdateReturn = ({ data }: ReturnFormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // console.log(data);

  //#region Formik
  const formik = useFormik({
    initialValues: {
      no: data.no ?? '', // رقم الفاتورة
      code: data.code ?? '', // رقم الفاتورة
      organizationId: data.organizationId ?? '', // الفروع
      peopleId: data.peopleId ?? '', //العميل
      recipientname: data.recipientname ?? '', // اسم العميل وممكن يتعدل
      billToLocationId: data.billToLocationId ?? null,
      shipToLocationId: data.shipToLocationId ?? null,
      referenceId: data.id ?? null,
      transactionDate: data.transactionDate ?? '', // تاريخ الفاتورة
      deliveryDate: data.deliveryDate ?? '', //  تاريخ التوصبل
      expirationDate: '',
      dueDate: '', //  تاريخ الإستحقاق
      paymentTermsId: data.paymentTermsId ?? '', //  شروط الدفع .. الخاص بالعميل من جدول العميل تلقائيا
      description: data.description ?? '', //  وصف الفاتورة
      grossSale: data.grossSale ?? '', //
      discountRate: data.discountRate ?? '', //   نسبة الخصم
      discountAmount: data.discountAmount ?? '', //   مبلغ الخصم
      vat: data.vat ?? '', //   قيمة الضريبة
      netSale: data.netSale ?? '', //   الصافي مجموع اللاينس
      contractId: data.contractId ?? '', //   العقد
      ameStatus: '', //
      personId: data.personId ?? '', //   المندوب
      paymentTerms: '', //
      deliveryTerm: '', //
      inventoryId: data.inventoryId ?? '', //
      waybill: data.waybill ?? '', //
      phone: data.phone ?? '', //
      currencyCode: data.currencyCode ?? '', //
      currencyConversionRate: data.currencyConversionRate ?? '', //
      batchId: '', //
      // transactionsLinesList: [] as TransactionsLinesList[],
      transactionsLinesList:
        data?.transactionsLinesList?.map((item: any) => ({
          id: item.id,
          // transactionId: item.transactionId,
          itemId: item.itemId,
          lineNumber: item.lineNumber,
          uom: item.uom,
          description: item.description,
          price: item.price,
          qty: item.qty,
          discountRate: item.discountRate,
          discountAmount: item.discountAmount,
          vatRate: item.vatRate,
          total: item.total,
          vatAmount: item.vatAmount,
          // unitCost: item.unitCost,
          // codeCombinationId: item.codeCombinationId,
          // currencyCode: item.currencyCode,
          // currencyConversionRate: item.currencyConversionRate,
          // lotId: item.lotId,
          // headerId: item.headerId,
          // lineId: item.lineId,
          inventoryId: item.inventoryId,
        })) ?? [],
    },

    enableReinitialize: true,
    validationSchema: AddSaleReturnsSchema(t),
    onSubmit: (values) => {
      const grossSale = values.transactionsLinesList.reduce(
        (s: any, a: any) => Number(s) + Number(a.total),
        0
      );

      if (values.transactionsLinesList.length === 0) {
        SwalAlert({ text: 'حدد الأصناف للإسترداد ' });
        formik.setSubmitting(false);

        return;
      }

      dispatch(
        addReturnsTransactions({
          // no: values.no,
          // code: 'CM-24-' + values.no,
          // code: 'CM-' + getLastTwoDigitsOfYear(values.transactionDate) + '-' + values.no,
          organizationId: values.organizationId,
          peopleId: values.peopleId,
          recipientname: values.recipientname,
          billToLocationId: values.billToLocationId,
          shipToLocationId: values.shipToLocationId,
          referenceId: values.referenceId,
          transactionDate: values.transactionDate,
          deliveryDate: values.deliveryDate,
          expirationDate: values.expirationDate || null,
          dueDate: values.dueDate || null,
          paymentTermsId: values.paymentTermsId || null,
          // paymentTermsId: values.paymentTermsId,
          description: values.description,
          // grossSale: values.grossSale || 0,
          grossSale: grossSale,
          discountRate: values.discountRate || 0,
          discountAmount: values.discountAmount || 0,
          vat: values.vat || 0,
          netSale: values.netSale || 0,
          contractId: values.contractId || null,
          ameStatus: values.ameStatus || 0,
          personId: values.personId,
          paymentTerms: values.paymentTerms,
          deliveryTerm: values.deliveryTerm,
          inventoryId: values.inventoryId,
          waybill: values.waybill,
          phone: values.phone,
          currencyCode: values.currencyCode,
          currencyConversionRate: values.currencyConversionRate || 0,
          batchId: values.batchId || null,

          transactionsLinesList: values.transactionsLinesList
            .filter((f: any) => f.selectedRow === true)
            .map((item: any, index: any) => ({
              // transactionId: null,
              itemId: item.itemId,
              lineNumber: ++index,
              // lineNumber: item.lineNumber,
              uom: item.uom,
              description: item.description,
              price: Number(item.price),
              qty: Number(item.ReturnQty),
              discountRate: Number(item.discountRate),
              discountAmount: Number(item.discountAmount),
              vatRate: Number(item.vatRate),
              total: Number(item.total),
              vatAmount: Number(item.vatAmount),
              // unitCost: Number(item.unitCost),
              // codeCombinationId: null,
              codeCombinationId: '54F915DF-8A22-486D-B860-08DC2A376B50',
              // currencyCode: 'SR',
              // currencyConversionRate: 0,
              // lotId: null,
              // headerId: null,
              // lineId: null,
              inventoryId: values.inventoryId,
            })),
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            // Navigate('/SalesTransactions/SalesInvoices', { replace: true });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched } = formik;
  //#endregion

  return (
    <div>
      {/* <Card> */}
      <Form onSubmit={formik.handleSubmit} className="p-1">
        <div className="row">
          <div className="col-lg-3">
            <div className="form-group flex-nowrap mb-3">
              <label className="form-label">{t('الرقم')}</label>

              <div className="input-group flex-nowrap">
                <Form.Control
                  readOnly
                  autoComplete="off"
                  className="form-control fw-bold text-center"
                  type="text"
                  name="no"
                  onChange={formikhandleChange}
                  value={values.code || ''}
                  isInvalid={!!(touched.no && errors.no)}
                />
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="form-group mb-3">
              <label className="form-label">{t('تاريخ الإسترداد')}</label>
              <Form.Control
                className="form-control  text-center"
                type="date"
                name="transactionDate"
                onChange={formikhandleChange}
                value={values.transactionDate || ''}
                isInvalid={!!(touched.transactionDate && errors.transactionDate)}
              />
              {/* <div className="text-danger">{formik.errors.transactionDate}</div> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('Note')}</label>
              <Form.Control
                autoComplete="off"
                className="form-control"
                as="textarea"
                rows={1}
                name="description"
                onChange={formik.handleChange}
                value={values.description}
                isInvalid={!!(touched.description && errors.description)}
              />
            </div>
          </div>
        </div>

        <UpdateReturnLines formik={formik} />

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn btn-lg me-1 btn-theme mb-1">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t('Add')}
          </button>
          <button type="button" className=" btn btn-lg me-1 btn-default mb-1">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
      {/* </Card> */}
    </div>
  );
};

export default UpdateReturn;
