import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { GetPriceListList } from '../../store/INV/PriceList/PriceListSlice';

const useGetPriceList = () => {

    const dispatch = useAppDispatch();
  
    useEffect(() => {
      if (PriceList?.length === 0) {
        dispatch(GetPriceListList());
      }
    }, []);
    
    const { PriceList, loading } = useAppSelector((state) => state.PriceList);
   

  return { PriceList, loading }
}

export default useGetPriceList
