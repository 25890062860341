import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
import { getBanks } from "../../store/Lookup/lookupSlice";
import { useTranslation } from "react-i18next";
 

const useGetBanks = ()=>{

  const {i18n} = useTranslation()
 
    const dispatch = useDispatch();

    const {Banks , loading , error} = useSelector(state => state.Lookup)

    useEffect(()=>{
      dispatch(getBanks())
    },[])



    const BanksOptions =
    Banks &&
    Banks?.map((item) => ({
      value: item.id,
      label: i18n.language === "en" ? item.name : item.name2,
    }));



    
    return { Banks , BanksOptions , loading , error } ;
};

export default useGetBanks ;