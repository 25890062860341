import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
import { getOrgType } from "../../store/Lookup/lookupSlice";

 


const useLookupOrgType = ()=>{

    const dispatch = useDispatch();
    
    useEffect(()=>{
        dispatch(getOrgType())
      },[dispatch])

    const { ORG_TYPE } = useSelector((state) => state.Lookup); 

    return { ORG_TYPE } ;
};

export default useLookupOrgType ;