import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import useGetReportsList from '../../../../hooks/NTX/useGetReportsList';
import { GetReportChartList } from '../../../../store/NTX/Reports/ReportShape/reportShapeSlice';
import 'gridstack/dist/gridstack.min.css';
import 'gridstack/dist/gridstack.min.css';
import ControlledExample from '../Gridstack/ControlledExample';
import { Card, CardBody } from '../../../../components/card/card';
import { useFormik } from 'formik';
import { AddDashboard } from '../../../../store/NTX/Reports/Dashboard/dashboardSlice';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import useLookupModule from '../../../../hooks/Lookups/use-Lookup-Module';
import useGetChartShapesList from '../../../../hooks/NTX/useGetChartShapesList';
import { useNavigate } from 'react-router-dom';

import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import Loading from '../../../../components/Shared/Loader/Loading';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import { AddUpdateDashboardSchema } from '../../../ValidationForm/validationSchema';

interface widgetItems {
  id: string;
  x: number;
  y: number;
  w: number;
  h: number;
}

const Add = () => {
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const [items, setItems] = useState<widgetItems[]>([]);
  const [reportId, setReportId] = useState<any>();
  const [chartId, setChartId] = useState<any>(null);
  const { Module } = useLookupModule();
  const dispatch = useAppDispatch();
  const { ChartShapesList, loading: loading3 } = useGetChartShapesList();
  const { ReportsList, loading } = useGetReportsList();
  const { ReportChartList, loading: loading2 } = useAppSelector((state) => state.ReportShape);
  useEffect(() => {
    dispatch(GetReportChartList());
  }, []);

  const handleAddItems = () => {
    if (items.some((item) => item.id === chartId)) {
      // Swal.fire('هذا المؤشر موجود بالفعل');
      SwalAlert({ text: 'هذا المؤشر موجود بالفعل', icon: 'info' });
      return;
    }

    setItems((items1) => [
      ...items,
      {
        id: chartId,
        // chartName: ChartName,
        // iconChart: IconChart,
        x: 0,
        y: 0,
        w: 2,
        h: 2,
      },
    ]);
    setChartId(null);
  };
  //________________________

  const formik = useFormik({
    initialValues: {
      name: '',
      name2: '',
      moduleId: '',
      chartList: [
        {
          dashboardId: '',
          xPosition: 0,
          yPosition: 0,
          width: 0,
          height: 0,
          reportChartId: '',
        },
      ],
    },
    validationSchema: AddUpdateDashboardSchema(t),
    onSubmit: (values, { resetForm }) => {
      if (items.length === 0) {
        SwalAlert({ text: 'يجب إضافة مؤشر واحد على الأقل' });

        formik.setSubmitting(false);
        return;
      }

      dispatch(
        AddDashboard({
          name: values.name,
          name2: values.name2,
          moduleId: values.moduleId,
          chartList: items.map((m) => ({
            xPosition: m.x,
            yPosition: m.y,
            width: m.w,
            height: m.h,
            reportChartId: m.id,
          })),
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          CustomAlert({ action: 'Add' });
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  const OptionsReportChart = ReportChartList?.filter((f) => f.reportId === reportId).map(
    (item) => ({
      value: item.id,
      label: (
        <>
          <i
            className={
              ChartShapesList.find((f) => f.id === item.shapeId)?.icon + ' text-theme'
            }></i>
          {i18n.language === 'en' ? item.name : item.name2}
        </>
      ),
    })
  );

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('إدارة لوحة البيانات'),
      link: '/NtxDashboardManagement',
    },
    {
      name: t('إضـافة'),
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('إضافة لوحة بيانات')} />

      <hr />

      <Loading loading={loading || loading2 || loading3}>
        <CardBody>
          <div className="row">
            <div className="col-xl-3 bg-inverse bg-opacity-5 p-3 rounded-3">
              <Form onSubmit={formik.handleSubmit}>
                <div className="col-xl-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('LocalName')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="name2"
                      onChange={formik.handleChange}
                      value={formik.values.name2}
                      isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.name}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-xl-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('GlobalName')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      isInvalid={!!(formik.touched.name && formik.errors.name)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.name2}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-xl-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Application')}</label>
                    <Form.Select
                      className="form-select"
                      name="moduleId"
                      value={formik.values.moduleId}
                      onChange={formik.handleChange}
                      isInvalid={!!(formik.touched.moduleId && formik.errors.moduleId)}>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {Module &&
                        Module.map((item: any, idx: any) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.moduleId}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="row bg-inverse bg-opacity-10 p-2 rounded-3 border border-2 border-theme">
                  <div className="col-xl-12">
                    <div className="form-group mb-3">
                      <label className="form-label">التقارير</label>
                      <Form.Select
                        className="form-select"
                        name="Reports"
                        onChange={(e) => setReportId(e.target.value)}>
                        <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                        {ReportsList &&
                          ReportsList?.filter((f) =>
                            ReportChartList?.some(
                              (s) => s?.reportId?.toLowerCase() === f?.id?.toLowerCase()
                            )
                          ).map((item, idx) => (
                            <option key={++idx} value={item.id}>
                              {i18n.language === 'ar' ? item.name2 : item.name}
                            </option>
                          ))}
                      </Form.Select>
                    </div>
                  </div>

                  <div className="col-xl-12 ">
                    <div className="form-group mb-3">
                      <label className="form-label">المؤشرات</label>
                      <div className="d-flex  justify-content-between">
                        <Select
                          isClearable
                          className="w-100"
                          classNamePrefix="react-select"
                          isSearchable={true}
                          options={OptionsReportChart}
                          value={
                            OptionsReportChart?.find((f) => f.value?.toLowerCase() === chartId) ||
                            null
                          }
                          onChange={(option: any) =>
                            setChartId(option === null ? null : option.value)
                          }
                          placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                        />
                        <button
                          type="button"
                          disabled={!chartId}
                          className="btn btn-primary rounded-0"
                          onClick={handleAddItems}>
                          <i className="bi bi-plus fa-lg fw-bold"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-center mt-5">
                  <button
                    disabled={formik.isSubmitting}
                    type="submit"
                    className="btn me-3 btn-theme btn-lg">
                    {formik.isSubmitting ? (
                      <div className="spinner-border spinner-border-sm me-4"></div>
                    ) : (
                      <i className="fas fa-lg fa-fw me-4 fa-plus"></i>
                    )}
                    {t('Add')}
                  </button>
                  <button
                    type="button"
                    className=" btn me-1 btn-default btn-lg"
                    onClick={() => Navigate('/NtxDashboardManagement', { replace: true })}>
                    <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                  </button>
                </div>
              </Form>
            </div>

            <div className="col-xl-9">
              <Card style={{ minHeight: '34rem' }}>
                <ControlledExample items={items} setItems={setItems} />
              </Card>
            </div>
          </div>
        </CardBody>
      </Loading>
    </div>
  );
};

export default Add;

//  {/* <Form.Select
//               className="form-select"
//               name="chartId"
//               value={chartId}
//               onChange={(e) => setChartId(e.target.value)}
//             >
//               <option value="">
//                 {i18n.language === "ar" ? "إختر" : "Choose"}
//               </option>
//               {ReportChartList &&
//                 ReportChartList.filter(
//                   (f) => f.reportId === reportId
//                 ).map((item, idx) => (
//                   <option key={++idx} value={item.id}>
//                     {i18n.language === "ar" ? item.name2 : item.name}
//                   </option>
//                 ))}
//             </Form.Select> */}
