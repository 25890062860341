import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../../API/axiosNeatex";

const initialState = {
  DynamicFormList: [],
  StaticFormList: [],
  loading: false,
  loading2: false,
  error: null,
  DynamicFormListByID: [],
  ExtraInformationTablesFormList: [],
  ExtraInformationRecordTablesFormList: [],
};

export const AddDynamicForm = createAsyncThunk(
  "Forms/AddDynamicForm",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("DynamicForm/AddDynamicForm", item);
      const {data} = await ntxAPI.post("DynamicForm/AddDynamicForm" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetDynamicForm = createAsyncThunk(
  "Forms/GetDynamicForm",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.get("DynamicForm/GetDynamicFormList" );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetDynamicFormByID = createAsyncThunk(
  "Forms/GetDynamicFormByID",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`DynamicForm/GetDynamicFormById?Id=${id}`);
      const {data} = await ntxAPI.get(`DynamicForm/GetDynamicFormById?Id=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const UodateDynamicForm = createAsyncThunk(
  "Forms/UodateDynamicForm",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("DynamicForm/UpdateDynamicForm", item);
      const {data} = await ntxAPI.post( "DynamicForm/UpdateDynamicForm", item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetStaticForm = createAsyncThunk(
  "Forms/GetStaticForm",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(
      //   "DynamicForm/GetUnEnableStaticDynamicFormList",
      //   thunkAPI
      // );
      const {data} = await ntxAPI.get("DynamicForm/GetUnEnableStaticDynamicFormList" );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetExtraInformationTablesFormList = createAsyncThunk(
  "Forms/GetExtraInformationTablesFormList",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(
      //   "DynamicForm/GetExtraInformationTablesFormList",
      //   thunkAPI
      // );
      const {data} = await ntxAPI.get("DynamicForm/GetExtraInformationTablesFormList" );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetExtraInformationRecordTablesFormList = createAsyncThunk(
  "Forms/GetExtraInformationRecordTablesFormList",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.get( "DynamicForm/GetExtraInformationRecordTablesFormList");
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);



const FormSlice = createSlice({
  name: "Forms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all Dynamic Forms
    builder
      .addCase(GetDynamicForm.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetDynamicForm.fulfilled, (state, action) => {
        state.loading = false;
        state.DynamicFormList = action.payload;
      })
      .addCase(GetDynamicForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // insert
      .addCase(AddDynamicForm.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddDynamicForm.fulfilled, (state, action) => {
        state.loading = false;
        state.DynamicFormList.push(action.payload);
      })
      .addCase(AddDynamicForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // get one Dynamic Forms by id
      .addCase(GetDynamicFormByID.pending, (state) => {
        state.loading2 = true;
        state.error = null;
        state.DynamicFormListByID = [];
      })
      .addCase(GetDynamicFormByID.fulfilled, (state, action) => {
        state.loading2 = false;
        state.DynamicFormListByID = action.payload;
      })
      .addCase(GetDynamicFormByID.rejected, (state, action) => {
        state.loading2 = false;
        state.error = action.payload;
      })
      // Update Dynamic Forms by id
      .addCase(UodateDynamicForm.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UodateDynamicForm.fulfilled, (state, action) => {
        state.loading = false;
        state.DynamicFormListByID = action.payload;
      })
      .addCase(UodateDynamicForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // get StaticForm
      .addCase(GetStaticForm.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetStaticForm.fulfilled, (state, action) => {
        state.loading = false;
        state.StaticFormList = action.payload;
      })
      .addCase(GetStaticForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // get ExtraInformationTablesFormList
      .addCase(GetExtraInformationTablesFormList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetExtraInformationTablesFormList.fulfilled, (state, action) => {
        state.loading = false;
        state.ExtraInformationTablesFormList = action.payload;
      })
      .addCase(GetExtraInformationTablesFormList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // get ExtraInformationRecordTablesFormList
      .addCase(GetExtraInformationRecordTablesFormList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetExtraInformationRecordTablesFormList.fulfilled, (state, action) => {
        state.loading = false;
        state.ExtraInformationRecordTablesFormList = action.payload;
      })
      .addCase(GetExtraInformationRecordTablesFormList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default FormSlice.reducer;
