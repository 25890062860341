import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { PosSessionModel , UpdatePosSessionModel } from './PosModels';
import { APIResponse } from '../../Shared/shared';
import { AxiosError } from 'axios';

export const addPosSession = createAsyncThunk(
  'PosSession/addPosSession',
  async (item: PosSessionModel, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<PosSessionModel>>('Pos/AddPosSession', item);
      if (data.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const updatPosSession = createAsyncThunk(
  'PosSession/updatPosSession',
  async (item: UpdatePosSessionModel, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<boolean>>('Pos/UpdatPosSession', item);
      if (data.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPosSessionByPosId = createAsyncThunk(
  'PosSession/getPosSessionByPosId',
  async (posId, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<PosSessionModel[]>>(
        `Pos/GetPosSessionByPosId?PosId=${posId}`
      );
      if (data.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const getPosSessionById = createAsyncThunk(
  'PosSession/getPosSessionById',
  async (sessionId : string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<PosSessionModel>>(
        `Pos/GetPosSessionById?Id=${sessionId}`
      );
      if (data.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const checkSessionOpen = createAsyncThunk(
  'PosSession/checkSessionOpen',
  async (item: {posId : string , personId : string}, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<string>>('Pos/CheckSessionOpen', item);
      return data;
      // if (data.succeeded) {
      // } else {
      //   return rejectWithValue(data.message);
      // }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// export const checkSessionOpen = createAsyncThunk<
// APIResponse<string>, // Return type
// {posId : string , personId : string}
// >(
// 'PosSession/checkSessionOpen',
// async (item: {posId : string , personId : string}, { rejectWithValue }) => {
//   try {
//     const { data } = await ntxAPI.post<APIResponse<string>>('Pos/CheckSessionOpen', item);
//     if (data.succeeded) {
//       return data;
//     } else {
//       return rejectWithValue(data.message);
//     }
//   } catch (error: any) {
//     const axiosError: AxiosError = error;
//     return rejectWithValue(axiosError.message);
//   }
// }
// );
 
const initialState = {
  PosSessionList: [] as PosSessionModel[],
  PosSessionListByPosId: [] as PosSessionModel[],
  PosSessionById: {} as PosSessionModel,
  loading: false,
  error: null as string | null,
};
 

const PosCashierSlice = createSlice({
  name: 'PosSession',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPosSessionByPosId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPosSessionByPosId.fulfilled, (state, action: PayloadAction<APIResponse<PosSessionModel[]>>) => {
        state.loading = false;
        state.PosSessionListByPosId = action.payload.data;
      })
      .addCase(getPosSessionByPosId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


      .addCase(getPosSessionById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPosSessionById.fulfilled, (state, action: PayloadAction<APIResponse<PosSessionModel>>) => {
        state.loading = false;
        state.PosSessionById = action.payload.data;
      })
      .addCase(getPosSessionById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


      .addCase(addPosSession.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        addPosSession.fulfilled,
        (state, action: PayloadAction<APIResponse<PosSessionModel>>) => {
          state.loading = false;
          state.PosSessionList.push(action.payload.data);
        }
      )
      .addCase(addPosSession.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default PosCashierSlice.reducer;
