import   { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
 import { GetMyNotificationMessageList } from "../../store/NTX/Notifications/notificationSlice";

const useGetMyNotificationMessage = () => {
  const dispatch = useAppDispatch();
  const { MyNotificationMessageList, loading } = useAppSelector((state) => state.Notifications);

 
  useEffect(() => {
    if (!MyNotificationMessageList?.length) {      
      dispatch(GetMyNotificationMessageList());
    }
  }, []);

 

  return {MyNotificationMessageList , loading };
};

export default useGetMyNotificationMessage;
