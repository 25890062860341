import React, { memo, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useLookupModule from "../../../../../../hooks/Lookups/use-Lookup-Module";
import {
  GetReportSourceList,
} from "../../../../../../store/NTX/Reports/reportSlice";
import Select from "react-select";
import { DataIcons } from "../../../../../../assets/icons";
import * as yup from "yup"; //
import { useSelector } from "react-redux";
import useGetDynamicForm from "../../../../../../hooks/Forms/useGetDynamicForm";
import { GetReportPrintTemplateTypeList } from "../../../../../../store/NTX/PrintTemplate/PrintTemplateSlice";

const Step1 = ({ setCheckValidationStep1, setDataStep1, DataById }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { Module } = useLookupModule();


  const { PrintTemplateTypeList } = useSelector((state) => state.PrintTemplate);

  
  const [FormRecordListByTableId, setFormRecordListByTableId] = useState([]);
  const [FormTypeListByTableId, setFormTypeListByTableId] = useState([]);
  const { DynamicFormList } = useGetDynamicForm();
  useEffect(() => {
    if (ReportSourceList?.length === 0) {
      dispatch(GetReportSourceList());
    }
    // if (PrintTemplateTypeList?.length === 0) {
    // }
    dispatch(GetReportPrintTemplateTypeList());

  }, [dispatch]);
  const { ReportSourceList } = useSelector((state) => state.Reports);

  const [formData, setFormData] = useState({
    viewName: "",
    name: "",
    name2: "",
    moduleId: "",
    templateType:"",
    reportSourceId: "",
    pageSize: "",
    icon: "",
    dynamicFormId:"",
    typeForm: "0",
    FormRecordId: "",
    FormTypeId: "",
    hasExtraInformation: "false",
    hasExtraInformationRecord: "false",
    hasExtraInformationType: "false",
  });

  //_______________________________________________
 
  const validationSchema = yup.object().shape({
    name: yup.string().required(t("Name is required")),
    name2: yup.string().required(t("Name2 is required")),
    moduleId: yup.string().required(t("Module is required")),
    reportSourceId: yup.string().required(t("Table Name is required")),
    pageSize: yup.string().required(t("page Size is required")),
  });
  // Function to check if all fields are filled
  const areAllFieldsFilled = () => {
    try {
      validationSchema.validateSync(formData, { abortEarly: false });
      return true; // All fields are filled
    } catch (error) {
      return false; // Some field is empty
    }
  };
 
  useEffect(() => {
    setFormData({
      name: DataById.name,
      name2: DataById.name2,
      moduleId: DataById.moduleId,
      templateType : DataById.printTemplateTypeId,
      pageSize: DataById.pageType,
      icon: DataById.icon,
      viewName: ReportSourceList?.find((f) => f.id === DataById.reportSourceId)
        ?.viewName,
      reportSourceId: DataById.reportSourceId,
      typeForm: DataById.dynamicFormType,
      dynamicFormId: DataById.dynamicFormId,
      tableId: ReportSourceList?.find(
        (f) => f.id === DataById.reportSourceId
      )?.tableId,
      hasExtraInformation: ReportSourceList?.find(
        (f) => f.id === DataById.reportSourceId
      )?.hasExtraInformation,
      hasExtraInformationRecord: ReportSourceList?.find(
        (f) => f.id === DataById.reportSourceId
      )?.hasExtraInformationRecord,
      hasExtraInformationType: ReportSourceList?.find(
        (f) => f.id === DataById.reportSourceId
      )?.hasExtraInformationType,
    });
  }, [DataById , ReportSourceList ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    setCheckValidationStep1(areAllFieldsFilled());
    setDataStep1(formData);
  }, [formData]);

  
  useEffect(() => {
    const filteredFormRecordList = DynamicFormList?.filter(
      (f) => f.dynamicFormTypesId === 3 && f.tableId === formData.tableId
    );
    const filteredFormTypeList = DynamicFormList?.filter(
      (f) => f.dynamicFormTypesId === 4 && f.tableId === formData.tableId
    );
  
    setFormRecordListByTableId(filteredFormRecordList);
    setFormTypeListByTableId(filteredFormTypeList);
  }, [DynamicFormList, formData.tableId]);


  return (
    <>
      <div className="row">
        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("LocalName")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name2"
              value={formData.name2 || ""}
              onChange={handleInputChange}
              autoComplete="false"
            />
          </div>
        </div>
        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("GlobalName")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name"
              value={formData.name || ""}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("Application")}</label>
            <Form.Select
                          disabled

              className="form-select bg-inverse bg-opacity-10"
              name="moduleId"
              value={formData.moduleId || ""}
              onChange={handleInputChange}
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {Module &&
                Module?.map((item, idx) => (
                  <option key={++idx} value={item.id}>
                    {i18n.language === "ar" ? item.name2 : item.name}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>

        
        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("نوع قالب الطباعة")}</label>
            <Form.Select
                          disabled

              className="form-select mb-3 bg-inverse bg-opacity-10"
              name="templateType"
              value={formData.templateType}
              onChange={(e) => handleInputChange(e)}
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {PrintTemplateTypeList &&
                PrintTemplateTypeList?.filter(f=> f.moduleId === formData.moduleId)?.map((item, idx) => (
                  <option key={item.id} value={item.id}>
                    {i18n.language === "ar" ? item.name2 : item.name}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>

        
      </div>

      <div className="row">
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Data_Source")}</label>
            <Form.Select
              disabled
              className="form-select mb-3 bg-inverse bg-opacity-10"
              // name="reportSourceId"
              value={formData.reportSourceId || ""}
              // onChange={(e) => {
              //   handleInputChange(e);
              // }}
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {ReportSourceList &&
                ReportSourceList?.map((item, idx) => (
                  <option key={++idx} value={item.id}>
                    {i18n.language === "en" ? item.name : item.name2}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Layout")}</label>
            <Form.Select
              className="form-select mb-3"
              name="pageSize"
              value={formData.pageSize || ""}
              onChange={(e) => handleInputChange(e)}
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              <option value="Landscape">Landscape</option>
              <option value="Portrait">Portrait</option>
            </Form.Select>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Icon")}</label>

            <Select
              classNamePrefix="react-select"
              isSearchable={true}
              required
              options={DataIcons}
              onChange={(option) =>
                setFormData({ ...formData, icon: option.value })
              }
              placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
              value={DataIcons?.find((f) => f.value === formData.icon)}
            />
          </div>
        </div>
      </div>

      {(formData.hasExtraInformation === true ||
        formData.hasExtraInformationRecord === true ||
        formData.hasExtraInformationType === true) && (
        <div className="row">
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">نوع البيانات</label>
              <Form.Select
                disabled
                className="form-select mb-3 bg-inverse bg-opacity-10"
                // name="typeForm"
                value={formData.typeForm}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="0">
                  {i18n.language === "ar" ? "البيانات الأساسية" : "Basic Data"}
                </option>
                {formData.hasExtraInformation === true && (
                  <option value="2">
                    {i18n.language === "ar"
                      ? " مع البيانات الاضافية"
                      : "with Extra Information"}
                  </option>
                )}
                {formData.hasExtraInformationRecord === true && (
                  <option value="3">
                    {i18n.language === "ar"
                      ? " مع السجلات الإضافية"
                      : "with Extra Information Record"}
                  </option>
                )}
                {formData.hasExtraInformationType === true && (
                  <option value="4">
                    {i18n.language === "ar"
                      ? "مع الأنواع الإضافية"
                      : "with Extra Information Type"}
                  </option>
                )}
              </Form.Select>
            </div>
          </div>

          <div className="col-xl-4">
            {formData.hasExtraInformationRecord === true &&
              formData.tableId !== null &&
              formData.typeForm === "3" && (
                <div className="form-group mb-3">
                  <label className="form-label">{t("Record")}</label>
                  <Form.Select
                    disabled
                    className="form-select mb-3 bg-inverse bg-opacity-10"
                    // name="FormRecordId"
                    value={formData.dynamicFormId}
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="">
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {DynamicFormList &&
                      FormRecordListByTableId?.map((item, idx) => (
                        <option key={item.id} value={item.id}>
                          {i18n.language === "ar" ? item.name2 : item.name}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              )}

            {formData.hasExtraInformationType === true &&
              formData.tableId !== null &&
              formData.typeForm === "4" && (
                <div className="form-group mb-3">
                  <label className="form-label">{t("Type")}</label>
                  <Form.Select
                    // disabled
                    className="form-select mb-3 bg-inverse bg-opacity-10"
                    // name="FormTypeId"
                    value={formData.dynamicFormId}
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="">
                      {i18n.language === "ar" ? "كل الأنواع" : "All Types"}
                    </option>
                    {DynamicFormList &&
                      FormTypeListByTableId?.map((item, idx) => (
                        <option key={item.id} value={item.id}>
                          {i18n.language === "ar" ? item.name2 : item.name}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              )}
          </div>
        </div>
      )}
    </>
  );
};
export default memo(Step1);
