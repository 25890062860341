import React, { Fragment } from 'react';
import Modal from 'react-bootstrap/Modal';

function NeatixModal({
  size, // sm  - lg  - xl
  show,
  setShow,
  title = '',
  children,
  bodyClassName = '',
  headerClassName = '',
  showHeader = true,
  fullscreen = false,
}) {

  console.log();
  return (
    <Fragment>
      <Modal
        size={size}
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="neatix-modal-title"
        // backdrop={backdrop ? 'static' : ''}
        fullscreen={fullscreen}>
        {showHeader && (
          <Modal.Header closeButton className={headerClassName}>
            <Modal.Title id="neatix-modal-title">{title}</Modal.Title>
          </Modal.Header>
        )}

        <Modal.Body className={bodyClassName}>{children}</Modal.Body>
      </Modal>
    </Fragment>
  );
}

export default NeatixModal;
