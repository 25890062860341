import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

import {
  getPurchaseRequestById,
  cleanUpPurchaseRequestById,
} from '../../../store/purchases/PurchaseRequests/purchaseRequestsSlice';

interface Props {
  id: string | undefined;
}

const useGetPurchaseRequestById = ({ id }: Props) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getPurchaseRequestById(id));
    }

    return () => {
      dispatch(cleanUpPurchaseRequestById());
    };
  }, [dispatch, id]);
  const { PurchaseRequestsById, loading, error } = useAppSelector((state) => state.PurchaseRequest);

  return { PurchaseRequestsById, loading, error };
};

export default useGetPurchaseRequestById;
