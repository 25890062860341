/* eslint-disable default-case */
import { memo, useEffect, useState } from "react";
import { Field, RuleGroupType } from "react-querybuilder";
import {
  QueryBuilder,
  formatQuery,
  defaultOperators,
} from "react-querybuilder";
import { QueryBuilderBootstrap } from "@react-querybuilder/bootstrap";
import { QueryBuilderDnD } from "@react-querybuilder/dnd";
import "./styles.css";
import CustomTranslations from "./Componant/CustomTranslations";
import CustomCombinators from "./Componant/CustomCombinators";

// const initialQuery: RuleGroupType = {
//   combinator: "and",
//   rules: [
//     // { field: "firstName", operator: "beginsWith", value: "" },
//     // { field: "lastName", operator: "in", value: "Vai,Vaughan" },
//   ],
// };

interface Props {
  fields: Field[];
  setParams: React.Dispatch<React.SetStateAction<any[]>>;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  setQueryBuilderReact: React.Dispatch<React.SetStateAction<string>>;

  queryBuilderReact: string;

}

const BuilderQuerys: React.FC<Props> = (props) => {


  let Parameters: {
    paramName: string;
    value: string;
    inputType: string;
    beginWith: string;
    endWith: string;
    hasIn: boolean;
  }[];
  const initialQuery: RuleGroupType = JSON.parse(props.queryBuilderReact);
  const [query, setQuery] = useState(initialQuery);


  const [paramsState, setParamsState] = useState<string[]>();


  function fun(): string[] {
    Parameters = [];
    var SqlQuery = formatQuery(query, "parameterized_named");
    if (Object.keys(SqlQuery.params)?.length === 0) {
      props.setParams([]);
    }
    return Object.keys(SqlQuery.params).map((key) => {
      const value = SqlQuery.params[key];
      const splits = key.split("_");
      const concatenatedString = splits?.slice(0, -1).join("_");
      const FType: any = props.fields?.filter(
        (item) => item.name === concatenatedString
      )[0]?.inputType;

      let para: any = {
        paramName: key,
        value: "",
        inputType: FType,
        beginWith: "",
        endWith: "",
        hasIn: false,
      };
      if (para.inputType === "text" && value.includes("%")) {
        para.beginWith = value.startsWith("%") ? "%" : "";
        para.endWith = value.endsWith("%") ? "%" : "";
      }

      if (SqlQuery.sql.includes(" in (:" + key)) {
        para.hasIn = true;
      }

      Parameters.push(para);
      props.setParams(Parameters);

      return `${key}: ${value} , `;
    });
  }

  useEffect(() => {
    fun();
  }, [props.fields]);


  useEffect(() => {
    setParamsState(fun());
    props.setQuery(
      formatQuery(query, "parameterized_named").sql.replace(/:/g, "@")
    );
    props.setQueryBuilderReact(JSON.stringify(query));
  }, [query]);

  //_________________________________________________________________________________________
  const [language, setLanguage] = useState<string>(localStorage.language);

  const GetOperators = (fieldName: string): any[] => {
    const field = props.fields?.find((fld) => fld.name === fieldName);

    if (language === "ar") {
      switch (field?.inputType) {
        case "text":
          return [
            { name: "=", label: "=" },
            { name: "!=", label: "!=" },
            { name: "contains", label: "يحتوي" },
            { name: "beginsWith", label: "يبدأ بـ" },
            { name: "endsWith", label: "ينتهي بـ" },
            { name: "doesNotContain", label: "لا يحتوي " },
            { name: "doesNotBeginWith", label: "لا يبدأ بـ" },
            { name: "doesNotEndWith", label: "لا ينتهي بـ" },
            { name: "null", label: "قيمة فارغة" },
            { name: "notNull", label: "قيمة غير فارغة" },

            // { name: "in", label: "يتضمن" },
            // { name: "notIn", label: "لا يتضمن" },
          ];
        case "number":
          return [
            ...defaultOperators?.filter((op) => ["=", "!="].includes(op.name)),
            { name: "<", label: "less than" },
            { name: "<=", label: "less than or equal to" },
            { name: ">", label: "greater than" },
            { name: ">=", label: "greater than or equal to" },
            ...defaultOperators?.filter((op) =>
              ["null", "notNull"].includes(op.name)
            ),
          ];
        case "date":
          return [
            { name: "=", label: "=" },
            { name: "!=", label: "!=" },
            { name: "<", label: "before" },
            { name: "<=", label: "on or before" },
            { name: ">", label: "after" },
            { name: ">=", label: "on or after" },
            ...defaultOperators?.filter((op) =>
              ["null", "notNull"].includes(op.name)
            ),
          ];
        case "time":
          return [
            { name: "=", label: "=" },
            { name: "!=", label: "!=" },
            { name: "<", label: "before" },
            { name: "<=", label: "on or before" },
            { name: ">", label: "after" },
            { name: ">=", label: "on or after" },
            ...defaultOperators?.filter((op) =>
              ["null", "notNull"].includes(op.name)
            ),
          ];
      }
    } else {
      switch (field?.inputType) {
        case "text":
          return [
            { name: "=", label: "is" },
            { name: "!=", label: "is not" },
            ...defaultOperators?.filter((op) =>
              [
                "contains",
                "beginsWith",
                "endsWith",
                "doesNotContain",
                "doesNotBeginWith",
                "doesNotEndWith",
                "null",
                "notNull",
                "in",
                "notIn",
              ].includes(op.name)
            ),
          ];
        case "number":
          return [
            ...defaultOperators?.filter((op) => ["=", "!="].includes(op.name)),
            { name: "<", label: "less than" },
            { name: "<=", label: "less than or equal to" },
            { name: ">", label: "greater than" },
            { name: ">=", label: "greater than or equal to" },
            ...defaultOperators?.filter((op) =>
              ["null", "notNull"].includes(op.name)
            ),
          ];
        case "date":
          return [
            { name: "=", label: "on" },
            { name: "!=", label: "not on" },
            { name: "<", label: "before" },
            { name: "<=", label: "on or before" },
            { name: ">", label: "after" },
            { name: ">=", label: "on or after" },
            ...defaultOperators?.filter((op) =>
              ["null", "notNull"].includes(op.name)
            ),
          ];
        case "time":
          return [
            { name: "=", label: "=" },
            { name: "!=", label: "!=" },
            { name: "<", label: "before" },
            { name: "<=", label: "on or before" },
            { name: ">", label: "after" },
            { name: ">=", label: "on or after" },
            ...defaultOperators?.filter((op) =>
              ["null", "notNull"].includes(op.name)
            ),
          ];
      }
    }

    return defaultOperators;
  };
  //_________________________________________________________________________________________

  return (
    <>
    <div className="fs-3 text-theme border border-theme">
     
    
      <QueryBuilderBootstrap>
        <QueryBuilderDnD>
          <QueryBuilder
            fields={props.fields}
            getOperators={GetOperators}
            showCloneButtons
            controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
            translations={CustomTranslations["ar"]}
            combinators={CustomCombinators["ar"]}
            query={query}
            onQueryChange={(q) => setQuery(q)}
          />
        </QueryBuilderDnD>
      </QueryBuilderBootstrap>
      </div>
  
    </>
  );
};

export default memo(BuilderQuerys);
