import { useFormik } from 'formik';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { TransactionsLinesList } from '../../../../store/Sales/SalesInvoices/SalesInvoicesModels';
import { Card, CardFooter } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import useGetPersons from '../../../../hooks/HR/use-get-persons';
import useGetOrganizations from '../../../../hooks/HR/use-get-organizations';
// import PurchaseLines from '../TransactionsLines/PurchaseLines';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch } from '../../../../store/hooks';
import useGetPeoplesList from '../../../../hooks/NTX/useGetPeoplesList';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import { AddQuoteInvoiceSchema } from '../../../ValidationForm/validationSchema';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import { useContext, useEffect, useState } from 'react';
import { AppSettings } from '../../../../config/app-settings';
import { addPurchaseBill } from '../../../../store/purchases/PurchaseBill/purchaseBillSlice';
import { addApInvoices } from '../../../../store/Finance/AP/ApInvoices/ApInvoicesSlice';
import useGetSuppliersList from '../../../../hooks/NTX/Peoples/useGetSuppliersList';
import { TreeSelect } from 'antd';
import useGetPaymentTermsList from '../../../../hooks/Sales/useGetItemsList';
import useGetPaymentMethod from '../../../../hooks/Payrolls/use-Get-PaymentMethod';
import ApInvoiceLines from './ApInvoiceLines';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import PurchaseRequestList from '../../../PUR/Transactions/PurchaseRequest/PurchaseRequestList';
import useGetPurchaseRequestById from '../../../../hooks/PUR/PurchaseRequest/useGetPurchaseRequestById';
import ApPaymentSchedules from './ApPaymentSchedules';
import useGetOrganizationWithPermissionByType from '../../../../hooks/NTX/Organization/useGetOrganizationWithPermissionByType';
import confirmAction from '../../../../components/Shared/Alert/confirmAction';

const Add = () => {
  const [showPurchaseRequest, setShowPurchaseRequest] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { Currency, loading: loading4 } = useLookupCurrency();
  const { dataEmployees, loading } = useGetPersons();
  // const { BranchsOptions, InventoryOptions, loading: loading3 } = useGetOrganizations();
  const { DataOptions: BranchsOptions, loading: loading3 } =
    useGetOrganizationWithPermissionByType('150');
  const { treeSuppliersData, error: error2, loading: loading2 } = useGetSuppliersList();
  const { PaymentTermsList } = useGetPaymentTermsList();
  const { PaymentMethodList } = useGetPaymentMethod();
  const { referenceId } = useParams();
  const { PurchaseRequestsById: data } = useGetPurchaseRequestById({ id: referenceId }); // طلب شراء

  useEffect(() => {
    setShowPurchaseRequest(false);
  }, [data]);

  //#region Formik
  const formik = useFormik({
    initialValues: {
      invoiceNum: '',
      peopleId: data.peopleId ?? '',
      invoiceType: '',
      referenceType: data ? 'PR' : '',
      referenceId: data.id ?? '',
      invoiceDate: '',
      organizationId: data.organizationId ?? '',
      paymentTermsId: '',
      paymentMethodId: data.paymentMethodId ?? '',
      currencyCode: data.currencyCode ?? '',
      currencyConversionRate: data.currencyConversionRate ?? 1,
      // transactionDate: new Date().toISOString().split('T')[0], // تاريخ الفاتورة
      apInvoiceLinesList:
        data.transactionsLinesList?.map((line) => ({
          lineNumber: line.lineNumber,
          lineType: 'Item',
          invItemId: line.itemId,
          uom: line.uom,
          description: line.description,
          price: line.price,
          qty: line.qty,
          discountRate: line.discountRate,
          discountAmount: line.discountAmount,
          vatRate: line.vatRate,
          total: line.total,
          vatAmount: line.vatAmount,
          accountId: null,
          costCenterId: null,
          currencyCode: data.currencyCode ?? '',
          currencyConversionRate: data.currencyConversionRate ?? '',
          purHeaderId: data.id,
          purLineId: line.id,
          codeCombination: '',
        })) ??
        [
          // {
          //   lineNumber: '',
          //   lineType: 'Item',
          //   invItemId: null,
          //   uom: null,
          //   description: '',
          //   price: 0,
          //   qty: 0,
          //   discountRate: 0,
          //   discountAmount: 0,
          //   vatRate: 0,
          //   total: 0,
          //   vatAmount: 0,
          //   accountId: null,
          //   costCenterId: null,
          //   currencyCode: data.currencyCode ?? '',
          //   currencyConversionRate: data.currencyConversionRate ?? '',
          //   purHeaderId: null,
          //   purLineId: null,
          //   codeCombination: '',
          // },
        ],
      apPaymentSchedulesList: [],
    },

    enableReinitialize: true,
    // validationSchema: AddQuoteInvoiceSchema(t),
    onSubmit: (values) => {
      // const { transactionsLinesList, ...restValues } = values;

      if (values.apInvoiceLinesList.length === 0) {
        SwalAlert({ text: '' });
        formik.setSubmitting(false);
        return;
      }

      dispatch(
        addApInvoices({
          invoiceNum: values.invoiceDate,
          peopleId: values.peopleId,
          invoiceType: values.invoiceType,
          referenceType: values.referenceType,
          referenceId: values.referenceId || null,
          invoiceDate: values.invoiceDate,
          organizationId: values.organizationId,
          paymentTermsId: values.paymentTermsId,
          paymentMethodId: values.paymentMethodId,
          currencyCode: values.currencyCode,
          currencyConversionRate: values.currencyConversionRate || 1,
          apInvoiceLinesList: values.apInvoiceLinesList.map((line, idx) => ({
            // lineNumber: line.lineNumber ,
            lineNumber: ++idx,
            lineType: line.lineType,
            invItemId: line.invItemId,
            uom: line.uom || null,
            description: line.description,
            price: line.price || 0,
            qty: line.qty || 0,
            discountRate: line.discountRate || 0,
            discountAmount: line.discountAmount || 0,
            vatRate: line.vatRate || 0,
            total: line.total,
            vatAmount: line.vatAmount || 0,
            accountId: line.accountId,
            costCenterId: line.costCenterId,
            currencyCode: line.currencyCode,
            currencyConversionRate: line.currencyConversionRate || 1,
            purHeaderId: line.purHeaderId,
            purLineId: line.purLineId,
          })),
          apPaymentSchedulesList:
            values.apPaymentSchedulesList.map((item: any) => ({
              amount: item.amount,
              dueDate: item.dueDate,
            })) || null,
        })
      )
        .unwrap()
        .then((res: any) => {
          debugger;
          // console.log(res);

          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched, setFieldValue } = formik;
  //#endregion

  const handleChangeOrganizationId = async (option: any) => {
    // فحص اذا كانت هناك بنود في الفاتورة
    if (values.apInvoiceLinesList.length > 0) {
      let isConfirmation = await confirmAction(
        'هل تريد المتابعة؟ سيتم حذف الحسابات ومراكز التكلفة والفروع والشركات المرتبطة بجميع البنود في الفاتورة. لا يمكن التراجع عن هذه العملية.'
      );
      if (!isConfirmation) {
        return;
      }
    }
    setFieldValue('organizationId', option === null ? null : option.value);

    const updatedLines = values.apInvoiceLinesList.map((item) => ({
      ...item,
      accountId: null,
      costCenterId: null,
      companyId: null,
      branchId: null,
      codeCombination: '',
    }));
    setFieldValue('apInvoiceLinesList', updatedLines);
  };

  //#region BcrumbList
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Ap Invoices',
      link: '/Finance/Ap/ApInvoices',
    },
    {
      name: 'Add Ap Invoices',
      link: null,
    },
  ];
  //#endregion

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="Add Ap Invoices" />
        <div className="ms-auto">
          <div className="btn-group" role="group" style={{ direction: 'ltr' }}>
            <button
              type="button"
              className="btn btn-theme dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              {/* <i className="fa bi bi-printer-fill fa-fw ms-3 fs-5"></i> */}
              {t('_____')}
            </button>
            <ul className="dropdown-menu text-center">
              <li>
                <Link
                  className="dropdown-item text-start"
                  to=""
                  onClick={() => setShowPurchaseRequest(true)}>
                  من طلب شراء
                  <i className="fa fa-cloud-download-alt fa-fw me-2 text-theme"></i>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item text-start"
                  to=""
                  // onClick={() => setShowPurchaseQuotation(true)}
                >
                  من أمر شراء
                  <i className="fa fa-cloud-download-alt fa-fw me-2 text-theme"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Card>
        <Loading loading={loading || loading2 || loading3 || loading4}>
          <CardBody>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-lg-2">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('التاريخ')}</label>
                    <Form.Control
                      className="form-control "
                      type="date"
                      name="invoiceDate"
                      onChange={formikhandleChange}
                      value={values.invoiceDate || ''}
                      // isInvalid={!!(touched.invoiceDate && errors.invoiceDate)}
                    />

                    <div className="text-danger">{formik.errors.invoiceDate}</div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('توع الفاتورة - invoiceType')} </label>
                    <Form.Select
                      className="form-select"
                      name="invoiceType"
                      onChange={formikhandleChange}
                      value={values.invoiceType || ''}
                      // isInvalid={!!(touched.invoiceType && errors.invoiceType)}
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      <option value="standard">
                        {i18n.language === 'en' ? 'Standard' : 'قياسي'}
                      </option>
                      <option value="debit_memo">
                        {i18n.language === 'en' ? 'Debit Memo' : 'إشعار مدين'}
                      </option>
                      <option value="Recurring">
                        {i18n.language === 'en' ? 'Recurring' : 'دوري'}
                      </option>
                    </Form.Select>
                    <div className="text-danger">{formik.errors.invoiceType}</div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('المورد')}</label>
                    <TreeSelect
                      showSearch
                      className="w-100"
                      treeLine
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      allowClear
                      treeDefaultExpandAll={false} // لا تقم بتوسيع كل العقد افتراضيًا
                      // loadData={} // يتم تحميل البيانات عند الطلب
                      // onSearch={} // تنفيذ البحث عند كتابة المستخدم
                      value={values.peopleId}
                      onChange={(option) => {
                        formik.setFieldValue('peopleId', option === null ? null : option);
                      }}
                      treeData={treeSuppliersData}
                      treeNodeFilterProp="title"
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group flex-nowrap mb-3">
                    <label className="form-label">{t('رقم فاتورة المورد')}</label>

                    <div className="input-group flex-nowrap">
                      <Form.Control
                        autoComplete="off"
                        className="form-control  text-center"
                        type="text"
                        name="invoiceNum"
                        onChange={formikhandleChange}
                        value={values.invoiceNum || ''}
                        isInvalid={!!(touched.invoiceNum && errors.invoiceNum)}
                      />
                    </div>
                    <div className="text-danger">{formik.errors.invoiceNum}</div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الفرع')}</label>

                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading3}
                      isSearchable={true}
                      isClearable
                      options={BranchsOptions}
                      value={BranchsOptions.find(
                        (f: any) => f.value === formik.values.organizationId
                      )}
                      onChange={(option: any) => handleChangeOrganizationId(option)}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger">{formik.errors.organizationId}</div>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">شروط الدفع</label>
                    <Form.Select
                      className="form-select"
                      name="paymentTermsId"
                      onChange={formik.handleChange}
                      value={formik.values.paymentTermsId || ''}
                      isInvalid={!!(formik.errors.paymentTermsId && formik.touched.paymentTermsId)}>
                      <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PaymentTermsList &&
                        PaymentTermsList.map((item: any, idx: number) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.paymentTermsId}
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('PaymentMethod')}</label>
                    <Form.Select
                      className="form-select"
                      name="paymentMethodId"
                      onChange={formik.handleChange}
                      value={formik.values.paymentMethodId || ''}
                      isInvalid={
                        !!(formik.touched.paymentMethodId && formik.errors.paymentMethodId)
                      }>
                      <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {PaymentMethodList.map((item: any, idx: number) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'ar' ? item.name : item.name2}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('Currency')} </label>
                    <Form.Select
                      className="form-select"
                      name="currencyCode"
                      onChange={formikhandleChange}
                      value={values.currencyCode || ''}
                      // isInvalid={!!(touched.currencyCode && errors.currencyCode)}
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {Currency &&
                        Currency?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.currencyCode}>
                            {item.description}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger">{formik.errors.currencyCode}</div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('معدل العملة')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="text"
                      name="currencyConversionRate"
                      onChange={formikhandleChange}
                      value={values.currencyConversionRate || ''}
                      // isInvalid={!!(touched.currencyConversionRate && errors.currencyConversionRate)}
                    />
                    <div className="text-danger">{formik.errors.currencyConversionRate}</div>
                  </div>
                </div>
              </div>

              <CardFooter className="mt-2 mb-2">
                <ul className="nav nav-tabs nav-tabs-v2 p-0">
                  <li className="nav-item me-3">
                    <Link to="#apInvoiceLines" className="nav-link active" data-bs-toggle="tab">
                      <i className="fas fa-file-invoice me-2 text-theme"></i>
                      Ap Invoice Lines
                    </Link>
                  </li>
                  <li className="nav-item me-3">
                    <Link to="#apPaymentSchedules" className="nav-link" data-bs-toggle="tab">
                      <i className="fas fa-calendar-alt me-2 text-theme"></i>
                      Ap Payment Schedules
                    </Link>
                  </li>
                </ul>
                <div className="tab-content py-4 px-0">
                  <div className="tab-pane fade show active" id="apInvoiceLines">
                    <ApInvoiceLines formik={formik} />
                    {formik.errors.apInvoiceLinesList?.length && (
                      <div className="text-danger"> * هناك حقول مطلوبة </div>
                    )}
                  </div>
                  <div className="tab-pane fade" id="apPaymentSchedules">
                    <ApPaymentSchedules formik={formik} />
                  </div>
                </div>
              </CardFooter>

              <div className="text-center mt-2">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn btn-lg me-1 btn-theme mb-1">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                  )}
                  {t('Add')}
                </button>
                <button
                  type="button"
                  className=" btn btn-lg me-1 btn-default mb-1"
                  onClick={() => navigate('/SalesTransactions/Quotation', { replace: true })}>
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </Form>
          </CardBody>
        </Loading>
      </Card>

      <NeatixModal
        show={showPurchaseRequest}
        setShow={setShowPurchaseRequest}
        bodyClassName="p-0"
        size="lg"
        showHeader={false}>
        <PurchaseRequestList Route="ApInvoice" />
      </NeatixModal>
    </>
  );
};

export default Add;
