import React, { useEffect } from 'react';
import { Card, CardBody } from '../../../../../components/card/card';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DeleteFlexValue,
  GetFlexValue,
  insertFlexValue,
} from '../../../../../store/NTX/FlexValue/FlixValueSlice';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../../components/Shared/Loader/Loader';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import showConfirmation from '../../../../../components/Shared/Alert/Confirmation';

export const AddFlexValue = () => {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetFlexValue(id));
  }, [dispatch, id]);
  const { FlexValueList, loading } = useSelector((state) => state.FlexValue);
  const data = FlexValueList && FlexValueList?.filter((f) => f.parentId === null);

  const formik = useFormik({
    initialValues: {
      name2: '',
      name: '',
      parentId: null,
      imgUrl: '',
    },
    // validationSchema: formJob(t),
    onSubmit: (values, { resetForm }) => {
      dispatch(
        insertFlexValue({
          flexValueSetId: id,
          name2: values.name2,
          name: values.name,
          parentId: null,
          imgUrl: values.imgUrl,
          enabledFlag: true,
        })
      )
        .unwrap()
        .then(() => {
          CustomAlert({ action: 'Add' });
          resetForm();
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: 'Error' });
        });
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.selectValue = 'مطلوب';
      }
      if (!values.name2) {
        errors.textValue = 'مطلوب';
      }
      return errors;
    },
  });

  // ______ handle Delete Flex Value
  const handleDeleteFlexValue = (item) => {
    showConfirmation(t('MsgConfirm'), () => {
      dispatch(DeleteFlexValue(item.id))
        .unwrap()
        .then(() => {
          dispatch(GetFlexValue(id));
          CustomAlert({ action: 'Delete' });
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: 'Error' });
        });
    });
  };

  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('SystemLists'),
      link: '/NTX/ListSystem',
    },
    {
      name:
        i18n.language === 'en'
          ? FlexValueList[0]?.flexValueSetName
          : FlexValueList[0]?.flexValueSetName2,
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName={t('AddSystemLists')}
        subtitle={
          i18n.language === 'en'
            ? FlexValueList[0]?.flexValueSetName
            : FlexValueList[0]?.flexValueSetName2
        }
      />

      <Card>
        <CardBody>
          <Loader loading={loading} />

          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-2"></div>
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t('LocalName')}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    isInvalid={!!formik.errors.name}
                    required
                  />
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t('GlobalName')}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name2"
                    onChange={formik.handleChange}
                    value={formik.values.name2}
                    isInvalid={!!formik.errors.name2}
                    required
                  />
                </div>
              </div>
              <div className="col-xl-2"></div>
              {/* <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Choose photo")}</label>
                  <Form.Control
                    type="file"
                    className="form-control"
                    placeholder=" أختر صورة"
                    name="imgUrl"
                    accept="image/*"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                  />
                </div>
              </div> */}
              {/* <div className="col-xl-3">
                  {selectedFile && (
                    <div className="mb-0">
                      <Card>
                        <CardBody>
                          <img
                            src={URL.createObjectURL(selectedFile)}
                            alt="Attachment Preview"
                            style={{ maxWidth: "100%", maxHeight: "290px" }}
                          />
                        </CardBody>
                      </Card>
                    </div>
                  )}
                </div> */}
            </div>

            <div className="text-center">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-theme mb-1">
                <i className="fas fa-plus fa-fw me-1"></i>
                {formik.isSubmitting ? 'Adding...' : t('Add')}
              </button>
              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                onClick={() => Navigate(-1, { replace: true })}>
                <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
              </button>
            </div>
          </Form>

          <hr className="mb-4" />
          <div className="row text-center">
            <div className="col-lg-10 mx-auto p-0 m-0  rounded-2">
              <div className="table-responsive border border-theme rounded-2 ">
                <table className="table  table-hover text-nowrap mb-0">
                  <thead className="bg-theme bg-opacity-60">
                    <tr>
                      {/* <th scope="col">الرقم</th> */}
                      <th scope="col">{t('Value')}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>

                  <tbody>
                    {data &&
                      data.map((item, idx) => (
                        <tr key={item.id}>
                          {/* <td className="align-middle">{++idx}</td> */}
                          <td className="align-middle">
                            {i18n.language === 'ar' ? item.name : item.name2}
                          </td>
                          <td className="align-middle">
                            <button
                              type="button"
                              className="btn btn-outline-danger m-0"
                              onClick={() => handleDeleteFlexValue(item)}>
                              <i className="fas fa-trash-alt fa-fw fa-lg"></i>
                              {/* {t('Button.Delete')} */}
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
