import { useFormik } from 'formik';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card, CardFooter } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import useGetPersons from '../../../../hooks/HR/use-get-persons';
import useGetOrganizations from '../../../../hooks/HR/use-get-organizations';
import PurchaseLines from '../TransactionsLines/PurchaseLines';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import useGetPeoplesList from '../../../../hooks/NTX/useGetPeoplesList';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import { AddQuoteInvoiceSchema } from '../../../ValidationForm/validationSchema';
import { useNavigate, useParams } from 'react-router-dom';
import DynamicPrint from '../../../../components/DynamicPrint/DynamicPrint';
import Loading from '../../../../components/Shared/Loader/Loading';
import { useEffect } from 'react';

import {
  getPurchaseOrderById,
  cleanUpPurchaseOrderById,
  updatePurchaseOrder,
} from '../../../../store/purchases/PurchaseOrder/purchaseOrderSlice';

const Update = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { Currency, loading: loading6 } = useLookupCurrency();
  const { dataEmployees, loading } = useGetPersons();
  const { BranchsOptions, InventoryOptions, loading: loading3 } = useGetOrganizations();
  const {
    OptionCustomers,
    CustomerList,
    loading: loading2,
  } = useGetPeoplesList({ LookupType: '' });

  // const { data, loading: loading5 } = useGetTransactionsSalesById({ id: id ?? '' });

  const { PurchaseOrderById: data, loading: loading5 } = useAppSelector(
    (s) => s.PurchaseOrder
  );
  console.log(data);

  useEffect(() => {
    if (id) {
      dispatch(getPurchaseOrderById(id));
    }
    return () => {
      dispatch(cleanUpPurchaseOrderById());
    };
  }, [id]);

  //#region Formik
  const formik = useFormik({
    initialValues: {
      id: data ? data.id : '',
      // no: data ? data.no : '',
      code: data ? data.code : '',
      // name: data ? data.name : '',
      // name2: data ? data.name2 : '',
      // transTypeId: data ? data.transTypeId : '',
      organizationId: data ? data.organizationId : '',
      peopleId: data ? data.peopleId : '',
      recipientname: data ? data.recipientname : '',
      billToLocationId: data ? data.billToLocationId : '',
      shipToLocationId: data ? data.shipToLocationId : '',
      referenceId: data ? data.referenceId : '',
      transactionDate: data ? data.transactionDate : '',
      deliveryDate: data ? data.deliveryDate : '',
      expirationDate: data ? data.expirationDate : '',
      dueDate: data ? data.dueDate : '',
      paymentTermsId: data ? data.paymentTermsId : '',
      description: data ? data.description : '',
      grossAmount: data ? data.grossAmount : '',
      discountRate: data ? data.discountRate : '',
      discountAmount: data ? data.discountAmount : '',
      vat: data ? data.vat : '',
      netAmount: data ? data.netAmount : '',
      isAme: true,
      ameStatus: data ? data.ameStatus : 0,
      personId: data ? data.personId : '',
      contractId: data ? data.contractId : 'e',
      paymentTerms: data ? data.paymentTerms : '',
      deliveryTerm: data ? data.deliveryTerm : '',
      inventoryId: data ? data.inventoryId : '',
      waybill: data ? data.waybill : '',
      phone: data ? data.phone : '',
      currencyCode: data ? data.currencyCode : '',
      currencyConversionRate: data ? data.currencyConversionRate : '',
      batchId: data ? data.batchId : '',
      paymentMethodId: data ? data.batchId : '',
      transactionsLinesList:
        data?.transactionsLinesList?.map((item) => ({
          id: item.id,
          // transactionId: item.transactionId,
          itemId: item.itemId,
          lineNumber: item.lineNumber,
          uom: item.uom,
          description: item.description,
          price: item.price,
          qty: item.qty,
          discountRate: item.discountRate,
          discountAmount: item.discountAmount,
          vatRate: item.vatRate,
          total: item.total,
          vatAmount: item.vatAmount,
          // unitCost: item.unitCost,
          // codeCombinationId: item.codeCombinationId,
          // currencyCode: item.currencyCode,
          // currencyConversionRate: item.currencyConversionRate,
          // lotId: item.lotId,
          // headerId: item.headerId,
          // lineId: item.lineId,
          inventoryId: item.inventoryId,
        })) || [],
    },

    enableReinitialize: true,
    validationSchema: AddQuoteInvoiceSchema(t),
    onSubmit: (values) => {
      const { transactionsLinesList, ...restValues } = values;

      const grossSale = values.transactionsLinesList.reduce(
        (s, a) => Number(s) + Number(a.total),
        0
      );

      if (values.transactionsLinesList.length === 0) {
        SwalAlert({ text: 'أدخل أصناف' });
        formik.setSubmitting(false);

        return;
      }

      dispatch(
        updatePurchaseOrder({
         

          id: values.id,
          // no: values.no,
          // code: values.code + values.no,
          // name: values.name,
          // name2: values.name2,
          // transTypeId: values.transTypeId,
          organizationId: values.organizationId,
          peopleId: values.peopleId,
          recipientname: values.recipientname,
          billToLocationId: billToLocationId,
          shipToLocationId: shipToLocationId,
          referenceId: values.referenceId,
          transactionDate: values.transactionDate,
          deliveryDate: values.deliveryDate || null,
          expirationDate: values.expirationDate || null,
          dueDate: values.dueDate || null,
          paymentTermsId: paymentTermsId,

          description: values.description,
          grossAmount: grossSale,
          discountRate: values.discountRate || 0,
          discountAmount: values.discountAmount || 0,
          vat: values.vat || 0,
          netAmount: values.netAmount || 0,
          contractId: values.contractId || null,
          ameStatus: values.ameStatus || 0,

          isAme: values.isAme || false,
          personId: values.personId,
          paymentTerms: values.paymentTerms,
          deliveryTerm: values.deliveryTerm,
          inventoryId: values.inventoryId,
          waybill: values.waybill,
          phone: values.phone,
          currencyCode: values.currencyCode,
          currencyConversionRate: values.currencyConversionRate || 0,
          batchId: values.batchId || null,
          paymentMethodId: values.paymentMethodId || null,
          transactionsLinesList: formik.values.transactionsLinesList.map((item) => ({
            id: item.id,
            transactionId: data.id,
            itemId: item.itemId,
            lineNumber: item.lineNumber,
            uom: item.uom,
            description: item.description,
            price: Number(item.price),
            qty: Number(item.qty),
            discountRate: Number(item.discountRate),
            discountAmount: Number(item.discountAmount),
            vatRate: Number(item.vatRate),
            total: Number(item.total),
            vatAmount: Number(item.vatAmount),
            // unitCost: Number(item.unitCost),
            codeCombinationId: '54F915DF-8A22-486D-B860-08DC2A376B50',
            currencyCode: 'SR',
            currencyConversionRate: 0,
            lotId: null,
            headerId: null,
            lineId: null,
            inventoryId: values.inventoryId,
          })),
        })
      )
        .unwrap()
        .then((res: any) => {
          debugger;
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Edit' });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  const { values, handleChange: formikhandleChange, errors, touched } = formik;
  //#endregion

  const paymentTermsId = CustomerList.find((f) => f.id === values.peopleId)?.termsId;
  const shipToLocationId = CustomerList.find((f) => f.id === values.peopleId)?.shipToLocationId;
  const billToLocationId = CustomerList.find((f) => f.id === values.peopleId)?.billToLocationId;

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'أوامر الشراء',
      link: '/PurchasesTransactions/PurchaseOrder',
    },
    {
      name: 'تفاصيل أمر الشـراء',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName=" تفاصيـل عرض السعر" />

        <div className="ms-auto">
          <div className="col-5 col-md-6 text-end">
            <DynamicPrint
              PrintTemplateTypeId="868E12B7-A5D3-41F9-AA87-45BB552B3AAB" // قوالب عرض السعر
              ReferenceId={id || ''}
            />
          </div>
        </div>
      </div>

      <Card>
        <Loading loading={loading || loading2 || loading3 || loading5 || loading6}>
          <Form onSubmit={formik.handleSubmit}>
            <CardBody>
              <div className="row">
                <div className="col-lg-3">
                  <div className="form-group flex-nowrap mb-3">
                    <label className="form-label">{t('رقم العرض')}</label>

                    <div className="input-group flex-nowrap">
                      <Form.Control
                        readOnly
                        className="form-control fw-bold text-center"
                        type="text"
                        onChange={formikhandleChange}
                        value={values.code || ''}
                        isInvalid={!!(touched.code && errors.code)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('التاريخ')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="date"
                      name="transactionDate"
                      onChange={formikhandleChange}
                      value={values.transactionDate || ''}
                      isInvalid={!!(touched.transactionDate && errors.transactionDate)}
                    />
                    <div className="text-danger">{formik.errors.transactionDate}</div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('تاريخ الإنتهاء')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="date"
                      name="expirationDate"
                      onChange={formikhandleChange}
                      value={values.expirationDate || ''}
                      isInvalid={!!(touched.expirationDate && errors.expirationDate)}
                    />
                    <div className="text-danger">{formik.errors.expirationDate}</div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('Currency')} </label>
                    <Form.Select
                      className="form-select"
                      name="currencyCode"
                      onChange={formikhandleChange}
                      value={values.currencyCode || ''}
                      isInvalid={!!(touched.currencyCode && errors.currencyCode)}>
                      {Currency &&
                        Currency?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.currencyCode}>
                            {item.description}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger">{formik.errors.currencyCode}</div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('العميل')}</label>

                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading2}
                      isSearchable={true}
                      isClearable
                      options={OptionCustomers}
                      value={OptionCustomers.find((f: any) => f.value === formik.values.peopleId)}
                      onChange={(option: any) => {
                        formik.setFieldValue('peopleId', option === null ? null : option.value);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger">{formik.errors.peopleId}</div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('مندوب المبيعات')}</label>
                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading}
                      isSearchable={true}
                      isClearable
                      name="personId"
                      options={dataEmployees}
                      value={dataEmployees.find((f: any) => f.value === formik.values.personId)}
                      onChange={(option: any) => {
                        formik.setFieldValue('personId', option === null ? null : option.value);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger">{formik.errors.personId}</div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('المخزن')}</label>

                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading3}
                      isSearchable={true}
                      isClearable
                      options={InventoryOptions}
                      value={InventoryOptions.find(
                        (f: any) => f.value === formik.values.inventoryId
                      )}
                      onChange={(option: any) => {
                        formik.setFieldValue('inventoryId', option === null ? null : option.value);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger">{formik.errors.inventoryId}</div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الفرع')}</label>

                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading3}
                      isSearchable={true}
                      isClearable
                      options={BranchsOptions}
                      value={BranchsOptions.find(
                        (f: any) => f.value === formik.values.organizationId
                      )}
                      onChange={(option: any) => {
                        formik.setFieldValue(
                          'organizationId',
                          option === null ? null : option.value
                        );
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger">{formik.errors.organizationId}</div>
                  </div>
                </div>

                {/* <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("اسم العميل")}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control text-center"
                    type="text"
                    name="recipientname"
                    onChange={formikhandleChange}
                    value={values.recipientname || ""}
                  />
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("تاريخ التوصيل")}</label>
                  <Form.Control
                    className="form-control  text-center"
                    type="date"
                    name="deliveryDate"
                    onChange={formikhandleChange}
                    value={values.deliveryDate || ""}
                    isInvalid={
                      !!(touched.deliveryDate && errors.deliveryDate)
                    }
                    // isValid={
                    //   formik.touched.deliveryDate &&
                    //   !formik.errors.deliveryDate
                    // }
                  />
                </div>
              </div> */}

                <div className="col-lg-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('مدة التوريد')}</label>
                    <Form.Control
                      className="form-control"
                      as="textarea"
                      rows={2}
                      name="deliveryTerm"
                      onChange={formik.handleChange}
                      value={values.deliveryTerm}
                      isInvalid={!!(touched.deliveryTerm && errors.deliveryTerm)}
                      // isValid={touched.deliveryTerm && !errors.deliveryTerm}
                    />
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الدفع')}</label>
                    <Form.Control
                      className="form-control"
                      as="textarea"
                      rows={2}
                      name="paymentTerms"
                      onChange={formik.handleChange}
                      value={values.paymentTerms}
                      isInvalid={!!(touched.paymentTerms && errors.paymentTerms)}
                      // isValid={touched.paymentTerms && !errors.paymentTerms}
                    />
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الشروط')}</label>
                    <Form.Control
                      className="form-control"
                      as="textarea"
                      rows={2}
                      name="description"
                      onChange={formik.handleChange}
                      value={values.description}
                      isInvalid={!!(touched.description && errors.description)}
                      // isValid={touched.description && !errors.description}
                    />
                  </div>
                </div>

                {/* <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Note')}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control"
                    as="textarea"
                    rows={1}
                    name="description"
                    onChange={formik.handleChange}
                    value={values.description}
                    isInvalid={!!(touched.description && errors.description)}
                    // isValid={touched.description && !errors.description}
                  />
                </div>
              </div> */}
              </div>

              <CardFooter className="px-0 py-1">
                <div className="row">
                  {Object.keys(data).length > 0 && <PurchaseLines formik={formik} />}
                  {formik.errors.transactionsLinesList?.length && (
                    <div className="text-danger"> * هناك حقول مطلوبة </div>
                  )}
                </div>
              </CardFooter>

              <div className="text-center mt-2">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn btn-lg me-1 btn-theme mb-1">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fa fa-save fa-fw me-1"></i>
                  )}

                  {t('Save')}
                </button>
                <button
                  type="button"
                  className=" btn btn-lg me-1 btn-default mb-1"
                  onClick={() => navigate('/PurchasesTransactions/PurchaseOrder', { replace: true })}>
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </CardBody>
          </Form>
        </Loading>
      </Card>
    </div>
  );
};

export default Update;
