import React, { Fragment, useState } from 'react';
import ExtraInformationBuilderUpdate from '../../../ExtraInformationBuilder/Update/ExtraInformationBuilderUpdate';
import useAddUpdateExtraInformation from '../../../ExtraInformationBuilder/useAddUpdateExtraInformation';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
// import Loader from '../../../../../components/Shared/Loader/Loader';
import { useSelector } from 'react-redux';
import { ntxAPI } from '../../../../../API/axiosNeatex';
import showConfirmation from '../../../../../components/Shared/Alert/Confirmation';

const UpdateExtraRecord = (props) => {
  const { extraInformationId, ReferenceId, setRefreshData, setExtraInformationId } = props;

  const { t } = useTranslation();

  const [extraInfoFormData, setExtraInfoFormData] = useState({});
  const { AddUpdateExtraInformation } = useAddUpdateExtraInformation();
  const { loading, error } = useSelector((state) => state.ExtraInformation);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeletting, setIsDeletting] = useState(false);

  const handleAddUpdateExtraInfo = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    var respons = await AddUpdateExtraInformation(extraInfoFormData);
    if (respons === null) {
      setIsSubmitting(false);
      CustomAlert({ action: 'Error' });
      return;
    } else {
      setIsSubmitting(false);
      CustomAlert({
        action: 'Edit',
        msg: 'تم تحديث البيانات بنجاح',
      });
    }
  };

  // Handle Delete Record
  const handleDeleteRecord = async (id) => {
    showConfirmation(' هل أنت متأكد من حذف هذا السجل ؟', async () => {
      try {
        setIsDeletting(true);
        const { data } = await ntxAPI.post(
          `ExtraInformation/DeleteExtraInformation?ExtraInformationId=${id}`
        );
        if (data?.succeeded === true) {
          CustomAlert({ action: 'Delete', msg: 'تم حذف السجل بنجـاح' });
          setExtraInformationId(null);
          setRefreshData(true);
          setIsDeletting(false);
        } else {
          CustomAlert({ action: 'Error' });
          setIsDeletting(false);
          return null;
        }
      } catch (err) {
        CustomAlert({ action: 'Error' });
        setIsDeletting(false);
        return null;
      }
    });
  };

  return (
    <Fragment>
      <Form onSubmit={handleAddUpdateExtraInfo}>
        <ExtraInformationBuilderUpdate
          extraInformationId={extraInformationId}
          setExtraInfoFormData={setExtraInfoFormData}
          referenceId={ReferenceId}
        />
        {loading || error ? null : (
          <div className="text-end mt-2">
            <button
              type="submit"
              className="btn me-1 btn-theme "
              disabled={isSubmitting || isDeletting}>
              {isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-1"></div>
              ) : (
                <i className="fa fa-save fa-fw me-1"></i>
              )}
              {t('Save')}
            </button>

            <button
              onClick={() => handleDeleteRecord(extraInformationId)}
              type="button"
              className="btn  btn-danger"
              disabled={isDeletting || isSubmitting}>
              {isDeletting ? (
                <div className="spinner-border spinner-border-sm"></div>
              ) : (
                <i className="fas fa-trash-alt fa-fw me-1"></i>
              )}
              {t('حذف السـجل')}
            </button>
          </div>
        )}
      </Form>
    </Fragment>
  );
};

export default UpdateExtraRecord;
