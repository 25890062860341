import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ntxAPI } from '../../API/axiosNeatex';
import { APIResponse } from '../Shared/shared';

export const AddAmeDelegate = createAsyncThunk(
  'AmeDelegate/AddAmeDelegate',
  async (item: any, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    // console.log(JSON.stringify(item));
    debugger;
    try {
      const { data } = await ntxAPI.post('AmeDelegate/Add', item);
      return data;
    } catch (error) {
      console.error(error as any);
      return rejectWithValue(error);
    }
  }
);

export const AddAmeDelegateGeneral = createAsyncThunk(
  'AmeDelegate/AddAmeDelegateGeneral',
  async (item: any, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    // console.log(JSON.stringify(item));
    // debugger;
    try {
      const { data } = await ntxAPI.post('AmeDelegate/GeneralAdd', item);
      return data;
    } catch (error) {
      console.error(error as any);
      return rejectWithValue(error);
    }
  }
);

// export const DeleteAmeDelegate = createAsyncThunk(
//   'AmeDelegate/DeleteAmeDelegate',
//   async (item: any, thunkAPI) => {
//     const { rejectWithValue } = thunkAPI;

//     // console.log(JSON.stringify(item));
//     debugger;
//     try {
//       const { data } = await ntxAPI.post('AmeDelegate/Delete', item);
//       return data;
//     } catch (error) {
//       console.error(error as any);
//       return rejectWithValue(error);
//     }
//   }
// );

export const DeleteMyDelegation = createAsyncThunk(
  'AmeDelegate/DeleteAmeDelegate',
  async (id: string, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // AmeDelegate/DeleteMyDelegation?id=9d84401d-dd3d-44d4-3ee4-08dcbfb77b3e
      const { data } = await ntxAPI.post(`AmeDelegate/DeleteMyDelegation?id=${id}`);
      return data;
    } catch (error) {
      console.error(error as any);
      return rejectWithValue(error);
    }
  }
);

export const DeleteDelegation = createAsyncThunk(
  'AmeDelegate/DeleteAmeDelegate',
  async (id: string, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.post(`AmeDelegate/Delete?id=${id}`);
      return data;
    } catch (error) {
      console.error(error as any);
      return rejectWithValue(error);
    }
  }
);

export const getMyDelegation = createAsyncThunk(
  'AmeDelegate/getMyDelegation',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.get<APIResponse<any>>('AmeDelegate/GetMyDelegation');

      if (data.succeeded === true) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const getDelegationActiveList = createAsyncThunk(
  'AmeDelegate/getDelegationActiveList',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.get('AmeDelegate/GetListActive');
      if (data.succeeded === true) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// const initialState = { AmeDelegateList: [], MyDelegationList: [], loading: false, error: null };

const initialState: {
  MyDelegationList: any[];
  DelegationActiveList: any[];
  loading: boolean;
  error: any | null;
} = {
  MyDelegationList: [],
  DelegationActiveList: [],
  loading: false,
  error: null,
};

const ameDelegateSlice = createSlice({
  name: 'AmeDelegate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMyDelegation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMyDelegation.fulfilled, (state, action) => {
        state.loading = false;
        state.MyDelegationList = action.payload;
      })
      .addCase(getMyDelegation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getDelegationActiveList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDelegationActiveList.fulfilled, (state, action) => {
        state.loading = false;
        state.DelegationActiveList = action.payload;
      })
      .addCase(getDelegationActiveList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // .addCase(AddAmeDelegate.pending, (state) => {
    //   state.loading = true;
    //   state.error = null;
    // })
    // .addCase(AddAmeDelegate.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.AmeDelegateList.push(action.payload);
    // })
    // .addCase(AddAmeDelegate.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // })

    // .addCase(DeleteAmeCondition.pending, (state) => {
    //   state.loading = true;
    //   state.error = null;
    // })
    // .addCase(DeleteAmeCondition.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.AmeConditionList = state.AmeConditionList?.filter((el) => el.id !== action.payload);
    // })
    // .addCase(DeleteAmeCondition.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // });
  },
});

export default ameDelegateSlice.reducer;
