import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody } from '../../../../components/card/card.jsx';
import {
  AddElementLink,
  DeleteElementLink,
  GetElementLinkDeatail,
} from '../../../../store/HR/Payroll/PayrollSlice.js';
import Loader from '../../../../components/Shared/Loader/Loader.js';
import { Form } from 'react-bootstrap';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import showConfirmation from '../../../../components/Shared/Alert/Confirmation';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import Pagination from '../../../../components/Shared/Pagination/Pagination.jsx';

const ElementLink = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(7);
  const { id } = useParams();
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetElementLinkDeatail(id));
  }, [dispatch, id]);
  const { ElementLinkDeatail, loading } = useSelector((state) => state.Payrolls);


  const filteredSearch = ElementLinkDeatail?.elementLink?.slice()
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData =  filteredSearch?.slice(indexOfFirstData, indexOfLastData);




  const dataElementLinkList =
    ElementLinkDeatail &&
    ElementLinkDeatail?.elemntType?.map((item) => ({
      value: item.id,
      label: i18n.language === 'ar' ? item.name2 : item.name,
    }));
  //______________________________________

  const handleDeleteElement = (item) => {
    showConfirmation(t('MsgConfirm'), () => {
      dispatch(DeleteElementLink(item.id))
        .unwrap()
        .then(() => {
          CustomAlert({ action: 'Delete' });
          dispatch(GetElementLinkDeatail(id));
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: 'Info' });
        });
    });
  };

  // add Element Link
  const formik = useFormik({
    initialValues: {
      selectValue: '',
    },
    onSubmit: (values) => {
      dispatch(
        AddElementLink({
          payrollId: id,
          elementTypeId: formik.values.selectValue,
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          CustomAlert({ action: 'Add' });
          dispatch(GetElementLinkDeatail(id));
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Info' });
          console.log(error);
        });
    },
    validate: (values) => {
      const errors = {};
      if (!values.selectValue) {
        errors.selectValue = 'Please select an option';
      }
      return errors;
    },
  });

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('Payrolls'),
      link: '/Pay/payrolls',
    },
    {
      name: t('LinkPayrollElements'),
      link: null,
    },
  ];
  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb
          BreadcrumbList={BcrumbList}
          PageName={t('LinkPayrollElements')}
          subtitle={
            i18n.language === 'ar'
              ? ElementLinkDeatail?.payroll?.name2
              : ElementLinkDeatail?.payroll?.name
          }
        />
      </div>

      <Card>
        <CardBody>
          <Loader loading={loading} />

          <Form onSubmit={formik.handleSubmit}>
            <div className="row align-items-end">
              <div className="col-xl-8 ">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Elemente')}</label>
                  <Select
                    isLoading={loading}
                    className="text-center"
                    classNamePrefix="react-select"
                    isRtl
                    isSearchable={true}
                    options={dataElementLinkList}
                    onChange={(option) => formik.setFieldValue('selectValue', option.value)}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                  {formik.errors.selectValue && formik.touched.selectValue && (
                    <div>{formik.errors.selectValue}</div>
                  )}
                </div>
              </div>

              <div className="col-xl-2  ">
                <div className=" form-group mb-3 d-flex justify-content-end ">
                  <button
                    disabled={formik.isSubmitting}
                    type="submit"
                    className="btn me-1 btn-theme w-100">
                    <i className="fas fa-plus fa-fw me-1"></i>
                    {formik.isSubmitting ? 'Adding...' : t('Add')}
                  </button>
                </div>
              </div>

              <div className="col-xl-2  ">
                <div className=" form-group mb-3 d-flex justify-content-end ">
                  <button
                    type="button"
                    className=" btn me-1 btn-default w-100"
                    onClick={() => Navigate(-1, { replace: true })}>
                    <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                  </button>
                </div>
              </div>
            </div>
          </Form>

          <div className="row mt-2">
            <div className="col-lg-12">
              <div className="table-responsive rounded-2 text-center   mb-2 ">
                <table className="table table-hover text-nowrap m-0">
                  <thead className="table-dark  ">
                    <tr>
                      <th scope="col"> {t('Name')}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {ElementLinkDeatail?.elementLink?.map((item, idx) => ( */}
                    {currentData?.map((item, idx) => (
                      <tr key={item.id}>
                        <td className="align-middle">
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </td>
                        <td className="align-middle text-end">
                          <button
                            type="button"
                            className="btn btn-outline-danger m-0"
                            onClick={() => handleDeleteElement(item)}>
                            <i className="fas fa-trash-alt fa-fw fa-lg mx-2"></i>
                            {t('Button.Delete')}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />

            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
export default ElementLink;
