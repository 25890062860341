import Swal from 'sweetalert2';
import i18n from 'i18next';
import { store } from '../../../../store';

export default function RefreashTokenToastSwalAlert() {
  // const UserProfile = JSON.parse(localStorage?.UserProfile)

  const UserProfile = store.getState()?.userLogin?.AuthUser?.profile;

  var UserName = i18n.language === 'en' ? UserProfile?.name : UserProfile?.name2;

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000000,
    titleText: 'يرجى الإنتظار ...',
    timerProgressBar: true,
    allowEnterKey: false,

    html: `
    <div class="text-center">
    <div class="d-flex">
    <div class="spinner-grow text-light text-center"></div>
    جاري تحديث بيانات الدخول للمستخدم 
    </div>
    
    <span class="text-theme">
    ${UserName}
    </span>
    </div>
   `,

    background: 'var(--bs-body-bg)',
    color: 'var(--bs-heading-color)',
    customClass: {
      popup: 'border border-theme border-3 rounded-4',
    },
    // iconHtml: '<i class="bi bi-pencil-fill"></i>', // Custom icon
  });

  Toast.fire({});
}
