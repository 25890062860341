import { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../components/Shared/Loader/Loader';
import { GetOrganizationStructure } from '../../../../store/HR/Organizations/OrganizationStructureSice';
import ListForm from './ListForm';
import { AppSettings } from '../../../../config/app-settings';
import ItemTree from './ItemTree';
import { Link } from 'react-router-dom';

const OrganizationStructure = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [orgData, setOrgData] = useState(null);

  const { loading } = useSelector((state) => state.OrganizationStructure);

  const data = useSelector((state) => state.OrganizationStructure.OrgStructureList);

  useEffect(() => {
    dispatch(GetOrganizationStructure());
  }, [dispatch]);

  var BG =
    !loading && data?.filter((item) => item.organizationIdParent === item.organizationIdChild);

  useEffect(() => {
    setOrgData(BG[0]);
  }, [loading]);

  //#region context tree
  const context = useContext(AppSettings);
  useEffect(() => {
    const handleFileArrowClick = (event) => {
      event.preventDefault();
      const node = event.target.closest('.file-node.has-sub');
      if (node) {
        node.classList.toggle('expand');
      }
    };

    const handleFileInfoClick = (event) => {
      event.preventDefault();
      const node = event.target.closest('.file-node');
      if (node) {
        const fileInfoNodes = document.querySelectorAll('.file-node');
        fileInfoNodes.forEach((otherNode) => {
          if (otherNode !== node) {
            otherNode.classList.remove('selected');
          }
        });
        node.classList.add('expand');
        node.classList.add('selected');
      }
    };

    const fileHasSubNodes = document.querySelectorAll('.file-node.has-sub');
    fileHasSubNodes.forEach((node) => {
      const fileArrow = node.querySelector('.file-link > .file-arrow');
      fileArrow?.addEventListener('click', handleFileArrowClick);
    });

    const fileInfoNodes = document.querySelectorAll('.file-node');
    fileInfoNodes.forEach((node) => {
      const fileInfo = node.querySelector('.file-link > .file-info');
      fileInfo?.addEventListener('click', handleFileInfoClick);
    });

    context.setAppContentFullHeight(true);

    return function cleanUp() {
      context.setAppContentFullHeight(false);
      fileHasSubNodes.forEach((node) => {
        const fileArrow = node.querySelector('.file-link > .file-arrow');
        fileArrow?.removeEventListener('click', handleFileArrowClick);
      });
      fileInfoNodes.forEach((node) => {
        const fileInfo = node.querySelector('.file-link > .file-info');
        fileInfo?.removeEventListener('click', handleFileInfoClick);
      });
    };
  }, [data, context]);

  //#endregion

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t('Home')}</a>
            </li>
            <li className="breadcrumb-item active">{t('OrganizationalStructure')}</li>
          </ul>
          <h1 className="page-header mb-0">{t('OrganizationalStructure')}</h1>
        </div>
      </div>

      <hr />
      <Loader loading={loading} />
      <div className="row">

        
        <div className="col-lg-4">
          <div className="file-manager-sidebar-content fs-1">
            <div className="file-tree mb-3 ">
              <div className="file-node has-sub expand">
                <Link to="" className="file-link" onClick={() => setOrgData(BG[0])}>
                  <span className="file-arrow"></span>
                  <span className="file-info">
                    <span className="file-icon">
                      <i className="bi bi-building-fill fa-lg text-theme"></i>
                    </span>
                    <span className="file-text text-theme fs-4 fw-bold ">
                      {i18n.language === 'ar' ? BG[0]?.childName2 : BG[0]?.childName}
                    </span>
                  </span>
                </Link>

                <ItemTree
                  All={data}
                  parentId={BG[0]?.organizationIdChild}
                  List={data?.filter((item) => {
                    return (
                      item.organizationIdParent === BG[0]?.organizationIdChild &&
                      item.organizationIdChild !== item.organizationIdParent
                    );
                  })}
                  setOrgData={setOrgData}
                />
              </div>
            </div>
          </div>
        </div>





        <div className="col-xl-8">
          {data?.length > 0 && <ListForm data={data} orgData={orgData} />}
        </div>
      </div>
    </>
  );
};

export default OrganizationStructure;
