import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  DeleteAprovalMember,
  GetApprovalMemberByGroupId,
} from '../../../store/Ame/AprovalMemberSlice';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';
import { usePermissions } from '../../../Helper/Permisstions/usePermissions';
import showConfirmation from '../../../components/Shared/Alert/Confirmation';

const ListApprovalMember = ({ data }) => {
  const { HasPermission } = usePermissions();

  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  // Handele Delete Member
  const handleDeleteMember = (item) => {
    showConfirmation(t('MsgConfirm'), () => {
      dispatch(DeleteAprovalMember(item.id))
        .unwrap()
        .then(() => {
          dispatch(GetApprovalMemberByGroupId(id));
          CustomAlert({ action: 'Delete' });
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: 'Error' });
        });
    });
  };

  return (
    <>
      {data.length > 0 ? (
        <div className="table-responsive rounded-2 text-center border border-theme">
          <table className="table table-hover  text-nowrap mb-0 ">
            <thead className="bg-theme bg-opacity-60">
              <tr>
                <th scope="col">{t('Member')}</th>
                <th scope="col">{t('Order')}</th>
                <th scope="col">
                  {' '}
                  {i18n.language === 'ar' ? 'يستلم المعلومات' : 'Information Only'}
                </th>
                <th scope="col"></th>
              </tr>
            </thead>

            <tbody>
              {data
                ?.slice()
                ?.sort((a, b) => a.orderNumber - b.orderNumber)
                ?.map((item, idx) => (
                  <tr key={item.id}>
                    <td className="align-middle">
                      {i18n.language === 'ar' ? item.name2 : item.name}
                    </td>
                    <td className="align-middle">{item.orderNumber}</td>
                    <td className="align-middle">
                      {item.isInformation ? (
                        <span className="badge bg-warning py-2">
                          {i18n.language === 'ar' ? 'يستلم المعلومات فقط' : 'Information Only'}
                        </span>
                      ) : (
                        ''
                        // <span className="badge bg-success"> </span>
                      )}
                    </td>
                    <td className="align-middle">
                      {HasPermission('AmeDeleteMemberFromApprovalsGroup') && (
                        <>
                          <button
                            type="button"
                            className="btn btn-outline-danger m-0"
                            onClick={() => handleDeleteMember(item)}>
                            <i className="fas fa-trash-alt fa-fw fa-lg"></i>
                            {/* {t('Button.Delete')} */}
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center bg-inverse bg-opacity-10 rounded-4 p-3 fw-bold">
          <div>لا يوجد أعضاء لمجموعة الموافقات هذه</div>
        </div>
      )}
    </>
  );
};

export default memo(ListApprovalMember);
