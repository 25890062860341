import Swal from 'sweetalert2';

export default function RefreashTokenFailureSwalAlert() {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 9000,
    timerProgressBar: true,
    allowEnterKey: false,

    html: `
     <div class="text-center">
      <div>نعتذر، حدثت مشكلة أثناء تحديث بياناتك.</div>
      <div>للحفاظ على أمان حسابك، سيتم تسجيل خروجك. نرجو منك إعادة تسجيل الدخول لاستئناف استخدام النظام.</div>
     </div>
   `,

    background: 'var(--bs-body-bg)',
    color: 'var(--bs-heading-color)',
    customClass: {
      popup: 'border border-theme border-3 rounded-4',
    },

    didOpen: (toast) => {
      toast.querySelector('.swal2-timer-progress-bar').style.backgroundColor = 'var(--bs-theme)'; // استبدل 'your-color' باللون المطلوب
      // toast.querySelector('.swal2-timer-progress-bar').style.backgroundColor = 'red'; // استبدل 'your-color' باللون المطلوب
    },
  });

  Toast.fire({});
}

// <div class="text-center">
//   <div>عذرًا، حدثت مشكلة أثناء تحديث البيانات.</div>
//   <div>سنقوم بإعادة تسجيل خروجك كإجراء أمني. يرجى إعادة تسجيل الدخول لمتابعة استخدام الحساب. نشكرك على تفهمك.</div>
// </div>

// <div class="text-center">
//   <div>نعتذر، حدث خطأ غير متوقع أثناء تحديث بياناتك.</div>
//   <div>لضمان أمان حسابك، سيتم تسجيل خروجك. نرجو منك تسجيل الدخول مجددًا.</div>
// </div>

// <div class="text-center">
//   <div>نعتذر، حدثت مشكلة أثناء تحديث بياناتك.</div>
//   <div>للحفاظ على أمان حسابك، سيتم تسجيل خروجك. نرجو منك إعادة تسجيل الدخول لاستئناف استخدام النظام.</div>
// </div>
