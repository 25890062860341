import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getPurchaseOrderList } from '../../store/purchases/PurchaseOrder/purchaseOrderSlice';


const useGetPurchaseOrderList = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (PurchaseOrderList?.length === 0) {
      dispatch(getPurchaseOrderList());
    }
  }, []);
  const { PurchaseOrderList, loading, error } = useAppSelector(
    (state) => state.PurchaseOrder
  );

  return { PurchaseOrderList, loading, error };
};

export default useGetPurchaseOrderList;
