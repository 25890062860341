import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import Select from "react-select";
import { Link } from "react-router-dom";
import Pagination from "../../../../components/Shared/Pagination/Pagination.jsx";
import useGetLocations from "../../../../hooks/HRSetting/use-get-locations.js";

const AnalysisTable = ({ ResultList , fromDate , toDate }) => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const [Locationid, setLocationid] = useState(null);
const { Locations , loading } = useGetLocations();


  useEffect(() => {
    setCurrentPage(1);
  }, [ResultList]);

  const filteredSearch = ResultList?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.employeeNumber?.toLowerCase().includes(searchLowerCase) ||
      item.employeeName?.toLowerCase().includes(searchLowerCase) ||
      item.employeeName2?.toLowerCase().toString().includes(searchLowerCase)
    );
  })?.filter((f) =>
  Locationid === null
    ? true
    : f?.locationId === Locationid || f.locationName2 === Locationid
)

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);
  //________________________________

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const csvContent = filteredSearch.map((entry) => ({
      "Employee Number": entry.employeeNumber,
      "EmployeeName": entry.employeeName,
      // "EmployeeName 2": entry.employeeName2,
      "Location": entry.locationName,
      "Date": entry.transitionDate,
      "Fingerprint Time (1)": entry.fingerprintTime1,
      "Fingerprint Time Actuall (1)": entry.fingerprintTimeAct1,
      "Fingerprint Time (2)": entry.fingerprintTime2,
      "Fingerprint Time Actuall (2)": entry.fingerprintTimeAct2,
      "Duration": entry.duration1,
      "Duration Actuall": entry.durationAct1,
      "Delay": entry.dely1,
    }));
    const worksheet = XLSX.utils.json_to_sheet(csvContent);
    XLSX.utils.book_append_sheet(workbook, worksheet, `${fromDate} => ${toDate}`);
    XLSX.writeFile(workbook, "Azzad_Details_analysis_data.xlsx");
  };

  return (
    <div>
      <div className="tab-content p-1">
        <div className="row">
          <div className="col-lg-3 d-flex justify-content-start">
            <div className="input-group mb-4">
              <div className="flex-fill position-relative">
                <div className="input-group">
                  <div
                    className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                    style={{ zIndex: 1020, right: "10px" }}
                  >
                    <i className="fa fa-search opacity-5"></i>
                  </div>
                  <input
                    type="text"
                    className="form-control ps-35px"
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={t("Search") + " ..."}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group mb-3">
              <Select
                classNamePrefix="react-select"
                isLoading={loading}
                isSearchable={true}
                isClearable
                options={Locations?.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                onChange={(option) => {
                  setLocationid(option === null ? null : option.value);
                  setCurrentPage(1);
                }}
                placeholder={t("location")}
              />
            </div>
          </div>
          <div className="col-lg-3 ">
            <button
              onClick={exportToExcel}
              className="btn btn-outline-theme text-nowrap rounded-2"
              tabIndex={0}
              aria-controls="datatable"
              type="button"
            >
              <span>
                <i className="fa fa-file-excel fa-fw me-1" /> {t('ExportData')}
              </span>
            </button>
          </div>

          <div className="col-lg-2 text-end">
            <Link
              to=""
              className="btn me-1 btn-outline-secondary "
              data-bs-dismiss="modal"
            >
              {t("Cancel")}
            </Link>
          </div>
        </div>

        <div className=" bg-inverse bg-opacity-5 rounded-4  ">
          <div className="table-responsive rounded-4">
            <table className="table text-center">
              <thead className="table-dark">
                <tr>
                  <th scope="col"> {t("Name")}</th>
                  <th scope="col">{t("EmployeeNumber")}</th>
                  <th scope="col">{t("location")}</th>
                  <th scope="col"> {t("Date")}</th>
                  <th scope="col">{t("OneFingerprint")}</th>
                  <th scope="col">{t("TowFingerprint")}</th>
                  <th scope="col">{t("Duration")}</th>
                  <th scope="col">{t("LATE")}</th>
                  <th scope="col">{t("ABSENT")}</th>
                </tr>
              </thead>
              <tbody>
                {currentData.map((item, index) => (
                  <tr
                    className={item.missPunch ? "bg-inverse bg-opacity-15" : ""}
                    key={++index}
                  >
                    <td className="align-middle">
                      {i18n.language === "en"
                        ? item.employeeName
                        : item.employeeName2}
                    </td>

                    <td className="align-middle">{item.employeeNumber}</td>
                    <td className="align-middle">{i18n.language === 'en'? item.locationName : item.locationName2}</td>

                    <td className="align-middle">{item.transitionDate}</td>

                    <td className="align-middle">{item.fingerprintTimeAct1}</td>

                    <td className="align-middle">{item.fingerprintTimeAct2}</td>

                    <td className="align-middle">{item.durationAct1}</td>

                    <td className="align-middle">{item.dely1}</td>
                    <td className="align-middle">
                      {item.missPunch ? (
                        <span className="badge d-block rounded-0 pt-5px w-70px bg-danger text-theme-900"></span>
                      ) : (
                        "."
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            filteredData={filteredSearch}
            dataPerPage={dataPerPage}
            handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default AnalysisTable;
