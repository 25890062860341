import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../../API/axiosNeatex";
import { RulesModels } from "./RulesModels";

 

export const AddAuthRule = createAsyncThunk(
  "Rules/AddAuthRule",
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post("Security/AddAuthRole", item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdateAuthRule = createAsyncThunk(
  "Rules/UpdateAuthRule",
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post("Security/UpdatAuthRole", item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetRoleList = createAsyncThunk(
  "Rules/GetRoleList",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.get(`Security/GetRoleList`);
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

//______

export const AddUserRole = createAsyncThunk(
  "Rules/AddUserRole",
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post("Security/AddUserRole", item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetRoleByUserId = createAsyncThunk(
  "Rules/GetRoleByUserId",
  async (id: string, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.get(
        `Security/GetRoleByUserId?UserId=${id}`
      );
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const DeleteUserRole = createAsyncThunk(
  "Rules/DeleteUserRole",
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post("Security/DeleteUserRole", item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);





const initialState: {
  RulesList: RulesModels[];
  RoleByUserIdList: RulesModels[];
  // RulesById: RulesModels
  loading: boolean;
  error: any | null;
} = {
  RulesList: [] as RulesModels[],
  RoleByUserIdList: [],
  loading: false,
  error: null,
};

const RulesSlice = createSlice({
  name: "Rules",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(AddAuthRule.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(AddAuthRule.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        if (action.payload.succeeded === true) {
          state.RulesList.push(action.payload.data);
        } else {
          // state.RulesList = action.payload.data;
        }
      })
      .addCase(AddAuthRule.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      //______________________

      .addCase(GetRoleList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetRoleList.fulfilled,
        (state, action: PayloadAction<RulesModels[]>) => {
          state.loading = false;
          state.RulesList = action.payload;
        }
      )
      .addCase(GetRoleList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      //_________________________

      .addCase(AddUserRole.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(AddUserRole.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        if (action.payload.succeeded === true) {
          // state.RoleByUserIdList.push(action.payload.data);
        } else {
          // state.RulesList = action.payload.data;
        }
      })
      .addCase(AddUserRole.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      //______________________

      .addCase(GetRoleByUserId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetRoleByUserId.fulfilled,
        (state, action: PayloadAction<[]>) => {
          state.loading = false;
          state.RoleByUserIdList = action.payload;
        }
      )
      .addCase(
        GetRoleByUserId.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default RulesSlice.reducer;
