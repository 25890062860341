import React, {   useState } from "react";
import { Link } from "react-router-dom";
import ModalComponent from "../../../../components/Modal/ModalComponent";
import { Card, CardBody } from "../../../../components/card/card";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import { useTranslation } from "react-i18next";
import AddMasterItems from "./AddMasterItems";
import useGetItemsList from "../../../../hooks/INV/MasterItems/useGetItemsList";
import Pagination from "../../../../components/Shared/Pagination/Pagination";
import i18n from "../../../../i18n";
import useGetCategoriesList from "../../../../hooks/INV/useGetCategoriesList";
 
const Index = () => {
  const [Show, setShow] = useState(false);
  const { t } = useTranslation();
  //  const [categoryId, setCategoryId] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const {CategoriesList , loading} = useGetCategoriesList()

  const { MasterItemsList } = useGetItemsList();

   
  const filteredSearch = MasterItemsList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //________________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "الأصناف",
      link: null,
    },
  ];


 

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t("الأصناف")} />

        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-outline-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAddMasterItems"
            onClick={() => setShow(true)}
          >
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t("Add ")}
          </Link>
        </div>
      </div>

      <Card>
        <CardBody>
        <div className="row mb-3">
              <div className="col-7 col-md-6 d-flex justify-content-start">
                <div className="input-group mb-2">
                  <div className="flex-fill position-relative">
                    <div className="input-group ">
                      <select
                        className="btn btn-outline-default dropdown-toggle p-0"
                        // value={selectedValue}
                        // onChange={(e) => setSelectedValue(e.target.value)}
                      >
                        <option className="dropdown-item" value={""}>
                          {t("All")}
                        </option>
                        {CategoriesList &&
                          CategoriesList.map((item) => (
                            <option
                              value={item.id}
                              key={item.id}
                              className="dropdown-item"
                            >
                              {i18n.language === "ar" ? item.name2 : item.name}
                            </option>
                          ))}
                      </select>

                      <div className="flex-fill position-relative">
                        <div className="input-group">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: "10px" }}
                          >
                            <i className="fa fa-search opacity-5"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            placeholder={t("Search")}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    
            </div>

          <div className="table-responsive">
            <table className="table table-hover text-nowrap">
              <thead className="table-dark">
                <tr>
                  <th className="pt-1 pb-3 align-middle">اسم الصنف</th>
                  <th className="pt-1 pb-3 align-middle">نوع الصنف</th>
                  <th className="pt-1 pb-3 align-middle">الفئة</th>
                  <th className="pt-1 pb-3 align-middle">كود الصنف (SKU)</th>
                </tr>
              </thead>
              <tbody>

                {
                  currentData && currentData.map(item => (

        
                <tr key={item.id}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="w-50px h-50px bg-white bg-opacity-25 d-flex align-items-center justify-content-center">
                        <img
                          alt=""
                          className="mw-100 mh-100"
                          src="/assets/img/Image/NoImg2.ico"
                        />
                      </div>
                      <div className="ms-3">
                        <Link
                          to={`/INV/InventorySettings/MasterItems/${item.id}/ItemsDetails`}
                          className="text-inverse text-opacity-75 text-decoration-none"
                        >
                          {i18n.language === 'en' ? item.name : item.name2}
                        </Link>
                      </div>
                    </div>
                  </td>
                  <td className="align-middle">Cotton</td>
                  <td className="align-middle">{i18n.language === 'en' ? item.categoryName : item.categoryName2}</td>
                  <td className="align-middle">{item.itemCode}</td>
                </tr>
           ))
                }
              </tbody>
            </table>
            </div>
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
        </CardBody>
      </Card>

      <ModalComponent title={t("اضافة صنف")} id="modalAddMasterItems">
        {Show && <AddMasterItems />}
      </ModalComponent>
    </div>
  );
};

export default Index;
