
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WorkShop, WorkShopById } from "./workshopModels";
import { ntxAPI } from "../../../API/axiosNeatex";



export const GetWorkShopList = createAsyncThunk(
    "Workshop/GetWorkShopList",
    async (_, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get('Fleet/GetWorkshopList');
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );


  export const _AddWorkShop = createAsyncThunk('Workshop/_AddWorkShop',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Fleet/AddWorkshop" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });


  export const GetWorkShopById = createAsyncThunk(
    "Workshop/GetWorkShopById",
    async ( id:string , thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
  
      try {
        const {data} = await ntxAPI.get(`Fleet/GetWorkshopById?Id=${id}`);
        return data.data;
      } catch (error : any) {
        console.error(error);
        return rejectWithValue(error.message);
      }
    }
  )

  

  export const _UpdateWorkShop = createAsyncThunk('Workshop/_UpdateWorkShop',
  async (item: any , {rejectWithValue} ) => {

   try {
     const {data} = await ntxAPI.post( "Fleet/UpdatWorkshop" , item)
     return data; 
   } catch (error : any) {
       return rejectWithValue(error.message);
   }
 });



const initialState: {
    WorkshopList : WorkShop[]
    WorkshopById? : WorkShopById
    loading: boolean;
    error: any | null;
  } = {
    WorkshopList: [],
    loading: false,
    error: null,

  };


  

const workshopSlice = createSlice({
    name: "Workshop",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder

      .addCase(_AddWorkShop.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(_AddWorkShop.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.WorkshopList.push(action.payload.data); 
      })
      .addCase(_AddWorkShop.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


    .addCase(GetWorkShopList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetWorkShopList.fulfilled, (state, action: PayloadAction<any> ) => {
        state.loading = false;
        state.WorkshopList = action.payload;
      })
      .addCase(GetWorkShopList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })



    .addCase(GetWorkShopById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetWorkShopById.fulfilled, (state, action: PayloadAction<WorkShopById> ) => {
        state.loading = false;
        state.WorkshopById = action.payload;
      })
      .addCase(GetWorkShopById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      
    },
  });
  
  export default workshopSlice.reducer;