import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getPurchaseBillList } from '../../store/purchases/PurchaseBill/purchaseBillSlice';


const useGetPurchaseBillList = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (PurchaseBillList?.length === 0) {
      dispatch(getPurchaseBillList());
    }
  }, []);
  const { PurchaseBillList, loading, error } = useAppSelector(
    (state) => state.PurchaseBill
  );

  return { PurchaseBillList, loading, error };
};

export default useGetPurchaseBillList;
