import React, { useEffect, useState } from 'react';
import { Card, CardBody } from '../../../../../components/card/card.jsx';
import { useTranslation } from 'react-i18next';
import DataEmployees from './DataEmployees.js';
import ModalComponent from '../../../../../components/Modal/ModalComponent.jsx';
import Pagination from '../../../../../components/Shared/Pagination/Pagination.jsx';
import AddPerson from '../AddPerson/AddPerson.js';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getEmployees } from '../../../../../store/HR/Person/employeeSlice.js';
import { usePermissions } from '../../../../../Helper/Permisstions/usePermissions';
import Loading from '../../../../../components/Shared/Loader/Loading';
import useGetOrganizations from '../../../../../hooks/HR/use-get-organizations.js';
import Select from 'react-select';

function Index() {
  const { HasPermission } = usePermissions();
  const [Show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [EmpPerPage, setEmpPrPage] = useState(6);

  const [filterOrganization, setFilterOrganization] = useState('all');
  const [empStatus, setEmpStatus] = useState(null);

  // _________________
  const { Employees, loading, error } = useSelector((state) => state.Employees);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      if (Employees?.length === 0) {
        await dispatch(getEmployees());
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  // __________________

  const filteredEmp = Employees?.slice()
    ?.filter((emp) => {
      const searchLowerCase = search.toLowerCase();
      return (
        emp.name2?.toLowerCase().includes(searchLowerCase) ||
        emp.name?.toLowerCase().toString().includes(searchLowerCase) ||
        emp.nationalIdentifier?.toString().includes(searchLowerCase) ||
        emp.employeeNumber?.toString().includes(searchLowerCase)
      );
    })
    ?.filter((f) =>
      filterOrganization === 'all' ? true : f.organizatioN_ID === filterOrganization
    )
    ?.filter((f) =>
      empStatus === null
        ? true
        : empStatus === 'active'
        ? f?.endOfServiceDate === null
        : f?.endOfServiceDate !== null
    );

  // Get current page
  const IndexOfLastEmp = currentPage * EmpPerPage;
  const IndexOfFirstPost = IndexOfLastEmp - EmpPerPage;
  const currentEmp = filteredEmp?.slice(IndexOfFirstPost, IndexOfLastEmp);

  var BreadcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Employees',
      link: null,
    },
  ];

  //__________________
  const { OrganizationOptions, loading: loading3 } = useGetOrganizations();

  return (
    <>
      <div className="row">
        <div className="d-flex align-items-center">
          <Breadcrumb BreadcrumbList={BreadcrumbList} PageName="Employees" />
          {HasPermission('AddHrPerson') && (
            <div className="ms-auto">
              <Link
                to=""
                className="btn btn-theme"
                data-bs-toggle="modal"
                data-bs-target="#modalXl"
                onClick={() => setShow(true)}>
                <i className="fa fa-plus-circle fa-fw me-1"></i>
                {t('AddNewEmployee')}
              </Link>
            </div>
          )}
        </div>

        <Card>
          <Loading loading={loading} error={error}>
            <CardBody>
              <>
                <div className="row">
 

                  <div className="col-lg-4">
                    <div className="input-group flex-nowrap">
                      <span className="input-group-text  text-theme fw-bold">
                        {t('Search')}
                        {/* <i className="fa fa-search  text-theme ms-3"></i> */}
                        </span>
                      <div className="input-group">
                      <div className="flex-fill position-relative">
                        <div className="input-group border-0 ">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}
                            >
                            <i className="fa fa-search opacity-5 text-theme"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            // placeholder={t('Search')}
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                              setCurrentPage(1);
                            }}
                          />
                        </div>
                      </div>
                    </div>
   
                    </div>
                  </div>



                  <div className="col-lg-4">
                    <div className="input-group flex-nowrap">
                      <span className="input-group-text text-theme fw-bold">{t('Organization')}</span>

                      <Select
                        classNamePrefix="react-select"
                        className="w-100"
                        isLoading={loading3}
                        isSearchable={true}
                        isClearable
                        options={[
                          {
                            label: t('NotAssigned'),
                            value: null,
                          },
                          ...OrganizationOptions,
                        ]}
                        onChange={(option) => {
                          setFilterOrganization(option === null ? 'all' : option.value);
                          setCurrentPage(1);
                        }}
                        placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="input-group flex-nowrap">
                      <span className="input-group-text text-theme fw-bold">{t('EmployeeStatus')}</span>
                      <Select
                        classNamePrefix="react-select"
                        className="w-100"
                        isLoading={loading3}
                        isSearchable={true}
                        isClearable
                        options={[
                          { label: t('On-the-job'), value: 'active' },
                          { label: t('Terminated') , value: 'exp' },
                        ]}
                        onChange={(option) => {
                          setEmpStatus(option === null ? null : option.value);
                          setCurrentPage(1);
                        }}
                        placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      />
                    </div>
                  </div>
                </div>

                <hr />

                <DataEmployees Employees={currentEmp} />
                <Pagination
                  dataPerPage={EmpPerPage}
                  currentPage={currentPage}
                  handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
                  filteredData={filteredEmp}
                />
              </>
            </CardBody>
          </Loading>
        </Card>

        <ModalComponent title={t('AddNewEmployee')} id="modalXl">
          {Show && <AddPerson />}
        </ModalComponent>
      </div>
    </>
  );
}
export default Index;
