import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';


const POLARCHART = ({labels , overTimeData}) => {
    useEffect(() => {
        var themeColorRgb = (getComputedStyle(document.body).getPropertyValue('--bs-theme-rgb')).trim();
        var gray800Rgb = (getComputedStyle(document.body).getPropertyValue('--bs-gray-800-rgb')).trim();
        var gray500Rgb = (getComputedStyle(document.body).getPropertyValue('--bs-gray-500-rgb')).trim();
        var gray300Rgb = (getComputedStyle(document.body).getPropertyValue('--bs-gray-300-rgb')).trim();
        var inverseRgb = (getComputedStyle(document.body).getPropertyValue('--bs-inverse-rgb')).trim();

        var canvas = document.getElementById('polarAreaChart');
        var existingChart = Chart.getChart(canvas);

        if (existingChart) {
          existingChart.destroy();
        }

        
        new Chart(canvas, {
          type: 'pie',
          data: {
            datasets: [{
              data: overTimeData,
              // backgroundColor: ['rgba('+ themeColorRgb +', .5)', 'rgba('+ inverseRgb +', .2)' +', .5)', 'rgba('+ gray300Rgb+', .5)', 'rgba('+ gray500Rgb +', .5)', 'rgba('+ gray800Rgb +', .5)'],
              borderWidth: 0
            }],
            labels: labels
          }
        });
        
        
      }, []);
  return (
    <div>
    <canvas id="polarAreaChart"></canvas>
  </div>
  )
}

export default POLARCHART