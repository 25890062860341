import React, {  useState } from "react";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import { Card, CardBody } from "../../../../components/card/card";
// import Pagination from "../../../../components/Shared/Pagination/Pagination";
import { useTranslation } from "react-i18next";
// import ModalComponent from '../../../components/Modal/ModalComponent';
import AddLocations from "./AddLocations";
import { useAppDispatch } from "../../../../store/hooks";
import { useNavigate } from "react-router-dom";
// import MedModalComponent from "../../../../components/Modal/MedModalComponent";
// import useGetOrganizations from "../../../../hooks/HR/use-get-organizations";
import Loader from "../../../../components/Shared/Loader/Loader";
import ModalComponent from "../../../../components/Modal/ModalComponent";
 import useGetLocations from "../../../../hooks/HRSetting/use-get-locations";

const Index = () => {
  const [Show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  // const dispatch = useAppDispatch();
  const Navigate = useNavigate();

  const { Locations, loading, error } = useGetLocations();

  const filteredSearch = Locations?.slice()?.filter((item: any) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);
  //________________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Locations",
      link: null,
    },
  ];
  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="Locations" />

      <Card>
      <Loader loading={loading}/>          <CardBody>
            {/* <Loader loading={loading} /> */}

                <div className="row">
                  <div className="col-7 col-md-6 d-flex justify-content-start">
                    <div className="input-group mb-4">
                      <div className="flex-fill position-relative">
                        <div className="input-group">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: "10px" }}
                          >
                            <i className="fa fa-search opacity-5"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder={t("Search") + " ..."}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-5 col-md-6 text-end">
                    <a
                      href="#/"
                      className="btn btn-outline-theme"
                      data-bs-toggle="modal"
                      data-bs-target="#modalAddLocation"
                      onClick={() => setShow(true)}
                    >
                      <i className="fa fa-plus-circle fa-fw me-1"></i>
                      {t("AddLocation")}
                    </a>
                  </div>
                </div>

                {/* //  START TABLE   */}

                <div className="mb-5">
                  <div className="row">
                    {currentData.map((item: any) => (
                      <div className="col-xl-4" key={item.id}>
                        <Card className="m-2">
                          <div className="m-1 bg-white bg-opacity-15">
                            <iframe
                              title="Youtube"
                              src={`http://www.google.com/maps/embed/v1/place?q=${item.latitude},${item.longitude}&key=AIzaSyCG3Kt_jO8o7x67EsdV9RuTJP5IxSEwmeo`}
                              style={{
                                border: 0,
                                width: "100%",
                                height: "10rem",
                              }}
                              allowFullScreen
                            ></iframe>
                            <div className="list-group list-group-flush">
                              <div className="list-group-item d-flex">
                                <div className="w-30px h-40px d-flex align-items-center justify-content-center">
                                  <i className="fas fa-lg fa-fw me-2 fa-location-arrow"></i>
                                </div>
                                <div className="flex-fill px-3">
                                  <div className="fw-bold">
                                    {i18n.language === "ar"
                                      ? item.name2
                                      : item.name}
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-outline-theme me-1"
                                  data-toggle="tooltip"
                                  title="تعديل بيانات الموقع"
                                  onClick={() =>
                                    Navigate(`${item.id}/Edit`)
                                  }
                                >
                                  <i className="fas fa-edit fa-lg"></i>
                                </button>
                                {/* <div className="text-nowrap">
                                  <div className="dropdown dropdown-icon ms-auto">
                                    <a
                                      href="#/"
                                      className="text-white"
                                      data-bs-toggle="dropdown"
                                    >
                                      <i className="fa fa-ellipsis-h"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <Link
                                        to={`${item.id}/Edit`} //  HRsettings/Location/:id/Edit
                                        className="dropdown-item"
                                      >
                                        تعديل
                                      </Link>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>
                    ))}
                  </div>
                </div>
     
          </CardBody>
        
      </Card>

      <ModalComponent title={t("AddLocation")} id="modalAddLocation">
        {Show && <AddLocations />}
      </ModalComponent>
    </div>
  );
};

export default Index;
