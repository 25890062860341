import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { GetGlJeHeadersList } from '../../../store/Finance/Journals/JournalsSlice';

const useGetJournalsList= () => {

    const dispatch = useAppDispatch();
    useEffect(() => {
      if(JournalsList?.length ===0){
        dispatch(GetGlJeHeadersList());
      }
    }, []);
    const { JournalsList , loading} = useAppSelector(
      (state) => state.Journals
    );
   

  return {JournalsList , loading }
}

export default useGetJournalsList
