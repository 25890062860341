
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


// import {  getData, postData } from "../../Axios/axiosData";
import { ntxAPI } from "../../../API/axiosNeatex";

const initialState = { Jobs: [], loading: false, error: null, Job: [] };



export const insertJob = createAsyncThunk(
  "Jobs/insertJob",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    
    try {
      // const data = await postData("HRSetting/AddJob" , item);
      const {data} = await ntxAPI.post("HRSetting/AddJob" , item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getJobs = createAsyncThunk("Jobs/getJobs", async (_, thunkAPI) => {
  const { rejectWithValue   } = thunkAPI;

  try {
    // const data = await getData("HRSetting/GetJobList" );
    const {data} = await ntxAPI.get("HRSetting/GetJobList");
    return data;
  } catch (error) {
    // console.error(error);
    return rejectWithValue(error.message);
  }
  
 
});

export const getJob = createAsyncThunk("Jobs/getJob", async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    // const data = await getData(`HRSetting/GetJobById?Id=${id}`);
    const {data} = await ntxAPI.get(`HRSetting/GetJobById?Id=${id}`);
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

export const editJob = createAsyncThunk(
  "Jobs/editJob",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
  
    try {
      // const data = await postData( "HRSetting/UpdateJob" , item);
      const {data} = await ntxAPI.post("HRSetting/UpdateJob" , item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

///_________________________________________________//________________________












///___________________________

const JobSlice = createSlice({
  name: "Jobs",
  initialState,
  reducers: {
   
  },
  extraReducers(builder) {
    builder
      /////// get all jobs
      .addCase(getJobs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getJobs.fulfilled, (state, action) => {
        state.loading = false;
        state.Jobs = action.payload;
      })
      .addCase(getJobs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // get one Job by id
      .addCase(getJob.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getJob.fulfilled, (state, action) => {
        state.loading = false;
        state.Job = action.payload;
      })
      .addCase(getJob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      ///////insert
      .addCase(insertJob.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(insertJob.fulfilled, (state, action) => {
        state.loading = false;
        state.Jobs.push(action.payload);
      })
      .addCase(insertJob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // edit 
      .addCase(editJob.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editJob.fulfilled, (state, action) => {
        state.loading = false;
        state.Job = action.payload;
      })
      .addCase(editJob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default JobSlice.reducer;
