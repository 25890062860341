import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
   GetItemConversionList,
} from '../../../store/INV/MasterItems/ItemConversionSlice';
// import { useTranslation } from 'react-i18next';

const useGetItemConversionList = () => {
  const dispatch = useAppDispatch();

  // const { i18n } = useTranslation();
  useEffect(() => {
    dispatch(GetItemConversionList());
  }, []);

  const { ItemConversionList, loading, error } = useAppSelector((a) => a.ItemConversion);
  

  // const OptionsUnitsByItemId = ItemConversionList?.map((item) => ({
  //   value: item.id,
  //   label: `${i18n.language === 'en' ? item.uomName : item.uomName2}`,
  // }));

  return { ItemConversionList, loading, error };
};

export default useGetItemConversionList;
