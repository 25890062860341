import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/Shared/Loader/Loader';
import { Card, CardBody } from '../../../components/card/card';
import { Form } from 'react-bootstrap';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';

import useGetFormColumn from '../../../hooks/Forms/use-get-FColumn';
import AddStaticForm from './AddStaticForm';
import BuilderRequest from './BuilderRequest/BuilderRequest';
import useAddRequest from './useAddRequest';
import Loading from '../../../components/Shared/Loader/Loading';

const AddRequests = () => {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { FormId } = useParams();
  const [referenceId, setReferenceId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { FormColumnList, loading, error } = useGetFormColumn(FormId);
  const [formData, setFormData] = useState({});
  const { AddRequest } = useAddRequest();

  const handleAddRequest = async (e) => {
    if (e) {
      e.preventDefault();
    }

    const RequestData = {
      selectedPersonId: '',
      isPersonaly: true,
      referenceId: referenceId || '',
      dynamicFormId: FormColumnList?.dynamicFormId,
      values: FormColumnList?.columnList
        ?.filter((f) => f.enabledFlag === true)
        ?.map((item) => ({
          dynamicFormColumnID: item.id,
          dataType: item.validationType || item.formatType,

          value:
            item.validationType === 'BOOLEAN' && !formData[item.id]
              ? '0'
              : item.validationType === 'ATTACHED'
              ? null
              : formData[item.id],

          lableText: i18n.language === 'ar' ? item.lable2 : item.lable,
          lableText2: i18n.language === 'ar' ? item.lable2 : item.lable,
          sequenceNum: item.sequenceNum,
          fileAttachment:
            item.validationType === 'ATTACHED' && formData[item.id] ? formData[item.id] : null,
          isUpdateAttach: item.validationType === 'ATTACHED' && formData[item.id] ? true : false,
        })),
    };

    setIsSubmitting(true);

    var respons = await AddRequest(RequestData);
    // data?.succeeded === true
    if (respons?.succeeded === true) {
      Navigate(-1, { replace: true });
      CustomAlert({ action: 'Add', msg: 'تم ارسال الطلب بنجاح' });
    } else {
      CustomAlert({ action: 'Info', msg: respons?.message });
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (referenceId !== null) {
      handleAddRequest();
    }
  }, [referenceId]);

  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('Requests'),
      link: null,
    },
    {
      name:
        i18n.language === 'en' ? FormColumnList.dynamicFormName : FormColumnList.dynamicFormName2,
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName={t('Requests')}
        subtitle={
          i18n.language === 'en' ? FormColumnList.dynamicFormName : FormColumnList.dynamicFormName2
        }
      />

      <Card>
        {/* <Loader loading={loading} /> */}

        <Loading loading={loading} error={error}>
          <CardBody>
            <Form onSubmit={handleAddRequest}>
              {FormColumnList?.isStatic && (
                <AddStaticForm
                  StaticFormId={FormColumnList.dynamicFormId}
                  setReferenceId={setReferenceId}
                />
              )}
              {FormColumnList?.dynamicFormId && (
                <BuilderRequest
                  FormColumnList={FormColumnList}
                  setFormData={setFormData}
                  formData={formData}
                />
              )}

              {!FormColumnList?.isStatic && (
                <div className="text-center">
                  <button disabled={isSubmitting} type="submit" className="btn me-1 btn-theme mb-1">
                    {isSubmitting ? (
                      <div className="spinner-border spinner-border-sm me-2"></div>
                    ) : (
                      <i className="bi bi-send fa-fw me-1"></i>
                    )}
                    {t('SendRequest')}
                  </button>

                  <button
                    type="button"
                    className=" btn me-1 btn-default mb-1"
                    onClick={() => Navigate(-1, { replace: true })}>
                    <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                  </button>
                </div>
              )}
            </Form>
          </CardBody>
        </Loading>
      </Card>
    </>
  );
};

export default AddRequests;

// <div className="d-flex align-items-center mb-3">
// <div>
//   <ul className="breadcrumb">
//     <li className="breadcrumb-item">
//       <a
//         href="#/"
//         onClick={() => Navigate("/Requests", { replace: true })}
//       >
//         {t("Home")}
//       </a>
//     </li>
//     <li className="breadcrumb-item">
//       <a
//         href="#/"
//         onClick={() => Navigate("/Requests", { replace: true })}
//       >
//         {t("selfService")}
//       </a>
//     </li>
//     <li className="breadcrumb-item active">
//       {i18n.language === "ar"
//         ? FormColumnList.dynamicFormName2
//         : FormColumnList.dynamicFormName}
//     </li>
//   </ul>
//   <h1 className="page-header mb-0">
//     {i18n.language === "ar"
//       ? FormColumnList.dynamicFormName2
//       : FormColumnList.dynamicFormName}
//   </h1>
// </div>
// </div>
