import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../../API/axiosNeatex';
import { InvReceiveModels } from './invReceiveModels';
import { TransactionTypesModels } from '../SharedModels';


export const addReceiveTransactions = createAsyncThunk(
  'InvReceive/addReceiveTransactions',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('InvReceive/AddReceiveTransactions', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateReceiveTransactions = createAsyncThunk(
  'InvReceive/updateReceiveTransactions',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('InvReceive/UpdateReceiveTransactions', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTransactionsReceiveList = createAsyncThunk(
  'InvReceive/getTransactionsReceiveList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('InvReceive/GetTransactionsReceiveList');
      if (data?.succeeded) {
        //===
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTransactionTypesReceiveList = createAsyncThunk(
  'InvReceive/getTransactionTypesReceiveList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('InvReceive/GetTransactionTypesReceiveList');
      if (data?.succeeded) {
        //===
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getReceiveTransactionsById = createAsyncThunk(
  'InvReceive/getReceiveTransactionsById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`InvReceive/GetReceiveTransactionsById?Id=${id}`);
      if (data?.succeeded) {
        // ===
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  InvReceiveList: InvReceiveModels[];
  TransactionTypesReceiveList: TransactionTypesModels[];
  //   Transactions ById: InvReceiveByIdModels;
  loading: boolean;
  error: any | null;
} = {
  InvReceiveList: [],
  TransactionTypesReceiveList: [],
  //   Transactions ById: {} as InvReceiveByIdModels,
  loading: false,
  error: null,
};

const InvReceiveSlice = createSlice({
  name: 'InvReceive',
  initialState,
  reducers: {
    // cleanUpTransactionsQuotationById: (state) => {
    //   state.TransactionsQuotationById = {} as InvReceiveByIdModels;
    // },
  },
  extraReducers(builder) {
    builder

      .addCase(getTransactionsReceiveList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getTransactionsReceiveList.fulfilled,
        (state, action: PayloadAction<InvReceiveModels[]>) => {
          state.loading = false;
          state.InvReceiveList = action.payload;
        }
      )
      .addCase(getTransactionsReceiveList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getTransactionTypesReceiveList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getTransactionTypesReceiveList.fulfilled,
        (state, action: PayloadAction<TransactionTypesModels[]>) => {
          state.loading = false;
          state.TransactionTypesReceiveList = action.payload;
        }
      )
      .addCase(getTransactionTypesReceiveList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getReceiveTransactionsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getReceiveTransactionsById.fulfilled, (state, action: PayloadAction) => {
        state.loading = false;
        //   state.TransactionsQuotationById = action.payload;
      })
      .addCase(getReceiveTransactionsById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// export const { cleanUpTransactionsQuotationById } = InvReceiveSlice.actions;
export default InvReceiveSlice.reducer;
