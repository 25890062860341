import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { GetNtxPrintTemplatesList } from '../../store/NTX/PrintTemplate/PrintTemplateSlice';
 

const useGetPrintTemplateList = () => {
  const dispatch = useAppDispatch();
  const { PrintTemplateList, loading } = useAppSelector(
    (state) => state.PrintTemplate
  );
    useEffect(() => {
    if (PrintTemplateList.length === 0) {
      dispatch(GetNtxPrintTemplatesList());

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return { PrintTemplateList, loading };
};

export default useGetPrintTemplateList;
