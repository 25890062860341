import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import { ntxheader, variables } from '../../API/variables';
import { DecryptString, EncryptString } from '../../Helper/Encrypt/NtxEncrypt';
import { ntxAPI } from '../../API/axiosNeatex';
import axios from 'axios';

const AuthUser = localStorage?.AuthUser ? JSON.parse(DecryptString(localStorage?.AuthUser)) : null;

const initialState = {
  AuthUser,
  loading: false,
  LoadingRefreashToken: false,
  ErrorRefreashToken: null,
  error: null,
};

export const LoginAction = createAsyncThunk('Login/LoginAction', async (item, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const { data } = await axios.post(variables.API_URL + 'Authenticate/login', item, {
      headers: ntxheader,
    });

    return data;
  } catch (error) {
    if (error.response) {
      if (error?.response?.status === 401) {
        return rejectWithValue('401'); // خطأ في بيانات التحقق
      } else if (error?.response?.status === 404) {
        return rejectWithValue('404'); // خطأ بالسيرفر
      }
    } else if (error?.code === 'ERR_NETWORK') {
      return rejectWithValue('500'); // لا يوجد اتصال بالشبكة
    }
    // Network error or other error
    return rejectWithValue('NetworkError');
  }
});

export const ChangePass = createAsyncThunk('Login/ChangePass', async (item, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const { data } = await ntxAPI.post('Security/ChangePassword', item);
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

export const refreashToken = createAsyncThunk('Login/refreashToken', async (_, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  const AuthUser = localStorage?.AuthUser
    ? JSON.parse(DecryptString(localStorage?.AuthUser))
    : null;
  const token = AuthUser?.token;
  const refreshToken = AuthUser?.refreshToken;

  try {
    const { data } = await axios.post(
      variables.API_URL + 'Authenticate/refresh-token',
      {
        accessToken: token,
        refreshToken: refreshToken,
      },
      {
        headers: ntxheader,
      }
    );

    await dispatch(setAuthUser(data));

    return data;
  } catch (error) {
    return rejectWithValue('Error');
  }
});

export const getPersonId = createSelector(
  (state) => state?.userLogin?.AuthUser,
  (AuthUser) => AuthUser?.personId || null
);
export const getBusinessGroupIdForPerson = createSelector(
  (state) => state?.userLogin?.AuthUser,
  (AuthUser) => AuthUser?.businessGroupId || null
);

const LoginSlice = createSlice({
  name: 'Login',
  initialState,
  reducers: {
    setAuthUser: (state, { payload }) => {
      localStorage.setItem('AuthUser', EncryptString(JSON.stringify(payload)));
      state.AuthUser = payload;
    },
    LogOut: (state, { payload }) => {
      localStorage.clear();
      state.AuthUser = null;
    },
  },
  extraReducers: (builder) => {
    // Login User Action
    builder
      .addCase(LoginAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(LoginAction.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        // You can handle any state updates related to the fulfilled action here
      })
      .addCase(LoginAction.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        // You can handle any state updates related to the rejected action here
      })

      .addCase(refreashToken.pending, (state) => {
        state.LoadingRefreashToken = true;
        state.ErrorRefreashToken = null;
      })
      .addCase(refreashToken.fulfilled, (state, { payload }) => {
        state.LoadingRefreashToken = false;
        state.ErrorRefreashToken = null;
        // You can handle any state updates related to the fulfilled action here
      })
      .addCase(refreashToken.rejected, (state, { payload }) => {
        state.LoadingRefreashToken = false;
        state.ErrorRefreashToken = payload;
      });
  },
});

export const { LogOut, setAuthUser } = LoginSlice.actions;

export default LoginSlice.reducer;
