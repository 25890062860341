import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import { FormPayrollElement } from '../../../ValidationForm/validationSchema';
import { Card, CardBody } from '../../../../components/card/card';
import {
  cleanElementTypeListById,
  GetClassification,
  GetElementTypeById,
  UpdateElementType,
} from '../../../../store/HR/Payroll/payrollElementSlice';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../../components/Shared/Loader/Loader';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Loading from '../../../../components/Shared/Loader/Loading';

function EditElementType({ id }) {
  const Navigate = useNavigate();
  // const { id } = useParams();
  const dispatch = useDispatch();

  // Translations
  const { t, i18n } = useTranslation();

  // get GetCurrency
  const { Currency } = useLookupCurrency();

  // get ClassificationList and GetElementTypeById
  useEffect(() => {
    dispatch(cleanElementTypeListById());
    dispatch(GetElementTypeById(id));
    dispatch(GetClassification());
  }, [dispatch, id]);

  const { ClassificationList, ElementTypeListById, loading3, error } = useSelector(
    (state) => state.PyrollElement
  );

  const formik = useFormik({
    initialValues: {
      name: ElementTypeListById ? ElementTypeListById.name : '',
      name2: ElementTypeListById ? ElementTypeListById.name2 : '',
      currencyCode: ElementTypeListById ? ElementTypeListById.currencyCode : '',
      classificationId: ElementTypeListById ? ElementTypeListById.classificationId : '',
      description: ElementTypeListById ? ElementTypeListById.description : '',
      isRecurring: ElementTypeListById ? ElementTypeListById.isRecurring : false,
      uom: ElementTypeListById ? ElementTypeListById.uom : '',
    },
    enableReinitialize: true,
    validationSchema: FormPayrollElement(t),
    onSubmit: (values) => {
      dispatch(
        UpdateElementType({
          id: id,
          name: values.name,
          name2: values.name2,
          currencyCode: values.currencyCode,
          classificationId: values.classificationId, // values.classificationId
          description: values.description,
          isRecurring: values.isRecurring,
          uom: values.uom,
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          CustomAlert({ action: 'Edit' });
          Navigate(-1, { replace: true });
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Info' });
        });
    },
  });

  return (
    <>
      {/* <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t('Home')}</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#/" onClick={() => Navigate('/payroll', { replace: true })}>
                {t('PayrollElements')}
              </a>
            </li>
            <li className="breadcrumb-item active">{t('Button.Edit')} </li>
          </ul>
          <h1 className="page-header mb-0"> {t('EditPayrollElement')}</h1>
        </div>
      </div> */}

      {/* <Card> */}
      {/* <CardBody> */}
      <Loading loading={loading3} error={error}>
        <Form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-xl-5">
              <div className="form-group mb-3">
                <label className="form-label">{t('LocalName')}</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="name2"
                  onChange={formik.handleChange}
                  value={formik.values.name2 || ''}
                  isInvalid={formik.touched.name2 && formik.errors.name2}
                />
                <Form.Control.Feedback type="invalid">
                  {/* {formik.errors.NATIONAL_IDENTIFIER} */}
                </Form.Control.Feedback>
              </div>
            </div>
            <div className="col-xl-5">
              <div className="form-group mb-3">
                <label className="form-label">{t('GlobalName')}</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name || ''}
                  isInvalid={formik.touched.name && formik.errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {/* {formik.errors.NATIONAL_IDENTIFIER} */}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-2">
              <div className="form-group mb-3">
                <label className="form-label"> </label>
                <Form.Check
                  type="switch"
                  label={t('Recurring')}
                  name="isRecurring"
                  onChange={formik.handleChange}
                  value={formik.values.isRecurring}
                  checked={formik.values.isRecurring || false}
                />
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label">{t('Classification')}</label>
                <Form.Select
                  className="form-select"
                  name="classificationId"
                  onChange={formik.handleChange}
                  value={formik.values.classificationId}
                  isInvalid={formik.touched.classificationId && formik.errors.classificationId}>
                  <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                  {ClassificationList.map((item, idx) => (
                    <option key={++idx} value={item.id}>
                      {i18n.language === 'ar' ? item.name2 : item.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {/* {formik.errors.TITLE} */}
                </Form.Control.Feedback>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label">{t('Currency')}</label>
                <Form.Select
                  className="form-select"
                  name="currencyCode"
                  onChange={formik.handleChange}
                  value={formik.values.currencyCode}
                  isInvalid={formik.touched.currencyCode && formik.errors.currencyCode}>
                  <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                  {Currency.map((item) => (
                    <option key={item.currencyCode} value={item.currencyCode}>
                      {item.description}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {/* {formik.errors.TITLE} */}
                </Form.Control.Feedback>
              </div>
            </div>
   <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('InputOfType')}</label>
              <Form.Select
                className="form-select"
                name="uom"
                onChange={formik.handleChange}
                value={formik.values.uom}>
                <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                <option key={1} value="Text">
                  {i18n.language === 'ar' ? 'نص' : 'Text'}
                </option>

                <option key={2} value="Date">
                  {i18n.language === 'ar' ? 'تاريخ' : 'Date'}
                </option>

                <option key={3} value="Percentage">
                  {i18n.language === 'ar' ? 'نسبة مئوية' : 'Percentage'}
                </option>

                <option key={4} value="NoBasic">
                  {i18n.language === 'ar' ? 'عدد مرات راتب أساسي' : 'No Basic'}
                </option>

                <option key={5} value="Money">
                  {i18n.language === 'ar' ? 'قيمة مالية' : 'Money'}
                </option>

                <option key={6} value="Number">
                  {i18n.language === 'ar' ? 'رقم' : 'Number'}
                </option>

                <option key={7} value="Time">
                  {i18n.language === 'ar' ? 'وقت' : 'Time'}
                </option>

                <option key={8} value="Day">
                  {i18n.language === 'ar' ? 'يوم' : 'Day'}
                </option>
              </Form.Select>
            </div>
          </div>

          </div>

       

          <div className="col-xl-12">
            <div className="form-group mb-3">
              <label className="form-label">{t('Note')}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description || ''}
              />
            </div>
          </div>

          <div className="text-center">
            <button
              disabled={formik.isSubmitting}
              type="submit"
              className="btn me-1 btn-theme mb-1">
              <i className="fa fa-save fa-fw me-1"></i> {t('Button.Save')}
            </button>
            <button
              // onClick={() => Navigate(-1, { replace: true })}
              data-bs-dismiss="modal"
              type="button"
              className=" btn me-1 btn-default mb-1">
              <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
            </button>
          </div>
        </Form>
      </Loading>
      {/* </CardBody> */}
      {/* </Card> */}
    </>
  );
}

export default EditElementType;
