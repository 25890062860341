import React, { useState } from 'react';
import Breadcrumb from '../../components/Shared/Breadcrumb';
import { Card, CardBody, CardFooter } from '../../components/card/card';
import PersonIntegration from './Persons/PersonIntegration';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PersonWithAssignmentIntegration from './PersonWithAssignment/PersonWithAssignmentIntegration';

// enum TypeData {
//   Persons = 'Persons',
//   // Jobs = 'Jobs',
//   // Positions = 'Positions',
// }

const Index = () => {
  //#region  سابقاً

  // const [showModal, setShowModal] = useState<boolean>(false);
  // const [selectedData, setSelectedData] = useState<any>();
  // const [fields, setFields] = useState<any>();
  // const [isloading, setIsloading] = useState<boolean>(false);
  // const [modalType, setModalType] = useState<TypeData>();

  // const { PersonFields } = FetchPersonFields();

  // const handleGetData = async (type: TypeData) => {
  //   switch (type) {
  //     case TypeData.Persons:
  //       setIsloading(true);
  //       const { data } = await ntxAPI.get('Persons/GetPersonList');
  //       setIsloading(false);
  //       return data.data;
  //   }
  // };

  // const handleGetField = (type: TypeData) => {
  //   switch (type) {
  //     case TypeData.Persons:
  //       return 'PersonFields';
  //   }
  // };

  // const handleSelected = async (type: TypeData, isUploadFile: boolean) => {
  //   setModalType(type);
  //   if (isUploadFile) {
  //     setFields(handleGetField(type));
  //     setShowModal(true);
  //   } else {
  //     setSelectedData(await handleGetData(type));
  //     await new Promise((resolve) => setTimeout(resolve, 2000));
  //   }
  // };

  //#endregion
  //________________________________________________________________

  const [ModalPersonWithAssignment, setModalPersonWithAssignment] = useState<boolean>(false);
  const [ModalPerson, setModalPerson] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleDownloadTemplateFile = (type: string) => {
    // const filePath = './Persons/Person-Fields.xlsx'
    var filePath;
    if (type === 'Person') {
      filePath = '/assets/IntegrationTemplates/Person-Fields.xlsx';
    } else if (type === 'PersonWithAassignment') {
      filePath = '/assets/IntegrationTemplates/Person-Assignment-Fields.xlsx';
    }

    const link = document.createElement('a');
    // link.href = process.env.PUBLIC_URL + '/assets/IntegrationTemplates/Person-Fields.xlsx';
    link.href = process.env.PUBLIC_URL + filePath;
    link.download = `${type}-Fields.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //_________________________
  var BreadcrumbList = [
    {
      name: 'Home',
      link: '/',
    },

    {
      name: 'التكامل مع الأنظمة',
      link: null,
    },
  ];

  //_________________



  return (
    <>
      <Breadcrumb BreadcrumbList={BreadcrumbList} PageName="التكامل مع الأنظمة" />
 
      <hr className="mb-3" />

      <div className="row">
        <div className="col-xl-3">
          <Card>
            <CardBody className="bg-inverse bg-opacity-10 p-2">
              <div className="m-1 bg-inverse bg-opacity-10">
                <div className="position-relative overflow-hidden" style={{ height: '165px' }}>
                  <div className="card-img-overlay text-center p-1">
                    <div className="my-3">
                      <i
                        className="bi bi-person  fa-lg text-theme"
                        style={{ fontSize: '4rem' }}></i>
                    </div>
                    <div>
                      <div className="fw-bold fs-5">بيانات الموظفين</div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>

            <CardFooter className="bg-none">
              <div className="d-flex justify-content-between">
                <button
                  disabled={true}
                  // disabled={isloading}
                  type="button"
                  className="btn btn-sm btn-outline-theme me-1"
                  // onClick={() => handleSelected(TypeData.Persons, false)}
                >
                  {false ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="bi bi-cloud-arrow-down fa-lg me-2"></i>
                  )}
                  API
                </button>

                <div className="btn-group" role="group" style={{ direction: 'ltr' }}>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-theme dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <i className="bi bi-file-earmark-spreadsheet fa-lg ms-4"></i>
                    {t('EXCEL')}
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="" onClick={() => setModalPerson(true)}>
                        <i className="bi bi-database-fill-gear fs-4 ms-2"></i>
                        بدء تكامل البيانات
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to=""
                        onClick={() => handleDownloadTemplateFile('Person')}>
                        <i className="bi bi-download  me-1 ms-2 fs-4"></i>
                        تحميل القالب
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </CardFooter>
          </Card>
        </div>

        <div className="col-xl-3">
          <Card>
            <CardBody className="bg-inverse bg-opacity-10 p-2">
              <div className="m-1 bg-inverse bg-opacity-10">
                <div className="position-relative overflow-hidden" style={{ height: '165px' }}>
                  <div className="card-img-overlay text-center p-1">
                    <div className="my-3">
                      <i
                        className="bi bi-person-lines-fill fa-lg text-theme"
                        style={{ fontSize: '4rem' }}></i>
                    </div>
                    <div>
                      <div className="fw-bold fs-5">بيانات الموظفين (مع التعيين)</div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>

            <CardFooter className="bg-none">
              <div className="d-flex justify-content-between">
                <button
                  disabled={true}
                  // disabled={isloading}
                  type="button"
                  className="btn btn-sm btn-outline-theme me-1"
                  // onClick={() => handleSelected(TypeData.Persons, false)}
                >
                  {false ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="bi bi-cloud-arrow-down fa-lg me-2"></i>
                  )}
                  API
                </button>

                <div className="btn-group" role="group" style={{ direction: 'ltr' }}>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-theme dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <i className="bi bi-file-earmark-spreadsheet fa-lg ms-4"></i>
                    {t('EXCEL')}
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        className="dropdown-item"
                        to=""
                        // onClick={() => handleSelected(TypeData.Persons, true)}>
                        onClick={() => setModalPersonWithAssignment(true)}>
                        <i className="bi bi-database-fill-gear fs-4 ms-2"></i>
                        بدء تكامل البيانات
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="dropdown-item"
                        to=""
                        onClick={() => handleDownloadTemplateFile('PersonWithAassignment')}>
                        <i className="bi bi-download  me-1 ms-2 fs-4"></i>
                        تحميل القالب
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </CardFooter>
          </Card>
        </div>
      </div>

      {ModalPerson && <PersonIntegration setModalPerson={setModalPerson} />}

      {ModalPersonWithAssignment && (
        <PersonWithAssignmentIntegration
          setModalPersonWithAssignment={setModalPersonWithAssignment}
        />
      )}
    </>
  );
};

export default Index;
