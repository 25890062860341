import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useTranslation } from 'react-i18next';
import { getGrades } from '../../store/HR/HRSetting/jobgradeSlice';

 

const useGetGrade = ( ) => {
  const {i18n} = useTranslation()

   

  const { Grades, loading, error } = useAppSelector((state) => state.Grades);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (Grades?.length === 0) {
      dispatch(getGrades());
    }
  }, []);

  const GradesOptions =
  Grades &&
  Grades?.map((item : any) => ({
    value: item.id,
    label: i18n.language === "en" ? item.name : item.name2,
  }));

 

 
   

  return { Grades, GradesOptions,  loading , error }
}

export default useGetGrade
