import React, { Fragment, useEffect, useState } from 'react';
import { LinesTypes } from '../../../../store/Finance/Journals/JournalsModels';
import Select from 'react-select';
import { GlJeLinesList } from '../../../../store/Finance/Journals/JournalsModels';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import useGetChartAccountsDetailsList from '../../../../hooks/Finance/useGetChartAccountsDetailsList';
import { Link } from 'react-router-dom';
import { TreeSelect  } from 'antd';

// import { Card, CardBody } from "../../../../components/card/card";
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import { useAppSelector } from '../../../../store/hooks';

interface formikvalues {
  glDate: string;
  ledgerId: string;
  jeCategory: string;
  jeSource: string;
  periodId: string;
  name: string;
  status: string;
  currencyCode: string;
  conversionFlag: boolean;
  conversionDate: string;
  reverseFlag: boolean;
  jeBatchId: string | null;
  parentJeHeaderId: string | null;
  postedDate: string;
  description: string;
  accountedDr: 0;
  accountedCr: 0;
  JeLinesList: GlJeLinesList[];
}

interface LinesProps {
  formik: FormikProps<formikvalues | any>;
  currencyConversionRate: number;
  isUpdate: boolean;
}

const JournalLines: React.FC<LinesProps> = ({
  formik,
  currencyConversionRate,
  isUpdate = false,
}) => {
  const [Line, setLine] = useState<LinesTypes | null>(null);
  const { i18n, t } = useTranslation();
  const [nextJeLineNum, setNextJeLineNum] = useState<number>(0);
  const maxLine = useAppSelector((m) => m.Journals.MaxLineNum);

  const {
    Accounts,
    Branches,
    Companies,
    CostCenters,
    OptionsBranches,
    OptionsAccounts,
    OptionsCompanies,
    OptionsCostCenters,
    TreeCostCenters,
    loading,
  } = useGetChartAccountsDetailsList();

  //______________________________________________________

  //#region Calculate the total debit and credit amounts

  const totalAccountDr = formik.values.JeLinesList.reduce(
    (total: any, line: any) => total + line.accountDr,
    0
  );
  const totalAccountCr = formik.values.JeLinesList.reduce(
    (total: any, line: any) => total + line.accountCr,
    0
  );
  const totalEnteredDr = formik.values.JeLinesList.reduce(
    (total: any, line: any) => total + line.enteredDr,
    0
  );
  const totalEnteredCr = formik.values.JeLinesList.reduce(
    (total: any, line: any) => total + line.enteredCr,
    0
  );
  //#endregion

  //#region handleLine Change Add Delete
  useEffect(() => {
    setNextJeLineNum(formik.values.JeLinesList.length);
  }, [formik.values.JeLinesList]);

  const handleAddLine = () => {
    if (
      !(
        Line &&
        Line.companyId &&
        Line.branchId &&
        Line.costCenterId &&
        Line.accountId &&
        Line.description &&
        (Line.enteredCr || Line.enteredDr)
      )
    ) {
      SwalAlert({ text: 'Please fill all fields before adding a line' });
      return;
    }

    const max = Math.max(...formik.values.JeLinesList.map((a: any) => a.jeLineNum), maxLine);

    formik.setFieldValue('JeLinesList', [
      ...formik.values.JeLinesList,
      { ...Line, jeLineNum: isUpdate ? max + 1 : nextJeLineNum + 1 },
    ]);

    // clear Line after adding line
    setLine({
      description: '',
      accountCr: 0,
      accountDr: 0,
      accountId: null,
      branchId: null,
      companyId: null,
      costCenterId: null,
      enteredCr: 0,
      enteredDr: 0,
      jeLineNum: 0,
    });
  };

  const handleDeleteLine = async (LineNum: number) => {
    const updatedLines = formik.values.JeLinesList?.filter(
      (line: any) => line.jeLineNum !== LineNum
    );

    // Recompute jeLineNum for all remaining lines
    const updatedLinesWithNewJeLineNum = updatedLines.map((line: any, index: number) => ({
      ...line,
      jeLineNum: index + 1,
    }));

    await formik.setFieldValue(
      'JeLinesList',
      isUpdate ? updatedLines : updatedLinesWithNewJeLineNum
    );
  };

  //#endregion

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    const allowedChars = /^[0-9.]+$/;
    const target = event.target as HTMLInputElement;

    if (!allowedChars.test(event.key) || (event.key === '.' && target.value.includes('.'))) {
      event.preventDefault();
    }
  }

  return (
    <div className="">
      <div className="row  ps-1">
        <div className="row">
          <div className="col-lg-3">
            <div className="form-group mb-3">
              <label className="form-label">{t('Company')}</label>
              <Select
                classNamePrefix="react-select"
                // className="text-center"
                isLoading={loading}
                isSearchable={true}
                isClearable
                name="companyId"
                value={
                  Line?.companyId === null
                    ? null
                    : OptionsCompanies?.find((option) => option.value === Line?.companyId)
                }
                options={OptionsCompanies}
                onChange={(option) => {
                  if (option === null) {
                    setLine({
                      ...Line,
                      companyId: null,
                      branchId: null,
                      costCenterId: null,
                    });
                  } else {
                    setLine({ ...Line, companyId: option.value });
                  }
                }}
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              />
            </div>
          </div>

          <div className="col-lg-3">
            <div className="form-group mb-3">
              <label className="form-label">{t('Branch')}</label>
              <Select
                classNamePrefix="react-select"
                // className="text-center"
                isLoading={loading}
                isSearchable={true}
                isClearable
                options={OptionsBranches?.filter((f) => f.accountParant === Line?.companyId)}
                name="branchId"
                value={
                  Line?.branchId === null
                    ? null
                    : OptionsBranches?.find((option) => option.value === Line?.branchId)
                }
                // onChange={(option) => {
                //   setLine({ ...Line, 'branchId': option === null ? null : option.value });
                // }}
                onChange={(option) => {
                  if (option === null) {
                    setLine({
                      ...Line,
                      branchId: null,
                      costCenterId: null,
                    });
                  } else {
                    setLine({ ...Line, branchId: option.value });
                  }
                }}
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              />
            </div>
          </div>
{/* 
          <div className="col-lg-3">
            <div className="form-group mb-3">
              <label className="form-label">{t("CostCenter")}</label>
              <Select
                classNamePrefix="react-select"
                // className="text-center"
                isLoading={loading}
                isSearchable={true}
                isClearable
                name="costCenterId"
                options={OptionsCostCenters?.filter(
                  (f) => f.accountParant === Line?.branchId
                )}
                value={
                  Line?.costCenterId === null
                    ? null
                    : OptionsBranches?.find(
                        (option) => option.value === Line?.costCenterId
                      )
                }
                onChange={(option) => {
                  setLine({
                    ...Line,
                    costCenterId: option === null ? null : option.value,
                  });
                }}
                placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
              />
            </div>
          </div> */}
          <div className="col-lg-3">
            <div className="form-group mb-3">
              <label className="form-label">{t('CostCenter')}</label>
              <TreeSelect
                showSearch
                treeData={TreeCostCenters}
                // style={{ width: '100%' }}
                // value={value}
                className="custom-tree-select w-100"
                treeLine
                placeholder="Please select"
                allowClear
                treeDefaultExpandAll
                onChange={(option) => {
                  setLine({
                    ...Line,
                    costCenterId: option === null ? null : option,
                  });
                }}
              />
            </div>
          </div>

          <div className="col-lg-3">
            <div className="form-group mb-3">
              <label className="form-label">{t('Accounts')}</label>
              <Select
                classNamePrefix="react-select"
                // className="text-center"
                isLoading={loading}
                isSearchable={true}
                isClearable
                name="accountId"
                options={OptionsAccounts}
                value={
                  Line?.accountId === null
                    ? null
                    : OptionsBranches?.find((option) => option.value === Line?.accountId)
                }
                onChange={(option) => {
                  setLine({
                    ...Line,
                    accountId: option === null ? null : option.value,
                  });
                }}
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              />
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-3 ">
            <div className="input-group">
              <span className="input-group-text">{t('Credit')}</span>
              <Form.Control
                autoComplete="off"
                className="form-control"
                type="text"
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyPress(e)}
                value={Line?.enteredCr || ''}
                // onChange={(e)=> {
                //   const allowedChars = /^[0-9.]+$/;
                //   const target = e.target;
                //   if (!allowedChars.test(target.value) || (target.value.includes(".") && target.value.split(".").length > 2)) {
                //     // If invalid, prevent the change
                //     e.preventDefault();
                //   } else {
                //     // If valid, update the state or perform other actions
                //     setLine({
                //       ...Line,
                //       enteredCr: target.value,
                //       enteredDr: Number(target.value) !== 0 ? 0 : Line?.enteredDr,
                //       accountCr: Number(target.value) * Number(currencyConversionRate),
                //       accountDr: Number(target.value) !== 0 ? 0 : Line?.enteredDr,
                //     });
                //   }
                // }}
                onChange={(e) =>
                  setLine({
                    ...Line,
                    enteredCr: e.target.value,
                    enteredDr: Number(e.target.value) !== 0 ? 0 : Line?.enteredDr,
                    accountCr: Number(e.target.value) * Number(currencyConversionRate),
                    accountDr: Number(e.target.value) !== 0 ? 0 : Line?.enteredDr,
                  })
                }
              />
            </div>
          </div>

          <div className="col-lg-3 ">
            <div className="input-group">
              <span className="input-group-text">{t('Debt')}</span>
              <Form.Control
                autoComplete="off"
                className="form-control"
                type="text"
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyPress(e)}
                value={Line?.enteredDr || ''}
                // onChange={(e)=> {
                //   const allowedChars = /^[0-9.]+$/;
                //   const target = e.target;
                //   if (!allowedChars.test(target.value) || (target.value.includes(".") && target.value.split(".").length > 2)) {
                //     // If invalid, prevent the change
                //     e.preventDefault();
                //   } else {
                //     // If valid, update the state or perform other actions
                //     setLine({
                //       ...Line,
                //       enteredDr:  (e.target.value),
                //       enteredCr:
                //         Number(e.target.value) !== 0 ? 0 : Line?.enteredCr,
                //       accountDr:
                //         Number(e.target.value) * Number(currencyConversionRate),
                //       accountCr:
                //         Number(e.target.value) !== 0 ? 0 : Line?.enteredCr,
                //     })
                //   }
                // }}
                onChange={(e) => {
                  setLine({
                    ...Line,
                    enteredDr: e.target.value,
                    enteredCr: Number(e.target.value) !== 0 ? 0 : Line?.enteredCr,
                    accountDr: Number(e.target.value) * Number(currencyConversionRate),
                    accountCr: Number(e.target.value) !== 0 ? 0 : Line?.enteredCr,
                  });
                }}
              />
            </div>
          </div>

          <div className="col-lg-6  ">
            <div className="input-group">
              <span className="input-group-text">{t('Description')}</span>
              <Form.Control
                autoComplete="off"
                className="form-control"
                as="textarea"
                rows={1}
                type="text"
                value={Line?.description || ''}
                onChange={(e) => setLine({ ...Line, description: e.target.value })}
              />
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-2 m-auto text-center ">
            <div className="form-group mb-3 rounded-4 p-1 bg-inverse bg-opacity-10">
              <label className=" fw-bold"> {t('Credit')} </label>
              <div className="d-flex justify-content-center">
                <small className="pt-2 pe-1">{formik.values?.currencyCode}</small>
                <div className="text-theme fw-bold fs-4">{totalAccountCr}</div>
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-2 m-auto text-center  ">
            <div className="form-group mb-3 rounded-4 p-1 bg-inverse bg-opacity-10">
              <label className="fw-bold">{t('Debt')} </label>
              <div className="d-flex justify-content-center">
                <small className="pt-2 pe-1">{formik.values?.currencyCode}</small>
                <div className="text-theme fs-4 fw-bold">{totalAccountDr}</div>
              </div>
            </div>
          </div>

          <div className="col-lg-7 text-end ">
            <button type="button" className="btn btn-lg btn-theme " onClick={() => handleAddLine()}>
              <i className="bi bi-plus-square-dotted fa-lg"></i>
            </button>
          </div>
        </div>
      </div>

      {formik?.values?.JeLinesList?.length > 0 && (
        <Fragment>
          <div className="bg-inverse bg-opacity-5 rounded-2 ">
            <div className="table-responsive rounded-2 text-center border border-theme">
              <table className="table  table-bordered mb-0 text-nowrap">
                <thead className="bg-theme bg-opacity-60">
                  <tr>
                    <th>#</th>
                    <th>{t('Debt')}</th>
                    <th>{t('Credit')}</th>
                    <th>{t('Accounts')}</th>
                    <th>{t('Description')}</th>
                    <th style={{ width: '5%' }}></th>
                  </tr>
                </thead>
                <tbody className="fw-bold">
                  {formik.values.JeLinesList.map((line: any, idx: number) => (
                    <tr key={++idx}>
                      <th scope="row" className="bg-theme bg-opacity-25">
                        {line.jeLineNum}
                      </th>
                      <td>{line.enteredDr}</td>
                      <td>{line.enteredCr}</td>
                      <td>
                        {[
                          Companies?.find((f) => f.id === line.companyId)?.name2,
                          Branches?.find((f) => f.id === line.branchId)?.name2,
                          CostCenters?.find((f) => f.id === line.costCenterId)?.name2,
                          Accounts?.find((f) => f.id === line.accountId)?.name2,
                        ]
                          .filter(Boolean)
                          .join(' | ')}
                      </td>
                      <td>{line.description}</td>
                      <td style={{ width: '5%' }}>
                        <Link
                          to=""
                          className="text-center text-danger"
                          onClick={() => handleDeleteLine(line.jeLineNum)}>
                          <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default JournalLines;
