import React from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { CardBody, Col, Form, Row } from 'react-bootstrap';
import { Card } from '../../../../components/card/card';
import PosCashierList from '../PosCashier/PosCashierList';
import { useParams } from 'react-router-dom';
import MedModalComponent from '../../../../components/Modal/MedModalComponent';
import PosCashier from '../PosCashier/PosCashier';
import PosPaymentMethod from '../PosPaymentMethod/PosPaymentMethod';
import PosPaymentMethodList from '../PosPaymentMethod/PosPaymentMethodList';
import SmallModalComponent from '../../../../components/Modal/SmallModalComponent';
import UpdatePos from '../Pos/UpdatePos';

const PosDetails = () => {
  const { posId } = useParams<string>();
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'نقاط البيع',
      link: '/Pos',
    },
    {
      name: 'التفاصيل',
      link: null,
    },
  ];
  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="تفاصيل نقطة البيع" />
      <hr className="mb-3" />

      <CardBody>
        <div className="row justify-content-center">
          <div className="mb-5">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <h4>
                <i className="bi bi-pencil-square fa-lg  text-theme"></i> نقطة البيع
              </h4>
            </div>
            <Card>
              <CardBody>
                <div className="row">{posId && <UpdatePos />}</div>
              </CardBody>
            </Card>
          </div>

          <Row>
            <Col md={7}>
              <div className="mb-5">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h5>
                    <i className="bi bi-person-bounding-box fa-lg  text-theme"></i> الكاشيــر
                  </h5>
                  <button
                    type="button"
                    className="btn btn-outline-theme"
                    data-bs-toggle="modal"
                    data-bs-target="#ModalPosCashier">
                    {/* <i className="far fa-lg me-2 bi bi-list-stars"></i> */}
                    إضــافة كاشيــر
                  </button>
                </div>
                <Card>
                  <CardBody>
                    <div className="row">{posId && <PosCashierList posId={posId} />}</div>
                  </CardBody>
                </Card>
              </div>
            </Col>

            <Col md={5}>
              <div className="mb-5">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h5>
                    <i className="bi bi-credit-card-2-front fa-lg  text-theme"></i> طرق الدفع
                  </h5>
                  <button
                    type="button"
                    className="btn btn-outline-theme"
                    data-bs-toggle="modal"
                    data-bs-target="#ModalPosPaymentMethod">
                    {/* <i className="far fa-lg me-2 bi bi-list-stars"></i> */}
                    إضــافة طريقة دفع
                  </button>
                </div>
                <Card>
                  <CardBody>
                    <div className="row">{posId && <PosPaymentMethodList posId={posId} />}</div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </CardBody>

      <MedModalComponent title={'إضــافة كاشيــر'} id="ModalPosCashier">
        {posId && <PosCashier posId={posId} />}
      </MedModalComponent>

      <MedModalComponent title={'إضــافة طريقة دفـع'} id="ModalPosPaymentMethod">
        {posId && <PosPaymentMethod posId={posId} />}
      </MedModalComponent>
    </div>
  );
};

export default PosDetails;
