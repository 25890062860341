import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody } from '../../../../components/card/card';
import Loader from '../../../../components/Shared/Loader/Loader';
import Loading from '../../../../components/Shared/Loader/Loading';

const AmeRequests = ({ FormList }) => {
  const { t, i18n } = useTranslation();

  const Navigate = useNavigate();
  const { loading } = useSelector((a) => a.AmeRequest);

  return (
    <>
      <Card>
        <Loading loading={loading}>
          <CardBody className='p-4'>
            <div className="row">
              {FormList &&
                FormList?.map((item, index) => (
                  <div className="col-xl-3 col-md-6" key={item.id}>
                    <div className="mailbox-detail-content">
                      <div className="mailbox-attachment1 w-100">
                        <Link
                          onClick={() => Navigate(`/SelfService/Requests/${item.id}/AddRequest`)}
                          to="">
                          <div className="document-file1 h-100px">
                            <i
                              className={
                                item.icon
                                  ? item.icon.replace('fa-lg fa-fw me-2', '') + ' pt-2 '
                                  : 'bi bi-menu-up pt-2'
                              }></i>
                          </div>
                          <div className="document-name">
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </CardBody>
        </Loading>
      </Card>
    </>
  );
};

export default AmeRequests;
