 
 

 
  // @import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@500&family=Readex+Pro&display=swap');

export const theme = {
//   chakra : {
// fontfa
//   },

  colors: {
    textColor: "var(--bs-heading-color)",
    subtitleColor: "var(--bs-heading-color)",
    inactiveColor: "var(--bs-heading-color)",
    border: "var(--bs-theme)",
    background: "var(--bs-body-bg)",
    backgroundAlpha: "rgba(255,255,255,0)",
    secondaryBackground: "rgba(var(--bs-inverse-rgb), .15)",
    // secondaryBackground: "var(--bs-theme)",
    highlight: "var(--bs-theme)",
    // rsi: {
    //   50: "#E6E6FF",
    //   100: "#C4C6FF",
    //   200: "#A2A5FC",
    //   300: "#8888FC",
    //   400: "#7069FA",
    //   500: "#5D55FA",
    //   600: "#4D3DF7",
    //   700: "#3525E6",
    //   800: "#1D0EBE",
    //   900: "#0C008C",
    // },
  },
  shadows: {
    outline: 10,
  },
  components: {
    UploadStep: {
      baseStyle: {
        heading: {
          fontFamily : 'Noto Kufi Arabic',
          fontSize: "3xl",
          color: "var(--bs-heading-color)",
          mb: "1rem",
        },
        title: {
          fontFamily : 'Noto Kufi Arabic',
          fontSize: "2xl",
          lineHeight: 8,
          fontWeight: "semibold",
          color: "var(--bs-heading-color)",
        },
        subtitle: {
          fontFamily : 'Noto Kufi Arabic',
          fontSize: "md",
          lineHeight: 6,
          color: "var(--bs-heading-color)",
          mb: "1rem",
        },
        tableWrapper: {
          fontFamily : 'Noto Kufi Arabic',
          mb: "0.5rem",
          position: "static",
          // position: "relative",
          h: "100%",
        },
        dropzoneText: {
          fontFamily : 'Noto Kufi Arabic',
          size: "lg",
          lineHeight: 7,
          fontWeight: "semibold",
          color: "var(--bs-theme)",
        },
        dropZoneBorder: "var(--bs-theme)",
        dropzoneButton: {
          mt: "1rem",
        },
      },
    },
    SelectSheetStep: {
      baseStyle: {
        heading: {
          
          color: "textColor",
          mb: 8,
          fontSize: "3xl",
        },
        radio: {},
        radioLabel: {
          color: "textColor",
        },
      },
    },
    SelectHeaderStep: {
      baseStyle: {
        heading: {
          color: "var(--bs-heading-color)",
          mb: 8,
          fontSize: "3xl",
        },
      },
    },
 
    // MatchIcon: MatchIconTheme,
    // Steps: StepsComponent,
    Modal: {
      baseStyle: {
        dialog: {
          borderRadius: "md",
          bg: "background",
          fontSize: "lg",
          color: "var(--bs-heading-color)",
        },
        closeModalButton: {},
        backButton: {
          gridColumn: "1",
          gridRow: "1",
          justifySelf: "start",
        },
        continueButton: {
          gridColumn: "1 / 3",
          gridRow: "1",
          justifySelf: "center",
          background: "var(--bs-theme)",
        },
      },
      variants: {
        rsi: {
          header: {
            // bg: "secondaryBackground",
            bg: "var(--bs-theme)",
            px: "2rem",
            py: ".8rem",
          },
          body: {
            bg: "background",
            display: "flex",
            paddingX: "1rem",
            paddingY: "1rem",
            flexDirection: "column",
            flex: 1,
            overflow: "auto",
            height: "100%",
          },
          footer: {
            bg: "secondaryBackground",
            py: ".5rem",
            px: "1rem",
            justifyContent: "center",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "1fr",
            gap: "1rem",
          },
          dialog: {
            outline: "unset",
            minH: "calc(var(--chakra-vh) - 6rem)",
            maxW: "calc(var(--chakra-vw) - 6rem)",
            my: "2rem",
            borderRadius: "md",
            overflow: "hidden",
          },
        },
      },
    },


    Button: {
      defaultProps: {
        colorScheme: "rsi",
      },
    },
  },

  styles: {
    global: {
      // supporting older browsers but avoiding fill-available CSS as it doesn't work https://github.com/chakra-ui/chakra-ui/blob/073bbcd21a9caa830d71b61d6302f47aaa5c154d/packages/components/css-reset/src/css-reset.tsx#L5
      ":root": {
        "--chakra-vh": "100vh",
        "--chakra-vw": "100vw",
      },
      "@supports (height: 100dvh) and (width: 100dvw) ": {
        ":root": {
          "--chakra-vh": "100dvh",
          "--chakra-vw": "100dvw",
        },
      },
      ".rdg": {
        contain: "size layout style paint",
        borderRadius: "lg",
        border: "none",
        borderTop: "1px solid var(--rdg-border-color)",
        blockSize: "100%",
        flex: "1",

        // we have to use vars here because chakra does not autotransform unknown props
        "--rdg-row-height": "35px",
        "--rdg-color": "var(--bs-heading-color)",
        "--rdg-background-color": "var(--chakra-colors-background)",
        "--rdg-header-background-color": "var(--bs-theme)",
        "--rdg-row-hover-background-color": "var(--chakra-colors-background)",
        "--rdg-selection-color": "var(--chakra-colors-blue-400)",
        // "--rdg-row-selected-background-color": "var(--chakra-colors-red-500)",
        "--rdg-row-selected-background-color": "var(--bs-theme)",
        // "--row-selected-hover-background-color": "var(--chakra-colors-red-400)",
        "--row-selected-hover-background-color": "var(--bs-theme)",
        "--rdg-error-cell-background-color": "var(--chakra-colors-red-50)",
        "--rdg-warning-cell-background-color": "var(--chakra-colors-orange-50)",
        "--rdg-info-cell-background-color": "var(--chakra-colors-blue-50)",
        "--rdg-border-color": "var(--chakra-colors-border)",
        "--rdg-frozen-cell-box-shadow": "none",
        "--rdg-font-size": "var(--chakra-fontSizes-sm)",
      },
      ".rdg-header-row .rdg-cell": {
        color: "textColor",
        fontSize: "xs",
        fontFamily : 'Noto Kufi Arabic',
        lineHeight: 10,
        fontWeight: "bold",
        letterSpacing: "wider",
        textTransform: "uppercase",
        "&:first-of-type": {
          borderTopLeftRadius: "lg",
        },
        "&:last-child": {
          borderTopRightRadius: "lg",
        },
      },
      ".rdg-row:last-child .rdg-cell:first-of-type": {
        borderBottomLeftRadius: "lg",
      },
      ".rdg-row:last-child .rdg-cell:last-child": {
        borderBottomRightRadius: "lg",
      },
      ".rdg[dir='rtl']": {
        ".rdg-row:last-child .rdg-cell:first-of-type": {
          borderBottomRightRadius: "lg",
          borderBottomLeftRadius: "none",
        },
        ".rdg-row:last-child .rdg-cell:last-child": {
          borderBottomLeftRadius: "lg",
          borderBottomRightRadius: "none",
        },
      },
      ".rdg-cell": {
        contain: "size layout style paint",
        borderRight: "none",
        borderInlineEnd: "none",
        borderBottom: "1px solid var(--rdg-border-color)",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        "&[aria-selected='true']": {
          boxShadow: "inset 0 0 0 1px var(--rdg-selection-color)",
        },
        "&:first-of-type": {
          boxShadow: "none",
          borderInlineStart: "1px solid var(--rdg-border-color)",
        },
        "&:last-child": {
          borderInlineEnd: "1px solid var(--rdg-border-color)",
        },
      },
      ".rdg-cell-error": {
        backgroundColor: "#ff0000a3",
      },
      ".rdg-cell-warning": {
        backgroundColor: "var(--rdg-warning-cell-background-color)",
      },
      ".rdg-cell-info": {
        backgroundColor: "var(--rdg-info-cell-background-color)",
      },
      ".rdg-static": {
        cursor: "pointer",
      },
      ".rdg-static .rdg-header-row": {
        display: "none",
      },
      ".rdg-static .rdg-cell": {
        "--rdg-selection-color": "none",
      },
      ".rdg-example .rdg-cell": {
        "--rdg-selection-color": "none",
        borderBottom: "none",
      },

      ".rdg-radio": {
        display: "flex",
        alignItems: "center",
      },
      ".rdg-checkbox": {
        "--rdg-selection-color": "none",
        display: "flex",
        alignItems: "center",
      },
    },
  },
} as const

// export const rtlThemeSupport = {
//   components: {
//     Modal: {
//       baseStyle: {
//         dialog: {
//           direction: "rtl",
//         },
//       },
//     },
//   },
// } as const

// export type CustomTheme = DeepPartial<typeof themeOverrides>