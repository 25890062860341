import { defaultCombinators} from 'react-querybuilder';

 const  CustomCombinators  ={
    "en":defaultCombinators,
    "ar":[
      {name: "and", label : "و"},
      {name: "or", label : "أو"}
    ]
      
    }
  export  default   CustomCombinators  ;


