import React from 'react';
import { useTranslation } from 'react-i18next';

const HistoryList = ({ List }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      {/* <div className="table-responsive   bg-inverse bg-opacity-10 rounded-4"> */}
      <div className="table-responsive  rounded-2 text-center">
        <table className="table table-hover text-nowrap mb-0 ">
          <thead className="bg-theme  ">
            <tr>
              <th>{t('Name')}</th>
              <th>{t('Date')}</th>
              <th>{t('Procedure')}</th>
              <th>{t('Comments')}</th>
            </tr>
          </thead>

          <tbody>
            {List &&
              List.map((item, idx) => (
                <tr key={idx++}>
                  <td className="align-middle">
                    {i18n.language === 'ar' ? item.name2 : item.name}
                  </td>
                  <td className="align-middle">{item.date}</td>
                  {/* <td className="align-middle">{item.action}</td> */}

                  <td className="align-middle">
                    {item.action === 'CREATED' ? (
                      <span className=" badge border border-primary  text-primary  px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ">
                        <i className="fa fa-circle fs-15px fa-fw me-5px"></i>
                        {t('CREATED')}
                      </span>
                    ) : item.action === 'QUESTION' ? (
                      <span className="badge border border-indigo text-indigo fw-bold px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        <i className="bi bi-question-circle-fill fs-18px fa-fw me-5px"></i>
                        {t('QUESTION')}
                      </span>
                    ) : item.action === 'ANSWER' ? (
                      <span className="badge border border-purple text-purple px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        <i className="bi bi-reply-fill fs-18px fa-fw me-5px"></i>
                        {t('ANSWER')}
                      </span>
                    ) : item.action === 'TRANSFER' ? (
                      <span className="badge border border-warning text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        <i className="bi bi-sign-turn-right-fill fs-18px fa-fw me-5px"></i>
                        {t('TRANSFER')}
                      </span>
                    ) : item.action === 'REJECTED' ? (
                      <span className="badge border border-danger  text-danger  px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        <i className="bi bi-x-circle-fill fs-18px fa-fw me-5px"></i>
                        {t('REJECTED')}
                      </span>
                    ) : item.action === 'APPROVED' ? (
                      <span className="badge border border-success  text-success  px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        <i className="bi bi-check-square-fill fs-18px fa-fw me-5px"></i>
                        {t('APPROVED')}
                      </span>
                    ) : item.action === 'PENDING' ? (
                      <span className="badge border border-warning  text-warning  px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        <i className="bi-hourglass-split fs-18px fa-fw me-5px"></i>
                        {t('PENDING')}
                      </span>
                    ) : item.action === 'WAITING' ? (
                      <span className="badge border border-secondary  text-secondary  px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        <i className="bi bi-clock-history fs-18px fa-fw me-5px"></i>
                        {t('WAITING')}
                      </span>
                    ) : item.action === 'WAITING_NOTF' ? (
                      <span className="badge border border-secondary   text-secondary   px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        <i className="bi bi-bell text-theme  fs-18px fa-fw me-5px"></i>
                        {t('WAITING_NOTF')}
                      </span>
                    ) : item.action === 'NOTIFIED' ? (
                      <span className=" badge border border-info   text-info   px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        <i className="bi bi-bell fs-18px fa-fw me-5px"></i>
                        {t('NOTIFIED')}
                      </span>
                    ) : item.action === 'DELEGATE' ? (
                      <span className=" badge border border-info  text-info   px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                         <i className="bi bi-person-fill fs-18px fa-fw me-5px"></i>
                         <i className="bi bi-arrow-right fs-18px fa-fw me-5px"></i>
                        {t('Delegated')}
                      </span>
                    ) : (
                      ''
                    )}
                  </td>

                  <td className="align-middle">{item.userComment}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HistoryList;
