import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../../API/axiosNeatex';
import { InvMoveModels } from './invMoveModels';
import { TransactionTypesModels } from '../SharedModels';


export const addMoveTransactions = createAsyncThunk(
  'InvMove/addMoveTransactions',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('InvMove/AddMoveTransactions', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateMoveTransactions = createAsyncThunk(
  'InvMove/updateMoveTransactions',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('InvMove/UpdateMoveTransactions', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTransactionsMoveList = createAsyncThunk(
  'InvMove/getTransactionsMoveList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('InvMove/GetTransactionsMoveList');
      if (data?.succeeded) {
        //===
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTransactionTypesMoveList = createAsyncThunk(
  'InvMove/getTransactionTypesMoveList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('InvMove/GetTransactionTypesMoveList');
      if (data?.succeeded) {
        //===
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getMoveTransactionsById = createAsyncThunk(
  'InvMove/getMoveTransactionsById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`InvMove/GetMoveTransactionsById?Id=${id}`);
      if (data?.succeeded) {
        // ===
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  InvMoveList: InvMoveModels[];
  TransactionTypesMoveList: TransactionTypesModels[];
  //   Transactions ById: InvMoveByIdModels;
  loading: boolean;
  error: any | null;
} = {
  InvMoveList: [],
  TransactionTypesMoveList: [],
  //   Transactions ById: {} as InvMoveByIdModels,
  loading: false,
  error: null,
};

const InvMoveSlice = createSlice({
  name: 'InvMove',
  initialState,
  reducers: {
    // cleanUpTransactionsQuotationById: (state) => {
    //   state.TransactionsQuotationById = {} as InvMoveByIdModels;
    // },
  },
  extraReducers(builder) {
    builder

      .addCase(getTransactionsMoveList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getTransactionsMoveList.fulfilled,
        (state, action: PayloadAction<InvMoveModels[]>) => {
          state.loading = false;
          state.InvMoveList = action.payload;
        }
      )
      .addCase(getTransactionsMoveList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getTransactionTypesMoveList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getTransactionTypesMoveList.fulfilled,
        (state, action: PayloadAction<TransactionTypesModels[]>) => {
          state.loading = false;
          state.TransactionTypesMoveList = action.payload;
        }
      )
      .addCase(getTransactionTypesMoveList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getMoveTransactionsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getMoveTransactionsById.fulfilled, (state, action: PayloadAction) => {
        state.loading = false;
        //   state.TransactionsQuotationById = action.payload;
      })
      .addCase(getMoveTransactionsById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// export const { cleanUpTransactionsQuotationById } = InvMoveSlice.actions;
export default InvMoveSlice.reducer;
