import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { ntxAPI } from "../../../../API/axiosNeatex";
import SkeletonLoader from "../../../../components/Shared/SkeletonLoader/SkeletonLine";

const TypeD = ({ item, handleSelectChange }) => {
  const { t, i18n } = useTranslation();

    const [valueSetList , setValueSetList] = useState([])

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
      async function GetTableData() {
        try {
          const { data } = await ntxAPI.get(
            `FlexValueSet/GetFlexValueList?FlexValueSetId=${item.flexValueSetId}`
          );
          let DataList = data.map((item) => ({
            value: item.id,
            label: i18n.language === "ar" ? item.name2 : item.name,
          }));
          
          setValueSetList(DataList);
          setIsLoading(false);  
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);  
        }
      }
  
      GetTableData();
    }, [item.flexValueSetId]);
    

  return (
    <>
      <div className="form-group mb-3">
        <label className="form-label">
          {i18n.language === "ar" ? item.lable2 : item.lable}
        </label>
        <div className="row" >
                  {isLoading ? ( 
          <SkeletonLoader/>
        ) : (
          valueSetList?.length > 0 && (
        <Select
          classNamePrefix="react-select"
          isSearchable={true}
          options={valueSetList}
          name={item.id}
          required={item.requiredFlag}
          onChange={handleSelectChange}
          placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
        />
        )
        )}
        </div>

      </div>
    </>
  );
};

export default TypeD;
