import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../../API/axiosNeatex';
import { ApBankAccountsModels } from './ApBankAccountsModels';
import { APIResponse } from '../../../Shared/shared';

export const addApBankAccounts = createAsyncThunk(
  'ApBankAccounts/addApBankAccounts',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('Ap/AddApBankAccounts', item);
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateApBankAccounts = createAsyncThunk(
  'ApBankAccounts/updateApBankAccounts',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('Ap/UpdateApBankAccounts', item);
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getApBankAccountsList = createAsyncThunk(
  'ApBankAccounts/getApBankAccountsList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ApBankAccountsModels[]>>(
        'Ap/GetApBankAccountsList'
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getApBankAccountsByNtxBankId = createAsyncThunk(
  'ApBankAccounts/getApBankAccountsByNtxBankId',
  async (NtxBankId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ApBankAccountsModels>>(
        `Ap/GetApBankAccountsByNtxBankId?NtxBankId=${NtxBankId}`
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getApBankAccountsById = createAsyncThunk(
  'ApBankAccounts/getApBankAccountsById',
  async (Id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ApBankAccountsModels>>(
        `Ap/GetApBankAccountsById?Id=${Id}`
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  ApBankAccountsList: ApBankAccountsModels[];
  ApBankAccountsById: ApBankAccountsModels;
  ApBankAccountsByNtxBankId: ApBankAccountsModels;
  loading: boolean;
  error: any | null;
} = {
  ApBankAccountsList: [],
  ApBankAccountsById: {} as ApBankAccountsModels,
  ApBankAccountsByNtxBankId: {} as ApBankAccountsModels, //
  loading: false,
  error: null,
};

const ApBankAccountsSlice = createSlice({
  name: 'ApBankAccounts',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(getApBankAccountsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getApBankAccountsList.fulfilled,
        (state, action: PayloadAction<ApBankAccountsModels[]>) => {
          state.loading = false;
          state.ApBankAccountsList = action.payload;
        }
      )
      .addCase(getApBankAccountsList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getApBankAccountsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getApBankAccountsById.fulfilled,
        (state, action: PayloadAction<ApBankAccountsModels>) => {
          state.loading = false;
          state.ApBankAccountsById = action.payload;
        }
      )
      .addCase(getApBankAccountsById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getApBankAccountsByNtxBankId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getApBankAccountsByNtxBankId.fulfilled,
        (state, action: PayloadAction<ApBankAccountsModels>) => {
          state.loading = false;
          state.ApBankAccountsByNtxBankId = action.payload;
        }
      )
      .addCase(getApBankAccountsByNtxBankId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default ApBankAccountsSlice.reducer;
