import React, { useState } from 'react'
import NeatixModal from '../../../components/Modal/NeatixModal'
import CloseSession from '../PosCard/CloseSession'
import { Card, CardBody } from '../../../components/card/card';
import { Link, useNavigate } from 'react-router-dom';

type Props ={
  handleDeleteOrder : () => void
}

const DropdownMenu = ({handleDeleteOrder}: Props) => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
  return (
    <>
   

    <div className="">
					<Link to="" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link fs-3" >
						<div className="menu-icon"><i className="bi bi-grid-3x3-gap nav-icon"></i></div>
					</Link>


					<div className="dropdown-menu fade dropdown-menu-end w-100 text-center">
						<div className="row row-grid gx-0">
							<div className="col-4">
								<Link to="" className="dropdown-item text-decoration-none p-3 bg-none" onClick={handleDeleteOrder}>
									<div className="position-relative">
										<i className="bi bi-x-circle h2 opacity-5 d-block my-1"></i>
									</div>
									<div className="fw-500 fs-12px text-danger">إلغاء هذه الفاتورة</div>
								</Link>
							</div>

							<div className="col-4">
								<Link to="" className="dropdown-item text-decoration-none p-3 bg-none" onClick={()=> setShow(true)}>
									<div><i className="bi bi-door-closed h2 opacity-5 d-block my-1"></i></div>
									<div className="fw-500 fs-12px text-inverse">إغلاق الجلسة </div>
								</Link>
							</div>

							<div className="col-4">
								<Link to="" className="dropdown-item text-decoration-none p-3 bg-none" onClick={()=> navigate(-1)}>
									<div><i className="fa fa-arrow-left h2 opacity-5 d-block my-1"> </i></div>
									<div className="fw-500 fs-12px text-inverse"> القائمة الرئيسية</div>
								</Link>
							</div>
						</div>


 
					</div>
				</div>


<NeatixModal setShow={setShow} show={show} size={"lg"} showHeader={false} bodyClassName="p-0"  >
    <Card>
<CardBody >
    <CloseSession/>

</CardBody>
    </Card>
</NeatixModal>

    </>
  )
}

export default DropdownMenu



// <div className="dropdown text-end">
// <a href="#/" data-bs-toggle="dropdown" className="text-inverse text-opacity-50"><i className="fa fa-ellipsis-h fs-5 fw-bold"></i></a>

//     {/* <button type='button' className='menu-toggler'>
// <i className="fa fa-ellipsis-h"></i>
//     </button> */}
//     <div className="dropdown-menu dropdown-menu-end">
           
//         <Link to="" className="dropdown-item d-flex align-items-center" onClick={()=> navigate(-1)}><i className="fa fa-arrow-left fs-14px my-n1 me-3 ms-n2"></i> رجوع للقائمة الرئيسية </Link>
//         <div className="dropdown-divider"></div>
//         <Link to="" className="dropdown-item d-flex align-items-center text-danger" onClick={()=> setShow(true)}><i className="bi bi-door-closed fw-bold fs-14px my-n1 me-3 ms-n2"></i> إغلاق الجلسة </Link>
       
   

//     </div>
// </div>