import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Pagination from '../../../../../components/Shared/Pagination/Pagination';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';


interface props {
  setID: React.Dispatch<React.SetStateAction<string>>; // For Update
}

const SubInventoryList: React.FC<props> = (props) => {
  const { Id } = useParams();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(5);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  // const Navigate = useNavigate();


  const { SubInventoryListByParentId } = useAppSelector((state) => state.SubInventory);

  const filteredSearch =
    SubInventoryListByParentId?.slice()?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name2?.toLowerCase()?.includes(searchLowerCase) ||
        item.name?.toLowerCase()?.includes(searchLowerCase)
      );
    }) ?? [];

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice?.(indexOfFirstData, indexOfLastData);
  return (
    <div>
      {SubInventoryListByParentId?.length > 0 && (
        <Fragment>
          <div className="table-responsive rounded-2 text-center  mb-2">
            <table className="table table-hover text-nowrap mb-0">
              <thead className="bg-theme bg-opacity-60">
                <tr className="fw-bold text-danger">
                  <th scope="col">{t('Name')}</th>
                  <th scope="col">{t('Code')}</th>
                  <th scope="col">{t('Note')}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {currentData?.map((item, index) => (
                  <tr key={item.id}>
                    <td className="align-middle">
                      {i18n.language === 'en' ? item.name : item.name2}
                    </td>
                    <td className="align-middle">{item.code}</td>
                    <td className="align-middle">{item.description}</td>
                    <td className="align-middle text-end">
                      <button
                        type="button"
                        className="btn btn-outline-theme"
                        data-bs-toggle="modal"
                        data-bs-target="#EditSubInventory"
                        onClick={() => props.setID(item.id || '')}>
                        <i className="far fa-lg me-2 fa-fw fa-edit"></i>
                        {t('Button.Edit')}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="me-3 pb-2 ms-2">
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default SubInventoryList;
