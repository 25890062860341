import React from 'react'
import NtxPeoples from '../../../NTX/Peoples/NtxPeoples'

const AddOwners = () => {


  return (
    <div>
      <NtxPeoples typeCode='OWNERS' isUpdate={false} />
    </div>
  )
}

export default AddOwners
