import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DeleteAbsenceAttendance,
  GetAbsenceAttendance,
} from '../../../../store/HR/Person/absenceSlice';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { usePermissions } from '../../../../Helper/Permisstions/usePermissions';
import showConfirmation from '../../../../components/Shared/Alert/Confirmation';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import ViewAbsence from './ViewAbsence/ViewAbsence';

function AbsenceList({ data }) {
  const { HasPermission } = usePermissions();
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const [absence, setAbsence] = useState();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { personId } = useParams();

  // Handele Delete AmeCondition
  const DeleteAbsence = (item) => {
    showConfirmation(t('MsgConfirm'), () => {
      dispatch(DeleteAbsenceAttendance(item.id))
        .unwrap()
        .then(() => {
          dispatch(GetAbsenceAttendance(personId));
        })
        .catch((error) => {
          CustomAlert({ action: 'Delete' });
          console.log(error);
        });
    });
  };

  // console.log(absence);
  console.log(show);

  return (
    <>
      <div className=" table-responsive rounded-2 text-center border">
        <table className="table table-hover text-nowrap mb-0">
          <thead className="table-dark">
            <tr>
              <th scope="col">{t('Absence_Type')}</th>
              <th scope="col">{t('Start Date')}</th>
              <th scope="col">{t('End_Date')}</th>
              <th scope="col">{t('NumberOfDays')}</th>
              <th scope="col"></th>
            </tr>
          </thead>

          <tbody>
            {data.map((item, idx) => (
              <tr key={item.id}>
                <td className="align-middle">
                  {i18n.language === 'ar' ? item.absenceName2 : item.absenceName}
                </td>
                <td className="align-middle">{item.dateStart}</td>
                <td className="align-middle">{item.dateEnd}</td>
                <td className="align-middle">{item.absenceDays}</td>
                <td className="align-middle text-end">
                  {/* <div className="btn-group"> */}
                  {HasPermission('DeleteHrPerAbsence') && (
                    <>
                      <button
                        type="button"
                        className="btn btn-outline-danger  "
                        onClick={() => DeleteAbsence(item)}>
                        <i className="fas fa-trash-alt fa-fw fa-lg"></i>
                        {t('Button.Delete')}
                      </button>
                    </>
                  )}
                  {HasPermission('ViewHrPerAbsence') && (
                    <button
                      // onClick={() => Navigate(`/HR/Employees/absence/${item.id}/ViewAbsence`)}
                      onClick={() => {
                        setAbsence(item);
                        setShow(true);
                      }}
                      type="button"
                      className="btn btn-outline-theme ms-1">
                      <i className="fas fa-lg fa-fw fa-info-circle me-2"></i>
                      {t('Show')}
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <NeatixModal show={show} setShow={setShow} bodyClassName="p-0" size='md' >
        {absence && <ViewAbsence isAme={false} ReferenceId={absence.id} />}
      </NeatixModal>
    </>
  );
}

export default AbsenceList;
