import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import useGetExtraInformationValue from '../../../../hooks/ExtraInformation/useGetExtraInformationValue';
import TypeD from './TypeD';
import TypeT from './TypeT';
import FileList from '../../../Attachments/FileList';
// import Loader from "../../../../components/Loader/Loader";
import TypeL from './TypeL';
import Loading from '../../../../components/Shared/Loader/Loading';

const ExtraInformationBuilderUpdate = (props) => {
  const { extraInformationId, referenceId, setExtraInfoFormData } = props;

  const { i18n } = useTranslation();
  const [formData, setFormData] = useState({});
  const [attachment, setAttachment] = useState({});

  //_______________________________________________________________________________________________________

  const {
    ExtraInformationWithValueById: data,
    loading,
    error,
  } = useGetExtraInformationValue(extraInformationId);
  // formData استخراج البيانات واضافتها الى
  useEffect(() => {
    const extractData = (dataList) => {
      const extractedData = {};
      if (Array.isArray(dataList))
        dataList.forEach((item) => {
          if (item.dataType === 'ATTACHED') {
            extractedData[item.dynamic_form_column_id] = item.attachment;
          } else {
            extractedData[item.dynamic_form_column_id] = item.value;
          }
        });
      return extractedData;
    };
    const extractedValues = extractData(data.values);
    setFormData(extractedValues);
  }, [data]);

  // attachment استخراج المرفقات ان وجدت واضافتها الى
  useEffect(() => {
    const extractAttachment = (dataList) => {
      const attach = {};
      if (Array.isArray(dataList))
        dataList.forEach((item) => {
          if (item.dataType === 'ATTACHED') {
            attach[item.dynamic_form_column_id] = item.attachment;
          }
        });
      return attach;
    };
    const extractedAttachments = extractAttachment(data.values);
    setAttachment(extractedAttachments);
  }, [data]);

  //________________________________________________________________________________________________________

  const sortedList = Array.isArray(data?.values)
    ? data?.values?.slice().sort((a, b) => a.sequnce - b.sequnce)
    : [];

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    const value = selectedOption ? selectedOption.value : null;
    setFormData({ ...formData, [name]: value });
  };

  const handleInputChange = (e, selectedOption) => {
    const { name, type, files, value, checked } = e.target;

    if (type === 'checkbox') {
      const checkboxValue = checked ? '1' : '0';
      setFormData({ ...formData, [name]: checkboxValue });
    } else if (type === 'file') {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    if (Array.isArray(data?.values)) {
      const updatedData = {
        dynamic_form_id: data?.dynamicFormId,
        extra_information_id: extraInformationId,
        reference_id: referenceId,
        module_id: data?.moduleId,
        values: data?.values?.map((item) => ({
          dynamic_form_column_id: item.dynamic_form_column_id,
          data_type: item.dataType,

          value:
            item.dataType === 'BOOLEAN'
              ? formData[item.dynamic_form_column_id] === '1'
                ? '1'
                : '0'
              : item.dataType === 'ATTACHED'
              ? null
              : formData[item.dynamic_form_column_id] || '',

          lable_text: i18n.language === 'ar' ? item.lableText : item.lableText,
          lable_text2: i18n.language === 'ar' ? item.lableText2 : item.lableText2,
          id: item.id === null ? '' : item.id,

          fileAttachment:
            item.dataType === 'ATTACHED' && formData[item.dynamic_form_column_id]
              ? formData[item.dynamic_form_column_id]
              : null,
          // isUpdateAttach : (item.dataType === 'ATTACHED' && formData[item.dynamic_form_column_id]) ? true : false
          isUpdateAttach:
            item.dataType === 'ATTACHED' && formData[item.dynamic_form_column_id] ? true : false,
        })),
      };
      setExtraInfoFormData(updatedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);
  return (
    <>
      <Loading loading={loading} error={error}>
        <div className="row">
          {Array.isArray(data.values) &&
            // eslint-disable-next-line array-callback-return
            sortedList.map((item, index) => {
              // eslint-disable-next-line default-case
              switch (item.dataType) {
                case 'DATE':
                  return (
                    <div key={item.dynamic_form_column_id} className="col-xl-6">
                      <div className="form-group mb-3">
                        <label className="form-label">
                          {i18n.language === 'ar' ? item.lableText2 : item.lableText}
                        </label>
                        <Form.Control
                          className="form-control"
                          required={item.requiredFlag}
                          type="Date"
                          name={item.dynamic_form_column_id}
                          value={formData[item.dynamic_form_column_id] || ''}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  );
                case 'NUMBER':
                  return (
                    <div key={item.dynamic_form_column_id} className="col-xl-6">
                      <div className="form-group mb-3">
                        <label className="form-label">
                          {i18n.language === 'ar' ? item.lableText2 : item.lableText}
                        </label>
                        <Form.Control
                          className="form-control"
                          required={item.requiredFlag}
                          type="number"
                          name={item.dynamic_form_column_id}
                          value={formData[item.dynamic_form_column_id] || ''}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  );
                case 'TEXT':
                  return (
                    <div key={item.dynamic_form_column_id} className="col-xl-6">
                      <div className="form-group mb-3">
                        <label className="form-label">
                          {i18n.language === 'ar' ? item.lableText2 : item.lableText}
                        </label>
                        <Form.Control
                          className="form-control"
                          required={item.requiredFlag}
                          type="text"
                          name={item.dynamic_form_column_id}
                          value={formData[item.dynamic_form_column_id] || ''}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  );
                case 'TIME':
                  return (
                    <div key={item.dynamic_form_column_id} className="col-xl-6">
                      <div className="form-group mb-3">
                        <label className="form-label">
                          {i18n.language === 'ar' ? item.lableText2 : item.lableText}
                        </label>
                        <Form.Control
                          className="form-control"
                          required={item.requiredFlag}
                          type="time"
                          name={item.dynamic_form_column_id}
                          value={formData[item.dynamic_form_column_id] || ''}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  );
                case 'ATTACHED':
                  return (
                    <div key={item.dynamic_form_column_id} className="col-xl-6">
                      <div className="form-group mb-3 bg-inverse bg-opacity-10 rounded-4 p-2">
                        <label className="form-label">
                          {i18n.language === 'ar' ? item.lableText2 : item.lableText}
                        </label>
                        <Form.Control
                          className="form-control"
                          required={item.requiredFlag}
                          type="file"
                          name={item.dynamic_form_column_id}
                          onChange={handleInputChange}
                          multiple
                        />
                        <FileList Attachments={attachment[item.dynamic_form_column_id]} />
                      </div>
                    </div>
                  );
                case 'BOOLEAN':
                  return (
                    <div key={item.dynamic_form_column_id} className="col-xl-6">
                      <div className="form-group mb-3">
                        <label className="form-label">
                          {i18n.language === 'ar' ? item.lableText2 : item.lableText}
                        </label>

                        <Form.Check
                          style={{ margin: '10px 20px' }}
                          type="switch"
                          name={item.dynamic_form_column_id}
                          checked={formData[item.dynamic_form_column_id] === '1'}
                          // defaultChecked={formData[item.dynamic_form_column_id] === "1" ? true : false }
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  );
                case 'T':
                  return (
                    // <Suspense key={item.id} fallback={<div className="spinner-grow spinner-grow-sm"></div>}>
                    <div key={item.dynamic_form_column_id} className="col-xl-6">
                      {formData[item.dynamic_form_column_id] !== undefined && (
                        <TypeT
                          key={item.dynamic_form_column_id}
                          item={item}
                          handleSelectChange={handleSelectChange}
                          value={formData[item.dynamic_form_column_id]}
                        />
                      )}
                    </div>
                    // </Suspense>
                  );
                case 'D':
                  return (
                    <div key={item.dynamic_form_column_id} className="col-xl-6">
                      {formData[item.dynamic_form_column_id] !== undefined && (
                        <TypeD
                          key={item.dynamic_form_column_id}
                          item={item}
                          handleSelectChange={handleSelectChange}
                          value={formData[item.dynamic_form_column_id]}
                        />
                      )}
                    </div>
                  );
                case 'L':
                  return (
                    <div key={item.dynamic_form_column_id} className="col-xl-6">
                      {formData[item.dynamic_form_column_id] !== undefined && (
                        <TypeL
                          key={item.dynamic_form_column_id}
                          item={item}
                          handleSelectChange={handleSelectChange}
                          value={formData[item.dynamic_form_column_id]}
                        />
                      )}
                    </div>
                  );
              }
            })}
        </div>
      </Loading>
    </>
  );
};

export default ExtraInformationBuilderUpdate;
