import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../../API/axiosNeatex';
import { InvIssueModels } from './invIssueModels';
import { TransactionTypesModels } from '../SharedModels';

export const addIssueTransactions = createAsyncThunk(
  'InvIssue/addIssueTransactions',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('InvIssue/AddIssueTransactions', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateIssueTransactions = createAsyncThunk(
  'InvIssue/updateIssueTransactions',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('InvIssue/UpdateIssueTransactions', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTransactionsIssueList = createAsyncThunk(
  'InvIssue/getTransactionsIssueList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('InvIssue/GetTransactionsIssueList');
      if (data?.succeeded) {
        //===
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTransactionTypesIssueList = createAsyncThunk(
  'InvIssue/getTransactionTypesIssueList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('InvIssue/GetTransactionTypesIssueList');
      if (data?.succeeded) {
        //===
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getIssueTransactionsById = createAsyncThunk(
  'InvIssue/getIssueTransactionsById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`InvIssue/GetIssueTransactionsById?Id=${id}`);
      if (data?.succeeded) {
        // ===
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  InvIssueList: InvIssueModels[];
  TransactionTypesIssueList: TransactionTypesModels[];
  //   Transactions ById: InvIssueByIdModels;
  loading: boolean;
  error: any | null;
} = {
  InvIssueList: [],
  TransactionTypesIssueList: [],
  //   Transactions ById: {} as InvIssueByIdModels,
  loading: false,
  error: null,
};

const InvIssueSlice = createSlice({
  name: 'InvIssue',
  initialState,
  reducers: {
    // cleanUpTransactionsQuotationById: (state) => {
    //   state.TransactionsQuotationById = {} as InvIssueByIdModels;
    // },
  },
  extraReducers(builder) {
    builder

      .addCase(getTransactionsIssueList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getTransactionsIssueList.fulfilled,
        (state, action: PayloadAction<InvIssueModels[]>) => {
          state.loading = false;
          state.InvIssueList = action.payload;
        }
      )
      .addCase(getTransactionsIssueList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getTransactionTypesIssueList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getTransactionTypesIssueList.fulfilled,
        (state, action: PayloadAction<TransactionTypesModels[]>) => {
          state.loading = false;
          state.TransactionTypesIssueList = action.payload;
        }
      )
      .addCase(getTransactionTypesIssueList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getIssueTransactionsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getIssueTransactionsById.fulfilled, (state, action: PayloadAction) => {
        state.loading = false;
        //   state.TransactionsQuotationById = action.payload;
      })
      .addCase(getIssueTransactionsById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// export const { cleanUpTransactionsQuotationById } = InvIssueSlice.actions;
export default InvIssueSlice.reducer;
