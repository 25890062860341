import React, { useState } from 'react';
import useGetOrganizations from '../../../../../hooks/HR/use-get-organizations';
import Select from 'react-select';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import { useAppDispatch } from '../../../../../store/hooks';
import { assignmentItemToMultiInventories } from '../../../../../store/INV/AssignmentItems/assignmentItemsSlice';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
//

const AssignmentItemToMultiInventories = () => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useAppDispatch();
  const [InventoryIds, setInventoryIds] = useState<any[]>([]);
  const { InventoryOptions } = useGetOrganizations();
  const { ItemId } = useParams();

  const onSubmit = () => {
    if (InventoryIds.length === 0) {
      CustomAlert({ action: 'info', msg: 'يرجى تحديـد الأصناف' });
      return;
    }
    setIsSubmitting(true);

    let body = {
      inventoryIds: InventoryIds,
      itemId: ItemId,
    };

    dispatch(assignmentItemToMultiInventories(body))
      .unwrap()
      .then(() => {
        setIsSubmitting(false);
        CustomAlert({ action: 'Add' });
      })
      .catch((error: any) => {
        setIsSubmitting(false);

        CustomAlert({ action: 'Error' });
      });
  };

  return (
    <div>
      <div className="col-12">
        <label className="form-label">المخازن</label>
        <div className="input-group flex-nowrap">
          <Select
            // isDisabled={!formData.Notification}
            classNamePrefix="react-select"
            className=" w-100"
            required
            isMulti
            options={InventoryOptions}
            // value={optionUsers.filter((option) => formData?.userIds?.includes(option.value))}
            onChange={(e) => {
              setInventoryIds(e.map((m: any) => m.value));
            }}
          />
        </div>
      </div>
      <div className="text-end mt-3">
        <button
          disabled={isSubmitting}
          onClick={() => onSubmit()}
          type="submit"
          className="btn me-1 btn-theme mb-1"
          id="SaveSubmit">
          {isSubmitting ? (
            <div className="spinner-border spinner-border-sm me-2"></div>
          ) : (
            // <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            <i className="fa fa-save btn-lg fa-fw me-1"></i>
          )}
          {t('Save')}
        </button>
      </div>
    </div>
  );
};

export default AssignmentItemToMultiInventories;
