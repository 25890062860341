import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { MasterItemsModels, UnitsOfMeasureModels } from './MasterItemsModels';

export const AddMasterItemsINV = createAsyncThunk(
  'MasterItemsINV/AddMasterItems',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/AddMasterItems', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdatMasterItems = createAsyncThunk(
  'MasterItemsINV/UpdatMasterItems',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/UpdatMasterItems', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetMasterItemsList = createAsyncThunk(
  'MasterItemsINV/GetMasterItemsList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('Inv/GetMasterItemsList');
      if (data.succeeded === true) {
        return data.data;
      } else {
        return [];
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetMasterItemsById = createAsyncThunk(
  'MasterItemsINV/GetMasterItemsById',
  async (ItemId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`Inv/GetMasterItemsById?Id=${ItemId}`);
      if (data.succeeded === true) {
        return data.data;
      } else {
        return [];
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetMasterItemsListByInventoryId = createAsyncThunk(
  'MasterItemsINV/GetMasterItemsListByInventoryId',
  async (InventoryId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(
        `Inv/GetMasterItemsListByInventoryId?InventoryId=${InventoryId}`
      );

      if (data.succeeded) {
        return data.data;
      } else {
        return [];
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getInventoriesListByItemId = createAsyncThunk(
  'MasterItemsINV/getInventoriesListByItemId',
  async (InventoryId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(
        `Inv/GetInventoriesListByItemId?ItemId=${InventoryId}`
      );

      if (data.succeeded) {
        return data.data;
      } else {
        return [];
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);



const initialState: {
  MasterItemsList: MasterItemsModels[];
  MasterItemsById: MasterItemsModels;
  MasterItemsListByInventoryId: MasterItemsModels[];
  UnitsOfMeasureList: UnitsOfMeasureModels[];
  InventoriesListByItemId?: [];
  loading: boolean;
  error: any | null;
} = {
  MasterItemsList: [],
  MasterItemsById: {} as MasterItemsModels,
  MasterItemsListByInventoryId: [],
  UnitsOfMeasureList: [],
  loading: false,
  error: null,
};

const MasterItemsSlice = createSlice({
  name: 'MasterItemsINV',
  initialState,
  reducers: {
    cleanUpMasterItemsListByInventoryId: (state) => {
      state.MasterItemsListByInventoryId = [] ;
    },
  },
  extraReducers(builder) {
    builder

      .addCase(AddMasterItemsINV.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddMasterItemsINV.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        if (action?.payload?.succeeded === true) {
          state.MasterItemsList.push(action.payload.data);
        }
      })
      .addCase(AddMasterItemsINV.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(GetMasterItemsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetMasterItemsList.fulfilled,
        (state, action: PayloadAction<MasterItemsModels[]>) => {
          state.loading = false;
          state.MasterItemsList = action.payload;
        }
      )
      .addCase(GetMasterItemsList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(GetMasterItemsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetMasterItemsById.fulfilled, (state, action: PayloadAction<MasterItemsModels>) => {
        state.loading = false;
        state.MasterItemsById = action.payload;
      })
      .addCase(GetMasterItemsById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(GetMasterItemsListByInventoryId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetMasterItemsListByInventoryId.fulfilled,
        (state, action: PayloadAction<MasterItemsModels[]>) => {
          state.loading = false;
          if (action.payload === null) {
            state.MasterItemsListByInventoryId = [];
          } else {
            state.MasterItemsListByInventoryId = action.payload;
          }
        }
      )
      .addCase(GetMasterItemsListByInventoryId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });

    //  .addCase(GetUnitsOfMeasureList.pending, (state, action) => {
    //     state.loading = true;
    //   })
    //   .addCase(GetUnitsOfMeasureList.fulfilled, (state, action: PayloadAction<UnitsOfMeasureModels[]> ) => {
    //     state.loading = false;
    //     state.UnitsOfMeasureList = action.payload;
    //   })
    //   .addCase(GetUnitsOfMeasureList.rejected, (state, action: PayloadAction<any>) => {
    //     state.loading = false;
    //     state.error = action.payload;
    //   })
  },
});

export const {cleanUpMasterItemsListByInventoryId} = MasterItemsSlice.actions
export default MasterItemsSlice.reducer;
