import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { ItemConversionModels } from './MasterItemsModels';

export const AddItemConversion = createAsyncThunk(
  'ItemConversion/AddItemConversion',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/AddItemConversion', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetItemConversionList = createAsyncThunk(
  'ItemConversion/GetItemConversionList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('Inv/GetItemConversionList');
      if (data.succeeded === true) {
        return data.data;
      } else {
        return rejectWithValue(data?.message || "هناك خطأ");
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetItemConversionByItemId = createAsyncThunk(
  'ItemConversion/GetItemConversionByItemId',
  async (ItemId: string, { rejectWithValue }) => {
    try {
      //
      const { data } = await ntxAPI.get(`Inv/GetItemConversionByItemId?ItemId=${ItemId}`);
      if (data.succeeded === true) {
        return data.data;
      } else {
        return rejectWithValue(data?.message || "هناك خطأ");
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  ItemConversionListByItem: ItemConversionModels[];
  ItemConversionList: ItemConversionModels[];
  loading: boolean;
  error: any | null;
} = {
  ItemConversionListByItem: [],
  ItemConversionList: [],
  loading: false,
  error: null,
};

const ItemConversionSlice = createSlice({
  name: 'ItemConversion',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(AddItemConversion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddItemConversion.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        if (action?.payload?.succeeded === true) {
          state.ItemConversionList.push(action.payload.data);
        }
      })
      .addCase(AddItemConversion.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(GetItemConversionList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetItemConversionList.fulfilled,
        (state, action: PayloadAction<ItemConversionModels[]>) => {
          state.loading = false;
          state.ItemConversionList = action.payload;
        }
      )
      .addCase(GetItemConversionList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(GetItemConversionByItemId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetItemConversionByItemId.fulfilled,
        (state, action: PayloadAction<ItemConversionModels[]>) => {
          state.loading = false;
          state.ItemConversionListByItem = action.payload;
        }
      )
      .addCase(GetItemConversionByItemId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default ItemConversionSlice.reducer;
