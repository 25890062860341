import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "../../../../components/card/card";
import Pagination from "../../../../components/Shared/Pagination/Pagination";
import {useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  AddRealEstateAssignment,
  GetPersonListToAccommodationByRealEstateId,
} from "../../../../store/SU/realEstateSlice";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import Loader from "../../../../components/Shared/Loader/Loader";

const Out = ({ List, loading }) => {
  const { Id } = useParams();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const [isSubmittibg, setIsSubmittibg] = useState(true);

  const filteredSearch = List?.slice()?.filter((emp) => {
    const searchLowerCase = search.toLowerCase();
    return (
      emp.NAME2?.toLowerCase().includes(searchLowerCase) ||
      emp.NAME?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  const handlePagination = (pageNumber) => setCurrentPage(pageNumber);

  const handleAddRealEstateAssignment = async (personId) => {
    setIsSubmittibg(false);
    var body = {
      realEstateId: Id,
      personId: personId,
      extraInformationId: null,
    };

    await dispatch(AddRealEstateAssignment(body))
      .unwrap()
      .then((res) => {
        // debugger;
        if (res.succeeded === true) {
          CustomAlert({ action: "Add", Title: "", msg: "تم العملية بنجاح" });
          dispatch(GetPersonListToAccommodationByRealEstateId(Id));
          setIsSubmittibg(true);
          return;
        }
        setIsSubmittibg(true);
        CustomAlert({ action: "Error", msg: res.message });
      })
      .catch((error) => {
        setIsSubmittibg(true);
        CustomAlert({ action: "Error" });
      });
  };

  return (
    <div>
      <Card>
        {/* <Loader loading={loading} /> */}
        <CardBody>
        <Loader loading={loading}/>            <>
              <div className="row">
                <div className="col-5 col-md-6 text-start">غير المسكنين</div>
                <div className="col-7 col-md-6 d-flex justify-content-end">
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: "10px" }}
                        >
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder={t("Search") + " ..."}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">{t("Name")}</th>
                      <th scope="col">{t("Department")}</th>
                      <th scope="col">{t("Religion")}</th>
                      <th scope="col">{t("Nationality")}</th>
                      <th scope="col">{t("Distance")}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((item, index) => (
                      <tr key={index}>
                        <td className="align-middle">
                          {i18n.language === "en" ? item.NAME : item.NAME2}
                        </td>
                        <td className="align-middle">
                          {i18n.language === "en"
                            ? item.ORGANIZATION
                            : item.ORGANIZATION2}
                        </td>
                        <td className="align-middle">
                          {i18n.language === "en"
                            ? item.RELIGIONS
                            : item.RELIGIONS2}
                        </td>
                        <td className="align-middle">
                          {i18n.language === "en"
                            ? item.NATIONALITY
                            : item.NATIONALITY2}
                        </td>
                        <td className="align-middle">{item.DISTANCE}</td>

                        <td className="align-middle">
                          {isSubmittibg ? (
                            <button
                              type="button"
                              className="btn btn-outline-secondary btn-lg m-0"
                              data-toggle="tooltip"
                              title="تسكين الموظف"
                              onClick={() =>
                                handleAddRealEstateAssignment(item.ID)
                              }
                            >
                              <i className="fas fa-lg fa-fw fa-chevron-down text-warning"></i>
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-outline-secondary btn-lg m-0"
                              data-toggle="tooltip"
                              title="تسكين الموظف"
                              onClick={() =>
                                handleAddRealEstateAssignment(item.ID)
                              }
                              disabled
                            >
                              <i className="fas fa-lg fa-fw fa-chevron-down text-warning"></i>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={handlePagination}
                currentPage={currentPage}
              />
            </>
       
        </CardBody>
      </Card>
    </div>
  );
};

export default Out;
