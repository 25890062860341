import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import useGetItemsList from '../../../../../hooks/INV/MasterItems/useGetItemsList';
import { TransactionsLinesList } from '../../../../../store/Sales/SalesInvoices/SalesInvoicesModels';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { GetItemConversionList } from '../../../../../store/INV/MasterItems/ItemConversionSlice';
import Loading from '../../../../../components/Shared/Loader/Loading';
import useGetItemConversionList from '../../../../../hooks/INV/ItemConversion/useGetItemConversionList';
import PopoverCustom from '../../../../../components/Shared/Popover/PopoverCustom';

interface ReturnLinesList extends TransactionsLinesList {
  selectedRow: boolean;
  ReturnQty: number;
  availableQtyReturn: number; // الكمية المتاحة للإسترجاع
}

interface LinesProps {
  formik: FormikProps<any>;
}

const ReturnLines: React.FC<LinesProps> = ({ formik }) => {
  const { SalesReturnsById: data } = useAppSelector((s) => s.SalesReturns);
  const { i18n, t } = useTranslation();
  const { ItemsOptions, loading, error } = useGetItemsList();
  const dispatch = useAppDispatch();
  const [Lines, setLines] = useState<ReturnLinesList[]>(formik?.values.transactionsLinesList || []);

  function calculateAvailableQtyReturn(invoice: any, returns: any) {
    // إنشاء كائن لتخزين الكميات المتاحة للإسترجاع
    const availableQtyMap = {} as any;

    // حساب الكميات المباعة من الفاتورة
    invoice.transactionsLinesList?.forEach((line: any) => {
      const itemId = line.itemId;
      availableQtyMap[itemId] = (availableQtyMap[itemId] || 0) + line.qty;
    });

    // طرح الكميات المسترجعة من المردودات السابقة
    returns?.forEach((returnInvoice: any) => {
      returnInvoice.transactionsLinesList?.forEach((line: any) => {
        const itemId = line.itemId;
        availableQtyMap[itemId] = (availableQtyMap[itemId] || 0) - line.qty;
      });
    });

    return availableQtyMap;
  }
  // استخدام الدالة لحساب الكميات المتاحة للإسترجاع
  const availableQtyReturn = calculateAvailableQtyReturn(data, data.salTransactionsReturns);

  useEffect(() => {
    if (formik?.values.transactionsLinesList.length) {
      const updatedLines = formik.values.transactionsLinesList.map((line: any) => ({
        ...line,
        ReturnQty: 0,
        total: 0,
        description: '',
        selectedRow: false, //  الصنف الذي تم تحديده للإسترجاع
        availableQtyReturn: availableQtyReturn[line.itemId], // الكميات المتاحة للإسترجاع
      }));
      setLines(updatedLines);
    }
  }, []);

  const { ItemConversionList, loading: loading2, error: error2 } = useGetItemConversionList();

  const handleLineChange = (index: number, field: string, value: any) => {
    if (field === 'itemId') {
      const updatedLines = [...Lines];

      let price = ItemsOptions.find((f) => f.value === value)?.salesPrice;
      let total = Number(updatedLines[index].qty) * Number(price);
      updatedLines[index] = {
        ...updatedLines[index],
        itemId: value,
        price: Number(price),
        total,
      };

      setLines(updatedLines);
      formik?.setFieldValue('transactionsLinesList', updatedLines);

      return;
    }

    const updatedLines = [...Lines];
    updatedLines[index] = {
      ...updatedLines[index],
      [field]: value,
    };

    // Recalculate total and VAT if needed
    if (field === 'ReturnQty' || field === 'price' || field === 'vatRate') {
      const total = Number(updatedLines[index].ReturnQty) * Number(updatedLines[index].price);
      const vatAmount = (total * Number(updatedLines[index].vatRate)) / 100;
      updatedLines[index] = {
        ...updatedLines[index],
        total,
        vatAmount,
      };
    }

    setLines(updatedLines);
    formik?.setFieldValue('transactionsLinesList', updatedLines);
  };

  const handleCheckboxChange = (index: number) => {
    const updatedLines = [...Lines];
    updatedLines[index].selectedRow = !updatedLines[index].selectedRow;

    // إذا تم إلغاء التحديد، قم بتصفير قيمة `ReturnQty`
    if (!updatedLines[index].selectedRow) {
      updatedLines[index].ReturnQty = 0;
      updatedLines[index].total = 0;
    }

    setLines(updatedLines);
    formik?.setFieldValue('transactionsLinesList', updatedLines);
  };

  const calculateTotalSum = () => {
    let total = Lines.reduce((sum, line) => sum + line.total, 0);
    const formattedTotal = new Intl.NumberFormat('en-US').format(total);
    return formattedTotal;
  };

  return (
    <>
      <Loading loading={loading || loading2} error={error || error2}>
        <div className="mb-3">
          <div className="row">
            <div className="col-2">
              <div className="mb-1 rounded-3 p-2 bg-inverse bg-opacity-10">
                <div className="d-flex justify-content-around">
                  <div className="fw-bold">{t('Total')} :</div>
                  <div className="text-theme fs-5 fw-bold">{calculateTotalSum()}</div>
                </div>
              </div>
            </div>
          </div>

          {Lines.length > 0 && (
            <div className="">
              <div
                className={`table-responsive rounded-2 text-center ${
                  formik.errors.transactionsLinesList ? 'border border-2 border-danger' : ''
                } `}>
                <table className="table table-borderless  table-hover mb-0 ">
                  <thead className="">
                    <tr>
                      <th className="align-middle bg-theme bg-opacity-30" style={{ width: '2%' }}>
                        {' '}
                        <i className="bi bi-filter"></i>
                      </th>
                      <th
                        className="align-middle  bg-inverse bg-opacity-30 "
                        style={{ width: '2%' }}>
                        #
                      </th>
                      <th
                        className="align-middle  bg-inverse bg-opacity-30 "
                        style={{ width: '30%' }}>
                        {t('اسم الصنف')}
                      </th>
                      <th
                        className="align-middle  bg-inverse bg-opacity-30 "
                        style={{ width: '15%' }}>
                        {t('الوحدة')}
                      </th>
                      <th
                        className="align-middle  bg-inverse bg-opacity-30 "
                        style={{ width: '5%' }}>
                        {t('الكمية المباعة')}
                      </th>
                      <th
                        className="align-middle  bg-inverse bg-opacity-30 "
                        style={{ width: '5%' }}>
                        {t('الكمية المسترجعة')}
                      </th>
                      <th
                        className="align-middle  bg-inverse bg-opacity-30 "
                        style={{ width: '5%' }}>
                        {t('السعر')}
                      </th>

                      <th
                        className="align-middle bg-inverse bg-opacity-30 "
                        style={{ width: '5%' }}>
                        {t('Total')}
                      </th>
                      <th
                        className="align-middle bg-inverse bg-opacity-30 "
                        style={{ width: '20%' }}>
                        {t('الوصف')}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="fw-bold">
                    {Lines.slice()
                      .reverse()
                      .map((line, index) => {
                        const Index = Lines.length - 1 - index;

                        return (
                          <tr
                            style={{ cursor: line.availableQtyReturn === 0 ? 'not-allowed' : '' }}
                            key={index}
                            className={line.selectedRow ? 'bg-theme bg-opacity-30' : ''}>
                            <td
                              className={`align-middle bg-opacity-30  ${
                                line.availableQtyReturn === 0
                                  ? 'bg-danger border border-1 border-danger'
                                  : 'bg-theme'
                              }`}>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={line.selectedRow || false}
                                onChange={() => handleCheckboxChange(Index)}
                                disabled={line.availableQtyReturn === 0}
                              />
                            </td>
                            <td className="align-middle bg-inverse bg-opacity-30">
                              {Lines.length - index}
                            </td>
                            <td className="align-middle">
                              <Select
                                isDisabled
                                classNamePrefix="react-select"
                                className="w-100"
                                isSearchable
                                isClearable
                                // options={ItemsOptions}
                                options={ItemsOptions.filter(
                                  (f) => !Lines.some((line) => line.itemId === f.value)
                                )}
                                onChange={(option) =>
                                  handleLineChange(
                                    Index,
                                    'itemId',
                                    option === null ? null : option.value
                                  )
                                }
                                value={
                                  line.itemId === null
                                    ? null
                                    : ItemsOptions.find((option) => option.value === line.itemId)
                                }
                                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                                }}
                              />
                            </td>
                            <td className="align-middle">
                              <select
                                disabled
                                className="form-select text-center"
                                value={line.uom || ''}
                                onChange={(e) => handleLineChange(Index, 'uom', e.target.value)}>
                                <option value="">
                                  {i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                </option>
                                {ItemConversionList.filter((f) => f.itemId === line.itemId).map(
                                  (item, idx) => (
                                    <option key={++idx} value={item.uom}>
                                      {i18n.language === 'en' ? item.uomName : item.uomName2}
                                    </option>
                                  )
                                )}
                              </select>
                            </td>
                            <td className="align-middle">{line.qty}</td>
                            <td className="align-middle">
                              <PopoverCustom
                                childern={
                                  <input
                                    disabled={!line.selectedRow}
                                    type="text"
                                    className="form-control text-center px-0"
                                    value={line.ReturnQty || 0}
                                    onChange={(e) =>
                                      handleLineChange(Index, 'ReturnQty', Number(e.target.value))
                                    }
                                    // max={6}
                                    onInput={(e) => {
                                      // إلغاء أي إدخال غير رقمي يدويًا
                                      // والتأكد من أن الرقم لا يتجاوز الكمية المتاحة
                                      const input = e.target as HTMLInputElement;
                                      input.value = input.value.replace(/[^0-9]/g, '');
                                      if (Number(input.value) > line.availableQtyReturn) {
                                        input.value = line.availableQtyReturn.toString();
                                      }
                                    }}
                                  />
                                }
                                Header="تفاصيل الكمية"
                                Body={
                                  <>
                                    <div className="d-flex justify-content-between">
                                      {/* <span className="mt-1">الكمية المتاحة للإسترجـاع :</span> */}
                                      <span className="mt-1">الكمية المتبقية للإرجاع:</span>
                                      <span className="text-success fw-bold fs-5 mx-2">
                                        {line.availableQtyReturn}
                                      </span>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      {/* <span className="mt-1">الكمية المسترجعة مسبقاً :</span> */}
                                      <span className="mt-1">الكمية التي تم إرجاعها سابقًا:</span>
                                      <span className="text-danger fw-bold fs-5 mx-2">
                                        {line.qty - line.availableQtyReturn}
                                      </span>
                                    </div>
                                  </>
                                }
                              />
                            </td>
                            <td className="align-middle">{line.price}</td>

                            <td className="align-middle">{line.total}</td>
                            <td className="align-middle">
                              <input
                                type="text"
                                className="form-control"
                                value={line.description || ''}
                                onChange={(e) =>
                                  handleLineChange(Index, 'description', e.target.value)
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </Loading>
    </>
  );
};

export default ReturnLines;
