import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

interface Props {
  formik: FormikProps<any>;
}

const ApPaymentSchedules = ({ formik }: Props) => {
  const { values, setFieldValue } = formik;

  const { t, i18n } = useTranslation();
  // Calculate the total invoice amount from the lines
  const totalInvoiceAmount = values?.apInvoiceLinesList?.reduce(
    (sum: number, item: any) => sum + item.total,
    0
  );

  // Function to handle changes in the payment amount and due date
  const handlePaymentsChange = (index: number, field: any, value: string | number) => {
    const updatedPayments = [...values.apPaymentSchedulesList];
    updatedPayments[index][field] = value;
    setFieldValue('apPaymentSchedulesList', updatedPayments);
  };

  // Add a new payment entry
  const handleAddPayment = () => {
    const newPayment = { amount: 0, dueDate: '' };
    setFieldValue('apPaymentSchedulesList', [...values.apPaymentSchedulesList, newPayment]);
  };

  // Distribute the total amount equally among all payments
  const distributePayments = () => {
    const paymentCount = values.apPaymentSchedulesList.length;
    const equalAmount = (totalInvoiceAmount / paymentCount).toFixed(2);

    const updatedPayments = values.apPaymentSchedulesList.map((payment: any) => ({
      ...payment,
      amount: parseFloat(equalAmount),
    }));

    setFieldValue('apPaymentSchedulesList', updatedPayments);
  };

  // Delete a payment entry at the specified index
  const handleDeletePayment = (index: number) => {
    const updatedPayments = values.apPaymentSchedulesList.filter(
      (_: any, paymentIndex: any) => paymentIndex !== index
    );
    setFieldValue('apPaymentSchedulesList', updatedPayments);
  };

  return (
    <div>
      <div className="d-flex justify-content-between mb-4">
        <h6>جدولة الدفعات</h6>
        <div>
          <button type="button" className="btn btn-outline-theme ms-1" onClick={handleAddPayment}>
            <i className="fas fa-money-bill me-2"></i>
            إضافة دفعة
          </button>

          <button type="button" className="btn btn-outline-theme ms-1" onClick={distributePayments}>
            <i className="fas fa-calculator me-2"></i>
            توزيع المبلغ بالتساوي
          </button>
        </div>
      </div>

      {values.apPaymentSchedulesList.map((payment: any, index: any) => (
        <div key={index} className="row mb-3">
          <div className="col-lg-5">
            <div className="input-group">
              <span className="input-group-text">{t('المبلغ')}</span>
              <Form.Control
                type="text"
                value={payment.amount}
                onChange={(e) => handlePaymentsChange(index, 'amount', parseFloat(e.target.value))}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/[^0-9]/g, '');
                }}
              />
            </div>
          </div>

          <div className="col-lg-5  ">
            <div className="input-group">
              <span className="input-group-text">{t('تاريخ الاستحقاق')}</span>
              <Form.Control
                type="date"
                value={payment.dueDate}
                onChange={(e) => handlePaymentsChange(index, 'dueDate', e.target.value)}
              />
            </div>
          </div>

          <div className="col-lg-2 text-end">
            <button
              type="button"
              className="btn btn-outline-danger w-100"
              onClick={() => handleDeletePayment(index)}>
              <i className="fas fa-trash-alt me-2"></i>
              حذف الدفعة
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ApPaymentSchedules;
