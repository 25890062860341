import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../components/card/card';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../components/Shared/Pagination/Pagination';
import AddVAT from './AddVAT';
import { Link } from 'react-router-dom';
import useGetGlLedgersList from '../../../hooks/Finance/useGetGlLedgersList';
import SmallModalComponent from '../../../components/Modal/SmallModalComponent';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { GetTaxManagersList } from '../../../store/Finance/TaxManagers/TaxManagerSlice';

const Index = () => {
  const [Show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const [selectedLedger, setSelectedLedger] = useState('');
  const { LedgersList } = useGetGlLedgersList();
  const dispatch = useAppDispatch();

  const { TaxManagerList } = useAppSelector((a) => a.TaxManager);
  useEffect(() => {
    dispatch(GetTaxManagersList());
  }, []);

  const filteredSearch = TaxManagerList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return item?.startDateActive?.toLowerCase().includes(searchLowerCase);
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'ضريبة الفيمة المضافة',
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="ضريبة الفيمة المضافة" />
      <Card>
        <CardBody>
          <div className="tab-content p-1">
            <div className="row">
              <div className="col-lg-6">
                <div className="input-group mb-2">
                  <select
                    className="text-theme fw-bold bg-inverse bg-opacity-20 btn btn-default dropdown-toggle px-4 "
                    value={selectedLedger}
                    onChange={(e) => setSelectedLedger(e.target.value)}>
                    <option className="dropdown-item" value={''}>
                      {t('All')}
                    </option>
                    {LedgersList &&
                      LedgersList.map((item) => (
                        <option value={item.id} key={item.id} className="dropdown-item">
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </option>
                      ))}
                  </select>

                  <div className="flex-fill position-relative">
                    <div className="input-group">
                      <div
                        className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                        style={{ zIndex: 1020 }}>
                        <i className="fa fa-search ps-2 opacity-5"></i>
                      </div>
                      <input
                        type="text"
                        className="form-control ps-4 py-10px"
                        placeholder={t('Search')}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 text-end">
                {selectedLedger && (
                  <Link
                    to=""
                    className="btn btn-theme mx-2 rounded-3"
                    data-bs-toggle="modal"
                    data-bs-target="#modalAddVat"
                    onClick={() => setShow(true)}>
                    <i className="fa fa-plus-circle fa-fw me-1 p-1"></i>
                    {t('إضافة')}
                  </Link>
                )}
              </div>
            </div>

            {/* //  START TABLE   */}
            <div className="table-responsive">
              <table className="table table-hover text-nowrap">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">{t('نوع الصريبة ')}</th>
                    <th scope="col">{t('نسبة الضريبة')}</th>
                    <th scope="col">{t('من تاريخ')}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((item, index) => (
                    <tr key={item.id}>
                      <td> {i18n.language === 'ar' ? item.taxRate : item.taxRate}</td>
                      <td> {item.startDateActive.toString()} </td>
                      <td> {item.endDateActive.toString()} </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-outline-lime"
                          // onClick={() => Navigate(`${item.id}/Edit`)}
                        >
                          <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                          {t('Button.Edit')}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </div>
        </CardBody>
      </Card>

      <SmallModalComponent title="اضافة" id="modalAddVat">
        {Show && <AddVAT LedgerId={selectedLedger}/>}
      </SmallModalComponent>
    </div>
  );
};

export default Index;
