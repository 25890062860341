

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
// import { getData, postData } from "../../Axios/axiosData";



export const GetTableViewName = createAsyncThunk(
    "Reports/GetTableViewName",
    async (item, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;

      try {
        // const data = await postData("Test/Query" , item);
        const {data} = await ntxAPI.post("Test/Query" , item)
        return data;
      } catch (error) {
        console.error(error);
        return rejectWithValue(error.message);
      }
    }
 );


export const GetReportSourceFeild = createAsyncThunk(
    "Reports/GetReportSourceFeild",
    async (item, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.post("Reports/GetReportSourceFeild" , item)
        return data;
      } catch (error) {
        console.error(error);
        return rejectWithValue(error.message);
      }
    }
 );









 export const AddNtxReports = createAsyncThunk(
  "Reports/AddNtxReports",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      // debugger
      const {data} = await ntxAPI.post("Reports/AddNtxReports" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

 export const UpdatNtxReports = createAsyncThunk(
  "Reports/UpdatNtxReports",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    // debugger
    try {
      const {data} = await ntxAPI.post("Reports/UpdatNtxReports"  , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetReportsList = createAsyncThunk(
  "Reports/GetReportsList",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData("Reports/GetNtxReportsList");
      const {data} = await ntxAPI.get( "Reports/GetNtxReportsList");
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetReportById = createAsyncThunk(
  "Reports/GetReportById",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`Reports/GetNtxReportsById?Id=${id}`);
      const {data} = await ntxAPI.get(`Reports/GetNtxReportsById?Id=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
)


export const GetReportSourceList = createAsyncThunk(
  "Reports/GetReportSourceList",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.get( "Reports/GetReportSourceList");
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
); 

  

const initialState = { ListTableViewName: [], ReportsList: [], ReportsListById: [] , ReportSourceList :[] ,loading: false, error: null };

const ReportSlice = createSlice({
    name: "Reports",
    initialState,
    reducers: {
      cleanReportsListById: (state) => {
        state.ReportsListById = [];
      },
    },
    extraReducers: (builder) => {
  builder
    // GetTableViewName
    .addCase(GetTableViewName.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(GetTableViewName.fulfilled, (state, action) => {
      state.loading = false;
      state.ListTableViewName = action.payload;
    })
    .addCase(GetTableViewName.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    // GetReportsList
    .addCase(GetReportsList.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(GetReportsList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.ReportsList = payload.data;
    })
    .addCase(GetReportsList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    // GetReportById
    .addCase(GetReportById.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.ReportsListById = [];
    })
    .addCase(GetReportById.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.data !== null) {
        state.ReportsListById = payload.data;
      }
    })
    .addCase(GetReportById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    // GetReportSourceList
    .addCase(GetReportSourceList.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(GetReportSourceList.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.succeeded === true) {
        state.ReportSourceList = payload.data;
      }
    })
    .addCase(GetReportSourceList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
},
   
  });

  export const { cleanReportsListById } = ReportSlice.actions;
  
  export default ReportSlice.reducer;