//#region import Routes
import React from 'react';
import App from '../App';
import { Navigate, createBrowserRouter } from 'react-router-dom';

// import Home from "./../pages/home/home.js";
import Login from '../pages/Login/Login.js';
import Dashboard from '../pages/dashboard/dashboard.js';
import Employees from '../pages/HR/Employees/HR-PERSON/Index/Index.js';

import PagesError from '../pages/PagesError/PagesError.js';
import Assignment from '../pages/HR/Employees/Assignment/Assignment.js';
import Absence from '../pages/HR/Employees/Absence/absence';
import HRRequests from '../pages/HR/HRRequest/HRRequests';

import ElementLink from '../pages/HR/payroll/payroll/ElementLink.js';

import EditJob from '../pages/HR/settings/Jobs/EditJob.js';
import EditJobGrade from '../pages/HR/settings/JobGrades/EditJobGrade.js';
import EditLocation from '../pages/HR/settings/Location/EditLocation/EditLocation.js';
import EditPosition from '../pages/HR/settings/position/EditPosition.js';
import EditDepartments from '../pages/HR/Departments/EditDepartments/EditDepartments.js';

import EditForm from '../pages/NTX/FlexValue/AmeForms/EditForm.js';
import { AddFlexValue } from '../pages/NTX/FlexValue/ListSystem/FlexValue/AddFlexValue.js';
import AddFormColumn from '../pages/NTX/FlexValue/FormColumn/AddFormColumn.js';

import Conditions from '../pages/Ame/RequestSettings/Conditions/Conditions';
import Rules from '../pages/Ame/RequestSettings/Rules/Rules';
import AddAmeCondition from '../pages/Ame/RequestSettings/AddAmeCondition.js';
import EditApprovalGroup from '../pages/Ame/ApprovalGroup/EditApprovalGroup.js';
import AddApprovalMember from '../pages/Ame/ApprovalMember/AddApprovalMember.js';
import AddAmeRule from '../pages/Ame/AmeRule/AmeRule/AddAmeRule.js';
import AddAmeConditionUsage from '../pages/Ame/AmeRule/AmeConditionUsage/AddAmeConditionUsage.js';
import AddAmeAction from '../pages/Ame/AmeRule/AmeActionRule/AddAmeAction.js';

import EditUser from '../pages/NTX/Security/Users/EditUser.js';
import EditElementType from '../pages/HR/payroll/payrollElement/EditElementType.js';
import AddPayrollElementEntry from '../pages/HR/payroll/PayrollElementEntry/AddPayrollElementEntry.js';
import AddPayProposal from '../pages/HR/payroll/PayProposal/AddPayProposal.js';
import RotationPlan from '../pages/HR/Raqeeb/RotationPlan.js';
import RotationSetting from '../pages/HR/Raqeeb/RotationSetting/RotationSetting.js';
import Delegation from '../pages/HR/Delegation/Delegation';
import AmeRequests from '../pages/Ame/Requests/AmeRequests/AmeRequests.js';
import AttendanceAnalysis from '../pages/HR/Raqeeb/AttendanceAnalysis/AttendanceAnalysis.js';
import AnalysisTable from '../pages/HR/Raqeeb/AttendanceAnalysis/AnalysisTable.js';

import AddPayrollRun from '../pages/HR/payroll/PayrollRun/AddPayrollRun.js';
import AddQuickPay from '../pages/HR/payroll/PayrollRun/AddQuickPay.js';
import ActionList from '../pages/HR/payroll/PayrollRun/ActionList/ActionList.js';
import SalaryReport from '../pages/HR/payroll/PayrollRun/ActionList/AssignmentAction.js';
import PayrollForPerson from '../pages/HR/payroll/PayrollRun/Report/PayrollForPerson.js';
import PayrollForAll from '../pages/HR/payroll/PayrollRun/Report/PayrollForAll.js';
import PayrollElementEntry from '../pages/HR/payroll/PayrollElementEntry/PayrollElementEntry.js';
import Departments from '../pages/HR/Departments/Departments.js';
import OrganizationStructure from '../pages/HR/Departments/Structure/OrganizationStructure';
import Job from '../pages/HR/settings/Jobs/Job.js';
import JobGrades from '../pages/HR/settings/JobGrades/JobGrades.js';
import Location from '../pages/HR/settings/Location/Location.js';
import Position from '../pages/HR/settings/position/position.js';
import GroupEmployees from '../pages/HR/settings/GroupEmployees/GroupEmployees.js';
import EditGroupEmployees from '../pages/HR/settings/GroupEmployees/EditGroupEmployees.js';
import Payroll from '../pages/HR/payroll/payroll/payroll.js';
import PayrollElement from '../pages/HR/payroll/payrollElement/PayrollElement.js';
import PayrollRun from '../pages/HR/payroll/PayrollRun/PayrollRun.js';
import QuickPay from '../pages/HR/payroll/PayrollRun/QuickPay.js';
import Forms from '../pages/NTX/FlexValue/AmeForms/Forms.js';
import ListSystem from '../pages/NTX/FlexValue/ListSystem/ListSystem.js';
import Users from '../pages/NTX/Security/Users/Users.js';

import PermissionUser from '../pages/NTX/Security/Users/PermissionUser';
import AuthRules from '../pages/NTX/Security/Rules/Index';

import RequestSettings from '../pages/Ame/RequestSettings/RequestSettings.js';
import ApprovalGroup from '../pages/Ame/ApprovalGroup/ApprovalGroup.js';
import MyRequests from '../pages/Ame/ViewRequests/MyRequests/MyRequests.js';
import AddRequests from '../pages/Ame/Requests/AddRequests.js';
import ViewRequests from '../pages/Ame/ViewRequests/ViewRequests.js';
import ViewAbsence from '../pages/HR/Employees/Absence/ViewAbsence/Index';
// import OnlyViewRequests from "../pages/Ame/ViewRequests/OnlyViewRequests.js";
import ExtraInformationForm from '../pages/NTX/FlexValue/ExtraInformationForm/ExtraInformationForm.js';
import EditExtraInformationForm from '../pages/NTX/FlexValue/ExtraInformationForm/EditExtraInformationForm.js';
import { AddSubLists } from '../pages/NTX/FlexValue/ListSystem/FlexValue/AddSubLists.js';
import ExtraRecordsForm from '../pages/NTX/FlexValue/ExtraRecordsForm/ExtraRecordsForm.js';
import EditExtraRecordsForm from '../pages/NTX/FlexValue/ExtraRecordsForm/EditExtraRecordsForm.js';
import SUExtraInformationDynamicType from '../pages/SU/ExtraInformationDynamicType/SU_ExtraInformationDynamicType.js';

import ExtraRecordFormPerson from '../pages/HR/Employees/HR-PERSON/ExtraRecord/ExtraRecordFormPerson.js';
import RealEstate from '../pages/SU/RealEstate/index/RealEstate';
import Accomodation from '../pages/SU/RealEstate/Accomodation/Accomodation';
import EditExtraInformationDynamicType from '../pages/NTX/FlexValue/ExtraInformationDynamicType/EditExtraInformationDynamicType.js';
import UpdateRealEstate from '../pages/SU/RealEstate/Update/UpdateRealEstate.js';
import AddContracts from '../pages/SU/Contracts/Add/AddContracts.js';
import Contracts from '../pages/SU/Contracts/index/Contracts.js';
import Branches from '../pages/SU/Branches/Index';
import UpdateContract from '../pages/SU/Contracts/Update/UpdateContract.js';

import AddReport from '../pages/NTX/Reports/Report/Add/AddReport';
import Reports from '../pages/NTX/Reports/Report/Index/Index';

import PrintingForms from '../pages/NTX/Reports/PrintingForms/Index/Index';
import AddPrintingForms from '../pages/NTX/Reports/PrintingForms/Add/AddPrintingForms';
import UpdatePrintingForms from '../pages/NTX/Reports/PrintingForms/Update/UpdatePrintingForms';

import UpdateReport from '../pages/NTX/Reports/Report/Update/UpdateReport';
import HRReports from '../pages/HR/HRReports/HRReports';

import AddReportShape from '../pages/NTX/Reports/ReportChart/Add/AddReportShape';
import ReportsBuilder from '../pages/NTX/Reports/ReportsAllByModule/Reports/ReportsBuilder/ReportsBuilder';
import Lookup from '../pages/NTX/Lookup/Lookup';
import UpdatePerson from '../pages/HR/Employees/HR-PERSON/Edit/UpdatePerson';
import Applications from '../pages/Applications/Applications';
// import NtxPeoples from "../pages/NTX/Peoples/NtxPeoplesIndex";
import Workshop from '../pages/FLEET/FleetSettings/Workshop/Index';
import FleetLocations from '../pages/FLEET/FleetSettings/Locations/Index';
import UppdateWorkShop from '../pages/FLEET/FleetSettings/Workshop/UppdateWorkShop';
import EquipmentsModel from '../pages/FLEET/FleetSettings/Manufacturer/Index';
import EditManufacturer from '../pages/FLEET/FleetSettings/Manufacturer/EditManufacturer';
import Model from '../pages/FLEET/FleetSettings/Model/Index';
import EditModel from '../pages/FLEET/FleetSettings/Model/EditModel';

// Integration
import Integration from '../pages/Integration/Index';

// Scanning Fleet
import FleetElementScan from '../pages/FLEET/FleetScan/ScanElement/Index';
import AddElementScan from '../pages/FLEET/FleetScan/ScanElement/AddElement';
import EquipmentInspection from '../pages/FLEET/FleetScan/ScanEquipments/Index';
import ScanningList from '../pages/FLEET/FleetScan/ScanEquipments/ScanningList';
import AddScan from '../pages/FLEET/FleetScan/ScanEquipments/AddScan';
import UpdateScan from '../pages/FLEET/FleetScan/ScanEquipments/UpdateScan';
import Equipments from '../pages/FLEET/Equipments/Index';
import Drivers from '../pages/FLEET/FleetSettings/Drivers/Index';
import Owners from '../pages/FLEET/FleetSettings/Owners/Index';
import Suppliers from '../pages/FLEET/FleetSettings/Suppliers/Index';
import EditEquipments from '../pages/FLEET/Equipments/EditEquipments';
import ExtraRecordEquipments from '../pages/FLEET/Equipments/ExtraRecord/ExtraRecordEquipments';
import EquipmentsAssignment from '../pages/FLEET/EquipmentsAssignment/EquipmentsAssignment';
import EquipmentsOdometer from '../pages/FLEET/EquipmentsOdometer/EquipmentsOdometer';
import AddEquipmentRoutes from '../pages/FLEET/EquipmentRoutes/AddEquipmentRoutes';
import WorkRequests from '../pages/FLEET/WorkRequests/WorkRequestList';
import AddWorkRequest from '../pages/FLEET/WorkRequests/AddWorkRequest';
import FLEETRequest from '../pages/FLEET/FLEETRequest/FLEETRequest';
import MaintenanceOrder from '../pages/FLEET/MaintenanceOrder/Index';
import AddOrderMaintenance from '../pages/FLEET/MaintenanceOrder/AddOrderMaintenance';
import EditEquipmentRoutes from '../pages/FLEET/EquipmentRoutes/EditEquipmentRoutes';
import EditDrivers from '../pages/FLEET/FleetSettings/Drivers/EditDrivers';
import EditOwners from '../pages/FLEET/FleetSettings/Owners/EditOwners';
import EditSuppliers from '../pages/FLEET/FleetSettings/Suppliers/EditSuppliers';
import CloseOrder from '../pages/FLEET/MaintenanceOrder/CloseOrder';
import AssignmentRoutes from '../pages/FLEET/AssignmentRoutes/AssignmentRoutes';
import SUReports from '../pages/SU/SUReports/SUReports';
import FLEETReports from '../pages/FLEET/FLEETReports/FLEETReports';
import DashboardFLEET from '../pages/FLEET/DashboardFLEET/DashboardFLEET';
import UppdateLocations from '../pages/FLEET/FleetSettings/Locations/UppdateLocations';
import UpdateBranches from '../pages/SU/Branches/UpdateBranches';
import InstallmentUnPaymentList from '../pages/SU/FollowPayments/InstallmentUnPayment/InstallmentUnPaymentList';
import InstallmentPaymentList from '../pages/SU/FollowPayments/InstallmentPayment/InstallmentPaymentList';
// import TestComponent from "../pages/ExtraInformation/ExtraRecordsDynamicForm/BuilderExtraRecord/Tabs/TestComponent";
import ExtraRecordRealEstate from '../pages/SU/RealEstate/ExtraRecord/ExtraRecordRealEstate';
import NtxSettings from '../pages/NTX/Settings/NtxSettings';
import NtxProfile from '../pages/NTX/Profile/NtxProfile';

//_Finance
import BankAccounts from '../pages/Finance/Settings/BankAccounts/Index';

import FiscalYear from '../pages/Finance/Settings/FiscalYear/Index';
import AccountingPeriod from '../pages/Finance/Settings/AccountingPeriod/Index';
import Ledgers from '../pages/Finance/Settings/Ledgers/Index';
import DailyRates from '../pages/Finance/Settings/DailyRates/Index';
import JeCategory from '../pages/Finance/Settings/JeCategory/Index';
import JeSource from '../pages/Finance/Settings/JeSource/Index';
import Vat from '../pages/Finance/VAT/Index';
import ChartAccounts from '../pages/Finance/ChartAccounts/Index';
import ChartAccountsDetails from '../pages/Finance/ChartAccountsDetails/Accounts/Index';
import CompaniesAndBranches from '../pages/Finance/ChartAccountsDetails/CompaniesAndBranches/Index';
import CostCenters from '../pages/Finance/ChartAccountsDetails/CostCenters2/Index';
import Journals from '../pages/Finance/Journals/Index';
import AddJournals from '../pages/Finance/Journals/AddJournals/AddJournals';
import UpdateJournals from '../pages/Finance/Journals/UpdateJournals/UpdateJournals';
import UnPostedJournalsList from '../pages/Finance/Journals/PostJournals/UnPostedJournalsList';

//_Finance Ap
import ApInvoices from '../pages/Finance/Ap/ApInvoices/Index';
import AddApInvoices from '../pages/Finance/Ap/ApInvoices/Add';


//_ Sales

import SalesReports from '../pages/Sales/SalesReports/SalesReports';
import SalesDashboards from '../pages/Sales/SalesDashboards/SalesDashboards';
import SalesRequests from '../pages/Sales/SalesRequests/SalesRequests';

import Customers from '../pages/Sales/SalesSettings/Customers/Index';
import UpdateCustomers from '../pages/Sales/SalesSettings/Customers/Update';
import BranchesCustomer from '../pages/Sales/SalesSettings/Customers/Branches/Index';
// import CustomersContacts from '../pages/Sales/SalesSettings/Customers/Contacts/CustomersContacts';
import SalesInvoices from '../pages/Sales/Transactions/SalesInvoices/Index';
import AddSalesInvoices from '../pages/Sales/Transactions/SalesInvoices/Add';
import UpdateSalesInvoices from '../pages/Sales/Transactions/SalesInvoices/Update';
import Quotation from '../pages/Sales/Transactions/Quotation/Index';
import UpdateQuotation from '../pages/Sales/Transactions/Quotation/Update';
import AddQuotation from '../pages/Sales/Transactions/Quotation/Add';

import SalesContracts from '../pages/Sales/Transactions/Contracts/index/Contracts';
import AddSalesContracts from '../pages/Sales/Transactions/Contracts/Add/AddContracts';

import SellOrder from '../pages/Sales/Transactions/SellOrder/Index';
import AddSellOrder from '../pages/Sales/Transactions/SellOrder/Add';
import UpdateSellOrder from '../pages/Sales/Transactions/SellOrder/Update';

import SalesReturn from '../pages/Sales/Transactions/SalesReturn/Index';
import AddSalesReturn from '../pages/Sales/Transactions/SalesReturn/SalesReturn';

import DeliveryPermission from '../pages/Sales/Transactions/DeliveryPermission/Index';
import AddDeliveryPermission from '../pages/Sales/Transactions/DeliveryPermission/Add';

import PriceList from '../pages/Sales/PriceList/Index';
import DetailsPriceList from '../pages/Sales/PriceList/DetailsPriceList';

import Vouchers from '../pages/Sales/Vouchers/Index';
import AddVouchers from '../pages/Sales/Vouchers/AddVoucher';

import Vouchers2 from '../pages/Sales/Vouchers2/Index';
import VoucherType from '../pages/Sales/Vouchers2/VoucherType';
import BuyXgetY from '../pages/Sales/Vouchers2/BuyXgetY/Add';



//Sales __ POS
import Pos from '../pages/POS/PosSetting/Pos/Index';
import PosDetails from '../pages/POS/PosSetting/PosDetails/PosDetails';
import PosCard from '../pages/POS/PosCard/PosCard';
import PointOfSaleShop from '../pages/POS/PointOfSale/PointOfSaleShop';
import PointOfSaleRestaurant from '../pages/POS/PointOfSale/PointOfSaleRestaurant';


//_  Purchases
import PurchaseQuotation from '../pages/PUR/Transactions/Quotation/Index';
import AddPurchaseQuotation from '../pages/PUR/Transactions/Quotation/Add';
import UpdatePurchaseQuotation from '../pages/PUR/Transactions/Quotation/Update';

import PurchaseRequests from '../pages/PUR/Transactions/PurchaseRequest/Index';
import AddPurchaseRequest from '../pages/PUR/Transactions/PurchaseRequest/Add';
import UpdatePurchaseRequest from '../pages/PUR/Transactions/PurchaseRequest/Update';

import PurchaseOrder from '../pages/PUR/Transactions/PurchaseOrder/Index';
import AddPurchaseOrder from '../pages/PUR/Transactions/PurchaseOrder/Add';
import UpdatePurchaseOrder from '../pages/PUR/Transactions/PurchaseOrder/Update';


import PurchaseBill from '../pages/PUR/Transactions/PurchaseBill/Index';
import AddPurchaseBill from '../pages/PUR/Transactions/PurchaseBill/Add';
import UpdatePurchaseBill from '../pages/PUR/Transactions/PurchaseBill/Update';

import PurchaseReturn from '../pages/PUR/Transactions/PurchaseReturn/Index';
import AddUpdateViewPurchaseReturn from '../pages/PUR/Transactions/PurchaseReturn/PurchaseReturn';

import PurchasesSuppliers from '../pages/PUR/Settings/Suppliers/Index';
import UpdateSupplier from '../pages/PUR/Settings/Suppliers/Update';
import BranchesSupplier from '../pages/PUR/Settings/Suppliers/Branches/Index';


//_ INV
import Inventory from '../pages/INV/INVSettings/Inventory/Index';
import SubInventory from '../pages/INV/INVSettings/Inventory/SubInventory/SubInventory';
import AssignmentItems from '../pages/INV/INVSettings/Inventory/AssignmentItems/AssignmentItems';
import CategoriesINV from '../pages/INV/INVSettings/Categories/Index';
import MasterItems from '../pages/INV/INVSettings/MasterItems/Index';
import SuppliersInv from '../pages/INV/INVSettings/Suppliers/Index';
import EditSuppliersInv from '../pages/INV/INVSettings/Suppliers/EditSuppliers';

import MasterItemsDetails from '../pages/INV/INVSettings/MasterItems/MasterItemsDetails/MasterItemsDetails';
import TransactionTypes from '../pages/INV/INVSettings/TransactionTypes/Index';
import UnitsOfMeasure from '../pages/INV/INVSettings/UnitsOfMeasure/Index';


import InvIssue from '../pages/INV/Transactions/InvIssue/Index';
import AddInvIssue from '../pages/INV/Transactions/InvIssue/Add';

import InvReceive from '../pages/INV/Transactions/InvReceive/Index';
import AddInvReceive from '../pages/INV/Transactions/InvReceive/Add';

import InvMove from '../pages/INV/Transactions/InvMove/Index';
import AddInvMove from '../pages/INV/Transactions/InvMove/Add';

import InventoryCount from '../pages/INV/InventoryCount/InventoryCount/Index';
import AddInventoryCount from '../pages/INV/InventoryCount/InventoryCount/Add';

// import PermissionAdd from '../pages/INV/Transactions/PermissionAdd/Index';
// import AddPermissionAdd from '../pages/INV/Transactions/PermissionAdd/Add';

// import PermissionSpend from '../pages/INV/Transactions/PermissionSpend/Index';
// import AddPermissionSpend from '../pages/INV/Transactions/PermissionSpend/Add';

// import PermissionDelivery from '../pages/INV/Transactions/PermissionDelivery/Index';
// import AddPermissionDelivery from '../pages/INV/Transactions/PermissionDelivery/Add';

// import PermissionReceive from '../pages/INV/Transactions/PermissionReceive/Index';
// import AddPermissionReceive from '../pages/INV/Transactions/PermissionReceive/Add';

//_ DashboardManagement
import DashboardManagement from '../pages/NTX/DashboardManagament/index/Index';
import AddDashboard from '../pages/NTX/DashboardManagament/AddDashboard/Add';
import UpdateDashBoard from '../pages/NTX/DashboardManagament/UpdateDashBoard/Update';
import BuilderDashboard from '../pages/NTX/DashboardManagament/BuilderDashboard/BuilderDashboard';

//_ Notifications
import Notifications from '../pages/NTX/Notifications/Index/Index';
import AddNotifications from '../pages/NTX/Notifications/Add/AddNotifications';
import UpdateNotifications from '../pages/NTX/Notifications/Update/UpdateNotifications';
import PermissionRule from '../pages/NTX/Security/Rules/PermissionRule';
import RulesUser from '../pages/NTX/Security/RulesUser/RulesUser';
import ConfirmPassword from '../pages/Login/ConfirmPassword';
import ChangePassword from '../pages/NTX/Security/Users/ChangePassword';
import DashboardHR from '../pages/HR/DashboardHR/DashboardHR';
import UpdateRaqeebData from '../pages/HR/Raqeeb/UpdateData/UpdateRaqeebData';
import AllRequests from '../pages/Ame/AllRequests/AllRequests';
import EndOfService from '../pages/HR/Employees/EndOfService/EndOfService';
import ConsolidationSet from '../pages/Finance/Settings/ConsolidationSet/ConsolidationSet';
import IndexConsolidationSet from '../pages/Finance/Settings/ConsolidationSet/Index';
// import TokenCheckWrapper from '../Helper/Wrapper/TokenCheckWrapper';


//#endregion

const AppRoute = createBrowserRouter([
  {
    path: '/',
    element: (
      //  <TokenCheckWrapper>
      <App />
      //  </TokenCheckWrapper>
    ),
    errorElement: <PagesError />,


    children: [
      { path: '', element: <Navigate to="/login" /> },
      { path: 'dashboard', element: <Dashboard /> },


      //#region  HR
      // ________ HR ___________
      
      {
        path: 'HR/Employees',
        element: <Employees />,
        children: [],
      },
      {
        path: 'HR/Employees/:id/Edit',
        element: <UpdatePerson />,
      },
      {
        path: 'HR/Employees/:id/Assignments',
        element: <Assignment />,
      },
      {
        path: 'HR/Employees/absence/:personId',
        element: <Absence />,
      },
      {
        path: 'HR/Employees/EndOfService/:personId',
        element: <EndOfService />,
      },
      {
        path: 'HR/Employees/absence/:Id/ViewAbsence',
        element: <ViewAbsence />,
      },
      {
        path: 'HR/Employees/:PersonId/ExtraRecordFormPerson',
        element: <ExtraRecordFormPerson />,
      },


      {
        path: 'HR/Delegation',
        element: <Delegation />,
      },

      // ____ Department ___________________________

      {
        path: 'Org/*',
        children: [
          { path: 'Departments', element: <Departments /> },
          { path: 'OrganizationStructure', element: <OrganizationStructure /> },
          { path: 'Departments/:id/Edit', element: <EditDepartments /> },
        ],
      },

      //_______ Payrolls____________________________
      {
        path: 'Pay/*',
        children: [
          { path: 'Payrolls', element: <Payroll /> },
          { path: 'payrolls/:id/ElementLink', element: <ElementLink /> },
          { path: 'PayrollElement', element: <PayrollElement /> },
          {
            path: 'PayrollElement/:id/EditElementType',
            element: <EditElementType />,
          },
          { path: 'payrollsRun', element: <PayrollRun /> },
          // {
          //   path: 'payrollsRun/:payrollId/AddPayrollRun',
          //   element: <AddPayrollRun />,
          // },
          {
            path: 'payrollsRun/:payrollId/ActionList',
            element: <ActionList />,
          },
          {
            path: 'payrollsRun/:payrollId/ActionList/:PayrollActionId/AssignmentActionList',
            element: <SalaryReport />,
          },
          { path: 'QuickPay', element: <QuickPay /> },
          // {
          //   path: 'QuickPay/:payrollId/:assignmentId/AddQuickPay',
          //   element: <AddQuickPay />,
          // },
          { path: 'PayrollElementEntry', element: <PayrollElementEntry /> },
          {
            path: 'PayrollElementEntry/:assignmentId/AddPayrollElementEntry',
            element: <AddPayrollElementEntry />,
          },
          {
            path: 'PayrollElementEntry/:assignmentId/AddPayProposal',
            element: <AddPayProposal />,
          },
        ],
      },

      //________HRsettings________________________
      {
        path: 'HRsettings/*',
        children: [
          { path: 'Job', element: <Job /> },
          { path: 'Job/:id/Edit', element: <EditJob /> },
          { path: 'JobGrade', element: <JobGrades /> },
          { path: 'JobGrade/:id/Edit', element: <EditJobGrade /> },
          { path: 'Location', element: <Location /> },
          { path: 'Location/:id/Edit', element: <EditLocation /> },
          { path: 'Position', element: <Position /> },
          { path: 'Position/:id/Edit', element: <EditPosition /> },
          { path: 'StaffGroup', element: <GroupEmployees /> },
          { path: 'StaffGroup/:id/Edit', element: <EditGroupEmployees /> },
        ],
      },

      // ______________________RAQEEEEEEEB_____________________
      {
        path: 'RAQ/*',
        children: [
          { path: 'RotationPlan', element: <RotationPlan /> },

          {
            path: 'RotationPlan/:id/RotationSetting',
            element: <RotationSetting />,
          },
          { path: 'AttendancAnalysis', element: <AttendanceAnalysis /> },
          {
            path: 'AttendancAnalysis/AnalysisTable',
            element: <AnalysisTable />,
          },
          {
            path: 'UpdateRaqeebData',
            element: <UpdateRaqeebData />,
          },
        ],
      },

      //________HRReports________________________
      {
        path: 'HR/Reports/*',
        children: [
          { path: '*', element: <HRReports /> },
          { path: ':ReportId/ReportsBuilder', element: <ReportsBuilder /> },
        ],
      },

      //____________HR/DASHBOARD____________________
      {
        path: 'HrDashboard/*',
        children: [
          { path: '*', element: <DashboardHR /> },
          { path: ':dashboardId/BuilderDashboard', element: <BuilderDashboard /> },
          // { path: ":dashboardId/BuilderDashboard", element: <BuilderDashboard /> },
        ],
      },

      //________________________HRRequest________________________
      {
        path: 'HRAme/Requests/*',
        children: [{ path: '*', element: <HRRequests /> }],
      },

      //#endregion

      //#region NTX
      //_____________________ NTX Routes ________________________________________________
      { path: 'Forms', element: <Forms /> },
      {
        path: 'NTX/*',
        children: [
          { path: 'Forms', element: <Forms /> },
          { path: 'Forms/:id/EditForm', element: <EditForm /> },
          { path: 'Forms/:id/AddFormColumns', element: <AddFormColumn /> },
          // {
          //   path: "FlexValue/:id/EditFormColumns",
          //   element: <EditFormColumn />,
          // },
          { path: 'ListSystem', element: <ListSystem /> },
          { path: 'ListSystem/:id/AddFlexValue', element: <AddFlexValue /> },
          { path: 'ListSystem/:id/AddSubLists', element: <AddSubLists /> },

          // { path: "ExtraInformationForm", element: <ExtraInformationForm /> },
          // {
          //   path: "ExtraInformationForm/:id/EditExtraInformationForm",
          //   element: <EditExtraInformationForm />,
          // },

          // { path: "ExtraRecordsForm", element: <ExtraRecordsForm /> },
          // {
          //   path: "ExtraRecordsForm/:id/EditExtraRecordsForm",
          //   element: <EditExtraRecordsForm />,
          // },
        ],
      },

      //________________ NTX Forms __________________________________________
      {
        path: 'Forms/*',
        children: [
          { path: 'ExtraInformationForm', element: <ExtraInformationForm /> },
          {
            path: 'ExtraInformationForm/:id/EditExtraInformationForm',
            element: <EditExtraInformationForm />,
          },

          { path: 'ExtraRecordsForm', element: <ExtraRecordsForm /> },
          {
            path: 'ExtraRecordsForm/:id/EditExtraRecordsForm',
            element: <EditExtraRecordsForm />,
          },
        ],
      },
      //________________ Dashboard NTX __________________________________________
      { path: 'NtxDashboardManagement', element: <DashboardManagement /> },
      { path: 'NtxDashboardManagement/AddUpdate', element: <AddDashboard /> },
      { path: 'NtxDashboardManagement/:DashboardId/UpdateDashBoard', element: <UpdateDashBoard /> },

      //________________ Security NTX __________________________________________

      {
        path: 'Security/*',
        children: [
          { path: 'Users', element: <Users /> },
          { path: 'Users/:userId/Edit', element: <EditUser /> },
          {
            path: 'Users/ChangePassword',
            element: <ChangePassword />,
          },
          {
            path: 'Users/:userId/RulesUser',
            element: <RulesUser />,
          },
          {
            path: 'Users/:refrenceId/PermissionUser',
            element: <PermissionUser />,
          },
          {
            path: 'AuthRules/:refrenceId/PermissionRule',
            element: <PermissionRule />,
          },
          {
            path: 'AuthRules',
            element: <AuthRules />,
          },
        ],
      },
      //
      { path: 'NTX/*', children: [{ path: 'Lookup', element: <Lookup /> }] },
      // { path: "NTX/*", children: [{ path: "Peoples", element: <NtxPeoples/> }] },
      //____________________________ Reports NTX __________________________________________

      {
        path: 'RPT/Reports/*',
        children: [
          { path: '*', element: <Reports /> },
          { path: 'AddReport', element: <AddReport /> },
          { path: ':id/UpdateReport', element: <UpdateReport /> },
          { path: ':id/AddReportShape', element: <AddReportShape /> },
        ],
      },
      {
        path: 'RPT/PrintingForms/*',
        children: [
          { path: '*', element: <PrintingForms /> },
          { path: 'AddPrintingForms', element: <AddPrintingForms /> },
          { path: ':id/UpdatePrintingForms', element: <UpdatePrintingForms /> },
          // { path: ":id/AddReportShape", element: <AddReportShape /> },
        ],
      },

      //_______ Notifications
      {
        path: 'NTF/*',
        children: [
          { path: 'Notifications', element: <Notifications /> },
          {
            path: 'Notifications/AddNotifications',
            element: <AddNotifications />,
          },
          {
            path: 'Notifications/:id/UpdateNotifications',
            element: <UpdateNotifications />,
          },
        ],
      },

      ///________________ Application And Settings
      {
        path: 'Neatix/Applications',
        element: <Applications />,
      },
      {
        path: 'Neatix/Settings',
        element: <NtxSettings />,
      },
      {
        path: 'Neatix/Profile',
        element: <NtxProfile />,
      },

      //#endregion

      //#region AME
      //_________________________________ Ame__________________________________________
      {
        path: 'Ame/*',
        children: [
          { path: 'RequestSetting', element: <RequestSettings /> },
          {
            path: 'RequestSetting/:id/AddAmeCondition',
            element: <AddAmeCondition />,
          },
          { path: 'RequestSetting/:id/AddAmeRule', element: <AddAmeRule /> },
          {
            path: 'RequestSetting/:id/AddAmeRule/:ruleId/AddAmeConditionUsage',
            element: <AddAmeConditionUsage />,
          },
          {
            path: 'RequestSetting/:id/AddAmeRule/:ruleId/AddAmeAction',
            element: <AddAmeAction />,
          },
          // Conditions & Rules
          {
            path: 'Conditions',
            element: < Conditions/>,
          },
          {
            path: 'Conditions/:id/AddAmeCondition',
            element: <AddAmeCondition />,
          },
          {
            path: 'Rules',
            element: < Rules/>,
          },



         // ApprovalGroup
          { path: 'ApprovalGroup', element: <ApprovalGroup /> },
          {
            path: 'ApprovalGroup/:id/AddApprovalMember',
            element: <AddApprovalMember />,
          },
          {
            path: 'ApprovalGroup/:id/EditApprovalGroup',
            element: <EditApprovalGroup />,
          },

          // Follow Requests متابعة الطلبات
           { path: 'FollowRequests', element: <AllRequests /> },
        ],
      },

      // ______________________________ SelfService __________________________________________________
      {
        path: 'SelfService/*',
        children: [
          { path: 'Requests', element: <AmeRequests /> },
          {
            path: 'Requests/:FormId/AddRequest',
            element: <AddRequests />,
          },
          {
            path: 'Requests/:TransactionHistoryId/ViewRequests',
            element: <ViewRequests />,
          },
          {
            path: 'Requests/:TransactionHistoryId/OnlyViewRequests',
            element: <ViewRequests isAction={false} />,
            // element: <OnlyViewRequests />,
          },
          { path: 'MyRequests', element: <MyRequests /> },
          { path: 'AllRequests', element: <AllRequests /> },
        ],
      },

      //#endregion

      //#region SU
      //___________________ SU _____________________________
      {
        path: 'SU/*',
        children: [
          { path: 'Contracts', element: <Contracts /> },
          { path: 'Contracts/AddContarcts', element: <AddContracts /> },
          { path: 'Contracts/:id/UpdateContract', element: <UpdateContract /> },
          { path: 'RealEstate', element: <RealEstate /> },

          {
            path: 'RealEstate/:Id/UpdateRealEstate',
            element: <UpdateRealEstate />,
          },
          {
            path: 'RealEstate/:RealEstateId/ExtraRecordRealEstate',
            element: <ExtraRecordRealEstate />,
          },
          {
            path: 'RealEstate/:Id/Accomodation',
            element: <Accomodation />,
          },

          {
            path: 'FollowPayments/InstallmentUnPayment',
            element: <InstallmentUnPaymentList />,
          },
          {
            path: 'FollowPayments/InstallmentPayment',
            element: <InstallmentPaymentList />,
          },

          {
            path: 'SU_ExtraInformationDynamicType',
            element: <SUExtraInformationDynamicType />,
          },
          {
            path: 'SU_ExtraInformationDynamicType/:FormId/Edit',
            element: <EditExtraInformationDynamicType />,
          }, // ستكون لكل موديول صفحة تعديل خاصة بمسار خاص ممكن نهندله بطريقة أفضل

          { path: 'Reports', element: <SUReports /> },
          {
            path: 'Reports/:ReportId/ReportsBuilder',
            element: <ReportsBuilder />,
          },
        ],
      },

      { path: 'SUSettings/Branches', element: <Branches /> },
      { path: 'SUSettings/Branches/:id/Edit', element: <UpdateBranches /> },
      { path: 'SUSettings/Locations', element: <FleetLocations /> },
      { path: 'SUSettings/Locations/:id/Edit', element: <UppdateLocations /> },

      //#endregion

      //#region Fleet

      //_______________ FLEET ________________________________
      {
        path: 'FLEETSettings/*',
        children: [
          { path: 'Workshop', element: <Workshop /> },
          { path: 'Workshop/:id/Edit', element: <UppdateWorkShop /> },

          { path: 'Locations', element: <FleetLocations /> },
          { path: 'Locations/:id/Edit', element: <UppdateLocations /> },

          { path: 'Manufacturers', element: <EquipmentsModel /> },
          { path: 'Manufacturers/:id/Edit', element: <EditManufacturer /> },

          { path: 'Model', element: <Model /> },
          { path: 'Model/:id/Edit', element: <EditModel /> },

          { path: 'FleetElementScan', element: <FleetElementScan /> },
          {
            path: 'FleetElementScan/:lookupCode/AddScanElement',
            element: <AddElementScan />,
          },

          { path: 'Drivers', element: <Drivers /> },
          { path: 'Drivers/:Id/Edit', element: <EditDrivers /> },

          { path: 'Owners', element: <Owners /> },
          { path: 'Owners/:Id/Edit', element: <EditOwners /> },

          { path: 'Suppliers', element: <Suppliers /> },
          { path: 'Suppliers/:Id/Edit', element: <EditSuppliers /> },
        ],
      },

      {
        path: 'FLEET/*',
        children: [
          { path: 'EquipmentInspection', element: <EquipmentInspection /> },
          {
            path: 'EquipmentInspection/:fleetEquipmentsId/AddScan',
            element: <AddScan />,
          },
          {
            path: 'EquipmentInspection/:fleetEquipmentsId/ScanningList',
            element: <ScanningList />,
          },
          {
            path: 'EquipmentInspection/:fleetEquipmentsId/ScanningList/:id/Edit',
            element: <UpdateScan />,
          },

          { path: 'Equipments', element: <Equipments /> },
          { path: 'Equipments/:Id/Edit', element: <EditEquipments /> },
          { path: 'Equipments/:Id/ExtraRecordEquipments', element: <ExtraRecordEquipments /> },

          {
            path: 'Equipments/:Id/EquipmentsAssignation',
            element: <EquipmentsAssignment />,
          },
          {
            path: 'Equipments/:Id/EquipmentsOdometer',
            element: <EquipmentsOdometer />,
          },

          {
            path: 'Equipments/:Id/EquipmentRoutes',
            element: <AddEquipmentRoutes />,
          },
          {
            path: 'Equipments/:Id/EquipmentRoutes/:equipmentRoutesID/Edit',
            element: <EditEquipmentRoutes />,
          },
          {
            path: 'Equipments/:Id/EquipmentRoutes/:equipmentRoutesID/AssignmentRoutes',
            element: <AssignmentRoutes />,
          },

          { path: 'WorkRequests', element: <WorkRequests /> },
          {
            path: 'WorkRequests/:Id/AddWorkRequest',
            element: <AddWorkRequest />,
          },
          {
            path: 'WorkRequests/:Id/AddMaintenanceOrder/:fleetEquipmentsId',
            element: <AddOrderMaintenance />,
          },

          {
            path: 'Maintenance/MaintenanceOrder',
            element: <MaintenanceOrder />,
          },
          {
            path: 'Maintenance/MaintenanceOrder/:Id/AddMaintenanceOrder/:fleetEquipmentsId',
            element: <AddOrderMaintenance />,
          },
          {
            path: 'Maintenance/MaintenanceOrder/:Id/CloseOrder',
            element: <CloseOrder />,
          },

          { path: 'Reports', element: <FLEETReports /> },
          {
            path: 'Reports/:ReportId/ReportsBuilder',
            element: <ReportsBuilder />,
          },

          { path: 'FleetDashboard', element: <DashboardFLEET /> },
          { path: 'FleetDashboard/:dashboardId/BuilderDashboard', element: <BuilderDashboard /> },
        ],
      },

      //________________________FLEEET Request________________________
      {
        path: 'FLEET/Maintenance/Requests/*',
        children: [{ path: '*', element: <FLEETRequest /> }],
      },
      //#endregion

      //#region Finance
      //_______________ Finance ________________________________
      {
        path: 'FinanceSettings/*',
        children: [
          { path: 'FiscalYear', element: <FiscalYear /> },
          { path: 'DailyRates', element: <DailyRates /> },
          { path: 'JeCategory', element: <JeCategory /> },
          { path: 'JeSource', element: <JeSource /> },
          { path: 'VAT', element: <Vat /> },
          { path: 'AccountingPeriod', element: <AccountingPeriod /> },
          { path: 'ChartAccounts', element: <ChartAccounts /> },
          {
            path: 'ChartAccounts/:ChartAccountsId/ChartAccountsDetails',
            element: <ChartAccountsDetails />,
          },
          {
            path: 'ChartAccounts/:ChartAccountsId/CompaniesAndBranches',
            element: <CompaniesAndBranches />,
          },
          {
            path: 'ChartAccounts/:ChartAccountsId/CostCenters',
            element: <CostCenters />,
          },

          { path: 'Ledgers', element: <Ledgers /> },

          { path: 'ConsolidationSet', element: <ConsolidationSet /> },
          { path: 'ConsolidationSet/:type', element: <IndexConsolidationSet /> },


          { path: 'BankAccounts', element: <BankAccounts /> },
          

        ],
      },
      {
        path: 'Finance/*',
        children: [
          { path: 'Journals', element: <Journals /> },
          { path: 'Journals/:ledgerId/AddJournals', element: <AddJournals /> },
          { path: 'Journals/:Id/UpdateJournals', element: <UpdateJournals /> },
          { path: 'PostingJournals', element: <UnPostedJournalsList /> },
        ],
      },
      {
        path: 'Finance/Ap',
        children: [
          { path: 'ApInvoices', element: <ApInvoices /> },
          { path: 'ApInvoices/AddApInvoices/:referenceId?', element: <AddApInvoices /> },
        ],
      },

      //#endregion


      //#region Sales:
      //_______________ Sales ________________________________
      {
        path: 'SalesSettings/*',
        children: [
          { path: 'Customers', element: <Customers /> },
          { path: 'Customers/:Id/UpdateCustomers', element: <UpdateCustomers /> },
          { path: 'Customers/:id/BranchesCustomer', element: <BranchesCustomer /> },
          // { path: "Customers/:id/BranchesCustomer", element: <CustomersContacts /> },
          // { path: 'Customers/:id/CustomersContacts', element: <CustomersContacts /> },

          { path: 'PriceList', element: <PriceList /> },
          { path: 'PriceList/:id/DetailsPriceList', element: <DetailsPriceList /> },

          { path: 'Vouchers', element: <Vouchers /> },
          { path: 'Vouchers/AddVouchers', element: <AddVouchers /> },
          
          { path: 'Vouchers2', element: <Vouchers2 /> },
          { path: 'Vouchers2/:voucherTypeId/VoucherType', element: <VoucherType /> },
          { path: 'Vouchers2/:voucherTypeId/VoucherType/:voucherId?/BuyXgetY', element: <BuyXgetY /> },
           
        ],
      },

      {
        path: 'SalesTransactions/*',
        children: [
          { path: 'SalesInvoices', element: <SalesInvoices /> },
          { path: 'SalesInvoices/AddSalesInvoices/:id?', element: <AddSalesInvoices /> },
          { path: 'SalesInvoices/:id/UpdateSalesInvoices', element: <UpdateSalesInvoices /> },

          { path: 'Contracts', element: <SalesContracts /> },
          { path: 'Contracts/AddSalesContracts', element: <AddSalesContracts /> },

          { path: 'SellOrder', element: <SellOrder /> },
          { path: 'SellOrder/AddSellOrder/:id?', element: <AddSellOrder /> },
          { path: 'SellOrder/:id/UpdateSellOrder', element: <UpdateSellOrder /> },

          { path: 'Quotation', element: <Quotation /> },
          { path: 'Quotation/AddQuotation', element: <AddQuotation /> },
          { path: 'Quotation/:id/UpdateQuotation', element: <UpdateQuotation /> },

          { path: 'SalesReturn', element: <SalesReturn /> },
          { path: 'SalesReturn/:InvoiceId/AddSalesReturn', element: <AddSalesReturn /> },

          { path: 'DeliveryPermission', element: <DeliveryPermission /> },
          { path: 'DeliveryPermission/AddDeliveryPermission', element: <AddDeliveryPermission /> },
        ],
      },

      {
        path: 'Sales/*',
        children: [
          { path: 'SalesRequests', element: <SalesRequests /> },

          { path: 'SalesDashboards', element: <SalesDashboards /> },
          { path: 'SalesDashboards/:dashboardId/BuilderDashboard', element: <BuilderDashboard /> },

          { path: 'SalesReports', element: <SalesReports /> },
          {
            path: 'SalesReports/:ReportId/ReportsBuilder',
            element: <ReportsBuilder />,
          },
        ],
      },

      //#endregion

 


      //#region Purchases:
   
      {
        path: 'PurchasesSettings/*',
        children: [
          { path: 'SUPPLIERS', element: < PurchasesSuppliers/> },
          { path: 'SUPPLIERS/:Id/UpdateSupplier', element: <UpdateSupplier /> },
          { path: 'SUPPLIERS/:id/BranchesSupplier', element: < BranchesSupplier/> },       
        ],
      },

      {
        path: 'PurchasesTransactions/*',
        children: [

          { path: 'Quotation', element: <PurchaseQuotation /> },
          { path: 'Quotation/AddPurchaseQuotation/:id?', element: <AddPurchaseQuotation /> },
          { path: 'Quotation/:id/UpdatePurchaseQuotation', element: <UpdatePurchaseQuotation /> },


          { path: 'PurchaseRequests', element: <PurchaseRequests /> },
          { path: 'PurchaseRequests/AddPurchaseRequest', element: <AddPurchaseRequest /> },
          { path: 'PurchaseRequests/:id/UpdatePurchaseRequest', element: <UpdatePurchaseRequest /> },


          { path: 'PurchaseOrder', element: <PurchaseOrder /> },
          { path: 'PurchaseOrder/AddPurchaseOrder/:id?', element: <AddPurchaseOrder /> },
          { path: 'PurchaseOrder/:id/UpdatePurchaseOrder', element: <UpdatePurchaseOrder/> },

          { path: 'PurchaseBill', element: <PurchaseBill /> },
          { path: 'PurchaseBill/AddPurchaseBill', element: <AddPurchaseBill /> },
          { path: 'PurchaseBill/:id/UpdatePurchaseBill', element: <UpdatePurchaseBill/> },

          { path: 'PurchaseReturn', element: <PurchaseReturn /> },
          { path: 'PurchaseReturn/:BillId/AddUpdateViewPurchaseReturn', element: <AddUpdateViewPurchaseReturn /> },

        ],
      },

      {
        path: 'Sales/*',
        children: [
          { path: 'SalesRequests', element: <SalesRequests /> },

          { path: 'SalesDashboards', element: <SalesDashboards /> },
          { path: 'SalesDashboards/:dashboardId/BuilderDashboard', element: <BuilderDashboard /> },

          { path: 'SalesReports', element: <SalesReports /> },
          {
            path: 'SalesReports/:ReportId/ReportsBuilder',
            element: <ReportsBuilder />,
          },
        ],
      },

      //#endregion

 
      //#region Pos:
      //_______________ PointOfSale ________________________________

      {
        path: 'Pos/*',
        children: [
          { path: 'PosSettings', element: <Pos /> },
          // { path: "POS", element: <PointOfSale /> },
          { path: 'PosSettings/:posId/PosDetails', element: <PosDetails /> },
          { path: 'PosCard', element: <PosCard /> },
          { path: ':posId/:sessionId/PointOfSaleShop', element: <PointOfSaleShop /> },
          { path: ':posId/:sessionId/PointOfSaleRestaurant', element: <PointOfSaleRestaurant /> },
        ],
      },

      //#endregion

      //#region INVENTORY
      //_______________ INV________________________________
      {
        path: 'INV/InventorySettings/*',
        children: [
          { path: 'Inventory', element: <Inventory /> },
          { path: 'Inventory/:Id/SubInventory', element: <SubInventory /> },
          { path: 'Inventory/:inventoryId/AssignmentItems', element: <AssignmentItems /> },
          { path: 'Categories', element: <CategoriesINV /> },
          { path: 'MasterItems', element: <MasterItems /> },
          { path: 'MasterItems/:ItemId/ItemsDetails', element: <MasterItemsDetails /> },

          { path: 'Suppliers', element: <SuppliersInv /> },
          { path: 'Suppliers/:Id/Edit', element: <EditSuppliersInv /> },

          { path: 'TransactionTypes', element: <TransactionTypes /> },
          { path: 'UnitsOfMeasure', element: <UnitsOfMeasure /> },
        ],
      },

      {
        path: 'INV/Transactions/*',
        children: [
          { path: 'InvIssue', element: <InvIssue /> },
          { path: 'InvIssue/AddInvIssue', element: <AddInvIssue /> },
 
          { path: 'InvReceive', element: <InvReceive /> },
          { path: 'InvReceive/AddInvReceive', element: <AddInvReceive /> },
 
          { path: 'InvMove', element: <InvMove /> },
          { path: 'InvMove/AddInvMove', element: <AddInvMove /> },
 
          // { path: 'PermissionSpend', element: <PermissionSpend /> },
          // { path: 'PermissionSpend/Add', element: <AddPermissionSpend /> },

          // { path: 'PermissionDelivery', element: <PermissionDelivery /> },
          // { path: 'PermissionDelivery/Add', element: <AddPermissionDelivery /> },

          // { path: 'PermissionReceive', element: <PermissionReceive /> },
          // { path: 'PermissionReceive/Add', element: <AddPermissionReceive /> },
        ],
      },

      {
        path: 'INV/InventoryCount/*',
        children: [
          { path: '*', element: <InventoryCount /> },
          { path: 'AddInventoryCount', element: <AddInventoryCount /> },
 
        ],
      },

      //#endregion


      //#region Integration
      {
        path: 'Integration/',
        element: <Integration />,
      },
      //#endregion
    
    
    ],
  },




  // #region outSide <Outlit> ـــ
  {
    path: '/Report/:AssignmentActionId/PayrollForPerson',
    element: <PayrollForPerson />,
  },
  {
    path: '/Report/:PayrollActionId/PayrollForAll',
    element: <PayrollForAll />,
  },
  //#endregion


  //#region Login 
  {
    path: 'login/*',
    children: [
      {
        path: '',
        element: (
          // <TokenCheckWrapper>
            <Login />
          // {/* </TokenCheckWrapper> */}
        ),
      },
      { path: 'ConfirmPassword', element: <ConfirmPassword /> },
    ],
  },
  //#endregion
 
]);

export default AppRoute;
