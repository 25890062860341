/* eslint-disable react-hooks/exhaustive-deps */
 
import { Card, CardBody } from "../../../../../components/card/card.jsx";
 
import Breadcrumb from "../../../../../components/Shared/Breadcrumb";
 
// import Items from "../Items/Items";
 
const AddContracts = () => {
  
   
  var List = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Contracts",
      link: "",
    },
    {
      name: "Add_Contract",
      link: null,
    },
  ];

  //_______________

   
  return (
    <>
      <Breadcrumb BreadcrumbList={List} PageName="Add_Contract" />
      <hr className="mb-3" />

      <CardBody>
      
        <hr className="mb-0" />
      </CardBody>
    </>
  );
};

export default AddContracts;
