

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { Equipments } from "./equipmentsModels";



export const  GetEquipmentsList = createAsyncThunk(
    "Equipments/GetEquipmentsList",
    async (_, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get('Fleet/GetEquipmentsList');

        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );

export const  GetEquipmentsById = createAsyncThunk(
    "Equipments/GetEquipmentsById",
    async (id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get(`Fleet/GetEquipmentsById?Id=${id}`);
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );


  export const InsertEquipments = createAsyncThunk('Equipments/InsertEquipments',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Fleet/AddEquipments" , item , {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });


  export const UpdateEquipments = createAsyncThunk('Equipments/UpdateEquipments',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Fleet/UpdatEquipments" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });

  


const initialState: {
    EquipmentsList : Equipments[]
    EquipmentsById? : Equipments
    loading: boolean;
    error: any | null;
  } = {
    EquipmentsList: [],
    loading: false,
    error: null,

  };


  

const equipmentsSlice = createSlice({
    name: "Equipments",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder

      .addCase(InsertEquipments.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(InsertEquipments.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.EquipmentsList.push(action.payload?.data); 
      })
      .addCase(InsertEquipments.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


    .addCase(GetEquipmentsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetEquipmentsList.fulfilled, (state, action: PayloadAction<any> ) => {
        state.loading = false;
        state.EquipmentsList = action.payload;
      })
      .addCase(GetEquipmentsList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })



    .addCase(GetEquipmentsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetEquipmentsById.fulfilled, (state, action: PayloadAction<Equipments> ) => {
        state.loading = false;
        state.EquipmentsById = action.payload;
      })
      .addCase(GetEquipmentsById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      
    },
  });
  
  export default equipmentsSlice.reducer;