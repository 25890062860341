import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetAbsenceAttendanceType,
  cleanAbsenceAttendanceTypeList,
} from '../../store/HR/Person/absenceSlice';

const useAbsenceType = (personId) => {
  const dispatch = useDispatch();
  // const { personId} = useParams();

  useEffect(() => {
    dispatch(GetAbsenceAttendanceType(personId));

    return () => {
      dispatch(cleanAbsenceAttendanceTypeList());
    };
  }, [personId]);

  const { AbsenceAttendanceTypeList } = useSelector((state) => state.Absence);

  return { AbsenceAttendanceTypeList };
};

export default useAbsenceType;
