import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../API/axiosNeatex";



export const GetApprovalGroupList = createAsyncThunk("ApprovalGroup/GetApprovalGroupList", async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.get(`AmeApprovalGroup/GetApprovalGroupList` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

  export const GetApprovalGroupById = createAsyncThunk("ApprovalGroup/GetApprovalGroupById", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    
    try {
      const {data} = await ntxAPI.get( `AmeApprovalGroup/GetApprovalGroupById?Id=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

  export const insertAprovalGroup = createAsyncThunk("ApprovalGroup/insertAprovalGroup", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.post("AmeApprovalGroup/AddApprovalGroup" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

  export const UpdateAprovalGroup = createAsyncThunk("ApprovalGroup/UpdateAprovalGroup", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.post("AmeApprovalGroup/UpdateApprovalGroup" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });
  





  const initialState = { ApprovalGroupList: [] , ApprovalGroupListById: [] ,  loading: false, error: null };

  const ApprovalGroupSlice = createSlice({
    name: "ApprovalGroup",
    initialState,
    reducers: {
    },
    extraReducers(builder) {
      builder
        /////// get Approval Group list
        .addCase(GetApprovalGroupList.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(GetApprovalGroupList.fulfilled, (state, action) => {
          state.loading = false;
          state.ApprovalGroupList = action.payload;
        })
        .addCase(GetApprovalGroupList.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
    
        //// insert Approval Group
        .addCase(insertAprovalGroup.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(insertAprovalGroup.fulfilled, (state, action) => {
          state.loading = false;
          state.ApprovalGroupList.push(action.payload);
        })
        .addCase(insertAprovalGroup.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
    
        /////// get Approval Group By ID
        .addCase(GetApprovalGroupById.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(GetApprovalGroupById.fulfilled, (state, action) => {
          state.loading = false;
          state.ApprovalGroupListById = action.payload;
        })
        .addCase(GetApprovalGroupById.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
    
        /////// update Approval Group By ID
        .addCase(UpdateAprovalGroup.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(UpdateAprovalGroup.fulfilled, (state, action) => {
          state.loading = false;
          state.ApprovalGroupListById = action.payload;
        })
        .addCase(UpdateAprovalGroup.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  
  export default ApprovalGroupSlice.reducer;