import { useFormik } from 'formik';
// import React, { useContext, useEffect } from "react";
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useGetPersons from '../../../../hooks/HR/use-get-persons';
import useGetOrganizations from '../../../../hooks/HR/use-get-organizations';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch } from '../../../../store/hooks';
import useGetPeoplesList from '../../../../hooks/NTX/useGetPeoplesList';
import useLookupInventoryTransactions from '../../../../hooks/Lookups/use-Lookup-InventoryTransactions';
import InvLines from '../TransactionsLines/InvLines';
import { addReceiveTransactions } from '../../../../store/INV/Transactions/InvReceive/invReceiveSlice';
import { useNavigate } from 'react-router-dom';
import useGetTransactionTypesReceiveList from '../../../../hooks/INV/InvReceive/useGetTransactionTypesReceiveList';
import Reference from './Reference';
import { TreeSelect } from 'antd';
import useGetCustomersList from '../../../../hooks/NTX/Peoples/useGetCustomersList';
import useGetSuppliersList from '../../../../hooks/NTX/Peoples/useGetSuppliersList';
import InvReceiveLines from './InvReceiveLines';

const Add = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();
  const { dataEmployees, loading } = useGetPersons();
  const { BranchsOptions, InventoryOptions, loading: loading3 } = useGetOrganizations();
  const { INVENTORY_TRANSACTIONS } = useLookupInventoryTransactions();

  const {
    treeCustomerData,
    OptionCustomers,
    error: error2,
    loading: loading2,
  } = useGetCustomersList();
  const { treeSuppliersData, error: error3, loading: loading5 } = useGetSuppliersList();

  const { OptionsTransactionTypes } = useGetTransactionTypesReceiveList();

  //#region Formik
  const formik = useFormik({
    initialValues: {
      code: '',
      transactionDate: '',
      description: '',
      branchId: '',
      // transactionTypeId: '',
      // transactionTypeName: '',
      transactionAmount: 0,
      referenceType: '',
      referenceId: '',
      // referenceCode: '',
      // referenceDate: '',
      supplierId: '',
      customerId: '',
      // branchTo: '',
      personId: '',
      // autoManual: true,
      // keeperId: '',
      // driverId: '',
      // fleetEquipmentsId: '',
      inventoryId: '',
      // inventoryTo: '',
      // directTransfer: 0,
      address: '',
      note: '',
      no: 0,
      transactionYear: 0,
      materialTransactionsLineList: [] as any[],
    },
    enableReinitialize: true,
    // validationSchema: AddGlJeHeaderSchema(t),
    onSubmit: (values) => {
      dispatch(
        addReceiveTransactions({
          code: values.code,
          transactionDate: values.transactionDate,
          description: values.description,
          branchId: values.branchId,
          transactionAmount: 0,
          referenceType: values.referenceType,
          referenceId: values.referenceId || null,
          supplierId: values.supplierId || null,
          customerId: values.customerId,
          personId: values.personId,
          inventoryId: values.inventoryId,
          address: values.address,
          note: values.note,
          no: 0,
          transactionYear: 0,

          materialTransactionsLineList: formik.values.materialTransactionsLineList.map((item) => ({
            transactionItemId: item.itemId,
            transactionUom: item.uom,
            unitPrice: 0, // item.price
            transactionQuantityIn: 0,
            transactionQuantityOut: item.qty,
            inventoryId: null,
            discount: 0,
            codeCombinationId: null,
            currencyCode: '',
            currencyConversionRate: 0,
            referenceIdMaster: null,
            referenceIdLine: null,
            transactionTypeId: null,
            equivalent: 0,
            expensesAmount: 0,
            batchId: null,
          })),
        })
      )
        .unwrap()
        .then((res: any) => {
          // debugger;
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  const { values, handleChange: formikhandleChange, errors, touched } = formik;
  //#endregion

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'InvReceive',
      link: '',
    },
    {
      name: 'Add InvReceive',
      link: null,
    },
  ];

  console.log(values);

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="Add InvReceive" />

      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-lg-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t('التاريـخ')}</label>
                  <Form.Control
                    className="form-control  text-center"
                    type="date"
                    name="transactionDate"
                    onChange={formikhandleChange}
                    value={values.transactionDate || ''}
                    isInvalid={!!(touched.transactionDate && errors.transactionDate)}
                    isValid={formik.touched.transactionDate && !formik.errors.transactionDate}
                  />
                </div>
              </div>

              <div className="col-lg-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t('المخزن')}</label>
                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={InventoryOptions}
                    onChange={(option: any) => {
                      formik.setFieldValue('inventoryId', option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>

              <div className="col-lg-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t('الفرع')}</label>
                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={BranchsOptions}
                    value={BranchsOptions.find((f: any) => f.value === formik.values.branchId)}
                    onChange={(option: any) => {
                      formik.setFieldValue('branchId', option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>

              <div className="col-lg-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t('نوع الحركة')}</label>
                  <Select
                    classNamePrefix="react-select"
                    // isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={OptionsTransactionTypes}
                    onChange={(option: any) => {
                      formik.setFieldValue('code', option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t('المرجع')}</label>
                  <Reference formik={formik} />
                </div>
              </div>

              {['BIL', 'POR'].includes(values.code) && (
                <div className="col-lg-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('المورد')}</label>
                    <TreeSelect
                      loading={loading5}
                      showSearch
                      className="w-100"
                      treeLine
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      allowClear
                      treeDefaultExpandAll={false} // لا تقم بتوسيع كل العقد افتراضيًا
                      // loadData={} // يتم تحميل البيانات عند الطلب
                      // onSearch={} // تنفيذ البحث عند كتابة المستخدم
                      value={values.supplierId}
                      onChange={(option: any) => {
                        formik.setFieldValue('supplierId', option === null ? null : option);
                      }}
                      treeData={treeSuppliersData}
                      treeNodeFilterProp="title"
                    />
                  </div>
                </div>
              )}

              {['INV', 'SOI'].includes(values.code) && (
                <div className="col-lg-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('العميل')}</label>
                    <TreeSelect
                      loading={loading2}
                      showSearch
                      className="w-100"
                      treeLine
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      allowClear
                      treeDefaultExpandAll={false} // لا تقم بتوسيع كل العقد افتراضيًا
                      // loadData={} // يتم تحميل البيانات عند الطلب
                      // onSearch={} // تنفيذ البحث عند كتابة المستخدم
                      // value={formik.values.supplierId ? { value: formik.values.supplierId, label: selectedSupplierLabel } : undefined}
                      value={values.customerId}
                      onChange={(option: any) => {
                        formik.setFieldValue('customerId', option === null ? null : option);
                      }}
                      treeData={treeCustomerData}
                      treeNodeFilterProp="title"
                      labelInValue
                    />
                  </div>
                </div>
              )}

              {/* <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('personId')}</label>
                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading}
                    isSearchable={true}
                    isClearable
                    name="personId"
                    options={dataEmployees}
                    onChange={(option: any) => {
                      formik.setFieldValue('personId', option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div> */}

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="form-label">{t('البيــان')}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control"
                    as="textarea"
                    rows={1}
                    name="description"
                    onChange={formik.handleChange}
                    value={values.description}
                    isInvalid={!!(touched.description && errors.description)}
                    isValid={touched.description && !errors.description}
                  />
                  <div></div>
                </div>
              </div>
            </div>

            <div className="row m-0 p-0">
              <div className="bg-inverse bg-opacity-5 p-2">
                {/* <TransactionsInvLines formik={formik} /> */}
                {/* <InvLines formik={formik} /> */}
                <InvReceiveLines formik={formik}/>
              </div>
            </div>

            <div className="text-center mt-3">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn btn-lg me-1 btn-theme mb-1">
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                )}
                {t('Add')}
              </button>
              <button
                type="button"
                className="btn btn-lg  me-1 btn-default mb-1"
                onClick={() => Navigate(-1)}>
                <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
              </button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default Add;
