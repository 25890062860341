import React, { Fragment, useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../../../../components/Shared/Breadcrumb";
import TestComponent from "../../../../ExtraInformation/ExtraRecordsDynamicForm/BuilderExtraRecord/Tabs/TestComponent";
import { useLocation, useParams } from "react-router-dom";
import { GetFormRecordWithValue } from "../../../../../store/ExtraInformation/extraInformationSlice";
import { useTranslation } from "react-i18next";

const ExtraRecordFormPerson = () => {
  const { t, i18n } = useTranslation();
  const { PersonId } = useParams(); // ReferenceId
  const location = useLocation();
  const dispatch = useDispatch();
  const [refreshData, setRefreshData] = useState(true);
  //__________________________________________________
  const tableId = "cd5aa419-0e0b-48fe-a66f-f004d97964a2"; // جدول الموظفين

  useEffect(() => {
    if (refreshData) {
      dispatch(
        GetFormRecordWithValue({
          TableId: tableId,
          ReferenceId: PersonId,
        })
      );
      setRefreshData(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);
  const { FormRecordWithValueList } = useSelector(
    (state) => state.ExtraInformation
  );

//#region 
const [referenceName, setReferenceName] = useState(null); // State to hold ReferenceName
useEffect(() => {
  if (location.state && location.state.ReferenceName) {

    const { ReferenceName } = location.state;
    if (ReferenceName) {
      setReferenceName(ReferenceName); // Update ReferenceName state
    }
  }
}, []);
//#endregion

  //________________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Employees",
      link: "/HR/Employees",
    },
    {
      name: t('ExtraRecord'),
      link: null,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb BreadcrumbList={BcrumbList} subtitle={referenceName} />
      {/* PageName="السجلات الإضافية" */}

      <TestComponent
        setRefreshData={setRefreshData}
        FormRecordWithValueList={FormRecordWithValueList}
        ReferenceId={PersonId}
      />

    </Fragment>
  );
};

export default ExtraRecordFormPerson;
