import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { GetExtraInformationRecordTablesFormList } from "../../store/NTX/FlexValue/Form/formSlice";

const useGetExtraInformationRecordTablesForm = () => { 
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetExtraInformationRecordTablesFormList());
  }, []);

  const { ExtraInformationRecordTablesFormList, loading, error } = useSelector(
    (state) => state.Forms
  );

  const dataExtraInformationRecordTablesFormList =
  ExtraInformationRecordTablesFormList &&
  ExtraInformationRecordTablesFormList.map((item) => ({
      value: item.id,
      label: i18n.language === "ar" ? item.name2 : item.name,
    }));

  return { ExtraInformationRecordTablesFormList, dataExtraInformationRecordTablesFormList, loading, error };
};

export default useGetExtraInformationRecordTablesForm;
