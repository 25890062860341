import { Badge } from 'react-bootstrap';
import i18n from 'react-i18next';

export const OptionStatus = [
  {
    value: 'IN PROGRESS"',
    label: (
      <Badge bg="primary" className="p-1 w-75">
        IN PROGRESS - قيد المعالجة
      </Badge>
    ),
 
  },

  // {
  //   value: 'TIMEOUT',
  //   label: (
  //       <Badge bg="warning" className="p-1">
  //        Timeout - انتهت المدة
  //       </Badge>
  //     ),
  // },


//   {
//     value: 'WAITING',
//     label: (
//         <Badge bg="primary" className="p-1">
//          Waiting - منتظر 
//         </Badge>
//       ),
//   },

  // {
  //   value: 'CREATED',
  //   label: (
  //       <Badge bg="info" className="p-1">
  //        Created - تم الإنشاء
  //       </Badge>
  //     ),
  // },
  {
    value: 'APPROVED',
    label: (
        <Badge bg="success" className="p-1 w-75">
         Approved - تمت الموافقة
        </Badge>
      ),
  },
  {
    value: 'CANCELED',
    label: (
        <Badge bg="secondary" className="p-1 w-75">
         Canceled - ملغي
        </Badge>
      ),
  },
  {
    value: 'REJECTED',
    label: (
        <Badge bg="danger" className="p-1 w-75">
         REJECTED - مرفوض
        </Badge>
      ),
  },
];
