

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { BanksModel } from "./banksModels";
 



export const  GetBankList = createAsyncThunk(
    "Banks/GetBankList",
    async (_, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get('NtxSetting/GetBankList');
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );

export const  GetBanksById = createAsyncThunk(
    "Banks/GetBanksById",
    async ( id : string , thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get(`NtxSetting/GetBanksById?Id=${id}`);
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );


  export const InsertBanks = createAsyncThunk('Banks/InsertBanks',
   async (item: any , {rejectWithValue} ) => {
    try {
      const {data} = await ntxAPI.post( "NtxSetting/AddBanks" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });


  export const UpdateBanks = createAsyncThunk('Banks/UpdateBanks',
   async (item: any , {rejectWithValue} ) => {
    try {
      const {data} = await ntxAPI.post( "NtxSetting/UpdatBanks " , item )
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });


 
  


const initialState: {
    BanksList : BanksModel[]
    BankById? : BanksModel
    loading: boolean;
    error: any | null;
  } = {
    BanksList: [],
    BankById : {} as BanksModel,
    loading: false,
    error: null,

  };


  

const banksSlice = createSlice({
    name: "Banks",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder

      .addCase(InsertBanks.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(InsertBanks.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.BanksList.push(action.payload?.data); 
      })
      .addCase(InsertBanks.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


    .addCase(GetBankList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetBankList.fulfilled, (state, action: PayloadAction<any> ) => {
        state.loading = false;
        state.BanksList = action.payload;
      })
      .addCase(GetBankList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })



    .addCase(GetBanksById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetBanksById.fulfilled, (state, action: PayloadAction<BanksModel> ) => {
        state.loading = false;
        state.BankById = action.payload;
      })
      .addCase(GetBanksById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      
    },
  });
  
  export default banksSlice.reducer;