
import React, { Fragment } from "react";
import AddDepartment from "../../../HR/Departments/AddDepartment";

const AddWorkshop = () => {


//   {
//     "meaning2": "ورشة",
//     "meaning": "Workshop",
//     "lookupCode": "160"
// },

  return (
<Fragment>
  <AddDepartment type="160"/>
</Fragment>
  );
};

export default AddWorkshop;
