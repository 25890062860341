import   { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
// import { useTranslation } from "react-i18next";
import { GetNotificationList } from "../../store/NTX/Notifications/notificationSlice";

const useGetNotificationList= () => {

  const dispatch = useAppDispatch();
  // const { i18n } = useTranslation();


    //__________________________________________________
    const { NotificationsList, loading, } = useAppSelector(
      (state) => state.Notifications
    );
    useEffect(() => {
      dispatch(GetNotificationList());
    }, [dispatch]);
  

 
  return {NotificationsList , loading };
};

export default useGetNotificationList;
