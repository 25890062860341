import { useFormik } from "formik";
import React  from "react";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  GetTablesForm,
  insertFlexValueSet,
} from "../../../../../store/NTX/FlexValue/flexValueSetSlice";
 
import CustomAlert from "../../../../../components/Shared/Alert/CustomAlert";
import { AddFlexValueDynamicSchema } from "../../../../ValidationForm/validationSchema";
import { GetLookupManagList } from "../../../../../store/NTX/Lookup/lookupsSlice";
import LookupTable from "./LookupTable";

// valiedation scheme

const AddFlexValueSet = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

 
  useEffect(() => {
    dispatch(GetTablesForm());
    dispatch(GetLookupManagList());
  }, []);
  const { TablesFormList } = useSelector((state) => state.FlexValueSet);
  const { LookupData } = useSelector((state) => state.LookupData);

  const formik = useFormik({
    initialValues: {
      lookupTypeForGetDataByType: null, // not for post
      radioValue: "D", // not for post
      name: "",
      name2: "",
      validationType: "", ////تجاهلها
      formatType: "",
      description: "",
      tableId: "",
      lookupType: "",
      lookupCode: "",
      //_________
    },
    validationSchema: AddFlexValueDynamicSchema(t),
    onSubmit: (values) => {
      // debugger;
      dispatch(
        insertFlexValueSet({
          name: values.name,
          name2: values.name2,
          validationType: null, ////تجاهلها
          formatType:
            formik.values.radioValue === "T"
              ? "T"
              : values.radioValue === "D"
              ? "D"
              : values.radioValue === "L"
              ? "L"
              : "",

          description: values.description,
          tableId: values.radioValue === "T" ? values.tableId : null,
          lookupType:
            values.radioValue === "L"
              ? values.lookupType
              : values.radioValue === "T" &&
                values.lookupTypeForGetDataByType !== null
              ? values.lookupCode
              : "",
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          CustomAlert({ action: "Add" });
          document.getElementById("closeModalFlex").click();
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  const Tables = TablesFormList.map((item) => ({
    value: item.id,
    label: i18n.language === "ar" ? item.name2 : item.name,
    lookupType: item.lookupType,
  }));

  const handleSelectChange = (e) => {

    formik.setFieldValue("tableId", e.value);
    if(e.lookupType !== null){
      formik.setFieldValue("lookupTypeForGetDataByType", e.lookupType);
    }else {
      formik.setFieldValue("lookupTypeForGetDataByType", null);
    }
  };

  const handleRadioChange = (e) => {
    formik.handleChange(e);
    formik.setFieldValue("lookupTypeForGetDataByType", null)
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("LocalName")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name2"
              onChange={formik.handleChange}
              value={formik.values.name2}
              isInvalid={formik.touched.name2 && formik.errors.name2}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("GlobalName")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isInvalid={formik.touched.name && formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <Form.Check
              type="radio"
              id="custom-switch"
              label={t("ConstructedList")}
              value="D"
              checked={formik.values.radioValue === "D"}
              onChange={(e) => handleRadioChange(e)}
              name="radioValue"
            />
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <Form.Check
              type="radio"
              label={t("tableInTheSystem")}
              value="T"
              name="radioValue"
              checked={formik.values.radioValue === "T"}
              onChange={(e) => handleRadioChange(e)}
            />
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <Form.Check
              type="radio"
              label={t("GeneralLists")}
              value="L"
              name="radioValue"
              checked={formik.values.radioValue === "L"}
              onChange={(e) => handleRadioChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-4"></div>

        <div className="col-xl-4">
          {formik.values.radioValue === "T" && (
          <div className="form-group mb-3">
            <Select
              classNamePrefix="react-select"
              isSearchable={true}
              options={Tables}
              name="tableId"
              onChange={(e) => handleSelectChange(e)}
              placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
            />
            {formik.errors.tableId && formik.touched.tableId && (
              <div style={{ color: "red" }}>{formik.errors.tableId}</div>
            )}

            {formik.values.lookupTypeForGetDataByType !== null && (
              <LookupTable
                formik={formik}
              />
            )}
          </div>
           )}
        </div>

        <div className="col-xl-4">
          {formik.values.radioValue === "L" && (
          <div className="form-group mb-3">
            <Form.Select
              className="form-select"
              name="lookupType"
              onChange={formik.handleChange}
              value={formik.values.lookupType}
              isInvalid={formik.touched.lookupType && formik.errors.lookupType}
            >
              <option key={null}>
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {LookupData &&
                LookupData.map((item, idx) => (
                  <option
                    value={item.categoryValue}
                    key={idx}
                    className="dropdown-item"
                  >
                    {item.categoryName}
                  </option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.lookupType}
            </Form.Control.Feedback>
          </div>
           )}
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <div className="form-group mb-3">
            <label className="form-label">{t("Note")}</label>
            <Form.Control
              className="form-control"
              as="textarea"
              rows={3}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              // isInvalid={formik.touched.description && formik.errors.description}
            />

            <div></div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <button
          disabled={formik.isSubmitting}
          type="submit"
          className="btn me-1 btn-theme mb-1"
          id="SaveSubmit"
        >
          <i className="fa fa-save fa-fw me-1"></i>
          {t("Save")}
        </button>
        <button
          type="button"
          className=" btn me-1 btn-default mb-1"
          data-bs-dismiss="modal"
          id="closeModalFlex"
        >
          <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
        </button>
        {/* <button
          type="button"
          onClick={formik.resetForm}
          className="btn me-1 btn btn-outline-danger mb-1"
        >
          <i className="fa fa-trash-alt fa-fw me-1"></i>
          {t("Clear")}
        </button> */}
      </div>
    </Form>
  );
};

export default AddFlexValueSet;
