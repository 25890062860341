import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { GetGlChartOfAccountsList } from '../../store/Finance/ChartAccounts/ChartAccountsSlice';
import { GetGlChartOfAccountsDetailsList } from '../../store/Finance/ChartAccounts/ChartAccountsDetailsSlice';
import { useTranslation } from 'react-i18next';

const useGetChartAccountsDetailsList = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ChartAccountsDetailsList?.length === 0) {
      dispatch(GetGlChartOfAccountsDetailsList());
    }
  }, []);
  const { ChartAccountsDetailsList, loading, error } = useAppSelector(
    (state) => state.ChartAccountsDetails
  );

  var Accounts = ChartAccountsDetailsList.filter((f) => f.accountlevel === 4);
  var Companies = ChartAccountsDetailsList.filter((f) => f.accountType === 'Company');
  var Branches = ChartAccountsDetailsList.filter((f) => f.accountType === 'Branch');

  var CostCenters = ChartAccountsDetailsList.filter(
    (f) => f.accountType === 'CostCenter' && f.accountParant !== null
  );

  //___ Options _____
  var OptionsBranches = Branches.map(({ id, name, name2, accountNumber, accountParant }) => ({
    value: id,
    label: `${i18n.language === 'en' ? name : name2} | ${accountNumber}`,
    accountParant: accountParant,
    accountNumber: accountNumber,
  }));

  var OptionsAccounts = Accounts.map(({ id, name, name2, accountNumber, accountParant }) => ({
    value: id,
    label: `${i18n.language === 'en' ? name : name2} | ${accountNumber}`,
    accountParant: accountParant,
    accountNumber: accountNumber,
  }));

  var OptionsCompanies = Companies.map(({ id, name, name2, accountNumber, accountParant }) => ({
    value: id,
    label: `${i18n.language === 'en' ? name : name2} | ${accountNumber}`,
    accountParant: accountParant,
    accountNumber: accountNumber,
  }));

  var OptionsCostCenters = CostCenters.map(({ id, name, name2, accountNumber, accountParant }) => ({
    value: id,
    label: `${i18n.language === 'en' ? name : name2} | ${accountNumber}`,
    accountParant: accountParant,
    accountNumber: accountNumber,
  }));
  //________

  //#region  // ___Start TreeSelect CostCenter _____
  const BildCostCentersTree = (list: any, parentId: any) => {
    return list
      .filter((item: any) => item.accountParant === parentId)
      .map((item: any) => ({
        title: `${i18n.language === 'en' ? item.name : item.name2} | ${item.accountNumber}`,
        value: item.id,
        accountNumber: item.accountNumber,
        children: BildCostCentersTree(list, item.id),
      }));
  };
  const TreeCostCenters = BildCostCentersTree(
    ChartAccountsDetailsList,
    '7a5a0a0d-00d9-4b8f-9f6a-8ebe1f851b47'
  );
  //#endregion

  //#region  // ___Start TreeSelect Companies _____
  const buildCompaniesTree = (list: any, parentId: any) => {
    return list
      .filter((item: any) => item.accountParant === parentId)
      .map((item: any) => ({
        title: i18n.language === 'en' ? item.name : item.name2,
        value: item.id,
        disabled: item.accountType === 'Branch',
        children: buildCompaniesTree(list, item.id),
      }));
  };
  const TreeCompanies = buildCompaniesTree(
    ChartAccountsDetailsList,
    '69b480f1-f40b-4e77-9e66-9b75d98395a7'
  );
  //#endregion

  //#region  // ___Start TreeSelect branches _____
  const buildBranchesTree = (list: any, parentId: any) => {
    return list
      .filter((item: any) => item.accountParant === parentId)
      .map((item: any) => ({
        title: i18n.language === 'en' ? item.name : item.name2,
        value: item.id,
        disabled: item.accountType === 'Company', // تعطيل خيار الشركات
        children: buildBranchesTree(list, item.id),
      }));
  };
  const TreeBranches = buildBranchesTree(
    ChartAccountsDetailsList,
    '69b480f1-f40b-4e77-9e66-9b75d98395a7'
  );
  //#endregion

  //_______
  return {
    ChartAccountsDetailsList,
    Accounts,
    Companies,
    Branches,
    CostCenters,
    loading,
    error,
    //
    OptionsAccounts,
    OptionsBranches,
    OptionsCompanies,
    OptionsCostCenters,
    TreeCostCenters,
    TreeCompanies,
    TreeBranches,
  };
};

export default useGetChartAccountsDetailsList;
