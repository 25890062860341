import React, { useState } from 'react';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../components/card/card';
import Loader from '../../../components/Shared/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Pagination from '../../../components/Shared/Pagination/Pagination';
import Select from 'react-select';

import useGetGlLedgersList from '../../../hooks/Finance/useGetGlLedgersList';
import useGetJournalsList from '../../../hooks/Finance/Journals/useGetJournalsList';

const Index = () => {
  const [selectedLedger, setSelectedLedger] = useState<any>(null);

  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const { LedgersList, OptionsLedgers, loading } = useGetGlLedgersList();
  const { JournalsList } = useGetJournalsList();
  console.log(OptionsLedgers);

  const filteredSearch = JournalsList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return item.name?.toLowerCase().includes(searchLowerCase);
    })
    ?.filter((f) => (selectedLedger === null ? true : f.ledgerId === selectedLedger));

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'القيود المالية',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="القيود المالية" />
      </div>

      <Card>
        <Loader loading={loading} />
        <CardBody>
          <div className="p-1">
            <div className="row mb-2">
              <div className="col-lg-4">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">
                    {t('Search')}
                    {/* <i className="fa fa-search  text-theme ms-3"></i> */}
                  </span>
                  <div className="input-group">
                    <div className="flex-fill position-relative">
                      <div className="input-group border-0 ">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5 text-theme"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          // placeholder={t('Search')}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={selectedLedger ? 'col-lg-6': 'col-lg-8'}>
                <div className="input-group flex-nowrap">
                  <span className="input-group-text  fw-bold">{t('الأستاذ العام')}</span>
                  <Select
                    classNamePrefix="react-select"
                    className="w-100"
                    // isLoading={loading2}
                    value={selectedLedger}
                    isSearchable={true}
                    isClearable
                    options={[
                      {
                        value: null,
                        label: t('All'),
                      },
                      ...OptionsLedgers,
                    ]}
                    onChange={(option) => setSelectedLedger(option === null ? null : option.value)}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>

              <div className="col-lg-2 text-end">
                
                  {selectedLedger && (
                    <Link
                      to={`${selectedLedger}/AddJournals`}
                      className="btn btn-theme   rounded-0">
                      <i className="fa fa-plus-circle fa-fw me-1 p-1"></i>
                      {t('إضافة قيـــد')}
                    </Link>
                  )}
           
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-hover text-nowrap">
                <thead className="bg-dark bg-opacity-75 ">
                  <tr>
                    {/* <th className="pt-2 pb-2">{t('JurmalName')}</th> */}
                    <th className="pt-2 pb-2">{t('اسم القيـد')}</th>
                    <th className="pt-2 pb-2">{t('العملة')}</th>
                    <th className="pt-2 pb-2">{t('Debt')}</th>
                    <th className="pt-2 pb-2">{t('Credit')}</th>
                    <th className="pt-2 pb-2">{t('الحالة')}</th>
                    <th className="pt-2 pb-2"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle"> {item.name} </td>
                      <td className="align-middle"> {item.currencyCode} </td>
                      <td className="align-middle"> {item.accountedDr} </td>
                      <td className="align-middle"> {item.accountedCr} </td>
                      <td className="align-middle"> {item.status} </td>

                      <td>
                        <Link
                          to={`${item.id}/UpdateJournals`}
                          className="btn btn-outline-theme mx-2">
                          <i className="far fa-lg fa-fw  fa-edit"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Index;

// <div className="d-flex mb-3">
// {/* <select
//   className="text-theme fw-bold bg-inverse bg-opacity-20 btn btn-default dropdown-toggle px-4 "
//   value={selectedLedger}
//   onChange={(e) => setSelectedLedger(e.target.value)}>
//   <option className="dropdown-item" value={''}>
//     {t('All')}
//   </option>
//   {LedgersList &&
//     LedgersList.map((item) => (
//       <option value={item.id} key={item.id} className="dropdown-item">
//         {i18n.language === 'ar' ? item.name2 : item.name}
//       </option>
//     ))}
// </select> */}

// <div className="flex-fill position-relative">
//   <div className="input-group">
//     <div
//       className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
//       style={{ zIndex: 1020 }}>
//       <i className="fa fa-search ps-2 opacity-5"></i>
//     </div>
//     <input
//       type="text"
//       className="form-control ps-4 py-10px"
//       placeholder={t('Search')}
//       value={search}
//       onChange={(e) => setSearch(e.target.value)}
//     />
//   </div>
// </div>

// {selectedLedger && (
//   <Link to={`${selectedLedger}/AddJournals`} className="btn btn-theme mx-2 rounded-3">
//     <i className="fa fa-plus-circle fa-fw me-1 p-1"></i>
//     {t('إضافة قيـــد')}
//   </Link>
// )}
// </div>
