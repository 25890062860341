import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AmeRequests from "../../Ame/Requests/AmeRequests/AmeRequests";
import Breadcrumb from "../../../components/Shared/Breadcrumb";
import { GetDynamicFormAme } from "../../../store/Ame/ameRequestSlice";

const FLEETRequest = () => {
  const { t, i18n } = useTranslation();

 

  const Dispatch = useDispatch();

  useEffect(() => {
    Dispatch(GetDynamicFormAme());
  }, []);

  const { DynamicFormAme } = useSelector((a) => a.AmeRequest);

 

  //_____________________________________________________
  var FormList = DynamicFormAme?.filter(
    (f) => f.moduleId === "0a6c6b89-1d52-4eaa-8d2c-040c74b1f6e0" // Fleet Medule
  );

  //________________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "MaintenanceRequests",
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="MaintenanceRequests" />

      <AmeRequests FormList={FormList} />
    </div>
  );
};

export default FLEETRequest;
