import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { useFormik } from 'formik';
import { AddSubinventory } from '../../../../../store/INV/SubInventory/SubInventorySlice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SubInventoryList from './SubInventoryList';
import { Card, CardBody } from '../../../../../components/card/card';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import MedModalComponent from '../../../../../components/Modal/MedModalComponent';
import EditSubInventory from './EditSubInventory';
import Loading from '../../../../../components/Shared/Loader/Loading';
import {
  GetSubinventoryByParentId,
  cleanUpSubInventoryListByParentId,
} from '../../../../../store/INV/SubInventory/SubInventorySlice';

const SubInventory = () => {
  const [subInventoryId, setSubInventoryId] = useState('');
  const { Id } = useParams();
  const Navigate = useNavigate();
  const { SubInventoryListByParentId, loading, error } = useAppSelector(
    (state) => state.SubInventory
  );

  useEffect(() => {
    if (Id) {
      dispatch(GetSubinventoryByParentId(Id));
    }
    return () => {
      dispatch(cleanUpSubInventoryListByParentId());
    };
  }, [Id]);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      name: '',
      name2: '',
      code: '',
      parentId: '',
      description: '',
    },
    // validationSchema:  ,
    onSubmit: (values, { resetForm }) => {
      dispatch(
        AddSubinventory({
          name: values.name,
          name2: values.name2,
          code: values.code,
          parentId: Id,
          description: values.description,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded) {
            CustomAlert({ action: 'Add' });
            formik.resetForm();
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'المخازن',
      link: '/INV/InventorySettings/Inventory',
    },
    {
      name: 'المخازن الفرعية',
      link: null,
    },
  ];

  const location = useLocation();
  const { name } = location?.state;

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="المخازن الفرعية" subtitle={name} />
      <Card>
        <Loading loading={loading} error={error}>
          <CardBody className="p-4">
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-xl-4 mb-1">
                  <div className="input-group flex-nowrap mb-3">
                    <span className="input-group-text">{t('LocalName')}</span>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="name2"
                      onChange={formik.handleChange}
                      value={formik.values.name2}
                      isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.name2}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-xl-4 mb-1">
                  <div className="input-group flex-nowrap mb-3">
                    <span className="input-group-text">{t('GlobalName')}</span>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      isInvalid={!!(formik.touched.name && formik.errors.name)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.name}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-xl-4 mb-1">
                  <div className="input-group flex-nowrap mb-3">
                    <span className="input-group-text">{t('Code')}</span>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="code"
                      onChange={formik.handleChange}
                      value={formik.values.code}
                      isInvalid={!!(formik.touched.code && formik.errors.code)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.code}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-xl-9 mb-1">
                  <div className="input-group flex-nowrap mb-3">
                    <span className="input-group-text">{t('Note')}</span>
                    <Form.Control
                      className="form-control"
                      as="textarea"
                      rows={1}
                      name="description"
                      onChange={formik.handleChange}
                      value={formik.values.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.description}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-xl-3 mb-1 text-end">
                  <button
                    disabled={formik.isSubmitting}
                    type="submit"
                    className="btn me-1 btn-theme "
                    id="SaveSubmit">
                    {formik.isSubmitting ? (
                      <div className="spinner-border spinner-border-sm me-2"></div>
                    ) : (
                      <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                    )}
                    {t('Add')}
                  </button>
                  <button
                    type="button"
                    className=" btn me-1 btn-default "
                    onClick={() => Navigate(-1)}>
                    <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                  </button>
                </div>
              </div>

              <div className="text-center"></div>
            </Form>

            <div className="row m2-3">
              <div className="col-lg-12 m-auto  ">
                {<SubInventoryList setID={setSubInventoryId} />}
              </div>
            </div>
          </CardBody>
        </Loading>
      </Card>
      <MedModalComponent id="EditSubInventory" title={t('Edit')}>
        {subInventoryId && <EditSubInventory Id={subInventoryId} />}
      </MedModalComponent>
    </>
  );
};

export default SubInventory;
