import { Translation, defaultTranslations} from 'react-querybuilder';

 const  CustomTranslations  ={
    "en":defaultTranslations,
    "ar":{
        "fields": {
          "title": "Fields1",
          "placeholderName": "~",
          "placeholderLabel": "------",
          "placeholderGroupLabel": "------"
        },
        "operators": {
          "title": "Operators1",
          "placeholderName": "~",
          "placeholderLabel": "------",
          "placeholderGroupLabel": "------"
        },
        "value": {
          "title": "Value1"
        },
        "removeRule": {
          "label": "x",
          "title": "حذف القاعدة"
        },
        "removeGroup": {
          "label": "x",
          "title": "حذف المجموعة"
        },
        "addRule": {
          "label": "+قاعدة",
          "title": "اضافة قاعدة"
        },
        "addGroup": {
          "label": "+مجموعة",
          "title": "اضافة مجموعة"
        },
        "combinators": {
          "title": "Combinators"
        },
        "notToggle": {
          "label": "Not",
          "title": "Invert this group"
        },
        "cloneRule": {
          "label": "⧉",
          "title": "نسخ القاعدة"
        },
        "cloneRuleGroup": {
          "label": "⧉",
          "title": "نسخ المجموعة"
        },
        "dragHandle": {
          "label": "⁞⁞",
          "title": "مقبض السحب"
        },
        "lockRule": {
          "label": "🔓",
          "title": "Lock rule"
        },
        "lockGroup": {
          "label": "🔓",
          "title": "Lock group"
        },
        "lockRuleDisabled": {
          "label": "🔒",
          "title": "Unlock rule"
        },
        "lockGroupDisabled": {
          "label": "🔒",
          "title": "Unlock group"
        }
      }
    }
  export  default   CustomTranslations  ;


