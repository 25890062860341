import { useFormik } from 'formik';
import React from 'react'
import Form from "react-bootstrap/Form";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import useGetOrganizations from '../../../../hooks/HR/use-get-organizations.js';
import { AddGroupEmp, getGroupEmp } from '../../../../store/HR/HRSetting/groupEmpSlice';
import { formGpoupEmp } from '../../../ValidationForm/validationSchema';

const AddGroupEmployees = () => {

  const { Organizations} = useGetOrganizations()

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const formik = useFormik({
    initialValues: {
      name2: "",
      name: "",
      businessGroupId:"",
      comments: "",
     
    },
    validationSchema: formGpoupEmp(t),
    onSubmit: (values , {resetForm}  ) => {


      dispatch(
        AddGroupEmp({
          name2: values.name2,
          name: values.name,
          businessGroupId: values.businessGroupId,
          comments: values.comments,
        })
      )
        .unwrap()
        .then(() => {
          CustomAlert({ action: "Add" });
          document.getElementById("closeModalGroup").click();
          dispatch(getGroupEmp());
        })
        .catch((error) => {
          formik.setSubmitting(false)
          CustomAlert({ action: "Error" });
        });
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit} >
      <div className="row">
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">
            {t("LocalName")}
            </label>
            <Form.Control
              className="form-control"
              type="text"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isInvalid={!!formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </div>
        </div>
      
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">
            {t("GlobalName")}
            </label>
            <Form.Control
              className="form-control"
              type="text"
              name="name2"
              onChange={formik.handleChange}
              value={formik.values.name2}
              isInvalid={!!formik.errors.name2}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name2}
            </Form.Control.Feedback>
          </div>
        </div>
      </div>

      <div className="form-group mb-3">
                <label
                  className="form-label"
                 
                >
                  business Group
                </label>
                <Form.Select
                  className="form-select"
                  id="exampleFormControlSelect1"
                  name="businessGroupId"
                    onChange={formik.handleChange}
                    value={formik.values.businessGroupId}
                    isInvalid={!!formik.errors.businessGroupId}
                >
                      <option key={null}>
                  {i18n.language === "ar" ? 'إختر' : 'Choose'}
                </option>
                {Organizations.map((item , idx) => (
           
                  <option key={++idx}  value={item.id}> 
                  {i18n.language === "ar" ? item.name2 : item.name}
                </option>
              
              ))}

              
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.businessGroupId}
                </Form.Control.Feedback>
              </div>

      <div className="row">
      <div className="col-xl-12">
        <div className="form-group mb-3">
          <label className="form-label">
          {t("Note")} 
          </label>
          <Form.Control
              className="form-control"
              as="textarea" rows={3}
              name="comments"
              onChange={formik.handleChange}
              value={formik.values.comments}
              isInvalid={!!formik.errors.comments}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.comments}
            </Form.Control.Feedback>
          <div>
          
        </div>
        </div>
      </div>
     

    </div>

    
    <div className="text-center">
      <button disabled={formik.isSubmitting} type="submit" className="btn me-1 btn-theme mb-1" id='SaveSubmit'>
      <i className="fa fa-save fa-fw me-1"></i> {formik.isSubmitting ? '...' : 'حفظ'}
      </button>
      <button type="button" className=" btn me-1 btn-default mb-1" data-bs-dismiss="modal" id="closeModalGroup">
      <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
        </button>
        <button type="button" onClick={formik.resetForm} className="btn me-1 btn btn-danger mb-1">
        <i className="fa fa-trash-alt fa-fw me-1"></i>
          {t("Clear")}</button>
          </div>
    </Form>
  )
}

export default AddGroupEmployees;