import React, { useEffect } from 'react'
// import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { GetReportsList } from '../../../store/NTX/Reports/reportSlice';
import ReportsAllByModule from "../../NTX/Reports/ReportsAllByModule/Index/Index"
import Loading from '../../../components/Shared/Loader/Loading';

interface Report {
    id: string;
    name: string;
    name2: string;
    moduleId: string;
    tableName: string;
    query: string;
    description: string;
    pageType: string;
    reportType: string;
  }
  
  interface ReportsListState {
    ReportsList: Report[];
    loading: boolean;
  }
  
//________________ Start Components
const FLEETReports: React.FC = () => {

    // const Navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { ReportsList, loading } = useAppSelector(
        (state) => state.Reports
      ) as ReportsListState;
      useEffect(() => {
        dispatch(GetReportsList());
      }, [dispatch]);

 
      
  return (
    <div>
      <Loading loading={loading}>

      <ReportsAllByModule ReportsList={ReportsList?.filter(f => f.moduleId === '0a6c6b89-1d52-4eaa-8d2c-040c74b1f6e0') }/>
      </Loading>
    </div>
  )
}

export default FLEETReports
