


import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { EquipmentsWorkOrder } from "./equipmentsworkorderModels";





export const  GetEquipmentsWorkOrderList = createAsyncThunk(
    "EquipmentsWorkOrder/GetEquipmentsWorkOrderList",
    async (_, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get('Fleet/GetEquipmentsWorkOrderList');
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );

export const  GetEquipmentsWorkOrderById = createAsyncThunk(
    "EquipmentsWorkOrder/GetEquipmentsWorkOrderById",
    async (id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get(`Fleet/GetEquipmentsWorkOrderById?Id=${id}`);
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );


  export const InsertEquipmentsWorkOrder = createAsyncThunk('EquipmentsWorkOrder/InsertEquipmentsWorkOrder',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Fleet/AddEquipmentsWorkOrder" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });


  export const UpdatEquipmentsWorkOrder = createAsyncThunk('EquipmentsWorkOrder/UpdatEquipmentsWorkOrder',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Fleet/UpdatEquipmentsWorkOrder" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });

  


const initialState: {
    EquipmentsWorkOrderList : EquipmentsWorkOrder[]
    EquipmentsWorkOrderById? : EquipmentsWorkOrder
    loading: boolean;
    error: any | null;
  } = {
    EquipmentsWorkOrderList: [],
    loading: false,
    error: null,

  };


  

const equipmentsworkorderSlice = createSlice({
    name: "EquipmentsWorkOrder",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder

      .addCase(InsertEquipmentsWorkOrder.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(InsertEquipmentsWorkOrder.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        if(action.payload.data){
          state.EquipmentsWorkOrderList.push(action.payload.data); 
        }
      })
      .addCase(InsertEquipmentsWorkOrder.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


    .addCase(GetEquipmentsWorkOrderList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetEquipmentsWorkOrderList.fulfilled, (state, action: PayloadAction<any> ) => {
        state.loading = false;
        state.EquipmentsWorkOrderList = action.payload;
      })
      .addCase(GetEquipmentsWorkOrderList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })



    .addCase(GetEquipmentsWorkOrderById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetEquipmentsWorkOrderById.fulfilled, (state, action: PayloadAction<EquipmentsWorkOrder> ) => {
        state.loading = false;
        state.EquipmentsWorkOrderById = action.payload;
      })
      .addCase(GetEquipmentsWorkOrderById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      
    },
  });
  
  export default equipmentsworkorderSlice.reducer;