import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// import { getData, postData } from "../../Axios/axiosData";
import { ntxAPI } from "../../../API/axiosNeatex";

export const AddOrganizationStructure = createAsyncThunk(
  "OrganizationStructure/AddOrganizationStructure",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("Organization/AddOrganizationStructure" , item);
      const {data} = await ntxAPI.post("Organization/AddOrganizationStructure" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetOrganizationStructure = createAsyncThunk(
  "OrganizationStructure/GetOrganizationStructure",
  async (item, thunkAPI) => {
    const { rejectWithValue  } = thunkAPI;


    try {
      // const data = await getData("Organization/GetOrganizationStructureList");
      const {data} = await ntxAPI.get( "Organization/GetOrganizationStructureList");
      return data;
    } catch (error) {
      console.error(error);
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetOrganizationWithoutStructure = createAsyncThunk(
  "OrganizationStructure/GetOrganizationWithoutStructure",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData("Organization/GetOrganizationListWithoutStructure");
      const {data} = await ntxAPI.get("Organization/GetOrganizationListWithoutStructure" );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  OrgStructureList: [],
  loading: false,
  error: null,
  OrgStructureListById: [],
  OrganizationListWithoutStructure: [],
};


const OrganizationStructureSlice = createSlice({
  name: "OrganizationStructure",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      ///// get all Organizations
      .addCase(GetOrganizationStructure.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetOrganizationStructure.fulfilled, (state, action) => {
        state.loading = false;
        state.OrgStructureList = action.payload;
      })
      .addCase(GetOrganizationStructure.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
  
      ///////insert
      .addCase(AddOrganizationStructure.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddOrganizationStructure.fulfilled, (state, action) => {
        state.loading = false;
        state.OrgStructureList.push(action.payload);
      })
      .addCase(AddOrganizationStructure.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
  
      /// Get Organization Without Structure 
      .addCase(GetOrganizationWithoutStructure.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetOrganizationWithoutStructure.fulfilled, (state, action) => {
        state.loading = false;
        state.OrganizationListWithoutStructure = action.payload;
      })
      .addCase(GetOrganizationWithoutStructure.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default OrganizationStructureSlice.reducer;
