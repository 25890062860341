import React, { memo, useEffect } from "react";
import Chart from "chart.js/auto";

const PIECHART = ({ labels, AbsenceData }) => {
  useEffect(() => {
    var themeColorRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme-rgb")
      .trim();
    var gray900Rgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-gray-900-rgb")
      .trim();
    var inverseRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse-rgb")
      .trim();

    var canvas = document.getElementById("pieChart");
    var existingChart = Chart.getChart(canvas);

    if (existingChart) {
      existingChart.destroy();
    }

    new Chart(canvas, {
      type: "pie",
      data: {
        labels: labels,
        // labels: ['NVC Head Office', 'NVR King Abdul Azziz', ' NVR Takhassusi' , 'Tariq'],
        datasets: [
          {
            data: AbsenceData,
            // data: [30, 20, 10 , 40],
            // backgroundColor: ['rgba('+ themeColorRgb +', .5)', 'rgba('+ inverseRgb +', .2)' +', .5)', 'rgba('+ themeColorRgb +', .5)'],
            // hoverBackgroundColor: ['rgba('+ themeColorRgb +', 1)', 'rgba('+ inverseRgb +', 1)', 'rgba('+ gray900Rgb +', 1)'],
            borderWidth: 0,
          },
        ],
      },
    });
  }, []);

 
  return (
    <div className="p-2">
      <canvas id="pieChart">
        {/* {AbsenceData?.length === 0 ? (
          <div className="text-center h-100  bg-inverse bg-opacity-10 rounded-4 p-3">
            لا يوجد بيانات
          </div>
        ) : null} */}
      </canvas>
    </div>
  );
};

export default memo(PIECHART);
