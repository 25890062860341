import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody } from '../../../../components/card/card';
import { GetAmeRuleListByDynamicFormId, insertAmeRule } from '../../../../store/Ame/ameRuleSlice';
import AmeRuleList from './AmeRuleList';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { usePermissions } from '../../../../Helper/Permisstions/usePermissions';
import Loading from '../../../../components/Shared/Loader/Loading';
// import { HasPermission } from "../../../../Helper/Permisstions/usePermissions";

function AddAmeRule() {
  const { HasPermission } = usePermissions();
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  //

  //
  const { AmeRuleListByDynamicFormId, loading, error } = useSelector((state) => state.AmeRule);
  useEffect(() => {
    dispatch(GetAmeRuleListByDynamicFormId(id));
  }, []);

  const formik = useFormik({
    initialValues: {
      dynamicFormId: '',
      name: '',
      name2: '',
    },
    validationSchema: Yup.object().shape({
      name2: Yup.string().required(() => t('Required')),
      name: Yup.string().required(() => t('Required')),
    }),
    onSubmit: (values) => {
      dispatch(
        insertAmeRule({
          dynamicFormId: id,
          name: formik.values.name,
          name2: formik.values.name2,
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          CustomAlert({ action: 'Add' });
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  const location = useLocation();
  const { ReferenceName } = location.state;

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('RequestsSettings'),
      link: '/Ame/RequestSetting',
    },
    {
      name: t('RequestRules'),
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName={t('RequestRules')}
        subtitle={ReferenceName}
      />

      <Card >
        <Loading loading={loading} error={error}>
          <CardBody>
            {HasPermission('AmeAddRule') && (
              <Form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-xl-2"></div>
                  <div className="col-xl-4">
                    <div className="form-group mb-3">
                      <label className="form-label" htmlFor="exampleFormControlSelect1">
                        {t('LocalName')}
                      </label>
                      <Form.Control
                        className="form-control"
                        type="text"
                        name="name2"
                        onChange={formik.handleChange}
                        value={formik.values.name2}
                        isInvalid={formik.touched.name2 && formik.errors.name2}
                      />
                    </div>
                  </div>
                  <div className="col-xl-4">
                    <div className="form-group mb-3">
                      <label className="form-label" htmlFor="exampleFormControlSelect1">
                        {t('GlobalName')}
                      </label>
                      <Form.Control
                        className="form-control"
                        type="text"
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        isInvalid={formik.touched.name && formik.errors.name}
                      />
                    </div>
                  </div>{' '}
                  <div className="col-xl-2"></div>
                </div>

                <div className="text-center">
                  <button
                    disabled={formik.isSubmitting}
                    type="submit"
                    className="btn me-1 btn-theme mb-1">
                    <i className="fas fa-plus fa-fw me-1"></i>
                    {formik.isSubmitting ? 'Adding...' : t('Add')}
                  </button>
                  <button
                    type="button"
                    className=" btn me-1 btn-default mb-1"
                    onClick={() => Navigate(-1, { replace: true })}>
                    <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                  </button>
                </div>
              </Form>
            )}
            <div className="mb-2" />

            <div className="row">
              <div className="col-12 m-auto">
                <div className="bg-inverse bg-opacity-5 rounded-4 pt-1">
                  <AmeRuleList data={AmeRuleListByDynamicFormId} />
                </div>
              </div>
            </div>
          </CardBody>
        </Loading>
      </Card>
    </>
  );
}

export default AddAmeRule;
