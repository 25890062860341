import i18n from 'i18next';
import useLookupNationality from '../../../hooks/Lookups/use-Lookup-Nationality';
import useLookupMarStatus from '../../../hooks/Lookups/use-Lookup-MarStatus';
import useLookupTitle from '../../../hooks/Lookups/use-Lookup-Title';
import useGetRotationPlan from '../../../hooks/HR/use-get-RotationPlan';
import useGetOrganizations from '../../../hooks/HR/use-get-organizations';
import useGetJobs from '../../../hooks/HR/useGetJobs';
import useGetGrade from '../../../hooks/HR/useGetGrade';
import useGetGroupEmp from '../../../hooks/HR/useGetGroupEmp';
import useGetPositions from '../../../hooks/HR/useGetPositions';
import useGetPersons from '../../../hooks/HR/use-get-persons';
import useGetLocations from '../../../hooks/HRSetting/use-get-locations';
import useGetPayrolls from '../../../hooks/HR/useGetPayrolls';
import useGetBanks from '../../../hooks/Lookups/use-get-Banks';
import useGetAssignmentStatusList from '../../../hooks/HR/useGetAssignmentStatusList';
import { useEffect, useState } from 'react';

const FetchPersonWithAssignmentFields = () => {
  const { dataNATIONALITY, NationalityLoading, error } = useLookupNationality();
  const { OptionsMarStatus, loading: loading1, error: error1 } = useLookupMarStatus();
  const { OptionsTitle, loading: loading2, error: error2 } = useLookupTitle();

  const { RotationPlanOptions, loading: loading3, error: error3 } = useGetRotationPlan();
  const { OrganizationOptions, loading: loading4, error: error4 } = useGetOrganizations();

  const { JobsOptions, loading: loading5, error: error5 } = useGetJobs();
  const { GradesOptions, loading: loading6, error: error6 } = useGetGrade();
  const { GroupEmpsOptions, loading: loading7, error: error7 } = useGetGroupEmp();
  const { PositionsOptions, loading: loading8, error: error8 } = useGetPositions();
  const { dataEmployees, loading: loading9, error: error9 } = useGetPersons();
  const { LocationsOptions, loading: loading22, error: error11 } = useGetLocations();
  const { PayrollsOptions, loading: loading33, error: error22 } = useGetPayrolls();

  const {
    AssignmentStatusOptions,
    loading: loading55,
    error: error33,
  } = useGetAssignmentStatusList();

  const { BanksOptions, loading: loading44, error: error44 } = useGetBanks();

  const [fieldsLoading, setfieldsLoading] = useState(false);

  useEffect(() => {
    setfieldsLoading(
      NationalityLoading ||
        loading1 ||
        loading2 ||
        loading3 ||
        loading4 ||
        loading5 ||
        loading6 ||
        loading7 ||
        loading8 ||
        loading9 ||
        loading22 ||
        loading33 ||
        loading55 ||
        loading44
    );
  }, [
    NationalityLoading,
    loading1,
    loading2,
    loading22,
    loading3,
    loading33,
    loading4,
    loading44,
    loading5,
    loading55,
    loading6,
    loading7,
    loading8,
    loading9,
  ]);

  const [fieldsError, setfieldsError] = useState(false);

  useEffect(() => {
    setfieldsError(
      error ||
        error1 ||
        error2 ||
        error3 ||
        error4 ||
        error5 ||
        error6 ||
        error7 ||
        error8 ||
        error9 ||
        error22 ||
        error33 ||
        error11 ||
        error44
    );
  }, [
    error,
    error1,
    error11,
    error2,
    error22,
    error3,
    error33,
    error4,
    error44,
    error5,
    error6,
    error7,
    error8,
    error9,
  ]);

  

  const PersonWithAssignmentFields = [
    // title
    {
      label: 'Title',
      key: 'title',
      fieldType: {
        type: 'select',
        options: OptionsTitle,
      },
      example: '',
      validations: [
        // {
        //   rule: 'required',
        //   errorMessage: 'Title is required',
        //   level: 'error',
        // },
      ],
    },

    // name
    {
      label: 'Global Name',
      key: 'name',
      fieldType: {
        type: 'input',
      },
      example: 'Mohammed Abdullah',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Name is required',
          level: 'error',
        },
      ],
    },

    // name2
    {
      label: 'Local Name',
      key: 'name2',
      fieldType: {
        type: 'input',
      },
      example: 'محمد عبدالله',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Name2 is required',
          level: 'error',
        },
      ],
    },

    //Employee Number
    {
      label: 'Employee Number',
      key: 'employeeNumber',
      fieldType: {
        type: 'input',
      },
      example: '1234',
      validations: [
        // {
        //   rule: 'required',
        //   errorMessage: 'Name2 is required',
        //   level: 'error',
        // },
      ],
    },

    //Gender
    {
      label: 'Gender',
      key: 'gender',
      fieldType: {
        type: 'select',
        options: [
          {
            label: 'ذكر',
            value: 'M',
          },
          {
            label: 'انثى',
            value: 'F',
          },
        ],
      },
      example: '',
      validations: [
        // {
        //   rule: 'required',
        //   errorMessage: 'gender is required',
        //   level: 'error',
        // },
      ],
    },

    //National Identifier
    {
      label: 'National Identifier',
      key: 'nationalIdentifier',
      fieldType: {
        type: 'input',
      },
      example: '000000000',
      validations: [
        // {
        //   rule: 'regex',
        //   value: '^[0-9]+(\\.[0-9]+)?$',
        //   // flags?: string,
        //   errorMessage: 'national Identifier is required',
        //   level: 'error',
        // },
      ],
    },

    //Start Date
    {
      label: 'Start Date',
      key: 'startDate',
      fieldType: {
        type: 'input',
      },
      example: '2023-11-09',
      validations: [
        // {
        //   rule: 'required',
        //   value: '^d{4}-d{2}-d{2}$',
        //   errorMessage: 'startDate is required',
        //   level: 'error',
        // },
      ],
    },

    //Date of Birth
    {
      label: 'Date of Birth',
      key: 'dateOfBirth',
      fieldType: {
        type: 'input',
      },
      example: '2023-11-09',
      validations: [
        // {
        //   rule: 'required',
        //   errorMessage: 'dateOfBirth is required',
        //   level: 'error',
        // },
        {
          rule: 'regex',
          value: '^(19|20)\\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$',
          errorMessage: 'The date must be in the format YYYY-MM-DD',
          level: 'error',
        },
      ],
    },

    //Marital Status
    {
      label: 'Marital Status',
      key: 'maritalStatus',
      fieldType: {
        type: 'select',
        options: OptionsMarStatus,
      },
      example: '',
      validations: [
        // {
        //   rule: 'required',
        //   errorMessage: 'maritalStatus is required',
        //   level: 'error',
        // },
      ],
    },

    // Nationality
    {
      label: 'Nationality',
      key: 'nationality',
      fieldType: {
        type: 'select',
        options: dataNATIONALITY,
      },
      example: '',
      validations: [
        // {
        //   rule: "required",
        //   errorMessage: 'Nationality is required',
        //   level: 'error',
        // },
      ],
    },

    //Town Of Birth
    {
      label: 'Town Of Birth',
      key: 'townOfBirth',
      fieldType: {
        type: 'input',
      },
      example: 'الرياض',
      validations: [
      //   {
      //     rule: 'required',
      //     errorMessage: 'townOfBirth is required',
      //     level: 'error',
      //   },
      ],
    },

    //Country of Birth
    {
      label: 'Country of Birth',
      key: 'countryOfBirth',
      fieldType: {
        type: 'select',
        options: dataNATIONALITY,
      },
      example: '',
      validations: [
      //   {
      //     rule: 'required',
      //     errorMessage: 'CountryOfBirth is required',
      //     level: 'error',
      //   },
      ],
    },

    //Religion
    {
      label: 'Religion',
      key: 'Religion',
      fieldType: {
        type: 'select',
        options: [
          {
            label: i18n.language === 'ar' ? 'مسلم' : 'Muslim',
            value: 'M',
          },
          {
            label: i18n.language === 'ar' ? 'مسيحي' : 'Christian',
            value: 'C',
          },
          {
            label: i18n.language === 'ar' ? 'غير ذلك' : 'otherwise',
            value: 'O',
          },
        ],
      },
      example: '',
      validations: [
      //   {
      //     rule: 'required',
      //     errorMessage: 'Religion is required',
      //     level: 'error',
      //   },
      ],
    },

    //Annual Leave
    {
      label: 'Annual Leave',
      key: 'annualLeave',
      fieldType: {
        type: 'select',
        options: [
          {
            label: '30 days',
            value: 30,
          },
          {
            label: '21 days',
            value: 21,
          },
        ],
      },
      example: '',
      // validations: [
      //   {
      //     rule: 'required',
      //     errorMessage: 'annualLeave is required',
      //     level: 'error',
      //   },
      // ],
    },

    //Rotation Plan
    {
      label: 'Rotation Plan',
      key: 'RotationPlanId',
      fieldType: {
        type: 'select',
        options: RotationPlanOptions,
      },
      example: '',
      // validations: [
      //   {
      //     rule: 'required',
      //     errorMessage: 'Rotation Plan is required',
      //     level: 'error',
      //   },
      // ],
    },

    // Bank Name
    {
      label: 'Bank Name',
      key: 'bankId',
      fieldType: {
        type: 'select',
        options: BanksOptions,
      },
      example: '',
      validations: [
        // {
        //   // rule: "required",
        //   errorMessage: "Bank Name is required",
        //   level: "error",
        // },
      ],
    },

    // iBan
    {
      label: 'iBan',
      key: 'iBan',
      fieldType: {
        type: 'input',
      },
      // example: "SA12340000000",
      validations: [
        // {
        //   rule: "required",
        //   errorMessage: "iBan is required",
        //   level: "error",
        // },
      ],
    },

    //______ Assignments __________________________________________________________

    // Organization
    {
      label: 'Organization',
      key: 'organizationId',
      fieldType: {
        type: 'select',
        options: OrganizationOptions,
      },
      example: '',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Organization is required',
          level: 'error',
        },
      ],
    },

    // Job
    {
      label: 'Job',
      key: 'jobId',
      fieldType: {
        type: 'select',
        options: JobsOptions,
      },
      example: '',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Job is required',
          level: 'error',
        },
      ],
    },

    //Position
    {
      label: 'Position',
      key: 'positionId',
      fieldType: {
        type: 'select',
        options: PositionsOptions,
      },
      // example: "",
      validations: [
        // {
        //   rule: 'required',
        //   errorMessage: 'Position is required',
        //   level: 'error',
        // },
      ],
    },

    //Grade
    {
      label: 'Grade',
      key: 'gradeId',
      fieldType: {
        type: 'select',
        options: GradesOptions,
      },
      example: '',
      validations: [
        // {
        //   rule: 'required',
        //   errorMessage: 'Grade is required',
        //   level: 'error',
        // },
      ],
    },

    // Person Group
    {
      label: 'Person Group',
      key: 'personGroupId',
      fieldType: {
        type: 'select',
        options: GroupEmpsOptions,
      },
      example: '',
      validations: [
        // {
        //   rule: 'required',
        //   errorMessage: 'Person Group is required',
        //   level: 'error',
        // },
      ],
    },

    //Location
    {
      label: 'Location',
      key: 'locationId',
      fieldType: {
        type: 'select',
        options: LocationsOptions,
      },
      example: '',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Location is required',
          level: 'error',
        },
      ],
    },

    //Supervisor
    {
      label: 'Supervisor',
      key: 'supervisorId',
      fieldType: {
        type: 'select',
        options: dataEmployees,
      },
      example: '',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Supervisor is required',
          level: 'error',
        },
         
      ],
    },

    //Payroll
    {
      label: 'Payroll',
      key: 'payrollId',
      fieldType: {
        type: 'select',
        options: PayrollsOptions,
      },
      example: '',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Payroll is required',
          level: 'error',
        },
      ],
    },

    //Assignmen Status
    {
      label: 'Assignmen Status',
      key: 'assignmentStatusTypeId',
      fieldType: {
        type: 'select',
        options: AssignmentStatusOptions,
      },
      example: '',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Assignmen Status is required',
          level: 'error',
        },
      ],
    },

    //assignmentStartDate
    {
      label: 'Assignment Start Date',
      key: 'assignmentStartDate',
      fieldType: {
        type: 'input',
      },
      example: '2023-11-09',
      validations: [
        {
          rule: 'required',
          value: '^d{4}-d{2}-d{2}$',
          errorMessage: 'assignmentStartDate is required',
          level: 'error',
        },
      ],
    },
  ];





  return { PersonWithAssignmentFields, fieldsLoading, fieldsError };
};

export default FetchPersonWithAssignmentFields;


