import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";




const initialState = { FlexValueSetList: [], loading: false, error: null , TablesFormList:[]  };

  export const GetFlexValueSetList = createAsyncThunk("FlexValueSet/GetFlexValueSetList", async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData("FlexValueSet/GetFlexValueSetList");
      const {data} = await ntxAPI.get("FlexValueSet/GetFlexValueSetList" );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

  export const GetTablesForm = createAsyncThunk("FlexValueSet/GetTablesForm", async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData("DynamicForm/GetTablesFormList");
      const {data} = await ntxAPI.get( "DynamicForm/GetTablesFormList");
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

  export const insertFlexValueSet = createAsyncThunk("FlexValueSet/insertFlexValueSet", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    // debugger
    try {
      const {data} = await ntxAPI.post("FlexValueSet/AddFlexValueSet" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });




  const FlexValueSetSlice = createSlice({
    name: "FlexValueSet",
    initialState,
    reducers: { },
    extraReducers: (builder) => {
      // get FlexValueSetList
      builder
        .addCase(GetFlexValueSetList.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(GetFlexValueSetList.fulfilled, (state, action) => {
          state.loading = false;
          state.FlexValueSetList = action.payload;
        })
        .addCase(GetFlexValueSetList.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        // get Tables
        .addCase(GetTablesForm.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(GetTablesForm.fulfilled, (state, action) => {
          state.loading = false;
          state.TablesFormList = action.payload;
        })
        .addCase(GetTablesForm.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        // Add FlexValueSetList
        .addCase(insertFlexValueSet.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(insertFlexValueSet.fulfilled, (state, action) => {
          state.loading = false;
          state.FlexValueSetList.push(action.payload);
        })
        .addCase(insertFlexValueSet.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });

  export default FlexValueSetSlice.reducer;