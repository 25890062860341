import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  AddScanningElement,
  GetScanningElementList,
} from "../../../../store/FLEET/FleetScan/fleetScanSlice";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import {
  GetFleetCategory,
  GetLookupPRIORITY,
} from "../../../../store/Lookup/lookupSlice";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import { Card, CardBody } from "../../../../components/card/card";
import { useNavigate, useParams } from "react-router-dom";
import ScanElementList from "./ScanElementList";
import ModalComponent from "../../../../components/Modal/ModalComponent";
import EditElement from "./EditElement";
import Loader from "../../../../components/Shared/Loader/Loader";
import { AddFleetElementSchema } from "../../../ValidationForm/validationSchema";

const AddElement = () => {
    const [ID, setID] = useState<string>(""); // لتعريفة من مكون القائمة ومن ثم استعماله في مكون التعديل
 
    const Navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { lookupCode } = useParams();

  useEffect(() => {
    dispatch(GetFleetCategory());
    dispatch(GetLookupPRIORITY());
  }, []);

  const { FleetCategory, PRIORITY } = useAppSelector((state) => state.Lookup);
  const { loading } = useAppSelector((state) => state.FleetScan);

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      name2: "",
      description: "",
      description2: "",
      sort: "",
      catagory: lookupCode,
      priority: "",
    },
    enableReinitialize:true,
    validationSchema: AddFleetElementSchema(t),
    onSubmit: (values) => {
      dispatch(
        AddScanningElement({
          name: values.name,
          name2: values.name2,
          description: values.description,
          description2: values.description2,
          catagory: values.catagory,
          sort: parseInt(values.sort),
          priority: values.priority,
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            document.getElementById("closeModalForm")?.click();
            formik.resetForm();
            CustomAlert({ action: "Add" });
            dispatch(GetScanningElementList());
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  //____________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "InspectionItems",
      link: "/FLEETSettings/FleetElementScan",
    },
    {
      name: "AddInspectionItems"  ,
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="AddInspectionItems" />

      <Card>
        <CardBody>
            <Loader loading={loading}/>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Catagory")}</label>
                  <Form.Select
                    disabled
                    className="form-select pt-"
                    name="catagory"
                    onChange={formik.handleChange}
                    value={formik.values.catagory}
                    isInvalid={
                      !!(formik.touched.catagory && formik.errors.catagory)
                    }
                  >
                    <option key={null}>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {FleetCategory &&
                      FleetCategory?.map((item: any, idx) =>
                        item?.list?.map((list: any) => (
                          <option key={++idx} value={list.lookupCode}>
                            {i18n.language === "ar"
                              ? list.meaning2
                              : list.meaning}
                          </option>
                        ))
                      )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.catagory}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-2">
                <div className="form-group mb-3">
                  <label className="form-label"> {t("Priority")} </label>
                  <Form.Select
                    className="form-select"
                    name="priority"
                    onChange={formik.handleChange}
                    value={formik.values.priority}
                    isInvalid={
                      !!(formik.touched.priority && formik.errors.priority)
                    }
                  >
                    <option key={null}>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {PRIORITY &&
                      PRIORITY?.map((item: any, idx) =>
                        item?.list?.map((list: any) => (
                          <option key={++idx} value={list.lookupCode}>
                            {i18n.language === "ar"
                              ? list.meaning2
                              : list.meaning}
                          </option>
                        ))
                      )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.priority}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("LocalName")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name2"
                    onChange={formik.handleChange}
                    value={formik.values.name2}
                    isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("GlobalName")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    isInvalid={!!(formik.touched.name && formik.errors.name)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name2}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-2">
                <div className="form-group mb-3">
                  <label
                    className="form-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    {t('Order')}
                  </label>
                  <Form.Control
                    className="form-control"
                    type="number"
                    name="sort"
                    onChange={formik.handleChange}
                    value={formik.values.sort}
                    isInvalid={!!(formik.touched.sort && formik.errors.sort)}
                  />

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.sort}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t("DescriptionAR")}</label>
                  <Form.Control
                    className="form-control"
                    as="textarea"
                    rows={3}
                    name="description2"
                    onChange={formik.handleChange}
                    value={formik.values.description2}
                    isInvalid={
                        !!(formik.touched.description2 && formik.errors.description2)
                      }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.description2}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t("DescriptionEN")}</label>
                  <Form.Control
                  style={{direction : "ltr"}}
                    className="form-control"
                    as="textarea"
                    rows={3}
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    isInvalid={
                        !!(formik.touched.description && formik.errors.description)
                      }
                  />
                 <Form.Control.Feedback type="invalid">
                    {formik.errors.description}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-theme mb-1"
                id="SaveSubmit"
              >
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                )}
                {t("Add")}
              </button>
              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                onClick={()=> Navigate( "/FLEETSettings/FleetElementScan" , { replace:true})}
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
            </div>
          </Form>
          <hr/>
          <div className=" bg-inverse bg-opacity-5 rounded-2 pt-1">

          <ScanElementList setID={setID}/>
          </div>
        </CardBody>
      </Card>

      <ModalComponent
        title={t("EditInspectionItems")}
        id="EditElementModal"
      >
        {
          ID && <EditElement ID={ID} />
        }
      </ModalComponent>

    </div>
  );
};

export default AddElement;
