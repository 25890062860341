import React from 'react';
import NtxPeoples from '../../../NTX/Peoples/NtxPeoples';

const Add = () => {
  var fieldList = [
    'typeCode',

    'personId',
    'billToLocationId',
    'shipToLocationId',
    'termsId', //المعرف الفريد لشروط الدفع.
    'setOfBooksId', //  الاستاذ العام
    'creditLimit', // حد الائتمان للمورد.
    // "paymentPriority", // أولوية الدفع لووكآب  موجود في المورد وليس في العميل  ??
    'invoiceCurrencyCode', //
    'paymentCurrencyCode', //
    'invoiceLimit', // موجود في المورد وليس في العميل ونوعه رقم   حد الفاتورة للمورد ??
    'paymentMethodLookupCode', //  ??
    'bankAccountName', // اسم الحساب البنكي للمورد.
    'bankAccountNum', //  : رقم الحساب البنكي.
    'bankNumber', //  رقم البنك.
    'bankAccountType', // نوع الحساب البنكي.
    'vatCode', //  الرقم الضريبي للمورد
    'tradingName', // الاسم التجاري للمورد
    // "workReference", // اسم الشخص
    'companyRegistrationNumber', // السجل التجاري للمورد
    'peopleCategory',
    'priceListId',
    'accountId',
  ];

  return (
    <div>
      <NtxPeoples typeCode="SUPPLIERS" isUpdate={false} fieldList={fieldList} />
    </div>
  );
};

export default Add;
