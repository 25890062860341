
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { EquipmentsAssignation } from "./equipmentsAssignmentModels"




export const  GetEquipmentsAssignmentList = createAsyncThunk(
    "EquipmentsAssignment/GetEquipmentsAssignationList",
    async (_, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get('Fleet/GetEquipmentsAssignationList');
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );

export const  GetEquipmentsAssignmentById = createAsyncThunk(
    "EquipmentsAssignment/GetEquipmentsAssignationById",
    async (id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get(`Fleet/GetEquipmentsAssignationById?Id=${id}`);
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );


  export const InsertEquipmentsAssignment = createAsyncThunk('EquipmentsAssignment/InsertEquipmentsAssignation',
   async (item: any , {rejectWithValue} ) => {

    // debugger
    try {
      const {data} = await ntxAPI.post( "Fleet/AddEquipmentsAssignation" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });


  export const UpdatEquipmentsAssignment = createAsyncThunk('EquipmentsAssignment/UpdatEquipmentsAssignation',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Fleet/UpdatEquipmentsAssignation" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });

  export const  GetEquipmentsAssignationByEquipmentsId = createAsyncThunk(
    "EquipmentsAssignment/GetEquipmentsAssignationByEquipmentsId",
    async (id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get(`Fleet/GetEquipmentsAssignationByFleetEquipmentsId?FleetEquipmentsId=${id}`);
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );


const initialState: {
    EquipmentsAssignationList : EquipmentsAssignation[]
    AssignationListByEquipmentsId : EquipmentsAssignation[]
    EquipmentsAssignationById? : EquipmentsAssignation
    loading: boolean;
    error: any | null;
  } = {
    EquipmentsAssignationList: [],
    AssignationListByEquipmentsId: [],
    loading: false,
    error: null,

  };


  

const equipmentsAssignmentSlice = createSlice({
    name: "EquipmentsAssignment",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder

      .addCase(InsertEquipmentsAssignment.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(InsertEquipmentsAssignment.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.EquipmentsAssignationList.push(action.payload.data); 
      })
      .addCase(InsertEquipmentsAssignment.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


    .addCase(GetEquipmentsAssignmentList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetEquipmentsAssignmentList.fulfilled, (state, action: PayloadAction<any> ) => {
        state.loading = false;
        state.EquipmentsAssignationList = action.payload;
      })
      .addCase(GetEquipmentsAssignmentList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })



    .addCase(GetEquipmentsAssignationByEquipmentsId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetEquipmentsAssignationByEquipmentsId.fulfilled, (state, action: PayloadAction<any> ) => {
        state.loading = false;
        state.AssignationListByEquipmentsId = action.payload;
      })
      .addCase(GetEquipmentsAssignationByEquipmentsId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })



    .addCase(GetEquipmentsAssignmentById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetEquipmentsAssignmentById.fulfilled, (state, action: PayloadAction<EquipmentsAssignation> ) => {
        state.loading = false;
        state.EquipmentsAssignationById = action.payload;
      })
      .addCase(GetEquipmentsAssignmentById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      
    },
  });
  
  export default equipmentsAssignmentSlice.reducer;