import React, { Fragment, memo, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Step3 = ({ fields, setCheckValidationStep3, setDataStep3, DataById }) => {
  const { t, i18n } = useTranslation();

  const [tableFields, setTableFields] = useState(DataById);
  const [sqlName, setsqlName] = useState('');
  const [aliasName, setAliasName] = useState('');
  const [inputType, setInputType] = useState('');
  const [label, setLabel] = useState('');

  useEffect(() => {
    setTableFields((prevState) =>
      prevState.map((item) => ({
        ...item,
        Label: fields?.find((f) => f.name === item.sqlName)?.label,
      }))
    );
  }, [fields, DataById]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const updatedItems = [...tableFields];
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);

    // Update the sequance property based on the new order
    // Create a new array with updated objects
    const updatedItemsWithSequence = updatedItems.map((item, index) => ({
      ...item,
      sequenceNum: index + 1,
    }));

    setTableFields(updatedItemsWithSequence);
  };

  const handleAddToTable = (e) => {
    e.preventDefault();
    if (sqlName && aliasName) {
      // Check if the already exists in the table array
      const Exists = tableFields?.some((item) => item.sqlName === sqlName);
      if (Exists) {
        alert('تمت إضافته مسبقًا');
        return;
      }

      const newData = {
        id: null,
        name: aliasName,
        name2: aliasName,
        sqlName: sqlName,
        Label: label,
        sequenceNum: tableFields?.length + 1,
        inputType: inputType,
      };
      setTableFields([...tableFields, newData]);
      setsqlName('');
      setAliasName('');
      setLabel('');
    }
  };

  const handleDeleteRowClick = (index) => {
    const updatedTableFields = [...tableFields];
    updatedTableFields.splice(index, 1);

    const updatedItemsWithSequence = updatedTableFields.map((item, index) => ({
      ...item,
      sequenceNum: index + 1,
    }));

    setTableFields(updatedItemsWithSequence);
  };

  //_______________________ Stard Validation Table  ______________________________________
  useEffect(() => {
    setCheckValidationStep3(tableFields?.length > 0);
    setDataStep3(tableFields);
  }, [tableFields]);
  //_______________________ End Validation Table  ______________________________________

  return (
    <Fragment>
      <Form onSubmit={handleAddToTable}>
        <div className="row align-items-end ">
          <div className="col-xl-5">
            <div className="form-group mb-3">
              <label className="form-label">{t('Column')}</label>
              <Form.Select
                className="form-select"
                value={sqlName}
                onChange={(e) => {
                  setsqlName(e.target.value);
                  setInputType(e.target.selectedOptions[0].dataset.inputType);
                  setLabel(e.target.selectedOptions[0].dataset.label);
                }}
                required>
                <option>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {fields &&
                  fields?.map((item) => (
                    <option
                      value={item.name}
                      key={item.name}
                      data-input-type={item.inputType}
                      data-label={item.label}>
                      {i18n.language === 'ar' ? item.label : item.label}
                    </option>
                  ))}
              </Form.Select>
            </div>
          </div>
          <div className="col-xl-5">
            <div className="form-group mb-3">
              <label className="form-label">{t('Alias_Name')}</label>
              <Form.Control
                className="form-control"
                type="text"
                value={aliasName}
                onChange={(e) => setAliasName(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="col-xl-2 text-end  ">
            <div className=" form-group mb-3 d-flex justify-content-end ">
              <button type="submit" className="btn me-1 btn-outline-theme w-100">
                <i className="fa fa-plus-circle fa-fw me-1"></i>
                {t('Add')}
              </button>
            </div>
          </div>
        </div>

 
      </Form>

      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="row">
          <div className="col-xl-12 m-auto">
            <div className="table-responsive rounded-2 text-center border ">
              <table className="table table-hover m-0">
                <thead className="bg-theme bg-opacity-60">
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">{t('Column')}</th>
                    <th scope="col">{t('Alias_Name')}</th>
                    {/* <th scope="col">Sequance</th> */}
                    <th scope="col"></th>
                  </tr>
                </thead>
                <Droppable droppableId="table">
                  {(provided) => (
                    <tbody {...provided.droppableProps} ref={provided.innerRef}>
                      {tableFields &&
                        tableFields
                          ?.slice()
                          .sort((a, b) => a.sequenceNum - b.sequenceNum)
                          .map((item, index) => (
                            <Draggable key={item.sqlName} draggableId={item.sqlName} index={index}>
                              {(provided) => (
                                <tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  key={item.sqlName}>
                                  <td className="align-middle">
                                    <a
                                      href="/#"
                                      title="مقبض السحب"
                                      style={{
                                        textDecoration: 'none',
                                        cursor: 'grab',
                                        pointerEvents: 'none',
                                      }}>
                                      ⁞⁞
                                    </a>
                                  </td>
                                  <td>{item.Label}</td>
                                  <td>{item.name}</td>
                                  {/* <td>{item.sequenceNum}</td> */}
                                  <td>
                                    <Link
                                      style={{ color: 'red' }}
                                      onClick={() => handleDeleteRowClick(index)}>
                                      <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
                                    </Link>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </table>
            </div>
          </div>
        </div>
      </DragDropContext>
    </Fragment>
  );
};

export default memo(Step3);
