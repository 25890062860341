import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../../API/axiosNeatex";
import { PermissionModels } from "./PermissionModels";

export const GetPermissionsByUserId = createAsyncThunk(
  "Permission/GetPermissionsByUserId",
  async (id: string, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.get(
        `Security/GetPermissionsByUserId?UserId=${id}`
      );
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdatePermissionUser = createAsyncThunk('Permission/UpdatePermissionUser',
async (item: any , {rejectWithValue} ) => {

 try {
   const {data} = await ntxAPI.post( "Security/UpdatePermissionUser" , item)
   return data; 
 } catch (error : any) {
     return rejectWithValue(error.message);
 }
});


export const UpdatePermissionRole = createAsyncThunk('Permission/UpdatePermissionRole',
async (item: any , {rejectWithValue} ) => {

 try {
   const {data} = await ntxAPI.post( "Security/UpdatePermissionRole" , item)
   return data; 
 } catch (error : any) {
     return rejectWithValue(error.message);
 }
});

export const GetPermissionsByRuleId = createAsyncThunk(
  "Permission/GetPermissionsByRuleId",
  async (id: string, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.get(
        `Security/GetPermissionsByRoleId?RoleId=${id}`
      );
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);



const initialState: {
  PermissionList: PermissionModels;
  loading: boolean;
  error: any | null;
} = {
  PermissionList: {} as PermissionModels,
  loading: false,
  error: null,
};



const PermissionSlice = createSlice({
  name: "Permission",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(GetPermissionsByUserId.pending, (state, action) => {
        state.loading = true;
        state.PermissionList = {} as PermissionModels;
      })
      .addCase(
        GetPermissionsByUserId.fulfilled,
        (state, action: PayloadAction<PermissionModels>) => {
          state.loading = false;
          state.PermissionList = action.payload;
        }
      )
      .addCase(
        GetPermissionsByUserId.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )



      .addCase(GetPermissionsByRuleId.pending, (state, action) => {
        state.loading = true;
        state.PermissionList = {} as PermissionModels;
      })
      .addCase(
        GetPermissionsByRuleId.fulfilled,
        (state, action: PayloadAction<PermissionModels>) => {
          state.loading = false;
          state.PermissionList = action.payload;
        }
      )
      .addCase(
        GetPermissionsByRuleId.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default PermissionSlice.reducer;
