import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
import { GetFormColumn } from "../../store/NTX/FlexValue/Form/FormColumnSlice";
 

const useGetFormColumn = (id)=>{

    const dispatch = useDispatch();

    const { FormColumnList, loading , error} = useSelector((state) => state.FormColumns);
    useEffect(() => {
      if(id){
        dispatch(GetFormColumn({ id, withStatic: false }));
      }
      
    }, [dispatch, id]);

    return { FormColumnList , loading , error} ;
};

export default useGetFormColumn ;