import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";

const initialState = { Grades: [], loading: false, error: null, Grade: [] };

export const getGrades = createAsyncThunk(
  "Grades/getGrades",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
 
   try {
      // const data = await getData( "HRSetting/GetGradeList" , thunkAPI);
      const {data} = await ntxAPI.get("HRSetting/GetGradeList");
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const insertGrade = createAsyncThunk(
  "Grades/insertGrade",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData( "HRSetting/AddGrade" , item);
      const {data} = await ntxAPI.post("HRSetting/AddGrade" , item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const editGrade = createAsyncThunk(
  "Grades/editGrade ",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
  
    try {
      // const data = await postData( "HRSetting/UpdateGrade" , item);
      const {data} = await ntxAPI.post("HRSetting/UpdateGrade" , item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getGrade = createAsyncThunk(
  "Grades/getGrade",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {

      // const data = await getData(`HRSetting/GetGradeById?Id=${id}`);
      const {data} = await ntxAPI.get(`HRSetting/GetGradeById?Id=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);




const gradeSlice = createSlice({
  name: "Grades",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /////// get
      .addCase(getGrades.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getGrades.fulfilled, (state, action) => {
        state.loading = false;
        state.Grades = action.payload;
      })
      .addCase(getGrades.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // get one grade by id
      .addCase(getGrade.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getGrade.fulfilled, (state, action) => {
        state.loading = false;
        state.Grade = action.payload;
      })
      .addCase(getGrade.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      ///////insert
      .addCase(insertGrade.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(insertGrade.fulfilled, (state, action) => {
        state.loading = false;
        state.Grades.push(action.payload);
      })
      .addCase(insertGrade.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      /////Edit
      .addCase(editGrade.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editGrade.fulfilled, (state, action) => {
        state.loading = false;
        state.Grade = action.payload;
      })
      .addCase(editGrade.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default gradeSlice.reducer;
