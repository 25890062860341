import React from 'react';
import AddAbsence from './AddAbsence.jsx';

import { useParams } from 'react-router-dom';
import { Card, CardBody } from '../../../../components/card/card.jsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
// import Loading from '../../../../components/Shared/Loader/Loading';

const Absence = () => {
  const { personId } = useParams();
  const { AbsenceAttendanceList: data, loading , error } = useSelector((state) => state.Absence);
  const { t, i18n } = useTranslation();

  var BreadcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Employees',
      link: '/HR/Employees',
    },
    {
      name: 'Absence',
      link: null,
    },
  ];

 
  return (
    <div>
      <Breadcrumb
        BreadcrumbList={BreadcrumbList}
        PageName="Absence"
        subtitle={i18n.language === 'ar' ? data.name2 : data.name}
      />

      <Card>
        <CardBody>
          {/* <Loading loading={loading} error={error}> */}
            <AddAbsence personId={personId}  />
          {/* </Loading> */}
        </CardBody>
      </Card>
    </div>
  );
};

export default Absence;
