import Swal from 'sweetalert2';

interface ToastSwalProps {
  icon?: 'warning' | 'question' | 'info' | 'error' | 'success';
  title?: string;
  timer?: number;
  htmlContent?: string | any;
  position?:
    | 'bottom'
    | 'bottom-end'
    | 'bottom-left'
    | 'bottom-right'
    | 'bottom-start'
    | 'center'
    | 'center-end'
    | 'center-left'
    | 'center-right'
    | 'center-right'
    | 'center-start'
    | 'top'
    | 'top-end'
    | 'top-left'
    | 'top-right'
    | 'top-start';
}

export default function ToastSwal({ icon, position, timer, title, htmlContent }: ToastSwalProps) {
  const Toast = Swal.mixin({
    toast: true,
    position: position ?? 'center',
    showConfirmButton: false,
    timer: timer ?? 3000,
    timerProgressBar: true,

    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },

    background: 'var(--bs-body-bg)',
    color: 'var(--bs-heading-color)',
    customClass: {
      popup: 'border border-theme border-3 rounded-4',
    },
  });

  Toast.fire({
    icon: icon ?? 'warning',

    title: title ?? '',

    html: htmlContent, 
  });
}
