import { Card, CardBody } from '../../../components/card/card.jsx';
import React, { useEffect, useRef, useState } from 'react';
import Pagination from '../../../components/Shared/Pagination/Pagination.jsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ModalComponent from '../../../components/Modal/ModalComponent';
import AddDepartment from './AddDepartment';
import useGetOrganizations from '../../../hooks/HR/use-get-organizations';
import { usePermissions } from '../../../Helper/Permisstions/usePermissions.ts';
import EditFormDepartments from './EditDepartments/EditFormDepartments';
import MedModalComponent from '../../../components/Modal/MedModalComponent';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import Loading from '../../../components/Shared/Loader/Loading';
// import DatatableTest from '../../Test/ConsolidationSet/DynamicDataTable/DatatableTest';
 
 
const Departments = () => {
  const { HasPermission } = usePermissions();
  const [Show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState('');
  const [id, setId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { Organizations, loading, error } = useGetOrganizations();

  const filteredSearch = Organizations?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  var BreadcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Departments',
      link: null,
    },
  ];
   
  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BreadcrumbList} PageName="Departments" />
        <div className="ms-auto">
          {HasPermission('AddHrDepartments') && (
            <Link
              to=""
              className="btn btn-theme me-1"
              data-bs-toggle="modal"
              data-bs-target="#modalXl"
              onClick={() => setShow(true)}>
              <i className="fa fa-plus-circle fa-fw "></i> {t('AddDepartment')}
            </Link>
          )}
        </div>
      </div>

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="tab-content p-2">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-2">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={(event) => setSearch(event.target.value)}
                          placeholder={t('Search') + ' ...'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-hover text-nowrap">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t('Name')}</th>

                      <th scope="col">{t('location')}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((item, index) => (
                      <tr key={index}>
                        <td className="align-middle">
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </td>
                        <td className="align-middle">
                          {i18n.language === 'ar' ? item.locationName2 : item.locationName}
                        </td>
                        <td className="align-middle text-end">
                          <div className="btn-group">
                            {HasPermission('EditHrDepartments') && (
                              <button
                                type="button"
                                className="btn btn-outline-theme  "
                                data-bs-toggle="modal"
                                data-bs-target="#Update"
                                onClick={() => setId(item.id)}>
                                <i className="far fa-lg fa-fw  fa-edit mx-1"></i>
                                {t('Button.Edit')}
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <Pagination
                filteredData={Organizations}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </CardBody>
        </Loading>
      </Card>

      <ModalComponent id="modalXl" title={t('AddDepartment')}>
        {Show && <AddDepartment />}
      </ModalComponent>

      <MedModalComponent id="Update" title={t('EditDepartment')}>
        {id && <EditFormDepartments id={id} />}
      </MedModalComponent>


      {/* <>
      <DatatableTest/>
      </>
  */}
    </>
  );
};

export default Departments;
