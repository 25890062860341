import { useEffect } from "react";
import { useAppDispatch , useAppSelector } from "../../store/hooks";
import { GetPaymentTermsList } from "../../store/NTX/Lookup/lookupsSlice";
 

const useGetPaymentTermsList = () => {
  const dispatch = useAppDispatch();
 
  useEffect(() => {
    if (PaymentTermsList?.length === 0) {
      dispatch(GetPaymentTermsList());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { PaymentTermsList , loading } = useAppSelector(
    (state) => state.LookupData
  );

  

  return {  PaymentTermsList , loading };
};

export default useGetPaymentTermsList;
