import React from 'react';
 
import Pagination from '../../../../components/Shared/Pagination/Pagination.jsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Card } from '../../../../components/card/card.jsx';
import ModalComponent from '../../../../components/Modal/ModalComponent.jsx';
import AddUser from './AddUser.js';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
// import Loader from '../../../../components/Shared/Loader/Loader.js';
import useGetUsers from '../../../../hooks/NTX/useGetUsers';
import { usePermissions } from '../../../../Helper/Permisstions/usePermissions.ts';
import Loading from '../../../../components/Shared/Loader/Loading';

function Users() {
  const { HasPermission } = usePermissions();

  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const [active, setActive] = useState(true);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { Users, loading , error} = useGetUsers();

  const filteredSearch = Users?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.usersName?.toLowerCase().includes(searchLowerCase) ||
        item.refName?.toLowerCase().toString().includes(searchLowerCase) ||
        item.refName2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.filter((f) => f.isActive === active);

  //

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'UserManagement',
      link: null,
    },
  ];
  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="UserManagement" />

        <div className="ms-auto">
          {HasPermission('NtxAddUser') && (
            <Link
              to=""
              className="btn me-1 btn-theme"
              data-bs-toggle="modal"
              data-bs-target="#modalAddUsers"
              onClick={() => setShow(true)}>
              <i className="fa fa-plus-circle fa-fw me-1"></i>
              {t('AddUser')}
            </Link>
          )}
        </div>
      </div>
      <Card>
        <Loading loading={loading} error={error}>
          <div className="tab-content p-4">
            <div className="row mb-2">
              <div className="col-7 col-md-6 d-flex justify-content-start">
                <div className="input-group mb-2">
                  <div className="flex-fill position-relative">
                    <div className="input-group ">
                      <select
                        className="btn btn-outline-default dropdown-toggle p-0"
                        value={active ? 'true' : 'false'}
                        // onChange={(e) => setActive(e.target.value)}
                        onChange={(e) => setActive(e.target.value === 'true')}>
                        {/* <option className="dropdown-item" value={""}>
                          {t("All")}
                        </option> */}
                        <option className="dropdown-item" value={'true'}>
                          {t('isActive')}
                        </option>
                        <option className="dropdown-item" value={'false'}>
                          {t('InActive')}
                        </option>
                      </select>

                      <div className="flex-fill position-relative">
                        <div className="input-group">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}>
                            <i className="fa fa-search opacity-5"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            placeholder={t('Search')}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* //  START TABLE   */}
            <div className="table-responsive">
              <table className="table text-nowrap">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">{t('UserName')}</th>
                    <th scope="col">{t('EmployeeName')}</th>
                    <th scope="col">{t('isActive')}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {/* <Loading loading={loading} error={error}> */}
                  {currentData.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle">{item.usersName}</td>
                      <td className="align-middle">
                        {i18n.language === 'en' ? item.refName : item.refName2}
                      </td>
                      <td className="align-middle">
                        {/* {item.isActive ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">not Active</span>} */}
                        {item.isActive ? (
                          <span className="badge d-block rounded-0 pt-5px w-70px bg-success text-theme-900">
                            {t('isActive')}
                          </span>
                        ) : (
                          <span className="badge d-block rounded-0 pt-5px w-70px bg-white bg-opacity-25">
                            {t('InActive')}
                          </span>
                        )}
                      </td>
                      <td className="text-end">
                        <div className="">
                          {HasPermission('NtxAddpermissionsRuletoUser') && (
                            <button
                              type="button"
                              className="btn btn-outline-theme m-1"
                              onClick={() =>
                                Navigate(`${item.id}/RulesUser`, {
                                  state: {
                                    name: i18n.language === 'en' ? item.refName : item.refName2,
                                  },
                                })
                              }>
                              <i className="fa-lg fa-fw me-2 bi bi-lock-fill"></i>
                              {t('UserRules')}
                            </button>
                          )}
                          {HasPermission('NtxAddadditionalpermissionstouser') && (
                            <button
                              type="button"
                              className="btn btn-outline-theme m-1"
                              onClick={() =>
                                Navigate(`${item.id}/PermissionUser`, {
                                  state: {
                                    name: i18n.language === 'en' ? item.refName : item.refName2,
                                  },
                                })
                              }>
                              <i className="fas fa-lg fa-fw me-2 fa-tasks"></i>
                              {t('ExtraPermissions')}
                            </button>
                          )}

                          {HasPermission('NtxEditUser') && (
                            <button
                              type="button"
                              className="btn btn-outline-theme m-1"
                              onClick={() => Navigate(`${item.id}/Edit`)}>
                              <i className="far fa-lg fa-fw m-2 fa-edit"></i>
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </div>
        </Loading>
      </Card>
      <ModalComponent title={t('AddUser')} id="modalAddUsers">
        {show && <AddUser />}
      </ModalComponent>
    </>
  );
}

export default Users;
