import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { GetGlJeSourceList } from '../../store/Finance/JeSource/sourceSlice';

const useGetGlJeSourceList = () => {


    const dispatch = useAppDispatch();
  

  
    useEffect(() => {
      if(GlJeSourceList?.length ===0){
        dispatch(GetGlJeSourceList());
      }
    }, []);
    const { GlJeSourceList , loading , error} = useAppSelector(
      (state) => state.JeSource
    );

   

  return { GlJeSourceList, loading , error }
}

export default useGetGlJeSourceList
