import React, { useState } from 'react';
import MedModalComponent from '../../../../components/Modal/MedModalComponent';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import Loader from '../../../../components/Shared/Loader/Loader';
import { useTranslation } from 'react-i18next';

import Pagination from '../../../../components/Shared/Pagination/Pagination';
import AddBankAccounts from './Add';

import UpdateBankAccounts from './Update';
import useGetGlJeSourceList from '../../../../hooks/Finance/useGetGlJeSourceList';
import { Link } from 'react-router-dom';
import useGetBankAccountsList from '../../../../hooks/Finance/BankAccounts/useGetBankAccountsList';

const Index = () => {
  const [ID, setID] = useState<string>('');

  const [Show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t } = useTranslation();

  const { GlJeSourceList, loading, error } = useGetGlJeSourceList();

  const { ApBankAccountsList } = useGetBankAccountsList();

  const filteredSearch = ApBankAccountsList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'الحسابات البنكيـــة',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="الحسابات البنكية" />

        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-outline-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAddDailyRates"
            onClick={() => setShow(true)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            حساب بنكي جديد
          </Link>
        </div>
      </div>

      <Card>
        <Loader loading={loading} />
        <CardBody>
          <div className="tab-content p-1">
            <div className="row mb-2">
              <div className="col-lg-6">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">{t('Search')}</span>
                  <div className="input-group">
                    <div className="flex-fill position-relative">
                      <div className="input-group border-0 ">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5 text-theme"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          // placeholder={t('Search')}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-hover text-nowrap">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">{t('LocalName')}</th>
                    <th scope="col">{t('GlobalName')}</th>
                    <th scope="col">{t('رقم الأيبان')}</th>
                    <th scope="col">{t('رقم الحساب')}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle"> {item.name2} </td>
                      <td className="align-middle"> {item.name} </td>

                      <td>
                        <button
                          type="button"
                          className="btn btn-outline-theme"
                          data-bs-toggle="modal"
                          data-bs-target="#modalEditDailyRates"
                          onClick={() => setID(item.id)}>
                          <i className="far fa-lg fa-fw fa-edit"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </div>
        </CardBody>
      </Card>

      <MedModalComponent title="اضافة" id="modalAddDailyRates">
        {Show && <AddBankAccounts />}
      </MedModalComponent>

      <MedModalComponent title="تعديل" id="modalEditDailyRates">
        {ID && <UpdateBankAccounts ID={ID} />}
      </MedModalComponent>
    </div>
  );
};

export default Index;
