import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { ntxAPI } from '../../../../API/axiosNeatex';
import SkeletonLoader from '../../../../components/Shared/SkeletonLoader/SkeletonLine';
import { FormikProps } from 'formik';

interface Props {
  formik: FormikProps<any>;
}
const Reference: React.FC<Props> = ({ formik }) => {
  const { values } = formik;
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  //#region  جلب فواتير المبيعات او اوامر البيع او او او بناء على الـ Code
  useEffect(() => {
    async function GetData() {
      setIsLoading(true);
      setError(null);
      let url = '';

      switch (values.code) {
        case 'BIL':
          url = 'PurchaseBill/GetList';
          break;
        case 'POR':
          url = 'PurchaseOrder/GetList';
          break;
        case 'CRT':
          url = 'SalesReturns/GetTransactionsReturnsList';
          break;
        case 'IOR':
          url = 'InvMove/GetTransactionsMoveList';
          break;
        default:
          setIsLoading(false);
          setData([]);
          return;
      }

      try {
        const { data } = await ntxAPI.get(url);
        if (data?.succeeded) {
          const res = data.data.map((item: any) => ({
            value: item.id,
            label: item.code,
          }));
          setData(res);
        } else {
          setError('Error !!');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error !!');
      } finally {
        setIsLoading(false);
      }
    }

    GetData();
  }, [values.code]);
  //#endregion

  return (
    <>
      {isLoading ? (
        <SkeletonLoader />
      ) : error ? (
        <div>{error}</div>
      ) : (
        <Select
          isLoading={isLoading}
          classNamePrefix="react-select"
          isClearable
          isSearchable={true}
          options={data}
          placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
          onChange={(option: any) => {
            formik.setFieldValue('referenceId', option === null ? null : option.value);
          }}
          // styles={customStyles} // Apply custom styles
        />
      )}
    </>
  );
};

export default Reference;
