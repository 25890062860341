import React, { useState } from 'react';
import useGetChartAccountsDetailsList from '../../../../hooks/Finance/useGetChartAccountsDetailsList';
import { Form } from 'react-bootstrap';
import { TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../store/hooks';
import {
  DeleteGlConsolidationSet,
  AddConsolidationSet,
  getOrganizationToConsolidationSetList,
} from '../../../../store/Finance/ConsolidationSet/consolidationSetSlice';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { consolidationSetModels } from '../../../../store/Finance/ConsolidationSet/consolidationSetModels';
import Loading from '../../../../components/Shared/Loader/Loading';
import ToastSwal from '../../../../components/Shared/Alert/ToastSwal';

interface Props {
  data: consolidationSetModels;
}
const AddUpdateConsolidation = ({ data }: Props) => {
  console.log(data);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accountsId, setAccountsId] = useState(data.chartOfAccountsDetailsId);

  const { TreeCostCenters, TreeCompanies, TreeBranches, loading, error } =
    useGetChartAccountsDetailsList();
  const { t, i18n } = useTranslation();
  const { type } = useParams();
  const dispatch = useAppDispatch();

  const onSubmit = (e: any) => {
    // setIsSubmitting(true);
    e.preventDefault();

    if (!accountsId) {
      ToastSwal({ title: 'حدد الحسـاب', position: 'top-start' });
      return;
    }
    let body = {
      chartOfAccountsDetailsId: accountsId,
      organizationId: data.organizationId,
    };

    dispatch(AddConsolidationSet(body))
      .unwrap()
      .then((res) => {
        if (res.succeeded === true) {
          setIsSubmitting(false);
          CustomAlert({ action: 'Add' });
          dispatch(getOrganizationToConsolidationSetList());
        } else {
          setIsSubmitting(false);
          CustomAlert({ action: 'Error' });
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        CustomAlert({ action: 'Error' });
      });
  };

  const onDelete = (e: any) => {
    // setIsSubmitting(true);
    e.preventDefault();

    let body = {
      chartOfAccountsDetailsId: data.chartOfAccountsDetailsId,
      organizationId: data.organizationId,
    };

    dispatch(DeleteGlConsolidationSet(body))
      .unwrap()
      .then((res) => {
        if (res.succeeded === true) {
          setIsSubmitting(false);
          CustomAlert({ action: 'Add', msg:'تم إزالة الربط' });
          dispatch(getOrganizationToConsolidationSetList());
        } else {
          setIsSubmitting(false);
          CustomAlert({ action: 'Error' });
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        CustomAlert({ action: 'Error' });
      });
  };

  return (
    <>
      <Loading loading={loading} error={error} Type='Dots'>
        <Form onSubmit={onSubmit}>
          <div className="row align-items-end">
            {type === 'CostCenter' && (
              <div className="col-lg-10">
                <div className="form-group mb-3">
                  <label className="form-label">{t('CostCenter')}</label>
                  <TreeSelect
                    // status='warning'
                    showSearch
                    treeData={TreeCostCenters}
                    className="custom-tree-select w-100"
                    treeLine
                    value={accountsId}
                    onChange={(option) => {
                      setAccountsId(option);
                    }}
                    allowClear
                    treeDefaultExpandAll
                    getPopupContainer={(triggerNode) => triggerNode.parentNode} // يجعل القائمة تظهر داخل نفس عنصر الـ parent
                  />
                </div>
              </div>
            )}

            {type === 'Company' && (
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="form-label">{t('الشركات')}</label>
                  <TreeSelect
                    showSearch
                    treeData={TreeCompanies}
                    className="custom-tree-select w-100"
                    treeLine
                    placeholder="حدد الشركة"
                    allowClear
                    treeDefaultExpandAll
                    getPopupContainer={(triggerNode) => triggerNode.parentNode} // يجعل القائمة تظهر داخل نفس عنصر الـ parent
                    value={accountsId}
                    onChange={(option) => {
                      setAccountsId(option);
                    }}
                  />
                </div>
              </div>
            )}

            {type === 'branch' && (
              <>
                {/* <div className="col-lg-10">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">{t('الفروع')}</span>
                  <div className="input-group">
                    <div className="flex-fill position-relative">
                      <div className="input-group border-0 ">
                        <TreeSelect
                        style={{borderRadius: '0px'}}
                          showSearch
                          treeData={TreeBranches}
                          value={accountsId}
                          className="custom-tree-select w-100  "
                          treeLine
                          placeholder="حدد الفرع"
                          allowClear
                          treeDefaultExpandAll
                          getPopupContainer={(triggerNode) => triggerNode.parentNode} // يجعل القائمة تظهر داخل نفس عنصر الـ parent
                          onChange={(option) => {
                            setAccountsId(option);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الفروع')}</label>
                    <TreeSelect
                      showSearch
                      treeData={TreeBranches}
                      value={accountsId}
                      className="custom-tree-select w-100"
                      treeLine
                      placeholder="حدد الفرع"
                      allowClear
                      treeDefaultExpandAll
                      getPopupContainer={(triggerNode) => triggerNode.parentNode} // يجعل القائمة تظهر داخل نفس عنصر الـ parent
                      onChange={(option) => {
                        setAccountsId(option);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="d-flex justify-content-end">
            {data.chartOfAccountsDetailsId !== null && (
              <button
                type="button"
                className="btn btn-danger me-1 w-50"
                onClick={(e) => onDelete(e)}>
                <i className="fas fa-unlink me-1"></i>
                إزالة الربط
              </button>
            )}
            <button disabled={isSubmitting} type="submit" className="btn me-1 btn-theme w-50">
              {isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="fa fa-link fa-fw me-1"></i>
              )}
              {t('ربط')}
            </button>
          </div>
        </Form>
      </Loading>
    </>
  );
};

export default AddUpdateConsolidation;
