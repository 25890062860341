import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  GetMasterItemsListByInventoryId,
  cleanUpMasterItemsListByInventoryId,
} from '../../../store/INV/MasterItems/MasterItemsSlice';
import { useTranslation } from 'react-i18next';

const useGetItemsListByInventoryId = (InventoryId: string | undefined) => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  useEffect(() => {
    if (InventoryId) {
      dispatch(GetMasterItemsListByInventoryId(InventoryId));
    }
    return () => {
      dispatch(cleanUpMasterItemsListByInventoryId());
    };
  }, [InventoryId, dispatch]);
  const { MasterItemsListByInventoryId, loading, error } = useAppSelector(
    (state) => state.MasterItemsINV
  );

  const ItemsOptions = MasterItemsListByInventoryId?.map((item) => ({
    value: item.id,

    // label: (
    //   <div className='d-flex justify-content-between'>
    //     <div className="">{i18n.language === 'en' ? item.name : item.name2}</div>
    //     <div className="text-theme">{item.itemCode}</div>
    //   </div>
    // ),
    label: i18n.language === 'en' ? item.name : item.name2,
    itemCode: item.itemCode ,
    //________________________________________________________________
    itemName: `${i18n.language === 'en' ? item.name : item.name2}`,
    salesPrice: item.salesPrice,
    purchasePrice: item.purchasePrice,
    itemNo: item.itemCode,
    uom: item.unitOfMeasure,
  }));

  return { MasterItemsListByInventoryId, ItemsOptions, loading, error };
};

export default useGetItemsListByInventoryId;
