
import React, { Fragment } from "react";
import AddDepartment from "../../../HR/Departments/AddDepartment";
import AddLocation from "../../../HR/settings/Location/AddLocation";

const AddLocations = () => {

  return (
<Fragment>
  <AddLocation/>
</Fragment>
  );
};

export default AddLocations;
