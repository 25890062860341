import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
 import { usePermissions } from '../../../Helper/Permisstions/usePermissions.ts';

const RotationList = ({ RotationPlanList, loading }) => {
  const { HasPermission } = usePermissions();

  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  return (
    <>
      <div className="">
        <div className="table-responsive rounded-2 text-center border border-theme">
          <table className="table table-hover text-nowrap m-0">
            <thead className="bg-theme bg-opacity-60">
              <tr>
                <th scope="col">{t('Name')}</th>
                <th scope="col">{t('Start Date')}</th>
                <th scope="col">{t('Date_from')}</th>
                <th scope="col"></th>
              </tr>
            </thead>

            <tbody>
              {RotationPlanList.map((item, idx) => (
                <tr key={item.id}>
                  <td className="align-middle">
                    {i18n.language === 'ar' ? item.name2 : item.name}
                  </td>
                  <td className="align-middle">{item.startDate}</td>
                  <td className="align-middle">{item.endDate}</td>
                  <td className="align-middle text-end">
                    {HasPermission('ViewDetailsRaqRotationPlans') && (
                      <button
                        type="button"
                        onClick={() => Navigate(`${item.id}/RotationSetting`)}
                        className="btn btn-outline-theme mb-1">
                        <i className="fab fa-lg fa-fw me-2 fa-whmcs"></i>
                        {t('PlanSettings')}
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default RotationList;
