import React from "react";

import Permissions from "../Permission/Permissions";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import { useLocation } from "react-router-dom";

const PermissionRule = () => {

  const location = useLocation();
  const name = location.state;

//_______________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "UserRules",
      link: "/Security/AuthRules",
    },
    {
      name: "Permissions",
      link: null,
    },
  ];

  return (
    <div>
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="Permissions" subtitle={name.name} />
        {/* <hr/> */}
      <Permissions type="rule" />
    </div>
  );
};

export default PermissionRule;
