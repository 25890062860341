
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { GetWorkShopById, _UpdateWorkShop } from '../../../../store/FLEET/Workshop/workshopSlice';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { Form } from 'react-bootstrap';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { AddWorkShopFLeetSchema } from '../../../ValidationForm/validationSchema';
import EditFormDepartments from '../../../HR/Departments/EditDepartments/EditFormDepartments';
import useOrganizationDetails from '../../../../hooks/HR/use-organization-details';
import EditFormLocation from '../../../HR/settings/Location/EditLocation/EditFormLocation';
import useLocationDetails from '../../../../hooks/Lookups/use-location-details';

const UppdateLocations = () => {

    const { id } = useParams()
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const Navigate = useNavigate();


    const { Location, loading , error } = useLocationDetails();
    // const { Location, loading , error } = useLocationDetails();


      //______________________________
      var BcrumbList = [
        {
          name: "Home",
          link: "/",
        },
        {
          name: "",
          link: "/FLEETSettings/Locations",
        },
        {
          name: "Edit",
          link: null,
        },
      ];
  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="EditLocation" />
      
      <Card>
        {/* <Loader loading={loading} /> */}
        
        <CardBody>
          {Object.keys(Location)?.length > 0 && (
             <EditFormLocation Location={Location} loading={loading}/>
          )}
        </CardBody>
      </Card>
    </div>
  )
}

export default UppdateLocations
