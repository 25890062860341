import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { GetEquipmentsList } from '../../store/FLEET/Equipments/equipmentsSlice';

const useGetEquipmentsList = () => {

    const dispatch = useAppDispatch();
    
    const { EquipmentsList, loading , error } = useAppSelector(
        (state) => state.Equipments
      );


    useEffect(() => {
        if(EquipmentsList?.length === 0){
          dispatch(GetEquipmentsList());
        }
      }, []);

   

  return { EquipmentsList, loading , error }
}

export default useGetEquipmentsList
