import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { GetPersonLookup } from "../../store/HR/Person/employeeSlice";
import { useTranslation } from "react-i18next";

const useGetPersons = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const { PersonList:Employees, loading, error } = useSelector((state) => state.Employees);

  useEffect(() => {
    const fetchData = async () => {
      if (Employees?.length === 0) {
        await dispatch(GetPersonLookup());
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const dataEmployees =
    Employees &&
    Employees.map((item) => ({
      value: item.id,
      label: i18n.language === "en" ? item.name : item.name2,
      employeeNumber : item.employeeNumber
    }));

  return { Employees, dataEmployees, loading, error };
};

export default useGetPersons;
