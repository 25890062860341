import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { GetNtxPeoplesList } from '../../../store/NTX/Peoples/peoplesSlice';
import { useTranslation } from 'react-i18next';

const useGetCustomersList = () => {
  const { i18n, t } = useTranslation();

  const dispatch = useAppDispatch();
  const { PeoplesList, loading, error } = useAppSelector((state) => state.Peoples);
  useEffect(() => {
    if (CustomerList?.length === 0) {
      dispatch(GetNtxPeoplesList('CUSTOMERS'));
    }
  }, []);

  var CustomerList = PeoplesList;
  var OptionCustomers = CustomerList.map((item) => ({
    value: item.id,
    label: i18n.language === 'en' ? item.name : item.name2,
    id: item.id,
    parentId: item.parentId,
  }));

  //#region TreeData
  const convertToTreeData = () => {
    const map = new Map();
    const treeData: { title: string; value: string; children: never[] }[] = [];

    OptionCustomers?.forEach((item) => {
      const node = {
        title: item.label,
        value: item.id,
        children: [],
      };

      map.set(item.id, node);

      if (item.parentId === null) {
        treeData.push(node);
      } else {
        const parent = map.get(item.parentId);
        if (parent) {
          parent.children.push(node);
        }
      }
    });

    return treeData;
  };
  const treeCustomerData = convertToTreeData();
  //#endregion

  const OptionCustomersWithAll = [
    {
      label: t('جميع العملاء'),
      value: 'All',
    },
    ...OptionCustomers,
  ];

  return { CustomerList, OptionCustomersWithAll ,  OptionCustomers, treeCustomerData, loading, error };
};

export default useGetCustomersList;
