import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';

interface PopoverCustomProps {
  childern: React.ReactNode; // محتوى العنصر الذي سيقوم بتفعيل الـ Popover
  Header?: string; // عنوان الـ Popover (اختياري)
  Body: React.ReactNode; // محتوى الـ Popover
  placement?: 'top' | 'right' | 'bottom' | 'left'; // تحديد موضع الـ Popover (افتراضيًا أعلى)
}

const PopoverCustom: React.FC<PopoverCustomProps> = ({
  childern: triggerContent,
  Header: popoverTitle,
  Body: popoverContent,
  placement = 'top',
}) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Popover className="text-center border-1 border-theme shadow">
          {popoverTitle && (
            <Popover.Header className="text-center fw-bold small bg-theme bg-opacity-25">
              <h6 className="m-0">{popoverTitle}</h6>
            </Popover.Header>
          )}
          <Popover.Body >{popoverContent}</Popover.Body>
        </Popover>
      }
      trigger={['hover', 'focus']}>
      <span>{triggerContent}</span>
    </OverlayTrigger>
  );
};

export default PopoverCustom;
