/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, {  useEffect , useState } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import TypeD from "./TypeD";
import TypeT from "./TypeT";
// import FileList from "../../../Attachments/FileList";
import TypeL from "./TypeL";

// Extra Information Form --" DynamicFormType 2 , 3 , 4"
const ExtraInformationBuilderAdd = (props) => {
  const { FormColumnList, setExtraInfoFormData, ReferenceId } = props;

  const {  i18n } = useTranslation();
  const [formData, setFormData] = useState({});
  const sortedList = FormColumnList?.columnList
    ?.slice()
    ?.filter((f) => f.enabledFlag === true)
    ?.sort((a, b) => a.sequenceNum - b.sequenceNum);

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    const value = selectedOption ? selectedOption.value : null;
    setFormData({ ...formData, [name]: value });
  };

  const handleInputChange = (e, selectedOption) => {
    const { name, type, files, value, checked } = e.target;

    if (type === "checkbox") {
      const checkboxValue = checked ? "1" : "0";
      setFormData({ ...formData, [name]: checkboxValue });
    } else if (type === "file") {
      setFormData({ ...formData, [name]: files[0] });
      // log(files);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    const updatedData = {
      dynamic_form_id: FormColumnList?.dynamicFormId,
      extra_information_id: "",
      reference_id: ReferenceId ? ReferenceId : "",
      module_id: FormColumnList?.moduleId,
      values: sortedList?.map((item) => ({
        dynamic_form_column_id: item.id,
        data_type: item.validationType || item.formatType,

        value:
          item.validationType === "BOOLEAN" && !formData[item.id]
            ? "0"
            : item.validationType === "ATTACHED"
            ? null
            : (formData[item.id]||''),

        lable_text: item.lable,
        lable_text2: item.lable2,
        id: "",
        fileAttachment:
          item.validationType === "ATTACHED" && formData[item.id]
            ? formData[item.id]
            : null,
        isUpdateAttach:
          item.validationType === "ATTACHED" && formData[item.id]
            ? true
            : false,
      })),
    };
    setExtraInfoFormData(updatedData);
  }, [formData]);

  //______________________________________________________________________________________
  return (
    <div>

      <div className="row">
        {sortedList?.map((item, index) => {
          if (item?.formatType === "S") {
            switch (item?.validationType) {
              case "DATE":
                return (
                  <div key={item?.id} className="col-xl-6">
                    <div className="form-group mb-3">
                      <label className="form-label">
                        {i18n.language === "ar" ? item?.lable2 : item?.lable}
                      </label>
                      <Form.Control
                        className="form-control"
                        required={item?.requiredFlag}
                        type="Date"
                        name={item?.id}
                        onChange={handleInputChange}
                        value={formData[item?.id] || ""}
                      />
                    </div>
                  </div>
                );
              case "NUMBER":
                return (
                  <div key={item.id} className="col-xl-6">
                    <div className="form-group mb-3">
                      <label className="form-label">
                        {i18n.language === "ar" ? item.lable2 : item.lable}
                      </label>
                      <Form.Control
                        className="form-control"
                        required={item.requiredFlag}
                        type="number"
                        name={item.id}
                        onChange={handleInputChange}
                        value={formData[item.id] || ""}
                      />
                    </div>
                  </div>
                );
              case "TEXT":
                return (
                  <div key={item.id} className="col-xl-6">
                    <div className="form-group mb-3">
                      <label className="form-label">
                        {i18n.language === "ar" ? item.lable2 : item.lable}
                      </label>
                      <Form.Control
                        className="form-control"
                        required={item.requiredFlag}
                        type="text"
                        name={item.id}
                        onChange={handleInputChange}
                        value={formData[item.id] || ""}
                      />
                    </div>
                  </div>
                );
              case "TIME":
                return (
                  <div key={item.id} className="col-xl-6">
                    <div className="form-group mb-3">
                      <label className="form-label">
                        {i18n.language === "ar" ? item.lable2 : item.lable}
                      </label>
                      <Form.Control
                        className="form-control"
                        required={item.requiredFlag}
                        type="time"
                        name={item.id}
                        onChange={handleInputChange}
                        value={formData[item.id] || ""}
                      />
                    </div>
                  </div>
                );

              case "ATTACHED":
                return (
                  <div key={item.id} className="col-xl-6">
                    <div className="form-group mb-3 bg-inverse bg-opacity-10 rounded-4 p-2">
                      <label className="form-label">
                        {i18n.language === "ar" ? item.lable2 : item.lable}
                      </label>
                      <Form.Control
                        className="form-control"
                        type="file"
                        name={item.id}
                        onChange={handleInputChange}
                        // onChange={handlTestChange}
                        // value={formData[item.id] | ""}
                        multiple
                      />
                      {/* <FileList uploadedFiles={uploadedFiles} onDeleteFile={deleteFile} /> */}
                    </div>
                  </div>
                );
              case "BOOLEAN":
                return (
                  <div key={item.id} className="col-xl-6">
                    <div className="form-group mb-3">
                      <label className="form-label">
                        {i18n.language === "ar" ? item.lable2 : item.lable}
                      </label>
                      <Form.Check
                        style={{ margin: "10px 20px" }}
                        type="switch"
                        name={item.id}
                        checked={formData[item.id] === "1"}
                        // defaultChecked={formData[item.id] === "1"}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                );
            }
          } else if (item.formatType === "D") {
            return (
              <div key={item.id} className="col-xl-6">
                <TypeD item={item} handleSelectChange={handleSelectChange} />
              </div>
            );
          } else if (item.formatType === "T") {
            return (
              <div key={item.id} className="col-xl-6">
                <TypeT
                  key={item.id}
                  item={item}
                  handleSelectChange={handleSelectChange}
                />
              </div>
            );
          } else if (item?.formatType === "L") {
            return (
              <div key={item.id} className="col-xl-6">
                <TypeL
                  key={item.id}
                  item={item}
                  handleSelectChange={handleSelectChange}
                />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default ExtraInformationBuilderAdd;
