import { useFormik } from 'formik';
import React from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';
import { Card, CardBody } from '../../../components/card/card';
import useGetRotationPlan from '../../../hooks/HR/use-get-RotationPlan.js';
import { AddRotationPlan, GetRotationPlan } from '../../../store/HR/Raqeeb/raqeebSlice.js';
import { formRaqeebSchema } from '../../ValidationForm/validationSchema';
import RotationList from './RotationPlanList';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import { usePermissions } from '../../../Helper/Permisstions/usePermissions';
import Loading from '../../../components/Shared/Loader/Loading';

function RotationPlan() {
  const { HasPermission } = usePermissions();

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: '',
      name2: '',
      startDate: '',
      endDate: '',
      description: '',
      allowIn: '',
      allowOut: '',
      count_Fingerprint: '',
    },
    validationSchema: formRaqeebSchema(t),
    onSubmit: (values) => {
      dispatch(
        AddRotationPlan({
          name: values.name,
          name2: values.name2,
          startDate: values.startDate,
          endDate: values.endDate,
          description: values.description,
          allowIn: values.allowIn,
          allowOut: values.allowOut,
          count_Fingerprint: values.count_Fingerprint,
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          CustomAlert({ action: 'Add' });
          dispatch(GetRotationPlan());
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  // ________________

  const { RotationPlanList, loading, error } = useGetRotationPlan();

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('RotationPlan'),
      link: null,
    },
  ];

  return (
    <>
      <div className="row">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('RotationPlanRaqeeb')} />

        <Card>
          {/* <Loader  /> */}
          <Loading loading={loading} error={error}>
            <CardBody>
              {HasPermission('AddRaqRotationPlans') && (
                <Form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-xl-4">
                      <div className="form-group mb-3">
                        <label className="form-label">{t('LocalName')}</label>
                        <Form.Control
                          className="form-control"
                          type="text"
                          name="name2"
                          onChange={formik.handleChange}
                          value={formik.values.name2}
                          isInvalid={!!formik.errors.name2}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.name2}
                        </Form.Control.Feedback>
                      </div>
                    </div>

                    <div className="col-xl-4">
                      <div className="form-group mb-3">
                        <label className="form-label">{t('GlobalName')}</label>
                        <Form.Control
                          className="form-control"
                          type="text"
                          name="name"
                          onChange={formik.handleChange}
                          value={formik.values.name}
                          isInvalid={!!formik.errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.name}
                        </Form.Control.Feedback>
                      </div>
                    </div>

                    <div className="col-xl-4">
                      <div className="form-group mb-3">
                        <label className="form-label">{t('NumberOfFingerprints')}</label>

                        <Form.Select
                          className="form-select"
                          name="count_Fingerprint"
                          onChange={formik.handleChange}
                          value={formik.values.count_Fingerprint}
                          isInvalid={
                            formik.touched.count_Fingerprint && formik.errors.count_Fingerprint
                          }>
                          <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                          <option value="1">بصمة واحدة</option>

                          <option value="2">بصمتــــان</option>

                          <option value="4">4 بصمــات</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.count_Fingerprint}
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-3">
                      <div className="form-group mb-3">
                        <label className="form-label">{t('AllowIn')}</label>
                        <Form.Control
                          type="time"
                          className="form-c ontrol"
                          name="allowIn"
                          onChange={formik.handleChange}
                          value={formik.values.allowIn}
                          isInvalid={formik.touched.allowIn && formik.errors.allowIn}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.allowIn}
                        </Form.Control.Feedback>
                      </div>
                    </div>

                    <div className="col-xl-3">
                      <div className="form-group mb-3">
                        <label className="form-label">{t('AllowOut')}</label>
                        <Form.Control
                          type="time"
                          className="form-c ontrol"
                          name="allowOut"
                          onChange={formik.handleChange}
                          value={formik.values.allowOut}
                          isInvalid={formik.touched.allowOut && formik.errors.allowOut}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.allowOut}
                        </Form.Control.Feedback>
                      </div>
                    </div>

                    <div className="col-xl-3">
                      <div className="form-group mb-3">
                        <label className="form-label">{t('Start Date')}</label>
                        <Form.Control
                          type="date"
                          className="form-c ontrol"
                          name="startDate"
                          onChange={formik.handleChange}
                          value={formik.values.startDate}
                          isInvalid={formik.touched.startDate && formik.errors.startDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.startDate}
                        </Form.Control.Feedback>
                      </div>
                    </div>

                    <div className="col-xl-3">
                      <div className="form-group mb-3">
                        <label className="form-label">{t('Date_from')}</label>
                        <Form.Control
                          type="date"
                          className="form-c ontrol"
                          name="endDate"
                          onChange={formik.handleChange}
                          value={formik.values.endDate}
                          isInvalid={formik.touched.endDate && formik.errors.endDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.endDate}
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3">
                        <label className="form-label">{t('Note')}</label>
                        <Form.Control
                          className="form-control"
                          as="textarea"
                          rows={3}
                          name="description"
                          onChange={formik.handleChange}
                          value={formik.values.description}
                          isInvalid={formik.touched.description && formik.errors.description}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.description}
                        </Form.Control.Feedback>
                        <div></div>
                      </div>
                    </div>
                  </div>

                  <div className="text-center">
                    <button
                      disabled={formik.isSubmitting}
                      type="submit"
                      className="btn btn-lg me-1 btn-theme mb-1 ">
                      <i className="fas fa-plus fa-fw me-1"></i>{' '}
                      {formik.isSubmitting ? '...' : t('Add')}
                    </button>
                  </div>
                </Form>
              )}
              <div className="row">
                <div className="col-lg-12">
                  <div className="  rounded-3">
                    {RotationPlanList?.length > 0 && (
                      <>
                        <hr className="mb-4" />
                        <RotationList RotationPlanList={RotationPlanList} loading={loading} />{' '}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </CardBody>
          </Loading>
        </Card>

        <div />
      </div>
    </>
  );
}

export default RotationPlan;
