import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useTranslation } from 'react-i18next';
import { getAssignmentStatusList } from '../../store/HR/Person/assignmentSlice';

const useGetAssignmentStatusList = () => {
  const { i18n } = useTranslation();

  const { AssignmentStatusList, loading, error } = useAppSelector((state) => state.Assignments);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (AssignmentStatusList?.length === 0) {
      dispatch(getAssignmentStatusList());
    }
  }, []);

  console.log(AssignmentStatusList);
  

  const AssignmentStatusOptions =
    AssignmentStatusList &&
    AssignmentStatusList?.map((item: any) => ({
      value: item.id,
      label: i18n.language === 'en' ? item.name : item.name2,
    }));

  return { AssignmentStatusList, AssignmentStatusOptions, loading  , error};
};

export default useGetAssignmentStatusList;
