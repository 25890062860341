import React from "react";

import Permissions from "../Permission/Permissions";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import { useLocation } from "react-router-dom";
 


 


const PermissionUser = () => {
 
  //________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "UserManagement",
      link: "/Security/Users",
    },
    {
      name: "ExtraPermissions",
      link: null,
    },
  ];

  // var user= Users?.filter((f: any) => f.id === refrenceId)
  const location = useLocation();
  const name = location.state;
  
  return (
    <div>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName="ExtraPermissions"
        subtitle={ name.name}
      />
      {/* <hr/> */}
      <Permissions type="user" />
    </div>
  );
};

export default PermissionUser;
