import React, { useState } from 'react';
import MedModalComponent from '../../../../components/Modal/MedModalComponent';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import Loader from '../../../../components/Shared/Loader/Loader';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import AddFiscalYear from './AddFiscalYear';
import useGetGlPeriodsList from '../../../../hooks/Finance/useGetGlPeriodsList';
import { Link } from 'react-router-dom';
import { PeriodsModels } from '../../../../store/Finance/Periods/PeriodsModels';
import UpdateFiscalYear from './UpdateFiscalYear';
import Loading from '../../../../components/Shared/Loader/Loading';

const Index = () => {
  const [id, setId] = useState(''); // for sent as props to Update component
  const [dataById, setDataById] = useState<PeriodsModels>(); // for sent as props to Update component PeriodsModels

  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();

  const { PeriodsList, loading , error } = useGetGlPeriodsList();

 

  const filteredSearch = PeriodsList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.filter((f) => f.periodYear === null);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'السنة المالية',
      link: null,
    },
  ];

  return (
    <div>
      
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="السنة المالية" />

        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-outline-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAddFiscalYear"
            // onClick={() => setShow(true)}
          >
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            اضافة سنة مالية
          </Link>
        </div>
      </div>

      <Card>
      <Loading loading={loading} error={error}>
        <CardBody>
          <div className="tab-content p-1">
            <div className="row">
              <div className="col-7 col-md-6 d-flex justify-content-start">
                <div className="input-group mb-2">
                  <div className="flex-fill position-relative">
                    <div className="input-group">
                      <div
                        className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                        style={{ zIndex: 1020, right: '10px' }}>
                        <i className="fa fa-search opacity-5"></i>
                      </div>
                      <input
                        type="text"
                        className="form-control ps-35px"
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={t('Search') + ' ...'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* //  START TABLE   */}
            <div className="table-responsive">
              <table className="table table-hover text-nowrap">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">{t('السنة')}</th>
                    <th scope="col">{t('From')}</th>
                    <th scope="col">{t('to')}</th>
                    <th scope="col">الإجـراء</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle">
                        {' '}
                        {i18n.language === 'ar' ? item.name2 : item.name}{' '}
                      </td>
                      <td className="align-middle"> {item.startDate.toString()} </td>
                      <td className="align-middle"> {item.endDate.toString()} </td>
                      <td className="align-middle">
                        <button
                          type="button"
                          className="btn btn-outline-theme"
                          data-bs-toggle="modal"
                          data-bs-target="#modalUpdateFiscalYear"
                          onClick={() => setDataById(item)}
                        >
                          <i className="far fa-lg fa-fw mx-2 fa-edit"></i>
                          {/* {t('Button.Edit')} */}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </div>
        </CardBody>
        </Loading>
      </Card>

      <MedModalComponent title="اضافة سنة مالية" id="modalAddFiscalYear">
        {<AddFiscalYear />}
      </MedModalComponent>

      <MedModalComponent title="تعديل سنة مالية" id="modalUpdateFiscalYear">
        {dataById && <UpdateFiscalYear DataById={dataById} />}
      </MedModalComponent>
    </div>
  );
};

export default Index;
