import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Link } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import {
  GetPosPaymentMethodByPosId,
  deletePosPaymentMethod,
} from '../../../../store/Sales/Pos/PosPaymentMethodSlice';
import showConfirmation from '../../../../components/Shared/Alert/Confirmation';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';

type Props = {
  posId: string;
};

const PosPaymentMethodList = ({ posId }: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GetPosPaymentMethodByPosId(posId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posId]);

  const {
    PosPaymentMethodListByPosId: data,
    loading,
    error,
  } = useAppSelector((a) => a.PosPaymentMethod);

  const handleDelete = (id: any) => {
    showConfirmation(t('MsgConfirm'), () => {
      dispatch(deletePosPaymentMethod(id))
        .unwrap()
        .then(() => {
          CustomAlert({ action: 'Delete' });
          dispatch(GetPosPaymentMethodByPosId(posId));
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: 'info' });
        });
    });
  };

  return (
    <Loading loading={loading} error={error}>
      {data.length > 0 ? (
        <div>
          <div className="table-responsive rounded-1 text-center ">
            <table className="table table-hover">
              <thead className="bg-theme bg-opacity-60">
                <tr>
                  <th scope="col">طريقة الدفع</th>
                  {/* <th scope="col">{t('posName')}</th> */}
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {i18n.language === 'en' ? item.paymentMethodName : item.paymentMethodName2}
                    </td>
                    {/* <td>{i18n.language === 'en' ? item.posName : item.posName2}</td> */}
                    <td>
                      <Link to="" style={{ color: 'red' }} onClick={() => handleDelete(item.id)}>
                        <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="text-center bg-inverse bg-opacity-10 rounded-2 p-3">
          لم تتم إضافة طرق دفع لنقطة البيع هذه
        </div>
      )}
    </Loading>
  );
};

export default PosPaymentMethodList;
