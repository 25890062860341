import React from "react";
import { Dispatch, SetStateAction } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TagsInput } from "react-tag-input-component";
import { Step4Modals } from "./Step4Modals";

interface Props {
  Columns: any[];
  formData: Step4Modals;
  setFormData: Dispatch<SetStateAction<Step4Modals>>;
}

const MobileTab: React.FC<Props> = ({ Columns, formData, setFormData }) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="row">
        <div className="col-xl-6 m-auto">
          <div className="form-group mb-3">
            <label className="form-label">أرقام الهواتف</label>
            <TagsInput
              value={formData.mobiles}
              onChange={(newTags) =>
                setFormData({ ...formData, mobiles: newTags })
              }
            />
          </div>
        </div>

        <div className="col-xl-6 m-auto">
          <div className="form-group mb-3">
            <label className="form-label">رقم تلقائي</label>
            <Form.Select
              className="form-select"
                value={formData.mobileColumn}
                onChange={(e)=> setFormData({...formData , mobileColumn:e.target.value })}
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {Columns &&
                Columns.map((item, idx) => (
                  <option key={++idx} value={item.AliasName}>
                    {i18n.language === "en" ? item.name : item.name}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileTab;
