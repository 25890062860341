import React from "react";
import { Dispatch, SetStateAction } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import useGetUsers from "../../../../../hooks/NTX/useGetUsers";
import { Step4Modals } from "./Step4Modals";

interface Props {
  Columns: any[];
  formData: Step4Modals;
  setFormData: Dispatch<SetStateAction<Step4Modals>>;
}

const NotificationTab: React.FC<Props> = ({
  Columns,
  formData,
  setFormData,
}) => {
  const { optionUsers } = useGetUsers();
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="row">
        <div className="col-xl-6 m-auto">
          <div className="form-group mb-3">
            <label className="form-label">المستخدمين</label>
            <Select
              classNamePrefix="react-select"
              className="z-3"
              required
              isMulti
              options={optionUsers}
              // value={op}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  userIds: e?.map((option) => option.value),
                })
              }
              placeholder='يرجى إختيار المستخدمين'
            />
          </div>
        </div>

        <div className="col-xl-6 m-auto">
          <div className="form-group mb-3">
            <label className="form-label">مستخدم تلقائي</label>
            <Form.Select
              className="form-select"
              value={formData.userColumn}
              onChange={(e) =>
                setFormData({ ...formData, userColumn: e.target.value })
              }
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {Columns &&
                Columns.map((item, idx) => (
                  <option key={++idx} value={item.AliasName}>
                    {i18n.language === "ar" ? item.AliasName : item.AliasName}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationTab;
