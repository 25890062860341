import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';

import {
  SalesReturnsModels2,
 

} from './SalesReturnsModels';

export const addReturnsTransactions = createAsyncThunk(
  'SalesReturns/addReturnsTransactions',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('SalesReturns/AddReturnsTransactions', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);




export const updatReturnsTransactions = createAsyncThunk(
  'SalesReturns/updatReturnsTransactions',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('SalesReturns/UpdatReturnsTransactions', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);



// export const getTransactionsInvoiceList = createAsyncThunk(
//   'SalesInvoices/getTransactionsInvoiceList',
//   async (_, { rejectWithValue }) => {
//     try {
//       const { data } = await ntxAPI.get('SalesInvoice/GetTransactionsInvoiceList');
//       if (data?.succeeded) {
//         //===
//         return data.data;
//       } else {
//         return rejectWithValue(data.message);
//       }
//     } catch (error: any) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

export const getTransactionsReturnsById = createAsyncThunk(
  'SalesReturns/getTransactionsReturnsById',
  async (InvoiceId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`SalesReturns/GetTransactionsReturnsById?Id=${InvoiceId}`);
      if (data?.succeeded) {
        // ===
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  // SalesInvoicesList: SalesReturnsModels2[];
  SalesReturnsById: any;
  loading: boolean;
  error: any | null;
} = {
  // SalesInvoicesList: [],
  // SalesReturnsById: {} as SalesReturnsModels2,
  SalesReturnsById: {} ,
  loading: false,
  error: null,
};

const SalesReturnsSlice = createSlice({
  name: 'SalesReturns',
  initialState,
  reducers: {
    cleanUpTransactionsReturnsById : (state) => {
      state.SalesReturnsById = {} as SalesReturnsModels2;
    },
  },
  extraReducers(builder) {
    builder

      // .addCase(getTransactionsInvoiceList.pending, (state, action) => {
      //   state.loading = true;
      // })
      // .addCase(
      //   getTransactionsInvoiceList.fulfilled,
      //   (state, action: PayloadAction<SalesReturnsModels2[]>) => {
      //     state.loading = false;
      //     state.SalesInvoicesList = action.payload;
      //   }
      // )
      // .addCase(getTransactionsInvoiceList.rejected, (state, action: PayloadAction<any>) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })




      .addCase(getTransactionsReturnsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getTransactionsReturnsById.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.SalesReturnsById = action.payload;
        }
      )
      .addCase(getTransactionsReturnsById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });


  },
});



export const { cleanUpTransactionsReturnsById } = SalesReturnsSlice.actions;
export default SalesReturnsSlice.reducer;
