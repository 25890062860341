import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

import { GetDashboardsList } from '../../../store/NTX/Reports/Dashboard/dashboardSlice';
import DashboardByModule from '../../NTX/DashboardManagament/DashboardListByModule/DashboardByModule';

//________________ Start Components
const SalesDashboards: React.FC = () => {
  const dispatch = useAppDispatch();

  const { DashBoardList, loading } = useAppSelector((s) => s.DashBoard);

  useEffect(() => {
    dispatch(GetDashboardsList());
  }, [dispatch]);

  const dashboardListByModule = DashBoardList?.filter(
    (f) => f?.moduleId?.toLowerCase() === "0a6c6b89-1d52-4eaa-8d2c-080c74b1f6e0"
    // (f) => f?.moduleId === null
  );

  return (
    <div>
      <DashboardByModule dashboardList={dashboardListByModule} />
    </div>
  );
};

export default SalesDashboards;
