import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
 
import { GetLookupTaxType } from "../../store/Lookup/lookupSlice";

const useLookupTaxType = ()=>{

    const dispatch = useDispatch();
    
    useEffect(()=>{
        dispatch(GetLookupTaxType())
      },[])

    const { TAX_TYPE } = useSelector((state) => state.Lookup); 

    return { TAX_TYPE } ;
};

export default useLookupTaxType ;