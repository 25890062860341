import React, { useState } from 'react';

import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { useParams } from 'react-router-dom';

import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import AddUpdateConsolidation from './AddUpdateConsolidation';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import useGetConsolidationSet from '../../../../hooks/Finance/ConsolidationSet/useGetConsolidationSet';
import Loading from '../../../../components/Shared/Loader/Loading';
import { consolidationSetModels } from '../../../../store/Finance/ConsolidationSet/consolidationSetModels';

const Index = () => {
  const [Show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [data, setData] = useState<consolidationSetModels>();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const { t, i18n } = useTranslation();

  const { type } = useParams();

  const { ConsolidationSetList, loading, error } = useGetConsolidationSet();

  const filteredSearch = ConsolidationSetList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.organizationName?.toLowerCase().includes(searchLowerCase) ||
        item.organizationName2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    .filter((f) => {
      if (type === 'Company') {
        return f.organizationType === '30';
      } else if (type === 'branch') {
        return f.organizationType === '150';
      } else if (type === 'CostCenter') {
        return f.organizationType === '150';
      }
    });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },

    {
      name: 'الربط بين الإدارات',
      link: null,
    },
  ];
  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="الربط بين الادارات" subtitle={type} />

        {/* <div className="ms-auto">
          <Link
            to=""
            className="btn btn-theme"
            // data-bs-toggle="modal"
            // data-bs-target="#modalAddUpdate"
            onClick={() => setShow(true)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('Add')}
          </Link>
        </div> */}
      </div>

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody className="p-3">
            <div className="row mb-2">
              <div className="col-lg-10">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">
                    {t('Search')}
                    {/* <i className="fa fa-search  text-theme ms-3"></i> */}
                  </span>
                  <div className="input-group">
                    <div className="flex-fill position-relative">
                      <div className="input-group border-0 ">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5 text-theme"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          // placeholder={t('Search')}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table text-nowrap">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">{t('الإدارة HR ')}</th>
                    <th scope="col">{t('الإدارة المالية ')}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>

                <tbody>
                  {currentData.map((item) => (
                    <tr key={item.organizationId}>
                      <td className="align-middle">
                        {i18n.language === 'en' ? item.organizationName : item.organizationName2}
                      </td>
                      <td className="align-middle">
                        {i18n.language === 'en'
                          ? item.chartOfAccountsDetailsName
                          : item.chartOfAccountsDetailsName2}
                      </td>

                      <td className="align-middle text-end">
                        <button
                          type="button"
                          onClick={() => {
                            setData(item);
                            setShow(true);
                            // setShow2(true);
                          }}
                          className="btn btn-outline-theme ms-1"
                          data-toggle="tooltip">
                          <i className="fa-lg bi bi-link-45deg me-1 "></i>
                          {t('ربط')}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </CardBody>
        </Loading>
      </Card>

      {/* <ModalComponent title={''} id="modalAddUpdate">
        {true && <AddUpdateConsolidation />}
      </ModalComponent> */}

      <NeatixModal setShow={setShow} show={Show} size="md">
        {data && <AddUpdateConsolidation data={data} />}
      </NeatixModal>
    </div>
  );
};

export default Index;
