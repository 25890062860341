import { useEffect, useState } from "react";
import { useDispatch , useSelector } from "react-redux";
 
import { getSex } from "../../store/Lookup/lookupSlice";

const useLookupSex = ()=>{
  
    const dispatch = useDispatch();
    
    useEffect(()=>{
        dispatch(getSex())
      },[])

    const { SEX , loading , error } = useSelector((state) => state.Lookup);

  

    return { SEX , loading , error } ;
};

export default useLookupSex ;