


import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { PeriodsModels } from "./PeriodsModels";



  export const AddGlPeriods = createAsyncThunk('Periods/AddGlPeriods',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Finance/AddGlPeriods" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });



  export const UpdatGlPeriods = createAsyncThunk('Periods/UpdatGlPeriods',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Finance/UpdatGlPeriods" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });





export const GetGlPeriodsList = createAsyncThunk(
    "Periods/GetGlPeriodsList",
    async (_, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get('Finance/GetGlPeriodsList');
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );
 

  
export const GetGlPeriodsById = createAsyncThunk(
    "Periods/GetGlPeriodsById",
    async (id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get(`Finance/GetGlPeriodsById?Id=${id}`);
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );
 


export const GetGlPeriodsByPeriodYear = createAsyncThunk(
    "Periods/GetGlPeriodsByPeriodYear",
    async (id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get(`Finance/GetGlPeriodsByPeriodYear?PeriodYear=${id}`);
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );
 




const initialState: {
    PeriodsList : PeriodsModels[]
    PeriodsById? : PeriodsModels
    loading: boolean;
    error: any | null;
  } = {
    PeriodsList: [],
    PeriodsById: {} as PeriodsModels,
    loading: false,
    error: null,

  };


  

const PeriodsSlice = createSlice({
    name: "Periods",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder

      .addCase(AddGlPeriods.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(AddGlPeriods.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.PeriodsList.push(action.payload.data); 
      })
      .addCase(AddGlPeriods.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


    .addCase(GetGlPeriodsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetGlPeriodsList.fulfilled, (state, action: PayloadAction<PeriodsModels[]> ) => {
        state.loading = false;
        state.PeriodsList = action.payload;
      })
      .addCase(GetGlPeriodsList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })




    .addCase(GetGlPeriodsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetGlPeriodsById.fulfilled, (state, action: PayloadAction<PeriodsModels> ) => {
        state.loading = false;
        state.PeriodsById = action.payload;
      })
      .addCase(GetGlPeriodsById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })



      
    },
  });
  
  export default PeriodsSlice.reducer;