import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../API/axiosNeatex";

const initialState = {
  TITLE: [],
  SEX: [],
  MAR_STATUS: [],
  NATIONALITY: [],
  ORG_TYPE: [],
  ORG_CLASS: [],
  Currency: [],
  Module: [],
  TableDataList: [],
  Banks: [],
  RELIGIONS: [],
  FleetCategory: [],
  FleetFuelType: [],
  FleetMotionVector: [],
  FleetRequestType: [],
  EngineStatues: [],
  PRIORITY: [],
  TAX_TYPE: [],
  UOM_CLASS: [],
  ITEM_TYPE: [],
  CUSTOMER_TYPE: [],
  INVENTORY_TRANSACTIONS: [],
  DISCOUNT_TYPE: [],
  REWARDS_TYPE: [],
  POS_TYPE: [],


  loading: false,
  NationalityLoading: false,
  error: null,
};

export const getTITLE = createAsyncThunk(
  "Lookup/getTITLE",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const cachedTitle = getState().Lookup.TITLE;
    if (cachedTitle?.length > 0) {
      return cachedTitle;
    }
    try {
      const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=TITLE");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getSex = createAsyncThunk("Lookup/getSex", async (_, thunkAPI) => {
  const { rejectWithValue, getState } = thunkAPI;
  const cachedSex = getState().Lookup.SEX;
  if (cachedSex?.length > 0) {
    return cachedSex;
  }

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=SEX");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getMarStatus = createAsyncThunk(
  "Lookup/getMarStatus",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const cacheddata = getState().Lookup.MAR_STATUS;
    if (cacheddata?.length > 0) {
      return cacheddata;
    }

    try {
      const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=MAR_STATUS");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getNationality = createAsyncThunk(
  "Lookup/getNationality",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const cachedData = getState().Lookup.NATIONALITY;
    if (cachedData?.length > 0) {
      return cachedData;
    }


    try {
      const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=NATIONALITY");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getOrgType = createAsyncThunk(
  "Lookup/getOrgType",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const cachedData = getState().Lookup.ORG_TYPE;
    if (cachedData?.length > 0) {
      return cachedData;
    }


    try {
      const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=ORG_TYPE");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getOrgClass = createAsyncThunk(
  "Lookup/getOrgClass",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=ORG_CLASS");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetCurrency = createAsyncThunk(
  "Lookup/GetCurrency",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const cachedData = getState().Lookup.Currency;
      if (cachedData?.length > 0) {
        return cachedData;
      }

    try {
      const {data} = await ntxAPI.get("NtxSetting/GetCurrencyList");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetModule = createAsyncThunk(
  "Lookup/GetModule",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const cachedData = getState().Lookup.Module;
      if (cachedData?.length > 0) {
        return cachedData;
      }
    try {
      const {data} = await ntxAPI.get("NtxSetting/GetModuleList");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
 
  }
);


export const GetTableData = createAsyncThunk(
  "Lookup/GetTableData",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
 
    try {
      // const data = await getData(`NtxSetting/GetDataByTabelId?TabelId=${id}`);
      const {data} = await ntxAPI.get(`NtxSetting/GetDataByTabelId?TabelId=${id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const getReligions = createAsyncThunk(
  "Lookup/getReligions",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const cacheddata = getState().Lookup.RELIGIONS;
    if (cacheddata?.length > 0) {
      return cacheddata;
    }

    try {
      // const data = await getData("Lookup/GetByTypes?LookupTypes=RELIGIONS" , thunkAPI)
      const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=RELIGIONS");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const getBanks = createAsyncThunk(
  "Lookup/getBanks",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const cacheddata = getState().Lookup.Banks;
    if (cacheddata?.length > 0) {
      return cacheddata;
    }

    try {
      const {data} = await ntxAPI.get("NtxSetting/GetBankList" );
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const GetFleetCategory = createAsyncThunk("Lookup/GetFleetCategory", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=FLEET_CATEGORY");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const GetFleetFuelType = createAsyncThunk("Lookup/GetFleetFuelType", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=FLEET_FUEL_TYPE");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const GetFleetMotionVector = createAsyncThunk("Lookup/GetFleetMotionVector", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=FLEET_MOTION_VECTOR");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


export const GetFleetRequestType = createAsyncThunk("Lookup/GetFleetRequestType", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=FLEET_REQUEST_TYPE");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


export const GetEngineStatues = createAsyncThunk("Lookup/GetEngineStatues", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=ENGINE_STATUS");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const GetLookupPRIORITY = createAsyncThunk("Lookup/GetLookupPRIORITY", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=PRIORITY");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});



export const GetLookupTaxType = createAsyncThunk("Lookup/GetLookupTaxType", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=TAX_TYPE");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


export const GetUom_Class = createAsyncThunk("Lookup/GetUom_Class", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=UOM_CLASS");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const GetItemType = createAsyncThunk("Lookup/GetItemType", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=ITEM_TYPE");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


export const GetCustomerType = createAsyncThunk("Lookup/GetCustomerType", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=CUSTOMER_TYPE");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


export const GetInventoryTransactions = createAsyncThunk("Lookup/GetInventoryTransactions", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=INVENTORY_TRANSACTIONS");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});



export const GetDISCOUNTTYPE = createAsyncThunk("Lookup/GetDISCOUNTTYPE", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=DISCOUNT_TYPE");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


export const GetREWARDS_TYPE = createAsyncThunk("Lookup/GetREWARDS_TYPE", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=REWARDS_TYPE");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


export const get_POS_TYPE = createAsyncThunk("Lookup/get_POS_TYPE", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=POS_TYPE");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});




const lookupSlice = createSlice({
  name: "Lookup",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      // getTITLE
      .addCase(getTITLE.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTITLE.fulfilled, (state, action) => {
        state.loading = false;
        state.TITLE = action.payload;
      })
      .addCase(getTITLE.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      // getReligions
      .addCase(getReligions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getReligions.fulfilled, (state, action) => {
        state.loading = false;
        state.RELIGIONS = action.payload;
      })
      .addCase(getReligions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // getSex
      .addCase(getSex.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSex.fulfilled, (state, action) => {
        state.loading = false;
        state.SEX = action.payload;
      })
      .addCase(getSex.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // getMAR_STATUS
      .addCase(getMarStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMarStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.MAR_STATUS = action.payload;
      })
      .addCase(getMarStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // getNATIONALITY
      .addCase(getNationality.pending, (state) => {
        state.NationalityLoading = true;
        state.error = null;
      })
      .addCase(getNationality.fulfilled, (state, action) => {
        state.NationalityLoading = false;
        state.NATIONALITY = action.payload;
      })
      .addCase(getNationality.rejected, (state, action) => {
        state.NationalityLoading = false;
        state.error = action.payload;
      })
  
      // getORG_TYPE
      .addCase(getOrgType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOrgType.fulfilled, (state, action) => {
        state.loading = false;
        state.ORG_TYPE = action.payload;
      })
      .addCase(getOrgType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // getORG_CLASS
      .addCase(getOrgClass.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOrgClass.fulfilled, (state, action) => {
        state.loading = false;
        state.ORG_CLASS = action.payload;
      })
      .addCase(getOrgClass.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // GetCurrency
      .addCase(GetCurrency.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetCurrency.fulfilled, (state, action) => {
        state.loading = false;
        state.Currency = action.payload;
      })
      .addCase(GetCurrency.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // getBanks
      .addCase(getBanks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBanks.fulfilled, (state, action) => {
        state.loading = false;
        state.Banks = action.payload;
      })
      .addCase(getBanks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // GetModule
      .addCase(GetModule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetModule.fulfilled, (state, action) => {
        state.loading = false;
        state.Module = action.payload;
      })
      .addCase(GetModule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // GetTableData 
      .addCase(GetTableData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetTableData.fulfilled, (state, action) => {
        state.loading = false;
        state.TableDataList = action.payload;
      })
      .addCase(GetTableData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // GetFleetCategory 
      .addCase(GetFleetCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetFleetCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.FleetCategory = action.payload;
      })
      .addCase(GetFleetCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // GetFleetFuelType 
      .addCase(GetFleetFuelType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetFleetFuelType.fulfilled, (state, action) => {
        state.loading = false;
        state.FleetFuelType = action.payload;
      })
      .addCase(GetFleetFuelType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // GetFleetMotionVector 
      .addCase(GetFleetMotionVector.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetFleetMotionVector.fulfilled, (state, action) => {
        state.loading = false;
        state.FleetMotionVector = action.payload;
      })
      .addCase(GetFleetMotionVector.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // GetFleetRequestType 
      .addCase(GetFleetRequestType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetFleetRequestType.fulfilled, (state, action) => {
        state.loading = false;
        state.FleetRequestType = action.payload;
      })
      .addCase(GetFleetRequestType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // GetEngineStatues 
      .addCase(GetEngineStatues.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetEngineStatues.fulfilled, (state, action) => {
        state.loading = false;
        state.EngineStatues = action.payload;
      })
      .addCase(GetEngineStatues.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // GetLookupPRIORITY 
      .addCase(GetLookupPRIORITY.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetLookupPRIORITY.fulfilled, (state, action) => {
        state.loading = false;
        state.PRIORITY = action.payload;
      })
      .addCase(GetLookupPRIORITY.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // GetLookupTaxType 
      .addCase(GetLookupTaxType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetLookupTaxType.fulfilled, (state, action) => {
        state.loading = false;
        state.TAX_TYPE = action.payload;
      })
      .addCase(GetLookupTaxType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // GetUom_Class 
      .addCase(GetUom_Class.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetUom_Class.fulfilled, (state, action) => {
        state.loading = false;
        state.UOM_CLASS = action.payload;
      })
      .addCase(GetUom_Class.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // GetItemType 
      .addCase(GetItemType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetItemType.fulfilled, (state, action) => {
        state.loading = false;
        state.ITEM_TYPE = action.payload;
      })
      .addCase(GetItemType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // GetInventoryTransactions 
      .addCase(GetInventoryTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetInventoryTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.INVENTORY_TRANSACTIONS = action.payload;
      })
      .addCase(GetInventoryTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // GetCustomerType 
      .addCase(GetCustomerType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetCustomerType.fulfilled, (state, action) => {
        state.loading = false;
        state.CUSTOMER_TYPE = action.payload;
      })
      .addCase(GetCustomerType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })


      // GetDISCOUNTTYPE 
      .addCase(GetDISCOUNTTYPE.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetDISCOUNTTYPE.fulfilled, (state, action) => {
        state.loading = false;
        state.DISCOUNT_TYPE = action.payload;
      })
      .addCase(GetDISCOUNTTYPE.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })


      //  GetREWARDS_TYPE
      .addCase(GetREWARDS_TYPE.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetREWARDS_TYPE.fulfilled, (state, action) => {
        state.loading = false;
        state.REWARDS_TYPE = action.payload;
      })
      .addCase(GetREWARDS_TYPE.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })


      //  GetREWARDS_TYPE
      .addCase(get_POS_TYPE.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(get_POS_TYPE.fulfilled, (state, action) => {
        state.loading = false;
        state.POS_TYPE = action.payload;
      })
      .addCase(get_POS_TYPE.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },

});

export default lookupSlice.reducer;


  