import React from "react";
import HrDashBoard from "../HR/DashboardMain/HrDashBoard";
import SuDahboard from "../SU/DashBoard/SuDahboard.js";
import FLEETDahboard from "../FLEET/DashBoard/FLEETDahboard.js";
import Integration from "../Integration/Index.tsx";
import useGetSysName from "../../hooks/useGetSysName";
import AmeDahboard from "../Ame/DashBoard/AmeDahboard.js";
import NtxDashboard from "../NTX/Dashboard/NtxDashboard";
import SalesHome from "../Sales/SalesHome/SalesHome";
 

function Dashboard() {
 

  const  sysName = useGetSysName()

   let Component = null;

  switch (sysName) {
    case "HR":
      Component = <HrDashBoard />;
      break;
    case "SU":
      Component = <SuDahboard />;
      break;
    case "FLEET":
      Component = <FLEETDahboard />;
      break;
    case "NTX":
      Component =  <NtxDashboard/>;
      break;
    case "AME":
      Component =  <AmeDahboard/>  ;
      break;
    case "SAL":
      Component =  <SalesHome/>  ;
      break;
    case "Integration":
      Component =  <Integration />  ;
      break;
    default:
      <div> null </div>;
  }

  return <>{Component}</>;
}

export default Dashboard;
