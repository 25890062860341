import i18n from 'i18next';
import useLookupNationality from '../../../hooks/Lookups/use-Lookup-Nationality';
import useLookupMarStatus from '../../../hooks/Lookups/use-Lookup-MarStatus';
import useLookupTitle from '../../../hooks/Lookups/use-Lookup-Title';
import useGetRotationPlan from '../../../hooks/HR/use-get-RotationPlan';
import useGetBanks from '../../../hooks/Lookups/use-get-Banks';


const FetchPersonFields = () => {
  const { dataNATIONALITY, NationalityLoading } = useLookupNationality();
  const { OptionsMarStatus, loading: loading1 } = useLookupMarStatus();
  const { OptionsTitle, loading: loading2 } = useLookupTitle();
  const { RotationPlanOptions, loading: loading3 } = useGetRotationPlan();
  const { BanksOptions, loading: loading44 } = useGetBanks();


  const fieldsLoading =
  NationalityLoading ||
  loading1 ||
  loading2 ||
  loading3 ||
  loading44;


  const PersonFields = [
    // title
    {
      label: 'Title',
      key: 'title',
      fieldType: {
        type: 'select',
        options: OptionsTitle,
      },
      example: '',
      validations: [
        // {
        //   rule: 'required',
        //   errorMessage: 'CountryOfBirth is required',
        //   level: 'error',
        // },
      ],
    },

    // name
    {
      label: 'Global Name',
      key: 'name',
      fieldType: {
        type: 'input',
      },
      example: 'Mohammed Abdullah',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Name is required',
          level: 'error',
        },
      ],
    },

    // name2
    {
      label: 'Local Name',
      key: 'name2',
      fieldType: {
        type: 'input',
      },
      example: 'محمد عبدالله',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Name2 is required',
          level: 'error',
        },
      ],
    },

    //Employee Number
    {
      label: 'Employee Number',
      key: 'employeeNumber',
      fieldType: {
        type: 'input',
      },
      example: '1234',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Name2 is required',
          level: 'error',
        },
      ],
    },

    //Gender
    {
      label: 'Gender',
      key: 'gender',
      fieldType: {
        type: 'select',
        options: [
          {
            label: 'ذكر',
            value: 'M',
          },
          {
            label: 'انثى',
            value: 'F',
          },
        ],
      },
      example: 'ذكر - Male',
      validations: [
        {
          rule: 'required',
          errorMessage: 'gender is required',
          level: 'error',
        },
      ],
    },

    //National Identifier
    {
      label: 'National Identifier',
      key: 'nationalIdentifier',
      fieldType: {
        type: 'input',
      },
      example: '1234',
      validations: [
        {
          rule: 'regex',
          value: '^[0-9]+(\\.[0-9]+)?$',
          // flags?: string,
          errorMessage: 'national Identifier is required',
          level: 'error',
        },
      ],
    },

    //Start Date
    {
      label: 'Start Date',
      key: 'startDate',
      fieldType: {
        type: 'input',
      },
      example: '2023-11-09',
      validations: [
        {
          rule: 'required',
          value: '^d{4}-d{2}-d{2}$',
          errorMessage: 'startDate is required',
          level: 'error',
        },
      ],
    },

    //Date of Birth
    {
      label: 'Date of Birth',
      key: 'dateOfBirth',
      fieldType: {
        type: 'input',
      },
      example: '2023-11-09',
      validations: [
        {
          rule: 'required',
          errorMessage: 'dateOfBirth is required',
          level: 'error',
        },
        {
          rule: 'regex',
          value: '^(19|20)\\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$',
          errorMessage: 'The date must be in the format YYYY-MM-DD',
          level: 'error',
        },
      ],
    },

    //Marital Status
    {
      label: 'Marital Status',
      key: 'maritalStatus',
      fieldType: {
        type: 'select',
        options: OptionsMarStatus,
      },
      example: 'متزوج',
      validations: [
        {
          rule: 'required',
          errorMessage: 'maritalStatus is required',
          level: 'error',
        },
      ],
    },

    // Nationality
    {
      label: 'Nationality',
      key: 'nationality',
      fieldType: {
        type: 'select',
        options: dataNATIONALITY,
      },
      example: 'Saudia Arabia',
      validations: [
        {
          // rule: "required",
          errorMessage: 'Nationality is required',
          level: 'error',
        },
      ],
    },

    //Town Of Birth
    {
      label: 'Town Of Birth',
      key: 'townOfBirth',
      fieldType: {
        type: 'input',
      },
      example: 'الرياض',
      // validations: [
      //   {
      //     rule: 'required',
      //     errorMessage: 'townOfBirth is required',
      //     level: 'error',
      //   },
      // ],
    },

    //Country of Birth
    {
      label: 'Country of Birth',
      key: 'countryOfBirth',
      fieldType: {
        type: 'select',
        options: dataNATIONALITY,
      },
      example: 'Saudi Arabia',
      validations: [
        {
          rule: 'required',
          errorMessage: 'CountryOfBirth is required',
          level: 'error',
        },
      ],
    },

    //Religion
    {
      label: 'Religion',
      key: 'Religion',
      fieldType: {
        type: 'select',
        options: [
          {
            label: i18n.language === 'ar' ? 'مسلم' : 'Muslim',
            value: 'M',
          },
          {
            label: i18n.language === 'ar' ? 'مسيحي' : 'Christian',
            value: 'C',
          },
          {
            label: i18n.language === 'ar' ? 'غير ذلك' : 'otherwise',
            value: 'O',
          },
        ],
      },
      example: 'مسلم - Muslim',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Religion is required',
          level: 'error',
        },
      ],
    },

    //Annual Leave
    {
      label: 'Annual Leave',
      key: 'annualLeave',
      fieldType: {
        type: 'select',
        options: [
          {
            label: '30',
            value: '30',
          },
          {
            label: '21',
            value: '21',
          },
        ],
      },
      example: '30',
      validations: [
        {
          rule: 'required',
          errorMessage: 'annualLeave is required',
          level: 'error',
        },
      ],
    },

    //Rotation Plan
    {
      label: 'Rotation Plan',
      key: 'RotationPlanId',
      fieldType: {
        type: 'select',
        options: RotationPlanOptions,
      },
      example: '',
      // validations: [
      //   {
      //     rule: 'required',
      //     errorMessage: 'Rotation Plan is required',
      //     level: 'error',
      //   },
      // ],
    },

    // Bank Name
    {
      label: 'Bank Name',
      key: 'bankId',
      fieldType: {
        type: 'select',
        options: BanksOptions,
      },
      example: '',
      validations: [
        // {
        //   // rule: "required",
        //   errorMessage: "Bank Name is required",
        //   level: "error",
        // },
      ],
    },

    // iBan
    {
      label: 'iBan',
      key: 'iBan',
      fieldType: {
        type: 'input',
      },
      // example: "SA12340000000",
      validations: [
        // {
        //   rule: "required",
        //   errorMessage: "iBan is required",
        //   level: "error",
        // },
      ],
    },
  ];

  return { PersonFields ,  fieldsLoading};
};

export default FetchPersonFields;
