import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";

// import { getData, postData } from "../../Axios/axiosData";




export const GetElementEntryToAdd = createAsyncThunk("PayElementEntry/GetElementEntryToAdd", async (assignmentId, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    // const data = await getData(`Payroll/GetElementEntryToAdd?AssignmentId=${assignmentId}`);
    const {data} = await ntxAPI.get(`Payroll/GetElementEntryToAdd?AssignmentId=${assignmentId}` );
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
}
);



export const InsertPayElementEntry = createAsyncThunk("PayElementEntry/InsertPayElementEntry", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
 
    try {
      // const data = await postData( "Payroll/AddPayElementEntry", item);
      const {data} = await ntxAPI.post("Payroll/AddPayElementEntry" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
  );



const initialState = { ElementEntryToAddList: [], loading: false, error: null };

const PayElementEntrySlice = createSlice({
  name: "PayElementEntry",
  initialState,
  reducers: { 
    cleanElementEntryToAddList : (state) => {
      state.ElementEntryToAddList = []
    }
   },
   extraReducers(builder) {
    builder
      // get Pay Element Entry To ADD List
      .addCase(GetElementEntryToAdd.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetElementEntryToAdd.fulfilled, (state, action) => {
        state.loading = false;
        state.ElementEntryToAddList = action.payload;
      })
      .addCase(GetElementEntryToAdd.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      /////insert
      .addCase(InsertPayElementEntry.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(InsertPayElementEntry.fulfilled, (state, action) => {
        state.loading = false;
        state.ElementEntryToAddList = action.payload;
      })
      .addCase(InsertPayElementEntry.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {cleanElementEntryToAddList} = PayElementEntrySlice.actions
export default PayElementEntrySlice.reducer;
