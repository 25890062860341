import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './i18n';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/index';
import AppRoute from './config/app-route.jsx';
// import './scss/stylesAR.scss';
// import './scss/stylesEN.scss';



const App = () => {
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    const loadStyles = async () => {
      try {
        const language = localStorage.language ?? 'ar';
        await import(`./scss/styles${language === 'ar' ? 'AR' : 'EN'}.scss`);
        setIsLoaded(true);
      } catch (error) {
        console.error('Failed to load styles:', error);
        // Fallback to default styles or handle error appropriately
        await require('./scss/stylesAR.scss');
        setIsLoaded(true);
      }
    };

    loadStyles();
  }, []);

  if (!isLoaded) {
    return null; // or a loading spinner, etc.
  }

  return (
    <Provider store={store}>
      <RouterProvider router={AppRoute} />
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App/>);



reportWebVitals();

// root.render(<App/>);
// const App = () => {
//   const [isLoaded, setIsLoaded] = React.useState(false);

//   React.useEffect(() => {
//     const loadStyles = async () => {
//       try {
//         const language = localStorage.language ?? 'ar';
//         await import(`./scss/styles${language === 'ar' ? 'AR' : 'EN'}.scss`);
//         setIsLoaded(true);
//       } catch (error) {
//         console.error('Failed to load styles:', error);
//         // Fallback to default styles or handle error appropriately
//         await require('./scss/stylesAR.scss');
//         setIsLoaded(true);
//       }
//     };

//     loadStyles();
//   }, []);

//   if (!isLoaded) {
//     return null; // or a loading spinner, etc.
//   }

//   return (
//     <Provider store={store}>
//       <RouterProvider router={AppRoute} />
//     </Provider>
//   );
// };
