import React from 'react';
import { memo } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';
import { DeleteAmeCondition, GetAmeCondition } from '../../../store/Ame/ameSlice';
import { usePermissions } from '../../../Helper/Permisstions/usePermissions';
import showConfirmation from '../../../components/Shared/Alert/Confirmation';

const ConditionList = () => {
  const { HasPermission } = usePermissions();

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(GetAmeCondition(id));
  }, [dispatch, id]);
  const { AmeConditionList } = useSelector((state) => state.Ame);

  const handleDeleteAmeCondition = (item) => {
    showConfirmation(t('MsgConfirm'), () => {
      dispatch(DeleteAmeCondition(item.id))
        .unwrap()
        .then(() => {
          dispatch(GetAmeCondition(id));
          CustomAlert({ action: 'Delete' });
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: 'Error' });
        });
    });
  };

  return (
    <div className="mt-2">
      {AmeConditionList.length > 0 ? (
        <div className="table-responsive rounded-2 text-center ">
          <table className="table  table-hover text-nowrap mb-0">
            <thead className="bg-theme bg-opacity-60">
              <tr>
                {/* <th scope="col">الرقم</th> */}
                <th scope="col">{t('ConditionDescription')}</th>
                <th scope="col"></th>
              </tr>
            </thead>

            <tbody>
              {AmeConditionList &&
                AmeConditionList.map((item, idx) => (
                  <tr key={++idx}>
                    {/* <td className="align-middle">{++idx}</td> */}
                    <td className="align-middle">
                      {i18n.language === 'ar' ? item?.name : item?.name2}
                    </td>
                    <td className="align-middle">
                      {HasPermission('AmeDeleteConditionsOrder') && (
                        <>
                          <button
                            type="button"
                            className="btn btn-outline-danger m-0"
                            onClick={() => handleDeleteAmeCondition(item)}>
                            <i className="fas fa-trash-alt fa-fw fa-lg"></i>
                            {/* {t('Button.Delete')} */}
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center bg-inverse bg-opacity-10 rounded-4 p-3 fw-bold">
          {i18n.language === 'en' ? (
            <div>There are no conditions for this request</div>
          ) : (
            <div>لا يوجد شروط لهذا الطلب</div>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(ConditionList);
