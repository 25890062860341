import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";

// import { getData, postData } from "../../Axios/axiosData";




export const insertPayProposal = createAsyncThunk("PayProposal/insertPayProposal", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("Payroll/AddPayProposal" , item);
      const {data} = await ntxAPI.post("Payroll/AddPayProposal" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);


export const GetPayProposal = createAsyncThunk("PayProposal/GetPayProposal", async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const {data} = await ntxAPI.get(`Payroll/GetPayProposalListByAssignmentId?AssignmentId=${id}` );
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
}
);



const initialState = { PayProposalList: [], loading: false, error: null };
const PayProposalSlice = createSlice({
  name: "PayProposal",
  initialState,
  reducers: {
    cleanPayProposalList: (state) => {
      state.PayProposalList = [];
    },
  },
  extraReducers(builder) {
    // get PayProposal List
    builder
      .addCase(GetPayProposal.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetPayProposal.fulfilled, (state, action) => {
        state.loading = false;
        state.PayProposalList = action.payload;
      })
      .addCase(GetPayProposal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // insert PayProposal
      .addCase(insertPayProposal.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(insertPayProposal.fulfilled, (state, action) => {
        state.loading = false;
        state.PayProposalList.push(action.payload);
      })
      .addCase(insertPayProposal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const {cleanPayProposalList} = PayProposalSlice.actions;
export default PayProposalSlice.reducer;
