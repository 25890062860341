/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import { Card, CardBody } from '../../../../../components/card/card';
// import useGetUsers from '../../../../../hooks/NTX/useGetUsers';
import { MentionsInput, Mention } from 'react-mentions';
import MentionStyle from './MentionStyle';

// import Select from 'react-select';
// import { TagsInput } from 'react-tag-input-component';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import {
  addNotificationRequest,
  updateNotificationRequest,
  getNotificationRequstByFormId,
  cleanNotificationRequestById,
} from '../../../../../store/Ame/NotificationRequest/notificationRequestSlice';
import Loading from '../../../../../components/Shared/Loader/Loading';

type Props = {
  DataDynamicForm: any;
  setShow: any;
};

const RequestsNotification = ({ DataDynamicForm, setShow }: Props) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();
  // const { optionUsers, error } = useGetUsers();
  // @[NtxPersonName]
  const [suggestedList, setSuggestedList] = useState<{ id: string; display: string }[]>([
    {
      id: 'NtxPersonName2',
      display: 'صاحب الطلب عربي',
    },
    {
      id: 'NtxPersonName',
      display: 'صاحب الطلب انجليزي',
    },
  ]);

  const [formData, setFormData] = useState({
    name: DataDynamicForm.name || '',
    name2: DataDynamicForm.name2 || '',
    messageTemplate: '',
    icon: DataDynamicForm.icon || '',
    sms: false,
    whatsapp: false,
    email: false,
    Notification: false,
    emailSubject: '',
    emailTo: [] as any[],
    emailCC: [] as any[],
    userIds: [] as any[],
    mobiles: [] as any[],
    dynamicFormId: DataDynamicForm.id || '',
    moduleId: DataDynamicForm.moduleId || '',
  });

  //#region
  useEffect(() => {
    dispatch(getNotificationRequstByFormId(DataDynamicForm.id));
    return () => {
      dispatch(cleanNotificationRequestById());
    };
  }, [DataDynamicForm]);

  const {
    NotificationRequestById: data,
    loadingById,
    error: error2,
  } = useAppSelector((s) => s.NotificationRequest);

  useEffect(() => {
    if (data !== null) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        messageTemplate:
          data.messageTemplate
            ?.replace('@[NtxPersonName2]', '@[صاحب الطلب عربي]')
            ?.replace('@[NtxPersonName]', '@[صاحب الطلب انجليزي]') || '',
        sms: data.sendSMS,
        whatsapp: data.sendWhatsApp,
        email: data.sendEmail,
        Notification: data.pushNotification,
        emailSubject: data.emailSubject || '',
        emailTo: data.emailTo?.split(',')?.map((a) => a.replace(/"/g, '')),
        emailCC: data.emailCC?.split(',')?.map((a) => a.replace(/"/g, '')),
        userIds: data.userIds?.split(',')?.map((a) => a.replace(/"/g, '')),
        mobiles: data.mobiles?.split(',')?.map((a) => a.replace(/"/g, '')),
      }));
    }
  }, [data]);

  //#endregion

  const handleInputChange = async (e: any) => {
    const { name, type, checked } = e.target;

    if (type === 'checkbox') {
      setFormData({
        ...formData,
        [name]: checked,
      });
    }
  };

  const onSubmit = () => {
    setIsSubmitting(true);

    const messageTemplate = formData.messageTemplate
      .replace('@[صاحب الطلب عربي]', '@[NtxPersonName2]')
      .replace('@[صاحب الطلب انجليزي]', '@[NtxPersonName]');

    let request = {
      name: formData.name,
      name2: formData.name2,
      dynamicFormId: formData.dynamicFormId,
      moduleId: formData.moduleId,
      // messageTemplate: formData.messageTemplate,
      messageTemplate: messageTemplate,
      icon: formData.icon,
      sendEmail: formData.email,
      emailSubject: formData.emailSubject,
      emailTo: formData.emailTo?.map((m) => `"${m}"`).join(',') || '',
      emailCC: formData.emailCC?.map((m) => `"${m}"`).join(',') || '',
      pushNotification: formData.Notification,
      userIds: formData.userIds?.map((m) => `"${m}"`).join(',') || '',
      sendSMS: formData.sms,
      sendWhatsApp: formData.whatsapp,
      mobiles: formData.mobiles?.map((m) => `"${m}"`).join(',') || '',
    };

    const actionToDispatch =
      data === null
        ? addNotificationRequest(request)
        : updateNotificationRequest({ ...request, id: data.id });

    dispatch(actionToDispatch)
      .unwrap()
      .then(() => {
        setIsSubmitting(false);
        data === null ? CustomAlert({ action: 'Add' }) : CustomAlert({ action: 'Edit' });
      })
      .catch((error) => {
        setIsSubmitting(false);

        CustomAlert({ action: 'Error' });
      });
  };

  return (
    <>
      <Loading loading={loadingById} error={error2}>
        <div>
          <div className="form-group mb-1 p-2 ">
            <label className="form-label me-2">{t('MessageText')}</label>
            <small className="form-text text-muted mb-2">{t('HintMessageText')}</small>

            <MentionsInput
              className="text-center"
              style={MentionStyle}
              // placeholder="اكتب @ لظهور المتغيرات"
              value={formData.messageTemplate || ''}
              onChange={(e) => setFormData({ ...formData, messageTemplate: e.target.value })}>
              <Mention
                style={{
                  backgroundColor: 'var(--bs-theme)',
                }}
                data={suggestedList}
                trigger="@"
                //  markup="@[__display__]"
                // markup="@[__id__]"
                markup="@[__display__]"
                displayTransform={(id, display) => display}
                // markup="@[__id__]"
              />
            </MentionsInput>
          </div>

          <div className="p-2">
            <label className="form-label">{t('TypeNotification')}</label>
            <div className=" rounded-3 pt-2">
              <div className="d-flex flex-column justify-content-around flex-md-row flex-column align-items-center ">
                <div className=" ">
                  <div className="project">
                    <div className="boxes">
                      <div className="box">
                        <input
                          type="checkbox"
                          name="Notification"
                          id="Notification"
                          checked={formData.Notification}
                          onChange={handleInputChange}
                        />

                        <label
                          htmlFor="Notification"
                          style={{ display: 'inline-flex', alignItems: 'center' }}>
                          <i className="fa-solid bi bi-bell" style={{ marginInlineEnd: '8px' }}></i>
                          {t('SystemNotification')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="project">
                    <div className="boxes">
                      <div className="box">
                        <input
                          type="checkbox"
                          name="sms"
                          id="sms"
                          checked={formData.sms}
                          onChange={handleInputChange}
                        />

                        <label
                          htmlFor="sms"
                          style={{ display: 'inline-flex', alignItems: 'center' }}>
                          <i
                            className="fa-solid bi bi-chat-right-text"
                            style={{ marginInlineEnd: '8px' }}></i>
                         {t('smsMessage')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="project">
                    <div className="boxes">
                      <div className="box">
                        <input
                          type="checkbox"
                          name="whatsapp"
                          id="whatsapp"
                          checked={formData.whatsapp}
                          onChange={handleInputChange}
                        />

                        <label
                          htmlFor="whatsapp"
                          style={{ display: 'inline-flex', alignItems: 'center' }}>
                          <i
                            className="fa-solid bi bi-whatsapp "
                            style={{ marginInlineEnd: '8px' }}></i>
                         {t('WhatsAppMessage')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="project">
                    <div className="boxes">
                      <div className="box">
                        <input
                          type="checkbox"
                          name="email"
                          id="email"
                          checked={formData.email}
                          onChange={handleInputChange}
                        />

                        <label
                          htmlFor="email"
                          style={{ display: 'inline-flex', alignItems: 'center' }}>
                          <i
                            className="fa-solid bi bi-envelope-at"
                            style={{ marginInlineEnd: '8px' }}></i>
                          {t('Email')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mt-2">
            <button
              disabled={isSubmitting}
              type="submit"
              className="btn btn-lg me-1 btn-theme mb-1"
              onClick={() => onSubmit()}>
              {isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="fa fa-save fa-fw me-1"></i>
              )}
              {t('Save')}
            </button>

            <button
              type="button"
              className=" btn btn-lg  btn-default mb-1"
              onClick={() => setShow(false)}>
              <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
            </button>
          </div>
        </div>
      </Loading>
    </>
  );
};

export default RequestsNotification;

// <div className="row mt-2 p-2">
// {formData.Notification && (
//   <div className="col-6">
//     <label className="form-label">المستخدمين</label>
//     <div className="input-group flex-nowrap">
//       <span className="input-group-text" id="addon-wrapping">
//         <i className="bi bi-people text-theme"></i>
//       </span>
//       <Select
//         isDisabled={!formData.Notification}
//         classNamePrefix="react-select"
//         className=" w-100"
//         required
//         isMulti
//         options={optionUsers}
//         value={optionUsers.filter((option) =>
//           formData?.userIds?.includes(option.value)
//         )}
//         onChange={(e) =>
//           setFormData({
//             ...formData,
//             userIds: e?.map((option) => option.value),
//           })
//         }
//       />
//     </div>
//   </div>
// )}

// {(formData.sms || formData.whatsapp) && (
//   <div className="col-6">
//     <label className="form-label">أرقام الموبايل</label>
//     <div className="input-group flex-nowrap">
//       <span className="input-group-text">
//         <i className="bi bi-phone text-theme"></i>
//       </span>
//       <div className="w-100">
//         <TagsInput
//           // disabled={false}
//           disabled={!formData.sms && !formData.whatsapp}
//           value={formData.mobiles || ''}
//           onChange={(newTags) => setFormData({ ...formData, mobiles: newTags })}
//         />
//       </div>
//     </div>
//   </div>
// )}
// </div>

// {formData.email && (
// <div className="row mt-2 p-2">
//   <div className="col-xl-4 ">
//     <div className="form-group mb-3">
//       <label className="form-label">عنوان البريد الإلكتروني</label>
//       <input
//         className="form-control"
//         type="text"
//         defaultValue={formData.emailSubject || ''}
//         onChange={(e) => setFormData({ ...formData, emailSubject: e.target.value })}
//         // value={formData.emailSubject  || ''}
//       />
//     </div>
//   </div>

//   <div className="col-xl-4">
//     <label className="form-label">To</label>
//     <div className="input-group flex-nowrap">
//       <span className="input-group-text">@</span>
//       <div className="w-100">
//         <TagsInput
//           value={formData.emailTo}
//           onChange={(newTags) => setFormData({ ...formData, emailTo: newTags })}
//         />
//       </div>
//     </div>
//   </div>

//   <div className="col-xl-4">
//     <div className="form-group mb-3">
//       <label className="form-label">CC</label>
//       <TagsInput
//         value={formData.emailCC}
//         onChange={(newTags) => setFormData({ ...formData, emailCC: newTags })}
//       />
//     </div>
//   </div>
// </div>
// )}

// <div className="text-center mt-2">
// <button
//   disabled={isSubmitting}
//   type="submit"
//   className="btn btn-lg me-1 btn-theme mb-1"
//   onClick={() => onSubmit()}>
//   {isSubmitting ? (
//     <div className="spinner-border spinner-border-sm me-2"></div>
//   ) : (
//     <i className="fa fa-save fa-fw me-1"></i>
//   )}
//   {t('Save')}
// </button>

// {/* <button
// type="button"
// className=" btn me-1 btn-outline-default mb-1"
// // data-bs-dismiss="modal"
// >
// <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
// </button> */}
// </div>
