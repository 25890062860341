
import { useFormik } from "formik";
import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import variables from "../../../APIURL/variables";
import { Card, CardBody } from "../../../../components/card/card";
import { GetDynamicFormByID } from "../../../../store/NTX/FlexValue/Form/formSlice";
import { DynamicFormExtraInfoSchema } from "../../../ValidationForm/validationSchema";
import { UodateDynamicForm } from "../../../../store/NTX/FlexValue/Form/formSlice";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import Loader from "../../../../components/Shared/Loader/Loader.js";

import Select from "react-select";

import useGetExtraInformationTablesForm from "../../../../hooks/Forms/useGetExtraInformationTablesForm";
import useGetTablesForm from "../../../../hooks/Forms/use-get-tables-form";

const EditExtraInformationForm = () => {
  const { id } = useParams();
  const { t,  } = useTranslation();
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  // useEffect(() => {
  //   dispatch(GetTablesForm());
  // }, []);
  // const { TablesFormList } = useSelector((state) => state.FlexValueSet);

  const { dataExtraInformationTablesFormList } = useGetExtraInformationTablesForm()

  const { dataTablesFormList } = useGetTablesForm()







  useEffect(() => {
    dispatch(GetDynamicFormByID(id));
  }, []);
  const { DynamicFormListByID, loading } = useSelector((state) => state.Forms);

  const formik = useFormik({
    initialValues: {
      name2: DynamicFormListByID ? DynamicFormListByID.name2 : "",
      name: DynamicFormListByID ? DynamicFormListByID.name : "",
      icon: null,
      moduleId: DynamicFormListByID ? DynamicFormListByID.moduleId : "",
      dynamicFormTypesId: 2, // 2 Extra Information
      isStatic: false,
      tableId: DynamicFormListByID ? DynamicFormListByID.tableId : "",
    },
    enableReinitialize: true,
    validationSchema: DynamicFormExtraInfoSchema(t),
    onSubmit: (values, { resetForm }) => {
 
      dispatch(
        UodateDynamicForm({
          id: DynamicFormListByID?.id,
          name: values.name,
          name2: values.name2,
          icon: values.icon,
          dynamicFormTypesId : values.dynamicFormTypesId,
          isStatic: values.isStatic,
          tableId : values.tableId 
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          CustomAlert({ action: "Edit" });
          Navigate("/NTX/ExtraInformationForm", { replace: true });
          resetForm();
        })
        .catch((error) => {
          console.log(error);
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });



  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item">
              <a
                href="#/"
                onClick={() => Navigate("/FlexValue", { replace: true })}
              >
                {t("Forms")}
              </a>
            </li>
            <li className="breadcrumb-item active">{t("EditForm")}</li>
          </ul>
          <h1 className="page-header mb-0">{t("EditForm")}</h1>
        </div>
      </div>
      <Card>
        <Loader loading={loading} />
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t("LocalName")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name2"
                    onChange={formik.handleChange}
                    value={formik.values.name2 || ""}
                    isInvalid={!!formik.errors.name2}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t("GlobalName")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name || ""}
                    isInvalid={!!formik.errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-3"></div>

              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label"> {t('Tables')}</label>

                 <Select
                    classNamePrefix="react-select"
                    isRtl
                    // classNamePrefix="select"
                    isSearchable={true}
                    options={dataExtraInformationTablesFormList}
                    onChange={(option) =>
                      formik.setFieldValue("tableId", option.value)
                    }
                    // placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                    value={dataTablesFormList?.filter(f => f.value === DynamicFormListByID?.tableId)[0]}
                    isDisabled
                    />
                 

 

                </div>
              </div>

              <div className="col-xl-3"></div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn me-1 btn-theme mb-1">
                <i className="fa fa-save fa-fw me-1"></i> {t("Save")}
              </button>

              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                onClick={() => Navigate(-1, { replace: true })}
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default EditExtraInformationForm;
