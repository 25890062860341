import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getTransactionsReceiveList } from '../../../store/INV/Transactions/InvReceive/invReceiveSlice';

const useGetInvReceiveList = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (InvReceiveList?.length === 0) {
      dispatch(getTransactionsReceiveList());
    }
  }, []);
  const { InvReceiveList, loading , error } = useAppSelector((state) => state.InvReceive);

  return { InvReceiveList, loading , error };
};

export default useGetInvReceiveList;
