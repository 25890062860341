import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  EndOfServiceModel,
  PeriodOfServiceTypeListModel,
  PeriodOfServiceDateModel,
  PeriodOfServiceByPersonIdModel,
} from './endofserviceModel';
import { APIResponse } from '../../../Shared/shared';
import { ntxAPI } from '../../../../API/axiosNeatex';

export const addPeriodOfService = createAsyncThunk(
  'EndOfService/addPeriodOfService',
  async (item: EndOfServiceModel, { rejectWithValue }) => {
    let body = {
      addPerPeriodsOfServiceDto: { ...item, elementEntryId: null },
    };
    try {
      const { data } = await ntxAPI.post<APIResponse<EndOfServiceModel>>(
        'Persons/AddPeriodOfService',
        body
      );
      if (data.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const getPeriodOfServiceTypeList = createAsyncThunk(
  'EndOfService/GetPeriodOfServiceTypeList',
  async (personId: string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<PeriodOfServiceTypeListModel[]>>(
        `Persons/GetPeriodOfServiceTypeList?PersonId=${personId}`
      );
      if (data.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const getStardDate = createAsyncThunk(
  'EndOfService/getStardDate',
  async (personId: string, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.get(`Persons/GetPerson?guid=${personId}`);
      return data.data.startDate;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

//______________
interface PeriodOfServiceDateParams {
  personId?: string;
  endDate?: string;
}
export const getPeriodOfServiceDate = createAsyncThunk(
  'EndOfService/getPeriodOfServiceDate',
  async ({ personId, endDate }: PeriodOfServiceDateParams, { rejectWithValue }) => {
    // var url = 'Persons/GetPeriodOfServiceDate' ;
    // if(personId && endDate){
    //   url = `Persons/GetPeriodOfServiceDate?PersonId=${personId}&currentDate=${endDate}`
    // }else if (!personId && endDate){
    //   url = `Persons/GetPeriodOfServiceDate?currentDate=${endDate}`
    // }else if (personId && !endDate){
    //   url = `Persons/GetPeriodOfServiceDate?PersonId=${personId}`
    // }else if(!personId && !endDate) {
    //   url = 'Persons/GetPeriodOfServiceDate'
    // }
    let url = 'Persons/GetPeriodOfServiceDate';

    if (personId || endDate) {
      const params = new URLSearchParams();
      if (personId) params.append('PersonId', personId);
      if (endDate) params.append('currentDate', endDate);
      url = `${url}?${params.toString()}`;
    }

    try {
      const { data } = await ntxAPI.get<APIResponse<PeriodOfServiceDateModel>>(url);
      if (data.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const getPeriodOfServiceByPersonId = createAsyncThunk(
  'EndOfService/getPeriodOfServiceByPersonId',
  async (personId: string | null, { rejectWithValue }) => {
    const url = personId
      ? `Persons/GetPeriodOfServiceByPersonId?PersonId=${personId}`
      : 'Persons/GetPeriodOfServiceByPersonId';
    try {
      const { data } = await ntxAPI.get<APIResponse<PeriodOfServiceByPersonIdModel>>(url);

      if (data.succeeded) {
        return { ...data.data , isTerminated : true }; 
        // return data.data;
      } else if (!data.succeeded && data.httpStatusCode === 400 && data.data) {
        return { ...data.data , isTerminated : false }; 
        // return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const initialState = {
  PeriodOfServiceTypeList: [] as PeriodOfServiceTypeListModel[],
  PeriodOfServiceDate: {} as PeriodOfServiceDateModel,
  PeriodOfServiceByPersonId: {} as PeriodOfServiceByPersonIdModel,
  stardDate: '' as string, // تاريخ التوظيف
  loading: false,
  loading2: false,
  error: null as string | null,
};

const endofserviceSlice = createSlice({
  name: 'EndOfService',
  initialState,
  reducers: {
    cleanPeriodOfServiceByPersonId: (state) => {
      state.PeriodOfServiceByPersonId = {} as PeriodOfServiceByPersonIdModel;
    },
    cleanPeriodOfServiceTypeList: (state) => {
      state.PeriodOfServiceTypeList = [] ;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getPeriodOfServiceTypeList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getPeriodOfServiceTypeList.fulfilled,
        (state, action: PayloadAction<PeriodOfServiceTypeListModel[]>) => {
          state.loading = false;
          state.PeriodOfServiceTypeList = action.payload;
        }
      )
      .addCase(getPeriodOfServiceTypeList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getPeriodOfServiceDate.pending, (state) => {
        state.loading2 = true;
        state.error = null;
      })
      .addCase(
        getPeriodOfServiceDate.fulfilled,
        (state, action: PayloadAction<PeriodOfServiceDateModel>) => {
          state.loading2 = false;
          state.PeriodOfServiceDate = action.payload;
        }
      )
      .addCase(getPeriodOfServiceDate.rejected, (state, action: PayloadAction<any>) => {
        state.loading2 = false;
        state.error = action.payload;
      })

      .addCase(getStardDate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getStardDate.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.stardDate = action.payload;
      })
      .addCase(getStardDate.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getPeriodOfServiceByPersonId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getPeriodOfServiceByPersonId.fulfilled,
        (state, action: PayloadAction<PeriodOfServiceByPersonIdModel>) => {
          state.loading = false;
          state.PeriodOfServiceByPersonId = action.payload;
        }
      )
      .addCase(getPeriodOfServiceByPersonId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { cleanPeriodOfServiceByPersonId , cleanPeriodOfServiceTypeList } = endofserviceSlice.actions;

export default endofserviceSlice.reducer;
