import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  GetPriceListById,
  PriceListByIdCleanUp,
} from "../../store/INV/PriceList/PriceListSlice";

interface Props {
  id: string;
}

const useGetPriceListById = ({ id }: Props) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(GetPriceListById(id));

    return () => {
      dispatch(PriceListByIdCleanUp());
    };
  }, [dispatch, id]);
  const { PriceListById, loading } = useAppSelector((state) => state.PriceList);

  return { PriceListById, loading };
};

export default useGetPriceListById;
