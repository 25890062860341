import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
 import { GetGlDailyRatesList } from '../../store/Finance/DailyRates/DailyRatesSlice';

const useGetGlDailyRatesList = () => {

    const dispatch = useAppDispatch();
  
  
    useEffect(() => {
      if(DailyRatesList?.length ===0){
        dispatch(GetGlDailyRatesList());
      }
    }, []);
    const { DailyRatesList , loading , error} = useAppSelector(
      (state) => state.DailyRates
    );

   

  return { DailyRatesList, loading , error }
}

export default useGetGlDailyRatesList
