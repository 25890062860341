

const iconMapping = {
  "jpg": "far fa-lg fa-fw fa-image",
  "JPG": "far fa-lg fa-fw fa-image",
  "png": "far fa-lg fa-fw fa-image",
  "jpeg": "far fa-lg fa-fw fa-image",
  "pdf": "far fa-lg fa-fw fa-file-pdf",
  "txt": "fa fa-file-text-o",
  "doc": "fa fa-file-word-o",
  // Add more extensions and icons as needed
};


const ViewFile = ({ Attachment }) => {
  const extension = Attachment?.name?.split('.').pop().toLowerCase();
  const openFile = (file) => {
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
  };

  return (
    <div >
      <div className="list-group mb-1 mt-0 shadow">
        <div className="list-group-item d-flex align-items-center mt-1" >
          <div
            className="w-40px h-40px d-flex align-items-center justify-content-center bg-theme text-white rounded-2 ms-n1"
            onClick={() => openFile(Attachment)}
            style={{ cursor: "pointer" , minWidth: "40px", minHeight:"40px"  }}
          >
            <i className={iconMapping[extension]}></i>
          </div>
          <div className="flex-fill px-3">
            <div
              className="fw-bold"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "inherit",
              }}
            >
              {Attachment.name}
            </div>
            <div className="small text-inverse text-opacity-50">
              {`${(Attachment?.size / 1024).toFixed(2)} KB`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewFile;
