import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { SourceModels } from "./SourceModels";




export const AddGlJeSource = createAsyncThunk(
  "JeSource/AddGlJeSource",
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post("Finance/AddGlJeSource", item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdatGlJeSource = createAsyncThunk(
  "JeCategory/UpdatGlJeSource",
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post("Finance/UpdatGlJeSource", item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetGlJeSourceList = createAsyncThunk(
  "JeSource/GetGlJeSourceList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get("Finance/GetGlJeSourceList");
      if(data?.succeeded) {
        return data?.data;
      } else {
        return initialState.GlJeSourceList
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);




const initialState: {
  GlJeSourceList: SourceModels[];
  loading: boolean;
  error: any | null;
} = {
  GlJeSourceList: [],
  loading: false,
  error: null,
};

const CategorySlice = createSlice({
  name: "JeSource",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(AddGlJeSource.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        AddGlJeSource.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.GlJeSourceList.push(action.payload.data);
        }
      )
      .addCase(
        AddGlJeSource.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )

      .addCase(GetGlJeSourceList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetGlJeSourceList.fulfilled,
        (state, action: PayloadAction<SourceModels[]>) => {
          state.loading = false;
          state.GlJeSourceList = action.payload;
        }
      )
      .addCase(
        GetGlJeSourceList.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default CategorySlice.reducer;
