import React from 'react';
import { useTranslation } from 'react-i18next';
import FileList from '../../../Attachments/FileList';
import AntFile from '../../../Attachments/AntFile';
import { Link } from 'react-router-dom';
import { PaperClipOutlined } from '@ant-design/icons'; // استخدام أيقونة مشبك الورق كمثال

const BuilderValue = ({ data }) => {
  const { i18n, t } = useTranslation();
  // console.log(JSON.stringify(data));

  return (
    <>
      <div className="row">
        {data
          ?.slice()
          ?.sort((a, b) => a.sequenceNum - b.sequenceNum)
          ?.map((item, idx) => (
            <div className="col-md-12" key={idx}>
              <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-1">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="mb-0 text-center">
                    <span className="text-inverse text-opacity-50 fw-bold">
                      {i18n.language === 'en' ? item.lableText : item.lableText2} :{' '}
                    </span>
                    <span className="text-theme h6">
                      {item?.dataType === 'BOOLEAN' ? (
                        item.value === '1' ? (
                          t('Yes')
                        ) : (
                          t('No')
                        )
                      ) : item?.dataType === 'ATTACHED' ? (
                        <Link
                          to={item?.attachment[0]?.filePath}
                          target="_blank"
                          // rel="noopener noreferrer"
                          className="fw-bold ">
                          {item?.attachment[0]?.fileName}
                          <PaperClipOutlined className="mx-2 fs-5 fw-bold" />
                        </Link>
                      ) : (
                        item.value
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default BuilderValue;

//  {data
//     ?.slice()
//     ?.sort((a, b) => a.sequenceNum - b.sequenceNum)
//     ?.map((item, idx) => (
//       <div className="input-group flex-nowrap mb-2">
//         <span className="input-group-text  text-nowrap border-theme">
//           {i18n.language === 'en' ? item.lableText : item.lableText2}
//         </span>
//         <input
//           type="text"
//           className="form-control form-control"
//           defaultValue={item.value}
//           disabled
//         />
//       </div>
//     ))}

// <table className="table table-bordered">
// <tbody>
//   {data
//     ?.slice()
//     ?.sort((a, b) => a.sequenceNum - b.sequenceNum)
//     ?.map((item, idx) => (
//       <tr key={idx}>
//         <th className="table-active align-middle text-center ">
//           {i18n.language === "en" ? item.lableText : item.lableText2}
//         </th>
//         <td>
//           {item.dataType === "ATTACHED" ? (
//             <FileList Attachments={item.attachment} />
//           ) : (
//             item.value
//           )}
//         </td>
//       </tr>
//     ))}
// </tbody>
// </table>
