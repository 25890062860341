import { createAsyncThunk, createSlice , PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { MaterialTransactionsModels } from "./MaterialTransactionsModels";

export const AddMaterialTransactions = createAsyncThunk(
  "MaterialTransactions/AddMaterialTransactions",
  async (item: any, { rejectWithValue }) => {
    console.log(JSON.stringify(item));
    
    // try {
    //   const { data } = await ntxAPI.post("Inv/AddMaterialTransactions", item);
    //   return data;
    // } catch (error: any) {
    //   return rejectWithValue(error.message);
    // }
  }
);

export const GetMaterialTransactionsList = createAsyncThunk(
  "MaterialTransactions/GetMaterialTransactionsList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get("Inv/GetMaterialTransactionsList");
      if (data?.succeeded === true) {
        return data.data;
      } else {
        return rejectWithValue(data?.message || "هناك خطأ");
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  MaterialTransactionsList: MaterialTransactionsModels[];
  loading: boolean;
  error: any | null;
} = {
  MaterialTransactionsList: [],
  loading: false,
  error: null,
};

const MaterialTransactionsSlice = createSlice({
  name: "MaterialTransactions",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(GetMaterialTransactionsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetMaterialTransactionsList.fulfilled,
        (state, action: PayloadAction<MaterialTransactionsModels[]>) => {
          state.loading = false;
          state.MaterialTransactionsList = action.payload;
        }
      )
      .addCase(
        GetMaterialTransactionsList.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )

    
  },
});

 
export default MaterialTransactionsSlice.reducer;
