import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody } from '../../../../../components/card/card';
import Loader from '../../../../../components/Shared/Loader/Loader';
import Pagination from '../../../../../components/Shared/Pagination/Pagination';
import {
  ConfirmPayrollAction,
  DeletePayrollAction,
  GetPayrollActionList,
} from '../../../../../store/HR/Payroll/runPayrollSlice';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import { usePermissions } from '../../../../../Helper/Permisstions/usePermissions';
import showConfirmation from '../../../../../components/Shared/Alert/Confirmation';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import Loading from '../../../../../components/Shared/Loader/Loading';

const ActionList = () => {
  const { HasPermission } = usePermissions();
  const { payrollId } = useParams();
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const { PayrollActionList, loading, error } = useSelector((state) => state.runPayroll);

  useEffect(() => {
    dispatch(GetPayrollActionList(payrollId));
  }, []);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = PayrollActionList?.filter((item) =>
    item?.name?.toLowerCase().includes(search.toLowerCase())
  )?.slice(indexOfFirstData, indexOfLastData);

  const handlePagination = (pageNumber) => setCurrentPage(pageNumber);

  //_________
  const handleNavigate = (item) => {
   
    Navigate(`${item.id}/AssignmentActionList`, {
      state: { ReferenceName: item.name },
    });
  };

  //___________________
  // Handele Delete Payroll Action
  const handleDeletePayrollAction = (item) => {
    showConfirmation(t('MsgConfirm'), async () => {
      await dispatch(DeletePayrollAction(item.id))
        .unwrap()
        .then(() => {
          dispatch(GetPayrollActionList(payrollId));
          CustomAlert({ action: 'Delete' });
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: 'Error' });
        });
    });
  };

  //___________________
  // Handele Delete Payroll Action
  const handleConfirmPayrollAction = (item) => {
    showConfirmation('هل أنت متــأكد من إعتماد هذا الراتب ؟', async () => {
      await dispatch(ConfirmPayrollAction(item.id))
        .unwrap()
        .then(() => {
          dispatch(GetPayrollActionList(payrollId));
          CustomAlert({
            action: 'Add',
            msg: `تم إعتماد الراتب  لشهر ${item.name} بنجاح`,
          });
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: 'Error' });
        });
    });
  };

  //#region useLocation
  const location = useLocation();
  const [referenceName, setReferenceName] = useState(null); // State to hold ReferenceName
  useEffect(() => {
    if (location.state && location.state.ReferenceName) {
      const { ReferenceName } = location.state;
      if (ReferenceName) {
        setReferenceName(ReferenceName); // Update ReferenceName state
      }
    }
  }, []);
  //#endregion

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('RunPayrolls'),
      link: '/Pay/payrollsRun',
    },
    {
      name: t('ShowAllRuns'),
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName={t('RunPayrolls')}
        subtitle={referenceName}
      />

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="tab-content p-2">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-2">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={handleSearch}
                          placeholder={t('Search') + ' ...'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-5 col-md-6 text-end">
                  <button
                    onClick={() => Navigate(-1, { replace: true })}
                    type="button"
                    className=" btn me-1 btn-default ">
                    <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                  </button>
                </div>
              </div>

              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table table-hover text-nowrap">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t('Period-Month')}</th>
                      <th scope="col">{t('TypeRunPayroll')}</th>
                      <th scope="col">{t('StartOfPeriod')}</th>
                      <th scope="col">{t('EndOfPeriod')}</th>
                      <th scope="col">{t('Date')}</th>

                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {PayrollActionList &&
                      currentData.map((item) => (
                        <tr key={item.id}>
                          <td className="align-middle">{item.name}</td>
                          <td className="align-middle">{item.actionType}</td>
                          <td className="align-middle">{item.startDate}</td>
                          <td className="align-middle">{item.endDate}</td>
                          <td className="align-middle">{item.creationDate}</td>
                          <td className="align-middle text-end">
                            {item.ameStatus === 0 && (
                              <div className="btn-group px-2">
                                {HasPermission('PayRollBackPayrollRun') && (
                                  <>
                                    <button
                                      type="button"
                                      className="btn btn-danger m-0"
                                      onClick={() => handleDeletePayrollAction(item)}>
                                      <i className="fas fa-trash-alt fa-fw fa-lg"></i>
                                      {t('Button.Delete')}
                                    </button>
                                  </>
                                )}
                                {HasPermission('PayPayrollRunApproval') && (
                                  <>
                                    <button
                                      type="button"
                                      className="btn btn-theme  m-0"
                                      onClick={() => handleConfirmPayrollAction(item)}>
                                      <i className="fas fa-lg fa-fw me-2 fa-thumbs-up"></i>
                                      {t('Approve')}
                                    </button>
                                  </>
                                )}
                              </div>
                            )}
                            {HasPermission('PayPayrollRunList') && (
                              <button
                                type="button"
                                onClick={() => handleNavigate(item)}
                                className="btn btn-outline-theme">
                                <i className="fas fa-lg fa-fw me-2 fa-file-invoice-dollar"></i>
                                {t('SalaryStatement')}
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={PayrollActionList}
                // .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
                dataPerPage={dataPerPage}
                handlePagination={handlePagination}
                currentPage={currentPage}
              />
            </div>
          </CardBody>
        </Loading>
      </Card>
    </>
  );
};

export default ActionList;
