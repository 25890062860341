import React, { useEffect } from 'react';
import * as XLSX from 'xlsx';
import { ntxAPI } from '../../../API/axiosNeatex';
import { AxiosError } from 'axios';
import { ReactSpreadsheetImport, StepType } from 'react-spreadsheet-import';
import { theme as CustomTheme } from '../Shared/CustomTheme';

import { Translations } from '../Shared/Translations';
import ToastSwal from '../../../components/Shared/Alert/ToastSwal';
import FetchPersonFields from './FetchPersonFields';
import Swal from 'sweetalert2';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';
import { exportToExcel } from '../../../Helper/Helper';

type Props = {
  setModalPerson: React.Dispatch<React.SetStateAction<boolean>>;
};

const PersonIntegration: React.FC<Props> = (props) => {

  
  async function onSubmit(data: any) {
    let Data = data?.validData;

    const body = Data.map((item: any) => ({
      title: item.title,
      name: item.name,
      name2: item.name2,
      employeeNumber: item.employeeNumber,
      gender: item.gender,
      startDate: item.startDate,
      nationalIdentifier: item.nationalIdentifier,
      maritalStatus: item.maritalStatus,
      religion: item.Religion,
      dateOfBirth: item.dateOfBirth,
      nationality: item.nationality,
      townOfBirth: item.townOfBirth,
      regionOfBirth: '',
      countryOfBirth: item.countryOfBirth,
      annualLeave: item.annualLeave || 0,
      bankId: item.bankId,
      iBan: item.iBan,
      extraInformationId: null,
      persoN_IMG: '',
    }));

    try {
      const { data } = await ntxAPI.post('Integration/AddListPerson', body);
      const { faildList, succeededList } = data?.data;

      if (data.succeeded) {
        if (succeededList?.length) {
          exportToExcel(succeededList, 'Succeeded-List');
        }
        if (faildList?.length) {
          exportToExcel(faildList, 'faild-List');
        }

        await new Promise((resolve) => setTimeout(resolve, 1000));

        CustomAlert({
          action: 'info',
          htmlContent: `
          <div className="text-center">
          <p>
            Successfully added <span className="text-success h3">${succeededList.length}</span> items
          </p>
          <p>
            Failed to add <span className="text-danger h3">${faildList?.length}</span> items
          </p>
        
        </div>
        `,
          Timer: 1000000,
        });
      } else if (faildList.length > 0) {
        CustomAlert({ action: 'Error' });
      }
      // return data;`
    } catch (err) {
      if (err instanceof AxiosError) {
        const errorMessage =
          err.response?.data?.message || err.message || 'An unknown error occurred';
        CustomAlert({ action: 'Error', msg: errorMessage });
      } else {
        CustomAlert({ action: 'Error', msg: 'An unexpected error occurred' });
      }
    }
  }

  const { PersonFields, fieldsLoading: Loading } = FetchPersonFields();

  useEffect(() => {
    if (Loading) {
      ToastSwal({
        // title: 'جاري تحميل البيانات',
        icon: 'info',
        timer: 2000000,
        position: 'top',
        htmlContent: `
      <div class="text-center d-flex justify-content-between">
      <div class="fs-6 w-75">جاري تجهيز البيانات لبدء خطوات التكامل</div>
      <div class="spinner-border text-theme"></div>
      </div>
     `,
      });
    }

    if (!Loading) {
      Swal.close();
    }
  }, [Loading]);

  return (
    <div>
      {!Loading ? (
        <ReactSpreadsheetImport
          isOpen={true}
          onClose={() => props.setModalPerson(false)}
          onSubmit={onSubmit}
          fields={PersonFields}
          customTheme={CustomTheme}
          rtl={localStorage.language === 'ar'}
          translations={Translations}
        />
      ) : null}
    </div>
  );
};

export default PersonIntegration;
