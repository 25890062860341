import React from "react";
import AddAbsence from "../../HR/Employees/Absence/AddAbsence";
import WorkRequestForm from "../../FLEET/WorkRequests/WorkRequestForm";

const AddStaticForm = ({ StaticFormId, setReferenceId }) => {
  let Component = null;

  switch (StaticFormId) {
    case "19b2d5d2-8864-4e5d-b07e-4c1713d07a3c": // Absence Form ID from Seeding
      Component = <AddAbsence isAme={true} setReferenceId={setReferenceId} />;
      break;
    case "d9e918b3-b706-4281-a6e6-5101be1bcd63": // WorkRequest Form ID from Seeding
      Component = (
        <WorkRequestForm isAme={true} setReferenceId={setReferenceId} />
      );
      break;
    default:
      <div> .. Neatix ERP .. </div>;
  }

  return <div>{Component}</div>;
};

export default AddStaticForm;
