import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
// import useGetItemsList from '../../../../hooks/INV/MasterItems/useGetItemsList';
// import { useAppDispatch } from '../../../../store/hooks';
// import { GetItemConversionList } from '../../../../store/INV/MasterItems/ItemConversionSlice';
import Loading from '../../../../components/Shared/Loader/Loading';
import useGetUnitsOfMeasureList from '../../../../hooks/INV/useGetUnitsOfMeasureList';
import useGetItemConversionList from '../../../../hooks/INV/ItemConversion/useGetItemConversionList';
import useGetItemsListByInventoryId from '../../../../hooks/INV/MasterItems/useGetItemsListByInventoryId';
import { ntxAPI } from '../../../../API/axiosNeatex';
// import { Card } from '../../../../components/card/card';

interface LinesProps {
  formik: FormikProps<any>;
  // isUpdate?: boolean;
}

const InvIssueLines: React.FC<LinesProps> = ({ formik }) => {
  //#region Handle InvLines
  const { values, setFieldValue, errors } = formik;
  const { UnitsOfMeasureList: UomList } = useGetUnitsOfMeasureList();
  const { ItemConversionList, loading: loading2, error: error2 } = useGetItemConversionList();
  const { i18n, t } = useTranslation();
  const { ItemsOptions, loading, error } = useGetItemsListByInventoryId(values.inventoryId);
  const [Lines, setLines] = useState<any[]>(values.materialTransactionsLineList || []);

  const GetUom = (line: any) => {
    const uomIdForItem = ItemsOptions.find((f) => f.value === line?.itemId)?.uom;

    // تصفية العناصر من قائمة التحويلات بناءً على itemId
    const filteredList = ItemConversionList.filter((f) => f.itemId === line.itemId);

    // العثور على الوحدة المقابلة من قائمة UomList بناءً على uomId
    const filteredList2 = UomList.find((f) => f.id === uomIdForItem);

    // دمج القائمتين في قائمة ثالثة
    const combinedList = [...filteredList];

    // إذا كانت filteredList2 موجودة وغير موجودة في filteredList، يتم إضافتها إلى القائمة
    if (filteredList2 && !filteredList.some((f) => f.uom === filteredList2.id)) {
      combinedList.push({
        id: filteredList2.id,
        itemId: line.itemId,
        uom: filteredList2.id,
        conversionValue: 1, // أو أي قيمة افتراضية مناسبة للتحويل
        barcode: '',
        uomName: filteredList2.name,
        uomName2: filteredList2.name2,
      });
    }

    return combinedList;
  };

  const handleLineChange = (index: number, field: string, value: any) => {
    const updatedLines = [...Lines];
    if (field === 'itemId') {
      const price = ItemsOptions.find((f) => f.value === value)?.purchasePrice || 0;
      updatedLines[index] = {
        ...updatedLines[index],
        itemId: value,
        price: Number(price),
        total: Number(updatedLines[index].qty) * Number(price),
      };
    } else {
      updatedLines[index] = {
        ...updatedLines[index],
        [field]: value,
      };
    }

    setLines(updatedLines);
    formik.setFieldValue('materialTransactionsLineList', updatedLines);
  };

  const handleAddLine = () => {
    const newLine: any = {
      id: null, //
      itemId: null,
      lineNumber: Lines?.length + 1,
      uom: '',
      price: 0,
      qty: 1,
      // discountRate: 0,
      // discountAmount: 0,
      // vatRate: 15,
      // total: 0,
      // vatAmount: 0,
      // description: '',
    };
    setLines([...Lines, newLine]);
  };

  const handleDeleteLine = (index: number) => {
    const updatedLines = Lines.filter((_, i) => i !== index);
    setLines(updatedLines);
    formik?.setFieldValue('materialTransactionsLineList', updatedLines);
  };

  const calculateTotalSum = () => {
    let total = Lines?.reduce((sum, line) => sum + line.total, 0);
    const formattedTotal = new Intl.NumberFormat('en-US').format(total);
    return formattedTotal;
  };
  useEffect(() => {
    setLines(values.materialTransactionsLineList);
  }, [values.materialTransactionsLineList]);
  //#endregion

  console.log(Lines);

 

  //#region  جلب الأصناف بناء على الـ referenceId
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function GetData() {
      let url = '';

      switch (values.code) {
        case 'INV':
          url = `SalesInvoice/GetTransactionsInvoiceById?Id=${values.referenceId}`;
          break;
        case 'SOI':
          url = `SalesOrderSalse/GetTransactionsOrderSalseById?Id=${values.referenceId}`;
          break;
        case 'IOI':
          url = `InvMove/GetMoveTransactionsById?Id=${values.referenceId}`;
          break;
        // case 'SPR':
          // url = `InvMove/GetMoveTransactionsById?Id=${values.referenceId}`;
          // break;
        default:
          return;
      }

      try {
        setIsLoading(true);
        const { data } = await ntxAPI.get(url);

        if (data?.succeeded) {
          const customerId = data?.data?.peopleId ?? null;

          const ItemsList =
            values.code === 'IOI'
              ? data?.data?.materialTransactionsLineList
              : data?.data?.transactionsLinesList;

          const res = ItemsList?.map((m: any) => ({
            id: null,
            itemId: m.transactionItemId || m.itemId,
            uom: m.transactionUom || m.uom,
            qty: m.transactionQuantityIn || m.qty,
            price: values.code === 'IOI' ? 0 : m.price,
            total: values.code === 'IOI' ? 0 : m.total,
          }));

          setLines(res);

          if (values.code === 'IOI') {
            setFieldValue('branchId', data?.data?.branchId);
            // setFieldValue('branchTo', data?.data?.branchTo);
          }

          setFieldValue('customerId', customerId);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    }

    GetData();
  }, [values.referenceId]);

  useEffect(() => {
    setLines([]);
  }, [values.code]);
  //#endregion

  // console.log(formik.values);

  return (
    <>
      <Loading loading={loading || loading2 || isLoading} error={error || error2} Type="Dots">
        <div className="mb-3">
          <div className="row">
            <div className="col-3">
              <div className="mb-1 rounded-3 p-2 bg-inverse bg-opacity-10">
                <div className="d-flex justify-content-around">
                  <div className="fw-bold">{t('Total')} :</div>
                  <div className="text-theme fs-5 fw-bold">{calculateTotalSum()}</div>
                </div>
              </div>
            </div>

            <div className="col-lg-6"></div>
            <div className="col-lg-3 text-end ">
              <div className="text-end mb-2">
                <button type="button" className="btn btn-theme  " onClick={handleAddLine}>
                  {t('إضافة صنف')}
                </button>
              </div>
            </div>
          </div>

          {Lines?.length > 0 && (
            <div className="">
              <div
                className={`table-responsive rounded-2 text-center ${
                  formik.errors.materialTransactionsLineList ? 'border border-2 border-danger' : ''
                } `}>
                <table className="table table-borderless  table-hover mb-0 ">
                  <thead className="bg-theme bg-opacity-30">
                    <tr>
                      <th className="align-middle" style={{ width: '3%' }}>
                        #
                      </th>
                      <th className="align-middle" style={{ width: '62%' }}>
                        {t('اسم الصنف')}
                      </th>
                      <th className="align-middle" style={{ width: '16%' }}>
                        {t('الوحدة')}
                      </th>
                      <th className="align-middle" style={{ width: '8%' }}>
                        {t('الكمية')}
                      </th>
                      <th className="align-middle" style={{ width: '8%' }}>
                        {t('السعر')}
                      </th>

                      <th className="align-middle text-end" style={{ width: '3%' }}></th>
                    </tr>
                  </thead>
                  <tbody className="fw-bold">
                    {Lines.slice()
                      .reverse()
                      .map((line, index) => {
                        const Index = Lines?.length - 1 - index;

                        return (
                          <tr key={index}>
                            <td className="align-middle bg-theme bg-opacity-30 small">
                              {Lines?.length - index}
                            </td>
                            <td className="align-middle small">
                              <Select
                                classNamePrefix="react-select"
                                className="w-100"
                                isSearchable
                                isClearable
                                options={ItemsOptions.filter(
                                  (f) => !Lines.some((line) => line.itemId === f.value)
                                )}
                                onChange={(option) =>
                                  handleLineChange(
                                    Index,
                                    'itemId',
                                    option === null ? null : option.value
                                  )
                                }
                                value={
                                  line.itemId === null
                                    ? null
                                    : ItemsOptions.find((option) => option.value === line.itemId)
                                }
                                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                                }}
                                formatOptionLabel={(option) => (
                                  <div className="d-flex justify-content-between   ">
                                    <div className="">{option.label}</div>
                                    <div className="text-theme fw-bold">{option.itemCode}</div>
                                  </div>
                                )}
                              />
                            </td>
                            <td className="align-middle small">
                              <select
                                className="form-select text-center"
                                value={line.uom || ''}
                                onChange={(e) => handleLineChange(Index, 'uom', e.target.value)}>
                                <option value="">
                                  {i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                </option>
                                {GetUom(line)?.map((item, idx) => (
                                  <option key={++idx} value={item.uom}>
                                    {i18n.language === 'en' ? item.uomName : item.uomName2}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td className="align-middle small">
                              <input
                                type="text"
                                className="form-control text-center px-0"
                                value={line.qty}
                                onChange={(e) =>
                                  handleLineChange(Index, 'qty', Number(e.target.value))
                                }
                                onInput={(e) => {
                                  // إلغاء أي إدخال غير رقمي يدويًا
                                  const input = e.target as HTMLInputElement;
                                  input.value = input.value.replace(/[^0-9]/g, '');
                                }}
                              />
                            </td>

                            <td className="align-middle small">
                              <input
                                type="text"
                                className="form-control text-center px-0"
                                // size={line.price.toString().length || 1}
                                value={line.price}
                                onChange={(e) =>
                                  handleLineChange(Index, 'price', Number(e.target.value))
                                }
                                onInput={(e) => {
                                  // إلغاء أي إدخال غير رقمي يدويًا
                                  const input = e.target as HTMLInputElement;
                                  input.value = input.value.replace(/[^0-9]/g, '');
                                }}
                              />
                            </td>

                            <td className="align-middle small">
                              <button
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={() => handleDeleteLine(Index)}>
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </Loading>
    </>
  );
};

export default InvIssueLines;
