// import { useTranslation } from "react-i18next";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import useOrganizationDetails from "../../../../hooks/HR/use-organization-details";
// import EditFormDepartments from "./EditFormDepartments";
// import Breadcrumb from "../../../../components/Shared/Breadcrumb";

const EditDepartments = () => {
  // const Navigate = useNavigate();
  // const { t, i18n } = useTranslation();
 
  // const { Organization, loading } = useOrganizationDetails();


  //________________________________
  // var BcrumbList = [
  //   {
  //     name: "Home",
  //     link: "/",
  //   },
  //   {
  //     name: "Inventory",
  //     link: null,
  //   },
  // ];

  return (
    <>

{/* <Breadcrumb BreadcrumbList={BcrumbList} PageName={t("EditDepartment")} />

      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/" onClick={() => Navigate(-1, { replace: true })}>
                {t("Home")}
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#/" onClick={() => Navigate(-1, { replace: true })}>
                {t("Departments")}
              </a>
            </li>
            <li className="breadcrumb-item active">{t("Button.Edit")} </li>
          </ul>
          <h1 className="page-header mb-0">{t("EditDepartment")}</h1>
        </div>
      </div> */}

      {/* <EditFormDepartments Organization={Organization} loading={loading} /> */}
    </>
  );
};

export default EditDepartments;
