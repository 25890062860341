import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { GetGlJeCategoryList } from '../../store/Finance/JeCategory/categorySlice';

const useGetGlJeCategoryList = () => {


    const dispatch = useAppDispatch();
  

  
    useEffect(() => {
      if(GlJeCategoryList?.length ===0){
        dispatch(GetGlJeCategoryList());
      }
    }, []);
    const { GlJeCategoryList , loading , error} = useAppSelector(
      (state) => state.JeCategory
    );

   

  return { GlJeCategoryList, loading , error}
}

export default useGetGlJeCategoryList
