import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
 
import { GetDynamicForm } from "../../store/NTX/FlexValue/Form/formSlice";


const useGetDynamicForm = ()=>{

    const dispatch = useDispatch();
    const { DynamicFormList, loading , error } = useSelector((state) => state.Forms);
    useEffect(() => {
      if(DynamicFormList?.length === 0 || DynamicFormList === null){
        dispatch(GetDynamicForm());
      }
    }, [dispatch]);


    var DynamicFormAme = DynamicFormList?.filter(
      (f) => f.dynamicFormTypesId === 1
    );
    var DynamicExtraInformation = DynamicFormList?.filter(
      (f) => f.dynamicFormTypesId === 2
    );
    var DynamicExtraRecord = DynamicFormList?.filter(
      (f) => f.dynamicFormTypesId === 3
    );
    var DynamicExtraType = DynamicFormList?.filter(
      (f) => f.dynamicFormTypesId === 4
    );

    return { loading  ,error,DynamicExtraType, DynamicFormList , DynamicFormAme , DynamicExtraInformation ,DynamicExtraRecord} ;
};

export default useGetDynamicForm ;