import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { JournalsUnPostModels } from "./JournalsModels";

export const GetJournalsUnPostedList = createAsyncThunk(
  "PostingJournals/GetJournalsUnPostedList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get("Finance/GetGlJeHeadersUnPostedList");
      if (data?.succeeded === true) {
        return data.data;
      } else {
        return rejectWithValue(data?.message || "هناك خطأ");
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const postedGlJeHeaders = createAsyncThunk(
  "PostingJournals/postedGlJeHeaders",
  async ( Journal :  any , { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post("Finance/postedGlJeHeaders" , Journal);
      if (data?.succeeded) {
        return data;
      } else {
        return data;
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);




const initialState: {
  JournalsUnPostedList: JournalsUnPostModels[];
  loading: boolean;
  error: any | null;
} = {
  JournalsUnPostedList: [],
  loading: false,
  error: null,
};

const PostingJournalsSlice = createSlice({
  name: "PostingJournals",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetJournalsUnPostedList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetJournalsUnPostedList.fulfilled,
        (state, action: PayloadAction<JournalsUnPostModels[]>) => {
          state.loading = false;
          state.JournalsUnPostedList = action.payload;
        }
      )
      .addCase(
        GetJournalsUnPostedList.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default PostingJournalsSlice.reducer;
