import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { TransactionTypesModels } from "./TransactionTypesModels";

 

 ;

export const GetTransactionTypesList = createAsyncThunk(
  "TransactionTypes_Inv/GetTransactionTypesList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get("Inv/GetTransactionTypesList");
      if (data.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data?.message || "هناك خطأ");
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  TransactionTypesList: TransactionTypesModels[];
  loading: boolean;
  error: any | null;
} = {
  TransactionTypesList: [],
  loading: false,
  error: null,
};

const TransactionTypesSlice = createSlice({
  name: "TransactionTypes_Inv",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

 

      .addCase(GetTransactionTypesList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetTransactionTypesList.fulfilled,
        (state, action: PayloadAction<TransactionTypesModels[]>) => {
          state.loading = false;
          state.TransactionTypesList = action.payload;
        }
      )
      .addCase(
        GetTransactionTypesList.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default TransactionTypesSlice.reducer;
