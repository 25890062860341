import React, { useEffect, useState } from "react";
import { Card, CardBody } from "../../../components/card/card";
import Breadcrumb from "../../../components/Shared/Breadcrumb";



const AddWorkRequest = () => {



  //____________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "WorkRequests",
      link: "/FLEET/WorkRequests",
    },
    {
      name: "AddWorkRequests",
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="WorkRequests" />

      <Card>
        <CardBody>
          {/* <WorkRequestForm /> */}
          <hr />
          {/* <WorkRequestList setID={setID} /> */}
        </CardBody>
      </Card>

      {/*       
    <MedModalComponent title={t('UpdateEquipmentsOdometer')} id="UpdateEquipmentsOdometer">
        {
          ID && 
          <EditEquipmentsOdometer ID={ID}/>
        // <EditEquipmentsAssignment ID={ID} />
        }
      </MedModalComponent> */}
    </div>
  );
};

export default AddWorkRequest;
