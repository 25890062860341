import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddExtraRecordsForm from './AddExtraRecordsForm.js';
import Pagination from '../../../../components/Shared/Pagination/Pagination.jsx';
import useLookupModule from '../../../../hooks/Lookups/use-Lookup-Module';
import { Card } from '../../../../components/card/card';
import ModalComponent from '../../../../components/Modal/ModalComponent';
import { usePermissions } from '../../../../Helper/Permisstions/usePermissions.ts';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import useGetDynamicForm from '../../../../hooks/Forms/useGetDynamicForm';
// import Loader from '../../../../components/Shared/Loader/Loader.js';
import Loading from '../../../../components/Shared/Loader/Loading';

const ExtraRecordsForm = () => {
  const { HasPermission } = usePermissions();

  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const Navigate = useNavigate();

  const [selectedValue, setSelectedValue] = useState('');
  const { Module } = useLookupModule();
  //__________________________________________________

  const { DynamicExtraRecord, loading } = useGetDynamicForm();

  //_________________________________________________________________________________________________

  const filtered = DynamicExtraRecord?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.filter((f) => (selectedValue === '' ? true : f.moduleId === selectedValue));

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filtered?.slice(indexOfFirstData, indexOfLastData);

  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('ExtraRecordForm'),
      link: null,
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('ExtraRecordForm')} />
        <div className="ms-auto">
          {HasPermission('NtxAddExtraInformationRecordform') && (
            <Link
              to=""
              className="btn btn-theme"
              data-bs-toggle="modal"
              data-bs-target="#modalAddForm"
              // hidden={hiddenAddForm}
            >
              <i className="fa fa-plus-circle fa-fw me-1"></i>
              {t('AddForm')}
            </Link>
          )}
        </div>
      </div>

      <Card>
        <Loading loading={loading}>
          <div className="tab-content p-4">
            <div className="row mb-3">
              <div className="col-7 col-md-6 d-flex justify-content-start">
                <div className="input-group mb-2">
                  <div className="flex-fill position-relative">
                    <div className="input-group ">
                      <select
                        className="btn btn-outline-default dropdown-toggle p-0"
                        value={selectedValue}
                        onChange={(e) => setSelectedValue(e.target.value)}>
                        <option className="dropdown-item" value={''}>
                          {t('All')}
                        </option>
                        {Module &&
                          Module.map((item) => (
                            <option value={item.id} key={item.id} className="dropdown-item">
                              {i18n.language === 'ar' ? item.name2 : item.name}
                            </option>
                          ))}
                      </select>

                      <div className="flex-fill position-relative">
                        <div className="input-group">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}>
                            <i className="fa fa-search opacity-5"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            placeholder={t('Search')}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* //  START TABLE   */}
            <div className="table-responsive">
              <table className="table text-nowrap">
                <thead className="table-dark">
                  <tr>
                    <th scope="col" style={{ width: '20%' }}>
                      {t('Name')}
                    </th>
                    <th scope="col" style={{ width: '20%' }}>
                      {t('Table')}
                    </th>
                    <th scope="col" style={{ width: '20%' }}>
                      {t('Application')}
                    </th>
                    <th scope="col" style={{ width: '20%' }}>
                      {t('Icon')}
                    </th>

                    <th scope="col" style={{ width: '20%' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle">
                        {i18n.language === 'ar' ? item.name2 : item.name}
                      </td>
                      <td className="align-middle">
                        {i18n.language === 'ar' ? item.tableName2 : item.tableName}
                      </td>
                      <td className="align-middle">
                        {i18n.language === 'ar' ? item.moduleName2 : item.moduleName}
                      </td>
                      <td className="align-middle">
                        <div className="text-theme">
                          <i className={item.icon}></i>
                        </div>
                      </td>
                      <td className="text-end ">
                        <div className="btn-group">
                          {HasPermission('NtxAddingfieldstoExtraInformationRecordform') && (
                            <button
                              type="button"
                              onClick={() => Navigate(`/NTX/Forms/${item.id}/AddFormColumns`)}
                              className="btn btn-lg btn-outline-theme"
                              data-toggle="tooltip"
                              title={t('fieldsForm')}>
                              <i className="fab fa-lg fa-fw fa-elementor ms-1"></i>
                              {t('fieldsForm')}
                            </button>
                          )}

                          {HasPermission('NtxEditExtraInformationRecordform') && (
                            <button
                              type="button"
                              onClick={() => Navigate(`${item.id}/EditExtraRecordsForm`)}
                              className="btn btn-lg btn-outline-theme"
                              data-toggle="tooltip"
                              title={t('Button.Edit')}>
                              <i className="far fa-lg fa-fw fa-edit me-1"></i>
                              {t('Button.Edit')}
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              filteredData={filtered}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </div>
        </Loading>
      </Card>

      <ModalComponent title={t('AddExtraRecordForm')} id="modalAddForm">
        <AddExtraRecordsForm />
      </ModalComponent>
    </>
  );
};

export default ExtraRecordsForm;
