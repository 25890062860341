import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getApBankAccountsList } from '../../../store/Finance/AP/ApBankAccounts/ApBankAccountsSlice';

const useGetBankAccountsList = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ApBankAccountsList?.length === 0) {
      dispatch(getApBankAccountsList());
    }
  }, []);
  const { ApBankAccountsList, loading, error } = useAppSelector((state) => state.ApBankAccounts);

  return { ApBankAccountsList, loading, error };
};

export default useGetBankAccountsList;
