import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../API/axiosNeatex";




export const GetApprovalMemberByGroupId = createAsyncThunk("ApprovalMember/GetApprovalMemberByGroupId", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.get(`AmeApprovalGroup/GetApprovalGroupMemberListByGroupId?Id=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });



  export const insertApprovalMember = createAsyncThunk("ApprovalMember/insertApprovalMember", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    
    try {
      const {data} = await ntxAPI.post("AmeApprovalGroup/AddApprovalGroupMember" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });



  export const DeleteAprovalMember = createAsyncThunk("ApprovalMember/DeleteAprovalMember", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.post( `AmeApprovalGroup/DeleteApprovalGroupMember?Id=${id}`)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });
  



  const initialState = { ApprovalMemberList: [] ,  loading: false, error: null };

  const ApprovalMemberSlice = createSlice({
    name: "ApprovalMember",
    initialState,
    reducers: { },
    extraReducers(builder) {
      builder
        .addCase(GetApprovalMemberByGroupId.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(GetApprovalMemberByGroupId.fulfilled, (state, action) => {
          state.loading = false;
          state.ApprovalMemberList = action.payload;
        })
        .addCase(GetApprovalMemberByGroupId.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(insertApprovalMember.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(insertApprovalMember.fulfilled, (state, action) => {
          state.loading = false;
          state.ApprovalMemberList.push(action.payload);
        })
        .addCase(insertApprovalMember.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(DeleteAprovalMember.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(DeleteAprovalMember.fulfilled, (state, action) => {
          state.loading = false;
          state.ApprovalMemberList = state.ApprovalMemberList?.filter((el) => el.id !== action.payload);
        })
        .addCase(DeleteAprovalMember.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  
  export default ApprovalMemberSlice.reducer;