import React, { useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';

import useGetOrganizations from '../../../../hooks/HR/use-get-organizations';
import { Col, Row } from 'react-bootstrap';
import { Card, CardBody, CardHeader } from '../../../../components/card/card';
import { Link, useNavigate } from 'react-router-dom';
  
const ConsolidationSet = () => {
  const { OrganizationOptions } = useGetOrganizations();
 
  const Navigate = useNavigate();
  //________________________________________________________________
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(7);
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = OrganizationOptions?.slice(indexOfFirstData, indexOfLastData);
  //________________________________________________________________

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },

    {
      name: 'الربط بين الإدارات',
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="الربط بين الادارات" />
      <hr />

 

      <div className="row">
        <div className="col-xl-4 col-md-6">
          <div className="mailbox-detail-content">
            <div className="mailbox-attachment1 w-100">
              <Link onClick={() => Navigate(`Company`)} to="">
                <div className="document-file1 h-100px">
                  <i className={'fas fa-gift pt-2'}></i>
                </div>
                <div className="document-name">الشركات</div>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="mailbox-detail-content">
            <div className="mailbox-attachment1 w-100">
              <Link onClick={() => Navigate(`branch`)} to="">
                <div className="document-file1 h-100px">
                  <i className={'fas fa-gift pt-2'}></i>
                </div>
                <div className="document-name">الفروع</div>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="mailbox-detail-content">
            <div className="mailbox-attachment1 w-100">
              <Link onClick={() => Navigate(`CostCenter`)} to="">
                <div className="document-file1 h-100px">
                  <i className={'fas fa-gift pt-2'}></i>
                </div>
                <div className="document-name">مراكز التكلفة</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsolidationSet;
