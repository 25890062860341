import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getApInvoicesList } from '../../../store/Finance/AP/ApInvoices/ApInvoicesSlice';

const useGetApInvoicesList = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (ApInvoicesList?.length === 0) {
      dispatch(getApInvoicesList());
    }
  }, []);
  const { ApInvoicesList, loading, error } = useAppSelector((state) => state.ApInvoices);

  return { ApInvoicesList, loading, error };
};

export default useGetApInvoicesList;
