import React, { useEffect, useState } from 'react'
import MedModalComponent from '../../../../components/Modal/MedModalComponent'
import Pagination from '../../../../components/Shared/Pagination/Pagination'
import { Card, CardBody } from '../../../../components/card/card'
import Breadcrumb from '../../../../components/Shared/Breadcrumb'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { useNavigate } from 'react-router-dom'
import { GetNtxPeoplesList } from '../../../../store/NTX/Peoples/peoplesSlice'
import AddOwners from './AddSuppliers'
import AddSuppliers from './AddSuppliers'
import Loader from '../../../../components/Shared/Loader/Loader'

const Index = () => {

    const [Show, setShow] = useState(false);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage] = useState(10);
    const { t , i18n} = useTranslation()
    const dispatch = useAppDispatch();
    const Navigate = useNavigate();

    useEffect(()=>{
        // dispatch( GetWorkShopList())
        dispatch(GetNtxPeoplesList(""))
    },[])
    const { PeoplesList , loading } = useAppSelector(state => state.Peoples)

    const filteredSearch = PeoplesList?.slice()?.filter((emp) => {
        const searchLowerCase = search.toLowerCase();
        return (
          emp.name?.toLowerCase().includes(searchLowerCase) ||
          emp.name2?.toLowerCase().toString().includes(searchLowerCase)
        );
      })?.filter(f => f.typeCode === 'SUPPLIERS');
    
      const indexOfLastData = currentPage * dataPerPage;
      const indexOfFirstData = indexOfLastData - dataPerPage;
      const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);
//________________________________
    var BcrumbList = [
        {
          name: "Home",
          link: "/",
        },
        {
          name: "Suppliers",
          link: null,
        },
      ];


  return (
    <div>
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="Suppliers" />

        <Card>
        {/* <Loading loading={loading} error={error} SkeletonType="Table"> */}
        <Loader loading={loading}/>
       
        <CardBody>


                  <div className="row">
                    <div className="col-7 col-md-6 d-flex justify-content-start">
                      <div className="input-group mb-4">
                        <div className="flex-fill position-relative">
                          <div className="input-group">
                            <div
                              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                              style={{ zIndex: 1020, right: "10px" }}
                            >
                              <i className="fa fa-search opacity-5"></i>
                            </div>
                            <input
                              type="text"
                              className="form-control ps-35px"
                              onChange={(e) => setSearch(e.target.value)}
                              placeholder={t("Search") + " ..."}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-5 col-md-6 text-end">
                      <a
                        href="#/"
                        className="btn me-1 btn-outline-theme"
                        data-bs-toggle="modal"
                        data-bs-target="#modalِAddSuppliers"
                        onClick={() => setShow(true)}
                      >
                        <i className="fa fa-plus-circle fa-fw me-1"></i>
                        {t("AddSuppliers")}
                      </a>
                    </div>
                  </div>
                  {/* //  START TABLE   */}
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="table-dark">
                        <tr>
                          <th scope="col">
                            #
                          </th>
                          <th scope="col">
                            {t("Name")}
                          </th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentData.map((item, index) => (
                          <tr key={item.id}>
                            <td className="align-middle">{++index}</td>
                            <td className="align-middle">
                              {i18n.language === "en" ? item.name : item.name2}
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-outline-lime"
                                onClick={() => Navigate(`${item.id}/Edit`)}
                              >
                                <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                                {t("Button.Edit")}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    filteredData={filteredSearch}
                    dataPerPage={dataPerPage}
                    handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
                    currentPage={currentPage}
                  />
      
          
         
        </CardBody>
         {/* </Loading> */}
      </Card>

      <MedModalComponent title={t("AddSuppliers")} id="modalِAddSuppliers">
        {Show && <AddSuppliers/>}
      </MedModalComponent>
      
    </div>
  )
}

export default Index
