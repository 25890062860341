import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";

const initialState = { Locations: [], loading: false, error: null, Location: [] };

export const insertLocation = createAsyncThunk(
  "Locations/insertLocation",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
   
    try {
      // const data = await postData("Lookup/AddLocation" , item);
      const {data} = await ntxAPI.post("Lookup/AddLocation" , item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getLocations = createAsyncThunk("Locations/getLocations", async (_, thunkAPI) => {
  const { rejectWithValue , getState} = thunkAPI;

  const cachedData = getState().Locations.Locations;
  if (cachedData?.length > 0) {
    return cachedData;
  }


  try {
    // const data = await getData("Lookup/GetLocationList");
    const {data} = await ntxAPI.get("Lookup/GetLocationList");
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

export const editLocation = createAsyncThunk(
  "Locations/editLocation",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("Lookup/UpdateLocation" , item);
      const {data} = await ntxAPI.post("Lookup/UpdateLocation" , item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getLocation = createAsyncThunk("Locations/getLocation", async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    // const data = await getData(`Lookup/GetLocationById?Id=${id}`);
    const {data} = await ntxAPI.get(`Lookup/GetLocationById?Id=${id}`);
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});




const LocationSlice = createSlice({
  name: "Locations",
  initialState,
  reducers: { 
    cleanLocation: (state) => {
      state.Location = [];
    },
   },

   extraReducers(builder) {
    builder
      /////// get all jobs
      .addCase(getLocations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.loading = false;
        state.Locations = action.payload;
      })
      .addCase(getLocations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      ///////insert
      .addCase(insertLocation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(insertLocation.fulfilled, (state, action) => {
        state.loading = false;
        state.Locations.push(action.payload);
      })
      .addCase(insertLocation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      // get one location by id
      .addCase(getLocation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLocation.fulfilled, (state, action) => {
        state.loading = false;
        state.Location = action.payload;
      })
      .addCase(getLocation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
  
});
export const { cleanLocation } = LocationSlice.actions;

export default LocationSlice.reducer;
