import React from 'react';

import ContentLoader from 'react-content-loader';

const SkeletonLine = () => {
  return (
    <ContentLoader
      speed={2}
      // width={200}
      // height={30}
      viewBox="0 0 400 25"
      backgroundColor="#7170701c"
      // foregroundColor="#808080"
      foregroundColor="var(--bs-theme)">
      <rect x="0" y="0" rx="4" ry="4" width="400" height="25" />
    </ContentLoader>
  );
};

export default SkeletonLine;
