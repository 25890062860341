import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { Card, CardBody } from '../../../../components/card/card';
import { GetFlexValueSetList } from '../../../../store/NTX/FlexValue/flexValueSetSlice';
import {
  GetFormColumn,
  insertFormColumn,
} from '../../../../store/NTX/FlexValue/Form/FormColumnSlice';
import { AddFormColumnSchema } from '../../../ValidationForm/validationSchema';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import ModalComponent from '../../../../components/Modal/ModalComponent.jsx';
import EditFormColumn from './EditFormColumn.js';
import Loading from '../../../../components/Shared/Loader/Loading';
import MedModalComponent from '../../../../components/Modal/MedModalComponent';

const AddFormColumn = () => {
  const Navigate = useNavigate();
  const [ID, setID] = useState('');
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { FlexValueSetList, loading, error } = useSelector((state) => state.FlexValueSet);

  useEffect(() => {
    dispatch(GetFlexValueSetList());
  }, [dispatch]);

  const {
    FormColumnList,
    loading: loading2,
    error: error2,
  } = useSelector((state) => state.FormColumns);

  useEffect(() => {
    dispatch(GetFormColumn({ id, withStatic: false }));
  }, [dispatch, id]);

  const formik = useFormik({
    initialValues: {
      lable: '',
      lable2: '',
      sequenceNum: '',
      // enabledFlag: true,
      requiredFlag: false,
      flexValueSetId: '',
      tableId: '',
      lookupType: '',
      defaultValue: '',
      //______________________ not for post
      validationType: '',
      formatType: 'S',
      FlexValueSetId: '',
    },
    validationSchema: AddFormColumnSchema(t),
    onSubmit: (values, { resetForm }) => {
      dispatch(
        insertFormColumn({
          dynamicFormId: id,
          // columnName: "",   // تجاهل
          lable: values.lable,
          lable2: values.lable2,
          sequenceNum: values.sequenceNum,
          enabledFlag: true,
          requiredFlag: values.requiredFlag,
          flexValueSetId: values.flexValueSetId,
          tableId: values.tableId,
          lookupType: values.lookupType,
          defaultValue: values.defaultValue.toString(),
        })
      )
        .unwrap()
        .then((res) => {
          formik.resetForm();
          dispatch(GetFormColumn({ id, withStatic: false }));
          CustomAlert({ action: 'Add' });
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  function handleSelectChange(e) {
    const selectedOption = e.target.selectedOptions[0];

    const tableId = selectedOption.dataset.tableId;
    const validationType = selectedOption.dataset.validationType;
    const formatType = selectedOption.dataset.formatType;
    const FlexValueSetId = selectedOption.dataset.id;
    const lookupType = selectedOption.dataset.lookupType;

    formik.setFieldValue('flexValueSetId', e.target.value);
    formik.setFieldValue('tableId', tableId || null);
    formik.setFieldValue('lookupType', lookupType || null);
    formik.setFieldValue('validationType', validationType || null);
    formik.setFieldValue('formatType', formatType || null);

    formik.setFieldValue('defaultValue', '');
    formik.setFieldValue('FlexValueSetId', FlexValueSetId || null);
  }

  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('Forms'),
      link: '',
    },
    {
      name:
        i18n.language === 'ar' ? FormColumnList.dynamicFormName2 : FormColumnList.dynamicFormName,
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName="AddfieldsForm"
        subtitle={
          i18n.language === 'ar' ? FormColumnList.dynamicFormName2 : FormColumnList.dynamicFormName
        }
      />

      <Card>
        <Loading loading={loading || loading2} error={error || error2}>
          <CardBody>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-xl-5">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('LocalName')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="lable2"
                      onChange={formik.handleChange}
                      value={formik.values.lable2}
                      isInvalid={formik.touched.lable2 && formik.errors.lable2}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.lable2}
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-xl-5">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('GlobalName')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="lable"
                      onChange={formik.handleChange}
                      value={formik.values.lable}
                      isInvalid={formik.touched.lable && formik.errors.lable}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.lable}
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-xl-1">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Order')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="sequenceNum"
                      onChange={formik.handleChange}
                      value={formik.values.sequenceNum}
                      isInvalid={formik.touched.sequenceNum && formik.errors.sequenceNum}
                      onInput={(e) => {
                        const input = e.target;
                        input.value = input.value.replace(/[^0-9]/g, '');
                        
                      }}
                    />

                    <Form.Control.Feedback type="invalid">
                      {formik.errors.sequenceNum}
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-xl-1">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Required')}</label>
                    <Form.Check
                      style={{ margin: '10px 20px' }}
                      type="checkbox"
                      id="custom-switch"
                      name="requiredFlag"
                      onChange={formik.handleChange}
                      value={formik.values.requiredFlag}
                    />
                  </div>
                </div>
              </div>

              <div className="row"></div>

              <div className="row">
                <div className="col-xl-6">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('FieldType')}</label>
                    <Form.Select
                      className="form-select"
                      name="flexValueSetId"
                      onChange={handleSelectChange}
                      isInvalid={formik.touched.flexValueSetId && formik.errors.flexValueSetId}>
                      <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {FlexValueSetList &&
                        FlexValueSetList.map((item, idx) => (
                          <option
                            key={++idx}
                            value={item.id}
                            data-table-id={item.tableId}
                            data-validation-type={item.validationType}
                            data-format-type={item.formatType}
                            data-lookup-type={item.lookupType}
                            data-id={item.id}>
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.flexValueSetId}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('DefaultValue')}</label>

                    <Form.Control
                      className="form-control"
                      disabled={formik.values.formatType !== 'S'}
                      type={
                        formik.values.validationType === 'DATE'
                          ? 'date'
                          : formik.values.validationType === 'TIME'
                          ? 'time'
                          : formik.values.validationType === 'NUMBER'
                          ? 'number'
                          : formik.values.validationType === 'NOTE'
                          ? 'text'
                          : 'text'
                      }
                      name="defaultValue"
                      onChange={formik.handleChange}
                      value={formik.values.defaultValue}
                      isInvalid={!!formik.errors.defaultValue}
                    />
                  </div>
                </div>
              </div>

              <div className="text-center">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn me-1 btn-theme mb-1"
                  // hidden={hiddenAddFormColumn}
                >
                  <i className="fas fa-plus fa-fw me-1"></i>
                  {formik.isSubmitting ? '...' : t('Add')}
                </button>
                <button
                  type="button"
                  className=" btn me-1 btn-default mb-1"
                  onClick={() => Navigate(-1, { replace: true })}>
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </Form>

            <div className="mb-4" />
            {FormColumnList?.columnList?.length > 0 && (
              <div className="row text-center">
                <div className="col-lg-12 mx-auto p-0 m-0   rounded-1">
                  <div className="table-responsive rounded-1 text-center ">
                    <table className="table table-hover text-nowrap mb-0">
                      <thead className="bg-theme bg-opacity-60">
                        <tr>
                          <th scope="col">{t('Field')}</th>
                          <th scope="col">{t('Type')}</th>
                          <th scope="col">{t('Order')}</th>
                          <th scope="col"> {t('Active')}</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>

                      <tbody>
                        {FormColumnList &&
                          FormColumnList?.columnList
                            ?.slice()
                            ?.sort((a, b) => a.sequenceNum - b.sequenceNum)
                            ?.map((item, idx) => (
                              <tr key={++idx}>
                                <td className="align-middle">
                                  {i18n.language === 'ar' ? item.lable2 : item.lable}
                                </td>
                                <td className="align-middle">
                                  {(i18n.language === 'ar'
                                    ? item.validationType
                                    : item.validationType) || item.formatType}
                                </td>
                                <td className="align-middle">{item.sequenceNum}</td>
                                <td className="align-middle">
                                  {item.enabledFlag ? (
                                    <span className="badge bg-primary"> {t('isActive')}</span>
                                  ) : (
                                    <span className="badge bg-danger"> {t('InActive')}</span>
                                  )}
                                </td>

                                <td className="align-middle text-end">
                                  <button
                                    data-bs-toggle="modal"
                                    data-bs-target="#EditFormColumn"
                                    onClick={() => setID(item.id)}
                                    type="button"
                                    className="btn btn-outline-theme ms-1">
                                    <i className="far fa-lg fa-fw  fa-edit"></i>
                                    {t('Edit')}
                                  </button>
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </CardBody>
        </Loading>
      </Card>
      <MedModalComponent title={t('EditfieldsForm')} id="EditFormColumn">
        {ID && <EditFormColumn ID={ID} />}
      </MedModalComponent>
    </>
  );
};

export default AddFormColumn;
