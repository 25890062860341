import React from "react";
import ExtraInformationDynamicType from "../../NTX/FlexValue/ExtraInformationDynamicType/ExtraInformationDynamicType";

const SU_ExtraInformationDynamicType = () => {
  return (
    <div>
      <ExtraInformationDynamicType
        moduleId={"0a6c6b89-1d52-4eaa-8d2c-030c74b1f6e0"}
        TableId={"ffe70bb0-1f6a-475e-945f-94faa967f80e"}
        Name={"أنواع الوحدات العقارية"}
      />
    </div>
  );
};

export default SU_ExtraInformationDynamicType;
