import Swal from "sweetalert2";


interface CustomAlertProps {
  action: 'Edit' | 'Delete' | 'Error' | 'Add' | 'info'  ; //| any ;
  Title?: string;
  msg?: string | any;
  Timer?: number;
  htmlContent? : string | any;
}

export default function CustomAlert({ action, msg , Title , Timer ,htmlContent }: CustomAlertProps) {
  let title = "";
  let icon = "";
  let text = "";
 
  switch (action) {
    case "Edit":
      title = Title ?? "" ;
      text = msg ?? "تمت عملية التعديل بنجاح" //i18next.language === "ar" ? : "";
      icon = "success";
      break;
    case "Delete":
      title = "حذف";
      text = "تمت عملية الحــذف بنجاح"
      icon = "warning";
      break;
    case "Add":
      title = Title ?? "" ;
      icon = "success";
      text = msg ?? "تمت عملية الإضــافة بنجاح";
      break;
    case "Error":
      // title =  "فشلت العملية";
      title = Title ?? "فشلت العملية"  ;
      text = msg ?? "";
      icon = "warning";
      break;
    default:
      title = title ?? "";
      text = msg ?? "";
      icon = "info";
  }

  Swal.fire({
    icon: icon as any,
    title: title,
    text: text,
    timer: Timer? Timer: 1500,  
    background: "var(--bs-body-bg)",
    color:'var(--bs-heading-color)',
    showConfirmButton: false, // Removes the "OK" button
    // allowOutsideClick: false, // Prevents closing when clicking outside
    // allowEnterKey: false, // Allows
    // iconColor:"var(--bs-theme)"
    customClass: {
      container: "border",
      popup: "border border-theme border-3 rounded-5"
    },

    html: htmlContent, 


  });
}
