import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination.jsx';
import { getGrades } from '../../../../store/HR/HRSetting/jobgradeSlice.js';
import { Card, CardBody } from '../../../../components/card/card.jsx';
import ModalComponent from '../../../../components/Modal/ModalComponent.jsx';
import AddJobGrade from './AddJobGrade';

import { usePermissions } from '../../../../Helper/Permisstions/usePermissions.ts';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import Loading from '../../../../components/Shared/Loader/Loading';
import MedModalComponent from '../../../../components/Modal/MedModalComponent.jsx';

const JobGrades = () => {
  const { HasPermission } = usePermissions();

  const [Show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [search, setSearch] = useState('');
  // const [sortBy, setSortBy] = useState("");
  // const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const { Grades, loading, error } = useSelector((state) => state.Grades);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Grades?.length === 0) {
      dispatch(getGrades());
    }
  }, []);

  const filteredSearch = Grades?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //___________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('Jobgrades'),
      link: null,
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('Jobgrades')} />
        {HasPermission('AddHrPerGrade') && (
          <div className="ms-auto">
            <Link
              to=""
              className="btn me-1 btn-outline-theme "
              data-bs-toggle="modal"
              data-bs-target="#modalAddGrade"
              onClick={() => setShow(true)}>
              <i className="fa fa-plus-circle fa-fw me-1"></i>
              {t('AddNewJobDegree')}
            </Link>
          </div>
        )}
      </div>
      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="tab-content p-2">
              <div className="tab-pane fade show active" id="allTab">
                <div className="row">
                  <div className="col-7 col-md-6 d-flex justify-content-start">
                    <div className="input-group mb-2">
                      <div className="flex-fill position-relative">
                        <div className="input-group">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}>
                            <i className="fa fa-search opacity-5"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder={t('Search') + ' ...'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* //  START TABLE   */}
                <div className="table-responsive">
                  <table className="table ">
                    <thead className="table-dark">
                      <tr>
                        <th scope="col">{t('Name')}</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                
                      {currentData.map((item, index) => (
                        <tr key={item.id}>
                          <td className="align-middle">
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </td>
                          <td>
                            <div className="btn-group">
                              {HasPermission('ViewDetailsHrPerGrade') && (
                                <button
                                  type="button"
                                  className="btn btn-outline-theme"
                                  onClick={() => Navigate(`${item.id}/Edit`)}>
                                  <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                                  {t('ViewDetails')}
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    
                    </tbody>
                  </table>
                </div>
                <Pagination
                  filteredData={filteredSearch}
                  dataPerPage={dataPerPage}
                  handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
                  currentPage={currentPage}
                />
              </div>
            </div>
          </CardBody>
        </Loading>
      </Card>

      <MedModalComponent title={t('AddGrade')} id="modalAddGrade">
        {Show && <AddJobGrade />}
      </MedModalComponent>
    </>
  );
};

export default JobGrades;
