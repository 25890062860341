import React from "react";
import Chart from "react-apexcharts";
import { CardExpandToggler } from "../../../components/card/card";

function RadialBarChart({ data }) {
  var themeColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme")
    .trim();
  var themeFont = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-font-family")
    .trim();
  var gray300 = getComputedStyle(document.body)
    .getPropertyValue("--bs-gray-300")
    .trim();
  var indigo = getComputedStyle(document.body)
    .getPropertyValue("--bs-indigo")
    .trim();
  var cyan = getComputedStyle(document.body)
    .getPropertyValue("--bs-cyan")
    .trim();
  var inverseRgb = getComputedStyle(document.body)
    .getPropertyValue("--bs-inverse-rgb")
    .trim();

  // radial bar chart
  var radialBarChartOptions = {
    plotOptions: {
      radialBar: {
        offsetY: -10,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: "10%",
          background: "transparent",
          image: undefined,
        },
        track: { background: "rgba(" + inverseRgb + ", .25)" },
        dataLabels: { name: { show: false }, value: { show: false } },
      },
    },
    colors: [cyan, themeColor, indigo, gray300],
    labels: data?.data?.map((m) => m.label) || [],
    legend: {
      show: true,
      floating: true,
      // position: "s",
      offsetX: 250,
      offsetY: 15,
      labels: { useSeriesColors: true },
      markers: { size: 0 },
      formatter: function (seriesName, opts) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
      },
      itemMargin: { horizontal: 10 },
      fontFamily: themeFont,
    },
  };

  var radialBarChartData = data?.data?.map((m) => m.value) || [];

  return (
    <div>
      <div>
        <div className="d-flex fw-bold small p-3 bg-theme bg-opacity-20">
          <span className="flex-grow-1">{data?.title}</span>
          <CardExpandToggler />
        </div>

        <Chart
          type="radialBar"
          options={radialBarChartOptions}
          series={radialBarChartData}
          // height={400}
        />
      </div>
    </div>
  );
}

export default RadialBarChart;
