import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { PurchaseOrderModels } from './purchaseOrderModels';



export const addPurchaseOrder = createAsyncThunk(
  'PurchaseOrder/addPurchaseOrder',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('PurchaseOrder/Add', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updatePurchaseOrder= createAsyncThunk(
  'PurchaseOrder/updatePurchaseOrder',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('PurchaseOrder/Update', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPurchaseOrderList = createAsyncThunk(
  'PurchaseOrder/getPurchaseOrderList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('PurchaseOrder/GetList');
      if (data?.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPurchaseOrderById = createAsyncThunk(
  'PurchaseOrder/getPurchaseOrderById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`PurchaseOrder/GetById?Id=${id}`);
      if (data?.succeeded) {
        // ===
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  PurchaseOrderList: PurchaseOrderModels[];
  PurchaseOrderById: PurchaseOrderModels;
  loading: boolean;
  error: any | null;
} = {
  PurchaseOrderList: [],
  PurchaseOrderById: {} as PurchaseOrderModels,
  loading: false,
  error: null,
};

const PurchaseOrderSlice = createSlice({
  name: 'PurchaseOrder',
  initialState,
  reducers: {
    cleanUpPurchaseOrderById: (state) => {
      state.PurchaseOrderById = {} as PurchaseOrderModels;
    },
  },
  extraReducers(builder) {
    builder

      .addCase(getPurchaseOrderList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getPurchaseOrderList.fulfilled,
        (state, action: PayloadAction<PurchaseOrderModels[]>) => {
          state.loading = false;
          state.PurchaseOrderList = action.payload;
        }
      )
      .addCase(getPurchaseOrderList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })




      .addCase(getPurchaseOrderById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getPurchaseOrderById.fulfilled,
        (state, action: PayloadAction<PurchaseOrderModels>) => {
          state.loading = false;
          state.PurchaseOrderById = action.payload;
        }
      )
      .addCase(getPurchaseOrderById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { cleanUpPurchaseOrderById } = PurchaseOrderSlice.actions;
export default PurchaseOrderSlice.reducer;
