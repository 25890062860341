import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { GetCategoriesList } from '../../store/INV/Categories/CategoriesSlice';
import { useTranslation } from 'react-i18next';

const useGetCategoriesList = () => {

    const dispatch = useAppDispatch();
  
    const {i18n , t} = useTranslation();
    useEffect(() => {
      if(CategoriesList?.length ===0){
        dispatch(GetCategoriesList());
      }
    }, []);
    const { CategoriesList , loading , error} = useAppSelector(
      (state) => state.CategoriesINV
    );

    const CategoriesOptions = CategoriesList?.map((item) => ({
      value: item.id,
      label: `${i18n.language === "en" ? item.name : item.name2 }`,
    }));

    const CategoriesOptionsWithAll = [
      {
        label: t('جميع الفئــات'),
        value: 'All',
      },
      ...CategoriesOptions,
    ];
  


  return { CategoriesList, CategoriesOptionsWithAll , CategoriesOptions,  loading , error}
}

export default useGetCategoriesList
