import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination.jsx';
import { GetMyFingerPrintByDate } from '../../../../store/HR/Raqeeb/raqeebSlice.js';
import Loading from '../../../../components/Shared/Loader/Loading';

const MyFingerPrintList = () => {
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const [dateRange, setDateRange] = useState({ fromDate: '', toDate: '' });

  const { MyFingerPrintByDateList: data, loading, error } = useSelector((state) => state.Raqeeb);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      GetMyFingerPrintByDate({
        DateFrom: dateRange.fromDate,
        DateTo: dateRange.toDate,
      })
    );
  }, [dispatch]);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = data?.slice(indexOfFirstData, indexOfLastData);
  //________________________________________________

  const handleGetDataByDate = () => {
    dispatch(
      GetMyFingerPrintByDate({
        DateFrom: dateRange.fromDate,
        DateTo: dateRange.toDate,
      })
    );
    setCurrentPage(1);
  };

  //_____________  التبديل بين الألوان في الجدول حسب التاريخ ______
  const dateColors = {};
  const color1 = 'bg-opacity-20'; // Light blue
  const color2 = 'bg-opacity-10'; // Lighter blue
  let toggle = false;
  //_____________________________________________________________________

  return (
    <>
      <Loading loading={loading} error={error}>
        <div className="p-3">
          <div className="row ">
            <div className="col-sm-6 col-md-4 col-lg-5 ">
              <div className="input-group mb-3 ">
                <span className="input-group-text">{t('From')}</span>

                <input
                  type="date"
                  className="form-control text-center"
                  name="DateFrom"
                  value={dateRange.fromDate}
                  onChange={(e) => setDateRange({ ...dateRange, fromDate: e.target.value })}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-5 ">
              <div className="input-group mb-3">
                <span className="input-group-text">{t('To')}</span>
                <input
                  type="date"
                  className="form-control text-center"
                  name="DateTo"
                  value={dateRange.toDate}
                  onChange={(e) => setDateRange({ ...dateRange, toDate: e.target.value })}
                />
              </div>
            </div>

            <div className="col-sm-12 col-md-4 col-lg-2 ">
              <button
                type="button"
                className="btn btn-outline-theme w-100"
                onClick={handleGetDataByDate}>
                <i className="fas fa-lg fa-fw fa-filter"></i> {/* {t("UpdateData")} */}
              </button>
            </div>
          </div>

          <div className="table-responsive text-center rounded-2">
            <table className="table text-nowrap ">
              <thead className="bg-inverse bg-opacity-25  p-1 mb-1">
                <tr>
                  <th scope="col">{t('Date')}</th>
                  <th scope="col">{t('Time')}</th>
                </tr>
              </thead>

              <tbody>
                {currentData.map((item, idx) => {
                  const date = item?.transactiondate;

                  if (!dateColors[date]) {
                    dateColors[date] = toggle ? color1 : color2;
                    toggle = !toggle;
                  }

                  return (
                    <tr key={idx} className={`bg-inverse ${dateColors[date]}`}>
                      <td className="align-middle fw-bold ">{item.transactiondate}</td>

                      <td className="align-middle fw-bold ">{item.transactiontime}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination
            filteredData={data}
            dataPerPage={dataPerPage}
            handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
            currentPage={currentPage}
          />
        </div>{' '}
      </Loading>
    </>
  );
};

export default MyFingerPrintList;
