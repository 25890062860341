import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
// import ModalComponent from '../../../components/Modal/ModalComponent';
import AddWorkshop from './AddWorkshop';
import { useAppDispatch } from '../../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import MedModalComponent from '../../../../components/Modal/MedModalComponent';
import useGetOrganizations from '../../../../hooks/HR/use-get-organizations';
import Loader from '../../../../components/Shared/Loader/Loader';


const Index = () => {
    const [Show, setShow] = useState(false);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage] = useState(10);
    const { t , i18n} = useTranslation()
    const dispatch = useAppDispatch();
    const Navigate = useNavigate();

 

    const { Organizations , loading } = useGetOrganizations()

    const filteredSearch = Organizations?.slice()?.filter((item : any) => {
        const searchLowerCase = search.toLowerCase();
        return (
          item.name?.toLowerCase().includes(searchLowerCase) ||
          item.name2?.toLowerCase().toString().includes(searchLowerCase)
        );
      })?.filter((f:any) => f.type === "160");

      
    
      const indexOfLastData = currentPage * dataPerPage;
      const indexOfFirstData = indexOfLastData - dataPerPage;
      const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);
//________________________________
    var BcrumbList = [
        {
          name: "Home",
          link: "/",
        },
        {
          name: "Workshops",
          link: null,
        },
      ];
  return (
    <div>
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="Workshops" />

        <Card>
 

       <Loader loading={loading}/>
        <CardBody>

           
 
                  <div className="row">
                    <div className="col-7 col-md-6 d-flex justify-content-start">
                      <div className="input-group mb-4">
                        <div className="flex-fill position-relative">
                          <div className="input-group">
                            <div
                              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                              style={{ zIndex: 1020, right: "10px" }}
                            >
                              <i className="fa fa-search opacity-5"></i>
                            </div>
                            <input
                              type="text"
                              className="form-control ps-35px"
                              onChange={(e) => setSearch(e.target.value)}
                              placeholder={t("Search") + " ..."}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-5 col-md-6 text-end">
                      <a
                        href="#/"
                        className="btn me-1 btn-outline-theme"
                        data-bs-toggle="modal"
                        data-bs-target="#modalِAddWorkShop"
                        onClick={() => setShow(true)}
                      >
                        <i className="fa fa-plus-circle fa-fw me-1"></i>
                        {t("AddWorkshop")}
                      </a>
                    </div>
                  </div>
                  {/* //  START TABLE   */}
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="table-dark">
                      <tr>
                      <th scope="col" >
                        {t("Name")}
                      </th>

                      <th scope="col" >
                        {t("location")}
                      </th>
                      <th scope="col"></th>
                      <th></th>
                    </tr>
                      </thead>
                      <tbody>
                        {currentData.map((item : any, index : number) => (
                           <tr key={index}>
                           <td className="align-middle">
                             {i18n.language === "ar" ? item.name2 : item.name}
                           </td>
                           <td className="align-middle">
                             {i18n.language === "ar"
                               ? item.locationName2
                               : item.locationName}
                           </td>
                           <td className="align-middle">
                             <div className="btn-group">
                               <button
                                 type="button"
                                 className="btn btn-outline-lime mb-1 me-1"
                                 onClick={() => Navigate(`${item.id}/Edit`)} //Departments/:id/Edit
                                 // hidden={hiddenEdit}
                               >
                                 <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                                 {t("Button.Edit")}
                               </button>
                             </div>
                           </td>
                         </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    filteredData={filteredSearch}
                    dataPerPage={dataPerPage}
                    handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
                    currentPage={currentPage}
                  />
        
          
         
        </CardBody>
         {/* </Loading> */}
      </Card>

      <MedModalComponent title={t("AddWorkshop")} id="modalِAddWorkShop">
        {Show && <AddWorkshop/>}
      </MedModalComponent>
      
    </div>
  )
}

export default Index
