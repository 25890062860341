import React from 'react';
import { useTranslation } from 'react-i18next';
import useGetNtxProfile from '../../../hooks/NTX/useGetNtxProfile';

const MyInformations = () => {
  const { t, i18n } = useTranslation();
  const { UserProfile: Profile } = useGetNtxProfile();

  return (
    <>
      <div className=" row p-3">
        <div className="col-md-6">
          <div className="bg-inverse bg-opacity-10 rounded-4 p-2 mb-1">
            <div className="mb-1 text-inverse text-opacity-50 fw-bold">{t('EmployeeNumber')}</div>
            <h6 className="text-center">{Profile?.employeeNumber || '00'}</h6>
          </div>
        </div>

        <div className="col-md-6">
          <div className="bg-inverse bg-opacity-10 rounded-4 p-2 mb-1">
            <div className="mb-1 text-inverse text-opacity-50 fw-bold">{t('Department')}</div>
            <h6 className="text-center">
              {i18n.language === 'ar' ? Profile?.organizationName2 : Profile?.organizationName2}
            </h6>
          </div>
        </div>

        <div className="col-md-6">
          <div className="bg-inverse bg-opacity-10 rounded-4 p-2 mb-1">
            <div className="mb-1 text-inverse text-opacity-50 fw-bold">{t('Job')}</div>
            <h6 className="text-center">
              {i18n.language === 'ar' ? Profile?.jobName2 : Profile?.jobName}
            </h6>
          </div>
        </div>

        <div className="col-md-6">
          <div className="bg-inverse bg-opacity-10 rounded-4 p-2 mb-1">
            <div className="mb-1 text-inverse text-opacity-50 fw-bold">{t('Position')}</div>
            <h6 className="text-center">
              {i18n.language === 'ar' ? Profile?.positionName2 : Profile?.positionName}
            </h6>
          </div>
        </div>
        <div className="col-md-6">
          <div className="bg-inverse bg-opacity-10 rounded-4 p-2 mb-2">
            <div className="mb-1 text-inverse text-opacity-50 fw-bold">{t('SuperVisor')}</div>
            <h6 className="text-center">
              {i18n.language === 'ar' ? Profile?.supervisorName2 : Profile?.supervisorName}
            </h6>
          </div>
        </div>
        <div className="col-md-6">
          <div className="bg-inverse bg-opacity-10 rounded-4 p-2 mb-2">
            <div className="mb-1 text-inverse text-opacity-50 fw-bold">{t('location')}</div>
            <h6 className="text-center">
              {i18n.language === 'ar' ? Profile?.locationName2 : Profile?.locationName}
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyInformations;
