import { useFormik } from "formik";
import React from "react";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { editGrade } from "../../../../store/HR/HRSetting/jobgradeSlice.js";
import { useTranslation } from "react-i18next";
import useGradeDetails from "../../../../hooks/HRSetting/use-grade-details.js";
import { formJob } from "../../../ValidationForm/validationSchema.js";
import { Card, CardBody } from "../../../../components/card/card.jsx";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import { getGroupEmp } from "../../../../store/HR/HRSetting/groupEmpSlice.js";
import { usePermissions } from "../../../../Helper/Permisstions/usePermissions";

const EditGroupEmployees = () => {
  const {HasPermission } = usePermissions()

  const { t } = useTranslation();
  const Navigate = useNavigate();

  const { Grade } = useGradeDetails();


  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name2: Grade ? Grade.name2 : "",
      name: Grade ? Grade.name : "",
      comments: Grade ? Grade.comments : "",
    },
    enableReinitialize: true,
    validationSchema: formJob(t),
    onSubmit: (values) => {
 
      if (window.confirm(`Do you really confirm Edit`)) {
        dispatch(
          editGrade({
            id: Grade.id,
            name2: values.name2,
            name: values.name,
            comments: values.comments,
          })
        )
          .unwrap()
          .then(() => {
            CustomAlert({ action: "Edit" });
            Navigate("/HR/HRsettings");
            dispatch(getGroupEmp());
          })
          .catch((error) => {
            console.log(error);
            CustomAlert({ action: "Error" });
          });
      }
    },
  });

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/"> {t("Home")}</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#/">{t("Staffgroup")}</a>
            </li>
            <li className="breadcrumb-item active"> {t("Button.Edit")}</li>
          </ul>
          <h1 className="page-header mb-0">{t("Edit StaffGroup")}</h1>
        </div>
      </div>

      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label
                    className="form-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    {t("LocalName")}
                  </label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name || ""}
                    isInvalid={!!formik.errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label
                    className="form-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    {t("GlobalName")}
                  </label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name2"
                    onChange={formik.handleChange}
                    value={formik.values.name2 || ""}
                    isInvalid={!!formik.errors.name2}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name2}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="form-group mb-3">
                  <label
                    className="form-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    {t("Note")}
                  </label>
                  <Form.Control
                    className="form-control"
                    as="textarea"
                    rows={3}
                    name="comments"
                    onChange={formik.handleChange}
                    value={formik.values.comments || ""}
                    isInvalid={!!formik.errors.comments}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.comments}
                  </Form.Control.Feedback>
                  <div></div>
                </div>
              </div>
            </div>

            {/* <div className="text-center"> */}
            {
             HasPermission("EditHrPerGroups") &&
     
            <button
              type="submit"
              className="btn me-1 btn-theme mb-1"
             
            >
              <i className="fa fa-save fa-fw me-1"></i> {t("Save")}
            </button>       }
            <button
              type="button"
              className=" btn me-1 btn-default mb-1"
              onClick={() => Navigate(-1, { replace: true })}
            >
              <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
            </button>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default EditGroupEmployees;
