import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GetMyRequest } from '../../../../store/Ame/ameRequestSlice.js';
import Pagination from '../../../../components/Shared/Pagination/Pagination.jsx';
import Loader from '../../../../components/Shared/Loader/Loader.js';
import NeatixModal from '../../../../components/Modal/NeatixModal.jsx';
import OnlyViewRequests from '../OnlyViewRequests.js';

const MyRequestsList = (props) => {
  const [show, setShow] = useState(false);
  const [transactionHistoryId, setTransactionHistoryId] = useState('');
  const { t, i18n } = useTranslation();
  // const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(8);
  const dispatch = useDispatch();
  const { MyRequestList, loading } = useSelector((state) => state.AmeRequest);

  useEffect(() => {
    dispatch(GetMyRequest());
  }, [dispatch]);

  const filteredSearch = MyRequestList?.filter((item) => {
    const searchLowerCase = props?.search?.toLowerCase();

    // If search is not provided, return the entire list
    if (!searchLowerCase) {
      return true;
    }

    return (
      item.personName2?.toLowerCase().includes(searchLowerCase) ||
      item.personName?.toLowerCase().includes(searchLowerCase) ||
      item.requestName?.toLowerCase().includes(searchLowerCase) ||
      item.requestName2?.toLowerCase().includes(searchLowerCase) ||
      item.dateCreated?.toLowerCase().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  return (
    <>
      <div className="table-responsive text-center rounded-2">
        <Loader loading={loading} />
        <table className="table text-nowrap table-hover">
          <thead className="bg-inverse bg-opacity-25  p-1 mb-1">
            <tr>
              <th scope="col" style={{ width: '20%' }}>
                {t('NumberOfRequest')}
              </th>
              <th scope="col" style={{ width: '20%' }}>
                {t('TypeOfRequest')}
              </th>
              <th scope="col" style={{ width: '20%' }}>
                {t('DateOfRequest')}
              </th>
              <th scope="col" style={{ width: '20%' }}>
                {t('Status')}
              </th>
              <th scope="col" style={{ width: '20%' }}></th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((item, index) => (
              <tr key={item.transectionHistoryId}>
                <td className="align-middle">{2}</td>
                <td className="align-middle">
                  {i18n.language === 'ar' ? item.requestName2 : item.requestName}{' '}
                </td>

                <td className="align-middle">{item.dateCreated}</td>

                <td className="align-middle">
                  {item.status === 'APPROVED' ? (
                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      <i className="fa fa-check-circle fs-18px fa-fw me-5px"></i>
                      {t('APPROVED')}
                    </span>
                  ) : item.status === 'IN PROGRESS' ? (
                    <span className="badge border border-primary text-primary px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      <i className="fa fa-spinner fs-18px fa-fw me-5px"></i>
                      {t('INPROGRESS')}
                    </span>
                  ) : item.status === 'REJECTED' ? (
                    <span className="badge border border-danger  text-danger  px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      <i className="fa fa-times-circle fs-18px fa-fw me-5px "></i>
                      {t('REJECTED')}
                    </span>
                  ) : (
                    ''
                  )}
                </td>

                <td className="align-middle">
                  <button
                    className="btn me-1 btn-outline-theme"
                    onClick={() => {
                      setTransactionHistoryId(item.transectionHistoryId);
                      setShow(true);
                    }}
                    >
                    <i className="fas fa-lg fa-fw fa-info-circle me-2"></i>
                    {t('ViewRequest')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Pagination
        filteredData={filteredSearch}
        dataPerPage={dataPerPage}
        handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
        currentPage={currentPage}
      />

      <NeatixModal
        show={show}
        setShow={setShow}
        size="xl"
        bodyClassName="p-0"
        showHeader={true}
        // title="عرض الطلبات"
        >
        {transactionHistoryId && <OnlyViewRequests TransactionHistoryId={transactionHistoryId} />}
      </NeatixModal>
    </>
  );
};

export default MyRequestsList;
