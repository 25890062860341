import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../API/axiosNeatex";



// GetAmeRuleList DynamicFormId
export const GetAmeRuleListByDynamicFormId = createAsyncThunk("AmeRule/GetAmeRuleListByDynamicFormId", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.get(`AmeSetting/GetAmeRuleListByDynamicFormId?DynamicFormId=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

  export const insertAmeRule = createAsyncThunk("AmeRule/insertAmeRule", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.post("AmeSetting/AddAmeRule" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });


  export const DeleteAmeRule = createAsyncThunk("AmeRule/DeleteAmeRule", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

 
    try {
      const {data} = await ntxAPI.post(`AmeSetting/DeleteAmeRule?Id=${id}`)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });









  const initialState = { AmeRuleListByDynamicFormId: [], loading: false, error: null };

  const AmeRuleSlice = createSlice({
    name: "AmeRule",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        // Get AME Rule List by Dynamic Form ID
        .addCase(GetAmeRuleListByDynamicFormId.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(GetAmeRuleListByDynamicFormId.fulfilled, (state, action) => {
          state.loading = false;
          state.AmeRuleListByDynamicFormId = action.payload;
        })
        .addCase(GetAmeRuleListByDynamicFormId.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
    
        // Insert AME Rule
        .addCase(insertAmeRule.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(insertAmeRule.fulfilled, (state, action) => {
          state.loading = false;
          state.AmeRuleListByDynamicFormId.push(action.payload);
        })
        .addCase(insertAmeRule.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
    
        // Delete AME Rule
        .addCase(DeleteAmeRule.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(DeleteAmeRule.fulfilled, (state, action) => {
          state.loading = false;
          state.AmeRuleListByDynamicFormId = state.AmeRuleListByDynamicFormId?.filter((el) => el.id !== action.payload);
        })
        .addCase(DeleteAmeRule.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    }
  });
  
  export default AmeRuleSlice.reducer;