import { useFormik } from "formik";
// import React, { useContext, useEffect } from "react";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import Select from "react-select";
import { Card } from "../../../../components/card/card";
import { CardBody, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
 
import useGetPersons from "../../../../hooks/HR/use-get-persons";
import useGetOrganizations from "../../../../hooks/HR/use-get-organizations";
 
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
 import { useAppDispatch } from "../../../../store/hooks";
import useGetPeoplesList from "../../../../hooks/NTX/useGetPeoplesList";
 import useLookupInventoryTransactions from "../../../../hooks/Lookups/use-Lookup-InventoryTransactions";
import useGetTransactionTypesList from "../../../../hooks/INV/useGetTransactionTypesList";
import TransactionsInvLines from "./TransactionsInvLines";
import { AddMaterialTransactions } from "../../../../store/INV/MaterialTransactions/MaterialTransactionsSlice";

const Add = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { dataEmployees, loading } = useGetPersons();
  const {
    BranchsOptions,
    InventoryOptions,
    loading: loading3,
  } = useGetOrganizations();
  const { OptionCustomers, loading: loading2 } = useGetPeoplesList({LookupType : ""});

  // const { OptionsTransactionTypesIN } = useGetTransactionTypesList();

  const { INVENTORY_TRANSACTIONS } = useLookupInventoryTransactions();

  //#region Formik
  const formik = useFormik({
    initialValues: {
      code: "",
      transactionDate: "",
      description: "",
      branchId: "",
      transactionTypeId: "",
      transactionTypeName: "",
      transactionAmount: 0,
      referenceType: "",
      referenceId: "",
      referenceCode: "",
      referenceDate: "",
      supplierId: "",
      customerId: "",
      branchTo: "",
      personId: "",
      autoManual: true,
      keeperId: "",
      driverId: "",
      fleetEquipmentsId: "",
      inventoryId: "",
      inventoryTo: "",
      directTransfer: 0,
      address: "",
      note: "",
      materialTransactionsLineList: [] as any[],
    },
    enableReinitialize: true,
    // validationSchema: AddGlJeHeaderSchema(t),
    onSubmit: (values) => {
      dispatch(
        AddMaterialTransactions({
          code: values.code,
          transactionDate: values.transactionDate,
          description: values.description,
          branchId: values.branchId,
          transactionTypeId: values.transactionTypeId,
          transactionTypeName: values.transactionTypeName,
          transactionAmount: values.transactionAmount,
          referenceType: values.referenceType,
          referenceId: values.referenceId || null ,
          referenceCode: values.referenceCode,
          referenceDate: values.referenceDate,
          supplierId: values.supplierId || null,
          customerId: values.customerId || null,
          branchTo: values.branchTo || null,
          personId: values.personId || null,
          autoManual: true,
          keeperId: values.keeperId || null,
          driverId: values.driverId || null,
          fleetEquipmentsId: values.fleetEquipmentsId || null,
          inventoryId: values.inventoryId || null,
          inventoryTo: values.inventoryTo || null,
          directTransfer: values.directTransfer,
          address: values.address,
          note: values.note,
          materialTransactionsLineList: formik.values.materialTransactionsLineList.map(
            (item) => ({
              transactionItemId: item?.itemId,
              transactionUom: item.uom,
              unitPrice: Number(item.price) ,
              transactionQuantityIn: Number(item.qty) ,
              transactionQuantityOut: 0,
              inventoryId: values.inventoryId || null,
              inventoryTo: values.inventoryTo || null,
              discount: 0,
              codeCombinationId: null,
              currencyCode: "",
              currencyConversionRate: 0,
              referenceIdMaster: null,
              referenceIdLine: null,
              transactionTypeId: null,
              equivalent: 0,
              expensesAmount: 0,
              batchId: null,
            })
          ),
        })
      )
        .unwrap()
        .then((res: any) => {
          debugger;
          if (res?.succeeded === true) {
            CustomAlert({ action: "Add" });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched } = formik;
  //#endregion

  

  //____________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "اذن تسليم ",
      link: "/INV/Transactions/PermissionAdd",
    },
    {
      name: "اذن تسليـم جديد",
      link: null,
    },
  ];
  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="اذن تسليـم جديد" />

      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">


              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("رقم")}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control text-center"
                    type="text"
                    name="code"
                    onChange={formikhandleChange}
                    value={values.code || ""}
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("التاريـخ")}</label>
                  <Form.Control
                    className="form-control  text-center"
                    type="date"
                    name="transactionDate"
                    onChange={formikhandleChange}
                    value={values.transactionDate || ""}
                    isInvalid={
                      !!(touched.transactionDate && errors.transactionDate)
                    }
                    isValid={
                      formik.touched.transactionDate &&
                      !formik.errors.transactionDate
                    }
                  />
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("نوع الحركة")}</label>
                  <Select
                    classNamePrefix="react-select"
                    // isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    // options={OptionsTransactionTypesIN}
                    onChange={(option: any) => {
                      formik.setFieldValue(
                        "transactionTypeId",
                        option === null ? null : option.value
                      );
                      // formik.setFieldValue(
                      //   "transactionTypeName",
                      //   option === null ? null : option.value
                      // );
                    }}
                    placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                  />
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("نوع المرجع")}</label>
                  <Form.Select
                    className="form-select"
                    name="referenceType"
                    onChange={formik.handleChange}
                    value={formik.values.referenceType}
                    // isInvalid={formik.touched.referenceType && formik.errors.referenceType}
                  >
                    <option key={null}>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {INVENTORY_TRANSACTIONS &&
                      INVENTORY_TRANSACTIONS.map((item: any, idx: number) =>
                        item.list.map((list: any) => (
                          <option key={++idx} value={list.lookupCode}>
                            {i18n.language === "ar"
                              ? list.meaning2
                              : list.meaning}
                          </option>
                        ))
                      )}
                  </Form.Select>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("رقم المرجع")}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control text-center"
                    type="text"
                    name="referenceCode"
                    onChange={formikhandleChange}
                    value={values.referenceCode || ""}
                  />
                </div>
              </div>

              <div className="col-lg-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t("تاريخ المرجع")}</label>
                  <Form.Control
                    className="form-control  text-center"
                    type="date"
                    name="referenceDate"
                    onChange={formikhandleChange}
                    value={values.referenceDate || ""}
                    isInvalid={
                      !!(touched.referenceDate && errors.referenceDate)
                    }
                    isValid={
                      formik.touched.referenceDate &&
                      !formik.errors.referenceDate
                    }
                  />
                </div>
              </div>





              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("المخزن")}</label>
                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={InventoryOptions}
                    onChange={(option: any) => {
                      formik.setFieldValue(
                        "inventoryId",
                        option === null ? null : option.value
                      );
                    }}
                    placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                  />
                </div>
              </div>

              <div className="col-lg-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t("العميل")}</label>

                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading2}
                    isSearchable={true}
                    isClearable
                    options={OptionCustomers}
                    onChange={(option: any) => {
                      formik.setFieldValue(
                        "peopleId",
                        option === null ? null : option.value
                      );
                    }}
                    placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                  />
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("الفرع")}</label>

                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={BranchsOptions}
                    onChange={(option: any) => {
                      formik.setFieldValue(
                        "branchId",
                        option === null ? null : option.value
                      );
                    }}
                    placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                  />
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("personId")}</label>
                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading}
                    isSearchable={true}
                    isClearable
                    name="personId"
                    options={dataEmployees}
                    onChange={(option: any) => {
                      formik.setFieldValue(
                        "personId",
                        option === null ? null : option.value
                      );
                    }}
                    placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                  />
                </div>
              </div>

              {/* <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("اسم العميل")}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control text-center"
                    type="text"
                    name="recipientname"
                    onChange={formikhandleChange}
                    value={values.recipientname || ""}
                  />
                </div>
              </div> */}

              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Note")}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control"
                    as="textarea"
                    rows={1}
                    name="description"
                    onChange={formik.handleChange}
                    value={values.description}
                    isInvalid={!!(touched.description && errors.description)}
                    isValid={touched.description && !errors.description}
                  />
                  <div></div>
                </div>
              </div>
            </div>

            <p> الأصناف</p>
            <hr />

            <div className="row">
              <TransactionsInvLines formik={formik} />
            </div>

            <div className="text-center">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-theme mb-1"
              >
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                )}
                {t("Add")}
              </button>
              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                data-bs-dismiss="modal"
                id="closeModalForm"
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default Add;
