import React, { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import useGetPosList from '../../../hooks/POS/useGetPosList';
// import { Modal } from 'bootstrap';
import { useTranslation } from 'react-i18next';
// import MedModalComponent from '../../../components/Modal/MedModalComponent';
import OpenSession from './OpenSession';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store/hooks';
import { checkSessionOpen } from '../../../store/Sales/Pos/PosSessionSlice';
import { useAppSelector } from '../../../store/hooks';
import { getPersonId } from '../../../store/Login/loginSlice';
// import SwalAlert from '../../../components/Shared/Alert/SwalAlert';
// import { Card } from '../../../components/card/card';
import { Col, Row } from 'react-bootstrap';
import NeatixModal from '../../../components/Modal/NeatixModal';
import Loading from '../../../components/Shared/Loader/Loading';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import { PosModel } from '../../../store/Sales/Pos/PosModels';

interface SessionData {
  isOpen: boolean;
  sessionId: string | null;
}

const PosCard = () => {
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { i18n, t } = useTranslation();

  const [sessionData, setSessionData] = useState<{ [key: string]: SessionData }>({});

  const [PosModel, setPosModel] = useState<PosModel>();

  const { PosList, error, loading: posListLoading } = useGetPosList();
  const PersonId = useAppSelector(getPersonId);

  const fetchSessionData = async (PosId: string) => {
    try {
      const CheckSession = await dispatch(
        checkSessionOpen({
          posId: PosId,
          personId: PersonId,
        })
      );
      const result = unwrapResult(CheckSession);

      if (result.succeeded) {
        return { isOpen: result.data !== null, sessionId: result.data };
      }
    } catch (error) {
      console.error('Failed to check session open:', error);
    }
    return { isOpen: false, sessionId: null };
  };

  const handleButtonClick = async (item: PosModel) => {
    if (sessionData[item.id]?.isOpen) {
      item.posType === 'shop'
        ? navigate(`/Pos/${item.id}/${sessionData[item.id].sessionId}/PointOfSaleShop`)
        : navigate(`/Pos/${item.id}/${sessionData[item.id].sessionId}/PointOfSaleRestaurant`);
    } else {
      setPosModel(item);
      setShow(true);
    }
  };

  useEffect(() => {
    const checkSessions = async () => {
      if (PosList) {
        const sessionPromises = PosList.map(async (pos) => {
          const session = await fetchSessionData(pos.id);
          return { id: pos.id, ...session };
        });

        const sessionResults = await Promise.all(sessionPromises);
        const sessionMap = sessionResults.reduce((acc: any, session: any) => {
          acc[session.id] = { isOpen: session.isOpen, sessionId: session.sessionId };
          return acc;
        }, {});
        setSessionData(sessionMap);
      }
    };

    checkSessions();
  }, [PosList]);

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'نقاط البيع',
      link: null,
    },
  ];

  //________________________________________________________________

  console.log(PosList);

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('نقاط البيع')} />
      <hr />

      <Loading loading={posListLoading || Object.keys(sessionData).length === 0} error={error}>
        <div className="p-2">
          <Row>
            {PosList &&
              PosList?.map((item, index) => (
                <Col lg={6} className="mb-3" key={item.id}>
                  <div className="list-group list-group-item  bg-inverse bg-opacity-10 p-1 shadow  ">
                    <div className="d-flex align-items-center justify-content-between h-100px ">
                      <div className="flex-fill ps-4">
                        <div
                          className={`w-70px h-70px d-flex align-items-center justify-content-center rounded-4  ${
                            sessionData[item.id]?.isOpen ? 'bg-theme text-white' : 'text-theme'
                          } `}>
                          <i className="fas fa-lg bi bi-bag-check fs-1"></i>
                        </div>
                      </div>

                      <div className="flex-fill text-center">
                        {/* <div className="fw-bold shadow-sm">نظام حلول تخطيط الموارد نيتكس</div> */}
                        <div className="fw-bold shadow-sm">{item.name2}</div>
                      </div>

                      <div className="flex-fill text-center">
                        <button
                          type="button"
                          className="btn btn-sm  btn-theme btn-primary"
                          onClick={() => handleButtonClick(item)}>
                          {sessionData[item.id]?.isOpen ? 'متابعة البيـع' : 'فتح الكاشير'}
                        </button>
                      </div>

                      

                      <div className="flex-fill ">
                        <div className="position-absolute" style={{ top: '6px', left: '10px' }}>
                          <div className="dropdown text-end ">
                            <Link
                              to=""
                              data-bs-toggle="dropdown"
                              className="text-inverse text-opacity-80 fs-5 menu-link">
                              <i className="fa fa-ellipsis-h nav-icon"></i>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-end">
                              <Link
                                to=""
                                className="dropdown-item d-flex align-items-center"
                                onClick={() => navigate(-1)}>
                                <i className="fa fa-arrow-left fs-14px my-n1 me-3 ms-n2"></i> رجوع
                                للقائمة الرئيسية
                              </Link>
                              <div className="dropdown-divider"></div>
                              <Link
                                to=""
                                className="dropdown-item d-flex align-items-center text-danger"
                                onClick={() => setShow(true)}>
                                <i className="bi bi-door-closed fw-bold fs-14px my-n1 me-3 ms-n2"></i>{' '}
                                إغلاق الجلسة
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      </Loading>

      <></>
      <NeatixModal
        setShow={setShow}
        show={show}
        size={'md'}
        showHeader={true}
        bodyClassName="p-2"
        title="الرصيد الإفتتاحي">
        {PosModel && <OpenSession PosModel={PosModel} />}
      </NeatixModal>
    </>
  );
};

export default PosCard;

// <div className="dropdown text-end">
// <a href="#/" data-bs-toggle="dropdown" className="text-inverse text-opacity-50"><i className="fa fa-ellipsis-h"></i></a>
//     <div className="dropdown-menu dropdown-menu-end">
//         <a href="#/" className="dropdown-item">open</a>
//         <a href="#/" className="dropdown-item">...</a>
//     </div>
// </div>

// {/* <div className="tab-content">
//   <div className="row">
//     {PosList &&
//       PosList?.map((item, index) => (
//         <div className="col-xl-3 col-md-6" key={item.id}>
//           <div className="mailbox-detail-content">
//             <div className="mailbox-attachment1 w-100" style={{ margin: '10px' }}>
//               <Link to="" onClick={() => handleCheckSessionOpen(item.id || '')}>
//                 <div className="document-file1 h-100px">
//                   <i className="fas bi bi-bag-check pt-2"></i>
//                 </div>

//                 <div className="document-name">
//                   {i18n.language === 'en' ? item.name : item.name2}
//                 </div>
//               </Link>
//             </div>
//           </div>
//         </div>
//       ))}
//   </div>
// </div> */}

// const handleCheckSessionOpen = async (PosId: string) => {
//   try {
//     const CheckSession = await dispatch(
//       checkSessionOpen({
//         posId: PosId,
//         personId: PersonId,
//       })
//     );
//     const result = unwrapResult(CheckSession);

//     // debugger;

//     if (result.succeeded && result.data !== null) {
//       navigate(`/Pos/${PosId}/${result.data}/PointOfSale`);
//     } else if (result.succeeded && result.data === null) {
// await setPosId(PosId);
//  setShow(true);
//     } else if (!result.succeeded && result.data === null && result.httpStatusCode === 400) {
//       SwalAlert({ text: 'هذا المستحدم غير مرتبط بنقطة البيع هذه' });
//     }
//   } catch {}
// };

// <Container>
//       <Row xs={2} md={6} lg={6}>
//         <Col className='p-0'><button type="button" className="btn btn-lg btn-outline-secondary w-100 h-70px">1</button></Col>
//         <Col className='p-0'><button type="button" className="btn btn-lg btn-outline-secondary w-100 h-70px">2</button></Col>
//         <Col className='p-0'><button type="button" className="btn btn-lg btn-outline-secondary w-100 h-70px">3</button></Col>
//       </Row>
//       <Row xs={2} md={6} lg={6}>
//         <Col className='p-0'><button type="button" className="btn btn-lg btn-outline-secondary w-100 h-70px">4</button></Col>
//         <Col className='p-0'><button type="button" className="btn btn-lg btn-outline-secondary w-100 h-70px">5</button></Col>
//         <Col className='p-0'><button type="button" className="btn btn-lg btn-outline-secondary w-100 h-70px">6</button></Col>
//       </Row>
//       <Row xs={2} md={6} lg={6}>
//         <Col className='p-0'><button type="button" className="btn btn-lg btn-outline-secondary w-100 h-70px">7</button></Col>
//         <Col className='p-0'><button type="button" className="btn btn-lg btn-outline-secondary w-100 h-70px">8</button></Col>
//         <Col className='p-0'><button type="button" className="btn btn-lg btn-outline-secondary w-100 h-70px">9</button></Col>
//       </Row>
//     </Container>
