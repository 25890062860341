import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getPurchaseQuotationList } from '../../../store/purchases/PurchaseQuotation/purchaseQuotationSlice';


const useGetPurchasesQuotationList = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (PurchaseQuotationList?.length === 0) {
      dispatch(getPurchaseQuotationList());
    }
  }, []);
  const { PurchaseQuotationList, loading, error } = useAppSelector(
    (state) => state.PurchaseQuotation
  );

  return { PurchaseQuotationList, loading, error };
};

export default useGetPurchasesQuotationList;
