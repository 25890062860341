import React, { useEffect, useRef } from "react";
import { Toast } from "bootstrap";

const ModalNotifications = ({ message }) => {
  // const toastRef = useRef(null);

  // useEffect(() => {
  //   const toastElement = toastRef.current;
  //   if (toastElement) {
  //     const toast = new Toast(toastElement , {
  //       autohide: true,
  //       delay: 10000,
  //     });
  //     toast.show();

  //     // return () => {
  //     //   toast.hide();
  //     // }

  //   }
  // }, [message]); 

  const toastRefs = useRef([]);

  useEffect(() => {
    message?.forEach((item, index) => {
      const toastElement = toastRefs.current[index];
      if (toastElement) {
        const toast = new Toast(toastElement, {
          autohide: true,
          delay: 10000,
        });
        toast.show();
      }
    });
  }, [message]);


 

   return (
    <div >
      <div className="toasts-container" style={{ zIndex: 1025 }}>
        {message.map((item, index) => (
          <div
            ref={(ref) => (toastRefs.current[index] = ref)}
            key={index}
            className="toast fade hide my-2"
            data-autohide="false"
          >
            <div className="toast-header bg-theme bg-opacity-50">
              <i className={item.icon}></i>
              <strong className="me-auto">إشعـــار</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="toast"
              ></button>
            </div>
            <div className="toast-body text-center fw-bold">{item.message}</div>
          </div>
        ))}
        </div>
    </div>
  );
};

export default ModalNotifications;



// <div className="toasts-container" style={{zIndex : 1025}}>
// <div
//   ref={toastRef}
//   className="toast fade hide"
//   data-autohide="false"
// >
//   <div className="toast-header bg-theme bg-opacity-50">
//     <i className="far fa-bell text-muted me-2"></i>
//     <strong className="me-auto">إشعـــار</strong>
//     {/* <small>Neatix</small> */}
//     <button
//       type="button"
//       className="btn-close"
//       data-bs-dismiss="toast"
//     ></button>
//   </div>

//   <div className="toast-body text-center fw-bold">{message}</div>
// </div>
// </div>