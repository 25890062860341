import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
 
import { getTransactionsQuotationById , cleanUpTransactionsQuotationById } from "../../store/Sales/SalesQuotation/salesQuotationSlice";

interface Props {
  id: string | undefined;
}

const useGetQuotationSalesById = ({ id }: Props) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if(id){

        dispatch(getTransactionsQuotationById(id));
    }

    return () => {
      dispatch(cleanUpTransactionsQuotationById());
    };
  }, [dispatch, id]);
  const { TransactionsQuotationById, loading , error } = useAppSelector((state) => state.SalesQuotation);

  return { TransactionsQuotationById, loading , error };
};

export default useGetQuotationSalesById;
