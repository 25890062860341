import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { UnitsOfMeasureModels } from "./UnitsOfMeasureModels";

 

 

export const GetUnitsOfMeasureList = createAsyncThunk(
  "UnitsOfMeasure/GetUnitsOfMeasureList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get("Inv/GetUnitsOfMeasureList");
      if (data.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data?.message || "هناك خطأ");
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  UnitsOfMeasureList: UnitsOfMeasureModels[];
  loading: boolean;
  error: any | null;
} = {
  UnitsOfMeasureList: [],
  loading: false,
  error: null,
};

const UnitsOfMeasureSlice = createSlice({
  name: "UnitsOfMeasure",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

 

      .addCase(GetUnitsOfMeasureList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetUnitsOfMeasureList.fulfilled,
        (state, action: PayloadAction<UnitsOfMeasureModels[]>) => {
          state.loading = false;
          state.UnitsOfMeasureList = action.payload;
        }
      )
      .addCase(
        GetUnitsOfMeasureList.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default UnitsOfMeasureSlice.reducer;
