import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { Form } from 'react-bootstrap';
import { UpdateAnalysis } from '../../../../store/HR/Raqeeb/raqeebSlice';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import RsponseData from './RsponseData';

const ValidationForm = (t) => {
  return Yup.object({
    dateFrom: Yup.date().required(() => t('Required')),
    dateTo: Yup.date()
      .required(() => t('Required'))
      .min(Yup.ref('dateFrom'), () => t('يجب ان يكون اكبر من تاريخ البدء')),
  });
};

const UpdateRaqeebData = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [seccess, setSeccess] = useState([]);
  const [faild, setFaild] = useState([]);
  const [IsshowResult, setIsshowResult] = useState(false);

  const formik = useFormik({
    initialValues: {
      dateFrom: '',
      dateTo: '',
    },
    validationSchema: ValidationForm(t),
    onSubmit: (values, { resetForm }) => {
      dispatch(
        UpdateAnalysis({
          dateFrom: values.dateFrom,
          dateTo: values.dateTo,
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.succeeded) {
            if (res?.data?.faild?.length > 0) {
              setFaild(res?.data?.faild);
            }
            if (res?.data?.seccess?.length > 0) {
              setSeccess(res?.data?.seccess);
            }
            CustomAlert({
              action: 'Add',
              msg: t('DataUpdatedSuccessfully'),
              Timer: 10000,
            });
          } else {
            CustomAlert({ action: 'Error', msg: res?.message });
            formik.setSubmitting(false);
          }

          formik.setSubmitting(false);
          setIsshowResult(true);
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  var BcrumbList = [
    {
      name: 'Home',
      link: '/dashboard',
    },
    {
      name: t('UpdateAttendanceData'),
      link: null,
    },
  ];
  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="UpdateAttendanceData" />

      <hr />

      <div className="row ">
        <Form onSubmit={formik.handleSubmit}>
          <div className="row align-items-end">
            <div className="col-xl-3">
              <div className="form-group mb-3">
                <label className="form-label">{t('From')}</label>
                <Form.Control
                  className="form-control text-center"
                  type="date"
                  name="dateFrom"
                  onChange={formik.handleChange}
                  value={formik.values.dateFrom}
                  isInvalid={!!formik.errors.dateFrom}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.dateFrom}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-3">
              <div className="form-group mb-3">
                <label className="form-label">{t('To')}</label>
                <Form.Control
                  className="form-control text-center"
                  type="date"
                  name="dateTo"
                  onChange={formik.handleChange}
                  value={formik.values.dateTo}
                  isInvalid={!!formik.errors.dateTo}
                />
                <Form.Control.Feedback type="invalid">{formik.errors.dateTo}</Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-3">
              <div className="form-group mb-3 d-flex justify-content-end">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn me-1 btn-theme   w-100">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="bi bi-repeat fa-fw me-2"></i>
                  )}
                  {t('UpdateData')}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>

      {IsshowResult && (
        <div className="row mt-3">
          <div className="col-lg-6 text-center mt-2">
            <div className="bg-inverse bg-opacity-10 rounded-4 p-2 ">
              <div className="fw-bold">
                {t('DataHasBeenUpdated')}
                <span className="fs-3 text-theme"> {seccess.length} </span>
                {t('employee')}
              </div>
              {seccess.length > 0 && <RsponseData data={seccess} />}
            </div>
          </div>
          <div className=" col-lg-6 text-center mt-2">
            <div className="bg-inverse bg-opacity-10 rounded-4 p-2">
              <div className="fw-bold">
                {t('FailedDataUpdate')}
                <span className="fs-3 text-theme"> {faild.length} </span>
                {t('employee')}
              </div>
              {faild.length > 0 && <RsponseData data={faild} />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateRaqeebData;
