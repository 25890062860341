import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { Card } from '../../../../../components/card/card';
import Loading from '../../../../../components/Shared/Loader/Loading';
import { CardBody } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../store/hooks';
import useGetItemsList from '../../../../../hooks/INV/MasterItems/useGetItemsList';
// import useGetCategoriesList from '../../../../../hooks/INV/useGetCategoriesList';
import { Transfer, ConfigProvider } from 'antd';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import { assignmentItems } from '../../../../../store/INV/AssignmentItems/assignmentItemsSlice';
import useGetItemsListByInventoryId from '../../../../../hooks/INV/MasterItems/useGetItemsListByInventoryId';
import i18n from '../../../../../i18n';

const AssignmentItems = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [itemIds, setItemIds] = useState<any[]>([]);

  // const [dataSource, setDataSource] = useState<
  //   {
  //     key: string;
  //     title: string;
  //   }[]
  // >();

  const { inventoryId } = useParams<{ inventoryId: string }>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { MasterItemsList, loading: loading2, error: error2 } = useGetItemsList();
  // const { CategoriesList } = useGetCategoriesList();

  const { ItemsOptions, error, loading } = useGetItemsListByInventoryId(inventoryId);
  // console.log(ItemsOptions);

  useEffect(() => {
    let ItemsByInv = ItemsOptions?.map((item) => item.value);
    setItemIds(ItemsByInv);
  }, [loading]);

  const onSubmit = () => {
    if (itemIds.length === 0) {
      CustomAlert({ action: 'info', msg: 'يرجى تحديـد الأصناف' });
      return;
    }
    setIsSubmitting(true);

    let body = {
      itemIds: itemIds,
      inventoryId: inventoryId,
    };

    dispatch(assignmentItems(body))
      .unwrap()
      .then(() => {
        setIsSubmitting(false);
        CustomAlert({ action: 'Add' });
      })
      .catch((error: any) => {
        setIsSubmitting(false);

        CustomAlert({ action: 'Error' });
      });
  };
  const location = useLocation();
  const { name } = location?.state;
  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'المخازن',
      link: '/INV/InventorySettings/Inventory',
    },
    {
      name: 'تعيين الأصناف',
      link: null,
    },
  ];

  // console.log(currentData);

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('تعيين الأصناف ')} subtitle={name} />
      <div className="position-relative">
        <Loading loading={loading || loading} error={error || error2}>
          <CardBody>
            <ConfigProvider direction={i18n.language === 'en' ? 'ltr' : 'rtl'}>
              <Transfer
                dataSource={MasterItemsList?.map((item) => ({
                  key: item.id, // المفتاح الفريد لكل عنصر
                  title: item.name, // النص الذي سيتم عرضه في قائمة النقل
                  description: item.description, // الوصف (يمكنك تعديله ليعرض محتوى آخر إذا رغبت)
                }))} // مصدر البيانات
                // dataSource={dataSource} // مصدر البيانات
                titles={['جميع الأصناف', 'أصناف المخزن']} // عناوين القوائم
                targetKeys={itemIds} // المفاتيح المختارة في القائمة المستهدفة
                onChange={(options: any) => setItemIds(options)} // دالة معالجة التغيير
                // onChange={handleChange} // دالة معالجة التغيير
                render={(item) => item.title} // كيف تظهر العناصر
                // oneWay // تمكين النقل باتجاه واحد
                showSearch // تمكين البحث
                pagination
              />
            </ConfigProvider>
          </CardBody>
          <div className="mt-3" />
          <div className="text-center">
            <button
              disabled={isSubmitting}
              onClick={() => onSubmit()}
              type="submit"
              className="btn me-1 btn-lg btn-theme mb-1"
              id="SaveSubmit">
              {isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                // <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                <i className="fa fa-save btn-lg fa-fw me-1"></i>
              )}
              {t('Save')}
            </button>
            <Link
              to="/INV/InventorySettings/Inventory"
              replace
              className="btn me-1 btn-lg btn-default mb-1">
              <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
            </Link>
          </div>
        </Loading>
      </div>
    </>
  );
};

export default AssignmentItems;
