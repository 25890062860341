import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Form } from 'react-bootstrap';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';

import { addPosPaymentMethod } from '../../../../store/Sales/Pos/PosPaymentMethodSlice';
import useGetPaymentMethod from '../../../../hooks/Payrolls/use-Get-PaymentMethod';


type Props = {
  posId: string;
};

const PosPaymentMethod = ({ posId }: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { PosList } = useAppSelector((state) => state.Pos);

  const { PaymentMethodList } = useGetPaymentMethod();

  const formik = useFormik({
    initialValues: {
      posId: posId,
      paymentMethodId: '',
    },

    // validationSchema: formOrganization(t),
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        addPosPaymentMethod({
          posId: values.posId,
          paymentMethodId: values.paymentMethodId,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded === true) {
            formik.resetForm();
            // document.getElementById("AddPriceList")?.click();
            CustomAlert({ action: 'Add' });
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
      formik.setSubmitting(false);
    },
  });

  var posName =
    i18n.language === 'en'
      ? PosList.find((f) => f.id?.toLowerCase() === posId.toLowerCase())?.name
      : PosList.find((f) => f.id === posId)?.name2;

      console.log(posName); 
      
  return (
    // <Loading loading={loading} error={error}>
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row mb-3">
          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('اسم نقطة البيع')}</label>
              <Form.Control
                className="form-control  "
                type="text"
                value={posName}
              />
            </div>
          </div>

          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('طريقة الدفع')}</label>
              <Form.Select
                className="form-select"
                name="paymentMethodId"
                onChange={formik.handleChange}
                value={formik.values.paymentMethodId}
                isInvalid={!!(formik.touched.paymentMethodId && formik.errors.paymentMethodId)}>
                <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {PaymentMethodList.map((item: any) => (
                  <option key={item.id} value={item.id}>
                    {i18n.language === 'ar' ? item.name : item.name2}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.paymentMethodId}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
            id="SaveSubmit">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t('Add')}
          </button>
          {/* <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button> */}
        </div>
      </Form>

      {/* <div className="table-responsive rounded-2 text-center border border-theme">
          <table className="table ">
            <thead className="bg-theme bg-opacity-60">
              <tr>
                <th scope="col">personId</th>
                <th scope="col">{t('startDate')}</th>
                <th scope="col">{t('endDate')}</th>
                <th scope="col"></th>
              </tr>
            </thead>

            <tbody>
              {PosCashierListByPosId.map((item, index) => (
                <tr key={index}>
                  <td>{i18n.language === 'en' ? item.personName : item.personName}</td>
                  <td>{item.StartDate}</td>
                  <td>{item.EndDate}</td>
                  <td>
                    <Link
                      to=""
                      style={{ color: 'red' }}
                      // onClick={() => handleDeleteRowClick(index)}
                    >
                      <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}
    </div>
    // {/* </Loading> */}
  );
};

export default PosPaymentMethod;
