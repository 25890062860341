import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Pagination from '../../../../components/Shared/Pagination/Pagination.jsx';
import { Card, CardBody } from '../../../../components/card/card.jsx';
import { useTranslation } from 'react-i18next';
import { GetElementType } from '../../../../store/HR/Payroll/payrollElementSlice.js';
import ModalComponent from '../../../../components/Modal/ModalComponent.jsx';
import AddPayrollElement from './AddPayrollElement.js';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { usePermissions } from '../../../../Helper/Permisstions/usePermissions';
import Loading from '../../../../components/Shared/Loader/Loading';
import EditElementType from './EditElementType.js';

const PayrollElement = () => {
  const { HasPermission } = usePermissions();

  // const [Show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState('');
  const [ElementId, setElementId] = useState('');
  // const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const Navigate = useNavigate();

  // Get all Payrolls from store
  const dispatch = useDispatch();
  const { ElementTypeList, loading, error } = useSelector((state) => state.PyrollElement);
  const {
    ClassificationList,
    loading: loading3,
    error: error2,
  } = useSelector((state) => state.PyrollElement);
  useEffect(() => {
    if (ElementTypeList?.length === 0) {
      dispatch(GetElementType());
    }
  }, [dispatch]);

  const filteredSearch = ElementTypeList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('PayrollElements'),
      link: null,
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('PayrollElements')} />

        {HasPermission('PayAddNewElement') && (
          <div className="ms-auto">
            <Link
              to=""
              className="btn btn-theme me-1"
              data-bs-toggle="modal"
              data-bs-target="#modalAddPayrollElement"
              // onClick={() => setShow(true)}
            >
              <i className="fa fa-plus-circle fa-fw me-1"></i> {t('AddPayrollElement')}{' '}
            </Link>
          </div>
        )}
      </div>

      <Card>
        <Loading loading={loading || loading3} error={error || error2}>
          <CardBody>
            <div className="tab-content p-2">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-2">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder={t('Search') + ' ...'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table table-hover text-nowrap">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t('Name')}</th>
                      <th scope="col">{t('Classification')}</th>

                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle">
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </td>
                        <td className="align-middle">
                          {i18n.language === 'en'
                            ? ClassificationList.find((f) => f.id === item.classificationId)?.name
                            : ClassificationList.find((f) => f.id === item.classificationId)?.name2}
                        </td>
                        <td className="text-end">
                          <div className="btn-group">
                            {HasPermission('PayElementPayrollLink') && (
                              <button
                                type="button"
                                onClick={() => setElementId(item.id)}
                                // onClick={() => Navigate(`${item.id}/EditElementType`)}
                                className="btn btn-outline-theme mb-1 me-1"
                                data-bs-toggle="modal"
                                data-bs-target="#modalEditPayrollElement">
                                <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                                {t('Button.Edit')}
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={ElementTypeList}
                // .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </CardBody>
        </Loading>
      </Card>

      <ModalComponent id="modalAddPayrollElement" title={t('AddPayrollElement')}>
        {true && <AddPayrollElement />}
      </ModalComponent>

      <ModalComponent id="modalEditPayrollElement" title={t('EditPayrollElement')}>
        {ElementId && <EditElementType id={ElementId} />}
      </ModalComponent>
    </>
  );
};
export default PayrollElement;
