import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
import { GetPaymentMethod } from "../../store/HR/Payroll/PayrollSlice";
 

const useGetPaymentMethod = ()=>{

 
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(GetPaymentMethod());
    }, []);
    const { PaymentMethodList , loading } = useSelector((state) => state.Payrolls);
    
    return { PaymentMethodList , loading } ;
};

export default useGetPaymentMethod ;