import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
 
import { GetDISCOUNTTYPE } from "../../store/Lookup/lookupSlice";

const useLookupDiscountType = ()=>{

    const dispatch = useDispatch();
    
    const { DISCOUNT_TYPE } = useSelector((state) => state.Lookup); 

    useEffect(() => {
      // Dispatch action only if DISCOUNT_TYPE is empty
      if (true) {
          dispatch(GetDISCOUNTTYPE());
      }
  }, []);


    return { DISCOUNT_TYPE } ;
};

export default useLookupDiscountType ;