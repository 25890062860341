import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
 
import { GetREWARDS_TYPE } from "../../store/Lookup/lookupSlice";

const useLookupRewardType = ()=>{

    const dispatch = useDispatch();
    
    const { REWARDS_TYPE } = useSelector((state) => state.Lookup); 
    
    useEffect(() => {
       if (true) {
          dispatch(GetREWARDS_TYPE());
      }
  }, []);


    return { REWARDS_TYPE } ;
};

export default useLookupRewardType ;