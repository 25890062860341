import React, { useRef, useState } from 'react';
import { Card, CardBody } from '../../../components/card/card';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PaymentPages = ({ order, setShowModalPayment, handleConfirmPayment }) => {
  const inputRefs = useRef([]);

  const getTotalOrder = () => {
    return order?.orderData
      ?.reduce((total, order) => {
        return total + parseFloat(order.price) * parseInt(order.quantity) * 1.15;
      }, 0)
      .toFixed(2);
  };

  const [optionsPayment, setOptionsPayment] = useState([
    {
      paymentType: 'Cash',
      value: Number(getTotalOrder()),
    },
  ]);

  const getSumOptionsPayment = () => {
    return optionsPayment
      ?.reduce((total, item) => {
        const value = parseFloat(item.value);
        return total + (isNaN(value) ? 0 : value);
      }, 0)
      .toFixed(2);
  };

  const AddOptionPayment = (type) => {
    const existingPaymentIndex = optionsPayment.findIndex((option) => option.paymentType === type);

    if (existingPaymentIndex !== -1) {
      inputRefs.current[existingPaymentIndex].focus();
    } else {
      setOptionsPayment((prevOptions) => {
        const newOptions = [...prevOptions, { paymentType: type, value: 0 }];
        // Get the index of the newly added option
        const newIndex = newOptions.length - 1;
        // Set focus to the corresponding input field
        setTimeout(() => {
          inputRefs.current[newIndex].focus();
        }, 50); // Adding a timeout to ensure focus is set after the component re-renders
        return newOptions;
      });
    }
  };

  const DeleteOptionPayment = (type) => {
    setOptionsPayment((prevOptions) => prevOptions.filter((f) => f.paymentType !== type));
  };

  //__________________
  const [currentInputIndex, setCurrentInputIndex] = useState(null);

  const handleInputChange = (e, index) => {
    const newValue = e.target.value;
    const newOptions = [...optionsPayment];
    newOptions[index].value = newValue;
    setOptionsPayment(newOptions);
  };

  const handleButtonClick = (value) => {
    if (currentInputIndex !== null) {
      const currentInput = inputRefs.current[currentInputIndex];
      if (currentInput) {
        const newValue = currentInput.value + value;
        handleInputChange({ target: { value: newValue } }, currentInputIndex);
      }
    }
  };

  const handleBackspace = () => {
    if (currentInputIndex !== null) {
      const currentInput = inputRefs.current[currentInputIndex];
      if (currentInput) {
        const newValue = currentInput.value.slice(0, -1);
        handleInputChange({ target: { value: newValue } }, currentInputIndex);
      }
    }
  };


 

  
  return (
    <>
      <div className="position-absolute top-0 start-0 m-0">
        <Link
          to={``}
          className="btn btn-danger p-0 rounded-0"
          onClick={() => setShowModalPayment(false)}>
          <i className="bi bi-x fa-lg fw-bold fs-1 m-2"></i>
        </Link>
      </div>
      <div className="row no-gutter d-flex h-100" style={{ direction: 'ltr' }}>
        <div className="col-sm-7 d-flex flex-column justify-content-between bg-inverse bg-opacity-10 p-3">
          <div className="pt-5">
            {optionsPayment.map((item, index) => (
              <div className="input-group mb-3">
                <label
                  className="fw-bold bg-inverse bg-opacity-20 text-center pt-2 fs-5"
                  style={{ width: '250px' }}>
                  {item.paymentType}
                </label>
                <div className="flex-fill">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control form-control-lg fs-3  pe-2"
                      value={item.value}
                      onChange={(e) => handleInputChange(e, index)}
                      onFocus={() => setCurrentInputIndex(index)}
                      ref={(el) => (inputRefs.current[index] = el)}
                    />
                    <button
                      className="btn btn-danger rounded-0"
                      onClick={() => DeleteOptionPayment(item.paymentType)}>
                      <i className="bi bi-trash fa-lg fw-bold fs-4"></i>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Row>
            <Col md={4} className="p-1">
              <table
                style={{
                  width: '100%',
                  tableLayout: 'fixed',
                  height: '375px',
                  direction: 'ltr',
                }}
                className="p-3">
                <tbody>
                  <tr>
                    <td style={{ padding: '0' }} rowSpan={1} className="shadow">
                      <button
                        type="button"
                        className="btn btn-lg btn-outline-theme rounded-0 w-100 h-100"
                        onClick={() => AddOptionPayment('Cash')}>
                        <div className="d-flex justify-content-between align-items-center w-100 h-100">
                          <div className="text-end">
                            <i className="bi bi-cash-coin h2"></i>
                          </div>
                          <div className="text-center w-100">
                            <span className="h4">Cash</span>
                          </div>
                        </div>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: '0' }} rowSpan={1} className="shadow">
                      <button
                        type="button"
                        className="btn btn-lg btn-outline-theme rounded-0 w-100 h-100"
                        onClick={() => AddOptionPayment('bank')}>
                        <div className="d-flex justify-content-between align-items-center w-100 h-100">
                          <div className="text-end">
                            <i className="bi bi-credit-card h2"></i>
                          </div>
                          <div className="text-center w-100">
                            <span className="h4">Bank</span>
                          </div>
                        </div>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: '0' }} rowSpan={1} className="shadow">
                      <button
                        type="button"
                        className="btn btn-lg btn-outline-theme rounded-0 w-100 h-100"
                        onClick={() => AddOptionPayment('Customer_Account')}>
                        <div className="d-flex justify-content-between align-items-center w-100 h-100">
                          <div className="text-end">
                          <i className="bi bi-credit-card h2"></i>
                          </div>
                          <div className="text-center w-100">
                            <span className="h4">Customer Account</span>
                          </div>
                        </div>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>

            <Col md={8} className="p-1">
              <table
                style={{
                  width: '100%',
                  tableLayout: 'fixed',
                  height: '375px',
                  direction: 'ltr',
                }}
                className="p-3">
                <tbody>
                  <tr>
                    {[7, 8, 9].map((item) => (
                      <td style={{ padding: '0' }} key={item} className="shadow">
                        <button
                          type="button"
                          className="btn btn-lg btn-outline-theme  fw-bold rounded-0 w-100 h-100"
                          onClick={() => handleButtonClick(item.toString())}>
                          <h1>{item}</h1>
                        </button>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {[4, 5, 6].map((item) => (
                      <td style={{ padding: '0' }} key={item} className="shadow">
                        <button
                          type="button"
                          className="btn btn-lg btn-outline-theme rounded-0 w-100 h-100"
                          onClick={() => handleButtonClick(item.toString())}>
                          <h1>{item}</h1>
                        </button>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {[1, 2, 3].map((item) => (
                      <td style={{ padding: '0' }} key={item} className="shadow">
                        <button
                          type="button"
                          className="btn btn-lg btn-outline-theme rounded-0 w-100 h-100"
                          onClick={() => handleButtonClick(item.toString())}>
                          <h1>{item}</h1>
                        </button>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td style={{ padding: '0' }} className="shadow">
                      <button
                        type="button"
                        className="btn btn-lg btn-outline-theme rounded-0 w-100 h-100"
                        onClick={() => handleButtonClick('0')}>
                        <h1>{0}</h1>
                      </button>
                    </td>
                    <td style={{ padding: '0' }} className="shadow">
                      <button
                        type="button"
                        className="btn btn-lg btn-outline-theme  rounded-0 w-100 h-100"
                        onClick={() => handleButtonClick('.')}>
                        <h1>.</h1>
                      </button>
                    </td>
                    <td style={{ padding: '0' }} className="shadow">
                      <button
                        type="button"
                        className="btn btn-lg btn-secondary fw-bold rounded-0 w-100 h-100"
                        onClick={handleBackspace}>
                        <h1> ⌫ </h1>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </div>

        <div className="col-sm-5 d-flex flex-column justify-content-between p-4 pb-0 ">
          <div>
            <label className="form-label">
              العميل <span className="text-danger">*</span>
            </label>
            <div className="input-group d-flex">
              <select className="text-primary fw-bold fs-5 bg-inverse bg-opacity-10 btn btn-default w-75">
                <option className="dropdown-item" value={''}>
                  عميل مبيعات نقدية
                </option>
              </select>

              <Link to={``} className="btn btn-primary rounded-0 w-25">
                <i className="bi bi-plus fa-lg fw-bold" style={{ fontSize: '2.3125rem' }}></i>
              </Link>
            </div>
          </div>

          <div>
            <h3 className="text-start mb-4">
              <u> : تفاصيــل الفاتورة </u>
            </h3>
            <table className="table table-hover p-3">
              <tbody>
                <tr>
                  <td className="align-middle text-center">
                    <strong className="fw-bold fs-4"> {getTotalOrder()} </strong>
                  </td>
                  <td className="align-middle text-start">
                    <p className="p-0 m-0 fw-bold fs-4">الإجمالي المستحق</p>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle text-center">
                    <strong> {getSumOptionsPayment()} </strong>
                  </td>
                  <td className="align-middle text-start">
                    <p className="p-0 m-0 fs-5">المدفوع</p>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle text-center">
                    <strong> {getTotalOrder() - getSumOptionsPayment()} </strong>
                  </td>
                  <td className="align-middle text-start">
                    <p className="p-0 m-0 fs-5">المتبقي</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="btn-group d-flex mt-2">
            <button
              className="btn btn-success rounded-0 p-0 "
              style={{ width: '230px', height: '80px' }}
              onClick={() => handleConfirmPayment()}>
              <span className="fw-bold fs-3 text-start" style={{ letterSpacing: '0.1em' }}>
                Confirm
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentPages;

// const calculateTotalPrice = () => {
//   let totalPrice = 0;
//   order.forEach(item => {
//     totalPrice += item.price * item.quantity;
//   });
//   return totalPrice;
// };
