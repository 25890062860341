import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  GetSubinventoryByParentId,
  UpdatSubinventory,
} from "../../../../../store/INV/SubInventory/SubInventorySlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomAlert from "../../../../../components/Shared/Alert/CustomAlert";
import { SubInventoryModels } from "../../../../../store/INV/SubInventory/SubInventoryModels";

interface props {
  Id: string;
}

const EditSubInventory = ({ Id }: props) => {
  const Navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [subInventory, setSubInventory] = useState<SubInventoryModels>();
  const { SubInventoryListByParentId } = useAppSelector(
    (state) => state.SubInventory
  );

  useEffect(() => {
    const dataById = SubInventoryListByParentId.find((f) => f.id === Id);

    setSubInventory(dataById);
  }, [Id, SubInventoryListByParentId]);

  const formik = useFormik({
    initialValues: {
      id: subInventory ? subInventory.id : "",
      name: subInventory ? subInventory.name : "",
      name2: subInventory ? subInventory.name2 : "",
      code: subInventory ? subInventory.code : "",
      parentId: subInventory ? subInventory.parentId : "",
      description: subInventory ? subInventory.description : "",
    },
    // validationSchema:  ,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        UpdatSubinventory({
          id: values.id,
          name: values.name,
          name2: values.name2,
          code: values.code,
          parentId: values.parentId,
          description: values.description,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded) {
            dispatch(GetSubinventoryByParentId(subInventory?.parentId || ""));

            CustomAlert({ action: "Edit" });
            document.getElementById("close")?.click();
            formik.resetForm();
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error" });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t("LocalName")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2 || ""}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name2}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t("GlobalName")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name || ""}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t("Code")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="code"
                onChange={formik.handleChange}
                value={formik.values.code || ""}
                isInvalid={!!(formik.touched.code && formik.errors.code)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.code}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t("Note")}</label>
              <Form.Control
                className="form-control"
                as="textarea"
                rows={2}
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description || ""}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.description}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
            id="SaveSubmit"
          >
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t("Save")}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="close"
          >
            <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default EditSubInventory;
