import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { GetTransactionTypesList } from '../../store/INV/TransactionTypes/TransactionTypesSlice';
import { useTranslation } from 'react-i18next';

const useGetTransactionTypesList = () => {

    const dispatch = useAppDispatch();

    const {i18n} = useTranslation();
  
    useEffect(() => {
      if(TransactionTypesList?.length ===0){
        dispatch(GetTransactionTypesList());
      }
    }, []);
    const { TransactionTypesList , loading , error} = useAppSelector(
      (state) => state.TransactionTypes_Inv
    );

   
   

  return { TransactionTypesList  ,  error , loading }
}

export default useGetTransactionTypesList
