import React, { useEffect, useState } from 'react';

import { Card, CardBody } from '../../../components/card/card';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import { useTranslation } from 'react-i18next';
import useGetItemsList from '../../../hooks/INV/MasterItems/useGetItemsList';
import Pagination from '../../../components/Shared/Pagination/Pagination';
import i18n from '../../../i18n';
import { Form } from 'react-bootstrap';
import useGetCategoriesList from '../../../hooks/INV/useGetCategoriesList';
import { MasterItemsModels } from '../../../store/INV/MasterItems/MasterItemsModels';
import { Link, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../store/hooks';
import { UpdatPriceList } from '../../../store/INV/PriceList/PriceListSlice';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';
import useGetPriceListById from '../../../hooks/Sales/useGetPriceListById';
import Loading from '../../../components/Shared/Loader/Loading';

// const listPrice = [
//   {
//     id: null,
//     priceListId: "8f4091b3-7dde-4c6c-9951-208b1e468a1d",
//     itemId: "b0c6b7ea-9753-41f1-16a0-08dc3d9fe998",
//     unitOfMeasure: "779320e8-8ee9-4b70-9adf-e62f7fccce7f",
//     unitPrice: 12,
//     enabledFlag: false,
//   },
//   {
//     id: null,
//     priceListId: "8f4091b3-7dde-4c6c-9951-208b1e468a1d",
//     itemId: "30e020f2-f28e-440d-5807-08dc4430aba0",
//     unitOfMeasure: "5cc5ff20-b711-4e46-a9ad-0f6de4b81b1a",
//     unitPrice: 62,
//     enabledFlag: false,
//   },
//   {
//     id: null,
//     priceListId: "8f4091b3-7dde-4c6c-9951-208b1e468a1d",
//     itemId: "eb9870a9-6a1a-4e06-4897-08dc4545c797",
//     unitOfMeasure: "47c9f321-2597-484a-8fcb-352807018916",
//     unitPrice: 94,
//     enabledFlag: true,
//   },
// ];

const DetailsPriceList = () => {
  // const { id } = useParams();
  const { id } = useParams<{ id: string }>(); // Assuming your id is of type string

  const [isSubmitting, setIssubmitting] = useState(false);
  const [filterEnable, setFilterEnable] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const dispatch = useAppDispatch();
  const [itemsList, setItemsList] =
    useState<(MasterItemsModels & { enabledFlag: boolean; unitPrice: number })[]>();

  const { MasterItemsList } = useGetItemsList();
  const { CategoriesList } = useGetCategoriesList();
  // const {PriceListById} = useGetPriceListById({id});

  const { PriceListById, loading } = useGetPriceListById({ id: id || '' }); // Provide a default value if id is undefined

  useEffect(() => {
    if (MasterItemsList.length) {
      const ItemsListPrice = MasterItemsList.map((item) => ({
        ...item,
        enabledFlag:
          PriceListById?.priceListItemsList?.find((f) => f.itemId === item.id)?.enabledFlag ||
          false,
        unitPrice:
          PriceListById?.priceListItemsList?.find((f) => f.itemId === item.id)?.unitPrice || 0,
      }));
      setItemsList(ItemsListPrice);
    }
  }, [MasterItemsList, PriceListById]);

  //#region
  const filteredSearch = itemsList
    ?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    .filter((f) => {
      if (filterEnable === 'Enable') {
        return f.enabledFlag === true;
      } else if (filterEnable === 'Disable') {
        return f.enabledFlag === false;
      }
      return true;
    })
    .filter((f) => (filterCategory === '' ? true : f.categoryId === filterCategory));

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);
  //#endregion

  const handleChange = (itemId: string, value: string) => {
    setItemsList((prevItemsList) =>
      prevItemsList?.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            unitPrice: parseFloat(value),
          };
        }
        return item;
      })
    );
  };

  const handleChangeCheckbox = (itemId: string, isChecked: boolean) => {
    setItemsList((prevItemsList) =>
      prevItemsList?.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            enabledFlag: isChecked,
          };
        }
        return item;
      })
    );
  };

  const onSubmit = () => {
    setIssubmitting(true);
    dispatch(
      UpdatPriceList({
        id: PriceListById ? PriceListById.id : '',
        name: PriceListById ? PriceListById.name : '',
        name2: PriceListById ? PriceListById.name2 : '',
        description: PriceListById ? PriceListById.description : '',
        typeCode: PriceListById ? PriceListById.typeCode : '',
        currencyCode: PriceListById ? PriceListById.currencyCode : '',
        startDateActive: PriceListById ? PriceListById.startDateActive : '',
        endDateActive: PriceListById ? PriceListById.endDateActive : '',
        // priceListItemsList :[]
        priceListItemsList: itemsList
          ?.filter((f) => f.enabledFlag)
          ?.map((item) => ({
            id: null,
            priceListId: PriceListById ? PriceListById.id : '',
            itemId: item.id,
            unitOfMeasure: item.unitOfMeasure,
            unitPrice: item.unitPrice,
            enabledFlag: item.enabledFlag,
          })),
      })
    )
      .unwrap()
      .then((res) => {
        if (res.succeeded === true) {
          CustomAlert({ action: 'Edit' });
          setIssubmitting(false);
        } else {
          setIssubmitting(false);
          CustomAlert({ action: 'Error' });
        }
      })
      .catch((error) => {
        setIssubmitting(false);
        CustomAlert({ action: 'Error' });
      });
  };

  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'قوائم الأسعار',
      link: '/SalesSettings/PriceList',
    },
    {
      name: 'التفاصيـل',
      link: null,
    },
  ];

  // console.log(currentData);
  

  return (
    <div>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName={t('الأسعار للقائمة')}
        subtitle={i18n.language === 'en' ? PriceListById.name : PriceListById.name2}
      />
      <Card>
        <Loading loading={loading}>
          <CardBody>
            <div className="row">
              <div className="col-xl-10 col-md-10 d-flex justify-content-start">
                <div className="input-group mb-2">
                  <div className="flex-fill position-relative">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control   px-35px"
                        placeholder={t('Search')}
                      />
                      <div
                        className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
                        style={{ zIndex: 1020 }}>
                        <i className="fa fa-search opacity-5"></i>
                      </div>
                    </div>
                  </div>

                  <select
                    className="btn btn-outline-default p-0  "
                    value={filterCategory}
                    onChange={(e) => setFilterCategory(e.target.value)}>
                    <option className="" value={''}>
                      {t('All')}
                    </option>
                    {CategoriesList &&
                      CategoriesList.map((item) => (
                        <option value={item.id} key={item.id} className=" ">
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </option>
                      ))}
                  </select>

                  <select
                    className="btn btn-outline-default p-0  "
                    value={filterEnable}
                    onChange={(e) => setFilterEnable(e.target.value)}>
                    <option value={''}>{t('الكل')}</option>
                    <option value={'Enable'}>{t('مفعل')}</option>
                    <option value={'Disable'}>{t('غير مفعل')}</option>
                  </select>

                 </div>
              </div>

              <div className="col-xl-2 col-md-12  text-end">
                <button className="btn  me-1 btn-theme" onClick={onSubmit} disabled={isSubmitting}>
                  {isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fa fa-save fa-fw me-2"></i>
                  )}
                  {t('Save')}
                </button>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table text-center text-nowrap">
                <thead className=" align-middle bg-inverse bg-opacity-25  p-1 mb-1">
                  <tr>
                    <th className=""></th>
                    <th className="">كود الصنف (SKU)</th>
                    <th className="">اسم الصنف</th>
                    <th className="">السعر</th>
                    <th className="">السعر الجديد</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData &&
                    currentData.map((item) => (
                      <tr
                        className={`${item.enabledFlag ? 'bg-theme bg-opacity-25' : ''} `}
                        key={item.id}>
                        <td className="w-10px align-middle">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={(e) => handleChangeCheckbox(item.id, e.target.checked)}
                              checked={item.enabledFlag}
                            />
                            <label className="form-check-label" htmlFor="product1"></label>
                          </div>
                        </td>
                        <td className="align-middle">
                          {/* <Link to="">{item.itemCode}</Link> */}
                          {item.itemCode} 
                        </td>
                        <td className="align-middle">
                          {' '}
                          {i18n.language === 'en' ? item.name : item.name2}
                        </td>
                        <td className="align-middle">{item.salesPrice}</td>
                        <td>
                          {' '}
                          <Form.Control
                            className={`form-control  form-control-sm text-center  ${
                              item.enabledFlag ? 'border border-theme' : ' bg-inverse bg-opacity-10'
                            } `}
                            type="text"
                            value={item.unitPrice}
                            onChange={(e) => handleChange(item.id, e.target.value)}
                            disabled={!item.enabledFlag}
                          />
                        </td>
                        {/* <td className="py-1 align-middle">
                        <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                          <i className="fa fa-circle fs-9px fa-fw me-5px"></i>{" "}
                          Paid
                        </span>
                      </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            {/* <div className="p-3 text-center ">
            <div className="row mb-2  p-2 rounded-2 bg-dark text-white">
              <div className="col-md-2">تفعيل</div>
              <div className="col-md-4">اسم الصنف</div>
              <div className="col-md-2">كود الصنف (SKU)</div>
              <div className="col-md-2">سعر البيع</div>
              <div className="col-md-2">سعر 2</div>
            </div>

            {currentData &&
              currentData.map((item) => (
                <div
                  className={`row mb-2 bg-inverse bg-opacity-10 p-2 rounded-2 ${
                    item.enabledFlag
                      ? "border  border-theme fw-bold bg-opacity-25"
                      : ""
                  }`}
                  key={item.id}
                >
                  <div className="col-md-2">
                    <div className="form-check form-switch">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) =>
                            handleChangeCheckbox(item.id, e.target.checked)
                          }
                          checked={item.enabledFlag}
                        />
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4">
                    {i18n.language === "en" ? item.name : item.name2}
                  </div>

                  <div className="col-md-2">{item.itemCode}</div>
                  <div className="col-md-2">{item.salesPrice}</div>
                  <div className="col-md-2">
                    <Form.Control
                      className={`form-control  form-control-sm text-center  ${
                        item.enabledFlag
                          ? "border border-theme"
                          : " bg-inverse bg-opacity-30"
                      } `}
                      type="text"
                      value={item.unitPrice}
                      onChange={(e) => handleChange(item.id, e.target.value)}
                      disabled={!item.enabledFlag}
                    />
                  </div>
                </div>
              ))}
          </div> */}

            <Pagination
              filteredData={filteredSearch || []}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </CardBody>
        </Loading>
      </Card>
    </div>
  );
};

export default DetailsPriceList;
