/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./Loader.css";
import loaderImg from "./LoaderNTX.gif";

const Loader = ({ loading }) => {
  if (!loading) return null;

  return (
    <div
      className="loader-container"
      style={{
        // position: "absolute",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",  
        // backgroundColor: 'rgba(255, 255, 255, 0.5)', // You can customize the background color and opacity of the loader overlay
        zIndex: 1000, // Set a higher z-index value to make sure the loader appears on top of the table content
      }}
    >
      <div className="loader">
        <img src={loaderImg}></img>
      </div>
    </div>
  );
};

export default Loader;
// top: 1,
// left: 1,
// right: 1,
// bottom: 1,
