


import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { LedgersModels } from "./LedgersModels";




  export const AddGlLedgers = createAsyncThunk('Ledgers/AddGlLedgers',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Finance/AddGlLedgers" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });

  export const UpdatGlLedgers = createAsyncThunk('Ledgers/UpdatGlLedgers',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Finance/UpdatGlLedgers" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });



export const GetGlLedgersList = createAsyncThunk(
    "Ledgers/GetGlLedgersList",
    async (_, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get('Finance/GetGlLedgersList');
        if(data?.succeeded) {
          return data?.data;
        } else {
          return initialState.LedgersList
        }
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );

export const GetGlLedgersById = createAsyncThunk(
    "Ledgers/GetGlLedgersById",
    async ( id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get(`Finance/GetGlLedgersById?Id=${id}`);
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );
 
 
 

 




const initialState: {
    LedgersList : LedgersModels[]
    LedgersById? : LedgersModels
    loading: boolean;
    error: any | null;
  } = {
    LedgersList: [],
    LedgersById: {} as LedgersModels,
    loading: false,
    error: null,

  };


  

const LedgersSlice = createSlice({
    name: "Ledgers",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder

      .addCase(AddGlLedgers.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(AddGlLedgers.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.LedgersList.push(action.payload.data); 
      })
      .addCase(AddGlLedgers.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


    .addCase(GetGlLedgersList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetGlLedgersList.fulfilled, (state, action: PayloadAction<LedgersModels[]> ) => {
        state.loading = false;
        state.LedgersList = action.payload;
      })
      .addCase(GetGlLedgersList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })




    .addCase(GetGlLedgersById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetGlLedgersById.fulfilled, (state, action: PayloadAction<LedgersModels> ) => {
        state.loading = false;
        state.LedgersById = action.payload;
      })
      .addCase(GetGlLedgersById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })



      
    },
  });
  
  export default LedgersSlice.reducer;