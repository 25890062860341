import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetExtraInformationWithValueById } from "../../store/ExtraInformation/extraInformationSlice";

const useGetExtraInformationValue = (extraInformationId) => {
  const dispatch = useDispatch();

   const [localExtraInformationId, setLocalExtraInformationId] = useState(null);

  useEffect(() => {
    // Update local state when extraInformationId changes
    setLocalExtraInformationId(extraInformationId);
  }, [extraInformationId]);




  useEffect(() => {
    if (localExtraInformationId !== null && localExtraInformationId !== undefined) {
      dispatch(GetExtraInformationWithValueById(localExtraInformationId));
      setLocalExtraInformationId(null);
    }
  }, [dispatch, localExtraInformationId]);

  const { ExtraInformationWithValueById, loading, error } = useSelector(
    (state) => state.ExtraInformation
  );

  return { ExtraInformationWithValueById, loading, error };
};

export default useGetExtraInformationValue;


 








  // useEffect(() => {
  //   if (localExtraInformationId !== null && localExtraInformationId !== undefined) {
  //     dispatch(GetExtraInformationWithValueById(localExtraInformationId));
  //     setLocalExtraInformationId(null)
  //   }
  //   setLocalExtraInformationId(null)
  // }, [dispatch, extraInformationId]);