import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GetCustomerType } from '../../store/Lookup/lookupSlice';
import { useTranslation } from 'react-i18next';

const useLookupGetCustomerType = () => {
  const dispatch = useDispatch();

  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (CUSTOMER_TYPE?.length === 0) {
      dispatch(GetCustomerType());
    }
  }, []);

  const { CUSTOMER_TYPE, loading, error } = useSelector((state) => state.Lookup);

  const OptionsCustomerType =
    CUSTOMER_TYPE &&
    CUSTOMER_TYPE.map((item, idx) =>
      item.list.map((list) => ({
        value: list.lookupCode,
        label: i18n.language === 'en' ? list.meaning : list.meaning2,
      }))
    ).flat();

  const OptionsCustomerTypeWithAll = [
    {
      label: t('جميع فئات العملاء'),
      value: 'All',
    },
    ...OptionsCustomerType,
  ];

  return { CUSTOMER_TYPE, OptionsCustomerTypeWithAll, OptionsCustomerType, loading, error };
};

export default useLookupGetCustomerType;
