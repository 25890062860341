import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { GetExtraInformationTablesFormList } from "../../store/NTX/FlexValue/Form/formSlice";

const useGetExtraInformationTablesForm = () => { 
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetExtraInformationTablesFormList());
  }, []);

  const { ExtraInformationTablesFormList, loading, error } = useSelector(
    (state) => state.Forms
  );

  const dataExtraInformationTablesFormList =
  ExtraInformationTablesFormList &&
  ExtraInformationTablesFormList.map((item) => ({
      value: item.id,
      label: i18n.language === "ar" ? item.name2 : item.name,
    }));

  return { ExtraInformationTablesFormList, dataExtraInformationTablesFormList, loading, error };
};

export default useGetExtraInformationTablesForm;
