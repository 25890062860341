import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { CategoryModels } from "./CategoryModels";

export const AddGlJeCategory = createAsyncThunk(
  "JeCategory/AddGlJeCategory",
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post("Finance/AddGlJeCategory", item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdatGlJeCategory = createAsyncThunk(
  "JeCategory/UpdatGlJeCategory",
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post("Finance/UpdatGlJeCategory", item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetGlJeCategoryList = createAsyncThunk(
  "JeCategory/GetGlJeCategoryList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get("Finance/GetGlJeCategoryList");
      if(data?.succeeded) {
        return data?.data;
      } else {
        return initialState.GlJeCategoryList
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  GlJeCategoryList: CategoryModels[];
  loading: boolean;
  error: any | null;
} = {
  GlJeCategoryList: [],
  loading: false,
  error: null,
};

const CategorySlice = createSlice({
  name: "JeCategory",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(AddGlJeCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        AddGlJeCategory.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.GlJeCategoryList.push(action.payload.data);
        }
      )
      .addCase(
        AddGlJeCategory.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )

      .addCase(GetGlJeCategoryList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetGlJeCategoryList.fulfilled,
        (state, action: PayloadAction<CategoryModels[]>) => {
          state.loading = false;
          state.GlJeCategoryList = action.payload;
        }
      )
      .addCase(
        GetGlJeCategoryList.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default CategorySlice.reducer;
