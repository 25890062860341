import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

interface Props {
  JourneyPlanList: any[];
  setJourneyPlanList: React.Dispatch<React.SetStateAction<any[]>>;
}

const RouteJourneyPlanList: React.FC<Props> = (props) => {
  const { ListLocationsAndRealEstate } = useAppSelector(
    (state) => state.EquipmentsRoutes
  );


  const { t, i18n } = useTranslation();

  const [referenceId, setReferenceId] = useState("");
  const [Name, setName] = useState("");
  const [Name2, setName2] = useState("");
  const [referenceType, setReferenceType] = useState("");

  //_______________________________________________________

  const handlechange = (e: any) => {
    const selectedOption = e.target.selectedOptions[0];
    const referenceType = selectedOption?.dataset?.type;
    const name = selectedOption?.dataset?.name;
    const name2 = selectedOption?.dataset?.name2;

    setReferenceId(e.target.value);
    setReferenceType(referenceType);

    setName(name);
    setName2(name2);
  };

  const handleDeleteRowClick = (referenceId: any) => {
    const updatedTableData = props.JourneyPlanList?.filter(
      (item) => item.referenceId !== referenceId
    );
    props.setJourneyPlanList(updatedTableData);
  };

  const handleAddToTable = () => {
    if (referenceId) {
      const Exists = props.JourneyPlanList?.some(
        (item) => item.referenceId === referenceId
      );
      if (Exists) {
        Swal.fire("تمت إضافته مسبقًا");
        return;
      }

      const newData = {
        id: null,
        fleetEquipmentsRouteId: null,
        referenceId: referenceId,
        referenceType: referenceType,
        sequence: props.JourneyPlanList?.length + 1,
        name: Name,
        name2: Name2,
      };
      props.setJourneyPlanList([...props.JourneyPlanList, newData]);

      setReferenceId("");
      setReferenceType("");
      setName("");
      setName2("");
    }
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const updatedItems = [...props.JourneyPlanList];
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);

    // Update the sequance property based on the new order
    updatedItems?.forEach((item, index) => {
      item.sequence = index + 1;
    });

    props.setJourneyPlanList(updatedItems);
  };

  return (
    <>
      <div>
        <div className="row d-flex align-items-center text-inverse m-5px bg-inverse bg-opacity-10 p-3 rounded-3">
          <div className="row">
            <div className="col-xl-9">
              <div className="form-group mb-3">
                <label className="form-label">{t("location")}</label>
                <Form.Select
                  className="form-select"
                  value={referenceId}
                  onChange={(e) => handlechange(e)}
                  required
                >
                  <option>{i18n.language === "ar" ? "إختر" : "Choose"}</option>
                  {ListLocationsAndRealEstate &&
                    ListLocationsAndRealEstate.map((item, idx) => (
                      <option
                        key={idx}
                        value={item.id}
                        data-type={item.type}
                        data-name={item.name}
                        data-name2={item.name2}
                      >
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </option>
                    ))}
                </Form.Select>
              </div>
            </div>
            <div className="col-xl-3 pt-4">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={handleAddToTable}
              >
                {t("Add")}
                <i className="fas fa-lg fa-fw ms-3 fa-angle-left"></i>
              </button>
            </div>
          </div>

          <table
                  className="table table-bordered text-nowrap table-sm text-center"
                  style={{ margin: "0px" }}
                >
                  <thead className="table-dark">
                    <tr>
                      <th>{t("Name")}</th>
                      <th>{t("Type")}</th>
                      <th></th>
                    </tr>
                  </thead>
               
                   
                      <tbody

                      >
                        {props.JourneyPlanList.map((data, index) => (

                       
                              <tr
                  
                                key={index}
                              >
                                <td>
                                  {i18n.language === "ar"
                                    ? data?.name2
                                    : data?.name}
                                </td>
                                <td>
                                  {data?.referenceType === "Location"
                                    ? t("BusinessLocation")
                                    : t("Residential")}
                                </td>
                                <td>
                                  <Link
                                    to=""
                                    style={{ color: "red" }}
                                    onClick={() =>
                                      handleDeleteRowClick(data.referenceId)
                                    }
                                  >
                                    <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
                                  </Link>
                                </td>
                              </tr>
                       
                      
                        ))}
                      </tbody>
                 
                 
                </table>

        </div>
      </div>
    </>
  );
};

export default RouteJourneyPlanList;



          // <div className="col-xl-12" style={{ padding: "0px" }}>
          //   <div className="table-responsive text-center">
          //     <DragDropContext onDragEnd={handleDragEnd}>
          //       <table
          //         className="table table-bordered text-nowrap table-sm"
          //         style={{ margin: "0px" }}
          //       >
          //         <thead className="table-dark">
          //           <tr>
          //             <th>{t("Name")}</th>
          //             <th>{t("Type")}</th>
          //             <th></th>
          //           </tr>
          //         </thead>
          //         <Droppable droppableId="table">
          //           {(provided) => (
          //             <tbody
          //               {...provided.droppableProps}
          //               ref={provided.innerRef}
          //             >
          //               {props.JourneyPlanList.map((data, index) => (
          //                 <Draggable
          //                   key={data.referenceId}
          //                   draggableId={data.referenceId}
          //                   index={index}
          //                 >
          //                   {(provided) => (
          //                     <tr
          //                       ref={provided.innerRef}
          //                       {...provided.draggableProps}
          //                       {...provided.dragHandleProps}
          //                       key={index}
          //                     >
          //                       <td>
          //                         {i18n.language === "ar"
          //                           ? data?.name2
          //                           : data?.name}
          //                       </td>
          //                       <td>
          //                         {data?.referenceType === "Location"
          //                           ? t("BusinessLocation")
          //                           : t("Residential")}
          //                       </td>
          //                       <td>
          //                         <Link
          //                           to=""
          //                           style={{ color: "red" }}
          //                           onClick={() =>
          //                             handleDeleteRowClick(data.referenceId)
          //                           }
          //                         >
          //                           <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
          //                         </Link>
          //                       </td>
          //                     </tr>
          //                   )}
          //                 </Draggable>
          //               ))}
          //             </tbody>
          //           )}
          //         </Droppable>
          //       </table>
          //     </DragDropContext>
          //   </div>
          // </div>