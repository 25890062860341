import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { GetNtxPeoplesList } from "../../store/NTX/Peoples/peoplesSlice";
import { GetNtxSettingsList } from "../../store/NTX/Settings/settingsSlice";

const useGetNtxSettings = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if( SettingsLiat.length === 0){
        dispatch(GetNtxSettingsList());
    }
  }, []);
  const { SettingsLiat, loading } = useAppSelector(
    (state) => state.NtxSettings
  );

  var HRSettingList = SettingsLiat.filter(
    (f) => f.moduleId === "0a6c6b89-1d52-4eaa-8d2c-010c74b1f6e0"
  );
  var AMESettingList = SettingsLiat.filter(
    (f) => f.moduleId === "0a6c6b89-1d52-4eaa-8d2c-020c74b1f6e0"
  );
  var SUSettingList = SettingsLiat.filter(
    (f) => f.moduleId === "0a6c6b89-1d52-4eaa-8d2c-030c74b1f6e0"
  );
  var FLEETSettingList = SettingsLiat.filter(
    (f) => f.moduleId === "0a6c6b89-1d52-4eaa-8d2c-040c74b1f6e0"
  );

  return {
    SettingsLiat,
    HRSettingList,
    AMESettingList,
    FLEETSettingList,
    SUSettingList,
    loading,
  };
};

export default useGetNtxSettings;
