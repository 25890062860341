import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  UpdatNtxPeoplesContacts,
  GetPeoplesContactsByPeopleId,
} from '../../../../store/NTX/Peoples/PeoplesContactSlice';
import { Form } from 'react-bootstrap';
import useLookupTitle from '../../../../hooks/Lookups/use-Lookup-Title';
import { useTranslation } from 'react-i18next';
// import { Card, CardBody } from "../../../../components/card/card";
// import Breadcrumb from "../../../../components/Shared/Breadcrumb";
// import PeoplesContactsList from "./PeoplesContactsList";
// import { useNavigate } from "react-router-dom";
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { PeoplesContactsModels } from '../../../../store/NTX/Peoples/PeoplesModels';

type Props = {
  DataById?: PeoplesContactsModels;
};

const UpdatePeoplesContacts: React.FC<Props> = ({ DataById }) => {
  const dispatch = useAppDispatch();
  const { TITLE } = useLookupTitle();
  const { t, i18n } = useTranslation();

  const formik = useFormik({
    initialValues: {
      id: DataById ? DataById.id : '',
      peopleId: DataById ? DataById.peopleId : '',
      peopleSiteId: DataById ? DataById.peopleSiteId : '',
      title: DataById ? DataById.title : '',
      name: DataById ? DataById.name : '',
      name2: DataById ? DataById.name2 : '',
      prefix: DataById ? DataById.prefix : '',
      phone: DataById ? DataById.phone : '',
      mobile: DataById ? DataById.mobile : '',
      department: DataById ? DataById.department : '',
      emailAddress: DataById ? DataById.emailAddress : '',
      url: DataById ? DataById.url : '',
      fax: DataById ? DataById.fax : '',
      extraInformationId: DataById ? DataById.extraInformationId : '',
    },
    // validationSchema:  ,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      dispatch(
        UpdatNtxPeoplesContacts({
          id: values.id,
          peopleId: values.peopleId || null,
          peopleSiteId: null, // values.peopleSiteId ,
          name: values.name,
          name2: values.name2,
          prefix: values.prefix,
          title: values.title,
          phone: values.phone,
          mobile: values.mobile,
          department: values.department,
          emailAddress: values.emailAddress,
          url: values.url,
          fax: values.fax,
          extraInformationId: null,
        })
      )
        .unwrap()
        .then((res) => {
          formik.resetForm();
          CustomAlert({ action: 'Edit' });
          document.getElementById('closeModalCustomersContacts')?.click();
          dispatch(GetPeoplesContactsByPeopleId(DataById?.peopleId || ''));

          // if (res.succeeded) {
          // } else {
          //   formik.setSubmitting(false);
          //   CustomAlert({ action: "Error" });
          // }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  //____________________________

  return (
    <div>
      <Form onSubmit={formik.handleSubmit} className="p-2">
        <div className="row ">
          <div className="col-xl-2">
            <div className="form-group mb-1">
              <label className="form-label ">
                <small>{t('title')}</small>{' '}
              </label>
              <Form.Select
                className="form-select form-select-sm"
                name="title"
                onChange={formik.handleChange}
                value={formik.values.title || ''}
                //   isInvalid={formik.touched.title && formik.errors.title}
              >
                <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {TITLE &&
                  TITLE.map((item: any, idx: number) =>
                    item.list.map((list: any) => (
                      <option key={++idx} value={list.lookupCode}>
                        {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                      </option>
                    ))
                  )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">{formik.errors.title}</Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-5">
            <div className="form-group mb-1">
              <label className="form-label">
                <small>{t('LocalName')}</small>
              </label>
              <Form.Control
                className="form-control form-control-sm text-center"
                type="text"
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2 || ''}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
            </div>
          </div>
          <div className="col-xl-5 ">
            <div className="form-group mb-1">
              <label className="form-label">
                <small>{t('GlobalName')}</small>
              </label>
              <Form.Control
                className="form-control form-control-sm text-center"
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name || ''}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
            </div>
          </div>

          <div className="col-xl-3">
            <div className="form-group mb-1">
              <label className="form-label">
                <small>{t('Phone')}</small>
              </label>
              <Form.Control
                className="form-control form-control-sm text-center"
                type="text"
                name="phone"
                onChange={formik.handleChange}
                value={formik.values.phone || ''}
                isInvalid={!!(formik.touched.phone && formik.errors.phone)}
              />
            </div>
          </div>
          <div className="col-xl-3">
            <div className="form-group mb-1">
              <label className="form-label">
                <small>{t('MobileNumber')}</small>
              </label>
              <Form.Control
                className="form-control form-control-sm text-center"
                type="text"
                name="mobile"
                onChange={formik.handleChange}
                value={formik.values.mobile || ''}
                isInvalid={!!(formik.touched.mobile && formik.errors.mobile)}
              />
            </div>
          </div>

          <div className="col-xl-3">
            <div className="form-group mb-1">
              <label className="form-label">
                <small>{t('رابط الموقع الإلكتروني')}</small>
              </label>
              <Form.Control
                className="form-control form-control-sm"
                type="text"
                name="url"
                onChange={formik.handleChange}
                value={formik.values.url || ''}
              />
            </div>
          </div>

          <div className="col-xl-3">
            <div className="form-group mb-1">
              <label className="form-label">
                <small>{t('Email')}</small>
              </label>
              <Form.Control
                className="form-control form-control-sm"
                type="text"
                name="emailAddress"
                onChange={formik.handleChange}
                value={formik.values.emailAddress || ''}
              />
            </div>
          </div>
        </div>

        <div className="text-end border-2 border-theme bg-inverse bg-opacity-5">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn btn-sm me-1 btn-theme mb-1">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fa fa-save fa-fw me-1"></i>
            )}
            {t('Save')}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default UpdatePeoplesContacts;
