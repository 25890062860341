import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';

import {
  SalesInvoiceModels2,
  // SalesInvoiceModels,

} from './SalesInvoicesModels';

export const AddInvoiceTransactions = createAsyncThunk(
  'SalesInvoices/AddInvoiceTransactions',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('SalesInvoice/AddInvoiceTransactions', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateInvoiceTransactions = createAsyncThunk(
  'SalesInvoices/updateSalesTransactions',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('SalesInvoice/UpdatInvoiceTransactions', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTransactionsInvoiceList = createAsyncThunk(
  'SalesInvoices/getTransactionsInvoiceList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('SalesInvoice/GetTransactionsInvoiceList');
      if (data?.succeeded) {
        //===
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTransactionsInvoiceById = createAsyncThunk(
  'SalesInvoices/getTransactionsInvoiceById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`SalesInvoice/GetTransactionsInvoiceById?Id=${id}`);
      if (data?.succeeded) {
        // ===
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  SalesInvoicesList: SalesInvoiceModels2[];
  SalesInvoicesById: SalesInvoiceModels2;
  loading: boolean;
  error: any | null;
} = {
  SalesInvoicesList: [],
  SalesInvoicesById: {} as SalesInvoiceModels2,
  loading: false,
  error: null,
};

const SaleTransactionsSlice = createSlice({
  name: 'SalesInvoices',
  initialState,
  reducers: {
    cleanUpSalesInvoicesById: (state) => {
      state.SalesInvoicesById = {} as SalesInvoiceModels2;
    },
  },
  extraReducers(builder) {
    builder

      .addCase(getTransactionsInvoiceList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getTransactionsInvoiceList.fulfilled,
        (state, action: PayloadAction<SalesInvoiceModels2[]>) => {
          state.loading = false;
          state.SalesInvoicesList = action.payload;
        }
      )
      .addCase(getTransactionsInvoiceList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })




      .addCase(getTransactionsInvoiceById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getTransactionsInvoiceById.fulfilled,
        (state, action: PayloadAction<SalesInvoiceModels2>) => {
          state.loading = false;
          state.SalesInvoicesById = action.payload;
        }
      )
      .addCase(getTransactionsInvoiceById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });


  },
});



export const { cleanUpSalesInvoicesById } = SaleTransactionsSlice.actions;
export default SaleTransactionsSlice.reducer;
