// eslint-disable-next-line import/no-anonymous-default-export
export default {
    control: {
      fontSize: 22,
      
    },
    "&multiLine": {
      borderRadius: "10px",

      control: {
        fontFamily: "monospace",
        minHeight: 70,
        
      },
      highlighter: {
        padding: 10,
        border: "2px solid transparent",
      },
      input: {
        padding: 10,
        border: "2px solid silver",

      },
    },
    "&singleLine": {
      borderRadius: "10px",
      display: "inline-block",
      width: 180,
      highlighter: {
        padding: 1,
        border: "2px inset transparent",
        
      },
      input: {
        padding: 1,
        border: "2px inset",
        
      },
    },
    suggestions: {
      borderRadius: "10px",
      list: {
        backgroundColor: "var(--bs-body-bg)",
        color : 'var(--bs-heading-color)',
        border: "1px solid #333",
        borderRadius: "10px",
        fontSize: 18,
      },
      item: {
        padding: "10px 20px",
        borderBottom: "1px solid #333",
        "&focused": {
          backgroundColor: "var(--bs-theme)",
        },
      },
    },
  };