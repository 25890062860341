import   { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { GetDashboardsList } from "../../store/NTX/Reports/Dashboard/dashboardSlice";

const useGetDashboardsList= () => {

  const dispatch = useAppDispatch();
  // const { i18n } = useTranslation();


    //__________________________________________________
    const { DashBoardList, loading , error} = useAppSelector((s) => s.DashBoard);

    useEffect(() => {
      dispatch(GetDashboardsList());
    }, [dispatch]);
  
 
  return {DashBoardList , loading ,error };
};

export default useGetDashboardsList;
