


import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { DailyRatesModels } from "./DailyRatesModels";





  export const AddGlDailyRates = createAsyncThunk('DailyRates/AddGlDailyRates',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Finance/AddGlDailyRates" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });

  export const UpdatGlDailyRates = createAsyncThunk('DailyRates/UpdatGlDailyRates',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Finance/UpdatGlDailyRates" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });


export const GetGlDailyRatesList = createAsyncThunk(
    "DailyRates/GetGlDailyRatesList",
    async (_, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get('Finance/GetGlDailyRatesList');
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );

 
 

 




const initialState: {
    DailyRatesList : DailyRatesModels[]
    loading: boolean;
    error: any | null;
  } = {
    DailyRatesList: [],
    loading: false,
    error: null,

  };


  

const DailyRatesSlice = createSlice({
    name: "DailyRates",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder

      .addCase(AddGlDailyRates.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(AddGlDailyRates.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.DailyRatesList.push(action.payload.data); 
      })
      .addCase(AddGlDailyRates.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


    .addCase(GetGlDailyRatesList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetGlDailyRatesList.fulfilled, (state, action: PayloadAction<DailyRatesModels[]> ) => {
        state.loading = false;
        state.DailyRatesList = action.payload;
      })
      .addCase(GetGlDailyRatesList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


 


      
    },
  });
  
  export default DailyRatesSlice.reducer;