import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import {  SalesOrderByIdModels } from './SalesOrderModels';

export const addSalOrderSalseTransactions = createAsyncThunk(
  'SalesOrder/addSalOrderSalseTransactions',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('SalesOrderSalse/AddSalOrderSalseTransactions', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateOrderSalseTransactions = createAsyncThunk(
  'SalesOrder/updateOrderSalseTransactions',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('SalesOrderSalse/UpdatOrderSalseTransactions', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTransactionsOrderSalseList = createAsyncThunk(
  'SalesOrder/getTransactionsQuotationList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('SalesOrderSalse/GetTransactionsOrderSalseList');
      if (data?.succeeded) {
        //===
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTransactionsOrderSalseById = createAsyncThunk(
  'SalesOrder/getTransactionsOrderSalseById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`SalesOrderSalse/GetTransactionsOrderSalseById?Id=${id}`);
      if (data?.succeeded) {
        // ===
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


const initialState: {
  SalesOrderList: SalesOrderByIdModels[];
  TransactionsSalesOrderById: SalesOrderByIdModels;
  loading: boolean;
  error: any | null;
} = {
  SalesOrderList: [],
  TransactionsSalesOrderById: {} as SalesOrderByIdModels,
  loading: false,
  error: null,
};

const SalesOrderSlice = createSlice({
  name: 'SalesOrder',
  initialState,
  reducers: {
    cleanUpTransactionsOrderSalseById: (state) => {
      state.TransactionsSalesOrderById = {} as SalesOrderByIdModels;
    },
  },
  extraReducers(builder) {
    builder

      .addCase(getTransactionsOrderSalseList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getTransactionsOrderSalseList.fulfilled,
        (state, action: PayloadAction<SalesOrderByIdModels[]>) => {
          state.loading = false;
          state.SalesOrderList = action.payload;
        }
      )
      .addCase(getTransactionsOrderSalseList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getTransactionsOrderSalseById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getTransactionsOrderSalseById.fulfilled,
        (state, action: PayloadAction<SalesOrderByIdModels>) => {
          state.loading = false;
          state.TransactionsSalesOrderById = action.payload;
        }
      )
      .addCase(getTransactionsOrderSalseById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { cleanUpTransactionsOrderSalseById } = SalesOrderSlice.actions;
export default SalesOrderSlice.reducer;
