import { useFormik } from "formik";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useTranslation } from "react-i18next";
import { AddRoutesAssignment } from "../../../store/FLEET/AssignmentRoutes/assignmentroutesSlice";
import CustomAlert from "../../../components/Shared/Alert/CustomAlert";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Shared/Breadcrumb";
import { Card, CardBody } from "../../../components/card/card";
import { Form } from "react-bootstrap";
import Select from "react-select";
import useGetPersons from "../../../hooks/HR/use-get-persons";
import AssignmentRoutesList from "./AssignmentRoutesList";
import { GetAssignmentByFleetEquipmentsId } from "../../../store/FLEET/AssignmentRoutes/assignmentroutesSlice";
import Swal from "sweetalert2";

const AssignmentRoutes = () => {
  const { Id, equipmentRoutesID } = useParams();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { dataEmployees, loading: personLoading } = useGetPersons();

  const { AssignmentByFleetEquipmentsId: data } = useAppSelector(
    (state) => state.AssignmentRoutes
  );

  const formik = useFormik({
    initialValues: {
      fleetEquipmentsId: Id,
      fleetEquipmentsRouteId: equipmentRoutesID,
      personId: "",
    },
    // validationSchema: AddWorkShopFLeetSchema(t),
    onSubmit: (values) => {
      if (
        data?.persons
          ?.filter((f) => f.fleetEquipmentsRouteId === equipmentRoutesID)
          ?.some((s) => s.personId === values.personId)
      ) {
        Swal.fire("تم اضافة هذا الموظف مسبقاً");
        formik.setSubmitting(false);
        return;
      }

      dispatch(
        AddRoutesAssignment({
          fleetEquipmentsId: Id,
          fleetEquipmentsRouteId: equipmentRoutesID,
          personId: values.personId,
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: "Add" });
            dispatch(GetAssignmentByFleetEquipmentsId(Id || ""));
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  //____________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Equipment",
      link: "/FLEET/Equipments",
    },
    {
      name: "BookingSeats",
      link: null,
    },
  ];
  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="BookingSeats" />

      <Card>
        <CardBody>
          <div className="row">
            <div className="col-9">
              <Form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-xl-9 mx-auto ">
                    <div className="form-group mb-3">
                      <label className="form-label">اسم الموظف</label>

                      <Select
                        className="text-center"
                        classNamePrefix="react-select"
                        isLoading={personLoading}
                        isSearchable={true}
                        isClearable
                        name="personId"
                        options={dataEmployees}
                        onChange={(option: any) => {
                          formik.setFieldValue(
                            "personId",
                            option === null ? null : option.value
                          );
                        }}
                        placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                        required
                      />

                      {formik.errors.personId && formik.touched.personId && (
                        <div style={{ color: "red" }}>
                          {formik.errors.personId}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-2 auto" style={{ paddingTop: "28px" }}>
                    <button
                      disabled={formik.isSubmitting}
                      type="submit"
                      className="btn me-1 btn-outline-theme"
                    >
                      {formik.isSubmitting ? (
                        <div className="spinner-border spinner-border-sm me-2"></div>
                      ) : (
                        <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                      )}
                      {t("Add")}
                    </button>
                  </div>
                </div>
              </Form>
            </div>

            <div className="col-3">
              <div className="table-responsive text-center">
                <table className="table table-bordered bg-inverse bg-opacity-10">
                  <thead className="">
                    <tr className="bg-inverse bg-opacity-15">
                      <th>عدد المقاعد</th>
                      <th>عدد الموظفين</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="align-middle">
                        <h3>{data?.equipmentsSets}</h3>
                      </td>
                      <td className="align-middle">
                        <h3>
                          {
                            data?.persons?.filter(
                              (f) =>
                                f.fleetEquipmentsRouteId === equipmentRoutesID
                            )?.length
                          }
                        </h3>
                      </td>
                    </tr>
                    {/* <tr>
                      <td colSpan={2} className="text-center">
                        <h3>Your Content Here</h3>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <AssignmentRoutesList />
        </CardBody>
      </Card>
    </div>
  );
};

export default AssignmentRoutes;
