import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetRotationPlan } from "../../store/HR/Raqeeb/raqeebSlice";
import { useTranslation } from "react-i18next";

const useGetRotationPlan = () => {
  const dispatch = useDispatch();
  const {i18n} = useTranslation();

  const { RotationPlanList, loading, error } = useSelector(
    (state) => state.Raqeeb
  );

  useEffect(() => {
    if (RotationPlanList?.length === 0) {
      dispatch(GetRotationPlan());
    }
  }, []);


  const RotationPlanOptions =
  RotationPlanList &&
  RotationPlanList?.map((item) => ({
    value: item.id,
    label: i18n.language === "en" ? item.name : item.name2,
  }));


  return { RotationPlanList, RotationPlanOptions , loading, error };
};

export default useGetRotationPlan;
