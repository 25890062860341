
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";





export const GetResponsibility = createAsyncThunk("Responsibility/GetResponsibility", async (_, thunkAPI) => {
  const { rejectWithValue   } = thunkAPI;

  try {
    // const data = await getData("Security/GetAuthResponsibilityList");
    const {data} = await ntxAPI.get("Security/GetAuthResponsibilityList" );
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});


 export const insertResponsibility = createAsyncThunk("Responsibility/insertResponsibility", async (item, thunkAPI) => {
  const { rejectWithValue   } = thunkAPI;

  try {
      // const data = await postData("Security/AddAuthResponsibility" , item);
      const {data} = await ntxAPI.post("Security/AddAuthResponsibility" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
}); 


export const GetResponsibilityById = createAsyncThunk("Responsibility/GetResponsibilityById", async (id, thunkAPI) => {
  const { rejectWithValue   } = thunkAPI;

  try {
    // const data = await getData(`Security/GetAuthResponsibilityById?Id=${id}`);
    const {data} = await ntxAPI.get( `Security/GetAuthResponsibilityById?Id=${id}`);
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

 export const UpdateResponsibility = createAsyncThunk("Responsibility/UpdateResponsibility", async (item, thunkAPI) => {
  const { rejectWithValue   } = thunkAPI;


  try {
    // const data = await postData("Security/UpdateAuthResponsibility" , item);
    const {data} = await ntxAPI.post("Security/UpdateAuthResponsibility" , item)
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
}); 



const initialState = { ResponsibilityList: [], loading: false, error: null , ResponsibilityById : [] };

const ResponsibilitySlice = createSlice({
  name: "Responsibility",
  initialState,
  reducers: { 
    cleanResponsibilityById: (state)=>{
      state.ResponsibilityById = [];
    }
  },
  extraReducers: (builder) => {
    // get all Users
    builder
      .addCase(GetResponsibility.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetResponsibility.fulfilled, (state, action) => {
        state.loading = false;
        state.ResponsibilityList = action.payload;
      })
      .addCase(GetResponsibility.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // insert
      .addCase(insertResponsibility.pending, (state) => {
        // state.loading = true;
        state.error = null;
      })
      .addCase(insertResponsibility.fulfilled, (state, action) => {
        // state.loading = false;
        state.ResponsibilityList.push(action.payload);
      })
      .addCase(insertResponsibility.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // get one Responsibility by id
      .addCase(GetResponsibilityById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetResponsibilityById.fulfilled, (state, action) => {
        state.loading = false;
        state.ResponsibilityById = action.payload;
      })
      .addCase(GetResponsibilityById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // edit
      .addCase(UpdateResponsibility.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdateResponsibility.fulfilled, (state, action) => {
        state.loading = false;
        state.ResponsibilityById = action.payload;
      })
      .addCase(UpdateResponsibility.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {cleanResponsibilityById} = ResponsibilitySlice.actions
export default ResponsibilitySlice.reducer;
