import React, { useEffect, useMemo, useState } from "react";
// import { useDispatch } from "react-redux";
import { ntxAPI } from "../../API/axiosNeatex";
// import { useAppDispatch } from "../../store/hooks";
import DoughnutChart from "./ChartJS/DoughnutChart";
// import { Card } from "../../components/card/card";
import RadialBarChart from "./ApexchartsJS/RadialBarChart";
import PieChart from "./ChartJS/PieChart";
// import PieChartApex from "./ApexchartsJS/PieChart";
import BarChart from "./ChartJS/BarChart";
import AreaChart from "./ApexchartsJS/AreaChart";
import ColumnChart from "./ApexchartsJS/ColumnChart";
import RadarChart from "./ChartJS/RadarChart";
import RadarChartApex from "./ApexchartsJS/RadarChart";
import LineChart from "./ChartJS/LineChart";

interface Props {
  chartId: string;
  DataChartReport?: any[];
  chartDateFrom?: string;
  chartDateTo?: string;
}

const Index: React.FC<Props> = ({
  chartId,
  DataChartReport,
  chartDateFrom,
  chartDateTo,
}) => {
  const [chartdata, setChartdata] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      setIsError(false);

      try {
        const { data } = await ntxAPI.post("Reports/GetReportsChartData", {
          reportChartId: chartId,
          parameterList: [],
          chartDateFrom: chartDateFrom || null,
          chartDateTo: chartDateTo || null,
        });

        if (data?.succeeded === false) {
          setIsError(true);
        } else {
          setChartdata(data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [chartId, chartDateFrom, chartDateTo]);

  const chart = useMemo(() => {
    if (isLoading) {
      return (
        <div className="text-center h-100" style={{ paddingTop: "7rem" }}>
          <div className="spinner-grow text-success"></div>
          <div className="spinner-grow text-danger"></div>
          <div className="spinner-grow text-warning"></div>
        </div>
      );
    }

    if (isError) {
      return (
        <div className="text-center h-100" style={{ paddingTop: "7rem" }}>
          <div className="text-theme">Error fetching data.</div>;
        </div>
      );
    }

    if (chartdata?.chartType === "donut") {
      return <DoughnutChart data={chartdata} />;
      // return <Dou data={chartdata} />;
    }
    if (chartdata?.chartType === "pie") {
      return <PieChart data={chartdata} />;
      // return <PieChartApex data={chartdata} />;
    }
    if (chartdata?.chartType === "radialBar") {
      return <RadialBarChart data={chartdata} />;
    }
    if (chartdata?.chartType === "bar") {
      return <BarChart data={chartdata} />;
    }

    if (chartdata?.chartType === "area") {
      return <AreaChart data={chartdata} />;
    }

    if (chartdata?.chartType === "column") {
      return <ColumnChart data={chartdata} />;
    }

      if (chartdata?.chartType === "radar") {
        // return <RadarChart data={chartdata} />;
        return <RadarChartApex data={chartdata} />;
      }

    if (chartdata?.chartType === "line") {
      return <LineChart data={chartdata} />;
    }

    return null;
  }, [isLoading, isError, chartdata]);

  return <>{chart}</>;
};

export default Index;
