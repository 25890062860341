import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { GetGlChartOfAccountsDetailsList } from '../../../store/Finance/ChartAccounts/ChartAccountsDetailsSlice';
import { useTranslation } from 'react-i18next';

const useGetBranches = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ChartAccountsDetailsList?.length === 0) {
      dispatch(GetGlChartOfAccountsDetailsList());
    }
  }, []);
  const { ChartAccountsDetailsList, loading, error } = useAppSelector(
    (state) => state.ChartAccountsDetails
  );

  var Branches = ChartAccountsDetailsList.filter((f) => f.accountType === 'Branch');

  //___ Options _____
  var OptionsBranches = Branches.map(({ id, name, name2, accountNumber, accountParant }) => ({
    value: id,
    label: `${i18n.language === 'en' ? name : name2} | ${accountNumber}`,
    accountParant: accountParant,
    accountNumber: accountNumber,
    accountShow: (
      <div className="d-flex justify-content-between">
        <div>{i18n.language === 'en' ? name : name2}</div>
        <div  className='text-theme ms-2'>{accountNumber}</div>
      </div>
    ),
  }));

  //#region  // ___Start TreeSelect branches _____
  const buildBranchesTree = (list: any, parentId: any) => {
    return list
      .filter((item: any) => item.accountParant === parentId)
      .map((item: any) => ({
        title: i18n.language === 'en' ? item.name : item.name2,
        value: item.id,
        disabled: item.accountType === 'Company', // تعطيل خيار الشركات
        children: buildBranchesTree(list, item.id),
      }));
  };
  const TreeBranches = buildBranchesTree(
    ChartAccountsDetailsList,
    '69b480f1-f40b-4e77-9e66-9b75d98395a7'
  );
  //#endregion

  //_______
  return {
    ChartAccountsDetailsList,
    Branches,
    loading,
    error,
    OptionsBranches,
    TreeBranches,
  };
};

export default useGetBranches;
