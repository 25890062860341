
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { EquipmentsWorkRequest } from "./equipmentsWorkRequestModels";





  export const AddEquipmentsWorkRequests = createAsyncThunk('EquipmentsWorkRequest/AddEquipmentsWorkRequests',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Fleet/AddEquipmentsWorkRequests" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });
  


export const GetEquipmentsWorkRequestsList = createAsyncThunk(
    "EquipmentsWorkRequest/GetEquipmentsWorkRequestsList",
    async (_, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get('Fleet/GetEquipmentsWorkRequestsList');
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );




  
  export const UpdatEquipmentsWorkRequests = createAsyncThunk('EquipmentsWorkRequest/UpdatEquipmentsWorkRequests',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Fleet/UpdatEquipmentsWorkRequests" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });


export const GetEquipmentsWorkRequestsById = createAsyncThunk(
    "EquipmentsWorkRequest/GetEquipmentsWorkRequestsById",
    async (id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get(`Fleet/GetEquipmentsWorkRequestsById?Id=${id}`);
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );




  




   
const initialState: {
    EquipmentsWorkRequestsList : EquipmentsWorkRequest[]
    EquipmentsWorkRequestById? : EquipmentsWorkRequest
    loading: boolean;
    error: any | null;
  } = {
    EquipmentsWorkRequestsList: [],
    loading: false,
    error: null,

  };



  

const equipmentsWorkRequestSlice = createSlice({
    name: "EquipmentsWorkRequest",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder

      .addCase(AddEquipmentsWorkRequests.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(AddEquipmentsWorkRequests.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.EquipmentsWorkRequestsList.push(action.payload.data); 
      })
      .addCase(AddEquipmentsWorkRequests.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


    .addCase(GetEquipmentsWorkRequestsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetEquipmentsWorkRequestsList.fulfilled, (state, action: PayloadAction<any> ) => {
        state.loading = false;
        state.EquipmentsWorkRequestsList = action.payload;
      })
      .addCase(GetEquipmentsWorkRequestsList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


    .addCase(GetEquipmentsWorkRequestsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetEquipmentsWorkRequestsById.fulfilled, (state, action: PayloadAction<any> ) => {
        state.loading = false;
        state.EquipmentsWorkRequestById = action.payload;
      })
      .addCase(GetEquipmentsWorkRequestsById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })



      
    },
  });
  
  export default equipmentsWorkRequestSlice.reducer;