import { useFormik } from 'formik';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
// import { TransactionsLinesList } from '../../../../store/Sales/SalesInvoices/SalesInvoicesModels';
import { Card, CardFooter } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
// import useGetPersons from '../../../../hooks/HR/use-get-persons';
import useGetOrganizations from '../../../../hooks/HR/use-get-organizations';
import PurchaseLines from '../TransactionsLines/PurchaseLines';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch } from '../../../../store/hooks';
import useGetPeoplesList from '../../../../hooks/NTX/useGetPeoplesList';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import { AddQuoteInvoiceSchema } from '../../../ValidationForm/validationSchema';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
// import { useContext, useEffect } from 'react';
// import { AppSettings } from '../../../../config/app-settings';
import { addPurchaseQuotation } from '../../../../store/purchases/PurchaseQuotation/purchaseQuotationSlice';
import useGetPurchaseRequestById from '../../../../hooks/PUR/PurchaseRequest/useGetPurchaseRequestById';
import useGetPaymentMethod from '../../../../hooks/Payrolls/use-Get-PaymentMethod';
import useGetSuppliersList from '../../../../hooks/NTX/Peoples/useGetSuppliersList';
import { TreeSelect } from 'antd';

const Add = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const { Currency, loading: loading4 } = useLookupCurrency();
  // const { dataEmployees, loading } = useGetPersons();
  const { PaymentMethodList } = useGetPaymentMethod();
  const { BranchsOptions, InventoryOptions, loading: loading3 } = useGetOrganizations();
  const { treeSuppliersData, SuppliersList, loading: loading2 } = useGetSuppliersList();
  const { id } = useParams();
  const { PurchaseRequestsById: data } = useGetPurchaseRequestById({ id: id }); // طلب شراء

  //#region Formik
  const formik = useFormik({
    initialValues: {
      isAme: true,
      organizationId: data.organizationId ?? '', // معرف المنظمة التي تطلب عرض السعر.
      peopleId: data.peopleId ?? '', //  : يمثل معرف المورد أو الشخص المسؤول عن تزويد البضائع أو الخدمات.
      recipientname: data.recipientname ?? '', // يمكن أن يكون اسم المورد أو الشخص الذي سيتلقى المعاملة.
      billToLocationId: data.billToLocationId ?? null,
      shipToLocationId: data.shipToLocationId ?? null,
      referenceId: data.id ?? null, //  معرف مرجعي للربط بين عروض الأسعار المختلفة.
      transactionDate: new Date().toISOString().split('T')[0], // : تاريخ طلب عرض السعر.
      deliveryDate: data.deliveryDate ?? '',
      expirationDate: data.expirationDate ?? '', //  تاريخ انتهاء صلاحية عرض السعر.
      dueDate: data.dueDate ?? '', // تاريخ الاستحقاق المتوقع (في حال كانت هناك مدة معينة للعرض).
      paymentTermsId: data.paymentTermsId ?? '', //  معرف شروط الدفع المقترحة.
      description: data.description ?? '',
      grossAmount: data.grossAmount ?? 0, // : إجمالي المبلغ المقدر.
      netAmount: data.netAmount ?? 0, // : المبلغ الصافي بعد الخصومات والضرائب.
      discountRate: data.discountRate ?? '', // نسبة الخصم المقدمة في العرض.
      discountAmount: data.discountAmount ?? '',
      vat: data.vat ?? '',
      contractId: data.contractId ?? '',
      ameStatus: data.ameStatus ?? '',
      personId: data.personId ?? '',
      paymentTerms: data.paymentTerms ?? '',
      deliveryTerm: data.deliveryDate ?? '',
      inventoryId: data.inventoryId ?? '', //
      waybill: data.waybill ?? '', //
      phone: data.phone ?? '', //
      currencyCode: data.currencyCode ?? 'SR', //
      currencyConversionRate: data.currencyConversionRate ?? '', //
      batchId: data.batchId ?? '', //
      paymentMethodId: data.paymentMethodId ?? '',
      categoryId: data.categoryId ?? null,
      transactionsLinesList:
        data?.transactionsLinesList?.map((item) => ({
          id: item.id,
          // transactionId: item.transactionId,
          itemId: item.itemId,
          lineNumber: item.lineNumber,
          uom: item.uom,
          description: item.description,
          price: item.price,
          qty: item.qty,
          discountRate: item.discountRate,
          discountAmount: item.discountAmount,
          vatRate: item.vatRate,
          total: item.total,
          vatAmount: item.vatAmount,
          // unitCost: item.unitCost,
          // codeCombinationId: item.codeCombinationId,
          // currencyCode: item.currencyCode,
          // currencyConversionRate: item.currencyConversionRate,
          // lotId: item.lotId,
          // headerId: item.headerId,
          // lineId: item.lineId,
          inventoryId: item.inventoryId,
        })) || [],

      // transactionsLinesList: [
      //   {
      //     description: '',
      //     discountAmount: 0,
      //     discountRate: 0,
      //     itemId: '',
      //     lineNumber: 0,
      //     price: 0,
      //     qty: 1,
      //     total: 0,
      //     uom: '',
      //     vatAmount: 0,
      //     vatRate: 0,
      //   },
      // ] as TransactionsLinesList[],
    },

    enableReinitialize: true,
    // validationSchema: AddQuoteInvoiceSchema(t),
    onSubmit: (values) => {
      // const { transactionsLinesList, ...restValues } = values;

      if (values.transactionsLinesList.length === 0) {
        SwalAlert({ text: 'أدخل أصناف' });
        formik.setSubmitting(false);
        return;
      }

      dispatch(
        addPurchaseQuotation({
          // no: values.no,
          // code: values.code + values.no, // Qut-24-1233
          // name: values.name,
          // name2: values.name2,
          // transTypeId: values.transTypeId,

          isAme: false,
          categoryId: null,

          organizationId: values.organizationId, // معرف المنظمة التي تطلب عرض السعر.
          peopleId: values.peopleId,
          recipientname: values.recipientname,
          billToLocationId: billToLocationId,
          shipToLocationId: shipToLocationId,
          referenceId: values.referenceId,
          transactionDate: values.transactionDate,
          deliveryDate: values.deliveryDate || null,
          expirationDate: values.expirationDate || null,
          dueDate: values.dueDate || null,
          paymentTermsId: paymentTermsId,
          paymentMethodId: values.paymentMethodId || null,
          description: values.description,
          grossAmount: 0,
          discountRate: values.discountRate || 0,
          discountAmount: values.discountAmount || 0,
          vat: values.vat || 0,
          netAmount: 0,
          contractId: values.contractId || null,
          ameStatus: values.ameStatus || 0,
          personId: values.personId || null,
          paymentTerms: values.paymentTerms,
          deliveryTerm: values.deliveryTerm,
          inventoryId: values.inventoryId,
          waybill: values.waybill,
          phone: values.phone,
          currencyCode: values.currencyCode,
          currencyConversionRate: values.currencyConversionRate || 0,
          batchId: values.batchId || null,
          transactionsLinesList: formik.values.transactionsLinesList.map((item) => ({
            itemId: item.itemId,
            lineNumber: item.lineNumber,
            uom: item.uom,
            description: item.description,
            price: Number(item.price),
            qty: Number(item.qty),
            discountRate: Number(item.discountRate),
            discountAmount: Number(item.discountAmount),
            vatRate: Number(item.vatRate),
            total: Number(item.total),
            vatAmount: Number(item.vatAmount),
            // unitCost: Number(item.unitCost),
            codeCombinationId: '54F915DF-8A22-486D-B860-08DC2A376B50',
            // currencyCode: 'SR',
            // currencyConversionRate: 0,
            // lotId: null,
            // headerId: null,
            // lineId: null,
            inventoryId: values.inventoryId,
          })),
        })
      )
        .unwrap()
        .then((res: any) => {
          // debugger;
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched } = formik;
  //#endregion

  const paymentTermsId = SuppliersList.find((f) => f.id === values.peopleId)?.termsId;
  const shipToLocationId = SuppliersList.find((f) => f.id === values.peopleId)?.shipToLocationId;
  const billToLocationId = SuppliersList.find((f) => f.id === values.peopleId)?.billToLocationId;

  //#region BcrumbList
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'عروض الأسعـار',
      link: '/PurchasesTransactions/Quotation',
    },
    {
      name: 'عرض سعـر جديد',
      link: null,
    },
  ];
  //#endregion

  // const context = useContext(AppSettings);
  // useEffect(() => {
  //   context.setAppTopNav(true);
  //   context.setAppSidebarNone(true);

  //   return function cleanUp() {
  //     context.setAppTopNav(false);
  //     context.setAppSidebarNone(false);
  //   };

  //   // eslint-disable-next-line
  // }, []);

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="عرض سعـر جديد" />
        {Object.keys(data).length > 0 && (
          <div className="d-flex p-0 m-0 bg-theme bg-opacity-15 rounded-0 p-1">
            رقم المرجع : {data.code}
          </div>
        )}
      </div>

      <Card>
        <Loading loading={loading2 || loading2 || loading3}>
          <CardBody>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('رقم الطلب')}</label>
                    <Form.Control
                      disabled
                      className="form-control text-center border border-theme"
                      type="text"
                      // name="transactionDate"
                      value={data.code}
                      // isInvalid={!!(touched.transactionDate && errors.transactionDate)}
                    />
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('تاريخ الطلب')}</label>
                    <Form.Control
                    disabled
                      className="form-control text-center border border-theme"
                      type="date"
                      value={data.transactionDate?.toString()?.split('T')[0] || ''}
                      // isInvalid={!!(touched.transactionDate && errors.transactionDate)}
                    />
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('تاريخ العرض')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="date"
                      name="transactionDate"
                      onChange={formikhandleChange}
                      value={values.transactionDate || ''}
                      // isInvalid={!!(touched.transactionDate && errors.transactionDate)}
                    />

                    <div className="text-danger">{formik.errors.transactionDate}</div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('تاريخ الإنتهاء')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="date"
                      name="expirationDate"
                      onChange={formikhandleChange}
                      value={values.expirationDate || ''}
                      // isInvalid={!!(touched.expirationDate && errors.expirationDate)}
                    />
                    <div className="text-danger">{formik.errors.expirationDate}</div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('المخزن')}</label>

                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading3}
                      isSearchable={true}
                      isClearable
                      options={InventoryOptions}
                      value={InventoryOptions.find(
                        (f: any) => f.value === formik.values.inventoryId
                      )}
                      onChange={(option: any) => {
                        formik.setFieldValue('inventoryId', option === null ? null : option.value);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger">{formik.errors.inventoryId}</div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الفرع')}</label>

                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading3}
                      isSearchable={true}
                      isClearable
                      options={BranchsOptions}
                      value={BranchsOptions.find(
                        (f: any) => f.value === formik.values.organizationId
                      )}
                      onChange={(option: any) => {
                        formik.setFieldValue(
                          'organizationId',
                          option === null ? null : option.value
                        );
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger">{formik.errors.organizationId}</div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('المورد')}</label>

                    <TreeSelect
                      showSearch
                      // value={value}
                      className="w-100"
                      treeLine
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      allowClear
                      // treeDefaultExpandAll
                      value={formik.values.peopleId}
                      onChange={(option: any) => {
                        formik.setFieldValue('peopleId', option === null ? null : option);
                      }}
                      treeData={treeSuppliersData}
                      treeNodeFilterProp="title"
                    />
                    <div className="text-danger">{formik.errors.peopleId}</div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('PaymentMethod')}</label>
                    <Form.Select
                      className="form-select"
                      name="paymentMethodId"
                      onChange={formik.handleChange}
                      value={formik.values.paymentMethodId}
                      isInvalid={!!formik.errors.paymentMethodId}>
                      <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PaymentMethodList.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {/* {formik.errors.TITLE} */}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('مدة التوريد')}</label>
                    <Form.Control
                      className="form-control"
                      as="textarea"
                      rows={1}
                      name="deliveryTerm"
                      onChange={formik.handleChange}
                      value={values.deliveryTerm}
                      isInvalid={!!(touched.deliveryTerm && errors.deliveryTerm)}
                    />
                  </div>
                </div> */}

                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Note')}</label>
                    <Form.Control
                      className="form-control"
                      as="textarea"
                      rows={2}
                      name="description"
                      onChange={formik.handleChange}
                      value={values.description}
                      isInvalid={!!(touched.description && errors.description)}
                      // isValid={touched.description && !errors.description}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الشروط')}</label>
                    <Form.Control
                      className="form-control"
                      as="textarea"
                      rows={2}
                      name="paymentTerms"
                      onChange={formik.handleChange}
                      value={values.paymentTerms}
                      // isInvalid={!!(touched.paymentTerms && errors.paymentTerms)}
                      // isValid={touched.paymentTerms && !errors.paymentTerms}
                    />
                  </div>
                </div>
              </div>

              <CardFooter>
                <div className="row  position-relative">
                  <PurchaseLines formik={formik} />
                  {formik.errors.transactionsLinesList?.length && (
                    <div className="text-danger"> * هناك حقول مطلوبة </div>
                  )}
                </div>
              </CardFooter>

              <div className="text-center mt-2">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn btn-lg me-1 btn-theme mb-1">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                  )}
                  {t('Add')}
                </button>
                <button
                  type="button"
                  className=" btn btn-lg me-1 btn-default mb-1"
                  onClick={() => navigate('/PurchasesTransactions/Quotation', { replace: true })}>
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </Form>
          </CardBody>
        </Loading>
      </Card>
    </>
  );
};

export default Add;
