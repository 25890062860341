import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { GetReportsList } from '../../store/NTX/Reports/reportSlice';
interface Report {
  id: string;
  name: string;
  name2: string;
  moduleId: string;
  tableName: string;
  query: string;
  description: string;
  pageType: string;
  reportType: string;
}

interface ReportsListState {
  ReportsList: Report[];
  loading: boolean;
  // Add any other properties you have in the ReportsList
}

const useGetReportsList = () => {
  const dispatch = useAppDispatch();
  const { ReportsList, loading } = useAppSelector((state) => state.Reports) as ReportsListState;
  useEffect(() => {
    if (ReportsList.length === 0) {
      dispatch(GetReportsList());
    }
  }, [ReportsList, dispatch]);

  const PrintTemplatesList = ReportsList.filter(f=> f.reportType === "")

  return { ReportsList, loading };
};

export default useGetReportsList;
