import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody } from '../../../components/card/card';
import { GetFormColumn } from '../../../store/NTX/FlexValue/Form/FormColumnSlice';
import { insertAmeCondition } from '../../../store/Ame/ameSlice';
import ConditionList from './ConditionList';
import * as Yup from 'yup';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';
import { GetTableData } from '../../../store/Lookup/lookupSlice';
import { ntxAPI } from '../../../API/axiosNeatex';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import { usePermissions } from '../../../Helper/Permisstions/usePermissions';
import Loading from '../../../components/Shared/Loader/Loading';
// import { unwrapResult } from '@reduxjs/toolkit';
import { getOrganizations } from '../../../store/HR/Organizations/organizationSlice';
import { getLocations } from '../../../store/HR/HRSetting/locationSlice';
import { getGrades } from '../../../store/HR/HRSetting/jobgradeSlice';
import { getPositions } from '../../../store/HR/HRSetting/positionSlice';
import { getGroupEmp } from '../../../store/HR/HRSetting/groupEmpSlice';
import { getJobs } from '../../../store/HR/HRSetting/jobSlice';
import { getPayrolls } from '../../../store/HR/Payroll/PayrollSlice';

// valiedation scheme
const validationSchema = Yup.object().shape({
  name2: Yup.string().required(),
  name: Yup.string().required(),
  Condition: Yup.string().required(),
  // dynamicFormColumnID: Yup.string().required(() => 'مطلوب'),
  dynamicFormColumnID: Yup.string().when('conditionType', {
    is: (val) => val === 'STATIC',
    then: () => Yup.string().notRequired(),
    // otherwise: Yup.string().nullable(),
  }),

  multiSelect: Yup.string().when(['formatType', 'conditionType'], {
    is: (formatType, conditionType) =>
      formatType === 'T' || formatType === 'D' || conditionType === 'STATIC',
    then: () => Yup.string().required(() => '*'),
  }),

  includeLowerLimit: Yup.string().when(['validationType', 'conditionType'], {
    is: (val, val2) => (val === 'TIME' || val === 'DATE' || val === 'NUMBER') && val2 !== 'STATIC',
    then: () => Yup.string().required(() => 'مطلوب'),
  }),

  param1: Yup.string().when('includeLowerLimit', {
    is: (val) => val,
    then: () => Yup.string().required(() => 'مطلوب'),
    otherwise:  () => Yup.string().notRequired(),
  }),
});

const AddAmeCondition = () => {


  //#region constats
  const { HasPermission } = usePermissions();
  const [dynamicData, setDynamicData] = useState([]);
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { FormColumnList, loading, error } = useSelector((state) => state.FormColumns);
  const filteredData = FormColumnList?.columnList?.filter(
    (item) => !['TEXT', 'ATTACHED', 'NOTE'].includes(item.validationType) && item?.enabledFlag
  );
  const groupedOptions = [
    {
      label: 'حقول الطلب',
      options: filteredData?.map((m) => ({
        value: m.id,
        label: i18n.language === 'ar' ? m.lable2 : m.lable,
        flexValueSetId: m.flexValueSetId,
        validationType: m.validationType,
        formatType: m.formatType,
        tableId: m.tableId,
        staticType: 'Dynamic',
      })),
    },
    {
      label: 'بيانات صاحب الطلب',
      options: [
        {
          value: 'DEPARTMENT',
          label: i18n.language === 'en' ? 'Department' : 'الإدارة',
          staticType: 'Static',
        },
        {
          value: 'LOCATION',
          label: i18n.language === 'en' ? 'Location' : 'الموقع',
          staticType: 'Static',
        },
        {
          value: 'GRADE',
          label: i18n.language === 'en' ? 'Grade' : 'الدرجة الوظيفية',
          staticType: 'Static',
        },
        { value: 'JOB', label: i18n.language === 'en' ? 'JOB' : 'الوظيفة', staticType: 'Static' },
        {
          value: 'POSITION',
          label: i18n.language === 'en' ? 'Position' : 'المنصب',
          staticType: 'Static',
        },
        {
          value: 'GROUP',
          label: i18n.language === 'en' ? 'Group' : 'مجموعة الموظفين',
          staticType: 'Static',
        },
        {
          value: 'PAYROLL',
          label: i18n.language === 'en' ? 'Payroll' : 'الراتب',
          staticType: 'Static',
        },
      ],
    },
  ];

  useEffect(() => {
    if (id) {
      dispatch(GetFormColumn({ id, withStatic: true }));
    }
  }, [dispatch, id]);

  //#endregion


  //#region  formik
  const formik = useFormik({
    initialValues: {
      formatType: null, // for handle with show and hide elements only and validation Dynamic
      validationType: null, // for handle with show and hide elements only and validation Dynamic

      CheckBoolean: false,
      multiSelect: '',
      param1: '',
      param2: '',
      Condition: '', // not for post just check validation
      //
      name: '',
      name2: '',
      includeLowerLimit: '',
      includeUpperLimit: '',
      valueType: '',
      parameterOne: '',
      parameterTwo: '',
      description: '',
      dynamicFormId: '',
      dynamicFormColumnID: '',
      //
      conditionType: '', // STATIC  // DYNAMIC
      staticType: '', // POSITION  LOCATION DEPARTMENT &&  null
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(
        insertAmeCondition({
          name: values.name,
          name2: values.name2,
          includeLowerLimit: values.includeLowerLimit || 'E',
          includeUpperLimit: values.includeUpperLimit || null,
          valueType: values.formatType, //  اذا كانت null  ماذا نحفظ

          parameterOne:
            (values.formatType === 'D' || values.conditionType === 'STATIC') &
            (values.validationType === null)
              ? values.multiSelect
              : (values.formatType === 'S') & (values.validationType === 'BOOLEAN')
              ? values.CheckBoolean === true
                ? '1'
                : '0'
              : values.param1.toString(),

          parameterTwo: values.includeUpperLimit === '' ? '' : values.param2.toString(),

          description: '', //
          dynamicFormId: id,
          dynamicFormColumnID:
            values.conditionType === 'STATIC' ? null : values.dynamicFormColumnID,
          conditionType: values.conditionType,
          staticType: values.staticType,
        })
      )
        .unwrap()
        .then(() => {
          resetForm();
          CustomAlert({ action: 'Add' });
        })
        .catch((error) => {
          formik.setSubmitting(false);
          console.log(error);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, errors, setFieldValue, touched, handleChange, isSubmitting } = formik;

  console.log(values);
  console.log(errors);
  //#endregion



  //#region functions

  async function handleSelectChange(option) {
    console.log(option);
    const { staticType, validationType, formatType, flexValueSetId, tableId, value } = option;
setFieldValue('Condition', option.value);
    setFieldValue('conditionType', staticType === 'Dynamic' ? 'DYNAMIC' : 'STATIC');

    setFieldValue('multiSelect', '');
    if (staticType === 'Dynamic') {
      setFieldValue('staticType', null);
      setFieldValue('validationType', validationType || null);
      setFieldValue('formatType', formatType || null);
      setFieldValue('dynamicFormColumnID', value);

      setDynamicData([]);
      await handleDynamicType(formatType, flexValueSetId, tableId);
    } else if (staticType === 'Static') {
      setFieldValue('dynamicFormColumnID', null);
      setFieldValue('validationType', null);
      setFieldValue('formatType', null);
      setFieldValue('staticType', value);

      setDynamicData([]);
      await handleStaticType(value);
    }
  }

  async function handleDynamicType(formatType, flexValueSetId, tableId) {
    try {
      let data;
      if (formatType === 'D') {
        const response = await ntxAPI.get(
          `FlexValueSet/GetFlexValueList?FlexValueSetId=${flexValueSetId}`
        );
        data = response.data;
      } else if (formatType === 'T') {
        const res = await dispatch(GetTableData(tableId)).unwrap();
        data = res;
      } else {
        setDynamicData([]);
        return;
      }

      const transformedData = data.map((item) => ({
        value: item.id,
        label: i18n.language === 'en' ? item.name : item.name2,
      }));
      setDynamicData(transformedData);
    } catch (error) {
      console.error('Error fetching dynamic data:', error);
    }
  }

  async function handleStaticType(value) {
    try {
      let data;
      switch (value) {
        case 'DEPARTMENT':
          data = await dispatch(getOrganizations()).unwrap();
          data = data?.filter((f) => f.organizationInformation === 'HR_ORG');
          break;
        case 'LOCATION':
          data = await dispatch(getLocations()).unwrap();
          break;
        case 'GRADE':
          data = await dispatch(getGrades()).unwrap();
          break;
        case 'POSITION':
          data = await dispatch(getPositions()).unwrap();
          break;
        case 'GROUP':
          data = await dispatch(getGroupEmp()).unwrap();
          break;
        case 'JOB':
          data = await dispatch(getJobs()).unwrap();
          break;
        case 'PAYROLL':
          data = await dispatch(getPayrolls()).unwrap();
          break;
        default:
          setDynamicData([]);
          return;
      }

      const transformedData = data.map((item) => ({
        value: item.id,
        label: i18n.language === 'en' ? item.name : item.name2,
      }));
      setDynamicData(transformedData);
    } catch (error) {
      console.error('Error fetching static data:', error);
    }
  }
  function GetLabelName() {
    var lableName = '';
    switch (values.staticType) {
      case 'DEPARTMENT':
        lableName = t('Departments');
        break;
      case 'LOCATION':
        lableName = t('Locations');
        break;
      case 'GRADE':
        lableName = t('Grade');
        break;
      case 'POSITION':
        lableName = t('Positions');
        break;
      case 'GROUP':
        lableName = t('Staffgroup');
        break;
      case 'JOB':
        lableName = t('Jobs');
        break;
      case 'PAYROLL':
        lableName = t('Payroll');
        break;
      default:
        lableName = t('...');
        return;
    }

    return lableName;
  }
  function GetLabelName2() {
    var lableName = '';
    var columnList = FormColumnList?.columnList;

    switch (values.formatType) {
      case 'D':
        lableName = columnList?.find((f) => f.id === values.dynamicFormColumnID).lable2;
        break;
      case 'T':
        lableName = columnList?.find((f) => f.id === values.dynamicFormColumnID).lable2;
        break;
      default:
        lableName = t('.');
        return;
    }

    return lableName;
  }

  const handleMultiSelectChange = (selectedOptions) => {
    const formattedOptions = selectedOptions.map((option) => option.value).join(',');
    setFieldValue('multiSelect', formattedOptions);
  };
  //#endregion


  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('RequestsSettings'),
      link: '/Ame/RequestSetting',
    },
    {
      name: t('Conditions'),
      link: null,
    },
  ];
  return (
    <>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName={t('Conditions')}
        subtitle={
          (i18n.language === 'ar'
            ? FormColumnList.dynamicFormName2
            : FormColumnList.dynamicFormName) || ''
        }
      />

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody className="p-4">
            {HasPermission('AmeAddConditionsOrder') && (
              <Form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-xl-3">
                    <div className="form-group mb-3">
                      <label className="form-label">{t('LocalName')}</label>
                      <Form.Control
                        className="form-control"
                        type="text"
                        name="name2"
                        onChange={handleChange}
                        value={values.name2 || ''}
                        isInvalid={touched.name2 && errors.name2}
                      />
                    </div>
                  </div>

                  <div className="col-xl-3">
                    <div className="form-group mb-3">
                      <label className="form-label">{t('GlobalName')}</label>
                      <Form.Control
                        className="form-control"
                        type="text"
                        name="name"
                        onChange={handleChange}
                        value={values.name || ''}
                        isInvalid={touched.name && errors.name}
                      />
                    </div>
                  </div>

                  <div
                    className={
                      values.formatType === 'D' ||
                      values.formatType === 'T' ||
                      values.conditionType === 'STATIC' ||
                      values.validationType === 'BOOLEAN'
                        ? 'col-xl-3'
                        : 'col-xl-6'
                    }>
                    <div className="form-group mb-3">
                      <label className="form-label">{t('Condition')}</label>

                      <Select
                        classNamePrefix="react-select"
                        isLoading={loading}
                        isSearchable={true}
                        // isClearable
                        options={groupedOptions}
                        onChange={(option) => handleSelectChange(option)}
                        placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      />
                    </div>
                  </div>

                  <div className="col-xl-3">
                    {values.validationType === 'BOOLEAN' && (
                      <div className="form-group mb-3">
                        <label className="form-label">Boolean</label>
                        <Form.Check
                          style={{ margin: '10px 20px' }}
                          type="switch"
                          id="custom-switch"
                          label=""
                          name="CheckBoolean"
                          onChange={handleChange}
                          value={values.CheckBoolean}
                          isInvalid={touched.CheckBoolean && errors.CheckBoolean}
                        />
                      </div>
                    )}

                    {(values.formatType === 'D' ||
                      values.formatType === 'T' ||
                      values.conditionType === 'STATIC') && (
                      <div className="form-group mb-3">
                        <label className="form-label">{GetLabelName() ?? GetLabelName2()}</label>
                        <Select
                          isSearchable
                          isMulti
                          options={dynamicData}
                          classNamePrefix="react-select"
                          name="multiSelect"
                          value={
                            values.multiSelect?.split(',').map((value) => {
                              return dynamicData.find((option) => option.value === value);
                            }) || null
                          }
                          onChange={handleMultiSelectChange}
                          placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                        />
                        <div className="text-danger">
                          {touched.multiSelect && errors.multiSelect}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {values.validationType === 'NUMBER' ||
                values.validationType === 'DATE' ||
                values.validationType === 'TIME' ? (
                  <div className="row ">
                    <div className="col-xl-6">
                      <label className="form-label">{t('TheFirstVariable')}</label>

                      <div className="input-group flex-nowrap">
                        <span className="w-50">
                          <Form.Select
                            className="form-select text-center input-group-text"
                            name="includeLowerLimit"
                            onChange={handleChange}
                            value={values.includeLowerLimit}
                            isInvalid={!!errors.includeLowerLimit}>
                            <option key={null} value={null}>
                              {i18n.language === 'ar' ? 'إختر' : 'Choose'}
                            </option>
                            <option value="E">
                              {i18n.language === 'en' ? 'Is Equal' : 'يساوي'}
                            </option>
                            <option value="G">
                              {i18n.language === 'en' ? 'Greater Than' : 'أكبر من'}
                            </option>
                            <option value="EG">
                              {i18n.language === 'en'
                                ? 'Greater Than Or Equal'
                                : 'أكبر من أو يساوي'}
                            </option>
                          </Form.Select>
                        </span>
                        <Form.Control
                          className="form-control text-center"
                          type={
                            values.validationType === 'NUMBER'
                              ? 'number'
                              : values.validationType === 'DATE'
                              ? 'date'
                              : 'time'
                          }
                          name="param1"
                          onChange={handleChange}
                          value={values.param1}
                          isInvalid={!!(touched.param1 && errors.param1)}
                        />
                      </div>
                      <div className="text-danger">
                        {touched.includeLowerLimit && errors.includeLowerLimit}
                      </div>
                    </div>



                    <div className="col-xl-6">
                      <label className="form-label">{t('TheSecondVariable')}</label>

                      <div className="input-group flex-nowrap">
                        <span className="w-50">
                          <Form.Select
                            className="form-select text-center input-group-text"
                            name="includeUpperLimit"
                            onChange={handleChange}
                            // onChange={handleMultiSelectChange}
                            value={values.includeUpperLimit}
                            isInvalid={touched.includeLowerLimit && errors.includeUpperLimit}>
                            <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                            <option value="L">
                              {i18n.language === 'en' ? 'Less Than ' : 'أقل من'}
                            </option>
                            <option value="LE">
                              {i18n.language === 'en' ? 'Less Than Or Equal' : 'أقل من أو يساوي'}
                            </option>
                          </Form.Select>
                        </span>
                        <Form.Control
                          className="form-control text-center"
                          disabled={values.includeUpperLimit === ''}
                          type={
                            values.validationType === 'NUMBER'
                              ? 'number'
                              : values.validationType === 'DATE'
                              ? 'date'
                              : 'time'
                          }
                          name="param2"
                          onChange={handleChange}
                          value={values.param2}
                          isInvalid={touched.param2 && errors.param2}
                        />
                      </div>
                      <div className="text-danger">
                        {/* {touched.includeLowerLimit && errors.includeLowerLimit} */}
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="text-center mt-4">
                  <button
                    disabled={isSubmitting}
                    type="submit"
                    className="btn me-1 btn-theme mb-1"
                    id="SaveSubmit">
                    {isSubmitting ? (
                      <div className="spinner-border spinner-border-sm me-2"></div>
                    ) : (
                      <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                    )}
                    {t('Add')}
                  </button>

                  <button
                    type="button"
                    className=" btn me-1 btn-default mb-1"
                    onClick={() => Navigate(-1, { replace: true })}>
                    <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                  </button>
                </div>
              </Form>
            )}
            <div className="row">
              <div className="col-10 m-auto">
                {/* <div className=" bg-inverse bg-opacity-5 rounded-4 pt-1"> */}
                <div className=" ">
                  <ConditionList />
                </div>
              </div>
            </div>
          </CardBody>
        </Loading>
      </Card>
    </>
  );
};

export default AddAmeCondition;
