import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../API/axiosNeatex";



export const CreateRrealEstate = createAsyncThunk(
  "RealEstate/CreateRrealEstate",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const {data} = await ntxAPI.post( "RealState/AddRrealEstate" , item)
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetRealEstateList = createAsyncThunk("RealEstate/GetRealEstateList", async (_, thunkAPI) => {
  const { rejectWithValue   } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("RealState/GetRealEstateList" );
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

export const GetRealEstateById = createAsyncThunk("RealEstate/GetRealEstateById", async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const {data} = await ntxAPI.get( `RealState/GetRealEstateById?Id=${id}`);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const EditRrealEstate = createAsyncThunk(
  "RealEstate/EditRrealEstate",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("RealState/UpdatRealEstate" , item);
      const {data} = await ntxAPI.post("RealState/UpdatRealEstate" , item)
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetPersonListToAccommodationByRealEstateId = createAsyncThunk("RealEstate/GetPersonListToAccommodationByRealEstateId", async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const {data} = await ntxAPI.get( `RealState/GetPersonListToAccommodationByRealEstateId?RealEstateId=${id}`);
    return data.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


export const DeleteRealEstateAssignment = createAsyncThunk(
  "RealEstate/DeleteRealEstateAssignment",
  async (RealEstateAssignmentId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {

      const {data} = await ntxAPI.post(`RealState/DeleteRealEstateAssignment?RealEstateAssignmentId=${RealEstateAssignmentId}`)
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const AddRealEstateAssignment = createAsyncThunk(
  "RealEstate/AddRealEstateAssignment",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const {data} = await ntxAPI.post( "RealState/AddSuRealEstateAssignment" , item)
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);


const initialState = { RealEstateList: [] , RealEstateById: [] , ListAccommodation :[],loading: false, error: null };


const realEstateSlice = createSlice({
  name: "RealEstate",
  initialState,
  reducers: {  },
  extraReducers: (builder) => {
    builder
      // Add Real Estate
      .addCase(CreateRrealEstate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(CreateRrealEstate.fulfilled, (state, action) => {
        state.loading = false;
        state.RealEstateList.push(action.payload);
      })
      .addCase(CreateRrealEstate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // Get All Real Estates
      .addCase(GetRealEstateList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetRealEstateList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.RealEstateList = payload;
      })
      .addCase(GetRealEstateList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // Get Real Estate By Id
      .addCase(GetRealEstateById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.RealEstateById = [];
      })
      .addCase(GetRealEstateById.fulfilled, (state, action) => {
        state.loading = false;
        state.RealEstateById = action.payload;
      })
      .addCase(GetRealEstateById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // Edit Real Estate
      .addCase(EditRrealEstate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(EditRrealEstate.fulfilled, (state, action) => {
        state.loading = false;
        state.RealEstateById = action.payload;
      })
      .addCase(EditRrealEstate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // Get Person List To Accommodation By Real Estate Id
      .addCase(GetPersonListToAccommodationByRealEstateId.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.ListAccommodation = [];
      })
      .addCase(GetPersonListToAccommodationByRealEstateId.fulfilled, (state, action) => {
        state.loading = false;
        state.ListAccommodation = action.payload;
      })
      .addCase(GetPersonListToAccommodationByRealEstateId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default realEstateSlice.reducer;
