import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";

const initialState = {
  RotationPlanList: [],
  RotationPlanListById: [],
  ChartRaqResultList: [],
  MyFingerPrintByDateList: [], // for person as token by date
  ResultList: [], 
  ResultSummaryList: [], 
  loading: false,
  error: null,
};

export const AddRotationPlan = createAsyncThunk(
  "Raqeeb/AddRotationPlan",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.post("RAQ/AddRotationPlan", item);
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetRotationPlan = createAsyncThunk(
  "Raqeeb/GetRotationPlan",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.get("RAQ/GetRotationPlanList");
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetRotationPlanById = createAsyncThunk(
  "Raqeeb/GetRotationPlanById",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.get(`RAQ/GetRotationPlanById?Id=${id}`);
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const UpdateRotationPlan = createAsyncThunk(
  "Raqeeb/UpdateRotationPlan",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;


    try {
      const { data } = await ntxAPI.post("RAQ/UpdateRotationPlan", item);
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetRaqResultByOrganization = createAsyncThunk(
  "Raqeeb/GetRaqResultByOrganization",
  async ({ DateFrom, DateTo }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.get(
        `RAQ/GetRaqResultByOrganization?DateFrom=${DateFrom}&DateTo=${DateTo}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetMyFingerPrintByDate = createAsyncThunk(
  "Raqeeb/GetMyFingerPrintByDate",
  async ({ DateFrom, DateTo }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.get(
        `RAQ/GetMyFingerPrintByDate?DateFrom=${DateFrom}&DateTo=${DateTo}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);



export const GetResultList = createAsyncThunk(
  "Raqeeb/GetResultList",
  async ({ DateFrom, DateTo }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.get(
        `RAQ/GetResultList?DateFrom=${DateFrom}&DateTo=${DateTo}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);


export const GetResultSummaryList = createAsyncThunk(
  "Raqeeb/GetResultSummaryList",
  async ({ DateFrom, DateTo }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      debugger
      const { data } = await ntxAPI.get(
        `RAQ/GetResultSummaryList?DateFrom=${DateFrom}&DateTo=${DateTo}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);


export const UpdateAnalysis = createAsyncThunk(
  "Raqeeb/UpdateAnalysis",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

 
    try {
      const { data } = await ntxAPI.post("RAQ/AnalysisResults", item);
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);









const raqeebSlice = createSlice({
  name: "Raqeeb",
  initialState,
  reducers: { 
    clearState: (state) => {
      state.ChartRaqResultList = [];
      state.ResultList = [];
      state.ResultSummaryList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Rotation Plan List
      .addCase(GetRotationPlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetRotationPlan.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.RotationPlanList = payload;
      })
      .addCase(GetRotationPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // Get one Rotation plan by id
      .addCase(GetRotationPlanById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetRotationPlanById.fulfilled, (state, action) => {
        state.loading = false;
        state.RotationPlanListById = action.payload;
      })
      .addCase(GetRotationPlanById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      ///////insert
      .addCase(AddRotationPlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddRotationPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.RotationPlanList.push(action.payload);
      })
      .addCase(AddRotationPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // Edit
      .addCase(UpdateRotationPlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdateRotationPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.RotationPlanListById = action.payload;
      })
      .addCase(UpdateRotationPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // Get GetRaqResultByOrganization
      .addCase(GetRaqResultByOrganization.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetRaqResultByOrganization.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.succeeded === true) {
          state.ChartRaqResultList = payload.data;
        }
      })
      .addCase(GetRaqResultByOrganization.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // Get GetMyFingerPrintByDate
      .addCase(GetMyFingerPrintByDate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetMyFingerPrintByDate.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.succeeded === true) {
          state.MyFingerPrintByDateList = payload.data;
        }
      })
      .addCase(GetMyFingerPrintByDate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // Get GetResultList
      .addCase(GetResultList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetResultList.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.succeeded === true) {
          state.ResultList = payload.data;
        }
      })
      .addCase(GetResultList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // Get GetResultSummaryList
      .addCase(GetResultSummaryList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetResultSummaryList.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.succeeded === true) {
          state.ResultSummaryList = payload.data;
        }
      })
      .addCase(GetResultSummaryList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});


export const { clearState } = raqeebSlice.actions;
export default raqeebSlice.reducer;
