import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getUsers } from "../../store/NTX/Security/usersSlice";
import { GetReportsList } from "../../store/NTX/Reports/reportSlice";
import { GetChartShapesList } from "../../store/NTX/Reports/ReportShape/reportShapeSlice";
 


const useGetChartShapesList = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if(ChartShapesList?.length === 0 ){
      dispatch(GetChartShapesList());
    }
  }, []);

  const { ChartShapesList, loading } = useAppSelector(
    (state) => state.ReportShape
  );
 ;


  return { ChartShapesList, loading };
};

export default useGetChartShapesList;




