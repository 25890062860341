/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import useGetOrganizations from "../../../hooks/HR/use-get-organizations";
import { GetNtxPeoplesList } from "../../../store/NTX/Peoples/peoplesSlice";
import { useNavigate, useParams } from "react-router-dom";
import CustomAlert from "../../../components/Shared/Alert/CustomAlert";
import { InsertEquipmentsWorkOrder } from "../../../store/FLEET/EquipmentsWorkOrder/equipmentsworkorderSlice";
import { EquipmentsWorkOrderSchema } from "../../ValidationForm/validationSchema";
import { GetFleetRequestType } from "../../../store/Lookup/lookupSlice";
import useGetEquipmentsList from "../../../hooks/FLeet/useGetEquipmentsList";

const OrderMaintenanceForm = () => {
  const { Id, fleetEquipmentsId } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();


  useEffect(() => {
    dispatch(GetNtxPeoplesList(""));
    dispatch(GetFleetRequestType());
  }, []);

  const { FleetRequestType } = useAppSelector(
    (state) => state.Lookup
  );

  const { EquipmentsList } = useGetEquipmentsList()
  const { PeoplesList } = useAppSelector((state) => state.Peoples);
  const { Organizations } = useGetOrganizations();

  const formik = useFormik({
    initialValues: {
      fleetEquipmentsWorkRequestsId: Id || null,
      fleetEquipmentsId: fleetEquipmentsId || null,
      orderCode: "",
      validationCode: "",
      closePersonId: null,
      dateCompletion: null,
      isInternal: "0", // boolean
      startCompletionSugg: "",
      endCompletionSugg: "",
      totalItems: 0,
      totalAsset: 0,
      otherAmounts: 0,
      totalBudget: 0,
      supplierId: "",
      workshopId: "",
      startDateExecution: null,
      endDateExecution: null,
      noteCompletion: "",
      totalItemsActual: 0,
      totalAssetActual: 0,
      otherAmountsActual: 0,
      totalBudgetActual: 0,
    },
    enableReinitialize: true,
    validationSchema: EquipmentsWorkOrderSchema(t),
    onSubmit: (values) => {
    
      dispatch(
        InsertEquipmentsWorkOrder({
          fleetEquipmentsWorkRequestsId: values.fleetEquipmentsWorkRequestsId,
          fleetEquipmentsId: values.fleetEquipmentsId,
          orderCode: values.orderCode,
          validationCode: values.validationCode,
          closePersonId: values.closePersonId,
          dateCompletion: values.dateCompletion,
          isInternal: values.isInternal === "1" ? true : false,
          startCompletionSugg: values.startCompletionSugg,
          endCompletionSugg: values.endCompletionSugg,
          totalItems: values.totalItems,
          totalAsset: values.totalAsset,
          otherAmounts: values.otherAmounts,
          totalBudget: values.totalBudget,
          supplierId: values.isInternal === '0' ? values.supplierId : null,
          workshopId: values.isInternal === '1' ? values.workshopId : null,
          startDateExecution: values.startDateExecution,
          endDateExecution: values.endDateExecution,
          noteCompletion: values.noteCompletion,
          totalItemsActual: values.totalItemsActual,
          totalAssetActual: values.totalAssetActual,
          otherAmountsActual: values.otherAmountsActual,
          totalBudgetActual: values.totalBudgetActual,
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            formik.resetForm();
            CustomAlert({ action: "Add" });
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  /// Calculate Total
  const calculateTotalBudget = () => {
    const { totalItems, totalAsset, otherAmounts } = formik.values;
    return totalItems + totalAsset + otherAmounts;
  };
  useEffect(() => {
    formik.setFieldValue("totalBudget", calculateTotalBudget());
  }, [
    formik.values.totalItems,
    formik.values.totalAsset,
    formik.values.otherAmounts,
  ]);
  //_________________________________

  
  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-2">
            <div className="form-group mb-3">
              <label className="form-label">{t('OrderNumber')}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="orderCode"
                onChange={formik.handleChange}
                value={formik.values.orderCode}
                isInvalid={
                  !!(formik.touched.orderCode && formik.errors.orderCode)
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.orderCode}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label"> {t('Equipment')}</label>
              <Form.Select
                className="form-select"
                disabled={fleetEquipmentsId !== undefined}
                name="fleetEquipmentsId"
                onChange={formik.handleChange}
                value={formik.values.fleetEquipmentsId || ""}
                isInvalid={
                  !!(
                    formik.touched.fleetEquipmentsId &&
                    formik.errors.fleetEquipmentsId
                  )
                }
              >
                <option value="">
                  {i18n.language === "ar" ? "إختر" : "Choose"}
                </option>
                {EquipmentsList &&
                  EquipmentsList.map((item, idx) => (
                    <option key={++idx} value={item.id}>
                      {i18n.language === "ar" ? item.name2 : item.name}
                    </option>
                  ))}
              </Form.Select>
            </div>
          </div>

          <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("TypeMaintenance")}</label>
            <Form.Select
              className="form-select"
              name="validationCode"
              onChange={formik.handleChange}
              value={formik.values.validationCode}
              isInvalid={
                !!(formik.touched.validationCode && formik.errors.validationCode)
              }
            >
              <option key={null}>
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {FleetRequestType &&
                FleetRequestType.map((item: any, idx) =>
                  item.list.map((list: any) => (
                    <option key={++idx} value={list.lookupCode}>
                      {i18n.language === "ar" ? list.meaning2 : list.meaning}
                    </option>
                  ))
                )}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.validationCode}
            </Form.Control.Feedback>
          </div>
        </div>

          <div className="col-xl-2">
            <div className="form-group mb-3">
              <label className="form-label"> {t('InternalOrExternal')}</label>
              <Form.Select
                className="form-select"
                name="isInternal"
                onChange={formik.handleChange}
                value={formik.values.isInternal || ""}
                isInvalid={
                  !!(formik.touched.isInternal && formik.errors.isInternal)
                }
              >
                <option value="">
                  {i18n.language === "ar" ? "إختر" : "Choose"}
                </option>
                <option value="1">داخلي</option>
                <option value="0">خارجي</option>
              </Form.Select>
            </div>
          </div>

          {formik.values.isInternal === "1" && (
            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label"> {t('Workshop')} </label>
                <Form.Select
                  className="form-select"
                  name="workshopId"
                  onChange={formik.handleChange}
                  value={formik.values.workshopId}
                  isInvalid={
                    !!(formik.touched.workshopId && formik.errors.workshopId)
                  }
                >
                  <option value="">
                    {i18n.language === "ar" ? "إختر" : "Choose"}
                  </option>
                  {Organizations &&
                    Organizations?.filter((f: any) => f.type === "160").map(
                      (item: any, idx: any) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === "ar" ? item.name2 : item.name}
                        </option>
                      )
                    )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                {formik.errors.workshopId}
              </Form.Control.Feedback>
              </div>
            </div>
           )} 
          {formik.values.isInternal === "0" && (
            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label"> {t('Supplier')}</label>
                <Form.Select
                  className="form-select"
                  name="supplierId"
                  onChange={formik.handleChange}
                  value={formik.values.supplierId || ""}
                  isInvalid={
                    !!(formik.touched.supplierId && formik.errors.supplierId)
                  }
                >
                  <option value="">
                    {i18n.language === "ar" ? "إختر" : "Choose"}
                  </option>
                  {PeoplesList &&
                    PeoplesList?.filter((f) => f.typeCode === "SUPPLIERS").map(
                      (item, idx) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === "ar" ? item.name2 : item.name}
                        </option>
                      )
                    )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                {formik.errors.supplierId}
              </Form.Control.Feedback>
              </div>
            </div>
          )}

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('Expected_start_date')}</label>
              <Form.Control
                className="form-control"
                type="datetime-local"
                name="startCompletionSugg"
                onChange={formik.handleChange}
                value={formik.values.startCompletionSugg}
                isInvalid={
                  !!(
                    formik.touched.startCompletionSugg &&
                    formik.errors.startCompletionSugg
                  )
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.startCompletionSugg}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('Expected_end_date')}</label>
              <Form.Control
                className="form-control"
                type="datetime-local"
                name="endCompletionSugg"
                onChange={formik.handleChange}
                value={formik.values.endCompletionSugg}
                isInvalid={
                  !!(
                    formik.touched.endCompletionSugg &&
                    formik.errors.endCompletionSugg
                  )
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.endCompletionSugg}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-xl-3">
            <div className="input-group">
              <span className="input-group-text">{t('Material_price')}</span>
              <Form.Control
                className="form-control"
                type="number"
                name="totalItems"
                onChange={formik.handleChange}
                value={formik.values.totalItems}
                isInvalid={
                  !!(formik.touched.totalItems && formik.errors.totalItems)
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.totalItems}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="input-group">
              <span className="input-group-text">{t('Remuneration_Workers')}</span>
              <Form.Control
                className="form-control"
                type="number"
                name="totalAsset"
                onChange={formik.handleChange}
                value={formik.values.totalAsset}
                isInvalid={
                  !!(formik.touched.totalAsset && formik.errors.totalAsset)
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.totalAsset}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="input-group">
              <span className="input-group-text">{t('Other_amounts')}</span>
              <Form.Control
                className="form-control"
                type="number"
                name="otherAmounts"
                onChange={formik.handleChange}
                value={formik.values.otherAmounts}
                isInvalid={
                  !!(formik.touched.otherAmounts && formik.errors.otherAmounts)
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.otherAmounts}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="input-group bg-inverse bg-opacity-20">
              <span className="input-group-text">{t('Total')}</span>
              <Form.Control
                className="form-control "
                type="number"
                name="totalBudget"
                onChange={formik.handleChange}
                readOnly
                value={formik.values.totalBudget}
                isInvalid={
                  !!(formik.touched.totalBudget && formik.errors.totalBudget)
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.totalBudget}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>

        {/* <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">تاريخ بدء التنفيذ</label>
                  <Form.Control
                    className="form-control"
                    type="datetime"
                    name="startDateExecution"
                    onChange={formik.handleChange}
                    value={formik.values.startDateExecution}
                    isInvalid={
                      !!(
                        formik.touched.startDateExecution &&
                        formik.errors.startDateExecution
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.startDateExecution}
                  </Form.Control.Feedback>
                </div>
              </div> */}

        <div className="text-center mt-5">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-outline-theme mb-1"
          >
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t("Add")}
          </button>

          <button
            type="button"
            className="btn me-1 btn-default mb-1"
            data-bs-dismiss={fleetEquipmentsId ? null : "modal"}
            onClick={fleetEquipmentsId ? () => Navigate(-1) : undefined}
          >
            <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          </button>

          {/* <button
            type="button"
            className=" btn me-1 btn-outline-default mb-1"
            onClick={() => Navigate("/FLEET/WorkRequests", { replace: true })}
          >
            <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          </button> */}
        </div>
      </Form>
    </div>
  );
};

export default OrderMaintenanceForm;
