import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
 
import { useParams } from "react-router-dom";
import { getGrade } from "../../store/HR/HRSetting/jobgradeSlice";

const useGradeDetails = ()=>{
    const { id } =  useParams();

    const dispatch = useDispatch();
    const { loading , error , Grade} = useSelector((state) => state.Grades); 

    useEffect(() => {
          if(!Grade?.length){
            dispatch(getGrade(id));
          } 
        }, [dispatch , id]);

    return { loading , error , Grade } ;
};

export default useGradeDetails ;