
import UnauthorizedPage from "../pages/PagesError/UnauthorizedPage";
import { useAppSelector } from "../store/hooks";

const withGuard = (Component , permissionToCheck) => {
    const Wrapper = (props) => {

      const permissions = useAppSelector((s) => s.userLogin?.AuthUser?.permission);

      const permissionsArray = permissions ? permissions.split(",") : [];
 
  
      return permissionsArray.includes(permissionToCheck) ? <Component {...props} /> : <UnauthorizedPage/>;
    };
  
    return Wrapper;
  };
  
  export default withGuard;