import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

import UpdatePeoplesContacts from './UpdatePeoplesContacts';
import { PeoplesContactsModels } from '../../../../store/NTX/Peoples/PeoplesModels';
// import Pagination from "../../../components/Shared/Pagination/Pagination";

type Props = {
  peopleId: string;
};

const PeoplesContactsList = ({ peopleId }: Props) => {
  // const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(4);
  const { t, i18n } = useTranslation();
  // const dispatch = useAppDispatch();

  const [DataById, setDataById] = useState<PeoplesContactsModels>();

  // useEffect(() => {
  //   if (peopleId) {
  //     dispatch(GetPeoplesContactsByPeopleId(peopleId));
  //   }

  //   return () => {
  //     dispatch(clearContactsListByPeopleId());
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const { PeoplesContactsListByPeopleId: data } = useAppSelector((state) => state.PeoplesContacts);

  // const filteredSearch = data?.slice()?.filter((item) => {
  //   const searchLowerCase = search.toLowerCase();
  //   return (
  //     item.name2?.toLowerCase().includes(searchLowerCase) ||
  //     item.mobile?.toLowerCase().includes(searchLowerCase) ||
  //     item.phone?.toLowerCase().includes(searchLowerCase) ||
  //     item.name?.toLowerCase().includes(searchLowerCase)
  //   );
  // });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = data?.slice(indexOfFirstData, indexOfLastData);

  //_________
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);

  const handleCollapseToggle = (index: any, item: PeoplesContactsModels) => {
    // Toggle the current row; collapse if it's the same row, expand if it's a different row
    const newIndex = expandedRowIndex === index ? null : index;
    setExpandedRowIndex(newIndex);
    setDataById(item);
  };

  return (
    <>
      {data.length === 0 ? (
        <div className="text-center bg-inverse bg-opacity-10 rounded-4 p-3 fw-bold">
          <div>لا يوجد بيانات</div>
        </div>
      ) : (
        <div>
          <div className="table-responsive rounded-2">
            <table className="table table-hover text-nwrap mb-0">
              <thead className="bg-inverse bg-opacity-25">
                <tr className="fw-bold text-danger">
                  <th>{t('Name')}</th>
                  <th>{t('MobileNumber')}</th>
                  <th>{t('Phone')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {currentData?.map((item, index) => (
                  <Fragment key={item.id}>
                    <tr key={item.id}>
                      <td className="align-middle">
                        {i18n.language === 'en' ? item.name : item.name2}
                      </td>
                      <td className="align-middle">{item.mobile}</td>
                      <td className="align-middle">{item.phone}</td>
                      <td className="align-middle text-end">
                        <Link
                          to=""
                          className="btn btn-outline-theme"
                          data-bs-toggle="collapse"
                          data-bs-target={`#Neatix${index}`}
                          onClick={() => handleCollapseToggle(index, item)}
                          // onClick={() => setDataById(item)}
                        >
                          {t('Button.Edit')}
                          <i className="far fa-lg fa-fw  fa-edit"></i>
                        </Link>
                      </td>
                    </tr>

                    <tr className="">
                      <td colSpan={5} className="p-0   bg-inverse bg-opacity-5">
                        {/* <div className="collapse p-0" id={`Neatix${index}`}> */}
                        <div
                          className={`collapse ${expandedRowIndex === index ? 'show' : ''} p-1`}
                          id={`collapse${index}`}>
                          <UpdatePeoplesContacts key={index} DataById={DataById} />
                          {/* </div> */}
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-3  ">
            <Pagination
              filteredData={data}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PeoplesContactsList;
