import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { PosCashierModel } from './PosModels';
import { APIResponse } from '../../Shared/shared';


export const addPosCashers = createAsyncThunk(
  'PosCashier/addPosCashers',
  async (item: PosCashierModel, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<PosCashierModel>>('Pos/AddPosCashers', item);
      if (data.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const getPosCashersList = createAsyncThunk(
  'PosCashier/GetPosCashersList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<PosCashierModel[]>>('Pos/GetPosCashersList');
      if (data.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const GetPosCashersByPosId = createAsyncThunk(
  'PosCashier/GetPosCashersByPosId',
  async (posId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<PosCashierModel[]>>(`Pos/GetPosCashersByPosId?PosId=${posId}`);
      if (data.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const deletePosCashers = createAsyncThunk(
  'PosCashier/deletePosCashers',
  async (id: string, { rejectWithValue , dispatch}) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<boolean>>(`Pos/DeletePosCashers?Id=${id}`);
      if (data.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);




const initialState = {
  PosCashierList: [] as PosCashierModel[],
  PosCashierListByPosId: [] as PosCashierModel[],
  PosCashierById: {} as PosCashierModel,
  loading: false,
  error: null as string | null,
};

// const initialState: {
//   PosCashierList: PosCashierModel[];
//   PosCashierListByPosId: PosCashierModel[];
//   PosCashierById: PosCashierModel;
//   loading: boolean;
//   error: any | null;
// } = {
//   PosCashierList: [],
//   PosCashierListByPosId: [],
//   PosCashierById: {} as PosCashierModel,
//   loading: false,
//   error: null,
// };

const PosCashierSlice = createSlice({
  name: 'PosCashier',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPosCashersList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPosCashersList.fulfilled, (state, action: PayloadAction<PosCashierModel[]>) => {
        state.loading = false;
        state.PosCashierList = action.payload;
      })
      .addCase(getPosCashersList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


      .addCase(GetPosCashersByPosId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetPosCashersByPosId.fulfilled, (state, action: PayloadAction<PosCashierModel[]>) => {
        state.loading = false;
        state.PosCashierListByPosId = action.payload;
      })
      .addCase(GetPosCashersByPosId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


      .addCase(deletePosCashers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePosCashers.fulfilled, (state, action: PayloadAction<APIResponse<boolean>>) => {
        state.loading = false;
        // state.PosCashierList = state.PosCashierList.filter(cashier => cashier.id !== action.payload);
      })
      .addCase(deletePosCashers.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      
      .addCase(addPosCashers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addPosCashers.fulfilled, (state, action: PayloadAction<APIResponse<PosCashierModel>>) => {
        state.loading = false;
        state.PosCashierList.push(action.payload.data);
      })
      .addCase(addPosCashers.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default PosCashierSlice.reducer;
