import React, { useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';

import useGetPurchaseRequestList from '../../../../hooks/PUR/PurchaseRequest/useGetPurchaseRequestList';

const Index = () => {
  const [date, setDate] = useState('');
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  const { PurchaseRequestsList, loading, error } = useGetPurchaseRequestList();

  const filteredSearch = PurchaseRequestsList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    .filter((f) => {
      if (!date) return true; // إذا كانت قيمة التاريخ فارغة، اجلب جميع النتائج بدون تصفية
      const itemDate = new Date(f.transactionDate).toDateString(); // تحويل التاريخ إلى كائن Date واستخراج اليوم
      const selectedDate = new Date(date).toDateString(); // تحويل التاريخ المحدد إلى كائن Date واستخراج اليوم
      return itemDate === selectedDate; // مقارنة اليوم فقط (بدون الوقت)
    })
    ?.sort((a, b) => b?.no - a?.no);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'طلبات الشــراء',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="طلبات الشــراء" />
        <div className="ms-auto">
          <Link to="AddPurchaseRequest" className="btn me-1 btn-theme">
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            طلب شراء جديد
          </Link>
        </div>
      </div>
      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="p-2">
              <div className="row mb-2">
                <div className="col-lg-6">
                  <div className="input-group flex-nowrap m-0">
                    <span className="input-group-text fw-bold">
                      {t('Search')}
                      {/* <i className="fa fa-search  text-theme ms-3"></i> */}
                    </span>
                    <div className="input-group">
                      <div className="flex-fill position-relative">
                        <div className="input-group border-0 ">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}>
                            <i className="fa fa-search opacity-5 text-theme"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px m-0"
                            // placeholder={t('Search')}
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                              setCurrentPage(1);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text fw-bold">
                      {t('Date')}
                      {/* <i className="fa fa-search  text-theme ms-3"></i> */}
                    </span>
                    <div className="input-group">
                      <div className="flex-fill position-relative">
                        <div className="input-group border-0 ">
                          <input
                            type="date"
                            className="form-control "
                            value={date}
                            onChange={(e) => {
                              setDate(e.target.value);
                              setCurrentPage(1);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table table-hover text-nowrap text-center">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t('رقم الطلب ')}</th>
                      <th scope="col">{t('تاريخ الطلب ')}</th>
                      <th scope="col">{t('الفرع')}</th>
                      <th scope="col">{t('المخزن')}</th>
                      <th scope="col">{t('قيمة الطلب')}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle "> {item.code} </td>
                        <td className="align-middle">
                          {item.transactionDate.toString().split('T')[0]}
                        </td>
                        <td className="align-middle"> {item.inventoryId} </td>
                        <td className="align-middle"> {item.organizationName2} </td>
                        <td className="align-middle"> {item.grossAmount}</td>

                        <td className="text-end">
                          <button
                            type="button"
                            className="btn btn-outline-theme mx-1"
                            onClick={() => Navigate(`${item.id}/UpdatePurchaseRequest`)}>
                            {/* <i className="far fa-lg fa-fw me-2 fa-edit"></i> */}
                            {t('عرض التفاصيل')}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </CardBody>
        </Loading>
      </Card>
    </div>
  );
};

export default Index;
