import React, { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/Shared/Loader/Loader';
import {
  cleanTransactionHistoryList,
  GetTransactionHistoryWithValueById,
} from '../../../store/Ame/ameRequestSlice';
import HistoryList from './HistoryList';
import HeaderRequest from './HeaderRequest/HeaderRequest';
import ContentRequest from './ContentRequest/ContentRequest';
import { Card, CardBody } from '../../../components/card/card';

const OnlyViewRequests = ({ TransactionHistoryId }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (TransactionHistoryId) {
      dispatch(cleanTransactionHistoryList());
      dispatch(GetTransactionHistoryWithValueById(TransactionHistoryId));
    }
  }, [TransactionHistoryId, dispatch]);
  const data = useSelector((state) => state.AmeRequest.TransactionHistoryWithValueList);
  const loadingViewRequest = useSelector((state) => state.AmeRequest.loadingViewRequest);

  if (loadingViewRequest) {
    return <Loader loading={loadingViewRequest} />;
  }

  return (
    <Card>
      <CardBody>
        <HeaderRequest data={data} />
        <ContentRequest data={data} />
        <div className="row mt-3">
          <div className="col-lg-12">{data.history && <HistoryList List={data.history} />}</div>
        </div>
      </CardBody>
    </Card>
  );
};

export default OnlyViewRequests;
