import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { Profile } from "./profileModels";
// import isOnline from 'is-online';

export const GetNtxProfile = createAsyncThunk(
  "NtxSettings/GetNtxProfile",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.get("Persons/GetProfileInfo");
      if(data.succeeded === true){
        // localStorage.setItem("UserProfile", JSON.stringify(data?.data) );
      }
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  Profile: Profile;
  loading: boolean;
  error: any | null;
} = {
  Profile: {} as Profile,
  loading: false,
  error: null,
};

const NtxProfileSlice = createSlice({
  name: "NtxProfile",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(GetNtxProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetNtxProfile.fulfilled,
        (state, action: PayloadAction<Profile>) => {
          state.loading = false;
          state.Profile = action.payload;
        }
      )
      .addCase(GetNtxProfile.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default NtxProfileSlice.reducer;
