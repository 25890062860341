import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody } from '../../../../components/card/card.jsx';
import AddPosition from './AddPosition.js';
import Pagination from '../../../../components/Shared/Pagination/Pagination.jsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getPositions } from '../../../../store/HR/HRSetting/positionSlice.js';
import ModalComponent from '../../../../components/Modal/ModalComponent.jsx';
import { usePermissions } from '../../../../Helper/Permisstions/usePermissions.ts';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
// import Loader from '../../../../components/Shared/Loader/Loader';
import Loading from '../../../../components/Shared/Loader/Loading';

const Position = () => {
  const { HasPermission } = usePermissions();

  const [Show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const { Positions, loading, error } = useSelector((state) => state.Positions);
  const dispatch = useDispatch();
  useEffect(() => {
    if (Positions?.length === 0) {
      dispatch(getPositions());
    }
  }, []);

  const filteredSearch = Positions?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('Positions'),
      link: null,
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('Positions')} />
        {HasPermission('AddHrPerPosition') && (
          <div className="ms-auto">
            <Link
              to=""
              className="btn me-1 btn-outline-theme"
              data-bs-toggle="modal"
              data-bs-target="#modalAddPosition"
              onClick={() => setShow(true)}>
              <i className="fa fa-plus-circle fa-fw me-1"></i>
              {t('AddPosition')}
            </Link>
          </div>
        )}
      </div>

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="tab-content p-2">
              <div className="row">
                <div className="col-7 col-md- d-flex justify-content-start">
                  <div className="input-group mb-2">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder={t('Search') + ' ...'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table text-nowrap">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t('Name')}</th>
                      <th scope="col">{t('Job')}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle">
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </td>
                        <td className="align-middle">
                          {i18n.language === 'ar' ? item.jobName2 : item.jobName}
                        </td>
                        <td className="text-end">
                          <div className="btn-group">
                            {HasPermission('ViewDetailsHrPerPosition') && (
                              <button
                                type="button"
                                className="btn btn-outline-theme"
                                onClick={() => Navigate(`${item.id}/Edit`)}>
                                <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                                {t('ViewDetails')}
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                    {/* </Loading> */}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </CardBody>{' '}
        </Loading>
      </Card>

      <ModalComponent title={t('AddPosition')} id="modalAddPosition">
        {Show && <AddPosition />}
      </ModalComponent>
    </>
  );
};

export default Position;
