import React, { useState } from 'react';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../components/card/card';
import { Link } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import useGetPurchaseRequestList from '../../../../hooks/PUR/PurchaseRequest/useGetPurchaseRequestList';

interface Props {
  Route: 'Quotation' | 'PurchaseOrder' | 'ApInvoice';
}

const PurchaseRequestList = ({ Route }: Props) => {
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(5);
  const { t, i18n } = useTranslation();
  // const Navigate = useNavigate();

  const { PurchaseRequestsList, loading, error } = useGetPurchaseRequestList();

  const filteredSearch = PurchaseRequestsList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.sort((a, b) => b?.no - a?.no);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  return (
    <Card>
      <Loading loading={loading}  error={error} Type='Dots'>
        <div>
          <div className="text-center bg-inverse bg-opacity-10 rounded-0 p-2 fw-bold">
            طلبات الشــراء
          </div>
          <div className="table-responsive">
            <table className="table table-hover text-nowrap">
              <thead className="bg-theme bg-opacity-30 rounded-0 p-1">
                <tr>
                  <th scope="col">{t('رقم الطلب ')}</th>
                  <th scope="col">{t('تاريخ الطلب ')}</th>
                  {/* <th scope="col">{t('المورد')}</th> */}
                  <th scope="col">{t('الفرع ')}</th>
                  <th scope="col">{t('قيمة الطلب ')}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {currentData?.map((item, index) => (
                  <tr key={item.id}>
                    <td className="align-middle"> {item.code} </td>
                    <td className="align-middle"> {item.transactionDate?.toString().split('T')[0]} </td>
                    {/* <td className="align-middle"> {item.peopleName2} </td> */}
                    <td className="align-middle"> {item.organizationName2} </td>
                    <td className="align-middle">{item.grossAmount} </td>
                    {/* //:id? */}
                    <td className="text-end">
                      <Link
                        className="btn btn-outline-theme"
                        // to={`/PurchasesTransactions/Quotation/AddPurchaseQuotation/${item.id}`}
                        to={
                          Route === 'PurchaseOrder'
                            ? `/PurchasesTransactions/PurchaseOrder/AddPurchaseOrder/${item.id}`
                            : Route === 'Quotation'
                            ? `/PurchasesTransactions/Quotation/AddPurchaseQuotation/${item.id}`
                            : Route === 'ApInvoice'
                            ? `/Finance/Ap/ApInvoices/AddApInvoices/${item.id}`
                            : ''
                        }
                      >
                        إنشــاء
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="p-3">
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </div>
        </div>
      </Loading>
    </Card>
  );
};

export default PurchaseRequestList;
