import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../../../../components/Shared/Loader/Loader';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../../components/Shared/Pagination/Pagination.jsx';
import useLookupModule from '../../../../../hooks/Lookups/use-Lookup-Module';
import { Card } from '../../../../../components/card/card';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { usePermissions } from '../../../../../Helper/Permisstions/usePermissions';
import ModalComponent from '../../../../../components/Modal/ModalComponent';
import MngTemplates from '../MngTemplates';
import { GetNtxPrintTemplatesList } from '../../../../../store/NTX/PrintTemplate/PrintTemplateSlice';
import Loading from '../../../../../components/Shared/Loader/Loading';
import NeatixModal from '../../../../../components/Modal/NeatixModal';

interface LookupModule {
  Module: any[];
}

function Index() {
  const { HasPermission } = usePermissions();

  const [templateId, setTemplateId] = useState<string>('');
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState<string>('');
  // const [sortOrder, setSortOrder] = useState<string>("asc");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataPerPage] = useState<number>(8);
  // const Navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [show, setShow] = useState(false);

  //__________________________________________________
  const { PrintTemplateList, loading, error } = useAppSelector((state) => state.PrintTemplate);
  useEffect(() => {
    if (PrintTemplateList.length === 0) {
      dispatch(GetNtxPrintTemplatesList());
    }
  }, [dispatch]);

  //_____________________________________________________

  const [selectedValue, setSelectedValue] = useState('');
  const { Module }: LookupModule = useLookupModule();

  //_________________________________________________________________________________________________

  const filteredSearch = PrintTemplateList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        // item.tableName?.toLowerCase().includes(searchLowerCase) ||
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.filter((f) => (selectedValue === '' ? true : f.moduleId === selectedValue));

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('قوالب الطباعة'),
      link: null,
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('إدارة قوالب الطباعة')} />
        <div className="ms-auto">
          <Link
            to="AddPrintingForms"
            className="btn me-1 btn-theme"
            // onClick={() => Navigate(`AddReport`)}
          >
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            اضافة قالب طباعة جديــد
          </Link>
        </div>
      </div>
      <Card>
        <Loading loading={loading} error={error}>
          <div className="tab-content p-3">
            <div className="row">
              <div className="col-7 col-md-6 d-flex justify-content-start">
                <div className="input-group mb-2">
                  <div className="flex-fill position-relative">
                    <div className="input-group mb-2">
                      <select
                        className="btn btn-outline-default p-0 dropdown-toggle"
                        value={selectedValue}
                        onChange={(event) => setSelectedValue(event.target.value)}>
                        <option className="dropdown-item" value={''}>
                          {t('All')}
                        </option>
                        {Module &&
                          Module.map((item) => (
                            <option value={item.id} key={item.id} className="dropdown-item">
                              {i18n.language === 'ar' ? item.name2 : item.name}
                            </option>
                          ))}
                      </select>

                      <div className="flex-fill position-relative">
                        <div className="input-group">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}>
                            <i className="fa fa-search opacity-5"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            placeholder={t('Search')}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* //  START TABLE   */}
            <div className="table-responsive">
              <table className="table text-nowrap">
                <thead className="table-dark">
                  <tr>
                    <th style={{ width: '25%' }}>{t('Reports_Name')}</th>
                    {/* <th style={{ width: "20%" }}>{t("Data_Source")}</th> */}
                    <th style={{ width: '25%' }}>{t('Application')}</th>
                    <th style={{ width: '25%' }}>{t('Icon')}</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {currentData.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle">
                        {i18n.language === 'en' ? item.name : item.name2}
                      </td>
                      <td className="align-middle">مصدر البيانات</td>
                      {/* <td className="align-middle">
      {i18n.language === "en"
        ? item.moduleName
        : item.moduleName2}
    </td> */}
                      <td className="align-middle">
                        <div className="text-theme fs-4">
                          <i className={item.icon}></i>
                        </div>
                      </td>
                      <td className="text-end ">
                        {/* <div className="btn-group"> */}
                        {HasPermission('NtxReportCharts') && (
                          <button
                            type="button"
                            className="btn btn-outline-theme ms-1"
                            // data-bs-toggle="modal"
                            // data-bs-target="#PrintTemplates"
                            onClick={() => {
                              setTemplateId(item.id);
                              setShow(true);
                            }}>
                            <i className=" text-theme fab fa-lg fa-fw me-1 bi bi-file-earmark-text"></i>
                            {t('إعدادت القالب')}
                          </button>
                        )}

                        {HasPermission('NtxEditReport') && (
                          <button
                            type="button"
                            className="btn btn-outline-theme ms-1"
                            onClick={() => {
                              const newLocation = `PrintingForms/${item.id}/UpdatePrintingForms`;
                              window.location.href = newLocation;
                            }}>
                            <i className="far fa-lg fa-fw me-1  fa-edit"></i>
                            {t('Edit')}
                          </button>
                        )}
                        {/* </div> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: number) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </div>
        </Loading>
      </Card>

      <ModalComponent id="PrintTemplates" title="إعدادات قالب الطباعة">
        {templateId && <MngTemplates templateId={templateId} />}
      </ModalComponent>

      <NeatixModal show={show} setShow={setShow} size="xl" bodyClassName="p-2" showHeader={false}>
        {templateId && <MngTemplates templateId={templateId} />}
      </NeatixModal>
    </>
  );
}

export default Index;
