import React, { Fragment, useState } from 'react';
import ExtraInformationBuilderAdd from '../../../ExtraInformationBuilder/Add/ExtraInformationBuilderAdd';
import useGetFormColumn from '../../../../../hooks/Forms/use-get-FColumn';
import { Form } from 'react-bootstrap';
import useAddUpdateExtraInformation from '../../../ExtraInformationBuilder/useAddUpdateExtraInformation';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import { useTranslation } from 'react-i18next';
// import Loader from '../../../../../components/Shared/Loader/Loader';
import { Link } from 'react-router-dom';
import { usePermissions } from '../../../../../Helper/Permisstions/usePermissions';
import Loading from '../../../../../components/Shared/Loader/Loading';

const AddExtraRecord = ({ FormId, ReferenceId, setRefreshData }) => {
  const { t, i18n } = useTranslation();
  const [extraInfoFormData, setExtraInfoFormData] = useState({});
  const { FormColumnList, loading, error } = useGetFormColumn(FormId);
  const { AddUpdateExtraInformation } = useAddUpdateExtraInformation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { HasPermission } = usePermissions();

  const dynamicFormName =
    i18n.language === 'en' ? FormColumnList?.dynamicFormName : FormColumnList?.dynamicFormName2;

  const handleAddUpdateExtraInfo = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    var respons = await AddUpdateExtraInformation(extraInfoFormData);
    if (respons === null) {
      CustomAlert({ action: 'Error' });
      setIsSubmitting(false);
      return;
    } else {
      setIsSubmitting(false);
      CustomAlert({
        action: 'Add',
        msg: 'تم إضافة معلومات جديدة بنجاح',
      });
      setRefreshData(true);
    }
  };

  return (
    <Fragment>
      <Loading loading={loading} error={error}>
        <Form onSubmit={handleAddUpdateExtraInfo}>
          {FormColumnList?.columnList?.length > 0 ? (
            <Fragment>
              <ExtraInformationBuilderAdd
                FormColumnList={FormColumnList}
                setExtraInfoFormData={setExtraInfoFormData}
                ReferenceId={ReferenceId}
              />

              <div className="text-end mt-3">
                <button type="submit" className="btn me-1 btn-theme mb-1" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                  )}
                  {t('Add')}
                </button>
              </div>
            </Fragment>
          ) : (
            <div className="text-center bg-inverse bg-opacity-10 rounded-3 p-3 fw-bold">
              <div className="text-center bg-inverse bg-opacity-10 rounded-3 p-3 fw-bold">
                {t('لا يوجد حقول لهذا النموذج')}
              </div>
              <br></br>
              {HasPermission('NtxAddingfieldstoExtraInformationRecordform') && (
                <Link
                  to={`/NTX/Forms/${FormId}/AddFormColumns`}
                  className="btn btn-lg btn-outline-theme">
                  إضغط هنا لتكوين نموذج {dynamicFormName}
                </Link>
              )}
            </div>
          )}
        </Form>
      </Loading>
    </Fragment>
  );
};

export default AddExtraRecord;
