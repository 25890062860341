import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useTranslation } from 'react-i18next';
import { getPayrolls } from '../../store/HR/Payroll/PayrollSlice';

 

const useGetPayrolls = ( ) => {
  const {i18n} = useTranslation()

   
  const dispatch = useAppDispatch()

  const { Payrolls, loading , error} = useAppSelector((state) => state.Payrolls);
  useEffect(() => {
    if (Payrolls?.length === 0) {
      dispatch(getPayrolls());
    }
  }, [dispatch]);


  const PayrollsOptions =
  Payrolls &&
  Payrolls?.map((item : any) => ({
    value: item.id,
    label: i18n.language === "en" ? item.name : item.name2,
  }));

 

 
   

  return { Payrolls,PayrollsOptions ,  loading , error }
}

export default useGetPayrolls
