import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// import { getData, postData } from "../../Axios/axiosData";
import { ntxAPI } from "../../../API/axiosNeatex";

const initialState = { Positions: [], loading: false, error: null, Position: [] };

export const insertPosition = createAsyncThunk(
  "Positions/insertPosition",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData( "HRSetting/AddPosition" , item);
      const {data} = await ntxAPI.post("HRSetting/AddPosition" , item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getPositions = createAsyncThunk("Positions/getPositions", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    // const data = await getData("HRSetting/GetPositionList");
    const {data} = await ntxAPI.get("HRSetting/GetPositionList");
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

export const getPosition = createAsyncThunk("Positions/getPosition", async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    // const data = await getData(`HRSetting/GetPositionById?Id=${id}`);
    const {data} = await ntxAPI.get(`HRSetting/GetPositionById?Id=${id}`);
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

export const editPosition = createAsyncThunk(
  "Positions/editPosition",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData( "HRSetting/UpdatePosition" , item);
      const {data} = await ntxAPI.post("HRSetting/UpdatePosition" , item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);




const PositionSlice = createSlice({
  name: "Positions",
  initialState,
  reducers: { },
  extraReducers(builder) {
    builder
      ///// get all Positions
      .addCase(getPositions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPositions.fulfilled, (state, action) => {
        state.loading = false;
        state.Positions = action.payload;
      })
      .addCase(getPositions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      ///////insert
      .addCase(insertPosition.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(insertPosition.fulfilled, (state, action) => {
        state.loading = false;
        state.Positions.push(action.payload);
      })
      .addCase(insertPosition.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      // edit 
      .addCase(editPosition.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editPosition.fulfilled, (state, action) => {
        state.loading = false;
        state.Position = action.payload;
      })
      .addCase(editPosition.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  
      ///// get one Position by ID
      .addCase(getPosition.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPosition.fulfilled, (state, action) => {
        state.loading = false;
        state.Position = action.payload;
      })
      .addCase(getPosition.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default PositionSlice.reducer;
