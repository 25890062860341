import { useAppDispatch } from '../../../../store/hooks';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { Form } from 'react-bootstrap';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import useGetChartAccountsList from '../../../../hooks/Finance/useGetChartAccountsList';
import { AddGlLedgers, GetGlLedgersList } from '../../../../store/Finance/Ledgers/LedgersSlice';

const AddLedgers = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const { Currency } = useLookupCurrency();

  const { ChartAccountsList } = useGetChartAccountsList();

  const formik = useFormik({
    initialValues: {
      id: '',
      name: '',
      name2: '',
      currencyCode: '',
      chartOfAccountsId: '',
      description: '',
    },
    //   validationSchema: AddEquipmentsModelFLeetSchema(t),
    onSubmit: (values) => {
      dispatch(
        AddGlLedgers({
          name: values.name,
          name2: values.name,
          currencyCode: values.currencyCode,
          chartOfAccountsId: values.chartOfAccountsId,
          description: values.description,
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            document.getElementById('closeModalForm')?.click();
            formik.resetForm();
            dispatch(GetGlLedgersList());
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  return (
    <div>
      
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('LocalName')}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
            </div>
          </div>
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('GlobalName')}</label>
              <Form.Control
                className="form-control "
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
            </div>
          </div>

          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">ألعملة</label>
              <Form.Select
                className="form-select text-center"
                name="currencyCode"
                onChange={formik.handleChange}
                value={formik.values.currencyCode}
                isInvalid={!!(formik.touched.currencyCode && formik.errors.currencyCode)}>
                <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {Currency.map((item: any) => (
                  <option key={item.currencyCode} value={item.currencyCode}>
                    {item.description}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">chart account</label>
              <Form.Select
                className="form-select text-center"
                name="chartOfAccountsId"
                onChange={formik.handleChange}
                value={formik.values.chartOfAccountsId}
                isInvalid={!!(formik.touched.chartOfAccountsId && formik.errors.chartOfAccountsId)}>
                <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {ChartAccountsList &&
                  ChartAccountsList.map((item) => (
                    <option key={item.id} value={item.id}>
                      {i18n.language === 'en' ? item.name : item.name2}
                    </option>
                  ))}
              </Form.Select>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-outline-theme mb-1">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t('Add')}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-outline-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default AddLedgers;
