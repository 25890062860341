import axios from 'axios';
import { store } from '../store/index';
import { ntxheader, variables } from './variables.js';
import { IsTokenValid } from '../Helper/Helper';
import { refreashToken } from '../store/Login/loginSlice';

export const ntxAPI = axios.create({
  baseURL: variables.API_URL,
  headers: ntxheader,
});
let isRefreshing = false;
let refreshQueue = [];

ntxAPI.interceptors.request.use(async (req) => {
  const Token = store.getState()?.userLogin?.AuthUser?.token;
  req.headers.Authorization = `Bearer ${Token}`;

  if (IsTokenValid()) {
    return req;
  }

  if (!isRefreshing) {
    isRefreshing = true;

    try {
      
      const refreshedToken = await store.dispatch(refreashToken());

      req.headers.Authorization = `Bearer ${refreshedToken.payload.token}`;

      // Reset the refreshing flag
      isRefreshing = false;

      refreshQueue?.forEach((resolve) => resolve(refreshedToken.payload.token));
      refreshQueue = [];
    } catch (err) {
      // await new Promise((resolve) => setTimeout(resolve, 3000));
      localStorage.clear();
      window.location.replace('/login');
    }
  } else {
    // If a refresh is already in progress, add the request to the queue
    // and return a promise that will be resolved with the new token
    // when it's available
    const tokenPromise = new Promise((resolve) => {
      refreshQueue.push((newToken) => {
        req.headers.Authorization = `Bearer ${newToken}`;
        resolve(req);
      });
    });

    return tokenPromise;
  }

  return req;
});

// async function handleTokenRefreshError() {
//   // RefreashTokenFailureSwalAlert();
//   await new Promise((resolve) => setTimeout(resolve, 3000));
//   localStorage.clear();
//   window.location.replace('/login');
// }
