import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from 'i18next-browser-languagedetector';
import translateAR from "./Language/ar.json";
import translateEN from "./Language/en.json";

const language = localStorage.getItem('language') || 'ar';

const resources = {
  ar: {
    translation: translateAR,
  },
  en: {
    translation: translateEN,
  },
};



i18n
  //   .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: language,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
