/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Card, CardBody } from '../../../../components/card/card.jsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  cleanAssignmentListById,
  GetAssignmentById,
  GetAssignmentByPersonId,
} from '../../../../store/HR/Person/assignmentSlice.js';
import { Link, useParams } from 'react-router-dom';
import AssignmentForm from './AssignmentForm.js';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import Loading from '../../../../components/Shared/Loader/Loading';

const Assignment = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { AssignmentPerson, isLoading, error2 } = useSelector((state) => state.Assignments);

  const { AssignmentListById, loading, error } = useSelector((state) => state.Assignments);

  const currentId =
    Object.keys(AssignmentPerson)?.length > 0 &&
    AssignmentPerson?.allAssignment?.find((item) => item.isCurrent === true)?.id;

  const sortedList =
    AssignmentPerson.allAssignment &&
    AssignmentPerson.allAssignment?.slice().sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);
      return dateA - dateB;
    });

  useEffect(() => {
    dispatch(GetAssignmentByPersonId(id));

    return () => {
      dispatch(cleanAssignmentListById());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (AssignmentPerson?.allAssignment?.length) {
      dispatch(GetAssignmentById(currentId));
    }
  }, [dispatch, currentId]);

  //______________________________________________________________________________________________________
  // تهيئة القيمة الافتراضية ل أكتيف
  // const idCurrent = AssignmentPerson?.allAssignment?.find(f => f?.isCurrent=== true)?.id
  const [active, setActive] = useState();
  useEffect(() => {
    if (AssignmentPerson?.allAssignment?.length > 0) {
      setActive(AssignmentPerson.allAssignment?.find((f) => f.isCurrent === true).id);
    }
  }, [AssignmentPerson]);

  //________________________________________________________________________________________________
  const handlebutton = async (item) => {
    dispatch(cleanAssignmentListById());
    dispatch(GetAssignmentById(item.id));
    setActive(item.id);
  };
  //____________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('Employees'),
      link: '/HR/Employees',
    },
    {
      name: t('Employee Aassignment'),
      link: null,
    },
  ];
  return (
    <>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName={t('Employee Aassignment')}
        subtitle={i18n.language === 'ar' ? AssignmentPerson.name2 : AssignmentPerson.name}
      />

      <div className="row">
        <div className="col-xl-12">
          <Card>
            <Loading loading={loading || isLoading} error={error || error2}>
              <>
                <div className="mb-2 ">
                  <Card>
                    <CardBody>
                      <div style={{ direction: 'ltr' }} className="nav-wizards-container">
                        <nav className="nav nav-wizards-2 mb-3">
                          {Object.keys(AssignmentPerson)?.length
                            ? sortedList.map((item) => (
                                <div key={item.id} className="nav-item col ">
                                  <Link
                                    className={`nav-link ${active === item.id ? 'active' : ''}`}
                                    onClick={() => handlebutton(item)}>
                                    <div className={`nav-text d-flex justify-content-around `}>
                                      {item.isCurrent && (
                                        <span className=" badge    text-black  px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ">
                                          {t('التعيين الحالي')}
                                          <i className="fa fa-clock fs-15px fa-fw me-5px"></i>
                                        </span>
                                      )}
                                      {item.startDate}
                                    </div>
                                  </Link>
                                </div>
                              ))
                            : ''}
                        </nav>
                      </div>
                    </CardBody>
                  </Card>
                </div>

                <CardBody className="">
                  {/* <Loader loading={isLoading} />
                  <Loader loading={loading} /> */}
                  {Object.keys(AssignmentPerson)?.length
                    ? (Object.keys(AssignmentListById)?.length > 0 ||
                        AssignmentPerson.allAssignment?.length === 0) && (
                        <AssignmentForm
                          AssignmentListById={AssignmentListById || {}}
                          AssignmentPerson={AssignmentPerson}
                        />
                      )
                    : ''}
                </CardBody>
              </>
            </Loading>
          </Card>
        </div>
      </div>

      <div />
    </>
  );
};

export default Assignment;
