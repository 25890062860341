import React from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';
import { Form } from 'react-bootstrap';
import useLookupTaxType from '../../../hooks/Lookups/use-Lookup-TaxType';
import {
  AddTaxManagers,
  GetTaxManagersList,
} from '../../../store/Finance/TaxManagers/TaxManagerSlice';

interface Props {
  LedgerId: string;
}

const AddVAT = ({ LedgerId }: Props) => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();

  const { TAX_TYPE } = useLookupTaxType();

  const formik = useFormik({
    initialValues: {
      taxType: '',
      codeCombinationId: null,
      ledgerId: LedgerId,
      taxRate: 0,
      enabledFlag: false,
      startDateActive: '',
      endDateActive: '',
    },
    enableReinitialize: true,
    //   validationSchema: AddEquipmentsModelFLeetSchema(t),
    onSubmit: (values) => {
      dispatch(
        AddTaxManagers({
          name: 'string',
          name2: 'string',

          taxType: values.taxType,
          // codeCombinationId: values.codeCombinationId,
          codeCombinationId: null,
          ledgerId: values.ledgerId,
          taxRate: values.taxRate,
          enabledFlag: values.enabledFlag,
          startDateActive: values.startDateActive,
          endDateActive: values.endDateActive,
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            document.getElementById('closeModalForm')?.click();
            formik.resetForm();
            dispatch(GetTaxManagersList());
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-12">
            <div className="form-group mb-3">
              <label className="form-label">نوع الضريبة</label>
              <Form.Select
                className="form-select text-center"
                name="taxType"
                value={formik.values.taxType}
                onChange={formik.handleChange}
                isInvalid={!!(formik.touched.taxType && formik.errors.taxType)}>
                <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {TAX_TYPE &&
                  TAX_TYPE.map((item: any, idx: any) =>
                    item.list.map((list: any) => (
                      <option key={++idx} value={list.lookupCode}>
                        {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                      </option>
                    ))
                  )}
              </Form.Select>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="form-group mb-3">
              <label className="form-label">{t('taxRate')}</label>
              <Form.Control
                className="form-control text-center"
                type="text"
                name="taxRate"
                onChange={formik.handleChange}
                value={formik.values.taxRate}
                isInvalid={!!(formik.touched.taxRate && formik.errors.taxRate)}
              />
            </div>
          </div>
          <div className="col-xl-12">
            <div className="form-group">
              <label className="form-label">{t('StartDate')}</label>
              <Form.Control
                className="form-control text-center"
                type="date"
                name="startDateActive"
                onChange={formik.handleChange}
                value={formik.values.startDateActive}
                isInvalid={!!(formik.touched.startDateActive && formik.errors.startDateActive)}
              />
            </div>
          </div>
          <div className="col-xl-6 m-auto">
            <div className="form-group  mb-2 bg-inverse bg-opacity-5 rounded-4 pb-1">
              <Form.Check
                style={{ margin: '10px 20px' }}
                className="pt-3"
                type="checkbox"
                label="Active"
                name="enabledFlag"
                checked={formik.values.enabledFlag}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <hr />
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-outline-theme mb-1">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t('Add')}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-outline-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default AddVAT;
