import EditFormDepartments from "../../../HR/Departments/EditDepartments/EditFormDepartments";


const UpdateStore = ({ inventoryId }) => {

  return (
    <div>
      <EditFormDepartments
      id={inventoryId}
        type="130"
      />
    </div>
  );
};

export default UpdateStore;
