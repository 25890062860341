import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ntxAPI } from "../../API/axiosNeatex";



  export const AddAmeActionRule = createAsyncThunk("AmeActionRule/AddAmeActionRule", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
 
    try {
      const {data} = await ntxAPI.post("AmeSetting/AddAmeActionRule" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

  export const GetAmeActionRule = createAsyncThunk("AmeActionRule/GetAmeActionRule", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.get(`AmeSetting/GetAmeActionRuleListByRuleId?RuleId=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });


  export const DeleteAmeActionRule = createAsyncThunk("AmeActionRule/DeleteAmeActionRule", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.post(`AmeSetting/DeleteAmeActionRule?Id=${id}` )
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });












  const initialState = { ActionRuleListByRuleId: [], loading: false, error: null };

  const AmeActionRuleSlice = createSlice({
    name: "AmeActionRule",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        // Get AME Action Rule
        .addCase(GetAmeActionRule.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(GetAmeActionRule.fulfilled, (state, action) => {
          state.loading = false;
          state.ActionRuleListByRuleId = action.payload;
        })
        .addCase(GetAmeActionRule.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
    
        // Insert AME Action Rule
        .addCase(AddAmeActionRule.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(AddAmeActionRule.fulfilled, (state, action) => {
          state.loading = false;
          state.ActionRuleListByRuleId.push(action.payload);
        })
        .addCase(AddAmeActionRule.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
    
        // Delete AME Action Rule
        .addCase(DeleteAmeActionRule.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(DeleteAmeActionRule.fulfilled, (state, action) => {
          state.loading = false;
          state.ActionRuleListByRuleId = state.ActionRuleListByRuleId?.filter((el) => el.id !== action.payload);
        })
        .addCase(DeleteAmeActionRule.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    }
  });
  
  export default AmeActionRuleSlice.reducer;