import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';

// import { getData, postData } from "../../Axios/axiosData";

export const GetAssignmentByPersonId = createAsyncThunk(
  'Assignments/GetAssignmentByPersonId',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`Persons/GetAssignmentByPersonId?PersonId=${id}`);
      const { data } = await ntxAPI.get(`Persons/GetAssignmentByPersonId?PersonId=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const AddAssignment = createAsyncThunk(
  'Assignments/AddAssignment',
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("Persons/AddUpdateAssignment" , item);
      const { data } = await ntxAPI.post('Persons/AddUpdateAssignment', item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetAssignmentById = createAsyncThunk(
  'Assignments/GetAssignmentById',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`Persons/GetAssignmentById?Id=${id}`);
      const { data } = await ntxAPI.get(`Persons/GetAssignmentById?Id=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getAssignmentStatusList = createAsyncThunk(
  'Assignments/getAssignmentStatusList',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await ntxAPI.get('Persons/GetAssignmentStatusList');
      if (data.status === 200) {
        return data.data;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  AssignmentPerson: [],
  AssignmentListById: [],
  AssignmentStatusList: [],
  loading: false,
  isLoading: false,
  error: null,
};

const AssignmentSlice = createSlice({
  name: 'Assignments',
  initialState,
  reducers: {
    cleanAssignmentListById: (state) => {
      state.AssignmentListById = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Assignment by Person ID
      .addCase(GetAssignmentByPersonId.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(GetAssignmentByPersonId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.AssignmentPerson = action.payload;
      })
      .addCase(GetAssignmentByPersonId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Get Assignment by ID
      .addCase(GetAssignmentById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetAssignmentById.fulfilled, (state, action) => {
        state.loading = false;
        state.AssignmentListById = action.payload;
      })
      .addCase(GetAssignmentById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Get Assignment by ID
      .addCase(getAssignmentStatusList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAssignmentStatusList.fulfilled, (state, action) => {
        state.loading = false;
        state.AssignmentStatusList = action.payload;
      })
      .addCase(getAssignmentStatusList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { cleanAssignmentListById } = AssignmentSlice.actions;
export default AssignmentSlice.reducer;
