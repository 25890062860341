import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import { Card, CardBody } from "../../../../components/card/card";
import Pagination from "../../../../components/Shared/Pagination/Pagination";
import { useTranslation } from "react-i18next";
 
import { Link, useNavigate } from "react-router-dom";
// import MedModalComponent from "../../../../components/Modal/MedModalComponent";
import useGetOrganizations from "../../../../hooks/HR/use-get-organizations";
import Loader from "../../../../components/Shared/Loader/Loader";
import useGetMaterialTransactionsList from "../../../../hooks/INV/useGetMaterialTransactionsList";

const Index = () => {
  // const [Show, setShow] = useState(false);
  // const [inventoryId, setInventoryId] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  // const { Organizations, loading } = useGetOrganizations();

  const {MaterialTransactionsList , loading} = useGetMaterialTransactionsList();


  const filteredSearch = MaterialTransactionsList?.slice()
    ?.filter((item: any) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.filter((f: any) => f.type === "130");

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);
  //________________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "إذن تسليــم",
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="إذن تسليــم" />

        <div className="ms-auto">
          <Link
            to="AddDeliveryPermission"
            className="btn me-1 btn-outline-theme"
 
            // onClick={() => setShow(true)}
          >
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t("إذن تسليم جديــد")}
          </Link>
        </div>
      </div>

      <Card  >
        
          <Loader loading={loading} />
        
          <CardBody>
            <div className="row">
              <div className="col-7 col-md-6 d-flex justify-content-start">
                <div className="input-group mb-4">
                  <div className="flex-fill position-relative">
                    <div className="input-group">
                      <div
                        className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                        style={{ zIndex: 1020, right: "10px" }}
                      >
                        <i className="fa fa-search opacity-5"></i>
                      </div>
                      <input
                        type="text"
                        className="form-control ps-35px"
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={t("Search") + " ..."}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* //  START TABLE   */}
            <div className="table-responsive">
              <table className="table">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">{t("Name")}</th>

                    <th scope="col">{t("location")}</th>
                    <th scope="col"></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((item: any, index: number) => (
                    <tr key={++index}>
                      <td className="align-middle">
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </td>
                      <td className="align-middle">
                        {i18n.language === "ar"
                          ? item.locationName2
                          : item.locationName}
                      </td>
                      <td className="align-middle">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-outline-theme"
                            data-bs-toggle="modal"
                            data-bs-target="#modalUpdateInventory"
                            // onClick={() => setInventoryId(item.id)} //Departments/:id/Edit
                          >
                            <i className="far fa-lg me-2 fa-fw fa-edit"></i>
                            {t("Button.Edit")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-theme"
                            //  data-bs-toggle="modal"
                            //  data-bs-target="#modalUpdateInventory"
                            onClick={() => Navigate(`${item.id}/SubInventory`)}

                            //
                          >
                            <i className="far fa-lg me-2 bi bi-shop-window "></i>
                            المخازن الفرعية
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </CardBody>
        
      </Card>

      {/* <MedModalComponent title={t("اضافة مخزن")} id="modalِAddInventory">
        {Show && <AddInventory />}
      </MedModalComponent>

      <MedModalComponent title={t("تعديل مخزن")} id="modalUpdateInventory">
        {inventoryId && <UpdateInventory inventoryId={inventoryId} />}
      </MedModalComponent> */}
    </div>
  );
};

export default Index;
