import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getTransactionsInvoiceList } from '../../store/Sales/SalesInvoices/salesInvoicesSlice';

const useGetInvoiceList = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (SalesInvoicesList?.length === 0) {
      dispatch(getTransactionsInvoiceList());
    }
  }, []);
  const { SalesInvoicesList, loading, error } = useAppSelector((state) => state.SalesInvoices);

  return { SalesInvoicesList, loading, error };
};

export default useGetInvoiceList;
