


import { createAsyncThunk, createSlice , PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { PrintTemplateColumnsModels, PrintTemplateModels, PrintTemplateTypeModels , PrintTemplateByType} from "./PrintTemplateModels";
// import { getData, postData } from "../../Axios/axiosData";

export const AddNtxPrintTemplate = createAsyncThunk(
    "PrintTemplate/AddNtxPrintTemplate",
    async (item, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;      
      try {
        const {data} = await ntxAPI.post("Reports/AddPrintTemplate" , item)
        return data;
      } catch (error: any) {
        console.error(error );
        return rejectWithValue(error.message);
      }
    }
  );


 


export const AddUpdatePrintTemplateFile = createAsyncThunk(
    "PrintTemplate/AddUpdatePrintTemplateFile",
    async (item, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        // debugger
        const {data} = await ntxAPI.post("Reports/AddUpdatePrintTemplateFile" , item)
        return data;
      } catch (error: any) {
        console.error(error );
        return rejectWithValue(error.message);
      }
    }
  );
  

export const UpdatNtxPrintTemplate = createAsyncThunk(
    "PrintTemplate/UpdatNtxPrintTemplate",
    async (item, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        // debugger
        const {data} = await ntxAPI.post("Reports/UpdatPrintTemplate" , item)
        return data;
      } catch (error: any) {
        console.error(error );
        return rejectWithValue(error.message);
      }
    }
  );
  



 export const GetNtxPrintTemplatesList = createAsyncThunk(
  "PrintTemplate/GetNtxPrintTemplatesList",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.get( "Reports/GetNtxPrintTemplatesList");
      if(data?.succeeded){
        return data.data;
      }else {
        return rejectWithValue(data?.message || "هناك خطأ");
      }
    } catch (error : any) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
); 

  


 export const GetReportPrintTemplateTypeList = createAsyncThunk(
  "PrintTemplate/GetReportPrintTemplateTypeList",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.get( "Reports/GetReportPrintTemplateTypeList");
      return data.data;
    } catch (error : any) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
); 



 export const GetPrintTemplateColumnsById = createAsyncThunk(
  "PrintTemplate/GetPrintTemplateColumnsById",
  async (Id : string, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // Reports/GetPrintTemplateColumnsById?Id=9134aa6b-6cd7-4a24-b19b-ba8adcf81898
      const {data} = await ntxAPI.get(`Reports/GetPrintTemplateColumnsById?Id=${Id}`);
      return data.data;
    } catch (error : any) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
); 


 export const GetPrintTemplateById = createAsyncThunk(
  "PrintTemplate/GetPrintTemplateById",
  async (Id : string, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      //GetPrintTemplateById?Id=a59ef5a5-bea6-4137-989b-808a611d2262
      const {data} = await ntxAPI.get(`Reports/GetPrintTemplateById?Id=${Id}`);
      return data.data;
    } catch (error : any) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
); 



 export const GetPrintTemplateByType = createAsyncThunk(
  "PrintTemplate/GetPrintTemplateByType",
  async (Id : string, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.get(`Reports/GetPrintTemplateByType?PrintTemplateTypeId=${Id}`);
      return data.data;
    } catch (error : any) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
); 




  
const initialState: {
    PrintTemplateList : PrintTemplateModels[]
    PrintTemplateById : PrintTemplateModels | null
    PrintTemplateColumnsList : PrintTemplateColumnsModels 
    PrintTemplateTypeList? : PrintTemplateTypeModels [], 
    PrintTemplateByType? : PrintTemplateByType [], 
    loading: boolean;
    loadingUpdate: boolean;
    error: any | null;
  } = {
    PrintTemplateList:[],
    PrintTemplateById : {} as PrintTemplateModels ,
    PrintTemplateColumnsList : {} as PrintTemplateColumnsModels ,
    loading: false,
    loadingUpdate: false,
    error: null,
  };



  const PrintTemplateSlice = createSlice({
    name: "PrintTemplate",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder

      .addCase(GetPrintTemplateById.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear any previous errors when creating a post
      })
      .addCase(GetPrintTemplateById.fulfilled, (state, action:PayloadAction<PrintTemplateModels>) => {
        state.loading = false;
        state.PrintTemplateById = action.payload
      })
      .addCase(GetPrintTemplateById.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


      .addCase(GetNtxPrintTemplatesList.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear any previous errors when creating a post
      })
      .addCase(GetNtxPrintTemplatesList.fulfilled, (state, action:PayloadAction<PrintTemplateModels[]>) => {
        state.loading = false;
        state.PrintTemplateList = action.payload
      })
      .addCase(GetNtxPrintTemplatesList.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


      ////Get Report Print Template Type List
      .addCase(GetReportPrintTemplateTypeList.pending, (state) => {
        state.loadingUpdate = true;
        state.error = null; // Clear any previous errors when creating a post
      })
      .addCase(GetReportPrintTemplateTypeList.fulfilled, (state, action:PayloadAction<PrintTemplateTypeModels[]>) => {
        state.loadingUpdate = false;
        state.PrintTemplateTypeList = action.payload
      })
      .addCase(GetReportPrintTemplateTypeList.rejected, (state, action:PayloadAction<any>) => {
        state.loadingUpdate = false;
        state.error = action.payload;
      })



      ////Get Report Print Template Type List
      .addCase(GetPrintTemplateColumnsById.pending, (state) => {
        state.loadingUpdate = true;
        state.error = null; // Clear any previous errors when creating a post
      })
      .addCase(GetPrintTemplateColumnsById.fulfilled, (state, action:PayloadAction<PrintTemplateColumnsModels>) => {
        state.loadingUpdate = false;
        state.PrintTemplateColumnsList = action.payload
      })
      .addCase(GetPrintTemplateColumnsById.rejected, (state, action:PayloadAction<any>) => {
        state.loadingUpdate = false;
        state.error = action.payload;
      })


      ////Get Report Print Template By Type List
      .addCase(GetPrintTemplateByType.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear any previous errors when creating a post
      })
      .addCase(GetPrintTemplateByType.fulfilled, (state, action:PayloadAction<PrintTemplateByType[]>) => {
        state.loading = false;
        state.PrintTemplateByType = action.payload
      })
      .addCase(GetPrintTemplateByType.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })







 

      

      
    },
  });
  
  export default PrintTemplateSlice.reducer;