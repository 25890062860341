import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import { GetNtxPeoplesList } from "../../../store/NTX/Peoples/peoplesSlice";
import useGetOrganizations from "../../../hooks/HR/use-get-organizations";
import { GetEquipmentsModelList } from "../../../store/FLEET/EquipmentsModel/equipmentsmodelSlice";
import CustomAlert from "../../../components/Shared/Alert/CustomAlert";
import {
  GetEquipmentsList,
  InsertEquipments,
} from "../../../store/FLEET/Equipments/equipmentsSlice";
import { GetEngineStatues } from "../../../store/Lookup/lookupSlice";
import { EquipmentsSchema } from "../../ValidationForm/validationSchema";

const AddEquipments = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GetNtxPeoplesList(""));
    dispatch(GetEquipmentsModelList());
    dispatch(GetEngineStatues());
  }, []);
  const { PeoplesList } = useAppSelector((state) => state.Peoples);
  const { EquipmentsModelList } = useAppSelector(
    (state) => state.EquipmentsModel
  );

  const { EngineStatues } = useAppSelector((state) => state.Lookup);

  const { Organizations } = useGetOrganizations();

  const formik = useFormik({
    initialValues: {
      name: "",
      name2: "",
      description: "",
      fleetModelId: "",
      plateNo: "",

      plateNoArabic: "",
      plateNoEnglish: "",
      
      serialNo: "",
      chassisNo: "",
      engineStatus: "",
      code: "",
      barcode: "",
      odometer: 0,
      organizationId: "",
      serviceDate: "",
      dateOfExpiry: "",
      purchasePrice: 0,
      ownerId: "",
      lastmaintenanceDate: "",
      driverId: "",
      Img: "",
      IsAddIMG: false,
      //______

      plateNoArabic1 : "",
      plateNoArabic2 : "",
      plateNoArabic3 : "",
      plateNoEnglish1 : "",
      plateNoEnglish2 : "",
      plateNoEnglish3 : "",
    },
    validationSchema: EquipmentsSchema(t),
    onSubmit: (values) => {
      const formData = new FormData();

      formData.append("Name", values.name);
      formData.append("Name2", values.name2);
      formData.append("Description", values.description);
      formData.append("FleetModelId", values.fleetModelId);
      formData.append("PlateNo", values.plateNo);
      formData.append("SerialNo", values.serialNo);
      formData.append("ChassisNo", values.chassisNo);
      formData.append("EngineStatus", values.engineStatus);
      formData.append("Code", values.code);
      formData.append("Barcode", values.barcode);
      formData.append("Odometer", values.odometer.toString());
      formData.append("OrganizationId", values.organizationId);
      formData.append("ServiceDate", values.serviceDate);
      formData.append("DateOfExpiry", values.dateOfExpiry);
      formData.append("PurchasePrice", values.purchasePrice.toString());
      formData.append("OwnerId", values.ownerId);
      formData.append("LastmaintenanceDate", values.lastmaintenanceDate);
      formData.append("DriverId", values.driverId);
      formData.append("Img", values.Img);
      // formData.append("IsAddIMG", values.IsAddIMG);
      formData.append("IsAddIMG", values.IsAddIMG.toString());

      dispatch(InsertEquipments(formData))
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            document.getElementById("closeModalForm")?.click();
            formik.resetForm();
            CustomAlert({ action: "Add" });
            dispatch(GetEquipmentsList());
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  const handleImageUpload = (event: any) => {
    formik.setFieldValue("Img", event.currentTarget.files[0]);
    formik.setFieldValue("IsAddIMG", true);
  };



  // ____
//   const arabicToEnglishMap: { [key: string]: string } = {
//     'ا': 'A', 'ب': 'B', 'ح': 'H', 'د': 'D', 'ر': 'R', 'س': 'S',
//     'ص': 'S', 'ط': 'T', 'ع': 'E', 'ف': 'F', 'ق': 'G', 'ك': 'K',
//     'ل': 'L', 'م': 'M', 'ن': 'N', 'و': 'O', 'ه': 'H', 'ي': 'Y',
//     '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4', '٥': '5',
//     '٦': '6', '٧': '7', '٨': '8', '٩': '9'
//   };

//   const englishToArabicMap: { [key: string]: string } = {
//     'A': 'ا', 'B': 'ب', 'H': 'ح', 'D': 'د', 'R': 'ر', 'S': 'س',
//     'T': 'ط', 'E': 'ع', 'F': 'ف', 'G': 'ق', 'K': 'ك', 'L': 'ل',
//     'M': 'م', 'N': 'ن', 'O': 'و', 'Y': 'ي', '0': '٠', '1': '١',
//     '2': '٢', '3': '٣', '4': '٤', '5': '٥', '6': '٦', '7': '٧',
//     '8': '٨', '9': '٩'
//   };

// const handleArabicPlateNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//   const { value } = event.target;
//   const englishValue = convertToEnglish(value);
//   formik.setFieldValue("plateNoArabic", value);
//   formik.setFieldValue("plateNoEnglish", englishValue);
// };

// const handleEnglishPlateNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//   const { value } = event.target;
//   const arabicValue = convertToArabic(value);
//   formik.setFieldValue("plateNoArabic", arabicValue);
//   formik.setFieldValue("plateNoEnglish", value);
// };


// const convertToEnglish = (arabicValue: string): string => {
//   return arabicValue.split('').map(char => arabicToEnglishMap[char] || char).join('');
// };

// const convertToArabic = (englishValue: string): string => {
//   return englishValue.split('').map(char => englishToArabicMap[char] || char).join('');
// };


  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-xl-2">
          <div className="form-group mb-3">
            <label className="form-label">{t("AssetNumber")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="code"
              onChange={formik.handleChange}
              value={formik.values.code}
              isInvalid={!!(formik.touched.code && formik.errors.code)}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.code}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-5">
          <div className="form-group mb-3">
            <label className="form-label">{t("LocalName")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name2"
              onChange={formik.handleChange}
              value={formik.values.name2}
              isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-5">
          <div className="form-group mb-3">
            <label className="form-label">{t("GlobalName")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isInvalid={!!(formik.touched.name && formik.errors.name)}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name2}
            </Form.Control.Feedback>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label"> {t("Model")}</label>
            <Form.Select
              className="form-select"
              name="fleetModelId"
              onChange={formik.handleChange}
              value={formik.values.fleetModelId || ""}
              isInvalid={
                !!(formik.touched.fleetModelId && formik.errors.fleetModelId)
              }
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {EquipmentsModelList &&
                EquipmentsModelList?.filter((f) => f.parentId !== null).map(
                  (item, idx) => (
                    <option key={++idx} value={item.id}>
                      {i18n.language === "ar" ? item.name2 : item.name}
                    </option>
                  )
                )}
            </Form.Select>
            {formik.errors.fleetModelId && formik.touched.fleetModelId && (
              <div>{formik.errors.fleetModelId}</div>
            )}
          </div>
        </div>

        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("Plate_Number")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="plateNo"
              onChange={formik.handleChange}
              // onChange={handlePlateNoChange}
              value={formik.values.plateNo}
              isInvalid={!!(formik.touched.plateNo && formik.errors.plateNo)}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.plateNo}
            </Form.Control.Feedback>
          </div>
        </div>

 
        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("Serial_Number")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="serialNo"
              onChange={formik.handleChange}
              value={formik.values.serialNo}
              isInvalid={!!(formik.touched.serialNo && formik.errors.serialNo)}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.serialNo}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("Chassis_Number")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="chassisNo"
              onChange={formik.handleChange}
              value={formik.values.chassisNo}
              isInvalid={
                !!(formik.touched.chassisNo && formik.errors.chassisNo)
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.chassisNo}
            </Form.Control.Feedback>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label"> {t("Engine_Status")}</label>
            <Form.Select
              className="form-select"
              name="engineStatus"
              onChange={formik.handleChange}
              value={formik.values.engineStatus || ""}
              isInvalid={
                !!(formik.touched.engineStatus && formik.errors.engineStatus)
              }
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {EngineStatues &&
                EngineStatues.map((item: any, idx) =>
                  item.list.map((list: any) => (
                    <option key={++idx} value={list.lookupCode}>
                      {i18n.language === "ar" ? list.meaning2 : list.meaning}
                    </option>
                  ))
                )}
            </Form.Select>
          </div>
        </div>

        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("Barcode")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="barcode"
              onChange={formik.handleChange}
              value={formik.values.barcode}
              isInvalid={!!(formik.touched.barcode && formik.errors.barcode)}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.barcode}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label"> {t("Odometer")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="odometer"
              onChange={formik.handleChange}
              value={formik.values.odometer}
              isInvalid={!!(formik.touched.odometer && formik.errors.odometer)}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.odometer}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label"> {t("Branch")}</label>
            <Form.Select
              className="form-select"
              name="organizationId"
              onChange={formik.handleChange}
              value={formik.values.organizationId || ""}
              isInvalid={
                !!(
                  formik.touched.organizationId && formik.errors.organizationId
                )
              }
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {Organizations &&
                Organizations?.filter((f: any) => f.type === "150").map(
                  (item: any, idx: number) => (
                    <option key={++idx} value={item.id}>
                      {i18n.language === "ar" ? item.name2 : item.name}
                    </option>
                  )
                )}
            </Form.Select>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("ServiceDate")}</label>
            <Form.Control
              className="form-control"
              type="date"
              name="serviceDate"
              onChange={formik.handleChange}
              value={formik.values.serviceDate}
              isInvalid={
                !!(formik.touched.serviceDate && formik.errors.serviceDate)
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.serviceDate}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("dateOfExpiry")}</label>
            <Form.Control
              className="form-control"
              type="date"
              name="dateOfExpiry"
              onChange={formik.handleChange}
              value={formik.values.dateOfExpiry}
              isInvalid={
                !!(formik.touched.dateOfExpiry && formik.errors.dateOfExpiry)
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.dateOfExpiry}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("Purchase_Price")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="purchasePrice"
              onChange={formik.handleChange}
              value={formik.values.purchasePrice}
              isInvalid={
                !!(formik.touched.purchasePrice && formik.errors.purchasePrice)
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.purchasePrice}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label"> {t("Owner")}</label>
            <Form.Select
              className="form-select"
              name="ownerId"
              onChange={formik.handleChange}
              value={formik.values.ownerId || ""}
              isInvalid={!!(formik.touched.ownerId && formik.errors.ownerId)}
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {PeoplesList &&
                PeoplesList?.filter((f) => f.typeCode === "OWNERS").map(
                  (item, idx) => (
                    <option key={++idx} value={item.id}>
                      {i18n.language === "ar" ? item.name2 : item.name}
                    </option>
                  )
                )}
            </Form.Select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label"> {t("Driver")}</label>
            <Form.Select
              className="form-select"
              name="driverId"
              onChange={formik.handleChange}
              value={formik.values.driverId || ""}
              isInvalid={!!(formik.touched.driverId && formik.errors.driverId)}
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {PeoplesList &&
                PeoplesList?.filter((f) => f.typeCode === "DRIVERS").map(
                  (item, idx) => (
                    <option key={++idx} value={item.id}>
                      {i18n.language === "ar" ? item.name2 : item.name}
                    </option>
                  )
                )}
            </Form.Select>
          </div>
        </div>

        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("LastMaintenanceDate")}</label>
            <Form.Control
              className="form-control"
              type="date"
              name="lastmaintenanceDate"
              onChange={formik.handleChange}
              value={formik.values.lastmaintenanceDate}
              isInvalid={
                !!(
                  formik.touched.lastmaintenanceDate &&
                  formik.errors.lastmaintenanceDate
                )
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.lastmaintenanceDate}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("Image_of_Equipment")}</label>
            <Form.Control
              className="form-control"
              type="file"
              name="Img"
              accept="image/*"
              onChange={(e) => handleImageUpload(e)}
              isInvalid={!!(formik.touched.Img && formik.errors.Img)}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Img}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("Note")}</label>
            <Form.Control
              className="form-control"
              as="textarea"
              rows={1}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
            />
          </div>
        </div>
      </div>


      {/* ////////////_________________________ */}











      <div className="text-center">
        <button
          disabled={formik.isSubmitting}
          type="submit"
          className="btn me-1 btn-theme mb-1"
          id="SaveSubmit"
        >
          {formik.isSubmitting ? (
            <div className="spinner-border spinner-border-sm me-2"></div>
          ) : (
            <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
          )}
          {t("Add")}
        </button>
        <button
          type="button"
          className=" btn me-1 btn-default mb-1"
          data-bs-dismiss="modal"
          id="closeModalForm"
        >
          <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
        </button>
      </div>
    </Form>
  );
};

export default AddEquipments;




// <div className="col-xl-3">
// <div className="form-group mb-3">
//   <label className="form-label">{t("Plate_Number_Arabic")}</label>
//   <div className="d-flex">
//     {['1', '2', '3'].map((num) => (
//       <Form.Control
//         key={`plateNoArabic${num}`}
//         className="form-control me-1"
//         type="text"
//         name={`plateNoArabic${num}`}
//         maxLength={1}
//         onChange={handleArabicPlateNoChange}
//         // value={formik.values[`plateNoArabic${num}`]}
//         // isInvalid={!!(formik.touched[`plateNoArabic${num}`] && formik.errors[`plateNoArabic${num}`])}
//       />
//     ))}
//   </div>
//   <Form.Control.Feedback type="invalid">
//     {formik.errors.plateNoArabic1 || formik.errors.plateNoArabic2 || formik.errors.plateNoArabic3}
//   </Form.Control.Feedback>
// </div>
// </div>

// <div className="col-xl-3">
// <div className="form-group mb-3">
//   <label className="form-label">{t("Plate_Number_English")}</label>
//   <div className="d-flex">
//     {['1', '2', '3'].map((num) => (
//       <Form.Control
//         key={`plateNoEnglish${num}`}
//         className="form-control me-1"
//         type="text"
//         name={`plateNoEnglish${num}`}
//         maxLength={1}
//         onChange={handleEnglishPlateNoChange}
//         // value={formik.values[`plateNoEnglish${num}`]}
//         // isInvalid={!!(formik.touched[`plateNoEnglish${num}`] && formik.errors[`plateNoEnglish${num}`])}
//       />
//     ))}
//   </div>
//   <Form.Control.Feedback type="invalid">
//     {formik.errors.plateNoEnglish1 || formik.errors.plateNoEnglish2 || formik.errors.plateNoEnglish3}
//   </Form.Control.Feedback>
// </div>
// </div>

// <div className="col-xl-3">
// <div className="form-group mb-3">
//   <label className="form-label">{t("Plate_Number_Number")}</label>
//   <Form.Control
//     className="form-control"
//     type="text"
//     name="plateNoNumber"
//     maxLength={3}
//     onChange={formik.handleChange}
//     // value={formik.values.plateNoNumber}
//     // isInvalid={!!(formik.touched.plateNoNumber && formik.errors.plateNoNumber)}
//   />
//   {/* <Form.Control.Feedback type="invalid">
//     {formik.errors.plateNoNumber}
//   </Form.Control.Feedback> */}
// </div>
// </div>

