
import React, { Fragment } from "react";
import AddDepartment from "../../HR/Departments/AddDepartment";

const AddWorkshop = () => {


//   {
//     "meaning2": "فرع",
//     "meaning": "Branch",
//     "lookupCode": "150"
// },

  return (
<Fragment>
  <AddDepartment type="150"/>
</Fragment>
  );
};

export default AddWorkshop;
