 
import { store } from "../../store";

   

export const HasPermission = (permission: string) => {
  const Permission =  store.getState()?.userLogin?.AuthUser?.permission
  const permissionsArray = Permission?.split(",");
  return permissionsArray?.includes(permission);
};







