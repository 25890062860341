import React from 'react';
import { Form } from 'react-bootstrap';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import { useFormik } from 'formik';
import SwalAlert from '../../../../../components/Shared/Alert/SwalAlert';
import { useAppDispatch } from '../../../../../store/hooks';
import {
  addPurchasesReturns,
  getPurchasesReturnsById,
} from '../../../../../store/purchases/PurchaseReturn/purchasesReturnsSlice';
import { useTranslation } from 'react-i18next';
import AddReturnLines from './AddReturnLines';
import { AddSaleReturnsSchema } from '../../../../ValidationForm/validationSchema';
import { useParams } from 'react-router-dom';
import { CardFooter } from '../../../../../components/card/card';
import { PurchasesReturnsModels } from '../../../../../store/purchases/PurchaseReturn/purchasesReturnModels';

type ReturnFormProps = {
  // data: PurchasesReturnsModels[];
  data: PurchasesReturnsModels;
};

const AddReturn = ({ data }: ReturnFormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { BillId } = useParams();

  //#region Formik
  const formik = useFormik({
    initialValues: {
      isAme: false,
      categoryId: null,

      organizationId: data.organizationId ?? '',
      peopleId: data.peopleId ?? '',
      recipientname: data.recipientname ?? '',
      billToLocationId: data.billToLocationId ?? '',
      shipToLocationId: data.shipToLocationId ?? '',
      referenceId: data.id ?? '',
      // transactionDate: data.transactionDate ?? '', //???
      transactionDate: new Date().toISOString().split('T')[0], // تاريخ الفاتورة

      deliveryDate: data.deliveryDate ?? '',
      expirationDate: data.expirationDate ?? '',
      dueDate: data.dueDate ?? '',
      paymentTermsId: data.paymentTermsId ?? '',
      paymentMethodId: data.paymentMethodId ?? '',
      description: data.description ?? '',
      grossAmount: data.grossAmount ?? '',
      discountRate: data.discountRate ?? '',
      discountAmount: data.discountAmount ?? '',
      vat: data.vat ?? '',
      netAmount: data.netAmount ?? '',
      contractId: data.contractId ?? '',
      ameStatus: data.ameStatus ?? '',
      personId: data.personId ?? '',
      paymentTerms: data.paymentTerms ?? '',
      deliveryTerm: data.deliveryTerm ?? '',
      inventoryId: data.inventoryId ?? '',
      waybill: data.waybill ?? '',
      phone: data.phone ?? '',
      currencyCode: data.currencyCode ?? '',
      currencyConversionRate: data.currencyConversionRate ?? '',
      batchId: data.batchId ?? '',

      transactionsLinesList:
        data?.transactionsLinesList?.map((item: any) => ({
          id: item.id,
          // transactionId: item.transactionId,
          itemId: item.itemId,
          lineNumber: item.lineNumber,
          uom: item.uom,
          description: item.description,
          price: item.price,
          qty: item.qty,
          discountRate: item.discountRate,
          discountAmount: item.discountAmount,
          vatRate: item.vatRate,
          total: item.total,
          vatAmount: item.vatAmount,
          // unitCost: item.unitCost,
          // codeCombinationId: item.codeCombinationId,
          // currencyCode: item.currencyCode,
          // currencyConversionRate: item.currencyConversionRate,
          // lotId: item.lotId,
          // headerId: item.headerId,
          // lineId: item.lineId,
          inventoryId: item.inventoryId,
          ReturnQty: 0,
          selectedRow: false,
        })) ?? [],
    },

    enableReinitialize: true,
    validationSchema: AddSaleReturnsSchema(t),
    onSubmit: (values) => {
      // const grossSale = values.transactionsLinesList.reduce(
      //   (s: any, a: any) => Number(s) + Number(a.total),
      //   0
      // );

      if (values?.transactionsLinesList?.filter((f: any) => f.selectedRow === true)?.length === 0) {
        SwalAlert({ text: 'حدد الأصناف للإسترداد ' });
        formik.setSubmitting(false);

        return;
      }

      dispatch(
        addPurchasesReturns({
          isAme: false,
          categoryId: null,

          organizationId: values.organizationId,
          peopleId: values.peopleId,
          recipientname: values.recipientname,
          billToLocationId: null,
          shipToLocationId: null,
          referenceId: values.referenceId,
          transactionDate: values.transactionDate,
          deliveryDate: values.deliveryDate || null,
          expirationDate: values.expirationDate || null,
          dueDate: values.dueDate || null,
          paymentTermsId: null,
          paymentMethodId: null,
          description: values.description,
          grossAmount: 0,
          discountRate: values.discountRate || 0,
          discountAmount: values.discountAmount || 0,
          vat: values.vat || 0,
          netAmount: 0,
          contractId: values.contractId || null,
          ameStatus: values.ameStatus || 0,
          personId: values.personId,
          paymentTerms: values.paymentTerms,
          deliveryTerm: values.deliveryTerm,
          inventoryId: values.inventoryId,
          waybill: values.waybill,
          phone: values.phone,
          currencyCode: values.currencyCode,
          currencyConversionRate: values.currencyConversionRate || 0,
          batchId: values.batchId || null,

          transactionsLinesList: values.transactionsLinesList
            .filter((f: any) => f.selectedRow === true)
            .map((item: any, index: any) => ({
              // transactionId: null,
              itemId: item.itemId,
              lineNumber: ++index,
              // lineNumber: item.lineNumber,
              uom: item.uom,
              description: item.description,
              price: Number(item.price),
              qty: Number(item.ReturnQty),
              discountRate: Number(item.discountRate),
              discountAmount: Number(item.discountAmount),
              vatRate: Number(item.vatRate),
              total: Number(item.total),
              vatAmount: Number(item.vatAmount),
              // unitCost: Number(item.unitCost),
              // codeCombinationId: null,
              codeCombinationId: '54F915DF-8A22-486D-B860-08DC2A376B50',
              // currencyCode: 'SR',
              // currencyConversionRate: 0,
              // lotId: null,
              // headerId: null,
              // lineId: null,
              inventoryId: values.inventoryId,
            })),
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            formik.resetForm();

            dispatch(getPurchasesReturnsById(BillId ?? ''));
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched } = formik;
  //#endregion

  // console.log(data);

  return (
    <div>
      <Form onSubmit={formik.handleSubmit} className="p-1">
        <div className="row">
          <div className="col-lg-3">
            <div className="form-group mb-3">
              <label className="form-label">{t('تاريخ الإسترداد')}</label>
              <Form.Control
                className="form-control  text-center"
                type="date"
                name="transactionDate"
                onChange={formikhandleChange}
                value={values.transactionDate || ''}
                isInvalid={!!(touched.transactionDate && errors.transactionDate)}
              />
              <div className="text-danger">{touched.transactionDate && errors.transactionDate}</div>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="form-group mb-3">
              <label className="form-label">{t('Note')}</label>
              <Form.Control
                autoComplete="off"
                className="form-control"
                as="textarea"
                rows={1}
                name="description"
                onChange={formik.handleChange}
                value={values.description}
                isInvalid={!!(touched.description && errors.description)}
              />
            </div>
          </div>
        </div>

        <CardFooter>
          <AddReturnLines formik={formik} />
        </CardFooter>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn btn-lg me-1 btn-theme mb-1">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            إضافة مردود
          </button>
          <button type="button" className=" btn btn-lg me-1 btn-default mb-1">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default AddReturn;
