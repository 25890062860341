import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { GetTablesForm } from "../../store/NTX/FlexValue/flexValueSetSlice";

const useGetTablesForm = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetTablesForm());
  }, []);

  const { TablesFormList, loading, error } = useSelector(
    (state) => state.FlexValueSet
  );

  const dataTablesFormList =
    TablesFormList &&
    TablesFormList.map((item) => ({
      value: item.id,
      label: i18n.language === "ar" ? item.name2 : item.name,
    }));

  return { TablesFormList, dataTablesFormList, loading, error };
};

export default useGetTablesForm;
