import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';

export const GetAbsenceAttendanceType = createAsyncThunk(
  'Absence/GetAbsenceAttendanceType',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.get(`Persons/GetAbsenceAttendanceTypeList?PersonId=${id}`);
      if(data?.succeeded){
        return data.data
      } else {
        return rejectWithValue('There are error in GetAbsenceAttendanceTypeList');
      }
      // return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const AddAbsenceAttendance = createAsyncThunk(
  'Absence/AddAbsenceAttendance',
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.post(`Persons/AddAbsenceAttendance`, item);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetAbsenceAttendance = createAsyncThunk(
  'Absence/GetAbsenceAttendance',
  async (PersonId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.get(
        `Persons/GetAbsenceAttendanceByPersonId?PersonId=${PersonId}`
      );
      // debugger
      // if(data?.succeeded === true) {
      //   return data?.data;
      // } else {
      //   return []
      // }

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const DeleteAbsenceAttendance = createAsyncThunk(
  'Absence/DeleteAbsenceAttendance',
  async (Id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.post(`Persons/DeleteAbsenceAttendance?Id=${Id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetAbsenceAttendanceView = createAsyncThunk(
  'Absence/GetAbsenceAttendanceView',
  async (Id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.get(
        `Persons/GetAbsenceAttendanceById?AbsenceAttendanceId=${Id}`
      );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  AbsenceAttendanceList: [],
  AbsenceAttendanceTypeList: [],
  AbsenceAttendanceViewList: [],
  loading: false,
  error: null,
};

const AbsenceSlice = createSlice({
  name: 'Absence',
  initialState,
  reducers: {
    cleanAbsenceAttendanceTypeList: (state) => {
      state.AbsenceAttendanceTypeList = [] ;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAbsenceAttendanceType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetAbsenceAttendanceType.fulfilled, (state, action) => {
        state.loading = false;
        state.AbsenceAttendanceTypeList = action.payload;
      })
      .addCase(GetAbsenceAttendanceType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(AddAbsenceAttendance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddAbsenceAttendance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(GetAbsenceAttendance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetAbsenceAttendance.fulfilled, (state, action) => {
        state.loading = false;
        state.AbsenceAttendanceList = action.payload;
      })
      .addCase(GetAbsenceAttendance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(DeleteAbsenceAttendance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(DeleteAbsenceAttendance.fulfilled, (state, action) => {
        state.loading = false;
        state.records = state.AbsenceAttendanceList.historyAttendances?.filter(
          (el) => el.id !== action.payload
        );
      })
      .addCase(DeleteAbsenceAttendance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(GetAbsenceAttendanceView.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.AbsenceAttendanceViewList = [];
      })
      .addCase(GetAbsenceAttendanceView.fulfilled, (state, action) => {
        state.loading = false;
        state.AbsenceAttendanceViewList = action.payload;
      })
      .addCase(GetAbsenceAttendanceView.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});


export const {cleanAbsenceAttendanceTypeList} = AbsenceSlice.actions
export default AbsenceSlice.reducer;
