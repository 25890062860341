import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import {  CardExpandToggler } from '../../../components/card/card';

function LineChart({data}) {

  console.log();
  var id = data.reportChartId;
  const chartRef = useRef(null);

  useEffect(() => {
    var themeColor = (getComputedStyle(document.body).getPropertyValue('--bs-theme')).trim();
		var themeColorRgb = (getComputedStyle(document.body).getPropertyValue('--bs-theme-rgb')).trim();
		var gray900 = (getComputedStyle(document.body).getPropertyValue('--bs-gray-900')).trim();
		var gray900Rgb = (getComputedStyle(document.body).getPropertyValue('--bs-gray-900-rgb')).trim();
		var gray800Rgb = (getComputedStyle(document.body).getPropertyValue('--bs-gray-800-rgb')).trim();
		var gray500 = (getComputedStyle(document.body).getPropertyValue('--bs-gray-500')).trim();
		var gray500Rgb = (getComputedStyle(document.body).getPropertyValue('--bs-gray-500-rgb')).trim();
		var gray300Rgb = (getComputedStyle(document.body).getPropertyValue('--bs-gray-300-rgb')).trim();
		
		var bodyBg = (getComputedStyle(document.body).getPropertyValue('--bs-body-bg')).trim();
		var bodyFontFamily = (getComputedStyle(document.body).getPropertyValue('--bs-body-font-family')).trim();
		var bodyFontWeight = (getComputedStyle(document.body).getPropertyValue('--bs-body-font-weight')).trim();
		var bodyColor = (getComputedStyle(document.body).getPropertyValue('--bs-body-color')).trim();
		var borderColor = (getComputedStyle(document.body).getPropertyValue('--bs-border-color')).trim();
		var inverse = (getComputedStyle(document.body).getPropertyValue('--bs-inverse')).trim();
		var inverseRgb = (getComputedStyle(document.body).getPropertyValue('--bs-inverse-rgb')).trim();

    Chart.defaults.font.family = bodyFontFamily;
		Chart.defaults.font.size = 12;
		Chart.defaults.color = bodyColor;
		Chart.defaults.borderColor = borderColor;
		Chart.defaults.plugins.legend.display = true;
		Chart.defaults.plugins.tooltip.padding = { left: 8, right: 12, top: 8, bottom: 8 };
		Chart.defaults.plugins.tooltip.cornerRadius = 8;
		Chart.defaults.plugins.tooltip.titleMarginBottom = 6;
		Chart.defaults.plugins.tooltip.titleFont.family = bodyFontFamily;
		Chart.defaults.plugins.tooltip.titleFont.weight = bodyFontWeight;
		Chart.defaults.plugins.tooltip.footerFont.family = bodyFontFamily;
		Chart.defaults.plugins.tooltip.displayColors = true;
		Chart.defaults.plugins.tooltip.boxPadding = 6;
		Chart.defaults.scale.grid.color = borderColor;
		Chart.defaults.scale.beginAtZero = true;




    let newChart = null;
 
		

    if (chartRef.current) {
      // Destroy previous chart instance
      if (chartRef.current.chartInstance) {
        chartRef.current.chartInstance.destroy();
      }

      // Create new chart instance
     newChart = new Chart(chartRef.current, {
      type: 'line',
      data: {
        labels: data?.kpiNames?.map((item) => item) || [],
        datasets: data?.data?.map((item)=> (
          {
            // color: themeColor,
            // backgroundColor: 'rgba('+ themeColorRgb +', .2)',
            // borderColor: themeColor,
            borderWidth: 1.5,
            // pointBackgroundColor: bodyBg,
            pointBorderWidth: 1.5,
            pointRadius: 4,
            pointHoverBackgroundColor: bodyBg,
            pointHoverBorderColor: themeColor,
            pointHoverRadius: 7,
            label: item.name ,
            data: item?.data?.map((subItem) => subItem.value),
          }
        ))
        //  [{
          // color: themeColor,
          // backgroundColor: 'rgba('+ themeColorRgb +', .2)',
          // borderColor: themeColor,
          // borderWidth: 1.5,
          // pointBackgroundColor: bodyBg,
          // pointBorderWidth: 1.5,
          // pointRadius: 4,
          // pointHoverBackgroundColor: bodyBg,
          // pointHoverBorderColor: themeColor,
          // pointHoverRadius: 7,
          // label: 'Total Sales' ,
          // data: [12, 19, 4, 5, 2, 3]
        // }]
        // datasets: [{
        //   color: themeColor,
        //   backgroundColor: 'rgba('+ themeColorRgb +', .2)',
        //   borderColor: themeColor,
        //   borderWidth: 1.5,
        //   pointBackgroundColor: bodyBg,
        //   pointBorderWidth: 1.5,
        //   pointRadius: 4,
        //   pointHoverBackgroundColor: bodyBg,
        //   pointHoverBorderColor: themeColor,
        //   pointHoverRadius: 7,
        //   label: 'Total Sales' ,
        //   data: [12, 19, 4, 5, 2, 3]
        // }]
      }
    });

      // Save the chart instance in the ref
      chartRef.current.chartInstance = newChart;
    }
    
        // Cleanup function
        return () => {
          if (newChart) {
            newChart.destroy();
          }
        };
    // eslint-disable-next-line
  }, []);
  
  return (
    <div>

<div className="d-flex fw-bold small p-3 bg-theme bg-opacity-20">
        <span className="flex-grow-1">{data?.title}</span>
        <CardExpandToggler />
      </div>

      <canvas id={id} ref={chartRef} className='p-1'></canvas>
    </div>
  )
}

export default LineChart;