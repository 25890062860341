import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import { useParams } from 'react-router-dom';
import useGetDiscountVoucherTypesList from '../../../hooks/Sales/Vouchers/useGetDiscountVoucherTypesList';

import BuyXgetY from './BuyXgetY/Index';

const VoucherType = () => {
  const { t, i18n } = useTranslation();
  // const [show, setShow] = useState(false);
  const [voucherTypeName, setVoucherTypeName] = useState<string | undefined>();
  const { voucherTypeId } = useParams();
  const { DiscountVoucherTypesList: VoucherTypesList, loading: loading2 } =
    useGetDiscountVoucherTypesList();


  useEffect(() => {
    const voucherType = VoucherTypesList.find((f) => f.id === voucherTypeId);
    const name = i18n.language === 'en' ? voucherType?.name : voucherType?.name2;
    setVoucherTypeName(name || '');
  }, [voucherTypeId, VoucherTypesList, i18n.language]);

  const getComponent = () => {
    switch (voucherTypeId) {
      case 'a6dc19de-e06f-44c4-b977-eceb457a7976': // اشترِ س، واحصل على ص
        return <BuyXgetY />;
      case '1a0eb9fb-b164-4a60-a6d0-16008fcb43f8':
        return <></>;
        break;
      case '55d69c47-c3b0-4831-a046-75a144fd68ce':
        return <></>;
        break;
      case '6a5742f7-6a1f-4dc0-b6b2-7c915e783a52':
        return <></>;
        break;
      case '9b23dba7-3956-4f18-9a71-d7866b7c8ab4':
        return <></>;
        break;
      case '2ad37011-c6c3-4987-a92a-e99830edf2f1':
        return <></>;
        break;

      default:
        return <></>;
    }
  };

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'القسـائم',
      link: '/SalesSettings/Vouchers2',
    },
    {
      name: voucherTypeName,
      link: null,
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb
          BreadcrumbList={BcrumbList}
          // PageName={t('القسـائم')}
          subtitle={voucherTypeName}
        />
      </div>

      <>{getComponent()}</>
    </>
  );
};

export default VoucherType;

// {/* <NeatixModal size="md" title="الكوبونات" setShow={setShow} show={show}>
//   <Coupons formik={formik} />
// </NeatixModal> */}
