export default function Logo({ themeClass }) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 3045.33 899.46"
      style={{width:"100%", height:"50px", enableBackground: "new 0 0 3045.33 899.46" }}
      xmlSpace="preserve"
    >
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            "\n\t.st0{fill:none;stroke:#00AEEF;stroke-width:13;stroke-miterlimit:10;}\n\t.st1{fill:#00AEEF;}\n\t.st2{fill:#585B5C;}\n\t.st3{fill:url(#SVGID_1_);}\n\t.st4{fill:url(#SVGID_00000156548320494318410060000012931984756485154736_);}\n\t.st5{fill:url(#SVGID_00000023959217392371925430000014255843161057470890_);}\n\t.st6{fill:url(#SVGID_00000147189732477368584280000005766575589944197775_);}\n\t.st7{fill:url(#SVGID_00000089575433400735580260000018107795201370153100_);}\n\t.st8{fill:url(#SVGID_00000069372878875695661980000003005293892376879277_);}\n\t.st9{fill:url(#SVGID_00000093878835719546492320000010405933725265633153_);}\n\t.st10{fill:url(#SVGID_00000075125242419640925740000000568929769812167040_);}\n\t.st11{fill:url(#SVGID_00000116936323463821384310000017197611303774940062_);}\n\t.st12{fill:url(#SVGID_00000131338747733275563700000001595301886628056764_);}\n",
        }}
      />
      <rect
        x="2266.98"
        y="150.75"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 434.4834 1943.8643)"
        className="st0"
        width="593.43"
        height="593.43"
      />
      <g>
        <path
          className={themeClass}
          d="M98.87,580.32c7.96,0,11.95-4.37,11.95-13.11V236.63c0-8.74-3.99-13.11-11.95-13.11v-13.11h107.53v13.11
          c-3.36,0-5.03,2.44-5.03,7.28c0,3.89,4.19,10.68,12.58,20.39l180.48,216.99V236.63c0-8.74-5.45-13.11-16.35-13.11v-13.11h94.96
          v13.11c-7.97,0-11.95,4.37-11.95,13.11v330.58c0,8.74,3.98,13.11,11.95,13.11v13.11H371.16v-13.11c5.44,0,8.17-2.18,8.17-6.55
          c0-3.39-3.36-8.98-10.06-16.75L177.48,325.46v241.75c0,8.74,4.81,13.11,14.46,13.11v13.11H98.87V580.32z"
        />
        <path
          className={themeClass}
          d="M849.71,289.05c0-5.34-1.79-9.34-5.35-12.01c-3.57-2.66-9.75-4-18.55-4H622.07v90.29h123.25
          c8.38,0,14.88-1.33,19.49-4c4.61-2.66,6.92-9.58,6.92-20.75h11.32v113.59h-11.32c0-11.16-2.31-18.32-6.92-21.48
          c-4.62-3.15-11.11-4.73-19.49-4.73H622.07v100.49H832.1c6.29,0,11.42-1.7,15.41-5.1c3.98-3.39,5.97-8.01,5.97-13.83h11.32v99.76
          h-11.32c0-7.28-1.99-11.41-5.97-12.38c-3.99-0.98-9.12-1.46-15.41-1.46H539.06v-13.11c2.93,0,5.55-1.09,7.86-3.28
          c2.3-2.18,3.46-5.46,3.46-9.83V236.63c0-4.37-1.16-7.65-3.46-9.83c-2.31-2.18-4.93-3.28-7.86-3.28v-13.11h286.75
          c8.8,0,14.98-1.57,18.55-4.73c3.56-3.15,5.35-7.4,5.35-12.74h11.32v96.12H849.71z"
        />
        <path
          className={themeClass}
          d="M871.72,580.32c5.44-0.97,9.75-4.12,12.89-9.47c3.14-5.34,6.18-11.4,9.12-18.2l116.33-283.25
          c2.93-6.3,5.55-12.13,7.86-17.48c2.3-5.34,3.46-9.94,3.46-13.84c0-4.85-1.47-8.25-4.4-10.19c-2.94-1.93-6.08-3.39-9.43-4.37v-13.11
          h106.27v13.11c-2.94,0.98-5.88,2.31-8.8,4c-2.94,1.71-4.4,5.22-4.4,10.56c0,3.89,0.73,7.77,2.2,11.65
          c1.46,3.89,3.67,8.99,6.6,15.29l130.8,296.36c2.09,5.35,4.92,9.23,8.49,11.65c3.56,2.44,7.64,4.86,12.26,7.28v13.11h-110.68v-13.11
          c1.67,0,3.77-0.73,6.29-2.18c2.51-1.46,3.77-3.64,3.77-6.55c0-4.85-1.68-11.16-5.03-18.93l-23.9-53.88H977.99l-22.64,51.7
          c-4.2,10.19-6.29,17.48-6.29,21.84c0,5.35,3.98,8.01,11.95,8.01v13.11h-89.29V580.32z M1108.79,444.88l-52.82-135.44l-59.11,135.44
          H1108.79z"
        />
        <path
          className={themeClass}
          d="M1459.68,567.21c0,8.74,4.61,13.11,13.83,13.11v13.11h-96.84v-13.11c2.93,0,5.55-1.09,7.86-3.28
          c2.3-2.18,3.46-5.46,3.46-9.83V277.4h-103.76c-3.77,0-7.45,1.22-11,3.64c-3.57,2.44-5.35,7.04-5.35,13.84h-11.32V192.94h11.32
          c0,6.8,1.78,11.41,5.35,13.83c3.56,2.43,7.23,3.64,11,3.64h279.2c3.77,0,7.44-1.21,11-3.64c3.56-2.42,5.35-7.03,5.35-13.83h11.32
          v101.94h-11.32c0-6.79-1.79-11.4-5.35-13.84c-3.57-2.42-7.23-3.64-11-3.64h-103.76V567.21z"
        />
        <path
          className={themeClass}
          d="M1622.55,580.32c10.9,0,16.35-4.37,16.35-13.11V236.63c0-8.74-5.45-13.11-16.35-13.11v-13.11h104.39v13.11
          c-10.91,0-16.35,4.37-16.35,13.11v330.58c0,8.74,5.44,13.11,16.35,13.11v13.11h-104.39V580.32z"
        />
        <path
          className={themeClass}
          d="M1897.35,223.52c-3.36,0-6.6,0.98-9.75,2.91c-3.14,1.95-4.72,4.86-4.72,8.74c0,3.4,1.15,7.53,3.46,12.38
          c2.3,4.86,5.35,9.96,9.12,15.29l51.57,75l52.19-76.46c4.61-6.79,8.59-12.62,11.95-17.48c3.35-4.85,5.03-8.49,5.03-10.92
          c0-6.3-5.66-9.47-16.98-9.47v-13.11h127.65v13.11c-10.06,0-17.51,0.73-22.32,2.18c-4.82,1.46-8.91,4.62-12.26,9.47L1983.5,391
          l104.39,154.37c1.67,2.91,3.67,6.31,5.97,10.19c2.3,3.89,5.13,7.65,8.49,11.29c3.35,3.64,7.02,6.8,11,9.47
          c3.98,2.67,8.49,4,13.52,4v13.11h-126.4v-13.11c6.7,0,10.06-3.88,10.06-11.65c0-3.39-1.37-7.28-4.09-11.65
          c-2.73-4.37-5.97-9.22-9.75-14.56l-57.85-87.38l-62.25,90.29c-2.1,3.4-4.72,7.41-7.86,12.01c-3.14,4.62-4.72,8.86-4.72,12.74
          c0,6.8,3.98,10.19,11.95,10.19v13.11h-115.71v-13.11c7.96,0,14.15-0.97,18.55-2.91c4.4-1.93,8.9-6.3,13.52-13.11l110.05-162.38
          l-101.87-150c-7.13-10.67-13.63-18.08-19.49-22.21c-5.88-4.12-12.79-6.19-20.75-6.19v-13.11h137.09V223.52z"
        />
      </g>
      <g>
        <path
          className={themeClass}
          d="M200.96,770.7h-13.1v2.95c0,1.82-1.34,6.56-4.01,14.24c-2.67,7.67-5.23,13-7.68,15.99
          c-3.08,2.91-9.11,6.26-18.09,10.04c-7.35,2.55-13.63,3.82-18.85,3.82c-9.99,0-18.09-2.18-24.31-6.55
          c-6.85-4.87-10.27-12.08-10.27-21.61l0.28-8.18c0-1.24,0.06-2.37,0.19-3.38c0.12-1.02,0.39-2.78,0.8-5.29
          c0.41-2.51,0.9-4.98,1.46-7.42c0.57-2.44,1.41-5.22,2.54-8.35c3.58-10.84,6.78-16.26,9.61-16.26c0.57,0,0.88,0.4,0.94,1.2
          l-4.15,14.19l-1.6,7.09c-0.38,1.96-0.57,3.67-0.57,5.13c0,9.82,3.93,17.17,11.78,22.04l3.77,1.85l4.43,1.64l4.15,0.87l4.62,0.33
          c6.91,0,13.6-1.16,20.07-3.49c8.29-2.55,13.19-5.53,14.7-8.95l-0.09-3.27l-0.09-2.18c0-0.8-0.13-1.64-0.38-2.51l-0.57-3.38
          c0-0.65-0.13-1.31-0.38-1.96c-0.13-0.87-0.35-1.75-0.66-2.62c-2.07-9.38-5.18-17.24-9.33-23.57l1.23-2.62l1.13-2.18l8.67-15.93
          l8.86,18.33c3.08,5.09,6.63,7.64,10.65,7.64h4.24V770.7z M154.03,697.04l-5.94,5.56c-3.27,3.06-5.53,4.95-6.79,5.67l-11.97-11.02
          l11.97-13.42L154.03,697.04z"
        />
        <path
          className={themeClass}
          d="M200.96,748.22l75.2-0.11v22.48l-75.2,0.11V748.22z"
        />
        <path
          className={themeClass}
          d="M276.16,748.22l75.2-0.11v22.48l-75.2,0.11V748.22z"
        />
        <path
          className={themeClass}
          d="M351.36,748.22l75.2-0.11v22.48l-75.2,0.11V748.22z"
        />
        <path
          className={themeClass}
          d="M426.56,748.22l75.2-0.11v22.48l-75.2,0.11V748.22z"
        />
        <path
          className={themeClass}
          d="M501.76,748.22l75.2-0.11v22.48l-75.2,0.11V748.22z"
        />
        <path
          className={themeClass}
          d="M576.96,748.22l75.2-0.11v22.48l-75.2,0.11V748.22z"
        />
        <path
          className={themeClass}
          d="M652.16,748.22l75.2-0.11v22.48l-75.2,0.11V748.22z"
        />
        <path
          className={themeClass}
          d="M727.36,748.22l75.2-0.11v22.48l-75.2,0.11V748.22z"
        />
        <path
          className={themeClass}
          d="M802.56,748.22l75.2-0.11v22.48l-75.2,0.11V748.22z"
        />
        <path
          className={themeClass}
          d="M877.76,748.33h27.14v-1.42v-3.16v-1.96l0.28-2.07c0.19-0.87,0.85-1.96,1.98-3.27
          c7.66-10.04,14.92-17.91,21.77-23.62c6.85-5.71,12.78-8.57,17.81-8.57c2.95,0,5.4,2.37,7.35,7.09l1.13,3.38l0.94,3.82l0.66,3.93
          l0.19,4.26c0,3.06-0.38,5.46-1.13,7.2l-6.5,12.44l-0.94,1.96h14.7v22.37h-85.38V748.33z M950.51,678.38l-6.69,8.4
          c-0.75,1.09-2.01,2.55-3.77,4.36l-16.49-10.04l-1.32,1.42l-4.05,4.26l-1.7,1.85l-1.41,1.96c-2.14-0.65-4.71-2-7.73-4.04
          c-2.14-1.16-4.37-2.65-6.69-4.47l3.02-4.04l4.24-5.13l1.51-1.75l1.7-1.75l8.01,6c2.83,1.75,4.99,2.73,6.5,2.95
          c1,0,2.7-1.27,5.09-3.82l4.9-5.67L950.51,678.38z M943.72,743.2c0-4.29-1.01-8.18-3.02-11.68c-1.88-3.71-4.68-5.56-8.39-5.56
          c-2.26,0-5.53,1.24-9.8,3.71c-4.21,2.18-6.31,4.69-6.31,7.53c0,2.47,1.63,5.17,4.9,8.07l1.98,1.2l3.2,1.42l2.17,0.65l2.54,0.22
          c0.82,0,1.62-0.04,2.4-0.11c0.78-0.07,1.74-0.25,2.87-0.55c1.13-0.29,1.85-0.55,2.17-0.76l2.26-1.42L943.72,743.2z"
        />
        <path
          className={themeClass}
          d="M963.14,748.33h39.2l0.28-0.76l0.85-2.73l0.47-2.73l0.09-3.06l0.47-12.11l11.59-15.93v2.84l-0.28,8.07
          l-0.28,5.89v1.75l-0.38,1.64l-0.66,4.36l-0.09,1.31c0,2.91,0.97,5.53,2.92,7.86c2.39,2.4,5.09,3.6,8.1,3.6h2.54v22.37h-9.14
          c-3.39,0-6.31-1.24-8.76-3.71c-2.45-2.47-4.3-6.18-5.56-11.13l-7.07,14.84h-34.3V748.33z M1019.39,676.2
          c-0.69,1.16-2.86,4.04-6.5,8.62l-3.3,3.71l-1.41,1.42c-0.38,0-2.86-1.35-7.44-4.04l-7.92-5.35l-5.65,6l-2.26,2.95
          c-1.95-0.44-4.87-1.85-8.76-4.26c-3.9-2.4-6.12-4.15-6.69-5.24l4.52-5.78l3.2-3.71l3.49-3.82c0.5,0.58,2.83,2.4,6.97,5.46
          l4.05,2.29l2.73,1.2c0.57,0,1.98-1.29,4.24-3.87c2.26-2.58,3.96-4.35,5.09-5.29L1019.39,676.2z"
        />
        <path
          className={themeClass}
          d="M1027.97,748.33h25.63c2.26,0,4.08-1.45,5.47-4.37c3.02-7.2,4.96-11.42,5.84-12.66
          c2.89-4.44,7.68-8.91,14.37-13.42c6.69-4.51,11.73-6.77,15.12-6.77c1.88,0,3.36,0.37,4.43,1.09c0.63,0.73,0.94,1.96,0.94,3.71v4.36
          l0.09,2.51l0.66,2.51c1.51,4.58,3.58,9.2,6.22,13.86c3.14,6.11,5.81,9.17,8.01,9.17h5.09v22.37h-13.95c-1.32,0-2.61,1.96-3.86,5.89
          c-0.94,4-2.01,6-3.2,6c-0.75,0-2.36-0.69-4.81-2.07c-2.45-1.38-5.75-3.49-9.89-6.33l-25.82-16.7c-1.51,1.31-2.77,3.46-3.77,6.44
          c-1.63,4.51-4.71,6.77-9.23,6.77h-17.34V748.33z M1097.04,751.49v-3.93l-0.47-3.27c0-0.87-0.47-2.58-1.41-5.13
          c-1.7-5.09-4.24-7.64-7.63-7.64l-4.33,0.11c-1.01,0-2.17,0.26-3.49,0.76c-2.07,0.44-4.05,1.53-5.94,3.27l-2.45,2.4l3.39,2.73
          l3.49,2.4l2.73,1.64l6.79,4.47l1.88,0.98l1.98,0.87l2.07,0.55l1.7,0.11L1097.04,751.49z"
        />
        <path
          className={themeClass}
          d="M1119.85,748.33h33.36c0.63-0.58,0.94-2.29,0.94-5.13c0-5.02-0.79-12.22-2.36-21.61l-2.73-14.95l-2.83-16.91
          l-6.69-6l5.56-12.33l3.86-7.64l2.92-4.8l0.09,3.49c0,0.73,0.06,1.24,0.19,1.53l0.57,1.2c0.25,0.95,1.19,2.55,2.83,4.8l11.5,8.62
          l5.09,3.38l3.68,2.07l-13.76,20.73v0.55l0.38,6.66l0.28,5.02l0.38,3.27l0.66,4.26l1.88,15.93c0,1.16,0.06,1.89,0.19,2.18
          c0,2.04-0.47,5.78-1.41,11.24l-1.23,7.09c-0.31,2.11-0.52,3.47-0.61,4.09c-0.09,0.62-0.39,1.69-0.9,3.22
          c0.19,0.44-0.47,0.98-1.98,1.64l-2.17,0.44l-1.98,0.33h-35.72V748.33z"
        />
        <path
          className={themeClass}
          d="M1212.86,737.52c0,9.53-1.1,17.9-3.3,25.1c-1.45,4.87-3.55,9.46-6.31,13.75l-8.58-86.86
          c0-0.87,1.7-4.76,5.09-11.68l8.01-14.84l0.85,7.86l1.41,13.2C1211.92,705.66,1212.86,723.49,1212.86,737.52z"
        />
        <path
          className={themeClass}
          d="M1382.29,770.7h-12.16l-0.94,2.73c-3.58,10.62-9.31,18.93-17.2,24.93c-7.88,6-17.36,9-28.41,9
          c-8.61,0-15.89-2.77-21.86-8.29c-2.89-2.69-5.06-5.62-6.5-8.78c-1.45-3.16-2.17-6.46-2.17-9.88c0-9.38,2.17-19.95,6.5-31.7
          c4.33-11.75,7.98-17.62,10.93-17.62l-0.66,5.67c-4.52,11.28-6.78,18.51-6.78,21.71c0,7.27,1.26,12.62,3.77,16.04
          c4.65,6.11,13.79,9.17,27.42,9.17c1.88,0,3.25-0.05,4.1-0.16c0.85-0.11,2.23-0.38,4.15-0.82c1.92-0.44,4.54-1.38,7.87-2.84
          c7.04-2.84,10.55-6.33,10.55-10.48l-9.8-89.7l2.54-5.24c0.5-0.94,1.08-2,1.74-3.16c0.66-1.16,1.37-2.33,2.12-3.49
          c3.39-5.31,5.9-9.06,7.54-11.24l2.07,28.59l2.83,35.14c0,3.49,0.22,7.02,0.66,10.58l0.94,9.82c0,2.4,0.31,4.33,0.94,5.78l1.23,1.53
          l1.41,0.33h7.16V770.7z"
        />
        <path
          className={themeClass}
          d="M1382.29,748.22l75.2-0.11v22.48l-75.2,0.11V748.22z"
        />
        <path
          className={themeClass}
          d="M1457.49,748.22l75.2-0.11v22.48l-75.2,0.11V748.22z"
        />
        <path
          className={themeClass}
          d="M1532.69,748.22l75.2-0.11v22.48l-75.2,0.11V748.22z"
        />
        <path
          className={themeClass}
          d="M1607.89,748.22l75.2-0.11v22.48l-75.2,0.11V748.22z"
        />
        <path
          className={themeClass}
          d="M1683.09,748.22l75.2-0.11v22.48l-75.2,0.11V748.22z"
        />
        <path
          className={themeClass}
          d="M1758.29,748.22l75.2-0.11v22.48l-75.2,0.11V748.22z"
        />
        <path
          className={themeClass}
          d="M1833.49,748.22l75.2-0.11v22.48l-75.2,0.11V748.22z"
        />
        <path
          className={themeClass}
          d="M1908.69,748.33h41.93l3.11-0.11c0.88,0,1.51-0.04,1.88-0.11c0.81-0.36,1.44-0.58,1.88-0.65
          c1.82-0.44,4.37-1.42,7.63-2.95c5.97-2.62,8.98-4.4,9.05-5.35l-14.14-5.67c-8.1-3.06-13.1-4.58-14.98-4.58
          c-4.52,0-7.73,0.62-9.61,1.85l-5.94,4.58l5.94-12.99c5.28-8.73,10.8-13.42,16.58-14.08l3.3-0.22c5.46,0,11.97,2.07,19.51,6.22
          c10.62,5.53,17.84,9.02,21.67,10.48l7.35,2.07l7.63,1.75c5.65,1.09,10.46,1.64,14.42,1.64l-2.54,8.29l-1.6,6
          c-0.19,1.16-0.57,2.44-1.13,3.82h14.61v22.37h-25.63l-2.26-16.8h-1.22h-2.83l-4.33-0.44l-7.45-0.11h-7.26
          c-2.2,0-4.79,0.67-7.77,2.02c-2.99,1.35-6.71,3.58-11.17,6.71c-3.71,2.47-7.29,4.58-10.74,6.33c-3.2,1.53-5.81,2.29-7.82,2.29
          h-38.07V748.33z"
        />
        <path
          className={themeClass}
          d="M2035.25,748.33h33.36c0.63-0.58,0.94-2.29,0.94-5.13c0-5.02-0.79-12.22-2.36-21.61l-2.73-14.95l-2.83-16.91
          l-6.69-6l5.56-12.33l3.86-7.64l2.92-4.8l0.09,3.49c0,0.73,0.06,1.24,0.19,1.53l0.56,1.2c0.25,0.95,1.19,2.55,2.83,4.8l11.5,8.62
          l5.09,3.38l3.68,2.07l-13.76,20.73v0.55l0.38,6.66l0.28,5.02l0.38,3.27l0.66,4.26l1.88,15.93c0,1.16,0.06,1.89,0.19,2.18
          c0,2.04-0.47,5.78-1.41,11.24l-1.22,7.09c-0.32,2.11-0.52,3.47-0.61,4.09c-0.09,0.62-0.39,1.69-0.89,3.22
          c0.19,0.44-0.47,0.98-1.98,1.64l-2.17,0.44l-1.98,0.33h-35.72V748.33z"
        />
        <path
          className={themeClass}
          d="M2128.26,737.52c0,9.53-1.1,17.9-3.3,25.1c-1.45,4.87-3.55,9.46-6.31,13.75l-8.58-86.86
          c0-0.87,1.7-4.76,5.09-11.68l8.01-14.84l0.85,7.86l1.41,13.2C2127.32,705.66,2128.26,723.49,2128.26,737.52z"
        />
      </g>
      <g>
        <g>
          <path
            className={themeClass}
            d="M2754.3,412.11l62.63-62.62l-62.43-62.43l-62.62,62.63l-35.35,35.35l-62.63,62.42l-22.24,22.24l-12.77,12.78
              l-0.33,0.32l-35.35-35.34l35.35-35.35l62.63-62.42l97.97-97.97L2558.77,91.33l-62.63,62.63l-35.35,35.34l-62.62,62.42l85.13,85.13
              l12.63,12.64l-0.04,0.05l-35.3,35.29l-62.42,62.63l97.97,97.97l-0.34,0.34l-12.78,12.77l-22.23,22.23l-62.62,62.43l62.42,62.62
              l35.34,35.35l62.63,62.42l62.63-62.42l35.34-35.35l62.42-62.62l-62.42-62.63l-35.34-35.14l35.34-35.34l35.35,35.34l62.42,62.42
              l62.63-62.42l-97.98-97.97L2754.3,412.11z M2523.21,251.72l35.35-35.34l35.34,35.34l-35.2,35.21l-0.14,0.13l-12.74-12.73
              L2523.21,251.72z M2558.56,678.54l-35.35-35.34l35.35-35.35l35.34,35.35L2558.56,678.54z"
          />
          <rect
            x="2807.98"
            y="403.18"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 519.0234 2147.9414)"
            className={themeClass}
            width="88.57"
            height="88.57"
          />
          <polygon
            className={themeClass}
            points="2425.24,349.49 2362.82,412.12 2300.4,349.7 2362.82,287.07 		"
          />
          <polygon
            className={themeClass}
            points="2425.45,545.43 2362.82,607.86 2300.19,545.43 2362.82,482.81 		"
          />
          <polygon
            className={themeClass}
            points="2317.14,437.12 2212.8,437.12 2213.01,436.92 2226.77,423.21 2265.06,385.04 2298.87,418.85 
              2303.57,423.55 		"
          />
          <polygon
            className={themeClass}
            points="2317.2,457.71 2317.12,457.79 2314.94,459.96 2264.85,509.88 2212.76,457.79 2213.45,457.79 
              2213.37,457.71 		"
          />
        </g>
        <g>
          <polygon
            className="st2"
            points="2317.28,457.71 2317.2,457.79 2213.45,457.79 2213.37,457.71 		"
          />
        </g>
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1="-4379.1875"
          y1="676.0135"
          x2="-4324.3896"
          y2="676.0135"
          gradientTransform="matrix(-0.3301 -0.3301 0.7071 -0.7071 603.9003 -649.1276)"
        >
          <stop
            offset="5.175071e-04"
            style={{ stopColor: "#000000", stopOpacity: "0.7" }}
          />
          <stop offset="0.8226" style={{ stopColor: "#00AEEF" }} />
        </linearGradient>
        <polygon
          className="st3"
          points="2540.64,268.88 2558.73,286.97 2496.02,349.68 2477.93,331.59 	"
        />
        <linearGradient
          id="SVGID_00000000944999109780204930000006543061503390860693_"
          gradientUnits="userSpaceOnUse"
          x1="2183.5996"
          y1="2029.624"
          x2="2238.397"
          y2="2029.624"
          gradientTransform="matrix(0.4669 -0.4669 0.7059 0.7059 137.5855 100.9972)"
        >
          <stop
            offset="5.175071e-04"
            style={{ stopColor: "#000000", stopOpacity: "0.7" }}
          />
          <stop offset="0.7666" style={{ stopColor: "#00AEEF" }} />
        </linearGradient>
        <polygon
          style={{
            fill: "url(#SVGID_00000000944999109780204930000006543061503390860693_)",
          }}
          points="2646.75,519.82 2621.16,545.41 
          2558.56,482.81 2584.15,457.22 	"
        />
        <linearGradient
          id="SVGID_00000014629886748300725960000001783843655142610063_"
          gradientUnits="userSpaceOnUse"
          x1="-2048.8989"
          y1="-3092.425"
          x2="-1994.1014"
          y2="-3092.425"
          gradientTransform="matrix(-0.4404 0.4404 -0.7019 -0.7019 -545.4089 -691.6316)"
        >
          <stop
            offset="5.175071e-04"
            style={{ stopColor: "#000000", stopOpacity: "0.7" }}
          />
          <stop offset="0.8656" style={{ stopColor: "#00AEEF" }} />
        </linearGradient>
        <polygon
          style={{
            fill: "url(#SVGID_00000014629886748300725960000001783843655142610063_)",
          }}
          points="2472.07,569.51 2496.2,545.37 
          2558.45,607.62 2534.32,631.75 	"
        />
        <linearGradient
          id="SVGID_00000018207869916594846180000001773949456899032714_"
          gradientUnits="userSpaceOnUse"
          x1="2265.324"
          y1="457.7144"
          x2="2265.324"
          y2="475.7843"
        >
          <stop
            offset={0}
            style={{ stopColor: "#000000", stopOpacity: "0.7" }}
          />
          <stop offset="0.7879" style={{ stopColor: "#00AEEF" }} />
        </linearGradient>
        <polygon
          style={{
            fill: "url(#SVGID_00000018207869916594846180000001773949456899032714_)",
          }}
          points="2317.28,457.71 2317.2,457.79 
          2314.94,459.96 2298.47,475.78 2230.71,475.78 2229.76,474.79 2213.45,457.79 2213.37,457.71 	"
        />
        <linearGradient
          id="SVGID_00000157265842918180646760000014291370719822911161_"
          gradientUnits="userSpaceOnUse"
          x1="-2972.7449"
          y1="1755.4978"
          x2="-2917.9475"
          y2="1755.4978"
          gradientTransform="matrix(1.000000e-13 -0.3297 1 -1.000000e-13 508.9477 -543.2754)"
        >
          <stop
            offset="5.175071e-04"
            style={{ stopColor: "#000000", stopOpacity: "0.7" }}
          />
          <stop offset="0.828" style={{ stopColor: "#00AEEF" }} />
        </linearGradient>
        <polygon
          style={{
            fill: "url(#SVGID_00000157265842918180646760000014291370719822911161_)",
          }}
          points="2299.06,418.86 2316.4,436.92 
          2212.49,436.92 2231.3,418.86 	"
        />
      </g>
    </svg>
  );
}
