import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import {  PeoplesContactsModels } from "./PeoplesModels";

export const AddNtxPeoplesContacts = createAsyncThunk(
  "PeoplesContacts/AddNtxPeoplesContacts",
  async (item:PeoplesContactsModels , { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post("Peoples/AddNtxPeoplesContacts", item);
      if (data?.succeeded) {
        return data.data;
      } else {
        return {} as PeoplesContactsModels;
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdatNtxPeoplesContacts = createAsyncThunk(
  "PeoplesContacts/UpdatNtxPeoplesContacts",
  async (item:PeoplesContactsModels , { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post("Peoples/UpdatNtxPeoplesContacts", item);
      if (data?.succeeded) {
        return data.data;
      } else {
        return {} as PeoplesContactsModels;
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);



export const GetPeoplesContactsByPeopleId = createAsyncThunk(
  "PeoplesContacts/GetPeoplesContactsByPeopleId",
  async (peopleId : string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`Peoples/GetPeoplesContactsByPeopleId?PeopleId=${peopleId}`);
      if (data?.succeeded) {
        return data.data;
      } else {
        debugger
        return rejectWithValue(data?.message || "هناك خطأ");
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const GetNtxPeoplesContactsList = createAsyncThunk(
  "PeoplesContacts/GetNtxPeoplesContactsList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get("Peoples/GetNtxPeoplesContactsList");
      if (data?.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);



const initialState: {
  PeoplesContactsList: PeoplesContactsModels[];
  PeoplesContactsListByPeopleId: PeoplesContactsModels[];
  loading: boolean;
  error: any | null;
} = {
  PeoplesContactsList: [],
  PeoplesContactsListByPeopleId: [],
  loading: false,
  error: null,
};

const PeoplesContactSlice = createSlice({
  name: "PeoplesContacts",
  initialState,
  reducers: {
    clearContactsListByPeopleId: (state) => {
      state.PeoplesContactsListByPeopleId = [];
    },
  },
  extraReducers(builder) {
    builder

      .addCase(AddNtxPeoplesContacts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        AddNtxPeoplesContacts.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.PeoplesContactsList.push(action.payload.data);
        }
      )
      .addCase(
        AddNtxPeoplesContacts.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )

      .addCase(GetNtxPeoplesContactsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetNtxPeoplesContactsList.fulfilled,
        (state, action: PayloadAction<PeoplesContactsModels[]>) => {
          state.loading = false;
          state.PeoplesContactsList = action.payload;
        }
      )
      .addCase(
        GetNtxPeoplesContactsList.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )


      .addCase(GetPeoplesContactsByPeopleId.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        GetPeoplesContactsByPeopleId.fulfilled,
        (state, action: PayloadAction<PeoplesContactsModels[]>) => {
          state.loading = false;
           state.error = null;
          state.PeoplesContactsListByPeopleId = action.payload;
        }
      )
      .addCase(
        GetPeoplesContactsByPeopleId.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )


  },
});


export const {clearContactsListByPeopleId} = PeoplesContactSlice.actions 
export default PeoplesContactSlice.reducer;
