import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { Card, CardBody } from '../../../../components/card/card';
import {
  GetPayProposal,
  insertPayProposal,
  cleanPayProposalList,
} from '../../../../store/HR/Payroll/payProposalSlice';
import { AddPayProposalSchema } from '../../../ValidationForm/validationSchema';
import PayProposalTableList from './PayProposalTableList';
import Loader from '../../../../components/Shared/Loader/Loader.js';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import Loading from '../../../../components/Shared/Loader/Loading';

const AddPayProposal = () => {
  const { assignmentId } = useParams();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  //___________________________________________________________
  useEffect(() => {
    dispatch(cleanPayProposalList());
    dispatch(GetPayProposal(assignmentId));
  }, [dispatch, assignmentId]);
  const { PayProposalList, loading, error } = useSelector((state) => state.PayProposal);
  //_________________________________________________________________

  const formik = useFormik({
    initialValues: {
      changeDate: '',
      proposalReason: '',
      proposedSalaryN: '',
      comments: '',
    },

    validationSchema: AddPayProposalSchema(t),

    onSubmit: (values) => {
      dispatch(
        insertPayProposal({
          assignmentId: assignmentId,
          // businessGroupId: null,
          changeDate: formik.values.changeDate,
          proposalReason: formik.values.proposalReason,
          proposedSalaryN: parseFloat(formik.values.proposedSalaryN),
          comments: formik.values.comments,
          //dateTo: null
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          CustomAlert({ action: 'Add' });
          dispatch(cleanPayProposalList());
          dispatch(GetPayProposal(assignmentId));
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  //______________________________________________

  function handleKeyPress(event) {
    const allowedChars = /^[0-9.]+$/;
    if (!allowedChars.test(event.key) || (event.key === '.' && event.target.value.includes('.'))) {
      event.preventDefault();
    }
  }

  //#region
  const location = useLocation();
  const [referenceName, setReferenceName] = useState(null); // State to hold ReferenceName
  useEffect(() => {
    if (location.state && location.state.ReferenceName) {
      const { ReferenceName } = location.state;
      if (ReferenceName) {
        setReferenceName(ReferenceName); // Update ReferenceName state
      }
    }
  }, []);
  //#endregion

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },

    {
      name: t('PayrollElementEntry'),
      link: '/Pay/PayrollElementEntry',
    },
    {
      name: t('Button.Basic_salary'),
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName={t('Button.Basic_salary')}
        subtitle={referenceName}
      />

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('SalaryValue')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="proposedSalaryN"
                      onChange={formik.handleChange}
                      // onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                      value={formik.values.proposedSalaryN}
                      isInvalid={formik.touched.proposedSalaryN && formik.errors.proposedSalaryN}
                    />

                    <Form.Control.Feedback type="invalid">
                      {formik.errors.proposedSalaryN}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('ChangeDate')}</label>
                    <Form.Control
                      className="form-control"
                      type="date"
                      name="changeDate"
                      onChange={formik.handleChange}
                      value={formik.values.changeDate}
                      isInvalid={formik.touched.changeDate && formik.errors.changeDate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.changeDate}
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Reason')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="proposalReason"
                      onChange={formik.handleChange}
                      value={formik.values.proposalReason}
                      isInvalid={formik.touched.proposalReason && formik.errors.proposalReason}
                    />

                    <Form.Control.Feedback type="invalid">
                      {formik.errors.proposalReason}
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>

              <div className="row align-items-end">
                <div className="col-xl-8">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Note')}</label>
                    <Form.Control
                      className="form-control"
                      type="texterea"
                      name="comments"
                      onChange={formik.handleChange}
                      value={formik.values.comments}
                      isInvalid={formik.touched.comments && formik.errors.comments}
                    />
                  </div>
                </div>

                <div className="col-xl-2 text-end  ">
                  <div className=" form-group mb-3 d-flex justify-content-end ">
                    <button
                      disabled={formik.isSubmitting}
                      type="submit"
                      className="btn me-1 btn-theme mb-1 w-100"
                      id="SaveSubmit">
                      <i className="fa fa-save fa-fw me-1"></i>{' '}
                      {formik.isSubmitting ? '...' : t('Save')}
                    </button>
                  </div>
                </div>

                <div className="col-xl-2 text-end  ">
                  <div className=" form-group mb-3 d-flex justify-content-end ">
                    <button
                      type="button"
                      className=" btn me-1 btn-default mb-1 w-100"
                      onClick={() => Navigate(-1, { replace: true })}>
                      <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                    </button>
                  </div>
                </div>
              </div>
            </Form>

            <div className="mb-1" />

            <div className="row">
              <div className="col-lg-12 ">
                <PayProposalTableList PayProposalList={PayProposalList} />
              </div>
            </div>
          </CardBody>
        </Loading>
      </Card>
    </>
  );
};

export default AddPayProposal;
