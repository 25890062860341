import React from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../store/hooks';
import { useFormik } from 'formik';
import useGetChartAccountsDetailsList from '../../../../hooks/Finance/useGetChartAccountsDetailsList';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import { ChartAccountsDetailsModels } from '../../../../store/Finance/ChartAccounts/ChartAccountsModels';
import {
  AddGlChartOfAccountsDetails,
  GetGlChartOfAccountsDetailsList,
  UpdatGlChartOfAccountsDetails,
} from '../../../../store/Finance/ChartAccounts/ChartAccountsDetailsSlice';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';

type Props = {
  treeDetails: ChartAccountsDetailsModels;
  breadcrumb: any[];
  isAdd: boolean;
};

const AccountsForm: React.FC<Props> = ({ treeDetails, breadcrumb, isAdd }) => {



  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { ChartAccountsDetailsList: data } = useGetChartAccountsDetailsList();
  const { Currency } = useLookupCurrency();
 

  //#region ValidationFunction
  // Define the type for the validation function
  const ListValidate = data.map((item) => item.accountNumber);
  type ValidationFunction = (value: string) => string | null;

  const validateAccountNumber: ValidationFunction = (value) => {
    // Convert both the input value and the list items to lowercase and remove spaces
    debugger;
    const normalizedValue = value.toLowerCase().replace(/\s/g, '');
    const normalizedList = ListValidate.map((item) => item.toLowerCase().replace(/\s/g, ''));

    if (normalizedList.includes(normalizedValue)) {
      return 'Lookup code already exists. Please choose a different code.';
    }
    return null; // Return null for no error
  };

  //#endregion


  //#region GetNextAccountNumber
  function getNextAccountNumber(item: ChartAccountsDetailsModels) {
    const filteredAccounts = data.filter(
      (account) => account.accountParant === item.id && account.accountType !== 'Future'
    );

    if (item.accountlevel === 1) {
      // Find the maximum account number among the children
      const maxAccountNumber = filteredAccounts.reduce((max, account) => {
        const accountNumber = parseInt(account.accountNumber);
        return accountNumber > max ? accountNumber : max;
      }, 0);

      if (maxAccountNumber === 0) {
        return item.accountNumber + 1;
      }

      // Extract the first three digits and increment by 1
      const firstThreeDigits = parseInt(maxAccountNumber?.toString()?.substring(0, 3)) + 1;

      // Return the incremented number padded with zeros
      return firstThreeDigits.toString().padStart(3, '0') + '00000';
    } else if (item.accountlevel === 2) {
      const maxAccountNumber = filteredAccounts.reduce((max, account) => {
        const accountNumber = parseInt(account.accountNumber);
        return accountNumber > max ? accountNumber : max;
      }, 0);

      // Increment the last five digits by 1000
      const incrementedNumber = maxAccountNumber + 1000;

      return incrementedNumber.toString();
    } else if (item.accountlevel === 3) {
      const maxAccountNumber = filteredAccounts.reduce((max, account) => {
        const accountNumber = parseInt(account.accountNumber);
        return accountNumber > max ? accountNumber : max;
      }, 0);
      // If maxAccountNumber is 0, return item.accountNumber + 1"

      if (maxAccountNumber === 0) {
        return parseInt(item.accountNumber) + 1;
      }

      // Increment the last five digits by 1
      const incrementedNumber = maxAccountNumber + 1;

      return incrementedNumber.toString();
    }
  }
  //#endregion



  //#region formik
  const formik = useFormik({
    initialValues: {
      id: isAdd ? null : treeDetails ? treeDetails.id : '',
      name: isAdd ? '' : treeDetails ? treeDetails.name : '',
      name2: isAdd ? '' : treeDetails ? treeDetails.name2 : '',
      chartOfAccountsId: treeDetails ? treeDetails.chartOfAccountsId : '',

      accountNumber: isAdd
        ? getNextAccountNumber(treeDetails)
        : treeDetails
        ? treeDetails.accountNumber
        : '', //  accountNumberPriffex,

      accountParant: isAdd
        ? treeDetails
          ? treeDetails.id
          : ''
        : treeDetails
        ? treeDetails.accountParant
        : '',

      accountType: 'Accounts',

      secondaryFlag: false, //  في ال "Accounts"دائما  false
      primaryParant: null, //
      sequenceNum: isAdd ? '' : treeDetails ? treeDetails.sequenceNum : '',

      budgetingAllowedFlag: isAdd
        ? false
        : treeDetails.budgetingAllowedFlag === null
        ? false
        : treeDetails.budgetingAllowedFlag,

      enabledFlag: isAdd ? true : treeDetails.enabledFlag,

      currencyCode: isAdd ? '' : treeDetails ? treeDetails.currencyCode : '',
      accountlevel: isAdd ? treeDetails.accountlevel + 1 : treeDetails.accountlevel,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('*').trim(),
      name2: Yup.string().required('*').trim(),
      currencyCode: Yup.string().required('*'),
      sequenceNum: Yup.number().required('*'),
      accountNumber: Yup.string().required(() => t('Required')),
    }),
    onSubmit: (values) => {
      const requestBody = {
        id: values.id,
        name: values.name,
        name2: values.name2,
        chartOfAccountsId: values.chartOfAccountsId,
        accountNumber: values.accountNumber?.toString(),
        accountParant: values.accountParant,
        accountType: values.accountType,
        secondaryFlag: values.secondaryFlag,
        primaryParant: values.primaryParant,
        sequenceNum: values.sequenceNum,
        budgetingAllowedFlag: values.budgetingAllowedFlag,
        currencyCode: values.currencyCode,
        enabledFlag: values.enabledFlag,
        accountlevel: values.accountlevel,
      };

      const actionToDispatch = isAdd
        ? AddGlChartOfAccountsDetails(requestBody)
        : UpdatGlChartOfAccountsDetails(requestBody);

      dispatch(actionToDispatch)
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: isAdd ? 'Add' : 'Edit' });
            dispatch(GetGlChartOfAccountsDetailsList());
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  //#endregion


  return (
    <div>
      <div className=" d-flex rounded-2 bg-inverse bg-opacity-20 mb-1 px-3 pt-2 pb-1">
        <div className="flex-grow-1">
          <Breadcrumb
            BreadcrumbList={breadcrumb}
            subtitle={breadcrumb[breadcrumb.length - 1]?.name}
          />
        </div>
        <div className="flex-grow-3 ">
          رقم الحـساب |{'  '}
          <span className="fw-bold text-theme ">{treeDetails.accountNumber}</span>
        </div>
        {/* {treeDetails.accountlevel} */}
      </div>

      <Form onSubmit={formik.handleSubmit} className="bg-inverse bg-opacity-5 rounded-3 p-3">
        <div className="row mb-2">
          <div className="col-xl-5 m-auto text-center rounded-4 p-1 bg-inverse bg-opacity-10 ">
            <div className="form-group mb-3">
              <label className="form-label">رقم الحساب</label>
              <div className="text-center">
                {treeDetails.accountlevel === 1 && !isAdd ? (
                  <div className="fw-bold" style={{ letterSpacing: '3px' }}>
                    <span className="text-theme fs-4">
                      {formik.values.accountNumber?.toString().charAt(0)}
                    </span>
                    {formik.values.accountNumber?.toString().slice(1)}
                  </div>
                ) : (treeDetails.accountlevel === 1 && isAdd) ||
                  (treeDetails.accountlevel === 2 && !isAdd) ? (
                  <div className="fw-bold" style={{ letterSpacing: '3px' }}>
                    {formik.values.accountNumber?.toString().slice(0, 1)}
                    <span className="text-theme fs-4">
                      {formik.values.accountNumber?.toString().slice(1, 3)}
                    </span>
                    {formik.values.accountNumber?.toString().slice(3)}
                  </div>
                ) : (treeDetails.accountlevel === 2 && isAdd) ||
                  (treeDetails.accountlevel === 3 && !isAdd) ? (
                  <div className="fw-bold" style={{ letterSpacing: '3px' }}>
                    {formik.values.accountNumber?.toString().slice(0, 3)}
                    <span className="text-theme fs-4">
                      {formik.values.accountNumber?.toString().slice(3, 5)}
                    </span>
                    {formik.values.accountNumber?.toString().slice(5)}
                  </div>
                ) : (treeDetails.accountlevel === 3 && isAdd) || treeDetails.accountlevel === 4 ? (
                  <div className="fw-bold" style={{ letterSpacing: '3px' }}>
                    {formik.values.accountNumber?.toString().slice(0, 5)}
                    <span className="text-theme fs-4">
                      {formik.values.accountNumber?.toString().slice(5)}
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>

          <div className="col-xl-5 m-auto  text-center rounded-4 p-1 bg-inverse bg-opacity-10">
            <div className="form-group mb-3">
              <label className="form-label"> الحسـاب الأب </label>
              <div className="text-theme fs-4">
                {data?.find((f) => formik.values.accountParant === f.id)?.name2}
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-xl-5 m-auto ">
            <div className="form-group mb-3">
              <label className="form-label">اسم الحساب</label>
              <Form.Control
                className="form-control"
                type="text"
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
              <Form.Control.Feedback type="invalid">
                {/* {formik.errors.name2} */}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-5 m-auto">
            <div className="form-group mb-3">
              <label className="form-label">اسم الحساب</label>
              <Form.Control
                className="form-control"
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">
                {/* {formik.errors.name} */}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-5 m-auto">
            <div className="form-group mb-3">
              <label className="form-label"> عملة الحســاب </label>
              <Form.Select
                className="form-select"
                name="currencyCode"
                onChange={formik.handleChange}
                value={formik.values.currencyCode || ''}
                isInvalid={!!(formik.touched.currencyCode && formik.errors.currencyCode)}>
                <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {Currency &&
                  Currency?.map((item: any, idx: number) => (
                    <option key={++idx} value={item.currencyCode}>
                      {item.description}
                    </option>
                  ))}
              </Form.Select>
            </div>
          </div>

          <div className="col-xl-5 m-auto ">
            <div className="form-group mb-3">
              <label className="form-label">الترتيب</label>
              <Form.Control
                className="form-control text-center"
                type="number"
                name="sequenceNum"
                onChange={formik.handleChange}
                value={formik.values.sequenceNum}
                isInvalid={!!(formik.touched.sequenceNum && formik.errors.sequenceNum)}
              />
              <Form.Control.Feedback type="invalid">
                {/* {formik.errors.sequenceNum} */}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>
        <div className="row bg-inverse bg-opacity-5 m-2 p-2 rounded-5">
          <div className="col-xl-4 m-auto">
            <div className="form-group ">
              <Form.Check
                type="checkbox"
                label="budgetingAllowedFlag"
                name="budgetingAllowedFlag"
                onChange={formik.handleChange}
                checked={formik.values.budgetingAllowedFlag}
              />
            </div>
          </div>
          {!isAdd && (
            <div className="col-xl-4 m-auto">
              <div className="form-group">
                <Form.Check
                  type="checkbox"
                  label="enabledFlag"
                  name="enabledFlag"
                  onChange={formik.handleChange}
                  checked={formik.values.enabledFlag}
                />
              </div>
            </div>
          )}
        </div>

        <div className="text-center">
          {!isAdd && (
            <button
              disabled={formik.isSubmitting || !treeDetails.enabledFlag}
              type="submit"
              className="btn me-1 btn-theme mb-1">
              {formik.isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="fa fa-save fa-fw me-2"></i>
              )}
              {t('Edit')}
            </button>
          )}

          {isAdd && (
            <button
              disabled={formik.isSubmitting}
              type="submit"
              className="btn me-1 btn-theme mb-1">
              {formik.isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
              )}
              {t('Add')}
            </button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default AccountsForm;

// const validationSchema = Yup.object().shape({
//   name: Yup.string().required('*'),
//   name2: Yup.string().required('*'),
// });
