import { Upload } from 'antd';
import { StarOutlined, UploadOutlined } from '@ant-design/icons';
import { InboxOutlined } from '@ant-design/icons';
const { Dragger } = Upload;

interface props {
  attachments: {
    id: string;
    referenceId: string;
    referenceType: string;
    fileName: string;
    filePath: string;
    description: string | null;
    size: number;
    extensionFile: string;
    creationDate: string;
  }[];
}

const AntFile = ({ attachments }: props) => {
    
  return (
<>

</>
  );
};

export default AntFile;
