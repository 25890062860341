import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ntxAPI } from '../../../API/axiosNeatex';

export const GetUserTypes = createAsyncThunk('Users/GetUserTypes', async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const { data } = await ntxAPI.get('Security/GetAuthUserTypesList');
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getUsers = createAsyncThunk('Users/getUsers', async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const { data } = await ntxAPI.get('Security/GetAuthUserList');
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const AddAuthUser = createAsyncThunk('Users/AddAuthUser', async (item, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const { data } = await ntxAPI.post('Security/AddAuthUser', item);
    if (data.succeeded) {
      return data.data;
    } else {
      return rejectWithValue(data);
    }
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getUserById = createAsyncThunk('Users/getUserById', async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const { data } = await ntxAPI.get(`Security/GetAuthUserById?Id=${id}`);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const UpdateAuthUser = createAsyncThunk('Users/UpdateAuthUser', async (item, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const { data } = await ntxAPI.post('Security/UpdateAuthUser', item);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const GetUserFunctionList = createAsyncThunk(
  'Users/GetUserFunctionList',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.get(`Security/GetUserFunctionListbyUserId?UserId=${id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const AddUpdateUserFunction = createAsyncThunk(
  'Users/AddUpdateUserFunction',
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.post('Security/AddUpdateUserFunction', item);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  Users: [],
  UserFunctionList: [],
  loading: false,
  error: null,
  User: [],
  UserTypesList: [],
};

const UserSlice = createSlice({
  name: 'Users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get User Types
      .addCase(GetUserTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetUserTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.UserTypesList = action.payload;
      })
      .addCase(GetUserTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // get all Users
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.Users = action.payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // // insert
      // .addCase(AddAuthUser.pending, (state) => {
      //   state.error = null;
      // })
      // .addCase(AddAuthUser.fulfilled, (state, action) => {
      //   state.Users.push(action.payload);
      // })
      // .addCase(AddAuthUser.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })

      // get one User by id
      .addCase(getUserById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.User = [];
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.loading = false;
        state.User = action.payload;
      })
      .addCase(getUserById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Get User Function List
      .addCase(GetUserFunctionList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.UserFunctionList = [];
      })
      .addCase(GetUserFunctionList.fulfilled, (state, action) => {
        state.loading = false;
        state.UserFunctionList = action.payload;
      })
      .addCase(GetUserFunctionList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default UserSlice.reducer;
