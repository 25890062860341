import { memo } from 'react';
import useGetCategoriesList from '../../../hooks/INV/useGetCategoriesList';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { FormikProps } from 'formik';
import Select from 'react-select';

interface Props {
  formik: FormikProps<any>;
}

const DiscountVouchersCategoriesList = ({ formik }: Props) => {
  const { CategoriesOptions, loading } = useGetCategoriesList();
  const { t, i18n } = useTranslation();

  const handleChange = (selectedOptions: any) => {
    const selectedItems = selectedOptions
      ? selectedOptions.map((option: any) => ({ categoryId: option.value }))
      : [];

    formik.setFieldValue('discountVouchersCategoriesList', selectedItems);
  };

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{t(' اختر الفئات')}</Form.Label>

        <Select
          classNamePrefix="react-select"
          className={`${
            formik.values.AllItemsAllCategories !== 'allCategories' && 'bg-inverse bg-opacity-10'
          } `}
          isLoading={loading}
          isDisabled={formik.values.AllItemsAllCategories !== 'allCategories'}
          isMulti
          isSearchable={true}
          isClearable
          options={CategoriesOptions}
          onChange={handleChange}
          value={formik.values.discountVouchersCategoriesList.map((item: any) =>
            CategoriesOptions.find((option) => option.value === item.categoryId)
          )}
          placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
        />
      </Form.Group>
    </>
  );
};

export default memo(DiscountVouchersCategoriesList);
