import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ntxAPI } from '../../API/axiosNeatex';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { GetPrintTemplateByType } from '../../store/NTX/PrintTemplate/PrintTemplateSlice';

import { DownOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Dropdown, message, Space, Tooltip } from 'antd';


type Props = {
  PrintTemplateTypeId: string;
  ReferenceId: string;
};

const DynamicPrint = ({ PrintTemplateTypeId, ReferenceId }: Props) => {
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GetPrintTemplateByType(PrintTemplateTypeId));
  }, [PrintTemplateTypeId, dispatch]);

  const { PrintTemplateByType: data, loading } = useAppSelector((state) => state.PrintTemplate);

  const handlePrint = async (TemplateId: string, parameterList: any[]) => {
    try {
      // debugger;
      const response = await ntxAPI.post(
        'Reports/Print',
        {
          // id: '1b83ceb6-1515-49b0-a3ed-fe9331c9a898',
          id: TemplateId,
          parameterList: [
            {
              // id: '7e6f4bc6-348e-4d42-5bea-08dc4be94183',
              // value: '47fdac08-885f-4a51-bd82-02e0c5774a64',
              id: parameterList[0].id,
              value: ReferenceId,
            },
          ],
        },
        {
          responseType: 'blob', // Ensure binary response
        }
      );

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'invoice.docx';
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  //________________________________________________________________



  
 

const handleMenuClick: MenuProps['onClick'] = (e) => {
  message.info('Click on menu item.');
  console.log('click', e);
};

const items: MenuProps['items'] = [
  {
    label: '1st menu item',
    key: '1',
    // icon: <UserOutlined />,
  },
  {
    label: '2nd menu item',
    key: '2',
    icon: <UserOutlined />,
  },
  {
    label: '3rd menu item',
    key: '3',
    icon: <UserOutlined />,
    danger: true,
  },
  {
    label: '4rd menu item',
    key: '4',
    icon: <UserOutlined />,
    danger: true,
    disabled: true,
  },
];

const menuProps  = {
  items,
  // onClick: handleMenuClick,
  
};


  return (
    <>
   
    <div className="btn-group" role="group" style={{ direction: 'ltr' }}>
      <button
        type="button"
        className="btn btn-theme dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false">
        {!loading ? (
          <>
            <i className="fa bi bi-printer-fill fa-fw ms-3 fs-5"></i>
            {t('طباعة')}
          </>
        ) : (
          <div className="spinner-grow spinner-grow-sm"></div>
        )}
      </button>
      <ul className="dropdown-menu" style={{zIndex : '1024'}}> 
        {data?.map((m) => (
          <li key={m.id}>
            <Link
              className="dropdown-item"
              to=""
              onClick={() => handlePrint(m.id, m.parameterList)}>
              {/* <i className="bi bi-1-circle ms-2 fs-5"></i> */}
              <i className={m.icon + ' ms-2'}></i>
              {i18n.language === 'en' ? m.name : m.name2}
            </Link>
          </li>
        ))}
      </ul>
    </div> 

{/* 
    <Space wrap>
 
   
 
    <Dropdown menu={menuProps}  >
      <Button>
        <Space>
          Button
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  </Space> */}

    </>
  );
};

export default DynamicPrint;
