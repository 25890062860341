import React from 'react';
import DataTableViewer from '../DataTable/DataTableViewer';
import { Card, CardBody, CardExpandToggler } from '../../../../../../components/card/card';
import ChartComponent from '../../../../../../components/Shared/ChartsComponent/index';
import Charts from '../../../../../Charts/Index';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// const DATA =[{"reportChartId":"fbd840a0-0979-46cb-260f-08dbcca6f609","data":[{"data":[{"label":"اسم المؤشر3d","value":3400}],"name":"Ameen Qaid Mohammed Ali"},{"data":[{"label":"اسم المؤشر3d","value":2000}],"name":"Binod Chaudhary"},{"data":[{"label":"اسم المؤشر3d","value":3500}],"name":"MAYLENE VALDEZ SERIEGAN"},{"data":[{"label":"اسم المؤشر3d","value":13000}],"name":"Mustafa Saeed Ahmed Aqlan"},{"data":[{"label":"اسم المؤشر3d","value":8300}],"name":"NABARAJ KARKI NETRA BAHADUR"},{"data":[{"label":"اسم المؤشر3d","value":1200}],"name":"Person 1"},{"data":[{"label":"اسم المؤشر3d","value":3200}],"name":"Person 5"}],"title":"bar","chartType":"bar","kpiNames":["اسم المؤشر3d"]},{"reportChartId":"3f294fc6-cacc-4ccb-7c5b-08dbcccce85a","data":[{"data":[{"label":"اسم الراتب ","value":1},{"label":"الوظيفة ","value":1}],"name":"April"},{"data":[{"label":"اسم الراتب ","value":1},{"label":"الوظيفة ","value":1}],"name":"January"},{"data":[{"label":"اسم الراتب ","value":2},{"label":"الوظيفة ","value":2}],"name":"July"},{"data":[{"label":"اسم الراتب ","value":1},{"label":"الوظيفة ","value":1}],"name":"June"},{"data":[{"label":"اسم الراتب ","value":1},{"label":"الوظيفة ","value":1}],"name":"March"},{"data":[{"label":"اسم الراتب ","value":1},{"label":"الوظيفة ","value":1}],"name":"November"}],"title":"متعدد ","chartType":"bar","kpiNames":["اسم الراتب ","الوظيفة "]},{"reportChartId":"6ac6e679-4004-4cee-7738-08dbcce4678e","data":[{"label":"Bar Helper","value":8300},{"label":"Cashier","value":4600},{"label":"Kitchen Prepration","value":8700},{"label":"Maintenance Technician","value":13000}],"title":"pie","chartType":"radialBar"}]

interface Props {
  DataReport: any;
  chartList: any[] | undefined;
  DataChartReport: any;
}

const TabsReports: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const objData = props.DataChartReport?.map((item: any) => {
    let object;
    if (
      item?.chartType === 'donut' ||
      item?.chartType === 'pie' ||
      item?.chartType === 'radialBar'
    ) {
      object = {
        type: item?.chartType,
        title: item?.title,
        data: item?.data?.map((m: any) => m.value),
        labels: item?.data?.map((m: any) => m.label),
      };
    } else {
      object = {
        type: item?.chartType,
        title: item?.title,
        data: item?.kpiNames?.map((kpiName: any) => {
          const kpiData = item?.data?.map((item: { data: any[] }) => {
            const kpi = item.data?.find((f) => f.label === kpiName);
            return kpi ? kpi.value : 0;
          });
          return {
            name: kpiName,
            data: kpiData,
          };
        }),
        labels: item?.data.map((m: any) => m.name),
      };
    }
    return object;
  });

  return (
    <Card>
      <div className="d-flex fw-bold bg-theme bg-opacity-10">
        <span className="flex-grow-1">
          <ul className="nav nav-tabs nav-tabs-v2 px-4 text-nowrap">
            <li className="nav-item me-3">
              <Link to="#1" className="nav-link active px-2 pb-1 text-center" data-bs-toggle="tab">
                <div className="nav-field">{t('Report')}</div>
                <div className="nav-value mt-1">
                  <i
                    className="fa-lg bi bi-file-spreadsheet"
                    style={{ color: "var(--bs-theme)" }}
                  ></i>
                </div>
              </Link>
            </li>

            {props.chartList?.map((item, index) => (
              <li className="nav-item me-3" key={++index}>
                <Link
                  to={`#${item.id}`}
                  className="nav-link px-2 pb-1 text-center"
                  data-bs-toggle="tab">
                  <div className="nav-field me-2">{item.name}</div>
                  <div className="nav-value mt-1">
                    <i className={item.icon} style={{ color: 'var(--bs-theme)' }}></i>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </span>
        <span className="py-4 px-3">
          <CardExpandToggler />
        </span>
      </div>

      <CardBody>
        <div className="tab-content">
          <div className="tab-pane fade show active" id="1">
            {Object.keys(props.DataReport)?.length > 0 && (
              <DataTableViewer DataReport={props.DataReport} />
            )}
          </div>

          {/* 
          {props.chartList?.map((item, index) => (
            <div className="tab-pane fade" id={item.id} key={++index}>
              {objData.map((m: any, index: number) => (
                <div className="col-6 m-auto" key={index}>
                  {item.name === m.title ? (
                    <ChartComponent DataChartReport={m} />
                  ) : null}
                </div>
              ))}
            </div>
          ))} */}

          {props.chartList?.map((item, index) => (
            <div className="tab-pane fade" id={item.id} key={++index}>
              {objData.map((m: any, index: number) => (
                <div className="col-6 m-auto" key={index}>
                  {item.name === m.title ? <Charts DataChartReport={m} chartId={item.id} /> : null}
                </div>
              ))}
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

export default TabsReports;
