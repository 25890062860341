import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { CardExpandToggler } from "../../../components/card/card";

function DoughnutChart({ data }) {
  const id = data.reportChartId;
  const chartRef = useRef(null);

  // useEffect(() => {
  //   var themeColor = getComputedStyle(document.body)
  //     .getPropertyValue("--bs-theme")
  //     .trim();
  //   var themeColorRgb = getComputedStyle(document.body)
  //     .getPropertyValue("--bs-theme-rgb")
  //     .trim();
  //   var gray900 = getComputedStyle(document.body)
  //     .getPropertyValue("--bs-gray-900")
  //     .trim();
  //   var inverse = getComputedStyle(document.body)
  //     .getPropertyValue("--bs-inverse")
  //     .trim();
  //   var inverseRgb = getComputedStyle(document.body)
  //     .getPropertyValue("--bs-inverse-rgb")
  //     .trim();

  //   // Get the existing Chart instance
  //   const existingChart = chartRef.current;
  //   if (existingChart) {
  //     existingChart.destroy();
  //   }

  //   // Wait for the component to render and the canvas element to be available
  //   const canvas = document.getElementById(id);

  //   if (canvas) {
  //     const newChart = new Chart(canvas, {
  //       type: "doughnut",
  //       data: {
  //         labels: data?.data?.map((m) => m.label) || [],
  //         datasets: [
  //           {
  //             data: data?.data?.map((m) => m.value) || [],
  //             // backgroundColor: ['rgba('+ themeColorRgb +', .25)', 'rgba('+ inverseRgb +', .2)', 'rgba('+ themeColorRgb +', .5)'],
  //             // hoverBackgroundColor: [themeColor, inverse, gray900],
  //             borderWidth: 0.5,
  //           },
  //         ],
  //       },
  //     });
  //     chartRef.current = newChart;
  //   }
  // }, [data, id]);


  useEffect(() => {
    var themeColorRgb = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme-rgb")
    .trim();
  var gray900Rgb = getComputedStyle(document.body)
    .getPropertyValue("--bs-gray-900-rgb")
    .trim();
  var inverseRgb = getComputedStyle(document.body)
    .getPropertyValue("--bs-inverse-rgb")
    .trim();


    let newChart = null;

    if (chartRef.current) {
      // Destroy previous chart instance
      if (chartRef.current.chartInstance) {
        chartRef.current.chartInstance.destroy();
      }

      // Create new chart instance
      newChart = new Chart(chartRef.current, {
        type: "doughnut",
        data: {
          labels: data?.data?.map((m) => m.label) || [],
          datasets: [
            {
              data: data?.data?.map((m) => m.value) || [],
              borderWidth: 0.5,
            },
          ],
        },
      });

      // Save the chart instance in the ref
      chartRef.current.chartInstance = newChart;
    }

    // Cleanup function
    return () => {
      if (newChart) {
        newChart.destroy();
      }
    };
  }, [data]);

  return (
    <div>
      <div className="d-flex fw-bold small p-3 bg-theme bg-opacity-20">
        <span className="flex-grow-1">{data?.title}</span>
        <CardExpandToggler />
      </div>

      <canvas id={id} ref={chartRef}></canvas>
    </div>
  );
}

export default DoughnutChart;
