import { useAppSelector } from "../../store/hooks";

const useGetNtxProfile = () => {
  const UserProfile = useAppSelector(
    (s) => s.userLogin?.AuthUser?.profile
  );

  return { UserProfile };
};

export default useGetNtxProfile;
