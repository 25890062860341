import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getTransactionsOrderSalseList } from '../../store/Sales/SalesOrder/SalesOrderSlice';

const useGetSalesOrderList = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (SalesOrderList?.length === 0) {
      dispatch(getTransactionsOrderSalseList());
    }
  }, []);
  const { SalesOrderList, loading, error } = useAppSelector((state) => state.SalesOrder);

  return { SalesOrderList, loading, error };
};

export default useGetSalesOrderList;
