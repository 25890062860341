import React from 'react'
import NtxPeoples from '../../../NTX/Peoples/NtxPeoples'

const AddSuppliers = () => {

  var fieldList = [
    "typeCode",
    "customerNumber",
    "personId",
    "billToLocationId",
    "shipToLocationId",
    // "termsId", // الشروط للعملاء . لها جدول محدد
    // "setOfBooksId", // جدول الاستاذ العام
    "creditLimit", // سقف الدين
    "paymentPriority", // أولوية الدفع لووكآب  موجود في المورد وليس في العميل
    "invoiceCurrencyCode", //
    "paymentCurrencyCode", //
    "invoiceLimit", // موجود في المورد وليس في العميل ونوعه رقم 
    "bankAccountName", // 
    "bankAccountNum", // 
    "vatCode", //  الرقم الضريبي للعميل
    "tradingName", // الاسم التجاري للعميل
    "workReference", // اسم الشخص
    "companyRegistrationNumber", // السجل التجاري للعميل
    "peopleCategory"

  ];

  return (
    <div>
      <NtxPeoples typeCode='SUPPLIERS' isUpdate={false} fieldList={fieldList} />
    </div>
  )
}

export default AddSuppliers
