import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { ChartAccountsDetailsModels } from "./ChartAccountsModels";

export const AddGlChartOfAccountsDetails = createAsyncThunk(
  "ChartAccountsDetails/AddGlChartOfAccountsDetails",
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post(
        "Finance/AddGlChartOfAccountsDetails",
        item
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdatGlChartOfAccountsDetails = createAsyncThunk(
  "ChartAccountsDetails/UpdatGlChartOfAccountsDetails",
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post(
        "Finance/UpdatGlChartOfAccountsDetails",
        item
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const GetGlChartOfAccountsDetailsList = createAsyncThunk(
    "ChartAccountsDetails/GetGlChartOfAccountsDetailsList",
    async (_, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get('Finance/GetGlChartOfAccountsDetailsList');
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );


  
export const GetGlChartOfAccountsDetailsById = createAsyncThunk(
    "ChartAccountsDetails/GetGlChartOfAccountsDetailsById",
    async (id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get(`Finance/GetGlChartOfAccountsDetailsById?Id=${id}`);
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );



export const GetGlChartOfAccountsDetailsByAccountParant = createAsyncThunk(
    "ChartAccountsDetails/GetGlChartOfAccountsDetailsByAccountParant",
    async (id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get(`Finance/GetGlChartOfAccountsDetailsByAccountParant?AccountParant=${id}`);
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );




const initialState: {
  ChartAccountsDetailsList: ChartAccountsDetailsModels[];
  ChartAccountsDetailsById: ChartAccountsDetailsModels;
  ChartAccountsDetailsByAccountParant: ChartAccountsDetailsModels;
  loading: boolean;
  error: any | null;
} = {
  ChartAccountsDetailsList: [],
  ChartAccountsDetailsById: {} as ChartAccountsDetailsModels,
  ChartAccountsDetailsByAccountParant: {} as ChartAccountsDetailsModels,
  loading: false,
  error: null,
};

const ChartAccountsDetailsSlice = createSlice({
  name: "ChartAccountsDetails",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(AddGlChartOfAccountsDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        AddGlChartOfAccountsDetails.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.ChartAccountsDetailsList.push(action.payload.data);
        }
      )
      .addCase(
        AddGlChartOfAccountsDetails.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )




      .addCase(GetGlChartOfAccountsDetailsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetGlChartOfAccountsDetailsList.fulfilled,
        (state, action: PayloadAction<ChartAccountsDetailsModels[]>) => {
          state.loading = false;
          state.ChartAccountsDetailsList = action.payload;
        }
      )
      .addCase(
        GetGlChartOfAccountsDetailsList.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )




      .addCase(GetGlChartOfAccountsDetailsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetGlChartOfAccountsDetailsById.fulfilled,
        (state, action: PayloadAction<ChartAccountsDetailsModels>) => {
          state.loading = false;
          state.ChartAccountsDetailsById = action.payload;
        }
      )
      .addCase(
        GetGlChartOfAccountsDetailsById.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default ChartAccountsDetailsSlice.reducer;
