


import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axiosNeatex";
import { EquipmentsRoutes, LocationsAndRealEstate } from "./equipmentsRoutesModels";




export const  GetEquipmentsRoutesList = createAsyncThunk(
    "EquipmentsRoutes/GetEquipmentsRoutesList",
    async (_, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get('Fleet/GetEquipmentsRoutesList');
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );

export const  GetEquipmentsRoutesById = createAsyncThunk(
    "EquipmentsRoutes/GetEquipmentsRoutesById",
    async (id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get(`Fleet/GetEquipmentsRoutesById?Id=${id}`);
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );


  export const InsertEquipmentsRoutes = createAsyncThunk('EquipmentsRoutes/InsertEquipmentsRoutes',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Fleet/AddEquipmentsRoutes" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });


  export const UpdatEquipmentsRoutes = createAsyncThunk('EquipmentsRoutes/UpdatEquipmentsRoutes',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Fleet/UpdatEquipmentsRoutes" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });


  export const  GetEquipmentsRoutesListByEquipmentsId = createAsyncThunk(
    "EquipmentsRoutes/GetEquipmentsRoutesListByEquipmentsId",
    async (id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get(`Fleet/GetEquipmentsRoutesByFleetEquipmentsId?FleetEquipmentsId=${id}`);
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );
  


  export const  GetListLocations = createAsyncThunk(
    "EquipmentsRoutes/GetListLocations",
    async (_, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get('Fleet/GetListLocations');
        return data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );


const initialState: {
    EquipmentsRoutesList : EquipmentsRoutes[]
    EquipmentsRoutesListByEquipmentsId : EquipmentsRoutes[]
    EquipmentsRoutesById? : EquipmentsRoutes
    ListLocationsAndRealEstate : LocationsAndRealEstate[]
    loading: boolean;
    error: any | null;
  } = {
    EquipmentsRoutesListByEquipmentsId : [],
    EquipmentsRoutesList: [],
    ListLocationsAndRealEstate: [],
    loading: false,
    error: null,

  };


  

const equipmentsRoutesSlice = createSlice({
    name: "EquipmentsRoutes",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder

      .addCase(InsertEquipmentsRoutes.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(InsertEquipmentsRoutes.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.EquipmentsRoutesList.push(action.payload.data); 
      })
      .addCase(InsertEquipmentsRoutes.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


    .addCase(GetEquipmentsRoutesList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetEquipmentsRoutesList.fulfilled, (state, action: PayloadAction<any> ) => {
        state.loading = false;
        state.EquipmentsRoutesList = action.payload;
      })
      .addCase(GetEquipmentsRoutesList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


    .addCase(GetEquipmentsRoutesListByEquipmentsId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetEquipmentsRoutesListByEquipmentsId.fulfilled, (state, action: PayloadAction<any> ) => {
        state.loading = false;
        state.EquipmentsRoutesListByEquipmentsId = action.payload;
      })
      .addCase(GetEquipmentsRoutesListByEquipmentsId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })




    .addCase(GetEquipmentsRoutesById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetEquipmentsRoutesById.fulfilled, (state, action: PayloadAction<EquipmentsRoutes> ) => {
        state.loading = false;
        state.EquipmentsRoutesById = action.payload;
      })
      .addCase(GetEquipmentsRoutesById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })




      .addCase(GetListLocations.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetListLocations.fulfilled, (state, action: PayloadAction<any> ) => {
        state.loading = false;
        state.ListLocationsAndRealEstate = action.payload;
      })
      .addCase(GetListLocations.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      
    },
  });
  
  export default equipmentsRoutesSlice.reducer;