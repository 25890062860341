import React from "react";
import NtxPeoples from "../../../NTX/Peoples/NtxPeoples";

const Add = () => {
  var fieldList = [
    "typeCode",
    "customerNumber",
    "personId",
    "billToLocationId",
    "shipToLocationId",
    "termsId", // الشروط للعملاء . لها جدول محدد
    "setOfBooksId", // جدول الاستاذ العام
    "creditLimit", // سقف الدين
    // "paymentPriority", // أولوية الدفع لووكآب  موجود في المورد وليس في العميل
    "invoiceCurrencyCode", //
    "paymentCurrencyCode", //
    // "invoiceLimit", // موجود في المورد وليس في العميل ونوعه رقم 
    "bankAccountName", // 
    "bankAccountNum", // 
    "vatCode", //  الرقم الضريبي للعميل
    "tradingName", // الاسم التجاري للعميل
    "workReference", // اسم الشخص
    "companyRegistrationNumber", // السجل التجاري للعميل
    "peopleCategory",
    "peopleCategory",
    "priceListId",
    "accountId",

  ];

  return (
    <div>
      <NtxPeoples typeCode="CUSTOMERS" isUpdate={false} fieldList={fieldList} />
    </div>
  );
};

export default Add;
