import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';


const DOUGHNUTCHART2 = ({labels , EARLYData}) => {

    useEffect(() => {
        var themeColor = (getComputedStyle(document.body).getPropertyValue('--bs-theme')).trim();
        var themeColorRgb = (getComputedStyle(document.body).getPropertyValue('--bs-theme-rgb')).trim();
        var gray900 = (getComputedStyle(document.body).getPropertyValue('--bs-gray-900')).trim();
        var inverse = (getComputedStyle(document.body).getPropertyValue('--bs-inverse')).trim();
        var inverseRgb = (getComputedStyle(document.body).getPropertyValue('--bs-inverse-rgb')).trim();

        var canvas = document.getElementById('doughnutChart2');
        var existingChart = Chart.getChart(canvas);

        if (existingChart) {
          existingChart.destroy();
        }
            
        new Chart(canvas, {
          type: 'doughnut',
          data: {
            labels: labels ,
            datasets: [{
              data: EARLYData ,
              // backgroundColor: ['rgba('+ themeColorRgb +', .25)', 'rgba('+ inverseRgb +', .2)', 'rgba('+ themeColorRgb +', .5)'],
              // hoverBackgroundColor: [themeColor, inverse, gray900],
              borderWidth: 0
            }]
          }
        });
        
        // eslint-disable-next-line
      }, []);
  return (
    <div>
    <canvas id="doughnutChart2"></canvas>
  </div>
  )
}

export default DOUGHNUTCHART2