import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ntxAPI } from "../../API/axiosNeatex";




 export const GetAmeConditionUsageListByRuleId = createAsyncThunk("AmeConditionUsage/GetAmeConditionUsageListByRuleId", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

   
    try {
      const {data} = await ntxAPI.get( `AmeSetting/GetAmeConditionUsageListByRuleId?RuleId=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });


  export const insertAmeConditionUsage = createAsyncThunk("AmeConditionUsage/insertAmeConditionUsage", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.post("AmeSetting/AddAmeConditionUsage" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });


  export const DeleteAmeConditionUsage = createAsyncThunk("AmeConditionUsage/DeleteAmeConditionUsage", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;


    try {
      const {data} = await ntxAPI.post(`AmeSetting/DeleteAmeConditionUsage?Id=${id}` )
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });




  const initialState = { ConditionUsageListByRuleId: [], loading: false, error: null };

  const AmeConditionUsageSlice = createSlice({
    name: "AmeConditionUsage",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        // Get AME Condition List by Rule ID
        .addCase(GetAmeConditionUsageListByRuleId.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(GetAmeConditionUsageListByRuleId.fulfilled, (state, action) => {
          state.loading = false;
          state.ConditionUsageListByRuleId = action.payload;
        })
        .addCase(GetAmeConditionUsageListByRuleId.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
    
        // Insert AME Condition Usage
        .addCase(insertAmeConditionUsage.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(insertAmeConditionUsage.fulfilled, (state, action) => {
          state.loading = false;
          state.ConditionUsageListByRuleId.push(action.payload);
        })
        .addCase(insertAmeConditionUsage.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
    
        // Delete AME Condition Usage
        .addCase(DeleteAmeConditionUsage.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(DeleteAmeConditionUsage.fulfilled, (state, action) => {
          state.loading = false;
          state.ConditionUsageListByRuleId = state.ConditionUsageListByRuleId?.filter((el) => el.id !== action.payload);
        })
        .addCase(DeleteAmeConditionUsage.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    }
  });
  
  export default AmeConditionUsageSlice.reducer;